/* eslint-disable no-undef */
import React, { useMemo, useState } from 'react';
import { cx } from 'emotion';
import throttle from 'lodash.throttle';

import { Menu, ListItem, Tooltip, IconButton } from '@ublend-npm/aulaui-next';
import MoreVert from '@material-ui/icons/MoreVert';
import { ANNOUNCEMENTS } from '@core/constants/postFeedType';
import { Post } from '@app/types/core';
import { goToPost } from '@app/actions/navigation';
import EditContent from '../../common/EditModal';
import UserBanner from '../UserBanner';
import TextButton from '../../common/Buttons/TextButton';
import ScheduledItemMenu from '../../common/ScheduledItemHeader';
import { isScheduledPost } from '../../../../../core/utils/posts';

import styles from './style';
import { menu, menuItem, dangerMenuItem } from '../../common/Menu.styled';
import { MAKE_IMPORTANT, NOT_IMPORTANT } from '../../../../constants/texts';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';

interface PostUserBannerProps {
  post: Post;
  isAuthor: boolean;
  isEducator: boolean;
  isReadOnlyUser: boolean;
  isUpdatingPost: boolean;
  navigateAfterDelete: boolean;
  isOpenMenuItemVisible?: boolean;
  handleDelete: (post: Post, navigateAfterDelete: boolean) => void;
  handleImportantPost: (post: Post) => void;
  handleOpenSchedulePost: (postId: string) => void;
  handlePublishPost: (postId: string) => void;
  addTag?: () => void;
}

const Actions = {
  Open: 'open',
  Edit: 'edit',
  MakeImportant: 'makeImportant',
  Delete: 'delete',
};

interface MenuItem {
  key: string;
  getLabel: (props: PostUserBannerProps) => string;
  isVisible: (props: PostUserBannerProps) => boolean;
  danger: boolean;
}

const menuItems: MenuItem[] = [
  {
    key: Actions.Open,
    getLabel: () => 'Open',
    isVisible: ({ isOpenMenuItemVisible = true }) => isOpenMenuItemVisible,
    danger: false,
  },
  {
    key: Actions.Edit,
    getLabel: () => 'Edit',
    isVisible: ({ isAuthor, isReadOnlyUser }) => !isReadOnlyUser && isAuthor,
    danger: false,
  },
  {
    key: Actions.MakeImportant,
    getLabel: ({ post: { important } }) =>
      !important ? MAKE_IMPORTANT : NOT_IMPORTANT,
    isVisible: ({ isEducator, post }) =>
      post.feedType !== ANNOUNCEMENTS && isEducator && !isScheduledPost(post),
    danger: false,
  },
  {
    key: Actions.Delete,
    getLabel: () => 'Delete',
    isVisible: ({ isAuthor, isEducator, isReadOnlyUser }) =>
      !isReadOnlyUser && (isAuthor || isEducator),
    danger: true,
  },
];

export const PostUserBanner = (props: PostUserBannerProps) => {
  const {
    post,
    isAuthor,
    isEducator,
    isUpdatingPost,
    navigateAfterDelete,
    handleDelete,
    handleImportantPost,
    handleOpenSchedulePost,
    handlePublishPost,
    addTag,
  } = props;

  const [editOpen, setEditOpen] = useState(false);
  const [addTagPressed, setAddTagPressed] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const scheduledPostItems = useMemo(() => {
    return [
      {
        label: 'Change scheduled time',
        action: () => handleOpenSchedulePost(post.objectId),
      },
      {
        label: 'Publish now',
        action: () => handlePublishPost(post.objectId),
      },
    ];
  }, [handleOpenSchedulePost, handlePublishPost, post.objectId]);

  const handleSelectMenuItem = ({ item }) => {
    if (!isUpdatingPost) {
      switch (item) {
        case Actions.Open:
          goToPost(post)();
          break;
        case Actions.Edit:
          setEditOpen(true);
          break;
        case Actions.MakeImportant:
          handleImportantPost(post);
          break;
        case Actions.Delete:
          setDeleteDialogOpen(true);
          break;
        default:
          break;
      }
    }
  };

  const deletePost = () => {
    handleDelete(post, navigateAfterDelete);
    setDeleteDialogOpen(false);
  };

  const handleSelectMenuItemThrottled = throttle(handleSelectMenuItem, 250);

  const addTagVisible = () => {
    const postHasNoTagPosts = !post.tagPosts?.length;
    const canAddTag = isAuthor || isEducator;
    return postHasNoTagPosts && !addTagPressed && canAddTag;
  };

  const ariaLabelId = `post-${post.objectId}`;

  const visibleMenuItems = menuItems.filter((mi) => mi.isVisible(props));

  return (
    <div id={ariaLabelId} style={styles.container}>
      {deleteDialogOpen ? (
        <ConfirmDeleteDialog
          closeDeleteDialog={() => setDeleteDialogOpen(false)}
          deletePost={deletePost}
        />
      ) : null}
      {/* Left container : Avatar + Username */}
      {/* Need to add post.scheduledFor until ESLint is properly configured so we can use post.scheduledFor! */}
      {isScheduledPost(post) && post.scheduledFor ? (
        <ScheduledItemMenu
          scheduledFor={post.scheduledFor}
          items={scheduledPostItems}
        />
      ) : (
        <UserBanner
          user={post.user}
          time={post.scheduledFor || post.createdAt}
        />
      )}
      {/* Right container : IconMenu */}
      <div style={styles.rightContainer}>
        {visibleMenuItems.length ? (
          <Menu
            menuListComponent="ul"
            classes={{ menu }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            renderTarget={({ handleTargetClick, isOpen }) => (
              <Tooltip title="Post options" position="bottom">
                <div style={styles.optionsContainer}>
                  <IconButton
                    icon={MoreVert}
                    size="xSmall"
                    type="secondary"
                    label="Post options"
                    onClick={handleTargetClick}
                    buttonAttributes={{ 'aria-expanded': isOpen }}
                  />
                </div>
              </Tooltip>
            )}
            onSelect={handleSelectMenuItemThrottled}
            renderChildren={({ getItemProps, highlightedIndex }) =>
              visibleMenuItems.map((item, index) => (
                <ListItem
                  key={item.key}
                  className={
                    item.danger ? cx(menuItem, dangerMenuItem) : menuItem
                  }
                  primaryText={item.getLabel(props)}
                  highlighted={highlightedIndex === index}
                  {...getItemProps({
                    item: item.key,
                    onClick: (e) => e.stopPropagation(),
                    'aria-selected': undefined,
                  })}
                />
              ))
            }
          />
        ) : null}
        {post && (
          <EditContent
            open={editOpen}
            content={post.content}
            itemClass="UBClassRoomPost"
            itemType={post.feedType}
            itemId={post.objectId}
            onClose={() => {
              setEditOpen(false);
            }}
          />
        )}
        {addTag && addTagVisible() ? (
          <TextButton
            containerStyles={styles.addTag}
            label="Add tag"
            onClick={() => {
              addTag();
              setAddTagPressed(true);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
