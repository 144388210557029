import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 1300px;
`;

export const LeftColumn = styled.div`
  flex-grow: 1;
  max-width: calc(
    584px + ${theme.spacing.medium} + ${theme.spacing.medium} + 100px
  );
  display: flex;
  flex-direction: column;
`;

export const RightColumn = styled.div`
  width: 230px;
  padding: ${theme.spacing.xSmall} ${theme.spacing.xxGiant}
    ${theme.spacing.xSmall} ${theme.spacing.xSmall};
`;

export const Title = styled.div`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.large};
  margin-top: ${theme.spacing.large};
`;
