import React from 'react';
import PropTypes from 'prop-types';

import ExtensionItem from './ExtensionItem';
import assignmentPropTypes from '../Assignments/utils/assignment.proptype';
import { HourGlassHalfFullIcon } from '../../common/Icons/HourGlassHalfFullIcon';

import {
  ExtensionsPane as ExtensionsPaneComponent,
  Button,
} from './Submissions.styled';

const noop = (_) => _;

const ExtensionsPane = ({
  extensions,
  parentAssignmentSubmissions,
  handleClick,
  handleEdit,
  handleDelete,
  handleVisibilityChange,
  selectedExtensionId,
  openCreateExtensionModal,
  isEducator,
}) => {
  return (
    <ExtensionsPaneComponent>
      {extensions.map((extension) => {
        const {
          id,
          title,
          submissions: extensionSubmissions,
          students,
          parentAssignmentId,
          isHidden,
        } = extension;
        const isAssignmentExtension = !!parentAssignmentId;
        const titleInExtension = isAssignmentExtension ? title : 'Main cohort';

        const clickHandler = () => handleClick(id);
        const editHandler = isAssignmentExtension
          ? () => handleEdit(id)
          : undefined;
        const deleteHandler = isAssignmentExtension
          ? () => handleDelete(extension)
          : undefined;

        const visibilityHandler = isAssignmentExtension
          ? (visibility) => handleVisibilityChange(visibility, extension)
          : undefined;

        const studentsCount = isAssignmentExtension
          ? students.length
          : parentAssignmentSubmissions.length;

        return (
          <ExtensionItem
            key={id}
            id={id}
            title={titleInExtension}
            isHidden={isHidden}
            submissionsCount={extensionSubmissions.length}
            studentsCount={studentsCount}
            selected={selectedExtensionId === id}
            onClick={clickHandler}
            showOptions={isAssignmentExtension && isEducator}
            onVisibilityChange={isEducator && visibilityHandler}
            onClickEdit={editHandler}
            onClickDelete={deleteHandler}
          />
        );
      })}
      {isEducator && (
        <Button
          type="text"
          iconLeft={HourGlassHalfFullIcon}
          onClick={openCreateExtensionModal}
        >
          New extension
        </Button>
      )}
    </ExtensionsPaneComponent>
  );
};

ExtensionsPane.propTypes = {
  extensions: PropTypes.arrayOf(assignmentPropTypes),
  handleClick: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleVisibilityChange: PropTypes.func,
  selectedExtensionId: PropTypes.string,
  parentAssignmentSubmissions: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  openCreateExtensionModal: PropTypes.func,
  isEducator: PropTypes.bool,
};

ExtensionsPane.defaultProps = {
  extensions: [],
  handleClick: noop,
  handleEdit: noop,
  handleDelete: noop,
  handleVisibilityChange: noop,
  selectedExtensionId: '',
  openCreateExtensionModal: () => {},
  isEducator: false,
};

export default ExtensionsPane;
