import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from '@ublend-npm/aulaui-next';
import CachedIcon from '@material-ui/icons/Cached';
import ListIcon from '@material-ui/icons/List';

import ScrollBar from '../../../common/ScrollBar';
import { Version, VersionList as VersionListType } from '../types';

import VersionModalLoader from './VersionModalLoader';
import VersionModalPlaceholder from './VersionModalPlaceholder';

import {
  ModalContent,
  VersionList,
  VersionView,
  ActionButton,
} from './VersionModal.styled';

import {
  MATERIAL_VERSIONS,
  MATERIAL_REVERT,
} from '../../../../../constants/texts';

/* eslint-disable react/prop-types */
const NormalContent = (props) => (
  <ModalContent>
    <VersionList
      versions={props.versions}
      current={props.current}
      selected={props.selected ? props.selected.id : null}
      onSelect={props.onSelect}
    />
    <ScrollBar autoHide>
      <VersionView
        version={props.selected}
        isLoadingVersion={props.isLoadingVersion}
      />
    </ScrollBar>
  </ModalContent>
);

const LoaderContent = () => (
  <ModalContent>
    <VersionModalLoader />
  </ModalContent>
);

const PlaceholderContent = () => (
  <ModalContent>
    <VersionModalPlaceholder />
  </ModalContent>
);
/* eslint-enable react/prop-types */

const headerChildren = (props) => (
  <ActionButton
    onClick={props.revert}
    iconRight={CachedIcon}
    loading={props.reverting}
    disabled={
      !(props.selected && props.selected.id !== props.current) ||
      props.reverting ||
      props.isLoadingVersion
    }
  >
    <span className="title" data-testid="revert-to-selected-version-button">
      {MATERIAL_REVERT}
    </span>
  </ActionButton>
);

const VersionModal = (props) => (
  <Modal
    id="material-versions"
    size="fullscreen"
    container={() => document.getElementById('content-container')}
    open={props.open}
    onClose={props.onClose}
    headerData={{
      children: headerChildren(props),
      icon: <ListIcon />,
      title: MATERIAL_VERSIONS,
    }}
  >
    {!props.isLoadingVersions && !!props.versions.length && (
      <NormalContent {...props} />
    )}
    {!props.isLoadingVersions && !props.versions.length && (
      <PlaceholderContent />
    )}
    {props.isLoadingVersions && <LoaderContent />}
  </Modal>
);

VersionModal.propTypes = {
  isLoadingVersions: PropTypes.bool,
  isLoadingVersion: PropTypes.bool,
  open: PropTypes.bool,
  revert: PropTypes.func,
  reverting: PropTypes.bool,
  current: PropTypes.string,
  selected: Version,
  versions: VersionListType,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

VersionModal.defaultProps = {
  isLoadingVersions: false,
  isLoadingVersion: false,
  open: false,
  revert: () => null,
  reverting: false,
  current: null,
  selected: null,
  versions: [],
  onClose: () => null,
  onSelect: () => null,
};

export default VersionModal;
