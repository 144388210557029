import { connect } from 'react-redux';
import AulaEditor from './AulaEditor';
import {
  API_URL,
  FILE_URL,
} from '../../../../../../../../core/constants/endpoints';

const mapStateToProps = () => {
  return {
    config: {
      filesUrl: FILE_URL(),
      apiUrl: API_URL(),
    },
  };
};

export default connect(mapStateToProps)(AulaEditor);
