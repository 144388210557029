import React from 'react';
import PropTypes from 'prop-types';

import FolderListItem from './FolderListItem.container';
import SectionListItem from './SectionListItem.container';
import { ListDividerBottom, ListDividerTop } from './MaterialListItem.styled';

const MaterialListItem = ({
  isFolder,
  materialId,
  educatorOnly,
  isFirst = false,
  isLast = false,
  ...props
}) => {
  const Item = isFolder ? FolderListItem : SectionListItem;
  return (
    <>
      {!isFirst && isFolder && <ListDividerTop />}
      <Item materialId={materialId} educatorOnly={educatorOnly} {...props} />
      {!isLast && isFolder && <ListDividerBottom />}
    </>
  );
};

MaterialListItem.propTypes = {
  isFolder: PropTypes.bool.isRequired,
  materialId: PropTypes.string.isRequired,
  educatorOnly: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

MaterialListItem.defaultProps = {
  educatorOnly: false,
  isFirst: false,
  isLast: false,
};

export default MaterialListItem;
