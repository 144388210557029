import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from '@ublend-npm/aulaui-next';
import UserAvatar from '../common/UserAvatar.container';
import { AvatarContainer } from './ConversationParticipant.styled';

const ConversationParticipant = ({ name, participantId }) => {
  return (
    <AvatarContainer>
      <Tooltip title={name} position="bottom">
        <div>
          <UserAvatar aria-hidden="false" userId={participantId} />
        </div>
      </Tooltip>
    </AvatarContainer>
  );
};

ConversationParticipant.propTypes = {
  name: PropTypes.string,
  participantId: PropTypes.string.isRequired,
};

ConversationParticipant.defaultProps = {
  name: '',
};

export default ConversationParticipant;
