import { connect } from 'react-redux';

import {
  selectors as materialSelectors,
  operations,
} from '../../../../../store/materials';
import MaterialVisibilityToggle from './MaterialVisibilityToggle';

const mapStateToProps = (state, ownProps) => {
  const material = materialSelectors.getMaterial(state, ownProps.materialId);

  return {
    hidden: material.hidden,
    scheduledFor: material.scheduledFor,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onHide: () => dispatch(operations.hideMaterial(ownProps.materialId)),
  onUnhide: () => dispatch(operations.unHideMaterial(ownProps.materialId)),
  openScheduleDialog: () =>
    dispatch(operations.openScheduleMaterial(ownProps.materialId)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { onHide, onUnhide, ...newDispatchProps } = dispatchProps;

  return {
    ...stateProps,
    ...newDispatchProps,
    ...ownProps,
    onToggleVisibility: () => {
      if (stateProps.hidden) {
        onUnhide();
      } else {
        onHide();
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MaterialVisibilityToggle);
