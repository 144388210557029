/* eslint-disable no-undef */
import {
  MapDispatchToProps,
  MapStateToProps,
  MergeProps,
  connect,
} from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FeedType } from '@app/types/core';
import { getCurrentSpaceId } from '@core/selectors/space';
import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import {
  getAssignmentsPath,
  getAnnouncementsPath,
  getCommonRoomPath,
} from '@app/actions/navigation';
import { selectFeedType } from '@core/actions/space';
import PostNavigation, {
  PostNavigationProps,
  PreviousPage,
} from './PostNavigation';
import { AulaState } from '@core/types/state';

export type PostNavigationOwnProps = Readonly<{
  style?: React.CSSProperties;
  prevPage?: PreviousPage;
  postFeedType?: FeedType;
}>;

type PropsFromState = Readonly<{
  spaceId: string;
  href: string;
}>;

const getHrefBuilder = (
  isAssignments: boolean,
  postFeedType: FeedType
): ((spaceId: string) => string) => {
  if (isAssignments) {
    return getAssignmentsPath;
  }

  return postFeedType === ANNOUNCEMENTS
    ? getAnnouncementsPath
    : getCommonRoomPath;
};

const isAssignments = (prevPage: string = 'feed') =>
  prevPage === 'all assignments';

const mapStateToProps: MapStateToProps<
  PropsFromState,
  PostNavigationOwnProps,
  AulaState
> = (state, { prevPage, postFeedType = COMMON_ROOM }) => {
  const spaceId = getCurrentSpaceId(state);

  const href = getHrefBuilder(isAssignments(prevPage), postFeedType)(spaceId);

  return {
    spaceId,
    href,
  };
};

type PropsFromDispatch = Readonly<{
  onClick: (spaceId: string) => React.MouseEventHandler | undefined;
}>;

const mapDispatchToProps: MapDispatchToProps<
  PropsFromDispatch,
  PostNavigationOwnProps
> = (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
  { postFeedType, prevPage }
) => ({
  onClick: isAssignments(prevPage)
    ? () => undefined
    : (spaceId: string) => () => {
        dispatch(selectFeedType({ feedType: postFeedType, spaceId }));
      },
});

const mergeProps: MergeProps<
  PropsFromState,
  PropsFromDispatch,
  PostNavigationOwnProps,
  PostNavigationProps
> = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onClick: dispatchProps.onClick(stateProps.spaceId),
  prevPage: ownProps.prevPage || 'feed',
});

const PostNavigationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PostNavigation);

export default PostNavigationContainer;
