import styled, { css, keyframes } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

/* This needs to be a li because it's inside a ul */
export const Container = styled.li`
  position: relative;
`;

export const Title = styled.div`
  color: ${theme.color.purple};
  text-align: center;
  font-size: 12px;
`;

export const Body = styled.div`
  padding-top: 4px;
  color: ${theme.color.designSystem.blackOpacity60};
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  display: inline-flex;
`;

const bounce = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-20px); }
  50% { transform: translateY(0); }
  75% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

export const pointer = css`
  pointer-events: none;
  position: absolute;
  right: -7px;
  top: -4px;
  font-size: 32px;

  animation: ${bounce};
  transform-origin: center bottom;
  animation-iteration-count: infinite;
  animation-duration: 2.8s;
`;
