import React from 'react';
import styled, { css } from 'react-emotion';
import { theme, ListItem as BaseListItem } from '@ublend-npm/aulaui-next';

export const Icon = styled(({ IconType }) => <IconType />)`
  font-size: 20px;
  background: none;
  color: ${({ fontColor }) => fontColor};
`;

export const HeaderItem = styled(BaseListItem)`
  padding: 0 8px 0 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  height: 32px;
  border-radius: 4px;
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.color.grey8};
  box-sizing: border-box;

  &:focus {
    outline: Highlight auto;
    outline: -webkit-focus-ring-color auto;
  }
`;

export const textClass = {
  small: css`
    font-size: 12px;
    font-weight: normal;
  `,
  medium: css`
    font-size: 14px;
    font-weight: normal;
  `,
  large: css`
    font-size: 16px;
    font-weight: normal;
  `,
};
