import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { LTIProviderType } from '@app/components/propTypes/common';
import { API_URL, FILE_URL } from '../../../../../core/constants/endpoints';
import withErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { ERROR_EDITOR } from '../../../../constants/texts';
import EditorContainer from './EditorReadOnly.styled';
import AulaEditorWithPlugins from '../AulaEditor/AulaEditorWithPlugins';

const EditorReadOnly = ({
  user,
  rawEditorState,
  accessToken,
  institution,
  spaceId,
  ltiProviders,
  itemType,
  itemId,
  isEducator,
}) => {
  const editorConfig = useMemo(
    () => ({
      filesUrl: FILE_URL(),
      apiUrl: API_URL(),
      accessToken,
      spaceId,
      institution,
      userId: user.objectId,
      ltiProviders,
      itemType,
      itemId,
      isEducator,
    }),
    [ltiProviders, itemType, itemId]
  );

  return (
    <EditorContainer>
      <AulaEditorWithPlugins
        readOnly
        editorState={rawEditorState}
        config={editorConfig}
        isAssignmentsEnabled
      />
    </EditorContainer>
  );
};

EditorReadOnly.propTypes = {
  accessToken: PropTypes.string.isRequired,
  institution: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rawEditorState: PropTypes.object.isRequired,
  user: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  ltiProviders: PropTypes.arrayOf(LTIProviderType).isRequired,
  itemType: PropTypes.string,
  itemId: PropTypes.string,
};

EditorReadOnly.defaultProps = {
  itemType: 'Unknown',
  itemId: 'Unknown',
};

export default withErrorBoundary(EditorReadOnly, { message: ERROR_EDITOR });
