import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

type PostCellWrapperProps = {
  isRecentImportant?: boolean;
};

export const PostCellContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${theme.spacing.xLarge};
  position: relative;
`;

export const PostCellWrapper = styled.div<PostCellWrapperProps>`
  width: 100%;
  max-width: 900px;
  min-width: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: ${theme.spacing.large};
  padding-bottom: ${theme.spacing.large};
  padding-left: ${theme.spacing.xxxLarge};
  padding-right: ${theme.spacing.xxxLarge};
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  border: ${({ isRecentImportant }) =>
    isRecentImportant
      ? `1px solid ${theme.color.designSystem.purple50}`
      : 'none'};
`;

export const PostCellSeparator = styled.div`
  background-color: ${theme.color.grey3};
  height: 1px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const PostCellEditedWrapper = styled.div`
  font-size: ${theme.font.size.large};
  color: ${theme.color.grayDark};
  margin-bottom: 15px;
  margin-top: -5px;
  margin-left: ${theme.spacing.xxGiant};
`;

export const ContentWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const PostCellPreviewWrapper = styled.div`
  width: calc(100% + 66.5px);
  display: flex;
  flex-direction: column;
  margin-left: -52.5px;
  margin-bottom: ${theme.spacing.small};
`;
