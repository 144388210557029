import PropTypes from 'prop-types';
import React from 'react';

import { editorStatePropType } from '../../../../../../utils/editorUtils';
import AulaEditorWithFileBlocksValidation from '../../../../common/AulaEditor/AulaEditorWithFileBlocksValidation';

const EditZone = ({
  onReturn,
  editorState,
  editorConfig,
  onChange,
  isSubmitting,
  setSubmittingState,
}) => {
  return (
    <AulaEditorWithFileBlocksValidation
      editorState={editorState}
      config={editorConfig}
      onReturn={onReturn}
      onChange={onChange}
      isSubmitting={isSubmitting}
      setSubmittingState={setSubmittingState}
      type="inbox"
    />
  );
};

EditZone.propTypes = {
  onReturn: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  editorState: editorStatePropType.isRequired,
  editorConfig: PropTypes.shape({
    filesUrl: PropTypes.string,
    apiUrl: PropTypes.string,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setSubmittingState: PropTypes.func.isRequired,
};

export default React.memo(EditZone);
