import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const QuickTipContainer = styled.div`
  font-size: ${theme.font.size.small};
  color: ${theme.color.grey11};
`;

export const QuickTipCommand = styled.span`
  background-color: rgba(122, 27, 190, 0.08);
  border-radius: ${theme.borderRadius.medium};
  color: ${theme.color.purple};
  display: inline-block;
  padding: 2px 4px;
`;
