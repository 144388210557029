import { connect } from 'react-redux';
import { createSelector, defaultMemoize } from 'reselect';
import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import { fetchAnnouncements, fetchCommonRoom } from '@core/actions/space';
import filterByTagAction from '@core/actions/classRoom/filter';
import { setNotificationAsReadByType } from '@core/notifications/actions';
import { currentUserId as getCurrentUserId } from '@core/selectors/user';
import {
  isInstructor,
  selectedFeedType,
  currentSpaceSelector,
  accessingCurrentSpaceAsStaff,
  importantPostsSelector,
  recentImportantPostsSelector,
  commonRoomPostsExcludingRecentImportantSelector,
  announcementsSelector,
  hasFinishedFetchingPostsSelector,
} from '@core/selectors/classroom';
import {
  getCurrentSpaceId,
  getScheduledPostsCount,
} from '@core/selectors/space';
import { selectFeedType } from '@core/actions/space';
import ClassRoomPostsTable from './PostsTable';
import {
  goToAnnouncements,
  goToCommonRoom,
  openImportantPosts,
} from '../../../../../actions/navigation';
import {
  selectors as notificationSelectors,
  operations as notificationOperations,
} from '../../../../../store/notifications';
import { selectors as inboxSelectors } from '../../../../../store/conversations';
import { isFetchingPostsSelector, hasFetchedAllPostsSelector } from '../utils';

const getCurrentUserIdSelector = defaultMemoize((state) =>
  getCurrentUserId(state)
);

const isEducatorSelector = createSelector(
  [(state) => state, getCurrentUserIdSelector],
  (state, userId) => isInstructor(state, userId)
);

const isSpaceMutedSelector = defaultMemoize((state) =>
  notificationSelectors.isSpaceMuted(state, getCurrentSpaceId(state))
);

const tagSelector = defaultMemoize((state) => state.data.tags);
const filterTagSelector = defaultMemoize((state) => state.classRoom.filterTag);

const scheduledPostCountSelector = defaultMemoize((state, feedType) =>
  getScheduledPostsCount(state, feedType)
);

const selectedTagObject = createSelector(
  [tagSelector, filterTagSelector],
  (tags, filterTag) => tags[filterTag]
);

const getSelectedTag = (state) =>
  selectedTagObject(state) && selectedTagObject(state).objectId;

const mapStateToProps = (state) => {
  const currentSpaceState = currentSpaceSelector(state);
  const feedType = selectedFeedType(state);
  const announcementsSelected = feedType === ANNOUNCEMENTS;
  const importantPosts = announcementsSelected
    ? []
    : importantPostsSelector(state);
  const recentImportantPosts = announcementsSelected
    ? []
    : recentImportantPostsSelector(state);
  const { isChangingClassRoom } = state.classRoom;
  const fetching =
    !isChangingClassRoom &&
    isFetchingPostsSelector(state, announcementsSelected);

  if (!currentSpaceState) {
    return {
      commonRoomPosts: [],
      announcementPosts: [],
      tags: {},
      classRoom: undefined,
      fetching: false,
      hasFinishedFetchingPosts: true,
      hasFetchedAll: true,
      selectedFeedType: COMMON_ROOM,
    };
  }

  return {
    currentUserId: getCurrentUserIdSelector(state),
    commonRoomPosts: commonRoomPostsExcludingRecentImportantSelector(state),
    announcementPosts: announcementsSelector(state),
    isEducator: isEducatorSelector(state),
    selectedTag: getSelectedTag(state),
    tags: tagSelector(state),
    classRoom: currentSpaceState,
    fetching,
    hasFinishedFetchingPosts: hasFinishedFetchingPostsSelector(state),
    hasFetchedAll:
      !isChangingClassRoom &&
      hasFetchedAllPostsSelector(state, announcementsSelected),
    spaceIsMuted: isSpaceMutedSelector(state),
    isReadOnlyUser: accessingCurrentSpaceAsStaff(state),
    importantPosts,
    importantPostsCount: importantPosts.length,
    isChangingClassRoom,
    recentImportantPosts,
    recentImportantPostsCount: recentImportantPosts.length,
    scheduledPostCount: scheduledPostCountSelector(state, feedType),
    isConversationOpen: !!inboxSelectors.currentConversationId(state),
    selectedFeedType: feedType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNextPosts: ({ classRoom, feedType, tagId }) => {
    if (!classRoom) {
      Promise.resolve();
    }
    const postsFetcher =
      feedType === ANNOUNCEMENTS ? fetchAnnouncements : fetchCommonRoom;
    dispatch(
      postsFetcher({
        classRoom,
        reset: false,
        tagId,
      })
    );
  },
  setRead: (classRoomId) =>
    new Promise((resolve) => {
      const p = dispatch(setNotificationAsReadByType('post', classRoomId));
      if (p && p.then) {
        p.then(() => {
          resolve();
        });
        return;
      }
      resolve();
    }),
  filterByTag: async (tagId) => {
    await dispatch(filterByTagAction(tagId));
  },
  toggleMuteSpace: (spaceId) =>
    dispatch(notificationOperations.toggleMuteSpace(spaceId)),
  openImportantPosts: () => dispatch(openImportantPosts()),
  goToAnnouncements: (spaceId, queryParams) => {
    dispatch(goToAnnouncements(spaceId, queryParams));
    dispatch(selectFeedType({ feedType: ANNOUNCEMENTS, spaceId }));
  },
  goToCommonRoom: (spaceId, queryParams) => {
    dispatch(goToCommonRoom(spaceId, queryParams));
    dispatch(selectFeedType({ feedType: COMMON_ROOM, spaceId }));
  },
});

// Prevents own props being forwarded. This component is passed to React router which passes
// props that are not needed and cause additional renders of ClassRoomPostsTable which can be
// expensive. Using this approach over shouldComponentUpdate since we don't need any own props.
const mergeProps = (otherStateToProps, otherDispatchToProps, { params }) => ({
  ...otherStateToProps,
  ...otherDispatchToProps,
  params,
});

const ClassRoomPostsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ClassRoomPostsTable);

export default ClassRoomPostsTableContainer;
