import PropTypes from 'prop-types';
import React from 'react';
import {
  convertLegacyContent,
  cleanState,
  isPersistable,
  isUpdating,
  hasError,
} from '@ublend-npm/aula-editor';
import { isAulaEditorState } from '@ublend-npm/aula-editor-utils';

import {
  DONE,
  INBOX_EDIT_MESSAGE_TITLE,
} from '../../../../../../constants/texts';
import * as types from '../../../types';
import onlyWhenDefined from '../../onlyWhenDefined';
import { flow } from '../../../utils';
import EditZone from './EditZone';
import {
  TitleContainer,
  CreateIcon,
  EditZoneContainer,
} from './EditMessageModal.styled';

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../../../../common/Dialog';

class EditMessageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: cleanState,
      isSubmitting: false,
    };

    this.onReturn = this.onReturn.bind(this);
    this.onClick = this.onClick.bind(this);
    this.setEditorState = this.setEditorState.bind(this);
    this.setSubmittingState = this.setSubmittingState.bind(this);
  }

  componentDidMount() {
    const { message } = this.props;
    const parsedContent = JSON.parse(message.content);
    const initialState = isAulaEditorState(parsedContent)
      ? parsedContent
      : convertLegacyContent(parsedContent);

    this.setState((prevState) => ({
      ...prevState,
      editorState: initialState,
    }));
  }

  setSubmittingState(isSubmitting) {
    this.setState((prevState) => ({
      ...prevState,
      isSubmitting,
    }));
  }

  onClick() {
    const { editorState } = this.state;

    if (isPersistable(editorState)) {
      if (!hasError(editorState) && !isUpdating(editorState)) {
        this.onReturn(editorState);
      } else {
        this.setSubmittingState(true);
      }
    }
  }

  onReturn() {
    const { save, close } = this.props;
    const { editorState, isSubmitting } = this.state;
    if (
      isPersistable(editorState) &&
      !isUpdating(editorState) &&
      !hasError(editorState) &&
      !isSubmitting
    ) {
      save(JSON.stringify(editorState));
      close();
    }
  }

  setEditorState(editorState) {
    this.setState((prevState) => ({
      ...prevState,
      editorState,
    }));
  }

  render() {
    const { close, saving, editorConfig } = this.props;
    const { editorState, isSubmitting } = this.state;

    return (
      <Dialog
        id="edit-message-modal"
        open
        onClose={close}
        maxWidth="md"
        style={{ zIndex: 1400 }}
        disableEnforceFocus
      >
        <DialogTitle id="edit-message-modal-dialogtitle" onClose={close}>
          <TitleContainer>
            <CreateIcon />
            {INBOX_EDIT_MESSAGE_TITLE}
          </TitleContainer>
        </DialogTitle>
        <DialogContent>
          <EditZoneContainer>
            <EditZone
              editorConfig={editorConfig}
              editorState={editorState}
              onChange={this.setEditorState}
              onReturn={this.onReturn}
              isSubmitting={isSubmitting}
              setSubmittingState={this.setSubmittingState}
            />
          </EditZoneContainer>
        </DialogContent>
        <DialogActions
          primary={{
            label: DONE,
            loading: saving,
            disabled:
              saving ||
              !isPersistable(editorState) ||
              isUpdating(editorState) ||
              hasError(editorState),
            onClick: this.onClick,
          }}
          secondary={{
            label: 'Cancel',
            onClick: close,
          }}
        />
      </Dialog>
    );
  }
}

EditMessageModal.propTypes = {
  close: PropTypes.func.isRequired,
  message: types.Message,
  save: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  editorConfig: PropTypes.shape({
    filesUrl: PropTypes.string,
    apiUrl: PropTypes.string,
  }).isRequired,
};

EditMessageModal.defaultProps = {
  message: undefined,
  saving: false,
};

const wrap = flow(onlyWhenDefined(['message']));

export default wrap(EditMessageModal);
