import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useEnabledLTIIntegrations = (editorConfig) => {
  const { ftLti13Enabled202207 } = useFlags();

  const filteredEditorConfig = useMemo(() => {
    if (ftLti13Enabled202207 || !editorConfig) {
      return editorConfig;
    }

    const { ltiProviders, ...restOfEditorConfig } = editorConfig;

    if (!ltiProviders || !ltiProviders.length) {
      return editorConfig;
    }

    const filteredLTIProviders = ltiProviders.filter(
      (provider) => provider.ltiVersion !== '1.3'
    );

    return {
      ...restOfEditorConfig,
      ltiProviders: filteredLTIProviders,
    };
  }, [editorConfig, ftLti13Enabled202207]);

  return filteredEditorConfig;
};

export default useEnabledLTIIntegrations;
