import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
  IconStyle,
  IconWrapper,
  AccordionWrapper,
  AccordionSummaryStyles,
  AccordionDetails,
  AccordionContent,
} from './Accordion.styled';
import { ENTER } from '../../../../utils/keyCodes';

const Accordion = ({
  dataSet,
  showDefaultExpandIcon,
  renderAccordionItem,
  AccordionTitleComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ArrowIcon = isOpen ? ArrowDropDownIcon : ArrowRightIcon;

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <AccordionWrapper
      tabIndex="0"
      onClick={handleClick}
      onKeyDown={(e) => e.keyCode === ENTER && handleClick()}
      expanded={isOpen}
    >
      <AccordionSummary
        className={AccordionSummaryStyles}
        /**
         * We're 'using an old version (3.9.4) of material UI which has a bug that causes accordions
         * to render as an empty div when styled with emotion, hence the inline styling.
         * Here's a demo on codesandbox - https://codesandbox.io/s/material-ui-accordion-bug-n98fd
         */
        style={{ padding: 0, minHeight: 0 }}
        aria-controls="participant-history-content"
        expandIcon={showDefaultExpandIcon && <ArrowDropDownIcon />}
      >
        {!showDefaultExpandIcon && (
          <IconWrapper>
            <ArrowIcon style={IconStyle} />
          </IconWrapper>
        )}
        <AccordionTitleComponent />
      </AccordionSummary>
      <AccordionDetails>
        <AccordionContent>
          {dataSet.map((data) => renderAccordionItem(data))}
        </AccordionContent>
      </AccordionDetails>
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  showDefaultExpandIcon: PropTypes.bool,
  renderAccordionItem: PropTypes.func.isRequired,
  AccordionTitleComponent: PropTypes.elementType.isRequired,
  dataSet: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
};

Accordion.defaultProps = {
  showDefaultExpandIcon: false,
};

export default Accordion;
