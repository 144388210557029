/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import debounce from 'lodash.debounce';
import ScrollBar from '../ScrollBar';
import CircularProgress from '../../CircularProgress';
import {
  Wrapper,
  TableFiller,
  TableContent,
  LoaderContainer,
  CircularProgressContainer,
} from './InfiniteTable.styled';

const LOAD_MORE_THRESHOLD = 100;
const DEFAULT_SCROLL_LISTENER_DEBOUNCE_MS = 75;
const SCROLL_LISTENER_DEBOUNCE_MAX_WAIT_MS = 500;

const debounceFunc = (func, scrollDebounceMs) => {
  const debounceTime =
    scrollDebounceMs || scrollDebounceMs === 0
      ? scrollDebounceMs
      : DEFAULT_SCROLL_LISTENER_DEBOUNCE_MS;

  return debounce(func, debounceTime, {
    maxWait: SCROLL_LISTENER_DEBOUNCE_MAX_WAIT_MS,
  });
};

const Loader = React.memo(({ loading }) => (
  <LoaderContainer data-testid="top-level-progress">
    {loading ? (
      <CircularProgressContainer>
        <CircularProgress />
      </CircularProgressContainer>
    ) : null}
  </LoaderContainer>
));

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

class InfiniteTable extends React.Component {
  constructor(props) {
    super(props);

    const { scrollDebounceMs } = this.props;
    this.state = {
      loadingPrevious: false,
      loadingNext: false,
      scroll: {},
    };

    this.onScroll = this.onScroll.bind(this);
    this.checkAndAdjustScroll = this.checkAndAdjustScroll.bind(this);
    this.debouncedOnScroll = debounceFunc(this.onScroll, scrollDebounceMs);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    if (this.scrollbars) {
      this.setState({
        scroll: { ...this.scrollbars.getValues() },
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.checkAndAdjustScroll(prevProps);
  }

  onScroll(event) {
    const { hasPrevious, hasNext, onScroll, loading } = this.props;
    const {
      loadingPrevious,
      loadingNext,
      scroll: { scrollTop: prevScrollTop },
    } = this.state;

    const { scrollTop, clientHeight, scrollHeight } = event;
    const scrollingUp = scrollTop < prevScrollTop;
    const atTop = scrollTop < LOAD_MORE_THRESHOLD;
    const shouldLoadPrevious =
      scrollingUp && atTop && hasPrevious && !loadingPrevious && !loading;
    if (shouldLoadPrevious) {
      this.loadPrevious();
    }

    const scrollingDown = scrollTop > prevScrollTop;
    const atBottom =
      scrollTop + clientHeight >= scrollHeight - LOAD_MORE_THRESHOLD;
    const shouldLoadNext =
      scrollingDown && atBottom && hasNext && !loadingNext && !loading;
    if (shouldLoadNext) {
      this.loadNext();
    }

    this.setState({
      scroll: { ...event },
    });
    onScroll(event);
  }

  getScrollValues() {
    return this.scrollbars.getValues();
  }

  scrollToBottom() {
    this.scrollbars.scrollToBottom();
  }

  scrollTop(top) {
    this.scrollbars.scrollTop(top);
  }

  async loadPrevious() {
    const { loadPrevious } = this.props;
    this.setState({ loadingPrevious: true });
    await loadPrevious();
    this.setState({ loadingPrevious: false });
  }

  async loadNext() {
    const { loadNext } = this.props;
    this.setState({ loadingNext: true });
    await loadNext();
    this.setState({ loadingNext: false });
  }

  checkAndAdjustScroll(prevProps) {
    const { items, keyExtractor, reversed } = this.props;
    const { items: prevItems } = prevProps;

    const numItems = items.length;
    const prevNumItems = prevItems.length;

    if (numItems === 0 || prevNumItems === 0 || !reversed) {
      return;
    }

    const itemsChanged = items !== prevItems;
    if (!itemsChanged) {
      return;
    }

    const {
      scroll: { scrollTop, scrollHeight },
    } = this.state;

    const firstKey = keyExtractor(items[0]);
    const prevFirstKey = keyExtractor(prevItems[0]);
    const addedAtTop = firstKey !== prevFirstKey;
    if (addedAtTop) {
      const { scrollHeight: newScrollHeight } = this.scrollbars.getValues();
      this.scrollbars.scrollTop(scrollTop + newScrollHeight - scrollHeight);
    }
  }

  renderItem(item, index) {
    const { renderItem, keyExtractor } = this.props;
    const key = keyExtractor(item);
    const renderedItem = renderItem(item, index);
    return React.cloneElement(renderedItem, { key, id: key });
  }

  render() {
    const {
      hasPrevious,
      hasSidebar,
      hasNext,
      reversed,
      loading,
      items,
      renderEmptyList,
      renderListStart,
      renderFooter,
      renderSidebar,
      renderHeader,
      viewStyle,
      contentStyle,
      scrollBarStyle,
      attributes,
      resetScrollOnMount,
      animateItems,
      viewProps,
    } = this.props;
    const { loadingPrevious, loadingNext } = this.state;

    const isEmpty = items.length === 0;

    const renderedItems = items.map(this.renderItem);
    const wrappedItems = animateItems ? (
      <FlipMove
        enterAnimation={animateItems.enter}
        leaveAnimation={animateItems.leave}
      >
        {renderedItems}
      </FlipMove>
    ) : (
      renderedItems
    );

    const topLoaderVisible = hasPrevious || (reversed && loading);
    const topLoaderLoading = loadingPrevious || loading;
    const noContentVisible =
      isEmpty && !loading && !loadingNext && !loadingPrevious;
    const bottomLoaderLoading = loadingNext || (loading && !topLoaderVisible);

    const renderTableContent = () => (
      <TableContent style={contentStyle}>
        {renderHeader()}
        {!hasPrevious && renderListStart()}
        {topLoaderVisible && <Loader loading={topLoaderLoading} />}
        {noContentVisible && renderEmptyList()}
        {wrappedItems}
        {hasNext && <Loader loading={bottomLoaderLoading} />}
        {renderFooter()}
      </TableContent>
    );

    return (
      <ScrollBar
        scrollBarRef={(scrollbars) => {
          this.scrollbars = scrollbars;
        }}
        autoHide
        onScrollFrame={this.debouncedOnScroll}
        viewProps={{
          style: {
            ...viewStyle,
            display: 'flex',
            flexDirection: 'column',
          },
          ...viewProps,
        }}
        resetScrollOnMount={resetScrollOnMount}
        attributes={attributes}
        style={scrollBarStyle}
      >
        {hasSidebar ? (
          <Wrapper>
            {renderTableContent()}
            {renderSidebar()}
          </Wrapper>
        ) : (
          <>
            {reversed && <TableFiller />}
            {renderTableContent()}
          </>
        )}
      </ScrollBar>
    );
  }
}

InfiniteTable.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  keyExtractor: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool,
  hasSidebar: PropTypes.bool,
  hasNext: PropTypes.bool,
  loadPrevious: PropTypes.func,
  loadNext: PropTypes.func,
  loading: PropTypes.bool,
  reversed: PropTypes.bool,
  renderEmptyList: PropTypes.func,
  renderListStart: PropTypes.func,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
  renderSidebar: PropTypes.func,
  viewStyle: PropTypes.shape({}),
  contentStyle: PropTypes.shape({}),
  scrollBarStyle: PropTypes.shape({}),
  attributes: PropTypes.object,
  resetScrollOnMount: PropTypes.bool,
  onScroll: PropTypes.func,
  animateItems: PropTypes.shape({
    enter: PropTypes.string,
    leave: PropTypes.string,
  }),
  viewProps: PropTypes.object,
  scrollDebounceMs: PropTypes.number,
};

InfiniteTable.defaultProps = {
  hasPrevious: false,
  hasSidebar: false,
  hasNext: false,
  reversed: false,
  loading: false,
  renderEmptyList: () => {},
  renderListStart: () => {},
  renderHeader: () => {},
  renderFooter: () => {},
  renderSidebar: () => {},
  loadPrevious: () => {},
  loadNext: () => {},
  viewStyle: {},
  contentStyle: {},
  scrollBarStyle: {},
  attributes: {},
  resetScrollOnMount: false,
  onScroll: () => {},
  animateItems: null,
  viewProps: {},
  scrollDebounceMs: undefined,
};

export default InfiniteTable;
