import { connect } from 'react-redux';
import { cleanState } from '@ublend-npm/aula-editor';

import { operations } from '../../../../../store/materials';
import * as appOperations from '../../../../../actions/materials';
import AddSectionButton from './AddSectionButton';

const mapsDispatchToProps = (dispatch, ownProps) => ({
  addSection: () =>
    dispatch(
      operations.createSection({
        content: cleanState,
        parent: ownProps.folderId,
        educatorOnly: ownProps.educatorOnly,
        hidden: !ownProps.educatorOnly,
        onSelect: appOperations.selectSectionInJourney,
      })
    ),
  onDrop: (sourceId) =>
    dispatch(
      operations.reorderMaterial({
        sourceId,
        parentId: ownProps.folderId,
        dropType: 'after',
      })
    ),
});

export default connect(null, mapsDispatchToProps)(AddSectionButton);
