/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { hashHistory } from 'react-router';
import React from 'react';
import {
  theme,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from '@ublend-npm/aulaui-next';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import TagFilter from '../../../common/TagFilter';
import tableStyle, { ImportantPosts, Wrapper, FeedChipSelector } from './style';
import CreatePost from '../CreatePost/CreatePost.container';
import InfiniteTable from '../../../common/InfiniteTable';
import PostCell from './PostCell/PostCell';
import SeparatorWithText from './SeparatorWithText/SeparatorWithText';
import SchedulePostDialog from '../SchedulePostDialog';
import PinIcon from '../PinIcon/PinIcon';

import withErrorBoundary from '../../../common/ErrorBoundary/ErrorBoundary';
import {
  ERROR_FEEDS,
  ERROR_POST,
  OTHER_POSTS,
} from '../../../../../constants/texts';
import withSuspense from '../../../common/Suspense/Suspense';

const SideBar = withSuspense(React.lazy(() => import('./PinnedPosts/SideBar')));
const NoPost = withSuspense(React.lazy(() => import('../NoPost/NoPost')));
const ScheduledPostsSummary = withSuspense(
  React.lazy(() => import('./ScheduledPostsSummary'))
);

const MemoizedPostCell = React.memo(
  withErrorBoundary(PostCell, { message: ERROR_POST })
);
class ClassRoomPostsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.loadNextPage = this.loadNextPage.bind(this);
    this.handleRouting = this.handleRouting.bind(this);

    this.readClassRoom = '';
  }

  componentDidMount() {
    const {
      classRoom,
      setRead,
      params: { classId },
    } = this.props;
    if (
      classRoom &&
      classRoom.unread.post &&
      this.readClassRoom !== classRoom.objectId
    ) {
      this.readClassRoom = classRoom.objectId;
      setRead(classRoom.objectId).then(() => {
        this.readClassRoom = '';
      });
    }
    this.updateDocumentTitle();
    if (classId === classRoom?.objectId) {
      this.handleRouting();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { setRead, isConversationOpen } = this.props;
    if (
      nextProps.classRoom &&
      nextProps.classRoom.unread.post &&
      this.readClassRoom !== nextProps.classRoom.objectId
    ) {
      this.readClassRoom = nextProps.classRoom.objectId;
      setRead(nextProps.classRoom.objectId).then(() => {
        this.readClassRoom = '';
      });
    }
    const announcementsSelected = nextProps.selectedFeedType === ANNOUNCEMENTS;
    const nextPropsPosts = announcementsSelected
      ? nextProps.announcementPosts
      : nextProps.commonRoomPosts;

    // Only start loading posts if we don't have a conversation open to reduce initial load time.
    if (
      isConversationOpen &&
      !nextProps.isConversationOpen &&
      !nextPropsPosts?.length
    ) {
      const { fetchNextPosts, classRoom, selectedTag, selectedFeedType } =
        nextProps;
      // this will fetch the initial posts since we have none at this point.
      fetchNextPosts({
        classRoom,
        tagId: selectedTag,
        feedType: selectedFeedType,
      });
    }
  }

  componentDidUpdate() {
    this.updateDocumentTitle();
    this.handleRouting();
  }

  updateDocumentTitle() {
    const { classRoom } = this.props;
    const spaceName =
      classRoom && classRoom.name ? `Feed - ${classRoom.name} - ` : '';
    document.title = `${spaceName}Aula`;
  }

  handleRouting() {
    const {
      selectedFeedType,
      classRoom,
      announcementPosts,
      goToAnnouncements,
      goToCommonRoom,
      hasFinishedFetchingPosts,
      selectedTag,
    } = this.props;

    if (!classRoom || !hasFinishedFetchingPosts) {
      return;
    }

    const hashCurrentLocation = hashHistory.getCurrentLocation();
    const hashPathName = hashCurrentLocation.pathname;
    const clearTagIdQueryParam =
      !selectedTag && !!hashCurrentLocation.query?.tagId;

    if (clearTagIdQueryParam) {
      hashHistory.push({
        pathname: hashPathName,
        query: undefined,
      });
    }

    const requireNavigation =
      !selectedFeedType || hashPathName.includes('/community/feed');
    if (!requireNavigation) {
      return;
    }

    const tagIdQueryParam = selectedTag ? { tagId: selectedTag } : undefined;
    const shouldViewAnnouncements =
      (!selectedFeedType && announcementPosts?.length > 0) ||
      (hashPathName.includes('/community/feed') &&
        selectedFeedType === ANNOUNCEMENTS);
    if (shouldViewAnnouncements) {
      goToAnnouncements(classRoom.objectId, tagIdQueryParam);
    } else {
      goToCommonRoom(classRoom.objectId, tagIdQueryParam);
    }
  }

  loadNextPage(classRoom) {
    const {
      fetchNextPosts,
      classRoom: propsClassRoom,
      selectedTag,
      selectedFeedType,
    } = this.props;
    return fetchNextPosts({
      classRoom: classRoom || propsClassRoom,
      tagId: selectedTag,
      feedType: selectedFeedType,
    });
  }

  renderFilter() {
    const {
      tags,
      isEducator,
      selectedTag,
      selectedFeedType,
      filterByTag,
      classRoom,
      goToAnnouncements,
      goToCommonRoom,
    } = this.props;
    const tagList = Object.keys(tags).map((tagId) => ({
      key: tags[tagId].objectId,
      label: tags[tagId].name,
    }));

    if (!tagList.length) {
      return null;
    }

    return (
      <TagFilter
        showSuggestion={isEducator}
        tagList={tagList}
        selectedTag={selectedTag}
        onChange={(tagId) => {
          filterByTag(tagId);
          if (selectedFeedType === ANNOUNCEMENTS) {
            goToAnnouncements(classRoom.objectId, { tagId });
          } else {
            goToCommonRoom(classRoom.objectId, { tagId });
          }
        }}
      />
    );
  }

  renderMutedInfo() {
    const { spaceIsMuted } = this.props;
    if (!spaceIsMuted) {
      return false;
    }

    return (
      <div style={tableStyle.muteInfoContainer}>
        <div style={tableStyle.iconContainer}>
          <VolumeOffIcon style={{ color: theme.color.grey11 }} />
        </div>
        <span>Students are muted</span>
      </div>
    );
  }

  renderMenu() {
    const { spaceIsMuted, toggleMuteSpace, classRoom } = this.props;

    const items = [
      {
        key: 'mute',
        label: spaceIsMuted
          ? 'Unmute posts by students in this space'
          : 'Mute posts by students in this space',
        Icon: spaceIsMuted ? VolumeUpIcon : VolumeOffIcon,
        handler: toggleMuteSpace,
      },
    ];

    const spaceId = classRoom ? classRoom.objectId : '';

    return (
      <Menu
        menuListComponent="ul"
        menuListAttributes={{ role: 'none presentation' }}
        ariaLabelledby="menu_feed"
        renderTarget={({ handleTargetClick }) => (
          <Tooltip title="Feed menu" position="bottom">
            <div>
              <IconButton
                icon={MoreVertIcon}
                size="small"
                type="secondary"
                label="Feed menu"
                onClick={handleTargetClick}
              />
            </div>
          </Tooltip>
        )}
        onSelect={({ item }) => item()}
        renderChildren={({ getItemProps }) =>
          items.map(({ key, label, Icon, handler }) => (
            <MenuItem
              key={key}
              size="medium"
              leftDecoration={<Icon style={{ color: theme.color.grey11 }} />}
              primaryText={label}
              {...getItemProps({
                item: handler.bind(null, spaceId),
                onClick: (e) => e.stopPropagation(),
              })}
            />
          ))
        }
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      />
    );
  }

  renderImportantPostsMenu({ style } = {}) {
    const { recentImportantPostsCount } = this.props;

    if (!recentImportantPostsCount) {
      return null;
    }

    return (
      <ImportantPosts style={style}>
        <PinIcon />
        <span>
          {`${recentImportantPostsCount} recent pinned post${
            recentImportantPostsCount > 1 ? 's' : ''
          }`}
        </span>
      </ImportantPosts>
    );
  }

  render() {
    const {
      announcementPosts,
      commonRoomPosts,
      recentImportantPostsCount,
      recentImportantPosts,
      classRoom,
      hasFetchedAll,
      fetching,
      currentUserId,
      isReadOnlyUser,
      isEducator,
      importantPostsCount,
      importantPosts,
      openImportantPosts,
      scheduledPostCount,
      isChangingClassRoom,
      tags,
      isConversationOpen,
      selectedFeedType,
      goToAnnouncements,
      goToCommonRoom,
      selectedTag,
      hasFinishedFetchingPosts,
    } = this.props;

    const shouldShowSidebar = importantPostsCount && !isChangingClassRoom;
    const announcementsSelected = selectedFeedType === ANNOUNCEMENTS;
    const posts = announcementsSelected ? announcementPosts : commonRoomPosts;
    const postCount = posts.length;
    const tagsCount = !!Object.keys(tags).length;
    const separatorIndex = recentImportantPostsCount - 1;
    const combinedPosts = !isChangingClassRoom
      ? [...recentImportantPosts, ...posts]
      : [];
    const shouldRenderEmptyList = !(
      postCount ||
      scheduledPostCount ||
      recentImportantPostsCount ||
      fetching ||
      selectedTag ||
      !selectedFeedType
    );

    if (isConversationOpen) {
      return null;
    }

    const chipSelectorOptions = [
      { id: ANNOUNCEMENTS, label: 'Announcements' },
      { id: COMMON_ROOM, label: 'Common room' },
    ];

    const onChipSelected = (chipId) => {
      // don't do anything if chip is already selected
      if (chipId === selectedFeedType) {
        return;
      }
      const tagIdQueryParam = selectedTag ? { tagId: selectedTag } : undefined;
      if (chipId === ANNOUNCEMENTS) {
        goToAnnouncements(classRoom.objectId, tagIdQueryParam);
      } else {
        goToCommonRoom(classRoom.objectId, tagIdQueryParam);
      }
    };

    const canCreatePost =
      !isReadOnlyUser &&
      selectedFeedType &&
      (selectedFeedType === COMMON_ROOM || isEducator);

    const isLoading = fetching || !hasFinishedFetchingPosts;

    return (
      <Wrapper data-testid={`feed-${classRoom && classRoom.objectId}`}>
        <FeedChipSelector
          options={chipSelectorOptions}
          selectedChipId={selectedFeedType}
          onChipSelected={onChipSelected}
        />
        <InfiniteTable
          items={combinedPosts}
          renderItem={(post, index) => {
            const hasSeparator =
              recentImportantPostsCount &&
              postCount &&
              index === separatorIndex;

            return (
              <div key={`post-item-${post.objectId}`}>
                {index === 0 &&
                  !isLoading &&
                  !!scheduledPostCount &&
                  this.renderImportantPostsMenu({
                    style: tableStyle.recentImportantPostsText({
                      inFilterContainer: false,
                    }),
                  })}
                <MemoizedPostCell
                  post={post}
                  classId={classRoom.objectId}
                  isRecentImportant={recentImportantPosts.some(
                    (pinnedPost) => pinnedPost.objectId === post.objectId
                  )}
                  style={tableStyle.postCell({ hasSeparator })}
                />
                {hasSeparator ? (
                  <SeparatorWithText
                    style={tableStyle.separator}
                    text={OTHER_POSTS}
                  />
                ) : null}
              </div>
            );
          }}
          keyExtractor={(post) => post.objectId}
          hasNext={!hasFetchedAll}
          loadNext={this.loadNextPage}
          loading={isLoading}
          viewStyle={tableStyle.container}
          contentStyle={tableStyle.table}
          scrollBarStyle={tableStyle.scrollBar}
          attributes={{ 'data-testid': 'posts-scroll-section' }}
          renderEmptyList={() =>
            shouldRenderEmptyList ? (
              <NoPost userId={currentUserId} feedType={selectedFeedType} />
            ) : null
          }
          renderHeader={() => (
            <React.Fragment>
              {canCreatePost && <CreatePost feedType={selectedFeedType} />}
              {!shouldRenderEmptyList && (
                <div style={tableStyle.filterRow({ fetching })}>
                  <div style={tableStyle.filterContainer}>
                    {(!!combinedPosts.length || !!selectedTag) &&
                      this.renderFilter()}
                    {!isLoading &&
                      !scheduledPostCount &&
                      this.renderImportantPostsMenu({
                        style: tableStyle.recentImportantPostsText({
                          inFilterContainer: true,
                          postCount,
                          tagsCount,
                          recentImportantPostsCount,
                          scheduledPostCount,
                        }),
                      })}
                  </div>
                  {!isReadOnlyUser && selectedFeedType === COMMON_ROOM && (
                    <div style={tableStyle.menuContainer}>
                      {this.renderMutedInfo()}
                      {this.renderMenu()}
                    </div>
                  )}
                </div>
              )}
              {!isLoading && scheduledPostCount >= 1 && (
                <ScheduledPostsSummary
                  style={tableStyle.scheduledPosts({
                    recentImportantPostsCount,
                    scheduledPostCount,
                  })}
                />
              )}
              <SchedulePostDialog />
            </React.Fragment>
          )}
          hasSidebar={Boolean(shouldShowSidebar)}
          renderSidebar={() =>
            shouldShowSidebar && (
              <SideBar
                spaceId={classRoom.objectId}
                importantPosts={importantPosts}
                importantPostsCount={importantPostsCount}
                openImportantPosts={openImportantPosts}
              />
            )
          }
          resetScrollOnMount
          animateItems={{
            enter: 'fade',
            leave: 'none',
          }}
        />
      </Wrapper>
    );
  }
}

ClassRoomPostsTable.propTypes = {
  currentUserId: PropTypes.string,
  commonRoomPosts: PropTypes.arrayOf(PropTypes.object),
  announcementPosts: PropTypes.arrayOf(PropTypes.object),
  importantPosts: PropTypes.arrayOf(PropTypes.object),
  recentImportantPosts: PropTypes.arrayOf(PropTypes.object),
  recentImportantPostsCount: PropTypes.number,
  scheduledPostCount: PropTypes.number,
  isEducator: PropTypes.bool,
  tags: PropTypes.shape({}).isRequired,
  filterByTag: PropTypes.func.isRequired,
  selectedTag: PropTypes.string,
  classRoom: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  fetchNextPosts: PropTypes.func.isRequired,
  setRead: PropTypes.func.isRequired,
  hasFetchedAll: PropTypes.bool,
  hasFinishedFetchingPosts: PropTypes.bool,
  spaceIsMuted: PropTypes.bool,
  toggleMuteSpace: PropTypes.func.isRequired,
  openImportantPosts: PropTypes.func.isRequired,
  importantPostsCount: PropTypes.number,
  isReadOnlyUser: PropTypes.bool,
  isChangingClassRoom: PropTypes.bool,
  isConversationOpen: PropTypes.bool,
  selectedFeedType: PropTypes.oneOf([ANNOUNCEMENTS, COMMON_ROOM]),
  goToAnnouncements: PropTypes.func.isRequired,
  goToCommonRoom: PropTypes.func.isRequired,
  params: PropTypes.shape({ classId: PropTypes.string }).isRequired,
};

ClassRoomPostsTable.defaultProps = {
  currentUserId: '',
  commonRoomPosts: [],
  announcementPosts: [],
  importantPosts: [],
  recentImportantPosts: [],
  importantPostsCount: 0,
  recentImportantPostsCount: 0,
  scheduledPostCount: 0,
  selectedTag: null,
  classRoom: null,
  isEducator: false,
  hasFetchedAll: false,
  spaceIsMuted: false,
  isReadOnlyUser: true,
  isChangingClassRoom: false,
  isConversationOpen: false,
  hasFinishedFetchingPosts: false,
  selectedFeedType: null,
};

export default withErrorBoundary(ClassRoomPostsTable, { message: ERROR_FEEDS });
