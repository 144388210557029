import React from 'react';
import { Assignment } from '@core/types/state';
import Provider from '../Provider';
import { SubmissionDetails, FirstRow } from './AssignmentDetails.styled';

type SubmissionsCountRowProps = {
  extensionName?: string;
  submissionCount: number;
  totalStudentsCount: number;
  assignment: Assignment;
  showProvider: boolean;
};

const SubmissionsCountRow = ({
  extensionName,
  submissionCount,
  totalStudentsCount,
  assignment,
  showProvider,
}: SubmissionsCountRowProps) => {
  const submissionCountVsTotal =
    totalStudentsCount === 0
      ? '0 students'
      : `${submissionCount}/${totalStudentsCount} submissions`;

  return (
    <FirstRow>
      {extensionName ? (
        <SubmissionDetails data-testid="extension-name">
          {extensionName}
          <span>{` (${submissionCountVsTotal})`}</span>
        </SubmissionDetails>
      ) : (
        <SubmissionDetails>{submissionCountVsTotal}</SubmissionDetails>
      )}
      {showProvider && <Provider assignment={assignment} />}
    </FirstRow>
  );
};

export default SubmissionsCountRow;
