import React, { useRef, useState, useCallback, useMemo } from 'react';

// HELPERS
import { cleanState, isPersistable, isUpdating } from '@ublend-npm/aula-editor';
import useCreateComment from '@core/comments/hooks/useCreateComment';
import uuid from '@core/utils/uuid';
import { API_URL, FILE_URL } from '@core/constants/endpoints';
import { CommentEntityType } from '@core/utils/commentsEntityTypes';
import { COMMENT_ITEM_TYPE } from '@core/constants/analytics';
import { LtiProvider } from '@ublend-npm/aula-schema';
import isEmptyContent from '../../ClassRoom/Feed/isEmptyContent';
import { User } from '../types';

// COMPONENTS
import Avatar from '../../../containers/User/Avatar/Avatar';

import useEnabledLTIIntegrations from '../../../../hooks/useEnabledLTIIntegrations';

// STYLES
import { Wrapper, EditorWrapper, AvatarWrapper } from './styles';

type CreateCommentProps = {
  entityId: string;
  entityType: CommentEntityType;
  isCreatingComment: boolean;
  EditorComponent: Function;
  user: User;
  institution: string;
  accessToken: string;
  spaceId: string;
  didComment?: () => void;
  parent?: string;
  root?: string;
  autoFocus?: boolean;
  ltiProviders?: Array<LtiProvider>;
  isEducator: boolean;
};

const CreateComment = ({
  entityId,
  entityType,
  accessToken,
  spaceId,
  institution,
  isCreatingComment,
  isEducator,
  didComment = () => {},
  user,
  parent = undefined,
  root = undefined,
  autoFocus = false,
  ltiProviders,
  EditorComponent,
}: CreateCommentProps) => {
  const editorRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editorState, setEditorState] = useState(cleanState);
  const [editorKey, setEditorKey] = useState(uuid());

  const { mutate: createComment } = useCreateComment(
    entityType,
    entityId,
    () => {
      setEditorState(cleanState);
      setEditorKey(uuid());
      didComment();
    }
  );

  const editorConfig = useMemo(
    () => ({
      filesUrl: FILE_URL(),
      apiUrl: API_URL(),
      accessToken,
      spaceId,
      institution,
      userId: user.objectId,
      ltiProviders,
      itemType: COMMENT_ITEM_TYPE,
      isEducator,
    }),
    [accessToken, ltiProviders]
  );

  const filteredEditorConfig = useEnabledLTIIntegrations(editorConfig);

  const handleEditorChange = useCallback((currentEditorState) => {
    setEditorState(currentEditorState);
  }, []);

  const handleCreateComment = () => {
    createComment({
      content: JSON.stringify(editorState),
      parent,
      root,
      entityId,
      entityType,
    });
  };

  const loading = isCreatingComment || isSubmitting;

  const EditorActionButtonProps = {
    id: 'add-comment',
    label: 'Comment',
    disabled:
      isEmptyContent(editorState) ||
      !isPersistable(editorState) ||
      isUpdating(editorState),
    hideWhenBlurred: true,
    onClick: handleCreateComment,
  };

  return (
    <Wrapper>
      <AvatarWrapper>
        <Avatar size={30} user={user} />
      </AvatarWrapper>
      <EditorWrapper>
        <EditorComponent
          key={editorKey}
          ref={editorRef}
          config={filteredEditorConfig}
          editorState={editorState}
          placeholder="Write a comment..."
          onChange={handleEditorChange}
          isSubmitting={loading}
          setSubmittingState={setIsSubmitting}
          readOnly={isCreatingComment}
          actionButton={EditorActionButtonProps}
          type="comment"
          autoFocus={autoFocus}
          isAssignmentsEnabled
        />
      </EditorWrapper>
    </Wrapper>
  );
};

export default CreateComment;
