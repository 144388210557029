import React from 'react';
import PropTypes from 'prop-types';

import User from '../User/User';
import { SpaceUserType } from '../../ExtensionModal.types';

const UsersList = ({
  users,
  onActionClick,
  actionIcon,
  tooltip,
  selectedUsers = [],
  disabledText,
}) => {
  return users.map((user) => {
    const { id, fullName, avatar, email, userActionDisabled } = user;

    const isAdded = selectedUsers.find(
      (selectedUser) => selectedUser.id === id
    );

    return (
      <User
        key={id}
        onActionClick={
          (!userActionDisabled && (() => onActionClick(user))) || undefined
        }
        actionIcon={!isAdded && actionIcon}
        tooltip={tooltip}
        avatar={avatar}
        email={email}
        isReadOnly={isAdded}
        userAdded={isAdded}
        fullName={fullName}
        isDisabled={userActionDisabled}
        disabledText={userActionDisabled ? disabledText : undefined}
      />
    );
  });
};

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
  selectedUsers: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
  actionIcon: PropTypes.elementType,
  tooltip: PropTypes.string,
  onActionClick: PropTypes.func,
  disabledText: PropTypes.string,
};

UsersList.defaultProps = {
  users: [],
  selectedUsers: [],
  actionIcon: null,
  tooltip: '',
  onActionClick: () => {},
  disabledText: '',
};

export default React.memo(UsersList);
