/* eslint-disable no-undef */
import { connect, MapDispatchToProps } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getScheduledPostsCount } from '@core/selectors/space';
import {
  openScheduledAnnouncements,
  openScheduledCommonRoomPosts,
} from '@app/actions/navigation/openScheduledPosts';
import { FeedType } from '@app/types/core';
import { selectedFeedType } from '@core/selectors/classroom';
import { ANNOUNCEMENTS } from '@core/constants/postFeedType';
import { MapStateToProps } from '@core/types/state';
import ViewScheduledPostButton from './ViewScheduledPostButton';

interface PostOwnProps {}

interface PropsFromState {
  count: number;
  feedType: FeedType | undefined;
}

const mapStateToProps: MapStateToProps<PropsFromState, PostOwnProps> = (
  state
) => {
  const feedType = selectedFeedType(state);
  const count = getScheduledPostsCount(state, feedType);
  return {
    count,
    feedType,
  };
};

interface PropsFromDispatch {
  openScheduledPosts: (feedType: FeedType) => void;
}

const mapDispatchToProps: MapDispatchToProps<
  PropsFromDispatch,
  PostOwnProps
> = (dispatch: ThunkDispatch<unknown, unknown, Action>) => ({
  openScheduledPosts: (feedType: FeedType) => {
    if (feedType === ANNOUNCEMENTS) {
      dispatch(openScheduledAnnouncements());
    } else {
      dispatch(openScheduledCommonRoomPosts());
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewScheduledPostButton);
