import { useMutation } from 'react-query';
import request from '../request';

/**
 * Thin wrapper over useMutation which adds our authentication headers.
 *
 * It also converts responses with status >= 400 to errors for react-query.
 *
 *
 * e.g. Using the sync API
 * ```
 * const { mutate: startPresentation, isLoading, data } = useMutateApi({ url: 'valid_url', method: 'POST' });
 *
 * const onStartClick = () => {
 *   const startPresentationPayload = {};
 *   startPresentation(startPresentationPayload);
 *   // the component will rerender and `isLoading` will be true until the request finishes
 * }
 *
 * // you can use the `data` here if needed
 * ```
 *
 * e.g. Using the async API
 * ```
 * const { mutateAsync: startPresentation } = useMutateApi({ url: 'valid_url', method: 'POST' });
 *
 * const onStartClick = () => {
 *   const startPresentationPayload = {};
 *   const data = await startPresentation(startPresentationPayload);
 *   // at this point the request is done and you can use data here directly
 *   // if data is undefined the request has an error.
 *   // there are also a bunch of options we can play with (e.g. throwOnError)
 * }
 * ```
 *
 * @param config                - axios configuration object
 * @param useMutationConfig     - useMutation configuration object
 * @returns {UseMutationResult} - response coming from useMutation
 */
const useMutateApi = (config, useMutationConfig) =>
  useMutation(async (data) => {
    const response = await request({
      ...config,
      data,
    });

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    return response.data;
  }, useMutationConfig);

export default useMutateApi;
