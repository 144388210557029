import React from 'react';
import PropTypes from 'prop-types';

import { Separator } from '@ublend-npm/aulaui-next';

import {
  SeparatorWithTextWrapper,
  SeparatorText,
  separatorClassName,
} from './style';

const CustomSeparator = () => <Separator className={separatorClassName} />;

const SeparatorWithText = ({ text, style }) => {
  return (
    <SeparatorWithTextWrapper style={style}>
      <CustomSeparator />
      {text && (
        <>
          <SeparatorText>{text}</SeparatorText>
          <CustomSeparator />
        </>
      )}
    </SeparatorWithTextWrapper>
  );
};

SeparatorWithText.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
};

SeparatorWithText.defaultProps = {
  text: undefined,
  style: {},
};

export default SeparatorWithText;
