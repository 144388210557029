import React, { useState } from 'react';

import DiscussionDetailsCard from './DiscussionDetailsCard/DiscussionDetailsCard';
import DiscussionTopicDialog from './DiscussionTopicDialog/DiscussionTopicDialog';

type EditDiscussionProps = {
  title?: string;
  showDiscussion?: boolean;
  hasComments?: boolean;
  onDiscussionSave: Function;
};

const EditDiscussion = ({
  title,
  showDiscussion,
  hasComments,
  onDiscussionSave,
}: EditDiscussionProps) => {
  const [openDiscussionTopicModal, setOpenDiscussionTopicModal] =
    useState(false);

  const onSetDiscussionTopic = (topic: string) => {
    onDiscussionSave({
      discussionTopic: topic,
      showDiscussion: true,
    });
    setOpenDiscussionTopicModal(false);
  };

  const onRemoveDiscussion = () => {
    onDiscussionSave({
      discussionTopic: null,
      showDiscussion: false,
    });
    setOpenDiscussionTopicModal(false);
  };

  const onToggleShowDiscussion = (
    shouldShowDiscussion: boolean,
    topic: string
  ) => {
    onDiscussionSave({
      discussionTopic: topic,
      showDiscussion: shouldShowDiscussion,
    });
    setOpenDiscussionTopicModal(false);
  };

  const discussionTopic = hasComments && !title ? 'Discussion' : title;

  return (
    <>
      <DiscussionDetailsCard
        openDiscussionTopicModal={() => setOpenDiscussionTopicModal(true)}
        discussionTopic={discussionTopic}
        showDiscussion={showDiscussion}
      />
      <DiscussionTopicDialog
        open={openDiscussionTopicModal}
        onClose={() => setOpenDiscussionTopicModal(false)}
        onSetDiscussionTopic={onSetDiscussionTopic}
        initialTopic={discussionTopic}
        canRemoveDiscussion={(title && !hasComments) as boolean}
        onRemoveDiscussion={onRemoveDiscussion}
        showDiscussion={showDiscussion}
        onToggleShowDiscussion={onToggleShowDiscussion}
      />
    </>
  );
};

EditDiscussion.defaultProps = {
  title: undefined,
  showDiscussion: false,
  hasComments: false,
  onDiscussionSave: () => {},
};

export default EditDiscussion;
