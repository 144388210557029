import styled from 'react-emotion';

const DEFAULT_BG = 'rgba(49, 64, 92, 0.08)';
const DEFAULT_COLOR = '#31405C';

const StyledLabel = styled.span`
  border-radius: 4px;
  height: 20px;
  padding: 2px 8px;

  font-size: 12px;
  font-weight: normal;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: ${({ background }) => background || DEFAULT_BG};
  color: ${({ color }) => color || DEFAULT_COLOR};
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 'none'};
  cursor: default;
`;

export default StyledLabel;
