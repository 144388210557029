import styled from 'react-emotion';

export const Container = styled('div')`
  display: flex;
  flex: 1;
`;

export const Content = styled('div')`
  flex-grow: 1;
  position: relative;
`;
