import styled from 'react-emotion';
import { Info as BaseInfo } from '@mui/icons-material';

export const Container = styled('div')`
  background: #edf5ff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const Info = styled(BaseInfo)`
  padding-right: 6px;
  padding-left: 12px;
  margin-bottom: -6px;
`;

export const Text = styled('span')`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;
