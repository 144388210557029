import React from 'react';
import { Loader } from './ConversationModalParticipantsLoader.styled';

const ConversationModalParticipantsLoader = () => (
  <React.Fragment>
    <Loader opacity=".4" />
    <Loader opacity=".6" />
    <Loader opacity=".8" />
  </React.Fragment>
);

export default ConversationModalParticipantsLoader;
