const closeEnough = (ms, iso1, iso2) => {
  const t1 = new Date(iso1).getTime();
  const t2 = new Date(iso2).getTime();
  return t2 - t1 < ms;
};

const reducer = (acc, curr, index, initialArray) => {
  const previous = index ? initialArray[index - 1] : undefined;
  const sameUser = previous && previous.writer === curr.writer;
  const quickEnough =
    previous && closeEnough(60000, previous.createdAt, curr.createdAt);
  const currentOrPreviousEdited =
    (previous && previous.editedAt) || curr.editedAt;

  if (previous && sameUser && quickEnough && !currentOrPreviousEdited) {
    acc[acc.length - 1].messages.push(curr.id);
  } else {
    acc.push({
      author: curr.writer,
      createdAt: curr.createdAt,
      messages: [curr.id],
    });
  }
  return acc;
};

const groupMessages = (messages) => messages.reduce(reducer, []);

export default groupMessages;
