import styled from 'react-emotion';

export const EditorWrapper = styled('div')`
  .RichEditor-editor {
    margin-bottom: 0 !important;
  }

  .DraftEditor-root {
    width: 100% !important;
    margin-left: 0px !important;
  }
`;

export const ResultText = styled.span`
  line-height: 1.5;
  word-break: break-word;
`;
