import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, theme, Tooltip } from '@ublend-npm/aulaui-next';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { AssignmentConversion } from './Submissions.styled';
import assignmentPropTypes from '../Assignments/utils/assignment.proptype';
import filterDestinationAssignmentsForConversion from './filterDestinationAssignmentsForConversion';

const ConversionSection = ({
  hasExtensions,
  assignment,
  assignments,
  setIsConvertingExtension,
}) => {
  if (!assignment) {
    return null;
  }
  const isConversionToExtensionPermitted =
    !hasExtensions && !assignment.groupSet && !assignment.isAnonymised;

  const availableDestinationAssignments =
    filterDestinationAssignmentsForConversion(assignments, assignment);
  const hasAvailableDestinationAssignments = !!(
    availableDestinationAssignments.length > 0
  );

  const iconColor = hasAvailableDestinationAssignments
    ? theme.color.designSystem.blackOpacity60
    : theme.color.designSystem.blackOpacity38;

  const handleClick = () =>
    hasAvailableDestinationAssignments
      ? setIsConvertingExtension(true)
      : setIsConvertingExtension(false);

  const tooltipTitle = hasAvailableDestinationAssignments ? (
    <span>
      <div>
        Turn into an extension
        <div>of another assignment</div>
      </div>
    </span>
  ) : (
    <div>There are no destination assignments available</div>
  );

  return (
    <>
      {isConversionToExtensionPermitted && (
        <span>
          <Tooltip title={tooltipTitle} position="top">
            <AssignmentConversion>
              <IconButton
                icon={() => <CompareArrowsIcon style={{ color: iconColor }} />}
                size="small"
                type="secondary"
                label="conversion"
                onClick={handleClick}
              />
            </AssignmentConversion>
          </Tooltip>
        </span>
      )}
    </>
  );
};

ConversionSection.propTypes = {
  hasExtensions: PropTypes.bool.isRequired,
  assignment: PropTypes.oneOfType([PropTypes.shape({}), assignmentPropTypes]),
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIsConvertingExtension: PropTypes.func.isRequired,
};

ConversionSection.defaultProps = {
  assignment: null,
};

export default ConversionSection;
