/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@ublend-npm/aulaui-next';
import axios from 'axios';

import { LTI_CONSUMER } from '../../../../../../core/constants/endpoints';
import { LoaderContaier, Iframe } from './TurnitinModal.styled';
import TurnitinLogo from '../../../../../../static/turnitin.png';
import CircularProgress from '../../../CircularProgress';

export default class TurnitinModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.iframeName = `turnitin-iframe-${Math.random()}`;

    this.callTurnitIn = this.callTurnitIn.bind(this);
    this.callProxyServer = this.callProxyServer.bind(this);
    this.renderLoader = this.renderLoader.bind(this);
    this.renderFrame = this.renderFrame.bind(this);
  }

  componentDidMount() {
    this.callTurnitIn();
  }

  callProxyServer() {
    return new Promise((resolve) => {
      const {
        assignment: {
          objectId: assignmentId,
          title,
          description,
          endDate: dueDate,
          startDate,
          gradesReleasedAt,
          maxGrade,
        },
        user: { email, firstName, lastName },
        space: { objectId: spaceId },
      } = this.props;
      const params = {
        assignmentId,
        spaceId,
        title,
        description,
        dueDate,
        startDate,
        gradesReleasedAt,
        ...(maxGrade ? { maxGrade } : {}),
        email,
        firstName,
        lastName,
      };

      axios
        .post(LTI_CONSUMER(), params, {
          withCredentials: true,
        })
        .then((response) => {
          resolve(response.data);
        });
    });
  }

  callTurnitIn() {
    this.callProxyServer().then((data) => {
      const myForm = document.createElement('form');
      myForm.method = data.method;
      myForm.action = data.url;
      const p = data.body;
      if (p) {
        for (const k in p) {
          const myInput = document.createElement('input');
          myInput.setAttribute('name', k);
          myInput.setAttribute('value', p[k]);
          myForm.appendChild(myInput);
        }
      }
      myForm.target = this.iframeName;
      document.body.appendChild(myForm);
      myForm.submit();
      document.body.removeChild(myForm);
    });
  }

  renderLoader() {
    const { loading } = this.state;
    if (!loading) return null;

    return (
      <LoaderContaier>
        <CircularProgress />
      </LoaderContaier>
    );
  }

  renderFrame() {
    return (
      <Iframe
        title="turnitin"
        name={this.iframeName}
        frameBorder="0"
        onLoad={() => this.setState({ loading: false })}
        data-testid="turnitin-iframe"
      >
        Loading...
      </Iframe>
    );
  }

  render() {
    const {
      onClose,
      assignment: { title },
    } = this.props;
    return (
      <Modal
        id="turnitin-modal"
        size="fullscreen"
        container={() => document.getElementById('turnitin-container')}
        open
        onClose={onClose}
        data-testid="turnitin-modal"
        headerData={{
          icon: (
            <img width="32px" height="32px" src={TurnitinLogo} alt="turnitin" />
          ),
          title: `Turnitin: ${title}`,
        }}
      >
        {this.renderLoader()}
        {this.renderFrame()}
      </Modal>
    );
  }
}

TurnitinModal.propTypes = {
  assignment: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    gradesReleasedAt: PropTypes.string.isRequired,
    maxGrade: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  space: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
