import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import useMutateApi from '../../api/hooks/useMutateApi';
import { CREATE_COMMENT } from '../../constants/endpoints';
import { COMMENTS_CREATE } from '../../constants/actions';
import { newError } from '../../actions/errors';
import queryKeys from './queryKeys';
import addCommentToQueryData from '../utils/addCommentToQueryData';

const getCommentAction = (status, payload) => ({
  type: COMMENTS_CREATE,
  status,
  payload,
});

const useCreateComment = (entityType, entityId, onSuccess) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.comments(entityType, entityId);
  const dispatch = useDispatch();

  return useMutateApi(
    {
      url: CREATE_COMMENT(),
      method: 'POST',
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(queryKey);
        dispatch(getCommentAction('started', {}));
      },
      onSuccess: ({ comment }) => {
        addCommentToQueryData(queryClient, comment);
        if (onSuccess) {
          onSuccess();
        }
        dispatch(
          getCommentAction('success', {
            comment,
          })
        );
      },
      onError: (error) => {
        dispatch(newError(error, 'createPostError'));
        dispatch({
          ...getCommentAction('error', {}),
          error,
        });
      },
    }
  );
};

export default useCreateComment;
