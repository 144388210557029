import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import OmniSearch from './OmniSearch/OmniSearch.container';
import OmniSearchV4 from './OmniSearchV4/OmniSearch.container';

const OmniSearchToggle = (props) => {
  const { webAppFtMuiV6OmniSearch20251 } = useFlags();
  return webAppFtMuiV6OmniSearch20251 ? (
    <OmniSearch {...props} />
  ) : (
    <OmniSearchV4 {...props} />
  );
};

export default OmniSearchToggle;
