import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  position: relative;
  height: ${theme.spacing.xLarge};
  padding-bottom: ${theme.spacing.xLarge};
  margin-left: ${theme.spacing.xLarge};
  margin-right: ${theme.spacing.xLarge};
`;

export const Indicator = styled('div')`
  height: ${theme.spacing.small};
  border-bottom: dashed 2px ${theme.color.gray};
  box-sizing: border-box;
`;

export const LabelContainer = styled('p')`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0;
`;

export const Label = styled('span')`
  font-size: ${theme.font.size.small};
  background-color: ${theme.color.white};
  padding: 0 ${theme.spacing.medium};
  color: ${theme.color.grey11};
`;
