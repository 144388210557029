import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('p')`
  flex-shrink: 0;
  display: block;
  margin: 0;
  font-size: ${theme.font.size.copy};
  color: ${theme.color.grayDark};
  padding: 0 0 ${theme.spacing.xSmall} ${theme.spacing.xxxLarge};
  margin-bottom: 15px; // this counters the space reserved for horizontal scroll
  line-height: ${theme.spacing.large};
  height: ${theme.spacing.xLarge};
`;
