import React, { ReactElement } from 'react';
import { Tooltip } from '@ublend-npm/aulaui-next';
import { Assignment, ProviderType } from '@core/types/state';
import { QUIZZES_PROVIDER } from '@core/assignments/constants';
import CardMenu from './CardMenu';
import Label from './Label';
import { STATUS } from '../List/utils';
import AnonymousIcon from '../../../AnonymousIcon';

import {
  Main,
  Content,
  Footer,
  Divider,
  Header,
  HiddenBadge,
  RightItems,
  StyledVisibilityOff,
  SubmittedLabel,
  ClickableContent,
  StyledGroupIcon,
  GroupBadge,
  AnonymousBadge,
  Subtitle,
  AlertText,
  HeaderContent,
  IntegrationDisabledAlert,
} from './AssignmentCard.styled';

import formatDate from '../../../../../../core/utils/formatDate';

type MenuItem = {
  id: string;
  text: string;
  icon: () => ReactElement;
  onClick: () => void;
};

type AssignmentCardProps = {
  Logo?: () => ReactElement;
  isHidden?: boolean;
  isAnonymised?: boolean;
  dense: boolean;
  status: string;
  title: string;
  startDate?: string;
  dueDate?: string;
  menuItems?: MenuItem[];
  isSubmitted?: boolean;
  grade?: string;
  onContentClick?: () => void;
  isGroupAssignment?: boolean;
  customFooter?: string;
  parentAssignmentId?: string;
  parentAssignment?: Assignment;
  isEducator: boolean;
  provider: ProviderType;
  isProviderDisabled?: boolean;
};

const AssignmentCard = (props: AssignmentCardProps) => {
  const {
    isHidden,
    status,
    title,
    startDate,
    dueDate = '',
    Logo = null,
    dense,
    menuItems = [],
    isSubmitted,
    grade = '',
    onContentClick,
    isGroupAssignment,
    customFooter,
    isAnonymised,
    parentAssignment,
    parentAssignmentId,
    isEducator,
    provider,
    isProviderDisabled = false,
  } = props;

  let dateFooter;

  if (startDate && dueDate) {
    dateFooter =
      status === STATUS.upcoming
        ? `Open on: ${formatDate(startDate)} - Due on: ${formatDate(dueDate)}`
        : `Due on: ${formatDate(dueDate)}`;
  }

  const footerText = grade ? `Grade - ${grade}` : customFooter || dateFooter;

  const disableClosedQuizWithoutSubmission =
    !isEducator &&
    provider === QUIZZES_PROVIDER &&
    status === STATUS.closed &&
    !isSubmitted;

  const isDisabled =
    disableClosedQuizWithoutSubmission ||
    !onContentClick ||
    (!isEducator && isProviderDisabled) ||
    (!isEducator && status === STATUS.upcoming);

  const assignmentTitle =
    parentAssignmentId && parentAssignment ? parentAssignment.title : title;
  const subtitle = parentAssignmentId && title;

  return (
    <Main isDisabled={isDisabled} data-testid={`assignment-${title}`}>
      <Header>
        {isProviderDisabled && (
          <IntegrationDisabledAlert type="error">
            <AlertText>
              This integration has been disabled by your institution.
            </AlertText>
          </IntegrationDisabledAlert>
        )}
        <HeaderContent>
          {Logo && <Logo />}
          {status && <Label type={status} />}
          {isSubmitted && (
            <SubmittedLabel>
              <Label type="submitted" />
            </SubmittedLabel>
          )}
          {isGroupAssignment && (
            <Tooltip title="This is a group assignment" position="top">
              <GroupBadge>
                <StyledGroupIcon />
              </GroupBadge>
            </Tooltip>
          )}
          {isAnonymised && (
            <Tooltip title="This assignment is anonymised" position="top">
              <AnonymousBadge>
                <AnonymousIcon />
              </AnonymousBadge>
            </Tooltip>
          )}
          {isHidden && (
            <Tooltip
              title="This assignment is hidden from students"
              position="top"
            >
              <HiddenBadge>
                <StyledVisibilityOff />
              </HiddenBadge>
            </Tooltip>
          )}
          <RightItems>
            {menuItems && menuItems.length > 0 && (
              <CardMenu
                title={title}
                isHidden={isHidden}
                placement="bottom-end"
                menuItems={menuItems}
              />
            )}
          </RightItems>
        </HeaderContent>
      </Header>
      <ClickableContent
        isDisabled={isDisabled}
        onClick={onContentClick}
        data-testid={`assignment-${title}-clickable`}
      >
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Content isDisabled={isDisabled} _dense={dense}>
          {assignmentTitle}
        </Content>
      </ClickableContent>
      {!dense && <Divider />}
      <Footer _grade={grade} _dense={dense}>
        {footerText}
      </Footer>
    </Main>
  );
};

export default AssignmentCard;
