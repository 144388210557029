import { connect } from 'react-redux';

import {
  getSchedulePostDialogOpenFor,
  getPostById,
} from '@core/selectors/posts.selectors';
import { closeSchedulePost } from '@core/actions/classRoom/openCloseSchedulePost';
import { createPost } from '@core/actions/classRoom/createPost';
import { editSpacePost } from '@core/actions/space/posts';

import SchedulePostDialog from './SchedulePostDialog';

const mapStateToProps = (state) => {
  const postId = getSchedulePostDialogOpenFor(state);
  const { scheduledFor } = postId ? getPostById(state, postId) : {};

  return {
    postData: {
      postId,
      scheduledFor,
    },
    open: !!postId,
    scheduledFor,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeSchedulePost()),
  onSchedule: (postData) => (date) =>
    dispatch(
      createPost({
        ...postData,
        scheduledFor: date,
      })
    ),
  onReschedule: (postData) => (date) =>
    dispatch(
      editSpacePost({
        postId: postData.postId,
        scheduledFor: date,
      })
    ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSchedule: dispatchProps.onSchedule(stateProps.postData),
  onReschedule: dispatchProps.onReschedule(stateProps.postData),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps,
  mergeProps
)(SchedulePostDialog);
