import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from '@ublend-npm/aulaui-next';
import { SpaceGroupType } from '../../ExtensionModal.types';

const GroupsSelection = ({ spaceGroups, selectedGroups, onChange }) => {
  return (
    <SelectInput
      name="group"
      label="Group"
      data={spaceGroups}
      value={selectedGroups}
      hasMultiSelect
      handleChange={onChange}
    />
  );
};

GroupsSelection.propTypes = {
  spaceGroups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)).isRequired,
  selectedGroups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(GroupsSelection);
