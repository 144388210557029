import React from 'react';

type RestoreMaterialsToastMessageProps = {
  pageTitle: string;
};

const RestoreMaterialsToastMessage = ({
  pageTitle,
}: RestoreMaterialsToastMessageProps) => (
  <span>
    <span style={{ fontWeight: 600 }}>{pageTitle}</span> was restored.
  </span>
);

export default RestoreMaterialsToastMessage;
