import AulaEditorWithPlugins from './AulaEditorWithPlugins';
import withAnalytics from './withAnalytics';
import withErrorBoundary from '../ErrorBoundary/ErrorBoundary';

// This is created for simplifying import/export of AulaEditor with analytics.
const AulaEditorWithAnalytics = withErrorBoundary(
  withAnalytics(AulaEditorWithPlugins),
  { ignore: true }
);

export default AulaEditorWithAnalytics;
