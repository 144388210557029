import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Tooltip } from '@ublend-npm/aulaui-next';
import {
  TURNITIN_PROVIDER,
  HANDIN_PROVIDER,
  QUIZZES_PROVIDER,
} from '@core/assignments/constants';
import { ENTER } from '../../../../utils/keyValues';
import { AlertDialog } from '../../common/Dialog';

import { useAssignmentTypeProvider } from './AssignmentTypeProvider';

import {
  GridColumn,
  SubmissionListItem,
  SubmissionListItemAvatar,
  MenuContainer,
} from './Submissions.styled';
import { analyticsTrackEvent } from '../../../../../core/utils/analytics';
import * as analyticEvents from '../../../../../core/constants/analytics';
import Menu from './Menu';

const SubmissionsCard = memo(
  ({
    user,
    submissionDate,
    grade,
    submissionExternalId,
    submissionId,
    isGroup,
    assignmentId,
    assignmentExternalId,
    accessingAsStaff,
    deleteSubmission,
    groupId,
    spaceId,
  }) => {
    const assignmentTypeConfig = useAssignmentTypeProvider();
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
    const openDeleteDialog = () => setIsDeleteDialogVisible(true);
    const closeDeleteDialog = () => setIsDeleteDialogVisible(false);

    const {
      launchFeedback,
      launchSubmission,
      type: assignmentType,
    } = assignmentTypeConfig;
    const isTurnitinSubmission = assignmentType === TURNITIN_PROVIDER;
    const isHandinSubmission = assignmentType === HANDIN_PROVIDER;
    const isQuizSubmission = assignmentType === QUIZZES_PROVIDER;
    const hasSubmission = !!submissionId;
    const isDeletable = isHandinSubmission && hasSubmission;
    const { id: studentId, avatar, firstName, lastName = '' } = user;
    const fullName = `${firstName} ${lastName}`;
    const canOpenSubmission =
      hasSubmission && (!accessingAsStaff || !isTurnitinSubmission);

    const handleKeydown = (e, handler) => {
      if (e.key === ENTER) {
        e.preventDefault();
        handler(e);
      }
    };

    const handleClick = () => {
      if (!canOpenSubmission) {
        return null;
      }

      analyticsTrackEvent(analyticEvents.VIEW_STUDENT_SUBMISSION, {
        assignmentId,
      });

      if (isQuizSubmission && hasSubmission) {
        return launchFeedback({ submissionId });
      }

      if (isHandinSubmission) {
        return launchSubmission({
          studentId,
          submissionId: submissionExternalId,
        });
      }

      return launchSubmission({ submissionExternalId, submissionId });
    };

    const deleteSubmissionHandler = (e) => {
      e.stopPropagation();

      deleteSubmission({
        spaceId,
        assignmentId,
        assignmentExternalId,
        ...(isGroup ? { groupId } : { studentId: user.id }),
      });
      closeDeleteDialog();
    };

    const isGraded = grade !== 'Not graded';
    const manualGradingText = 'Manual grading may be required';
    let ariaLabel = `View submission by ${fullName}, submitted on ${submissionDate}, grade is ${grade}`;
    if (!isGraded) {
      ariaLabel += `, ${manualGradingText}`;
    }

    return (
      <SubmissionListItem
        tabIndex="0"
        onClick={handleClick}
        onKeyDown={(e) => handleKeydown(e, handleClick)}
        data-testid="submission-item"
        aria-label={ariaLabel}
      >
        <GridColumn cols={6} gap={12}>
          <SubmissionListItemAvatar aria-hidden="true">
            <Avatar size="large" name={fullName} src={avatar} />
          </SubmissionListItemAvatar>
          <p data-testid="submission-item-full-name">{fullName}</p>
        </GridColumn>
        <GridColumn cols={4}>
          <p data-testid="submission-item-date" primary={submissionDate}>
            {submissionDate}
          </p>
        </GridColumn>
        <GridColumn cols={2} withSpaceBetween>
          <Tooltip
            title={manualGradingText}
            position="top"
            disableHoverListener={isGraded}
            disableFocusListener={isGraded}
            disableTouchListener={isGraded}
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <span tabIndex="0" data-testid="submission-item-grade">
              {grade}
            </span>
          </Tooltip>
          {isDeletable && (
            <MenuContainer>
              <Menu deleteSubmission={openDeleteDialog} />
            </MenuContainer>
          )}
        </GridColumn>
        <AlertDialog
          id="confirm-delete-submission"
          title="Delete submission"
          message={`Delete ${fullName}'s submission?`}
          open={isDeleteDialogVisible}
          onClose={closeDeleteDialog}
          action={{
            label: 'Delete submission',
            onClick: deleteSubmissionHandler,
            danger: true,
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: closeDeleteDialog,
          }}
        />
      </SubmissionListItem>
    );
  }
);

SubmissionsCard.propTypes = {
  submissionDate: PropTypes.string.isRequired,
  grade: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    avatar: PropTypes.string,
  }),
  submissionExternalId: PropTypes.string,
  submissionId: PropTypes.string,
  assignmentId: PropTypes.string,
  assignmentExternalId: PropTypes.string,
  isGroup: PropTypes.bool,
  accessingAsStaff: PropTypes.bool,
  deleteSubmission: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  spaceId: PropTypes.string.isRequired,
};

SubmissionsCard.defaultProps = {
  user: {
    firstName: '',
    lastName: '',
    id: '',
    avatar: '',
  },
  grade: '-',
  submissionExternalId: '',
  submissionId: '',
  assignmentId: '',
  assignmentExternalId: '',
  isGroup: false,
  accessingAsStaff: false,
  groupId: '',
};

export default SubmissionsCard;
