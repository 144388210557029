import { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const menu = css`
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const menuItem = css`
  min-width: 160px;
  margin: 8px 0px;
`;

export const dangerMenuItem = css`
  color: ${theme.color.red};
`;
