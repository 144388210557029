import { AulaEditor } from '@ublend-npm/aula-editor';
import { connect } from 'react-redux';
import withPlugins from './withPlugins';
import {
  isTalisEnabled as getIsTalisEnabled,
  isEchoEnabled as getIsEchoEnabled,
  isPlanetEnabled as getIsPlanetEnabled,
  isPanoptoEnabled as getIsPanoptoEnabled,
  isBibliuEnabled as getIsBibliuEnabled,
  isH5PEnabled as getIsH5PEnabled,
} from '../../../../../core/selectors/lti';

const mapStateToProps = (state) => ({
  isTalisEnabled: getIsTalisEnabled(state),
  isEchoEnabled: getIsEchoEnabled(state),
  isPlanetEnabled: getIsPlanetEnabled(state),
  isPanoptoEnabled: getIsPanoptoEnabled(state),
  isBibliuEnabled: getIsBibliuEnabled(state),
  isH5PEnabled: getIsH5PEnabled(state),
});

export default connect(mapStateToProps)(withPlugins(AulaEditor));
