import styled from 'react-emotion';
import { IconButton as MuiIconButton } from '@mui/material'; // Import IconButton from MUI v6
import { styleUtils, theme } from '@ublend-npm/aulaui-next';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.xxLarge};
  width: 100%;
  max-width: 900;
  min-width: 300;
  padding: ${theme.spacing.xLarge};
  border-radius: ${theme.borderRadius.large};
  box-shadow: ${theme.shadow.default};
  background-color: ${theme.color.white};
  box-sizing: border-box;
  :focus-within {
    ${styleUtils.focusOutline}
  }
`;

export const EditorWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export const ControlsWrapper = styled.div`
  margin-left: ${theme.spacing.medium};
`;

export const IconButton = styled(MuiIconButton)`
  border-radius: ${theme.borderRadius.medium};
  height: 40px;
  width: 40px;
  margin-right: 8px;
`;
