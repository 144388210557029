import styled, { css } from 'react-emotion';
import { theme, IconButton } from '@ublend-npm/aulaui-next';
import { Divider } from '@mui/material';

export const HoverIconButton = styled(IconButton)`
  color: ${theme.color.designSystem.blackOpacity60};
  flex-shrink: 0;

  display: flex;
  opacity: 0;
  [role='button']:hover & {
    opacity: 1;
  }
  [role='button']:focus & {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
  }
  &:hover {
    background-color: ${theme.color.designSystem.background.grey.hover};
  }
`;

export const listItem = css`
  background-color: transparent;
  &:hover {
    background-color: ${theme.color.designSystem.background.grey.hover};
  }
  border-radius: 4px;
  padding: 4px;
  padding-inline-start: 6px;
`;

export const hiddenListItem = css`
  .label {
    color: ${theme.color.blackAlpha(theme.opacity.alpha60)};
  }
  .drag {
    opacity: 0.2;
  }
`;

export const ListDividerTop = styled(Divider)`
  margin-top: -1px;
  background-color: rgb(224, 224, 224);
`;

export const ListDividerBottom = styled(Divider)`
  background-color: rgb(224, 224, 224);
`;
