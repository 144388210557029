/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Dialog, Avatar, Button } from '@ublend-npm/aulaui-next';
import {
  ContentContainer,
  DividerContainer,
  TitleWrapper,
} from './GotItModal.styled';

type GotItModalProps = {
  logo: string | undefined;
  name: string;
  textParagraph1: string;
  textParagraph2: string;
  isVisible: boolean;
  onClose: () => void;
};

const GotItModal = ({
  logo,
  name,
  textParagraph1,
  textParagraph2,
  isVisible,
  onClose,
}: GotItModalProps) => (
  <Dialog height="296" width="493" open={isVisible}>
    <ContentContainer>
      <DividerContainer>
        <Avatar src={logo} name={name} size="small" />
        <TitleWrapper tabIndex={0}>{name}</TitleWrapper>
      </DividerContainer>
      <DividerContainer>{textParagraph1}</DividerContainer>
      <DividerContainer>{textParagraph2}</DividerContainer>
      <div>
        <Button type="primary" onClick={onClose}>
          Got it
        </Button>
      </div>
    </ContentContainer>
  </Dialog>
);

export default GotItModal;
