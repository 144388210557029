import { isAulaEditorState } from '@ublend-npm/aula-editor-utils';
import getTh90ContentSummary from './th90-editor/getTh90ContentSummary';
import getAulaEditorContentSummary from './aula-editor/getAulaEditorContentSummary';
import safeParse from './safeParse';

const getContentSummary = (data = {}) => {
  const parsedContent = safeParse(data.content);

  if (isAulaEditorState(parsedContent)) {
    return getAulaEditorContentSummary({ ...data, content: parsedContent });
  }

  return getTh90ContentSummary(data);
};

export default getContentSummary;
