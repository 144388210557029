const getHandinUrl = ({ baseDomain, space, assignment, search }) => {
  let handinUrl = `https://handin.${baseDomain}/${space}`;

  if (process.env.TEST_ENV === 'cypress') {
    handinUrl = `https://handin.local.aula.education/${space}`;
  }

  if (assignment) {
    handinUrl += `/${assignment}`;
  }
  if (search) {
    handinUrl += `?${search}`;
  }

  return handinUrl;
};

export default getHandinUrl;
