import { AulaReduxAction } from '../../types/state';
import { CLASSROOM_MODULE_EDIT_MATERIAL } from '../actions';
import { Material } from '../types';

type EditMaterialActionFactory = (updatedMaterial: Material) => AulaReduxAction;

const editMaterial: EditMaterialActionFactory =
  (updatedMaterial) => (dispatch) =>
    dispatch({
      type: CLASSROOM_MODULE_EDIT_MATERIAL,
      status: 'success',
      updatedMaterial,
      payload: {
        updatedMaterial,
      },
    });

export default editMaterial;
