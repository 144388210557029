import PropTypes from 'prop-types';

export const Writer = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatar: PropTypes.string,
});

export const Version = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  editor: Writer,
  updatedAt: PropTypes.string.isRequired,
});

export const VersionList = PropTypes.arrayOf(Version);

export const Section = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string,
  writer: PropTypes.oneOfType([Writer, PropTypes.string]),
  versions: VersionList,
  version: PropTypes.string,
});

export const SectionList = PropTypes.arrayOf(Section);
