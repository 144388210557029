import PropTypes from 'prop-types';

export const unfinishedUploadBlock = ({
  data: { type, readyForAction, url },
}) => type === 'file' && (!readyForAction || !url);

export const atLeastOneUnfinishedBlock = (blocks) =>
  blocks.findIndex(unfinishedUploadBlock) !== -1;

export const withoutUnfinishedBlocks = (blocks) =>
  blocks.filter((b) => !unfinishedUploadBlock(b));

export const removeUnfinishedFileBlocksFromContent = ({
  blocks,
  entityMap,
}) => ({ entityMap, blocks: withoutUnfinishedBlocks(blocks) });

export const editorNodePropType = PropTypes.shape({});
editorNodePropType.children = PropTypes.arrayOf(editorNodePropType);

const editorMetaPropType = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.string,
  category: PropTypes.string,
});

const editorMetaEntityPropType = PropTypes.objectOf(
  PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    data: editorMetaPropType,
    error: PropTypes.string,
  })
);
const editorContextPropType = PropTypes.shape({
  entities: editorMetaEntityPropType,
});

export const editorStatePropType = PropTypes.shape({
  value: PropTypes.arrayOf(editorNodePropType),
  context: editorContextPropType,
});
