/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';

import SearchButton from './Search/SearchButton';
import SearchBar from './Search';
import CreateButton from './CreateButton/CreateButton';
import { Container, Title } from './TopBar.styled';

class TopBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isSearching: false };

    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch() {
    this.setState(({ isSearching }) => ({ isSearching: !isSearching }));
  }

  render() {
    const { isSearching } = this.state;
    const { isInstructor, onShowCreateAssignment } = this.props;

    return (
      <Container>
        {isSearching ? (
          <SearchBar onClose={this.toggleSearch} />
        ) : (
          [
            <Title key="assignment-page">Assignments</Title>,
            <SearchButton
              key={`search-btn_${Math.random()}`}
              onClick={this.toggleSearch}
            />,
            isInstructor ? (
              <CreateButton
                key={`create-btn_${Math.random()}`}
                onShowCreateAssignment={onShowCreateAssignment}
              />
            ) : null,
          ]
        )}
      </Container>
    );
  }
}

export default TopBar;
