import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import AddReaction from '../../common/Icons/AddReaction';
import EmojiPicker, { PositionPropType } from '../../emoji/EmojiPicker';
import style from './style';
import { ENTER } from '../../../../utils/keyCodes';

@Radium
class ReactionAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickerAnchorEl: null,
    };

    this.openEmojiPicker = this.openEmojiPicker.bind(this);
    this.closeEmojiPicker = this.closeEmojiPicker.bind(this);
    this.handleEmojiPickerFromKeyboard =
      this.handleEmojiPickerFromKeyboard.bind(this);
  }

  closeEmojiPicker() {
    const { closeEmojiPicker } = this.props;
    closeEmojiPicker();
    this.setState({ pickerAnchorEl: null });
  }

  openEmojiPicker(e) {
    const { openEmojiPicker } = this.props;
    openEmojiPicker();
    this.setState({ pickerAnchorEl: e.currentTarget });
  }

  handleEmojiPickerFromKeyboard(e) {
    e.keyCode === ENTER && this.state.pickerAnchorEl === null
      ? this.openEmojiPicker(e)
      : this.closeEmojiPicker();
  }

  render() {
    const { id, anchorOrigin, transformOrigin, onSelect } = this.props;
    const { pickerAnchorEl } = this.state;

    return (
      <span style={style.container}>
        <span
          role="button"
          aria-label="Reaction emojis"
          id={id}
          style={style.iconWrapper}
          onClick={this.openEmojiPicker}
          onKeyDown={this.handleEmojiPickerFromKeyboard}
          tabIndex="0"
        >
          <AddReaction style={style.icon} />
        </span>
        <EmojiPicker
          id={`${id}-emoji-picker`}
          anchorEl={pickerAnchorEl}
          show={!!pickerAnchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={this.closeEmojiPicker}
          onSelect={onSelect}
        />
      </span>
    );
  }
}

ReactionAdd.propTypes = {
  onSelect: PropTypes.func.isRequired,
  anchorOrigin: PositionPropType.isRequired,
  transformOrigin: PositionPropType.isRequired,
  id: PropTypes.string.isRequired,
  openEmojiPicker: PropTypes.func.isRequired,
  closeEmojiPicker: PropTypes.func.isRequired,
};

export default ReactionAdd;
