import styled, { css } from 'react-emotion';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import { theme } from '@ublend-npm/aulaui-next';

const getStyles = () => ({
  root: {
    margin: 0,
    padding: `${theme.spacing.xxSmall} 0`,
    boxShadow: 'none',
    backgroundColor: theme.color.designSystem.grey10,
    fontWeight: 'normal',
    fontFamily: theme.font.family.workSans,
    fontSize: theme.font.size.copy,
    lineHeight: theme.font.lineHeight.copy,

    '&:before': {
      height: 0,
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
});

export const AccordionWrapper = withStyles(getStyles)(MuiAccordion);

export const AccordionSummaryStyles = css`
  & > div {
    display: flex;
    align-items: center;
    margin: 0 !important;
  }

  :hover {
    background-color: ${theme.color.designSystem.background.grey.hover};
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0 ${theme.spacing.large};
`;

export const AccordionContent = styled('div')`
  border-left: 1px solid ${theme.color.blackAlpha(0.12)};
  padding-left: ${theme.spacing.xSmall};
  padding-top: 10px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${theme.spacing.xxxxLarge};
  justify-content: center;
  width: ${theme.spacing.xxxxLarge};
`;

export const IconStyle = {
  color: `${theme.color.blackAlpha(0.6)}`,
  fontSize: `${theme.spacing.xxxxLarge}`,
  '&:hover': {
    color: `${theme.color.designSystem.purple70}`,
  },
};
