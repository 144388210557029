import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, styleUtils } from '@ublend-npm/aulaui-next';
import { cleanState, isUpdating, isPersistable } from '@ublend-npm/aula-editor';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Tooltip from '@ublend-npm/react-tooltip';
import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import uuid from '@core/utils/uuid';
import {
  LTIProviderType,
  ReactElementType,
} from '@app/components/propTypes/common';
import SchedulePostDialog from '../SchedulePostDialog/SchedulePostDialog';
import { ControlsWrapper, EditorWrapper, IconButton, Wrapper } from './styles';
import isEmptyContent from '../isEmptyContent';
import feedPostTemplates from './feedPostTemplates';

import useEnabledLTIIntegrations from '../../../../../hooks/useEnabledLTIIntegrations';

const noop = () => {};

const CreatePost = forwardRef(
  (
    {
      isEducator,
      isSavingPost,
      createPost,
      spaceId,
      editorConfig,
      EditorComponent,
      feedType,
    },
    ref
  ) => {
    const editorRef = ref;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
    const [editorState, setEditorState] = useState(cleanState);
    const [enablePostButtons, setEnablePostButtons] = useState(false);
    const [correlationId, setCorrelationId] = useState(uuid());
    const handleCloseDialog = () => setScheduleDialogOpen(false);

    const resetComponentState = () => {
      setEditorState(cleanState);
      setEnablePostButtons(false);
      setCorrelationId(uuid());
    };

    const handleCreatePost = async (additionalDataWithSchedulingDate) => {
      try {
        await createPost({
          spaceId,
          content: editorState,
          correlationId,
          feedType,
          ...additionalDataWithSchedulingDate,
        });
        setMessage('A new post has been added');
        resetComponentState();
      } catch (error) {
        setMessage('The post has not been added');
        console.log(error);
      }
    };

    const handleSchedulePost = async (date) =>
      handleCreatePost({ scheduledFor: date, feedType });

    const handleEditorChange = useCallback(
      (state) => {
        setEditorState(state);
        setIsSubmitting(isUpdating(state));
        setEnablePostButtons(
          !isEmptyContent(state) && isPersistable(state) && !isUpdating(state)
        );
      },
      [setEditorState, setIsSubmitting, setEnablePostButtons]
    );

    const filteredEditorConfig = useEnabledLTIIntegrations(editorConfig);
    filteredEditorConfig.correlationId = correlationId;

    const placeholder =
      (feedType === ANNOUNCEMENTS &&
        'Share important information in this space') ||
      (isEducator && 'Share something or post a formative assessment') ||
      'Ask or share something, this is your space too';

    const loading = isSavingPost || isSubmitting;

    return (
      <>
        <Wrapper>
          <EditorWrapper data-testid="feed-post-editor">
            <EditorComponent
              key={correlationId}
              type="createpost"
              ref={editorRef}
              config={filteredEditorConfig}
              editorState={editorState}
              placeholder={placeholder}
              onChange={handleEditorChange}
              isSubmitting={loading}
              setSubmittingState={setIsSubmitting}
              readOnly={isSavingPost}
              isAssignmentsEnabled
              templates={feedPostTemplates}
              contentType="post"
              correlationId={correlationId}
            />
          </EditorWrapper>
          <ControlsWrapper>
            <Tooltip position="top" title="Schedule for later">
              <IconButton
                onClick={() => setScheduleDialogOpen(true)}
                disabled={!enablePostButtons}
                aria-label="Schedule for later"
              >
                <ScheduleIcon />
              </IconButton>
            </Tooltip>
            <Button
              loading={loading}
              disabled={!enablePostButtons}
              type="primary"
              size="medium"
              onClick={handleCreatePost}
            >
              Post
            </Button>
          </ControlsWrapper>
        </Wrapper>
        <SchedulePostDialog
          open={scheduleDialogOpen}
          onClose={handleCloseDialog}
          onSchedule={handleSchedulePost}
          onReschedule={noop}
        />
        <div
          css={[styleUtils.visuallyHidden()]}
          aria-live="polite"
          role="status"
        >
          {message}
        </div>
      </>
    );
  }
);

CreatePost.propTypes = {
  spaceId: PropTypes.string,
  isEducator: PropTypes.bool.isRequired,
  isSavingPost: PropTypes.bool.isRequired,
  createPost: PropTypes.func.isRequired,
  editorConfig: PropTypes.shape({
    accessToken: PropTypes.string,
    spaceId: PropTypes.string.isRequired,
    institution: PropTypes.string.isRequired,
    filesUrl: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    isEducator: PropTypes.bool.isRequired,
    ltiProviders: PropTypes.arrayOf(LTIProviderType).isRequired,
  }).isRequired,
  EditorComponent: ReactElementType.isRequired,
  feedType: PropTypes.oneOf([ANNOUNCEMENTS, COMMON_ROOM]).isRequired,
};

CreatePost.defaultProps = {
  spaceId: null,
};

export default CreatePost;
