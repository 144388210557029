import React from 'react';
import PropTypes from 'prop-types';
import {
  ConversionDetailsContainer,
  Title,
  Content,
  EmptyTitle,
  SubTitle,
} from './ConversionDetails.styled';

const ConversionDetails = ({
  currentTitle,
  convertedExtensionTitle,
  parentAssignmentTitle,
}) => {
  return (
    <ConversionDetailsContainer>
      <Content>
        <Title>Existing assignment: </Title>
        <SubTitle>&#34;{currentTitle}&#34;</SubTitle>
      </Content>

      <Content>
        <Title>Will be turned into an extension called: </Title>
        <SubTitle>&#34;{convertedExtensionTitle}&#34;</SubTitle>
      </Content>

      <Content>
        <Title>As a part of the assignment:</Title>
        {parentAssignmentTitle ? (
          <SubTitle>&#34;{parentAssignmentTitle}&#34;</SubTitle>
        ) : (
          <EmptyTitle>Select a destination assignment above</EmptyTitle>
        )}
      </Content>
    </ConversionDetailsContainer>
  );
};

ConversionDetails.propTypes = {
  currentTitle: PropTypes.string.isRequired,
  convertedExtensionTitle: PropTypes.string,
  parentAssignmentTitle: PropTypes.string,
};

ConversionDetails.defaultProps = {
  convertedExtensionTitle: null,
  parentAssignmentTitle: null,
};
export default React.memo(ConversionDetails);
