import React from 'react';
import PropTypes from 'prop-types';
import MoreVert from '@mui/icons-material/MoreVert';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Menu, ListItem, Tooltip } from '@ublend-npm/aulaui-next';
import IconButton from '../../common/Buttons/IconButton';
import { menu, menuItem } from '../../common/Menu.styled';
import {
  Extension,
  HoverIconButton,
  TitleContainer,
  MenuContainer,
  TitleText,
  SubmissionsCount,
} from './ExtensionItem.styled';
import {
  EXTENSION_HIDE,
  EXTENSION_UNHIDE,
  ASSIGNMENT_HIDE_TOOLTIP,
  ASSIGNMENT_UNHIDE_TOOLTIP,
  EXTENSION_UNHIDE_TOOLTIP,
  EXTENSION_HIDE_TOOLTIP,
} from '../../../../constants/texts';

const EDIT = 'edit';
const DELETE = 'delete';

const MENU_ITEMS = [
  {
    key: EDIT,
    getLabel: () => 'Edit',
    isVisible: () => true,
  },
  {
    key: DELETE,
    getLabel: () => 'Delete',
    isVisible: () => true,
  },
];

const ExtensionItem = ({
  id,
  title,
  submissionsCount,
  studentsCount,
  selected,
  onClick,
  onClickEdit,
  onClickDelete,
  showOptions,
  isHidden,
  onVisibilityChange,
}) => {
  const handleClickFunctions = {
    [EDIT]: onClickEdit,
    [DELETE]: onClickDelete,
  };

  const VisibilityButton = isHidden ? IconButton : HoverIconButton;
  const VisibilityIcon = isHidden ? VisibilityOff : Visibility;
  const ExtensionTooltipText = isHidden
    ? EXTENSION_UNHIDE_TOOLTIP
    : EXTENSION_HIDE_TOOLTIP;

  const AssignmentTooltipText = isHidden
    ? ASSIGNMENT_UNHIDE_TOOLTIP
    : ASSIGNMENT_HIDE_TOOLTIP;
  const IconLabel = isHidden ? EXTENSION_HIDE : EXTENSION_UNHIDE;

  return (
    <Extension selected={selected} onClick={onClick} role="button" tabIndex={0}>
      <TitleContainer>
        <TitleText showOptions={showOptions}>{`${title}`}</TitleText>
        <SubmissionsCount>{`(${submissionsCount}/${studentsCount})`}</SubmissionsCount>
      </TitleContainer>
      <Menu
        menuListComponent="ul"
        classes={{ menu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        renderTarget={({ handleTargetClick, isOpen }) => (
          <MenuContainer>
            {showOptions && (
              <Tooltip title="Extension options" position="bottom">
                <HoverIconButton
                  icon={MoreVert}
                  size="xSmall"
                  label="Extension options"
                  onClick={handleTargetClick}
                  buttonAttributes={{ 'aria-expanded': isOpen }}
                />
              </Tooltip>
            )}
            <Tooltip
              title={showOptions ? ExtensionTooltipText : AssignmentTooltipText}
              position="bottom"
            >
              <div>
                <VisibilityButton
                  icon={VisibilityIcon}
                  size="xSmall"
                  label={IconLabel}
                  onClick={() => onVisibilityChange(!isHidden)}
                  buttonAttributes={{ 'aria-expanded': isOpen }}
                />
              </div>
            </Tooltip>
          </MenuContainer>
        )}
        renderChildren={({ getItemProps, highlightedIndex }) =>
          MENU_ITEMS.reduce((acc, item, index) => {
            const { key, isVisible, getLabel } = item;
            const handleClick = handleClickFunctions[key];

            return isVisible()
              ? [
                  ...acc,
                  <ListItem
                    key={item.key}
                    className={menuItem}
                    primaryText={getLabel()}
                    highlighted={highlightedIndex === index}
                    {...getItemProps({
                      item: key,
                      onClick: (e) => {
                        e.stopPropagation();

                        handleClick(id);
                      },
                    })}
                  />,
                ]
              : acc;
          }, [])
        }
      />
    </Extension>
  );
};

ExtensionItem.propTypes = {
  id: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  studentsCount: PropTypes.number.isRequired,
  submissionsCount: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  showOptions: PropTypes.bool,
};

ExtensionItem.defaultProps = {
  selected: false,
  onClickEdit: undefined,
  onClickDelete: undefined,
  showOptions: false,
  onVisibilityChange: undefined,
};

export default ExtensionItem;
