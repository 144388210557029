import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import Submission from './Submissions/Submissions.container';
import SubmissionV4 from './Submissionsv4/Submissions.container';

const SubmissionToggle = (props) => {
  const { assignmentDetailsPageMuiV6202502 } = useFlags();

  return assignmentDetailsPageMuiV6202502 ? (
    <SubmissionV4 {...props} />
  ) : (
    <Submission {...props} />
  );
};

export default SubmissionToggle;
