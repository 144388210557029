import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

type ElementWithPadding = {
  hasPadding?: boolean;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${theme.spacing.xLarge};
  margin-bottom: ${theme.spacing.xLarge};
  position: relative;
`;

export const Cell = styled.div<ElementWithPadding>`
  width: 100%;
  min-width: 400px;
  background-color: ${theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${({ hasPadding }) =>
    hasPadding ? theme.spacing.medium : `0 ${theme.spacing.medium}`};
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid #e3e5e8;
  box-sizing: border-box;
  overflow: hidden;
`;

export const CommentsContainer = styled.div<ElementWithPadding>`
  width: 100%;
  padding: ${({ hasPadding }) =>
    hasPadding ? `${theme.spacing.medium} 0` : 0};
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.large};
  margin-bottom: ${theme.spacing.medium};
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.copy};
  color: ${theme.color.blackAlpha(0.87)};
`;

export const ButtonTitle = styled.span`
  font-size: ${theme.font.size.small};
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.small};
`;
