import styled from 'react-emotion';
import Prefixer from 'inline-style-prefixer';
import { theme } from '@ublend-npm/aulaui-next';
import { SPACE_SELECTOR_HEIGHT } from '../../../../constants/sizes';

const importantPostsStyles = new Prefixer().prefix({
  container: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  header: {
    lineHeight: '40px',
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '500',
    marginBottom: '20px',
  },
  headerLabel: {
    marginLeft: 5,
    color: theme.color.grey4,
  },
  postsContainer: {
    alignItems: 'center',
    padding: '0 54px 54px 54px',
    margin: 'auto',
  },
  table: {
    width: '100%',
  },
  postWrapper: {
    maxWidth: 624,
    margin: 'auto',
  },
  navigation: {
    padding: '20px 0',
    textAlign: 'left',
  },
  parentContainer: {
    height: `calc(100% - ${SPACE_SELECTOR_HEIGHT}px)`,
  },
});

export const Title = styled.div`
  align-items: center;
  display: flex;
  margin: auto;
  max-width: 624px;
  width: 100%;

  span {
    margin-left: 18px;
  }
`;

export const PinIconStyle = {
  height: '21px',
};

export default importantPostsStyles;
