import styled from 'react-emotion';
import { theme, IconButton as BaseIconButton } from '@ublend-npm/aulaui-next';

export const IconButton = styled(BaseIconButton)`
  margin-right: ${theme.spacing.small};
  width: 32px;
  height: 32px;
  color: ${theme.color.designSystem.blackOpacity60};
  background: transparent;
`;

export default { IconButton };
