import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ExtensionModal from './ExtensionModal';
import {
  operations as assignmentOperations,
  selectors,
} from '../../../../../store/assignments';
import { accessToken as getAccessToken } from '../../../../../../core/selectors/user';
import { extractStudentIdsFromErrorMessage } from '../utils/extractStudentsFromError';

const getAssignment = createSelector(
  [selectors.getExtension],
  (extension) => extension || {}
);

const editAssignmentExtension = (
  user,
  spaceId,
  extension,
  accessToken,
  openExtension
) => {
  return assignmentOperations.editAssignment({
    currentUser: user,
    spaceId,
    assignment: extension,
    accessToken,
    openExtension,
  });
};

const mapStateToProps = (state, { extensionId }) => {
  const { user } = state.user;
  const { groups } = state.data.groups;
  const extension = getAssignment(state, extensionId);

  const { selectedSpace: spaceId } = state.assignments || {};

  const assignmentUpdateError = selectors.getAssignmentUpdateError(state);
  const hasSubmissionsInCurrentExtension =
    selectors.hasSubmissionsInCurrentExtension(state);
  const hasSubmissionInParent = selectors.hasSubmissionInParentOnUpdate(state);

  const studentIdsWithExistingSubmissions =
    hasSubmissionsInCurrentExtension || hasSubmissionInParent
      ? extractStudentIdsFromErrorMessage(assignmentUpdateError.detail)
      : [];

  return {
    title: `Edit "${extension.title}"`,
    isEditing: true,
    id: extension.id,
    assignment: {
      ...extension,
      extensionName: extension.title,
    },
    studentIds: extension.students || [],
    parentAssignmentId: extension.parentAssignmentId,
    spaceGroups: Object.keys(groups).map((groupId) => ({
      key: groupId,
      value: groups[groupId].name,
      students: groups[groupId].students,
    })),
    spaceId,
    accessToken: getAccessToken(state, spaceId),
    studentIdsWithExistingSubmissions,
    assignmentUpdateError,
    user,
  };
};

const mapDispatchToProps = {
  saveAssignmentExtension: editAssignmentExtension,
  resetErrorState: () => assignmentOperations.resetErrorState(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionModal);
