import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ublend-npm/aulaui-next';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import CancelIcon from '@material-ui/icons/Cancel';

import { HeaderItem, Icon, textClass } from './Header.styled';

const Header = ({
  expanded,
  label,
  leftDecoration,
  onClick,
  dismiss,
  fontColor,
  fontSize,
  iconColor,
  ...props
}) => {
  const iconType = expanded ? CollapseIcon : ExpandIcon;
  const getAriaLabel = () => {
    if (dismiss) {
      return `Dismiss ${label}.`;
    }

    if (expanded) {
      return `Collapse ${label}.`;
    }

    return `Expand ${label}.`;
  };

  return (
    <HeaderItem
      size="small"
      component="div"
      attributes={{ 'aria-label': getAriaLabel(), role: 'combobox' }}
      primaryText={label}
      leftDecoration={leftDecoration}
      rightDecoration={
        <Icon
          IconType={dismiss ? CancelIcon : iconType}
          iconColor={iconColor}
        />
      }
      onClick={onClick}
      fontColor={fontColor}
      classes={{
        text: textClass[fontSize],
      }}
      {...props}
    />
  );
};

Header.propTypes = {
  expanded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  leftDecoration: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  iconColor: PropTypes.string,
  dismiss: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  leftDecoration: null,
  fontColor: theme.color.black,
  iconColor: undefined,
  fontSize: 'small',
  dismiss: false,
  backgroundColor: theme.color.beige,
};

export default Header;
