import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { Dialog, theme } from '@ublend-npm/aulaui-next';
import { STUDENT_ROLE } from '@core/constants/space';
import {
  HANDIN_PROVIDER,
  TURNITIN_PROVIDER,
} from '@core/assignments/constants';
import { AlertDialog } from '../../../common/Dialog';
import {
  DialogContentStyled,
  ModalFooter,
  DeleteButton,
  DialogActions,
} from './ExtensionModal.styled';
import { STUDENTS_VIEW } from './components/Navigation/constants';
import { MAIN_STEP, PROPERTIES_STEP } from './steps/constants';
import TurnitinModal from '../TurnitinModal';
import { SURE_DELETE_EXTENSION } from '../../../../../constants/texts';
import {
  AssignmentCreationErrorType,
  AssignmentType,
  AssignmentUpdateErrorType,
  SpaceGroupType,
} from './ExtensionModal.types';
import steps from './steps/config';
import ExistingSubmissionErrorDialog from './components/ExistingSubmissionErrorDialog/ExistingSubmissionErrorDialog';
import useApi from '../../../../../../core/api/hooks/useApi';
import { SPACE_PARTICIPANTS } from '../../../../../../core/constants/endpoints';

const aulaTheme = createTheme({
  typography: {
    fontFamily: theme.font.family.workSans,
  },
  palette: {
    primary: {
      main: theme.color.purple,
    },
    text: {
      hint: theme.color.grey11,
    },
  },
});

const ExtensionModal = (props) => {
  const {
    assignment: {
      startDate,
      endDate,
      gradesReleasedAt,
      description,
      isHidden,
      extensionName,
      provider,
      isAnonymised,
    },
    studentIds,
    studentIdsWithExistingSubmissions,
    user,
    spaceId,
    accessToken,
    isEditing,
    saveAssignmentExtension,
    openExtension,
    open,
    onClose,
    assignmentCreationError,
    assignmentUpdateError,
  } = props;

  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [step, setStep] = useState(MAIN_STEP);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [data, setData] = useState({
    navigationView: STUDENTS_VIEW,
    assignedStudents: [],
    unassignedStudents: [],
    groups: [],
    startDate,
    endDate,
    gradesReleasedAt,
    description,
    isHidden,
    extensionName,
    user,
    spaceId,
    accessToken,
    isLoadingStudents: isAnonymised,
    editorState:
      provider === HANDIN_PROVIDER
        ? description && JSON.parse(description)
        : description,
  });

  const updateStateData = (newProps) => {
    setData((oldProps) => ({
      ...oldProps,
      ...newProps,
    }));
  };

  const [launchTIIAssignment, setLaunchTIIAssignment] = useState(null);
  useApi(
    {
      url: SPACE_PARTICIPANTS({ spaceId, limit: 2000, role: STUDENT_ROLE }),
    },
    {
      onSuccess: ({ participants }) => {
        const allStudents = participants.map(
          ({ id, firstName, lastName, avatar, email }) => ({
            id,
            avatar,
            email,
            fullName: `${firstName} ${lastName}`,
          })
        );
        updateStateData({
          assignedStudents: allStudents.filter((su) =>
            studentIds.includes(su.id)
          ),
          unassignedStudents: allStudents.filter(
            (su) => !studentIds.includes(su.id)
          ),
          isLoadingStudents: false,
        });
      },
    }
  );

  useEffect(() => {
    props.resetErrorState();
  }, []);

  const studentsWithExistingSubmissions = useMemo(() => {
    if (!data.assignedStudents?.length) {
      return [];
    }
    return studentIdsWithExistingSubmissions.map((studentId) =>
      data.assignedStudents.find((student) => student.id === studentId)
    );
  }, [studentIdsWithExistingSubmissions, data.assignedStudents]);

  const save = async (status) => {
    setLoading(true);
    if (status === 'trash') {
      setIsDeleting(true);
    }
    const assignment = {
      title: data.extensionName,
      description: data.description,
      startDate: data.startDate,
      endDate: data.endDate,
      gradesReleasedAt: data.gradesReleasedAt,
      isHidden: data.isHidden,
    };

    const extension = {
      ...props.assignment,
      ...assignment,
      students: data.assignedStudents.map((student) => student.id),
      ...(status === 'trash' ? { status: 'trash' } : { status: 'live' }),
      parentAssignmentId: props.parentAssignmentId,
    };

    const closeOpenedDialogs = () => {
      setDeleteDialogOpen(false);
      setIsDeleting(false);
      if (status === 'trash') {
        openExtension(props.parentAssignmentId);
      }
    };

    const newAssignmentExtension = await saveAssignmentExtension(
      user,
      spaceId,
      extension,
      accessToken
    );

    closeOpenedDialogs();
    if (newAssignmentExtension) {
      if (provider === TURNITIN_PROVIDER) {
        openExtension(newAssignmentExtension.objectId);
        setLaunchTIIAssignment(newAssignmentExtension);
      } else {
        openExtension(newAssignmentExtension.id);
        onClose();
      }
    } else {
      setLoading(false);
    }
  };

  const handleTIIModalClose = () => {
    setLaunchTIIAssignment(null);
    onClose();
  };

  const stepConfig = steps[step];

  if (!stepConfig) {
    return null;
  }

  const {
    component: Component,
    getRequiredProps = () => ({}),
    actions,
  } = stepConfig;

  const getActions = (selectedStep) => {
    if (!actions) {
      return null;
    }

    const { primary, secondary } = actions;

    const actionsConfig = {
      primary: primary && {
        label: primary.label(isEditing),
        onClick: primary.stepId ? () => setStep(primary.stepId) : null,
        disabled: primary.isDisabled && primary.isDisabled(props, data),
        loading,
      },
      secondary: secondary && {
        label: secondary.label(),
        onClick: secondary.stepId
          ? () => {
              setStep(secondary.stepId);
            }
          : null,
        disabled: secondary.isDisabled && secondary.isDisabled(props, data),
      },
    };

    switch (selectedStep) {
      case PROPERTIES_STEP:
        actionsConfig.primary.onClick = save;
        break;
      default:
        actionsConfig.secondary.onClick = onClose;
        break;
    }

    return (
      <DialogActions
        primary={actionsConfig.primary}
        secondary={actionsConfig.secondary}
        border={false}
      />
    );
  };

  if (studentsWithExistingSubmissions.length) {
    return (
      <ExistingSubmissionErrorDialog
        students={studentsWithExistingSubmissions}
        provider={provider}
        onClose={onClose}
        errorCode={
          isEditing
            ? assignmentUpdateError?.errorCode
            : assignmentCreationError?.errorCode?.code
        }
      />
    );
  }

  return (
    <MuiThemeProvider theme={aulaTheme}>
      <Dialog id="extension-modal" open={open} onClose={onClose}>
        <DialogContentStyled>
          <Component
            data={data}
            step={step}
            setData={updateStateData}
            setStep={setStep}
            {...getRequiredProps({
              ...props,
              isLoadingStudents: data.isLoadingStudents,
            })}
          />
          <ModalFooter isEditing={isEditing}>
            {isEditing && (
              <DeleteButton
                type="text"
                onClick={() => setDeleteDialogOpen(true)}
              >
                Delete extension
              </DeleteButton>
            )}
            <div>{getActions(step)}</div>
          </ModalFooter>
          {deleteDialogOpen && (
            <AlertDialog
              id="confirm-delete-extension"
              title={`Delete extension "${data.extensionName}"`}
              message={SURE_DELETE_EXTENSION}
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              action={{
                label: 'Delete',
                onClick: () => save('trash'),
                danger: true,
                loading: isDeleting,
              }}
              secondaryAction={{
                label: 'Cancel',
                onClick: () => setDeleteDialogOpen(false),
              }}
            />
          )}
        </DialogContentStyled>
      </Dialog>
      {launchTIIAssignment && (
        <TurnitinModal
          user={user}
          assignment={launchTIIAssignment}
          space={{ objectId: spaceId }}
          isEducator
          onClose={handleTIIModalClose}
        />
      )}
    </MuiThemeProvider>
  );
};

ExtensionModal.propTypes = {
  // Modal control props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  openExtension: PropTypes.func,
  saveAssignmentExtension: PropTypes.func.isRequired,
  resetErrorState: PropTypes.func.isRequired,

  // Modal data props
  user: PropTypes.shape({}).isRequired,
  accessToken: PropTypes.string,
  spaceId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  assignment: PropTypes.shape(AssignmentType),
  studentIds: PropTypes.arrayOf(PropTypes.string),
  spaceGroups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)),
  parentAssignmentId: PropTypes.string.isRequired,
  studentIdsWithExistingSubmissions: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  assignmentCreationError: PropTypes.shape(AssignmentCreationErrorType),
  assignmentUpdateError: PropTypes.shape(AssignmentUpdateErrorType),
  isEditing: PropTypes.bool,
};

ExtensionModal.defaultProps = {
  onClose: () => {},
  openExtension: () => {},
  isEditing: false,
  assignment: {},
  studentIds: [],
  spaceGroups: [],
  assignmentCreationError: null,
  assignmentUpdateError: null,
  accessToken: null,
};

export default React.memo(ExtensionModal);
