import React from 'react';

import createSvgIcon from './hoc/createSvgIcon';

export default createSvgIcon({
  svg: () => (
    <>
      <path
        d="M11.3333 13.1667V10.6667H5.5V7.33332H11.3333V4.83332L15.5 8.99999L11.3333 13.1667ZM9.66667 0.666656C10.1087 0.666656 10.5326 0.842251 10.8452 1.15481C11.1577 1.46737 11.3333 1.8913 11.3333 2.33332V3.99999H9.66667V2.33332H2.16667V15.6667H9.66667V14H11.3333V15.6667C11.3333 16.1087 11.1577 16.5326 10.8452 16.8452C10.5326 17.1577 10.1087 17.3333 9.66667 17.3333H2.16667C1.72464 17.3333 1.30072 17.1577 0.988155 16.8452C0.675595 16.5326 0.5 16.1087 0.5 15.6667V2.33332C0.5 1.8913 0.675595 1.46737 0.988155 1.15481C1.30072 0.842251 1.72464 0.666656 2.16667 0.666656H9.66667Z"
        fill="black"
        fill-opacity="0.6"
      />
    </>
  ),
  displayName: 'Logout',
  viewBox: '0 0 16 18',
});
