import React from 'react';
import PropTypes from 'prop-types';
import * as types from '../types';
import Emoji from '../../emoji/Emoji';
import EmojiUtils from '../../../../../core/utils/emoji';
import { StatusText, ConversationTitle } from './ConversationModalTitle.styled';

const ConversationModalTitle = ({ title, status }) => {
  return (
    <>
      <ConversationTitle>{title}</ConversationTitle>
      {status ? (
        <StatusText>
          <Emoji emoji={EmojiUtils.getFromShortName(status.emojiName)} />
          {status.text}
        </StatusText>
      ) : null}
    </>
  );
};

ConversationModalTitle.propTypes = {
  title: PropTypes.string.isRequired,
  status: types.Status,
};

ConversationModalTitle.defaultProps = {
  status: null,
};

export default ConversationModalTitle;
