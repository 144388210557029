import React from 'react';
import { getProgressBarStyles } from './ProgressBar.styled';

export const getPixelGapBetweenMarkers = (
  totalCount: number
): number | undefined => {
  if (!totalCount) {
    return;
  }
  if (totalCount < 30) {
    return 4;
  }
  if (totalCount < 50) {
    return 2;
  }
};

type SectionsProgressInFolder = {
  numberOfSections: number;
  numberOfSectionsCompleted: number;
};

const ContinuousProgressBar = ({
  numberOfSections,
  numberOfSectionsCompleted,
}: SectionsProgressInFolder) => {
  const percentageWidth = (100 / numberOfSections) * numberOfSectionsCompleted;
  return (
    <div
      style={getProgressBarStyles.continuousProgressbarContainer}
      role="meter"
      aria-label="continuous progress bar"
      aria-valuenow={numberOfSectionsCompleted}
      aria-valuemin={0}
      aria-valuemax={numberOfSections}
    >
      <div
        style={getProgressBarStyles.continuousProgressbarBar({
          percentageWidth,
        })}
        title={`continuous progress bar percentage ${percentageWidth}`}
        aria-label={`continuous progress bar percentage ${percentageWidth}`}
        data-testid="continuous-progress-bar-percentage"
      />
    </div>
  );
};

const SectionsProgressBar = (
  { numberOfSections, numberOfSectionsCompleted }: SectionsProgressInFolder,
  pixelGapBetweenSections: number
) => {
  if (!pixelGapBetweenSections) {
    return null;
  }

  const sections = [...Array(numberOfSections)].map((_, index) => {
    const isFilled = index < numberOfSectionsCompleted;
    return (
      <div
        key={index}
        title={`${isFilled ? 'filled' : 'unfilled'} progress section`}
        aria-label={`section-${isFilled ? 'filled' : 'unfilled'}-${index}`}
        style={getProgressBarStyles.sectionsProgressBarContainer({
          isFilled,
          pixelGapBetweenSections,
          isLastSection: index === numberOfSections - 1,
        })}
      />
    );
  });

  return (
    <div
      role="meter"
      style={getProgressBarStyles.progressBarContainer}
      aria-label="Sections progress bar"
      aria-valuenow={numberOfSectionsCompleted}
      aria-valuemin={0}
      aria-valuemax={numberOfSections}
    >
      {sections}
    </div>
  );
};

const ProgressBar = (sectionsProgressInFolder: SectionsProgressInFolder) => {
  if (!sectionsProgressInFolder || !sectionsProgressInFolder.numberOfSections) {
    return null;
  }

  const pixelGapBetweenSections = getPixelGapBetweenMarkers(
    sectionsProgressInFolder.numberOfSections
  );

  if (!pixelGapBetweenSections) {
    return ContinuousProgressBar(sectionsProgressInFolder);
  }

  return SectionsProgressBar(sectionsProgressInFolder, pixelGapBetweenSections);
};

export default ProgressBar;
