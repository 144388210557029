import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@ublend-npm/aulaui-next';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import SearchBar from '../../components/SearchBar/SearchBar';
import UsersList from '../../components/UsersList/UsersList';
import GroupsSelection from '../../components/GroupsSelection/GroupsSelection';
import GroupsSelectionSummary from '../../components/GroupsSelectionSummary/GroupsSelectionSummary';
import {
  STUDENTS_VIEW,
  GROUP_VIEW,
} from '../../components/Navigation/constants';
import { SpaceUserType, SpaceGroupType } from '../../ExtensionModal.types';
import { AlertContainer } from './Properties.styled';
import { CREATING_EXTENSION_SERVER_ERROR } from '../../../constants';

const errorText = CREATING_EXTENSION_SERVER_ERROR;
const USER_ACTION_DISABLED = 'Student submission exists in this assignment.';

const filterOutSelectedStudentsFromUnassigned = (
  unassignedStudents,
  assignedStudents
) =>
  unassignedStudents.reduce((acc, user) => {
    if (assignedStudents.some((student) => student.id === user.id)) {
      return acc;
    }
    return [...acc, user];
  }, []);

const NavigationView = ({
  data: { assignedStudents, groups, unassignedStudents },
  setData,
  spaceGroups,
  navigationView,
  hasError,
  greyedOutUsers,
  isLoadingStudents,
}) => {
  return (
    <>
      {navigationView === STUDENTS_VIEW && (
        <>
          <SearchBar
            users={unassignedStudents}
            selectedUsers={assignedStudents}
            greyedOutUsers={greyedOutUsers}
            isFetching={isLoadingStudents}
            onChange={(selectedStudents) => {
              const updatedUnassignedStudents =
                filterOutSelectedStudentsFromUnassigned(
                  unassignedStudents,
                  selectedStudents
                );
              setData({
                assignedStudents: selectedStudents,
                unassignedStudents: updatedUnassignedStudents,
              });
            }}
          />

          {hasError && (
            <AlertContainer>
              <Alert type="error">{errorText}</Alert>
            </AlertContainer>
          )}

          <UsersList
            users={assignedStudents}
            onActionClick={(studentToUnassign) => {
              const { id } = studentToUnassign;

              const isStudentUnassigned = unassignedStudents.some(
                (student) => student.id === id
              );

              setData({
                unassignedStudents: isStudentUnassigned
                  ? unassignedStudents
                  : [...unassignedStudents, studentToUnassign],
                assignedStudents: assignedStudents.filter(
                  (student) => student.id !== id
                ),
              });
            }}
            actionIcon={RemoveIcon}
            disabledText={USER_ACTION_DISABLED}
          />
        </>
      )}

      {navigationView === GROUP_VIEW && (
        <>
          <GroupsSelection
            selectedGroups={groups}
            spaceGroups={spaceGroups}
            onChange={(e) =>
              setData({
                groups: e.target.value,
              })
            }
          />
          <GroupsSelectionSummary students={groups.students} />
        </>
      )}
    </>
  );
};

NavigationView.propTypes = {
  spaceGroups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)).isRequired,
  data: PropTypes.shape({
    assignedStudents: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
    unassignedStudents: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
    groups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)),
  }).isRequired,
  setData: PropTypes.func.isRequired,
  navigationView: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  isLoadingStudents: PropTypes.bool,
  greyedOutUsers: PropTypes.arrayOf(PropTypes.string),
};

NavigationView.defaultProps = {
  greyedOutUsers: [],
  hasError: false,
  isLoadingStudents: false,
};

export default React.memo(NavigationView);
