import React from 'react';
import PropTypes from 'prop-types';
import Radium, { Style } from 'radium';
import Autosuggest from 'react-autosuggest';
import TagsInput from 'react-tagsinput';
import onClickOutside from 'react-onclickoutside';
import debounce from 'lodash.debounce';
import { theme as AulaUiTheme } from '@ublend-npm/aulaui-next';
import TagItem from './TagItem/TagItem';
import styles, { theme } from './style';
import fonts from '../../../../../../styles/fonts';
import Tag from '../../../../common/Icons/Tag';
import * as TEXT from '../../../../../../constants/texts';

@Radium
class TagList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
      suggestions: [],
      hasFocus: false,
      addingSuggestion: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleNewTags = this.handleNewTags.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.search = this.search.bind(this);
    this.addAriaAttribute = this.addAriaAttribute.bind(this);
    this._currentNumberOfTags = 0;
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }

    this.addAriaAttribute();
  }

  addAriaAttribute() {
    if (
      this.refs &&
      this.refs.tags &&
      this.refs.tags.input &&
      this.refs.tags.input.autowhatever
    ) {
      const autowhateverContainer =
        this.refs.tags.input.autowhatever.itemsContainer;
      autowhateverContainer.setAttribute(
        'aria-label',
        autowhateverContainer.id
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tags.length) {
      this.handleNewTags(nextProps.tags);
    }
  }

  handleNewTags(tags) {
    const filteredTags = tags.filter(
      (tag) =>
        !this.state.tags.find((t) => {
          if (!t.objectId) {
            return false;
          }

          return t.objectId === tag.objectId;
        })
    ); // check if new tags from server

    if (filteredTags.length) {
      this._currentNumberOfTags = tags.length;
      const localTags = this.state.tags
        .filter((tag) => tag.tmp)
        .filter((tag) => !tags.find((t) => t.name === tag.name));

      const stateTags = [...tags, ...localTags];

      this.setState({ tags: stateTags });
      this.forceUpdate();
    }
  }

  handleChange(tags) {
    this.setState({ tags });
  }

  handleRemove(key, id, remove) {
    this._currentNumberOfTags--;
    remove(key);
    this.props.deleteTag(id);
  }

  handleAdd(suggestion) {
    this._currentNumberOfTags++;
    if (suggestion.new) {
      this.props.addTag(suggestion.value);
    } else {
      this.props.addTag(
        suggestion.name,
        suggestion.objectID || suggestion.objectId
      );
    }
  }

  handleClick(tagId) {
    this.props.clickTag(tagId);
  }

  search(v) {
    const value = v.trim();

    let stateSuggestions = Object.keys(this.props.spaceTags)
      .filter((tId) => this.props.spaceTags[tId].name.includes(value))
      .map((tId) => this.props.spaceTags[tId]);

    if (!stateSuggestions.length) {
      stateSuggestions = [{ new: true, value }, ...stateSuggestions];
    }

    this.setState({
      suggestions: stateSuggestions,
    });
  }

  handleUpdate(v) {
    if (v.trim()) {
      if (this.state.currentDebouncedVersion) {
        this.state.currentDebouncedVersion.cancel();
      }
      const currentDebouncedVersion = debounce(() => this.search(v), 200);
      currentDebouncedVersion();
      this.setState({ currentDebouncedVersion });
    }
  }

  handleClickOutside() {
    if (this.state.hasFocus) {
      this.blur();
    }
  }

  focus() {
    this.refs.tags.input.input.focus();
    this.setState({ hasFocus: true });
  }

  blur() {
    this.refs.tags.input.input.blur();
    this.refs.tags.clearInput();
    this.setState({ hasFocus: false });
  }

  render() {
    const { handleUpdate } = this;
    const { handleAdd } = this;

    let suggestions = this.state.suggestions.filter((searchTag) => {
      const tag = this.state.tags.find(
        (t) => t.objectId === searchTag.objectId || t.name === searchTag.value
      );
      if (tag) {
        return false; // already added
      }
      return true;
    });

    const renderTag = (tagProps) => {
      const { key, ...other } = tagProps;
      other._key = key;
      return (
        <TagItem
          key={key}
          showDelete={this.state.hasFocus}
          handleRemove={this.handleRemove}
          handleClick={this.handleClick}
          classId={this.props.post.classRoomId || this.props.post.classRoom}
          {...other}
        />
      );
    };

    const renderLayout = (tagComponents, inputComponent) => (
      <div style={styles.tagContainer}>
        {tagComponents}
        {inputComponent}
      </div>
    );
    const AutocompleteInput = (props) => {
      const { ...other } = props;
      delete other.addTag;

      return (
        <div className="autosuggestContainer">
          <Style
            scopeSelector=".autosuggestContainer"
            rules={{
              '.react-tagsinput-input': {
                ...fonts.light,
                fontSize: 12,
                lineHeight: '20px',
              },
              '.react-tagsinput-input::-webkit-input-placeholder': {
                fontSize: 12,
              },
              '.react-tagsinput-input:-moz-placeholder': {
                fontSize: 12,
              },
              '.react-tagsinput-input::-moz-placeholder': {
                fontSize: 12,
              },
              '.react-tagsinput-input:-ms-input-placeholder': {
                fontSize: 12,
              },
            }}
          />
          <Autosuggest
            ref={props.ref}
            suggestions={suggestions}
            onSuggestionsClearRequested={() => {
              suggestions = [];
            }}
            shouldRenderSuggestions={(value) =>
              value && value.trim().length > 0
            }
            getSuggestionValue={(suggestion) => suggestion.name}
            highlightFirstSuggestion
            renderSuggestion={(suggestion) => {
              if (suggestion.new) {
                return (
                  <div>
                    <span style={styles.tagNew}>{TEXT.NEW_TAG}</span>
                    <span>{suggestion.value}</span>
                  </div>
                );
              }
              return suggestion.name;
            }}
            inputProps={{ ...other, onFocus: this.focus }}
            onSuggestionsFetchRequested={({ value }) => handleUpdate(value)}
            onSuggestionSelected={(e, { suggestion }) => {
              handleAdd(suggestion);
              this.refs.tags.clearInput();
              this.refs.tags.input.input.value = '';
            }}
            theme={theme}
          />
        </div>
      );
    };

    const placeholder = this.props.canAdd ? 'Add a tag' : '';

    return (
      <div
        style={{
          ...styles.container,
          backgroundColor: this.state.hasFocus ? AulaUiTheme.color.grey0 : '',
        }}
      >
        <Tag style={styles.tagIcon} />

        <TagsInput
          ref="tags"
          renderInput={AutocompleteInput}
          renderTag={renderTag}
          renderLayout={renderLayout}
          value={this.state.tags}
          onChange={this.handleChange}
          tagDisplayProp="name"
          className=""
          addKeys={[]}
          removeKeys={[]}
          disabled={!this.props.canAdd}
          inputProps={{ placeholder, 'aria-label': placeholder }}
        />
      </div>
    );
  }
}

TagList.propTypes = {
  post: PropTypes.shape().isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string,
      name: PropTypes.string,
      tagPostId: PropTypes.string,
    })
  ).isRequired,
  spaceTags: PropTypes.shape(),
  search: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  clickTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  canAdd: PropTypes.bool.isRequired,
};

TagList.defaultProps = {
  spaceTags: {},
  autoFocus: false,
};

export default onClickOutside(TagList);
