import { connect } from 'react-redux';
import { INBOX_MESSAGE_ITEM_TYPE } from '@core/constants/analytics';
import * as userSelectors from '@core/selectors/user';
import {
  operations as creators,
  selectors as inboxSelectors,
} from '../../../../store/conversations';
import { operations as reactionOperations } from '../../../../store/reactions';
import Message from './Message';

const mapStateToProps = (state, ownProps) => {
  const { content, createdAt, updatedAt, writer, failed, repliedMessage } =
    inboxSelectors.message(state, ownProps.messageId);

  return {
    content,
    createdAt,
    currentUserId: userSelectors.currentUserId(state),
    updatedAt,
    writerId: writer,
    failed,
    repliedMessage,
    highlighted: inboxSelectors.isMessageHighlighted(state, ownProps.messageId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openEditMessage: () => dispatch(creators.openEditMessage(ownProps.messageId)),
  replyToMessage: () => dispatch(creators.replyToMessage(ownProps.messageId)),
  toggleReaction: ({ emojiName }) =>
    dispatch(
      reactionOperations.toggleReaction({
        itemId: ownProps.messageId,
        itemType: INBOX_MESSAGE_ITEM_TYPE,
        emojiName,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
