import {
  MaterialProgressStatus,
  UserProgress,
  UpdateUserProgressContract,
} from '@ublend-npm/aula-schema/types/materials';
import { UPDATE_MATERIAL_SECTION_PROGRESS } from '../../constants/endpoints';
import request, { getData } from './request';

const updateMaterialProgress = async (
  spaceId: string,
  sectionId: string,
  progressStatus: MaterialProgressStatus
): Promise<UserProgress> => {
  const url = UPDATE_MATERIAL_SECTION_PROGRESS(spaceId, sectionId);

  return request<UpdateUserProgressContract>({
    method: 'put',
    url,
    data: { progressStatus },
  }).then(getData);
};

export default updateMaterialProgress;
