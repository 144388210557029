import safeParse from '../safeParse';

const getFileText = (file) => {
  if (!file) {
    return null;
  }
  const { name, type: fileType } = file;
  if (!fileType) {
    return null;
  }

  const mimeTypeMap = {
    image: '📷 Image',
    audio: '🎵 Audio',
    video: '🎥 Video',
  };
  const mainMimeType = fileType.split('/')[0];
  const textForType = mimeTypeMap[mainMimeType];
  return textForType || `📁 ${name}`;
};

const getAtomicText = (block) => {
  const { data } = block;
  if (!data || !data.type) {
    return null;
  }

  const { type } = data;
  switch (type) {
    case 'link':
      return `🔗 ${data.link}`;
    case 'file':
      return getFileText(data.file);
    case 'video':
      return '🎥 Video';
    default:
      return null;
  }
};

const getBlockContent = (block) => {
  switch (block.type) {
    case 'unstyled':
      return block.text;
    case 'atomic':
      return getAtomicText(block);
    case 'code-block':
      return '⌨️ Code';
    default:
      return null;
  }
};

const getTh90ContentSummary = ({ content, allBlocks = false }) => {
  const { blocks } = safeParse(content);

  if (!blocks || blocks.length === 0) {
    return null;
  }

  if (allBlocks) {
    return blocks.map((block) => getBlockContent(block));
  }

  const firstBlock = blocks[0];
  return [getBlockContent(firstBlock)];
};

export default getTh90ContentSummary;
