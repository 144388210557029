import styled from 'react-emotion';

const FieldRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.spaced ? 'space-between' : 'flex-start'};
  width: 100%;
`;

export default FieldRow;
