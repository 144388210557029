import { connect } from 'react-redux';
import OmniSearch from './OmniSearch';

import {
  openModal,
  closeModal,
  fetchResults,
  clearResults,
} from '../../../../core/actions/omniSearch';

const mapStateToProps = (state) => {
  const spacesNames = Object.values(state.data.classRooms).reduce(
    (acc, classRoom) => ({
      ...acc,
      [classRoom.objectId]: classRoom.name,
    }),
    {}
  );
  const conversationsTitles = Object.values(
    state.data.inboxv2.conversations
  ).reduce(
    (acc, conversation) => ({
      ...acc,
      [conversation.id]: conversation.title,
    }),
    {}
  );

  return {
    open: state.omniSearch.open,
    loading: state.omniSearch.loading,
    results: state.omniSearch.results,
    tabs: state.omniSearch.tabs,
    footerMap: {
      ...spacesNames,
      ...conversationsTitles,
    },
    classId: state.classRoom.current,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openModal: openModal(dispatch),
  closeModal: closeModal(dispatch),
  fetchResults: fetchResults(dispatch),
  clearResults: clearResults(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OmniSearch);
