import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, Tooltip } from '@ublend-npm/aulaui-next';
import { avatarContainer, avatarName } from './EducatorParticipant.styled';

const EducatorParticipant = ({
  name,
  src,
  online,
  colourSeed,
  fullName,
  isFull,
}) => {
  if (isFull) {
    return (
      <div className={avatarContainer}>
        <Avatar
          name={name}
          src={src}
          online={online}
          colourSeed={colourSeed}
          size="small"
        />
        <span className={avatarName}>{fullName}</span>
      </div>
    );
  }

  return (
    <Tooltip title={fullName} position="bottom">
      <div>
        <Avatar
          name={name}
          src={src}
          online={online}
          colourSeed={colourSeed}
          size="small"
        />
      </div>
    </Tooltip>
  );
};

EducatorParticipant.propTypes = {
  name: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  src: PropTypes.string,
  online: PropTypes.bool.isRequired,
  colourSeed: PropTypes.string.isRequired,
  isFull: PropTypes.bool,
};

EducatorParticipant.defaultProps = {
  isFull: false,
  src: '',
};

export default EducatorParticipant;
