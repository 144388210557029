import React, { useRef } from 'react';
import { cx } from 'emotion';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import { DND_TYPE } from './MaterialListItem.dnd';
import { AddSectionListItem, AddIcon } from './AddSectionButton.styled';
import { buttonItem } from './SectionListItem.styled';
import { listItem } from './MaterialListItem.styled';

const CLASSES = {
  text: 'addNewPageLabel',
};
const style = {
  listStyle: 'none',
  padding: 0,
};

const AddSectionButton = ({ onDrop, addSection }) => {
  const buttonRef = useRef(null);
  const [{ isDraggingOver }, drop] = useDrop({
    accept: DND_TYPE,
    canDrop: (item) => !item.isFolder,
    collect: (monitor) => ({
      isDraggingOver: monitor.canDrop() && monitor.isOver(),
    }),
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }

      onDrop(item.materialId);
    },
  });

  drop(buttonRef);

  return (
    <ul ref={buttonRef} style={style}>
      <AddSectionListItem
        attributes={{ 'data-testid': 'add-folder-page' }}
        classes={CLASSES}
        className={cx(listItem, buttonItem)}
        textTruncate
        primaryText="New page"
        leftDecoration={<AddIcon />}
        onClick={addSection}
        dropType={isDraggingOver ? 'before' : null}
      />
    </ul>
  );
};

AddSectionButton.propTypes = {
  onDrop: PropTypes.func.isRequired,
  addSection: PropTypes.func.isRequired,
};

export default AddSectionButton;
