import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { defaultMemoize } from 'reselect';
import { useSelector } from 'react-redux';

import { institutionShortName } from '@core/selectors/institution';
import { Loader, Button } from '@ublend-npm/aulaui-next';
import { LTI1P3_PROVIDER, QUIZZES_PROVIDER } from '@core/assignments/constants';
import pushRoute from '@app/utils/routing';
import assignmentPropTypes from '../Assignments/utils/assignment.proptype';
import ExtensionsPane from './ExtensionsPane';
import SubmissionsDownloadAll from './SubmissionsDownloadAll';
import {
  TopBar,
  Container,
  DropdownStyle,
  AssignmentTitle,
  ContentWrapper,
  SubmissionsContent,
  SubmissionsCommandBar,
  DropdownWrapper,
  DropdownItemStyle,
  NavigationInlineStyle,
  AssignmentTitleWrapper,
} from './Submissions.styled';
import { Select } from '../../common/FieldSets';
import SubmissionsList from './SubmissionsList';
import SubmissionGroups from './FilterSubmissions/FilterSubmissions';
import { MANAGE_GROUPS, FOOTER_ACTIONS } from './constants';
import { SURE_DELETE_EXTENSION } from '../../../../constants/texts';
import groupSubmissionsByDate from './groupSubmissionsByDate';
import PostNavigation from '../Feed/PostNavigation/PostNavigation.container';
import formatGroupSubmissions from './GroupSubmissions/formatGroupSubmissions';
import { analyticsTrackEvent } from '../../../../../core/utils/analytics';
import * as analyticEvents from '../../../../../core/constants/analytics';
import CreateExtensionModal from '../Assignments/ExtensionModal/CreateExtensionModal.container';
import EditExtensionModal from '../Assignments/ExtensionModal/EditExtensionModal.container';

import AssignmentTypeProvider from './AssignmentTypeProvider';
import AssignmentDetails from './AssignmentDetails/AssignmentDetails';
import ConversionModalContainer from '../Assignments/ExtensionModal/ConversionModal.container';
import { HourGlassHalfFullIcon } from '../../common/Icons/HourGlassHalfFullIcon';
import { AlertDialog } from '../../common/Dialog';
import ConversionSection from './ConversionSection';
import useAnonymousExtensionsFlag from './hooks/useAnonymousExtensionsFlag';
import QuizDecommissionBanner from '../Assignments/Quizzes/QuizDecommissionBanner';

const getFormattedGroupSubmissions = defaultMemoize((submissions) =>
  formatGroupSubmissions(submissions)
);

const getStudentsWithNoGroup = defaultMemoize((groupSet, sets) =>
  sets.find((set) => set.key === groupSet)
);

const SubmissionsPage = memo((props) => {
  const {
    user,
    sets,
    spaceName,
    accessToken,
    assignmentId,
    parentAssignmentSubmissions,
    isFetchingSubmissions,
    fetchSubmissionsErrored,
    hasFetchedSubmissions,
    fetchSubmissions,
    assignment,
    assignments,
    hasFetchedAssignments,
    fetchAssignmentsErrored,
    isFetchingAssignments,
    fetchAssignments,
    accessingAsStaff,
    deleteSubmission,
    spaceId,
    getSubmissions,
    extensions,
    hasExtensions,
    getSubmissionsFetchedStatus,
    editExtension,
    isEducator,
    showToast,
  } = props;

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedExtensionId, setSelectedExtensionId] = useState(assignment.id);
  const [isCreatingExtension, setCreationExtensionState] = useState(false);
  const [editedExtension, setEditingExtensionState] = useState(null);
  const [isConvertingExtension, setIsConvertingExtension] = useState(false);
  const [extensionForDeletion, setExtensionForDeletion] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const openExtension = (id) => {
    if (!isFetchingSubmissions) {
      fetchSubmissions({
        accessToken,
        assignmentId: id,
      });
    }

    setSelectedExtensionId(id);
  };
  const handleClickEdit = async (id) => {
    if (!isFetchingSubmissions) {
      fetchSubmissions({
        accessToken,
        assignmentId: id,
      });
    }

    setEditingExtensionState(id);
  };

  const handleClickDelete = (extension) => {
    setExtensionForDeletion(extension);
  };

  const handleDeleteExtension = async () => {
    setIsDeleting(true);
    await editExtension({
      currentUser: user,
      spaceId,
      assignment: { ...extensionForDeletion, status: 'trash' },
      accessToken,
    });
    setIsDeleting(false);
    setExtensionForDeletion(false);
    openExtension(assignment.id);
  };

  const handleVisibilityChange = async (isHidden = false, extension) => {
    editExtension({
      currentUser: user,
      spaceId,
      assignment: { ...extension, isHidden },
      accessToken,
    });
  };

  const handleFetchAssignments = async () => {
    if (!hasFetchedAssignments && !isFetchingAssignments) {
      fetchAssignments({
        user,
        accessToken,
        spaceId,
      });
    }
  };

  const handleFetchSubmissions = async (group = null) => {
    if (!isFetchingSubmissions) {
      fetchSubmissions({ accessToken, assignmentId, group });
    }
    if (group) {
      analyticsTrackEvent(analyticEvents.FILTER_SUBMISSIONS_BY_GROUP_ID, {
        activeClassroom: spaceId,
        assignmentId,
        groupId: group,
      });
    } else {
      setSelectedGroup(null);
    }
  };

  const updateDocumentTitle = () => {
    const space = spaceName ? `${spaceName} - ` : '';
    document.title = `Submissions - ${space}Aula`;
  };

  const prevAccessToken = useRef();
  useEffect(() => {
    if (accessToken && !prevAccessToken.current) {
      handleFetchAssignments();
      handleFetchSubmissions();
      prevAccessToken.current = accessToken;
    }
  }, [accessToken]);

  useEffect(() => {
    if (assignmentId) {
      handleFetchSubmissions();
    }
  }, [assignmentId]);

  useEffect(() => {
    handleFetchSubmissions(selectedGroup);
  }, [assignment]);

  useEffect(() => {
    updateDocumentTitle();
  }, [spaceName]);

  useEffect(() => {
    openExtension(assignment.id);
  }, [assignment.id]);

  const handleSelectChange = (key) => {
    switch (key) {
      case FOOTER_ACTIONS:
        setSelectedGroup(null);
        handleFetchSubmissions();
        break;
      case MANAGE_GROUPS: {
        const { openGroups } = props;
        openGroups(spaceId);
        break;
      }
      default:
        setSelectedGroup(key);
        handleFetchSubmissions(key);
        break;
    }
  };

  const memoizedExtensionFinder = useCallback(
    ({ id }) => id === selectedExtensionId,
    [selectedExtensionId]
  );

  const currentAssignment = hasExtensions
    ? extensions.find(memoizedExtensionFinder)
    : assignment;

  const currentSubmissions = currentAssignment
    ? getSubmissions(currentAssignment.id)
    : parentAssignmentSubmissions;

  const isGroupAssignment = !!assignment.groupSet;
  const { isAnonymised, provider } = assignment;
  const submissionsData = isGroupAssignment
    ? getFormattedGroupSubmissions(parentAssignmentSubmissions)
    : currentSubmissions;
  const submissionGroups = groupSubmissionsByDate(
    submissionsData,
    (currentAssignment || assignment).endDate
  );

  const { earlySubmissions, lateSubmissions, noSubmissions } = submissionGroups;
  // @totalCount gets the correct count for students/groups in individual & group assignments
  // submission length would give incorrect count for group assignments
  const totalCount =
    earlySubmissions.length + lateSubmissions.length + noSubmissions.length;
  const totalSubmissions = earlySubmissions.length + lateSubmissions.length;

  const isQuizSubmission = currentAssignment?.provider === QUIZZES_PROVIDER;

  const isLti1P3Provider = currentAssignment?.provider === LTI1P3_PROVIDER;

  const isExtensionsEnabledForAnonymous = useAnonymousExtensionsFlag({
    provider,
    isAnonymised,
  });

  const hasError = fetchSubmissionsErrored || fetchAssignmentsErrored;

  const institution = useSelector(institutionShortName);
  const uwsInstitutions = ['uws', 'uws-test', 'pa-assessments'];
  const coventryInstitutions = [
    'coventry',
    'cov-bfmat',
    'cov-cug',
    'cov-london',
    'cov-test',
    'pa-core',
  ];

  const uwsInfoMsg = uwsInstitutions.includes(institution);
  const coventryInfoMsg = coventryInstitutions.includes(institution);
  const genericInfoMsg = !uwsInfoMsg && !coventryInfoMsg;

  const isAssignmentUnavailable = hasFetchedAssignments && !assignment.id;

  useEffect(() => {
    const deletedExt = extensions?.some((ex) => ex.id === editedExtension);
    if (!deletedExt && editedExtension) {
      setEditingExtensionState(null);
      showToast({
        message: `This extension is no longer available. It could have been hidden or deleted by an educator.`,
      });
    }
  }, [extensions?.length, editedExtension]);

  if (isAssignmentUnavailable) {
    pushRoute(`dashboard/${spaceId}/journey/assignments`);
    showToast({
      message:
        'This assignment is no longer available. It could have been hidden or deleted by an educator.',
    });
    return null;
  }
  if (hasError) {
    return (
      <Container>
        <TopBar>
          <PostNavigation
            style={NavigationInlineStyle}
            prevPage="all assignments"
          />
        </TopBar>
        <TopBar>
          <p>An error occured while loading submissions. Please refresh!</p>
        </TopBar>
      </Container>
    );
  }

  const shouldRenderExtensionsButton =
    !isQuizSubmission &&
    !isGroupAssignment &&
    !hasExtensions &&
    !isLti1P3Provider &&
    isExtensionsEnabledForAnonymous &&
    isEducator;

  const loading =
    !hasFetchedAssignments ||
    !hasFetchedSubmissions ||
    (currentAssignment && !getSubmissionsFetchedStatus(currentAssignment.id));

  const { studentsWithNoGroup } = getStudentsWithNoGroup(
    assignment.groupSet,
    sets
  ) || { studentsWithNoGroup: [] };

  // Submissions cannot be filtered by institution managed groups
  // as institution managed group sets cannot be attached to assignments,
  // hence they are excluded from the group filter options.
  const groupFilterOptions = sets.filter((set) => !set.externalId);
  return (
    <AssignmentTypeProvider
      assignment={currentAssignment || {}}
      spaceId={spaceId}
      user={user}
    >
      <Container>
        <TopBar>
          <PostNavigation
            style={NavigationInlineStyle}
            prevPage="all assignments"
          />
        </TopBar>
        {genericInfoMsg && isQuizSubmission && <QuizDecommissionBanner />}
        {coventryInfoMsg && isQuizSubmission && (
          <QuizDecommissionBanner institution="coventry" />
        )}
        {uwsInfoMsg && isQuizSubmission && (
          <QuizDecommissionBanner institution="uws" />
        )}

        {!hasExtensions && loading ? (
          <TopBar data-testid="submissions-loader">
            <Loader size={25} />
          </TopBar>
        ) : (
          <>
            <AssignmentTitleWrapper>
              <AssignmentTitle data-testid="assignment-title">
                {assignment.title}
              </AssignmentTitle>
              <div>
                {!isQuizSubmission && !isLti1P3Provider && isEducator && (
                  <ConversionSection
                    assignment={currentAssignment}
                    hasExtensions={hasExtensions}
                    setIsConvertingExtension={setIsConvertingExtension}
                    assignments={assignments}
                  />
                )}
                {!isQuizSubmission && shouldRenderExtensionsButton && (
                  <Button
                    type="primary"
                    iconLeft={HourGlassHalfFullIcon}
                    onClick={
                      !accessingAsStaff &&
                      (() => setCreationExtensionState(true))
                    }
                    size="small"
                    disabled={accessingAsStaff}
                  >
                    New extension
                  </Button>
                )}
              </div>
            </AssignmentTitleWrapper>
            <ContentWrapper>
              {hasExtensions && (
                <ExtensionsPane
                  extensions={extensions}
                  handleClick={openExtension}
                  openExtension={openExtension}
                  handleEdit={handleClickEdit}
                  openCreateExtensionModal={() =>
                    setCreationExtensionState(true)
                  }
                  handleDelete={handleClickDelete}
                  handleVisibilityChange={handleVisibilityChange}
                  selectedExtensionId={selectedExtensionId}
                  parentAssignmentSubmissions={parentAssignmentSubmissions}
                  isEducator={isEducator}
                />
              )}
              {currentAssignment && (
                <SubmissionsContent>
                  {!loading && (
                    <>
                      <AssignmentDetails
                        assignment={currentAssignment}
                        submissionCount={totalSubmissions}
                        totalStudentsCount={totalCount}
                        parentAssignment={assignment}
                        loading={loading}
                      />

                      <SubmissionsCommandBar>
                        <DropdownWrapper id="group-dropdown">
                          <Select
                            label="Filter by group"
                            id="tags-select"
                            selectedKey={selectedGroup}
                            onChange={handleSelectChange}
                            onDismiss={selectedGroup && handleSelectChange}
                            dataSet={groupFilterOptions}
                            DropdownStyle={DropdownStyle}
                            DropdownItemType={SubmissionGroups}
                            headerTextTruncate
                            isLoading={false}
                            headerFontSize="medium"
                            showKeyInHeader
                            ariaLabelledby="group-dropdown"
                            DropdownItemStyle={DropdownItemStyle}
                          />
                        </DropdownWrapper>
                        <SubmissionsDownloadAll
                          currentUserIsEducator={isEducator}
                          assignmentExternalId={assignment.externalId}
                          accessToken={accessToken}
                          isGroupAssignment={isGroupAssignment}
                          isAnonymisedAssignment={isAnonymised}
                          submissionCount={totalSubmissions}
                        />
                      </SubmissionsCommandBar>
                    </>
                  )}
                  <SubmissionsList
                    submissions={currentSubmissions}
                    submissionGroups={submissionGroups}
                    loading={loading}
                    assignmentId={currentAssignment?.id}
                    assignmentExternalId={currentAssignment?.externalId}
                    isGroupAssignment={isGroupAssignment}
                    studentsWithNoGroup={studentsWithNoGroup}
                    accessingAsStaff={accessingAsStaff}
                    deleteSubmission={deleteSubmission}
                    spaceId={spaceId}
                    isExtension={!!currentAssignment?.parentAssignmentId}
                    isAnonymised={isAnonymised}
                  />
                </SubmissionsContent>
              )}
            </ContentWrapper>
          </>
        )}

        {isCreatingExtension && !isQuizSubmission && (
          <CreateExtensionModal
            open={isCreatingExtension}
            assignmentId={assignmentId}
            openExtension={openExtension}
            onClose={() => setCreationExtensionState(false)}
          />
        )}

        {editedExtension && !isQuizSubmission && (
          <EditExtensionModal
            open={!!editedExtension}
            extensionId={editedExtension}
            openExtension={openExtension}
            onClose={() => {
              setEditingExtensionState(null);
            }}
          />
        )}

        {isConvertingExtension && !isQuizSubmission && (
          <ConversionModalContainer
            open={isConvertingExtension}
            assignmentId={assignmentId}
            onClose={(openConvertedExtension) => {
              setIsConvertingExtension(false);
              if (
                openConvertedExtension &&
                typeof openConvertedExtension === 'function'
              ) {
                openConvertedExtension();
              }
            }}
          />
        )}

        {extensionForDeletion && !isQuizSubmission && (
          <AlertDialog
            id="confirm-delete-extension"
            title={`Delete extension "${extensionForDeletion?.title}"`}
            message={SURE_DELETE_EXTENSION}
            open={!!extensionForDeletion}
            onClose={() => setExtensionForDeletion(false)}
            action={{
              label: 'Delete',
              onClick: () => handleDeleteExtension(),
              danger: true,
              loading: isDeleting,
            }}
            secondaryAction={{
              label: 'Cancel',
              onClick: () => setExtensionForDeletion(false),
            }}
          />
        )}
      </Container>
    </AssignmentTypeProvider>
  );
});

SubmissionsPage.propTypes = {
  sets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName: PropTypes.string,
  }).isRequired,
  spaceId: PropTypes.string,
  assignment: PropTypes.oneOfType([PropTypes.shape({}), assignmentPropTypes]),
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  parentAssignmentSubmissions: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  isFetchingSubmissions: PropTypes.bool.isRequired,
  fetchSubmissionsErrored: PropTypes.bool.isRequired,
  hasFetchedSubmissions: PropTypes.bool.isRequired,
  accessToken: PropTypes.string,
  spaceName: PropTypes.string,
  assignmentId: PropTypes.string.isRequired,
  openGroups: PropTypes.func.isRequired,
  fetchSubmissions: PropTypes.func.isRequired,
  editExtension: PropTypes.func.isRequired,
  hasFetchedAssignments: PropTypes.bool.isRequired,
  fetchAssignmentsErrored: PropTypes.bool,
  isFetchingAssignments: PropTypes.bool.isRequired,
  fetchAssignments: PropTypes.func.isRequired,
  deleteSubmission: PropTypes.func.isRequired,
  accessingAsStaff: PropTypes.bool.isRequired,
  isEducator: PropTypes.bool.isRequired,
  extensions: PropTypes.arrayOf(assignmentPropTypes),
  hasExtensions: PropTypes.bool.isRequired,
  getSubmissions: PropTypes.func.isRequired,
  getSubmissionsFetchedStatus: PropTypes.func.isRequired,
};

SubmissionsPage.defaultProps = {
  accessToken: undefined,
  assignment: {},
  spaceId: '',
  spaceName: '',
  extensions: undefined,
  fetchAssignmentsErrored: false,
};

export default SubmissionsPage;
