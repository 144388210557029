import request from '../request';
import { REGEN_SESSION_TOKEN } from '../../constants/endpoints';

const regenerateSessionToken = () =>
  request({
    method: 'post',
    url: REGEN_SESSION_TOKEN(),
  });

export default regenerateSessionToken;
