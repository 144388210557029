import { MaterialProgressStatus } from '@ublend-npm/aula-schema/types/materials';
import { AulaReduxAction } from '../../types/state';
import updateMaterialProgressApi from '../api/updateMaterialProgress';
import { getCurrentSpaceId } from '../../selectors/space';
import { UpdateMaterialProgressAction } from '../sharedActions';
import { CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS } from '../actions';
import showToast from '../../toasts/operations/showToast.operation';

type UpdateMaterialProgressActionFactory = (
  sectionId: string,
  userProgress: MaterialProgressStatus
) => AulaReduxAction<void, UpdateMaterialProgressAction>;

export const updateMaterialProgress: UpdateMaterialProgressActionFactory =
  (materialId, userProgress) => async (dispatch, getState) => {
    dispatch({
      type: CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS,
      status: 'started',
      payload: { materialId },
    });

    const spaceId = getCurrentSpaceId(getState());

    try {
      const updatedProgress = await updateMaterialProgressApi(
        spaceId,
        materialId,
        userProgress
      );

      dispatch({
        type: CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS,
        status: 'success',
        payload: { updatedProgress, materialId },
      });
    } catch (error) {
      dispatch({
        type: CLASSROOM_MODULE_UPDATE_SECTION_PROGRESS,
        status: 'error',
        payload: { materialId },
      });
      dispatch(
        showToast({
          message: `We can't mark this as ${userProgress} right now. Please try again later`,
        })
      );
    }
  };
