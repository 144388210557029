import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const UploadButton = styled('input')`
  display: none;
  color: ${theme.color.designSystem.blackOpacity60};
`;

export const moreMenu = css`
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const menuItem = css`
  min-width: 160px;
  margin: 8px 0px;
`;
