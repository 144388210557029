import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ublend-npm/aulaui-next';
import getContentSummary from '../../../../../core/utils/getContentSummary';

import {
  ReplyContainer,
  ReplyIcon,
  ClearIcon,
  Author,
  RepliedMessageContainer,
  ReplyMessage,
  Container,
} from './Reply.styled';

const Reply = ({ content, author, dismissReply }) => {
  const [contentSummary, setContentSummary] = useState(null);
  useEffect(() => {
    setContentSummary(getContentSummary({ content })[0]);
  }, [content]);

  return (
    <Container>
      <ReplyContainer>
        <ReplyIcon color={`${theme.color.mediumBlue}`} />
        <RepliedMessageContainer>
          <Author>{`${author.firstName} ${author.lastName}`}</Author>
          <ReplyMessage>{contentSummary}</ReplyMessage>
        </RepliedMessageContainer>
        <ClearIcon
          color={theme.color.designSystem.purple70}
          onClick={dismissReply}
        />
      </ReplyContainer>
    </Container>
  );
};

Reply.propTypes = {
  content: PropTypes.string.isRequired,
  author: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  dismissReply: PropTypes.func.isRequired,
};

export default Reply;
