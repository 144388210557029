import { connect } from 'react-redux';
import ExtensionModal from './ExtensionModal';
import {
  operations as assignmentOperations,
  selectors,
} from '../../../../../store/assignments';
import { accessToken as getAccessToken } from '../../../../../../core/selectors/user';
import { extractStudentIdsFromErrorMessage } from '../utils/extractStudentsFromError';

const createAssignmentExtension = (user, spaceId, assignment, accessToken) => {
  return assignmentOperations.createAssignment({
    currentUser: user,
    spaceId,
    assignment: { ...assignment, status: 'live' },
    accessToken,
  });
};

const mapStateToProps = (state, { assignmentId }) => {
  const { user } = state.user;
  const { groups } = state.data.groups;
  const {
    provider,
    startDate,
    endDate,
    gradesReleasedAt,
    description,
    isHidden,
    gradeType,
    shouldCloseOnDueDate,
    isAnonymised,
    id,
  } = selectors.getAssignment(state, assignmentId);
  const { selectedSpace: spaceId } = state.assignments || {};
  const assignmentCreationError = selectors.getAssignmentCreationError(state);
  const hasSubmissionInParent = selectors.hasSubmissionInParent(state);

  const studentIdsWithExistingSubmissions = hasSubmissionInParent
    ? extractStudentIdsFromErrorMessage(
        assignmentCreationError.errorCode.detail
      )
    : [];

  return {
    title: 'New extension',
    assignment: {
      provider,
      startDate,
      endDate,
      gradesReleasedAt,
      description,
      isHidden,
      gradeType,
      shouldCloseOnDueDate,
      isAnonymised,
    },
    studentIds: [],
    parentAssignmentId: id,
    spaceGroups: Object.keys(groups).map((groupId) => ({
      key: groupId,
      value: groups[groupId].name,
      students: groups[groupId].students,
    })),
    spaceId,
    accessToken: getAccessToken(state, spaceId),
    user,
    studentIdsWithExistingSubmissions,
    assignmentCreationError,
  };
};

const mapDispatchToProps = {
  saveAssignmentExtension: createAssignmentExtension,
  resetErrorState: () => assignmentOperations.resetErrorState(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionModal);
