// Turns S3 urls into CloudFront distribution ones. All buckets are called <env>-parse-bucket
// except the app one that's called ublend-parse-storage
const FileBackwardsCompatTransforms = [
  {
    regex: /https:\/\/((.*)-(parse-bucket|parse-storage).s3.amazonaws.com)\//,
    handler: (_, __, env) =>
      `https://files.${env === 'ublend' ? 'app' : env}.aula.education/`,
  },
  {
    regex: /https:\/\/s3..*amazonaws.com\/(.*)-(parse-bucket|parse-storage)\//,
    handler: (_, __, env) =>
      `https://files.${env === 'ublend' ? 'app' : env}.aula.education/`,
  },
];

const getNewFileUrl = (fileUrl = '') =>
  FileBackwardsCompatTransforms.reduce(
    (url, transform) => url.replace(transform.regex, transform.handler),
    fileUrl
  );

export default getNewFileUrl;
