import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@ublend-npm/aulaui-next';

import { MAIN_STEP } from '../constants';
import {
  Root,
  StudentsWrapper,
  TabsWrapper,
  ArrowBack,
} from './StudentsList.styled';
import UsersList from '../../components/UsersList/UsersList';
import { Tab, Tabs } from '../../../../../common/Tabs';
import { ExtensionModalDataType } from '../../ExtensionModal.types';

const StudentsList = ({
  setStep,
  data: { extensionName, assignedStudents, unassignedStudents },
  setData,
}) => {
  const allStudents = [...assignedStudents, ...unassignedStudents];
  return (
    <Root>
      <Button
        type="text"
        size="small"
        iconLeft={ArrowBack}
        onClick={() => setStep(MAIN_STEP)}
      >
        Back{`${extensionName ? ` to ${extensionName}` : ''}`}
      </Button>

      <StudentsWrapper>
        <TabsWrapper>
          <Tabs id="space-settings-tabs" value={0}>
            {/** TODO: Include available extensions as tabs */}
            <Tab label={`Main cohort (${unassignedStudents.length})`} />
          </Tabs>
        </TabsWrapper>

        <UsersList
          users={allStudents}
          selectedUsers={assignedStudents}
          onActionClick={(student) =>
            setData({
              assignedStudents: [...assignedStudents, student],
              unassignedStudents: unassignedStudents.filter(
                (user) => user.id !== student.id
              ),
            })
          }
          actionIcon={AddIcon}
          tooltip={`Add${extensionName ? ` to ${extensionName}` : ''}`}
        />
      </StudentsWrapper>
    </Root>
  );
};

StudentsList.propTypes = {
  data: PropTypes.shape(ExtensionModalDataType).isRequired,
  setData: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default React.memo(StudentsList);
