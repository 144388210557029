import PropTypes from 'prop-types';
import React from 'react';
import { theme, Button, Tooltip } from '@ublend-npm/aulaui-next';

import Avatar from '../../../containers/User/Avatar/Avatar';
import { ENTER } from '../../../../utils/keyCodes';
import styles from './style';
import Remove from '../../common/Icons/Remove';

class ParticipantsListUserBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonHover: false,
      loading: false,
    };

    this.handleAdd = this.handleAdd.bind(this);
  }

  handleAdd() {
    this.setState({ loading: true });
    this.props
      .onAddParticipant(this.props.user)
      .then(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <div key={this.props.user.objectId} style={styles.userCell}>
        <Avatar
          user={this.props.user}
          size={36}
          style={styles.avatar}
          isInstructor={this.props.isInstructor}
        />
        <span style={styles.nameButtonsContainer}>
          <span style={styles.name}>
            {`${this.props.user.firstName} ${this.props.user.lastName}`}
          </span>
          {!this.props.hideAdd && (
            <Button
              type="primary"
              loading={this.state.loading}
              onClick={this.handleAdd}
            >
              Add
            </Button>
          )}
          {!this.props.hideRemove && (
            <Tooltip title="Remove" space={2} position="top" stay={false}>
              <Remove
                tabIndex="0"
                aria-label="Remove participant"
                role="button"
                style={{
                  ...styles.button,
                  color: this.state.buttonHover
                    ? 'black'
                    : theme.color.grayDark,
                }}
                onClick={this.props.onRemoveParticipant}
                onKeyDown={(e) => {
                  if (e.keyCode === ENTER) {
                    this.props.onRemoveParticipant();
                  }
                }}
              />
            </Tooltip>
          )}
        </span>
      </div>
    );
  }
}

ParticipantsListUserBanner.propTypes = {
  user: PropTypes.object.isRequired,
  isInstructor: PropTypes.bool,
  hideAdd: PropTypes.bool,
  hideRemove: PropTypes.bool,
  onAddParticipant: PropTypes.func,
  onRemoveParticipant: PropTypes.func,
};

ParticipantsListUserBanner.defaultProps = {
  onAddParticipant: () => {},
  onRemoveParticipant: () => {},
};

export default ParticipantsListUserBanner;
