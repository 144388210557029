import { css } from 'react-emotion';

// eslint-disable-next-line import/prefer-default-export
export const avatarContainer = css`
  display: flex;
  align-items: center;
`;

export const avatarName = css`
  margin-left: 25px;
`;
