import PropTypes from 'prop-types';
import React from 'react';
import {
  Avatar,
  Container,
  Description,
  Title,
  Subtitle,
} from './Userblock.styled';

const getAvatarSize = (row) => (row ? { size: 'small' } : { size: 'medium' });

const UserBlock = ({
  collapsed,
  correlationId,
  name,
  title,
  size,
  subtitle,
  avatar,
  online,
  user,
  boldTitle,
}) => (
  <Container row={collapsed}>
    <Avatar
      size={size}
      colourSeed={correlationId}
      name={name || title}
      src={avatar}
      online={online}
      user={user}
      {...getAvatarSize(collapsed)}
    />
    <Description row={collapsed}>
      <Title bold={boldTitle}>{title}</Title>
      <Subtitle row={collapsed}>{subtitle}</Subtitle>
    </Description>
  </Container>
);

UserBlock.propTypes = {
  avatar: PropTypes.string,
  boldTitle: PropTypes.bool,
  collapsed: PropTypes.bool,
  correlationId: PropTypes.string,
  name: PropTypes.string,
  online: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.string,
  user: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  isNotFocusable: PropTypes.bool,
  size: PropTypes.string,
};

UserBlock.defaultProps = {
  avatar: undefined,
  boldTitle: true,
  collapsed: false,
  correlationId: undefined,
  name: undefined,
  online: false,
  subtitle: <span />,
  title: '',
  user: undefined,
  isNotFocusable: false,
  size: 'medium',
};

export default UserBlock;
