import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getCurrentSpaceId, getCurrentSpaceName } from '@core/selectors/space';
import { getSelectedSpaceId } from '@core/assignments/selectors';
import showToast from '@core/toasts/operations/showToast.operation';
import Submissions from './Submissions';
import { MANAGE_GROUPS, FOOTER_ACTIONS } from './constants';
import { accessToken as getAccessToken } from '../../../../../core/selectors/user';
import { location } from '../../../../selectors/routing';
import launchTurnitinAssignment from '../../../../actions/assignments/launchTurnitinAssignment.action';
import launchTurnitinSubmission from '../../../../actions/assignments/launchTurnitinSubmission.action';
import { operations, selectors } from '../../../../store/assignments';
import {
  accessingCurrentSpaceAsStaff,
  isInstructor,
} from '../../../../../core/selectors/classroom';
import openGroups from '../../../../actions/navigation/openGroups.action';

const compareSets = (a, b) => new Date(a.createdAt) - new Date(b.createdAt);

const getSetsWithGroups = (sets, groups, space) =>
  Object.values(sets)
    .filter(
      (set) =>
        (!space || set.space === space) &&
        (!('externalId' in set) || !set.externalId)
    )
    .map((set) => ({
      ...set,
      groups: set.groups.map((groupId) => groups[groupId]),
    }));

const getSetWithGroupsSelector = () =>
  createSelector(getSetsWithGroups, (setsWithGroups) => setsWithGroups);

const makeSetsWithKeysSelector = createSelector([(sets) => sets], (sets) => {
  const allSets = sets
    .reduce((acc, set) => {
      if (set.groups.length) {
        acc.push(
          {
            isHeader: true,
            key: set.id,
            label: set.name,
            studentsWithNoGroup: set.studentsWithNoGroup,
            externalId: set.externalId,
          },
          ...[
            // create a flat array of all sets and their groups
            ...set.groups.map((group) => ({
              ...group,
              isHeader: false,
              key: group.id,
              label: group.name,
            })),
          ]
        );
      }
      return acc;
    }, [])
    .sort(compareSets);

  // these keys are needed to render the footer items properly by Dropdown component
  allSets.push(
    { label: MANAGE_GROUPS, key: MANAGE_GROUPS, isHeader: false },
    { label: FOOTER_ACTIONS, key: FOOTER_ACTIONS, isHeader: false }
  );
  return allSets;
});

const makeMapStateToProps = () => {
  const getSetsData = getSetWithGroupsSelector();

  const mapStateToProps = (state) => {
    const { selectedSpace: spaceId } = state.assignments || {};
    const { user } = state.user;
    const [assignmentId] = location(state).pathname
      ? location(state).pathname.split('/').slice(-2)
      : [''];
    const currentClassRoomId = getCurrentSpaceId(state);
    const getSubmissions = (id) =>
      selectors.getAssignmentSubmissions(state, id);

    const assignment = selectors.getAssignment(state, assignmentId) || {};
    const { extensions: assignmentExtensions = [] } = assignment;
    const hasExtensions = !!assignmentExtensions.length;
    const extensions = hasExtensions
      ? [assignment, ...assignmentExtensions]
      : [];

    const getSubmissionsFetchedStatus = (id) =>
      selectors.hasFetchedSubmissions(state, id);
    const assignments = selectors.getAssignments(state);

    return {
      sets: makeSetsWithKeysSelector(
        getSetsData(
          state.data.groups.sets,
          state.data.groups.groups,
          currentClassRoomId
        )
      ),
      spaceId,
      user,
      assignmentId,
      getSubmissions,
      assignment,
      extensions,
      assignments,
      hasExtensions,
      getSubmissionsFetchedStatus,
      spaceName: getCurrentSpaceName(state),
      selectedSpace: state.data.classRooms[getSelectedSpaceId(state)],
      accessToken: getAccessToken(state, spaceId),
      parentAssignmentSubmissions: selectors.getAssignmentSubmissions(
        state,
        assignmentId
      ),
      hasFetchedAssignments: selectors.hasFetchedAssignments(state),
      isFetchingAssignments: selectors.isFetchingAssignments(state),
      fetchAssignmentsErrored: selectors.isErrored(state),
      fetchSubmissionsErrored: selectors.hasFetchSubmissionsErrored(
        state,
        assignmentId
      ),
      hasFetchedSubmissions: selectors.hasFetchedSubmissions(
        state,
        assignmentId
      ),
      isFetchingSubmissions: selectors.isFetchingSubmissions(
        state,
        assignmentId
      ),
      accessingAsStaff: accessingCurrentSpaceAsStaff(state),
      isEducator: isInstructor(state),
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  deleteSubmission: (params) => dispatch(operations.deleteSubmission(params)),
  editExtension: (params) => dispatch(operations.editAssignment(params)),
  fetchAssignments: (params) => dispatch(operations.fetchAssignments)(params),
  fetchSubmissions: (params) => dispatch(operations.fetchSubmissions(params)),
  launchTurnitinAssignment,
  launchTurnitinSubmission,
  openGroups: (spaceId) => dispatch(openGroups(spaceId)),
  showToast: (toast) => dispatch(showToast(toast)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Submissions);
