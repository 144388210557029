/* eslint-disable jsx-a11y/no-static-element-interactions */

import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';

import ParticipantUserBanner from '../../UserBanner/InboxUserBanner/InboxParticipantUserBanner';
import AutocompleteUser from '../common/AutocompleteUser';

import dialogStyle from '../../../../styles/presentational/ClassRoom/Inbox/inboxGroupParticipantsStyle';
import * as TEXT from '../../../../constants/texts';
import onlyWhenTrue from '../ConversationModal/onlyWhenTrue';
import { flow } from '../utils';

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
  AlertDialog,
} from '../../common/Dialog';

class GroupParticipantList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDialogOpen: false,
      confirmDialogText: '',
      confirmDialogAction: undefined,
      addingParticipants: false,
      newParticipants: [],
      loading: false,
      newGroupName: undefined,
    };

    this.handleRemoveParticipant = this.handleRemoveParticipant.bind(this);
    this.handleOpenConfirmDialog = this.handleOpenConfirmDialog.bind(this);
    this.handleCloseConfirmDialog = this.handleCloseConfirmDialog.bind(this);
    this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
    this.handleChangeGroupName = this.handleChangeGroupName.bind(this);
    this.addGroupName = this.addGroupName.bind(this);
  }

  componentDidMount() {
    const { addParticipantsFirst } = this.props;

    if (addParticipantsFirst) {
      this.setState({ addingParticipants: true });
    }
  }

  handleOpenConfirmDialog() {
    this.setState({ confirmDialogOpen: true });
  }

  handleCloseConfirmDialog() {
    const { confirmDialogOpen } = this.state;

    if (confirmDialogOpen) {
      this.setState({
        confirmDialogOpen: false,
        confirmDialogAction: undefined,
        loading: false,
        addingParticipants: false,
      });
    } else {
      this.setState({ addingParticipants: false });
    }
  }

  handleConfirmDialog() {
    const {
      addingParticipants,
      newParticipants,
      newGroupName,
      confirmDialogAction,
    } = this.state;
    const { addParticipants, groupId, changeGroupName, removeParticipant } =
      this.props;

    if (addingParticipants) {
      this.setState({ loading: true });
      const participantsIds = newParticipants.map(
        (participant) => participant.objectId
      );
      addParticipants(participantsIds, groupId)
        .then(() => {
          if (newGroupName) {
            changeGroupName(newGroupName, groupId).then(() => {
              this.setState({
                loading: false,
                addingParticipants: false,
                newParticipants: [],
              });
            });
          } else {
            this.setState({
              loading: false,
              addingParticipants: false,
              newParticipants: [],
            });
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            addingParticipants: false,
            newParticipants: [],
          });
        });
    } else if (confirmDialogAction) {
      this.setState({ loading: true });
      removeParticipant(confirmDialogAction.participantId, groupId)
        .then(() => {
          this.handleCloseConfirmDialog();
        })
        .catch(() => {
          this.handleCloseConfirmDialog();
        });
    }
  }

  handleRemoveParticipant(user) {
    this.setState({
      confirmDialogText: `Are you sure you want to remove ${user.firstName}
      ${user.lastName} from the conversation?`,
      confirmDialogAction: {
        type: 'remove',
        buttonText: 'Remove',
        participantId: user.objectId,
      },
    });
    this.handleOpenConfirmDialog();
  }

  handleAddParticipant(user) {
    const { addParticipants, groupId } = this.props;

    return addParticipants([user.id], groupId);
  }

  handleChangeGroupName(e) {
    const newGroupName = e.target.value;
    this.setState({ newGroupName });
  }

  addGroupName() {
    return (
      <div>
        <TextField
          hintText={TEXT.INPUT_TEXT_INBOX_GROUP}
          floatingLabelText="Group Name"
          onChange={this.handleChangeGroupName}
          style={dialogStyle.groupNameField}
        />
      </div>
    );
  }

  render() {
    const {
      handleClose,
      groupName,
      boundToSpaceGroup,
      open,
      participants,
      icon,
      title,
      classRoomId,
      userId,
      conversation: { educatorOnly },
    } = this.props;
    const {
      loading,
      confirmDialogAction,
      newParticipants,
      newGroupName,
      addingParticipants,
      confirmDialogOpen,
      confirmDialogText,
    } = this.state;

    const addParticipantsButton =
      boundToSpaceGroup || educatorOnly ? null : (
        <Button
          variant="contained"
          aria-label={TEXT.BUTTON_ADD_PARTICIPANTS}
          style={dialogStyle.addParticipantButton}
          onClick={() => this.setState({ addingParticipants: true })}
        >
          {TEXT.BUTTON_ADD_PARTICIPANTS}
        </Button>
      );

    return (
      <React.Fragment>
        <Dialog
          id="conversation-view-participants"
          open={open}
          onClose={handleClose}
          maxWidth="md"
          style={{ zIndex: 1600 }}
        >
          <DialogTitle
            id="conversation-view-participants-dialogtitle"
            onClose={handleClose}
          >
            {addingParticipants
              ? 'Add participants'
              : 'Conversation participants'}
          </DialogTitle>
          <DialogContent>
            <div style={dialogStyle.wrapper}>
              <div style={dialogStyle.infoWrapper}>
                {participants.length > 2 && icon(30)}
                <span style={dialogStyle.labelsWrapper}>
                  <div style={dialogStyle.title}>{title}</div>
                  <span style={dialogStyle.subtitle}>
                    {!addingParticipants &&
                      `${participants.length} participants`}
                  </span>
                </span>
              </div>
              {addingParticipants && (
                <div style={dialogStyle.participantAutocomplete}>
                  <AutocompleteUser
                    onChange={(users) =>
                      this.setState({ newParticipants: users })
                    }
                    classRoomId={classRoomId}
                    blockList={participants.map((u) => u.objectId)}
                  />
                </div>
              )}
              {!addingParticipants && (
                <>
                  {addParticipantsButton}
                  <div style={dialogStyle.table}>
                    {participants
                      .filter((u) => u.objectId !== userId)
                      .map((user) => (
                        <ParticipantUserBanner
                          user={user}
                          key={user.objectId}
                          hideRemove={
                            boundToSpaceGroup ||
                            educatorOnly ||
                            participants.length === 2
                          }
                          hideAdd
                          onRemoveParticipant={() =>
                            this.handleRemoveParticipant(user)
                          }
                        />
                      ))}
                  </div>
                </>
              )}
              {newParticipants.length > 0 &&
                groupName === '-' &&
                this.addGroupName()}
            </div>
          </DialogContent>
          {addingParticipants ? (
            <DialogActions
              primary={{
                label: confirmDialogAction
                  ? confirmDialogAction.buttonText
                  : TEXT.BUTTON_ADD,
                disabled:
                  newParticipants.length > 0 &&
                  !newGroupName &&
                  groupName === '-',
                onClick: this.handleConfirmDialog,
                loading,
              }}
              secondary={{
                label: TEXT.BUTTON_CANCEL,
                onClick: () => {
                  this.setState({
                    addingParticipants: false,
                  });
                  this.handleCloseConfirmDialog();
                  handleClose();
                },
                disabled: loading,
              }}
            />
          ) : null}
        </Dialog>
        <AlertDialog
          id="confirm-remove-participant"
          title="Remove participant"
          message={confirmDialogText}
          open={confirmDialogOpen}
          onClose={this.handleCloseConfirmDialog}
          action={{
            label: 'Remove',
            onClick: this.handleConfirmDialog,
            danger: true,
            disabled: loading,
            loading,
          }}
        />
      </React.Fragment>
    );
  }
}

GroupParticipantList.propTypes = {
  addParticipants: PropTypes.func.isRequired,
  addParticipantsFirst: PropTypes.bool,
  changeGroupName: PropTypes.func.isRequired,
  classRoomId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  participants: PropTypes.array.isRequired,
  removeParticipant: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  boundToSpaceGroup: PropTypes.bool.isRequired,
  conversation: PropTypes.shape({
    educatorOnly: PropTypes.bool,
  }),
};

GroupParticipantList.defaultProps = {
  addParticipantsFirst: false,
  conversation: {},
};

const wrap = flow(onlyWhenTrue(['open']));

export default wrap(GroupParticipantList);
