import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
// import { TextField } from '@mui/material';
// import React, { useState } from 'react';

import * as TEXT from '../../../../constants/texts';
import style from '../../../../styles/presentational/ClassRoom/Inbox/inboxCreateGroupStyle';
import dialogStyle from '../../../../styles/presentational/ClassRoom/Inbox/inboxGroupParticipantsStyle';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../common/Dialog';
import onlyWhenTrue from '../ConversationModal/onlyWhenTrue';
import { flow } from '../utils';
import { DialogStyle } from './ChangeConversationName.styled';

interface ChangeConversationNameProps {
  changeGroupName: (name: string, objectId: string) => Promise<void>;
  group: { objectId: string };
  handleClose: () => {};
  icon: (size: number) => {};
  open: boolean;
  participants: [];
  title: string;
  isChangingGroupName?: boolean;
}

const ChangeConversationName = ({
  changeGroupName,
  group,
  handleClose,
  icon,
  isChangingGroupName,
  open,
  participants,
  title,
}: ChangeConversationNameProps) => {
  const [groupName, setGroupName] = useState('');

  const handleChangeName = (e) => {
    setGroupName(e.target.value);
  };

  const handleRequestClose = () => {
    handleClose();
  };

  const handleChangeGroupName = () => {
    if (groupName) {
      const changed = changeGroupName(groupName, group.objectId);
      changed.then(handleRequestClose).catch(handleRequestClose);
    }
  };

  return (
    <Dialog
      id="rename-conversation"
      open={open}
      onClose={handleRequestClose}
      maxWidth="md"
      style={DialogStyle}
    >
      <DialogTitle
        id="rename-conversation-dialogtitle"
        onClose={handleRequestClose}
      >
        Rename conversation
      </DialogTitle>
      <DialogContent>
        <div style={dialogStyle.wrapper}>
          <div style={dialogStyle.infoWrapper}>
            {participants.length > 2 && icon(30)}
            <span style={dialogStyle.labelsWrapper}>
              <div style={dialogStyle.title}>{title}</div>
              <span style={dialogStyle.subtitle}>
                {`${participants.length} participants`}
              </span>
            </span>
          </div>
          <div style={dialogStyle.participantAutocomplete}>
            <TextField
              helperText={TEXT.INPUT_TEXT_INBOX_GROUP}
              label={TEXT.INPUT_TEXT_NEW_CONVERSATION_NAME}
              aria-label={TEXT.INPUT_TEXT_NEW_CONVERSATION_NAME}
              onChange={handleChangeName}
              style={style.groupNameField}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions
        primary={{
          label: TEXT.BUTTON_SAVE,
          onClick: handleChangeGroupName,
          loading: isChangingGroupName,
          disabled: groupName.length === 0,
        }}
        secondary={{
          label: 'Cancel',
          onClick: handleRequestClose,
        }}
      />
    </Dialog>
  );
};

const wrap = flow(onlyWhenTrue(['open']));

export default wrap(ChangeConversationName);
