import styled from 'react-emotion';
import { Button, theme } from '@ublend-npm/aulaui-next';

export const StepCompleteButton = styled(Button)`
  background-color: ${theme.color.designSystem.success};
  color: ${theme.color.white};
  font-weight: 100;
  border: none;
  &:hover {
    background-color: #156b2f;
  }
`;
