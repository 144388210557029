import PropTypes from 'prop-types';
import React from 'react';
import { theme } from '@ublend-npm/aulaui-next';
import Select from '../FieldSets/Select';

import { FilterContainer } from './TagFilter.styled';

import TagFilterItem from './TagFilterItem';

const TagFilter = ({ tagList, selectedTag, onChange }) => {
  const containerName = 'filter-container';

  return (
    <FilterContainer id={containerName} key="filter-container">
      <Select
        id="tags-select"
        selectedKey={selectedTag}
        dataSet={tagList}
        DropdownStyle={{ width: '200px' }}
        DropdownItemType={TagFilterItem}
        label="Filter by tag"
        onChange={onChange}
        headerTextTruncate
        onDismiss={selectedTag && onChange}
        isLoading={false}
        headerFontSize="medium"
        headerFontColor={theme.color.grey11}
        showKeyInHeader={false}
        ariaLabelledby={containerName}
      />
    </FilterContainer>
  );
};

TagFilter.defaultProps = {
  tagList: [],
  selectedTag: null,
  onChange: () => {},
};

TagFilter.propTypes = {
  tagList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  selectedTag: PropTypes.string,
  onChange: PropTypes.func,
};

export default TagFilter;
