import React from 'react';
import { ModalContent } from '../CreateAssignment/SharedModalStyles.styled';
import { FormUtils } from '@app/hooks/useYupForm/useYupForm';
import { DateField, SelectField, TextField } from './LineItemForm.styled';
import FormInfoMessage from '@app/components/presentational/common/FormInfoMessage/FormInfoMessage';
import { LtiAssignmentCreationParameters } from '@ublend-npm/aula-schema/types/ltiConsumer';
import { dropdownType } from './lineItemSchema';

export type LineItemFormState = Record<string, string>;

type LineItemField = string;

type ExtraParameterFieldProps = {
  parameter: LtiAssignmentCreationParameters[number];
  getFieldProps: FormUtils<LineItemFormState>['getFieldProps'];
};

const ExtraParameterField = ({
  parameter,
  getFieldProps,
}: ExtraParameterFieldProps) => {
  const dropdownFieldProps = (field: LineItemField) => {
    const { set, ...props } = getFieldProps(field);
    return {
      ...props,
      hasError: props.error,
      errorMessage: `${props.label} is a required field`,
      data: parameter.options.map((opt) => ({
        key: opt.value,
        value: opt.label,
      })),
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) =>
        set(event.target.value),
    };
  };

  switch (parameter.type) {
    case dropdownType:
      return <SelectField {...dropdownFieldProps(parameter.key)} />;
    default:
      return null;
  }
};

type LineItemFormProps = {
  getFieldProps: FormUtils<LineItemFormState>['getFieldProps'];
  extraParameters?: LtiAssignmentCreationParameters;
  showFormInfoMessage?: boolean;
};

export const LineItemForm = ({
  getFieldProps,
  extraParameters = [],
  showFormInfoMessage = false,
}: LineItemFormProps) => {
  const textFieldProps = (field: LineItemField) => {
    const { set, ...props } = getFieldProps(field);

    return {
      ...props,
      onChange: (input: React.ChangeEvent<HTMLInputElement>) =>
        set(input.target.value),
    };
  };

  const dateFieldProps = (field: LineItemField) => {
    const { set, ...props } = getFieldProps(field);
    return {
      ...props,
      onChange: set,
    };
  };

  const openDateProps = dateFieldProps('openDate');
  const dueDateProps = dateFieldProps('dueDate');
  const gradesReleasedProps = dateFieldProps('gradesReleaseDate');

  return (
    <ModalContent>
      <TextField {...textFieldProps('title')} />
      <TextField {...textFieldProps('description')} />
      <DateField {...openDateProps} maxDate={dueDateProps.value} />
      <DateField
        {...dueDateProps}
        minDate={openDateProps.value}
        maxDate={gradesReleasedProps.value}
      />
      <DateField
        {...gradesReleasedProps}
        minDate={dueDateProps.value || openDateProps.value}
      />
      <TextField {...textFieldProps('maxGrade')} />
      {extraParameters &&
        extraParameters.map((ep) => (
          <ExtraParameterField
            key={ep.key}
            parameter={ep}
            getFieldProps={getFieldProps}
          />
        ))}
      {showFormInfoMessage && (
        <FormInfoMessage message="Please note: Assignments must be activated in the tool and set as visible in Aula before students can view and submit" />
      )}
    </ModalContent>
  );
};
