import axios from 'axios';
import { LtiRequestType } from './types';

export type Lti1p3LaunchParams = {
  url: string;
  spaceId: string;
  clientId: string;
  type: LtiRequestType;
  userId: string;
  resourceLinkId: string;
  resourceLinkTitle?: string;
  assignmentId?: string;
  assignmentFormData?: Record<string, unknown>;
};

type PayloadProps = {
  spaceId: string;
  clientId: string;
  type: string;
  resourceLinkId: string;
  resourceLinkTitle?: string;
  assignmentId?: string;
  assignmentFormData?: Record<string, unknown>;
};

export const lti1p3Start = async ({
  url,
  spaceId,
  clientId,
  type,
  userId,
  resourceLinkId,
  resourceLinkTitle,
  assignmentId,
  assignmentFormData,
}: Lti1p3LaunchParams): Promise<string> => {
  let payload: PayloadProps = {
    spaceId,
    clientId,
    type,
    resourceLinkId,
  };

  if (resourceLinkTitle) {
    payload = { ...payload, resourceLinkTitle };
  }

  if (assignmentId) {
    payload = { ...payload, assignmentId };
  }

  if (assignmentFormData) {
    payload = { ...payload, assignmentFormData };
  }

  const result = await axios.post(url, payload, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'x-user-id': userId,
    },
  });
  const { ltiSessionToken } = result.data;
  return ltiSessionToken;
};
