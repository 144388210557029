import styled from 'react-emotion';

export const ContentContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 32px;
`;

export const DividerContainer = styled('div')`
  font-size: 16px;
  line-height: 25.6px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TitleWrapper = styled('h4')`
  margin: 0;
`;
