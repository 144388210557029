import { Menu, MenuItem } from '@ublend-npm/aulaui-next';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@ublend-npm/react-tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IconButton } from './Menu.styled';

const SubmissionsMenu = (props) => {
  const { deleteSubmission } = props;

  const items = [
    {
      key: 'delete',
      label: 'Delete submission',
      handler: deleteSubmission,
    },
  ];

  return (
    <Menu
      menuListComponent="ul"
      menuListAttributes={{ role: 'none presentation' }}
      ariaLabelledby="menu_submissions"
      renderTarget={({ handleTargetClick }) => (
        <Tooltip title="Submissions menu" position="bottom">
          <div>
            <IconButton
              icon={MoreVertIcon}
              size="medium"
              label="Submissions menu"
              onClick={handleTargetClick}
            />
          </div>
        </Tooltip>
      )}
      onSelect={({ item }) => item()}
      renderChildren={({ getItemProps }) =>
        items.map(({ key, label, handler }) => (
          <MenuItem
            key={key}
            size="medium"
            primaryText={label}
            {...getItemProps({
              item: handler,
              onClick: (e) => e.stopPropagation(),
            })}
          />
        ))
      }
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    />
  );
};

SubmissionsMenu.propTypes = {
  deleteSubmission: PropTypes.func.isRequired,
};

export default SubmissionsMenu;
