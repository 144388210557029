import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CreatePost from './CreatePost';
import { createPost } from '../../../../../../core/actions/classRoom/createPost';
import { currentUserId as getCurrentUserId } from '../../../../../../core/selectors/user';
import { isInstructor } from '../../../../../../core/selectors/classroom';
import AulaEditorWithAnalyticsWithoutPlugins from '../../../common/AulaEditor/AulaEditorWithAnalyticsWithoutPlugins';
import AulaEditorWithAnalytics from '../../../common/AulaEditor/AulaEditorWithAnalytics';
import generateEditorConfig from '../../generateEditorConfig';

const mapStateToProps = (state, ownProps) => {
  const currentUserId = getCurrentUserId(state);
  const isEducator = isInstructor(state, currentUserId);
  const { feedType } = ownProps;

  return {
    spaceId: state.data.classRooms[state.classRoom.current]
      ? state.data.classRooms[state.classRoom.current].objectId
      : undefined,
    isSavingPost: state.posts.isCreatingPost,
    isChangingClassRoom: state.classRoom.isChangingClassRoom,
    isEducator,
    editorConfig: generateEditorConfig({ state, itemType: feedType }),
    feedType,
    EditorComponent: isEducator
      ? AulaEditorWithAnalytics
      : AulaEditorWithAnalyticsWithoutPlugins,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPost: (postData) => dispatch(createPost(postData)),
});

const ClassRoomCreatePostContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePost);

export default withRouter(ClassRoomCreatePostContainer);
