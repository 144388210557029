import React from 'react';
import PropTypes from 'prop-types';
import { Separator, Loader, Alert, AlertTitle } from '@ublend-npm/aulaui-next';

import NoResults from '../Settings/NoResults';
import SubmissionsCard from './SubmissionsCard';
import NoSubmissionsCard from './NoSubmissionsCard';
import {
  SubmissionGroupTitle,
  scrollbarStyle,
  LoaderContainer,
  SubmissionsListHeader,
  SubmissionListItemsWrapper,
  SubmissionListItem as SubmissionListHeader,
  AlertContainer,
  SubmissionHeaderContainer,
} from './Submissions.styled';
import formatDate from '../../../../../core/utils/formatDate';
import Scrollbar from '../../common/ScrollBar';
import SubmissionsGroupCard from './GroupSubmissions/SubmissionsGroupCard';
import Accordion from '../../common/Accordion/Accordion';

const NO_STUDENT_EXTENSION = {
  TITLE: 'No students are assigned to this extension',
  MESSAGE:
    'To assign students, hover over this extension in the left column and click on the 3 dots to edit the extension.',
};

const NO_STUDENT_MAIN_COHORT = {
  TITLE: 'There are no students in the main cohort',
  MESSAGE:
    'To move students back to the main cohort, hover over one of extensions in the left column and click on the 3 dots to edit it and remove students.',
};

const LATE_SUBMISSIONS_KEY = 'lateSubmissions';
const EARLY_SUBMISSIONS_KEY = 'earlySubmissions';
const NO_SUBMISSIONS_KEY = 'noSubmissions';

// Keys here will be used as test IDs
// for e2e test. Avoid renaming them.
// But if you must, remember to update e2e tests.
const SubmissionCategories = {
  [LATE_SUBMISSIONS_KEY]: 'Late submissions',
  [EARLY_SUBMISSIONS_KEY]: 'On time submissions',
  [NO_SUBMISSIONS_KEY]: 'No submission',
};

const getGrade = (submission) => {
  if (submission.displayGrade) {
    if (submission.displayGrade.includes('NaN')) {
      return 'Not graded';
    }
    return submission.displayGrade;
  }

  if (submission.receivedAt) {
    return 'Not graded';
  }

  return '-';
};

const SubmissionsList = ({
  loading,
  assignmentId,
  assignmentExternalId,
  submissionGroups,
  isGroupAssignment,
  studentsWithNoGroup,
  accessingAsStaff,
  deleteSubmission,
  spaceId,
  isExtension,
  isAnonymised,
}) => {
  const allSubmissions = Object.values(submissionGroups).reduce(
    (acc, submissionsInGroup) => [...acc, ...submissionsInGroup],
    []
  );

  if (!loading && allSubmissions && allSubmissions.length === 0) {
    if (isGroupAssignment) {
      return (
        <div>
          <NoResults primaryText="There are no submissions yet." />
        </div>
      );
    }
    const alertTitle = isExtension
      ? NO_STUDENT_EXTENSION.TITLE
      : NO_STUDENT_MAIN_COHORT.TITLE;
    const alertMessage = isExtension
      ? NO_STUDENT_EXTENSION.MESSAGE
      : NO_STUDENT_MAIN_COHORT.MESSAGE;
    return (
      <AlertContainer>
        <Alert onClose={null} type="info">
          <AlertTitle>{alertTitle}</AlertTitle>
          {alertMessage}
        </Alert>
      </AlertContainer>
    );
  }

  const renderSubmissions = (data) =>
    data.map((submission) =>
      isGroupAssignment ? (
        <Accordion
          key={submission.name}
          dataSet={submission.submissions}
          renderAccordionItem={(itemData) => (
            <SubmissionsCard
              key={itemData.user.id}
              user={itemData.user}
              submissionDate=""
              assignmentId={assignmentId}
              grade={getGrade(itemData)}
              assignmentExternalId={assignmentExternalId}
              submissionExternalId={itemData.externalId}
              submissionId={itemData.id}
              isGroup
              accessingAsStaff={accessingAsStaff}
              deleteSubmission={deleteSubmission}
              spaceId={spaceId}
              groupId={itemData.group.id}
            />
          )}
          AccordionTitleComponent={() => (
            <SubmissionsGroupCard
              name={submission.name}
              userId={submission.submissions[0].user.id || ''}
              submissionId={submission.submissions[0].externalId || ''}
              submissionDate={
                submission.receivedAt
                  ? formatDate(submission.receivedAt)
                  : 'No submission'
              }
              grade={getGrade(submission)}
              accessingAsStaff={accessingAsStaff}
            />
          )}
        />
      ) : (
        <SubmissionsCard
          key={submission.user.id}
          user={submission.user}
          grade={getGrade(submission)}
          assignmentId={assignmentId}
          assignmentExternalId={assignmentExternalId}
          submissionExternalId={submission.externalId}
          submissionId={submission.id}
          submissionDate={
            submission.receivedAt
              ? formatDate(submission.receivedAt)
              : 'No submission'
          }
          accessingAsStaff={accessingAsStaff}
          deleteSubmission={deleteSubmission}
          spaceId={spaceId}
        />
      )
    );

  const renderSubmissionList = (submissionData, submissionGroupKey) => (
    <div key={submissionGroupKey}>
      <Separator />
      <SubmissionGroupTitle>
        {SubmissionCategories[submissionGroupKey]}{' '}
        <span data-testid={`${submissionGroupKey}-count`}>
          ({submissionData[submissionGroupKey].length})
        </span>
      </SubmissionGroupTitle>
      <div data-testid={`${submissionGroupKey}-container`}>
        {submissionGroupKey === NO_SUBMISSIONS_KEY && isAnonymised ? (
          <NoSubmissionsCard
            count={submissionData[submissionGroupKey].length}
            isGroup={isGroupAssignment}
          />
        ) : (
          renderSubmissions(submissionData[submissionGroupKey])
        )}
      </div>
    </div>
  );

  const loadingMessage = (
    <LoaderContainer>
      <Loader size={25} />
    </LoaderContainer>
  );
  const renderContent = () => {
    return Object.keys(submissionGroups).map((submissionGroupKey) =>
      renderSubmissionList(submissionGroups, submissionGroupKey)
    );
  };
  const renderStudentsWithNoGroup = () => (
    <Accordion
      dataSet={studentsWithNoGroup}
      renderAccordionItem={(student) => (
        <SubmissionsCard
          key={student.id}
          user={{
            id: student.id,
            avatar: student.avatar,
            firstName: student.firstName,
            lastName: student.lastName,
          }}
          submissionDate=""
          grade=""
          submissionId=""
          isGroup
          accessingAsStaff={accessingAsStaff}
        />
      )}
      AccordionTitleComponent={() => <p>(Not assigned to a group)</p>}
    />
  );

  return loading ? (
    loadingMessage
  ) : (
    <>
      <SubmissionHeaderContainer>
        <SubmissionListHeader isHeader>
          <SubmissionsListHeader cols={6}>Student</SubmissionsListHeader>
          <SubmissionsListHeader cols={4}>
            Submission Date
          </SubmissionsListHeader>
          <SubmissionsListHeader cols={2}>Grade</SubmissionsListHeader>
        </SubmissionListHeader>
      </SubmissionHeaderContainer>
      <Scrollbar autoHide style={scrollbarStyle}>
        <SubmissionListItemsWrapper>
          {renderContent()}
          {isGroupAssignment &&
            studentsWithNoGroup.length !== 0 &&
            renderStudentsWithNoGroup()}
        </SubmissionListItemsWrapper>
      </Scrollbar>
    </>
  );
};

SubmissionsList.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isGroupAssignment: PropTypes.bool.isRequired,
  assignmentExternalId: PropTypes.string.isRequired,
  studentsWithNoGroup: PropTypes.arrayOf(PropTypes.shape({})),
  submissionGroups: PropTypes.shape({}).isRequired,
  accessingAsStaff: PropTypes.bool.isRequired,
  isExtension: PropTypes.bool.isRequired,
  isAnonymised: PropTypes.bool,
  deleteSubmission: PropTypes.func.isRequired,
};

SubmissionsList.defaultProps = {
  studentsWithNoGroup: [],
  isAnonymised: false,
};

export default SubmissionsList;
