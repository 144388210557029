import Prefixer from 'inline-style-prefixer';
import { CSSProperties } from 'react';
import { theme } from '@ublend-npm/aulaui-next';
import { SPACE_SELECTOR_HEIGHT } from '../../../../../constants/sizes';

interface Styles {
  container: CSSProperties;
  header: CSSProperties;
  headerLabel: CSSProperties;
  postsContainer: CSSProperties;
  table: CSSProperties;
  postWrapper: CSSProperties;
  navigation: CSSProperties;
  parentContainer: CSSProperties;
  title: CSSProperties;
}

const scheduledPostsStyles = new Prefixer().prefix<Styles>({
  container: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  header: {
    lineHeight: '40px',
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    marginBottom: '20px',
  },
  headerLabel: {
    marginLeft: 5,
    color: theme.color.grey4,
  },
  postsContainer: {
    alignItems: 'center',
    padding: '0 54px 0 54px',
    margin: 'auto',
  },
  table: {
    width: '100%',
    padding: '0px 54px',
  },
  postWrapper: {
    maxWidth: 624,
    margin: 'auto',
  },
  navigation: {
    padding: '20px 0',
    textAlign: 'left',
  },
  parentContainer: {
    height: `calc(100% - ${SPACE_SELECTOR_HEIGHT}px)`,
  },
  title: {
    display: 'block',
    width: '100%',
    maxWidth: 624,
    margin: 'auto',
  },
});

export default scheduledPostsStyles;
