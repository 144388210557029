import React from 'react';
import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

export const Container = styled(Paper)`
  margin-top: 4px;
  padding: 8px 0;
  max-height: 200px;
  width: ${(props) => props.width || '408px'};
  overflow: auto;
  border-radius: 4px;
`;

export const DropdownList = styled('ul')`
  margin: 0;
  padding: 0;
`;

export const Item = styled(({ isHeader, ...props }) => (
  <MenuItem component="li" {...props} />
))`
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
`;

export const TagItem = styled(({ isHeader, ...props }) => <Item {...props} />)`
  height: 41px;
  padding: 12px;
  ${(props) =>
    !props.isHeader && `border-bottom: 1px solid ${theme.color.grey3};`}
`;

export const LabelItem = styled(Item)`
  color: ${theme.color.grayDark};
  :hover {
    background-color: white;
  }
`;

export const LoaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;
