import styled from 'react-emotion';
import UnStyledHiddenIcon from '@mui/icons-material/VisibilityOff';
import { theme, TextField as TextFieldBase } from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  padding: ${theme.spacing.xLarge};
  flex-grow: 2;
  overflow: auto;
`;

export const Title = styled('div')`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.copy};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Form = styled('div')`
  margin-top: ${theme.spacing.medium};
`;

export const DateFields = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DateField = styled('div')`
  width: calc(50% - ${theme.spacing.small});
  margin-top: ${theme.spacing.small};
  min-height: 70px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme.spacing.xSmall};
  margin-bottom: ${theme.spacing.medium};
`;

export const SwitchLabel = styled('div')`
  color: ${theme.color.designSystem.blackOpacity87};
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.regular};
  margin-left: ${theme.spacing.small};
`;

export const SwitchTitleContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const HiddenIcon = styled(UnStyledHiddenIcon)`
  font-size: ${theme.font.size.headline};
`;

export const TextField = styled(TextFieldBase)`
  height: auto;
  & .fieldset-textfield-input {
    font-size: 14px;
    height: ${theme.spacing.xSmall};
  }
`;

export const TextAreaField = styled(TextFieldBase)`
  height: auto;
  min-height: 152px;
`;

export const HandinEditorWrapper = styled('div')``;

export const AlertContainer = styled('div')`
  margin-top: ${theme.spacing.xSmall};
`;

// Cannot use the design system settings as needs to match the material-ui helper design
export const HandinHelperText = styled('div')`
  color: ${theme.color.blackAlpha(0.6)};
  font-size: 0.75rem;
  margin-left: 14px;
  min-height: 14px;
  margin-top: 7px;
`;
