const compareDates = (a, b) => new Date(a.createdAt) - new Date(b.createdAt);

export default (comments) => {
  const groupedReplies = comments.reduce((acc, comment) => {
    if (!comment.root) {
      return acc;
    }
    return {
      ...acc,
      [comment.root]: [...(acc[comment.root] || []), comment].sort(),
    };
  }, {});

  return comments.sort(compareDates).reduce(
    (acc, comment) => ({
      ...acc,
      [comment.id]: {
        ...comment,
        replies: (groupedReplies[comment.id] || [])
          .sort(compareDates)
          .map(({ id }) => id),
      },
    }),
    {}
  );
};
