import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, Tooltip } from '@ublend-npm/aulaui-next';
import take from 'lodash.take';
import EducatorParticipant from './EducatorParticipant.container';
import { EDUCATOR_AREA_PARTICIPANTS_TOOLTIP } from '../../../../../constants/texts';
import {
  participantList,
  participantItem,
  participantListFull,
  participantItemFull,
  modalPaperClass,
  modalContentContainer,
  OthersContainer,
} from './EducatorParticipants.styled';

const menuId = 'educator-menu-show-participants';
const MAX_EDUCATORS_TO_SHOW = 6; // Show educators by default in a section view
const concatClassNames = (...classNames) =>
  classNames.filter((name) => !!name).join(' ');
const isOnlyOneEducator = (educators) =>
  !educators.length || educators.length === 1;
const pluralizeOthers = (n) => (n > 1 ? 'others' : 'other');

const EducatorParticipants = (props) => {
  const [isOpenParticipantsModal, setOpenParticipantsModal] = useState(false);
  const { educators } = props;

  const renderParticipant = (participant, isFull) => {
    const itemClasses = isFull
      ? concatClassNames(participantItem, participantItemFull)
      : participantItem;
    return (
      <li key={participant.id} className={itemClasses}>
        <EducatorParticipant
          key={participant.id}
          participantId={participant}
          isFull={isFull}
        />
      </li>
    );
  };

  const withName = true;
  const withoutName = false;
  const educatorsCount = educators ? educators.length : 0;
  const educatorsToDisplay =
    (educatorsCount && take(educators, MAX_EDUCATORS_TO_SHOW)) || [];
  const othersCount = educatorsCount - educatorsToDisplay.length;

  const renderOthers = (count) => (
    <Tooltip title={EDUCATOR_AREA_PARTICIPANTS_TOOLTIP} position="bottom">
      <OthersContainer onClick={() => setOpenParticipantsModal(true)}>
        {`+${count} ${pluralizeOthers(count)}`}
      </OthersContainer>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <ul className={participantList}>
        {educatorsToDisplay.map((item) => renderParticipant(item, withoutName))}
        {!isOnlyOneEducator(educators) && othersCount > 0 && (
          <li key={menuId} id={menuId} className={participantItem}>
            {renderOthers(othersCount)}
          </li>
        )}
      </ul>
      <Modal
        id="view-educator-participants-modal"
        container={() => document.getElementById('user-profile-container')}
        classes={{ paper: modalPaperClass }}
        open={isOpenParticipantsModal}
        headerData={{
          title: 'Educators in this space',
        }}
        onClose={() => setOpenParticipantsModal(false)}
      >
        <div className={modalContentContainer}>
          <ul
            className={concatClassNames(participantList, participantListFull)}
          >
            {educators &&
              educators.length &&
              educators.map((item) => renderParticipant(item, withName))}
          </ul>
        </div>
      </Modal>
    </React.Fragment>
  );
};

EducatorParticipants.propTypes = {
  educators: PropTypes.arrayOf(PropTypes.object),
};

EducatorParticipants.defaultProps = {
  educators: [],
};

export default EducatorParticipants;
