import React from 'react';
import noContent from '../../../../../static/empty-state-sep-21.png';
import { INBOX_NO_CONTENT_TITLE } from '../../../../constants/texts';
import { Container, Image, Title } from './MessageTableNoContent.styled';

const MessageTableNoContent = () => (
  <Container data-testid="dm-without-message">
    <Image src={noContent} alt="Student" />
    <Title>{INBOX_NO_CONTENT_TITLE}</Title>
  </Container>
);

export default MessageTableNoContent;
