import styled from 'react-emotion';

import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  padding: 16px 24px;
`;

export const TitleRow = styled('div')`
  font-family: ${theme.font.family.workSans};
  justify-content: space-between;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled('h2')`
  color: ${theme.color.designSystem.blackOpacity87};
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 14px;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
`;

export const Legend = styled('div')`
  font-family: ${theme.font.family.workSans};
  color: ${theme.color.grayDark};
  & > svg {
    width: 16px;
    height: 16px;
  }
`;
