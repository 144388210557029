import { ContentTemplate } from '@ublend-npm/aula-editor';

const templates: ContentTemplate[] = [
  {
    id: '5b71f4f0-7c6e-4bd4-845e-b401d4710254',
    name: 'Live session',
    description:
      'Use this template when you want to draw attention to an upcoming live session and share the link to join an online session.',
    category: 'Announcements',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '🎙 Live Session',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: '📅 ',
            },
            {
              text: 'Date:',
              b: true,
            },
            {
              text: ' Insert Date \n⏰ ',
            },
            {
              text: 'Time:',
              b: true,
            },
            {
              text: ' Insert Time\n🔗 ',
            },
            {
              text: 'Join:',
              b: true,
            },
            {
              text: ' Insert Meeting link here',
              b: true,
              u: true,
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '29e49428-1b59-430b-9cb4-59533b2ff471',
    name: 'Live Seminar Q&A',
    description:
      'Use this template when you want to direct students attention to the correct space to add questions during live seminars. Adding this will encourage students who may feel shy asking questions out loud to engage, whilst also allowing for less disruptions during the live sessions.',
    category: 'Announcements',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '❓ Live Seminar Q&A',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: "Please add your questions below as you listen to the presentations and feedback throughout this week's seminar!\n",
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '6275e27b-b20f-447e-90e9-e6e047ef6c5a',
    name: 'Guest Speaker Q&A',
    description:
      "Use this template when you want to encourage students to submit questions for a guest speaker whilst also directing students to the session. Giving prompts to 'upvote' different peers' questions will help you and the speaker to prioritise the areas the speaker should spend the most time discussing.",
    category: 'Announcements',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '🗣 Guest Speaker Q&A',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: '📅 ',
            },
            {
              text: 'Date:',
              b: true,
            },
            {
              text: ' Insert Date \n⏰ ',
            },
            {
              text: 'Time:',
              b: true,
            },
            {
              text: ' Insert Time\n🔗 ',
            },
            {
              text: 'Join:',
              b: true,
            },
            {
              text: ' Insert Meeting link here',
              b: true,
              u: true,
            },
          ],
        },
        {
          children: [
            {
              text: 'Bio:',
              u: true,
            },
          ],
          type: 'h3',
        },
        {
          children: [
            {
              text: 'Write a 1-2 line biography for the guest speaker.',
            },
          ],
        },
        {
          children: [
            {
              text: 'To Do:',
              u: true,
            },
          ],
          type: 'h3',
        },
        {
          children: [
            {
              text: '🤔 ',
            },
            {
              text: 'Think',
              b: true,
            },
            {
              text: ' of a question about ',
            },
            {
              text: '<insert topic>',
              b: true,
            },
            {
              text: ' for our guest speaker and ',
            },
            {
              text: 'add',
              b: true,
            },
            {
              text: ' it as a reply to this feed post. ',
            },
          ],
        },
        {
          children: [
            {
              text: '🚀 Then ',
            },
            {
              text: 'give',
              b: true,
            },
            {
              text: ' the rocket response to the ',
            },
            {
              text: 'three questions',
              b: true,
            },
            {
              text: ' you would most like to hear the answers to. ',
            },
          ],
        },
        {
          children: [
            {
              text: "We'll make sure the questions with the most rocket votes are prioritised during the ",
            },
            {
              text: 'live Q&A',
              b: true,
            },
            {
              text: '.',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '72c1eaa2-236c-44e0-804a-2bc1d1008a95',
    name: 'Feedback',
    description:
      'Use this template when you want to alert students to their feedback being available. Drawing attention to the feedback will encourage students to read through and engage with any feedback shared.',
    category: 'Announcements',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '📣 Feedback',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'Congratulations everyone - the quality of work submitted this week for your team challenges has been exceptional.  It is wonderful to see you all actively taking on board feedback from previous weeks.',
            },
          ],
        },
        {
          children: [
            {
              text: 'This week’s feedback is now ready for you to view in the Journey.',
              b: true,
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: 'c0022cc8-a0aa-472e-b18e-e81337004a03',
    name: 'Assignment feedback/grades',
    description:
      'Use this template when you want to draw attention to the availability of assignment grades and feedback. This can be helpful to direct students to where to find their grades and to engage with their feedback.',
    category: 'Announcements',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '📣 Announcement - Assignment Feedback / Grades',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'Your feedback and marks for ',
            },
            {
              text: '<insert assignment name>',
              b: true,
            },
            {
              text: ' are now in Handin.',
            },
          ],
        },
        {
          children: [
            {
              text: 'When you go to the assignment, you will find a ',
            },
            {
              text: 'written summary / final grade, voice/video note offering feedback.',
              b: true,
            },
          ],
        },
        {
          children: [
            {
              text: '+ Add relevant assignment block here',
              b: true,
            },
          ],
        },
        {
          children: [
            {
              text: '🥳 Insert a congratulatory and encouraging message here!',
              b: true,
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '50fd998e-f497-4dd1-9401-073754fb1a27',
    name: 'Weekly group challenge',
    description:
      'Use this template when you want to remind students of their challenge, as well as giving them somewhere to post their output. Keeping all outputs in one feedpost allows the community feed to be easier to navigate, as well as encouraging feedback from peers as it is all easy to find.',
    category: 'Activities & challenges',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '🚀 Week ',
            },
            {
              text: '<insert week>',
              u: true,
            },
            {
              text: ' Group Challenge:',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: '🎯 Your group challenge was to ',
            },
            {
              text: '<insert challenge summary>.',
              b: true,
            },
            {
              text: '.',
            },
          ],
        },
        {
          children: [
            {
              text: '👥 Now you have completed this, please ',
            },
            {
              text: 'nominate',
              b: true,
            },
            {
              text: ' one member of your group to ',
            },
            {
              text: 'share',
              b: true,
            },
            {
              text: ' your ',
            },
            {
              text: '<insert output>',
              b: true,
            },
            {
              text: ' as a reply below.',
            },
          ],
        },
        {
          children: [
            {
              text: '​🗣 Then, individually ',
            },
            {
              text: 'read',
              b: true,
            },
            {
              text: ' and ',
            },
            {
              text: 'evaluate',
              b: true,
            },
            {
              text: " each other groups' feedback.",
            },
          ],
        },
        {
          children: [
            {
              text: '🧐 ',
            },
            {
              text: 'Think about:',
              b: true,
            },
            {
              text: '\n     ❓ Is it ',
            },
            {
              text: 'accurate',
              b: true,
            },
            {
              text: ', ',
            },
            {
              text: 'clear',
              b: true,
            },
            {
              text: ' and ',
            },
            {
              text: 'specific',
              b: true,
            },
            {
              text: '?\n     ❓ ',
            },
            {
              text: '<Insert relevant subject specific question to guide feedback>',
              b: true,
            },
            {
              text: '?\n     ❓ If so, ',
            },
            {
              text: 'how',
              b: true,
            },
            {
              text: '?  If not, ',
            },
            {
              text: 'why',
              b: true,
            },
            {
              text: ' not?',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '6869ef89-d11c-4e05-a9fe-7470054a845d',
    name: 'Group activity',
    description:
      'Use this template when you want to prompt a group discussion between students, with clear instructions on expectations. Giving students a clear set of instructions with an expectation of output will help to drive the correct output from groups, as well as minimize time students spend dissecting the task as opposed to working on it.',
    category: 'Activities & challenges',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '✏️ Group Activity: ',
            },
            {
              text: 'Insert enquiry question title?',
              u: true,
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'Write a short introduction to the activity here.',
              b: true,
            },
          ],
        },
        {
          children: [
            {
              text: 'Use bullet points and relevant emojis to highlight key points.',
              b: true,
            },
            {
              text: '\n     ⚽️ Subject specific \n     🧐 Evidence\n     🎓 Formal tone/language·\n     🥉 Use of the third person',
            },
          ],
        },
        {
          children: [
            {
              text: 'T',
            },
            {
              text: 'o Do:',
              u: true,
            },
          ],
          type: 'h3',
        },
        {
          children: [
            {
              text: '✏️ ',
            },
            {
              text: 'First',
              b: true,
            },
            {
              text: ', in your group chat ',
            },
            {
              text: 'insert first step.',
              b: true,
            },
          ],
        },
        {
          children: [
            {
              text: '🚀 ',
            },
            {
              text: 'Next insert second step.',
              b: true,
            },
          ],
        },
        {
          children: [
            {
              text: '👥 ',
            },
            {
              text: 'Then',
              b: true,
            },
            {
              text: ', nominate one member of your group to share your ',
            },
            {
              text: 'insert output',
              b: true,
            },
            {
              text: ' as a reply below.',
            },
          ],
        },
        {
          children: [
            {
              text: '🗣 ',
            },
            {
              text: 'Finally',
              b: true,
            },
            {
              text: ', individually ',
            },
            {
              text: 'read',
              b: true,
            },
            {
              text: ' and ',
            },
            {
              text: 'evaluate',
              b: true,
            },
            {
              text: " each other groups' work.",
            },
          ],
        },
        {
          children: [
            {
              text: '🧐 ',
            },
            {
              text: 'Think about:',
              b: true,
            },
            {
              text: '\n     ❓ Is it ',
            },
            {
              text: 'accurate',
              b: true,
            },
            {
              text: ', ',
            },
            {
              text: 'clear',
              b: true,
            },
            {
              text: ' and ',
            },
            {
              text: 'specific',
              b: true,
            },
            {
              text: '?\n     ❓ ',
            },
            {
              text: '<Insert relevant subject specific question to guide feedback>',
              b: true,
            },
            {
              text: '?\n     ❓ If so, ',
            },
            {
              text: 'how',
              b: true,
            },
            {
              text: '?  If not, ',
            },
            {
              text: 'why',
              b: true,
            },
            {
              text: ' not?',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '70e107cf-65bb-40b7-8903-9233c4b62cb2',
    name: 'Community ground rules',
    description:
      'Use this template when you want to set the expectations from both students and for yourself when starting the module. These ground rules can be edited to match your own personal preferences. Establishing this social contract will allow students to understand that they are not here to simply listen, but to engage with materials and their community.',
    category: 'Activities & challenges',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '👥 Community Ground Rules:',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: '🤗 I agree to share my skills and capabilities to support and help my fellow students where I can.',
            },
          ],
        },
        {
          children: [
            {
              text: "🧘🏾‍♂️ If I don't feel comfortable being on-screen during live sessions, I will upload a profile picture of myself!",
            },
          ],
        },
        {
          children: [
            {
              text: '👥 I will always treat others with respect, even if I disagree with their perspective.',
            },
          ],
        },
        {
          children: [
            {
              text: '🚀 I understand that all communications are through Aula, which has both private and group messaging capability. Tutors will respond to all feed posts and direct messages within 24 hours Monday - Friday.',
            },
          ],
        },
        {
          children: [
            {
              text: '🧐 I will check the Community Feed regularly, on a daily basis if possible, but at least 3-4 times a week.',
            },
          ],
        },
        {
          children: [
            {
              text: '👥 I will contribute to both the Community Feed and my group chats a minimum of twice per week.',
            },
          ],
        },
        {
          children: [
            {
              text: 'To Do:',
              u: true,
            },
          ],
          type: 'h3',
        },
        {
          children: [
            {
              text: '🤝 Please give a thumbs up emoji to let us know you have read, and agree to this social contract.\n',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '319bd83c-1964-4bfd-a0b1-026d8ff50af7',
    name: 'Live seminar Q&A',
    description:
      "Use this template when you want to direct students' attention to asking questions during live seminars. Adding this will encourage less confident students to engage, whilst also allowing for less disruptions during the live sessions. Responding to questions directly on the feed will also allow students who could not make the seminar, or wish to use the materials to revise, to do so at a later date.",
    category: 'In-class',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '❓Live Seminar Q&A',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: "Please add your questions below as you listen to the presentations and feedback throughout this week's seminar!\n",
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: 'ffe3d0ed-e6b6-45fe-89ce-ea11aae053fc',
    name: 'What are you listening to?',
    description:
      'Use this template when you want to encourage student engagement and participation, and build a sense of community through discussion of a neutral topic. This can be replaced with what are you watching / reading to keep the activity fresh during the module. Building a sense of connection and community outside of academic information has been shown to encourage academic engagement.',
    category: 'Just for fun',
    content: {
      context: {
        entities: {
          'cd81d1b4-c8e7-4da3-b70c-30215233cd2b': {
            type: 'meta',
            url: 'https://padlet.com/naomirowan/f1jvem5mmi4exzx1',
            data: {
              title: "What I'm Listening to Now!",
              description: 'Made with interest',
              image:
                'https://padlet.pics/1/image?t=ar_2,c_lfill,dpr_1,f_jpg,g_auto,h_600,w_1200&url=https%3A%2F%2Fassets.padletcdn.com%2Fstatic%2Fpadlets%2Ff1jvem5mmi4exzx1%2Fexports%2Fpeek%2F1629707746%2FQkFoN0J6b1BjSFZpYkdsalgydGxlVWtpRldZeGFuWmxiVFZ0YldrMFpYaDZlREVHT2daRlZEb01kbVZ5YzJsdmJra2lEekUyTWprM01EYzNORFlHT3daRy0tOWI3Njg3MGM2MzVhMTgyMmYxN2I5ZTBiNzc4YmY1Njk4OTkwOTA3Ng--',
              icon: 'https://padlet.net/assets/favicon-d3e0268866afd2cc64f5a47987729eb9a6e125cb4147f9dc7fb4ba5056b2bb82.ico',
              category: 'summary',
            },
          },
        },
      },
      value: [
        {
          children: [
            {
              text: '🎧Just for Fun! What are you listening to? (not compulsory)',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: "If you have a favorite song or artist that you're listening to right now, please post it here by selecting the plus sign in the pink circle at the bottom right of the Music window.  No need to add your name to the post if you don't want to.  You're welcome to add multiple songs or artists.",
            },
          ],
        },
        {
          type: 'blockLink',
          entity: 'cd81d1b4-c8e7-4da3-b70c-30215233cd2b',
          children: [
            {
              text: '',
            },
          ],
        },
        {
          children: [
            {
              text: '',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: 'edbff87c-095f-4da6-b34a-f62bfaaef61b',
    name: '.gif battle',
    description:
      'Use this template when you want to encourage student interaction and engagement. Using activities like these at the start of live sessions can help students to loosen up and feel more comfortable with each other, building safety and trust which is beneficial to the learning environment.',
    category: 'Just for fun',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '🤣 Just for Fun .gif Battle',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'Before posting this, copy this ',
              b: true,
            },
            {
              type: 'inlineLink',
              url: 'https://trello.com/b/Z07yg0BA/gif-battle-game',
              children: [
                {
                  text: 'Trello board template',
                  b: true,
                },
              ],
            },
            {
              text: ' and invite students to join it. (Click on the “How it works” card to see full instructions for the game.) Add a tiebreaker round if needed.',
              b: true,
            },
          ],
        },
        {
          children: [
            {
              text: 'The game consists of four rounds, each with its own theme. You’ll use ',
            },
            {
              text: '',
            },
            {
              type: 'inlineLink',
              url: 'https://giphy.com/',
              children: [
                {
                  text: 'Giphy.com',
                },
              ],
            },
            {
              text: ' or Google Images to find a .gif that fits with the theme for that round and attach that gif to a card. Once everyone has their .gifs in, you’ll vote on the round’s best submission. At the end, the person who wins the most rounds will be crowned the Ultimate ',
            },
            {
              text: '<insert course title>',
              b: true,
            },
            {
              text: ' .Gif Battle Champion. Just think of the glory!',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: 'f24c18d6-aa9e-4d23-bb86-96f6b0d9ef5c',
    name: 'Super powers',
    description:
      'Use this template when you want to encourage student participation, whilst also getting to know each student a little more. These exercises are great for promoting creativity in students and warming up their minds to critical thinking. Having them break down and discuss the different merits of each super power can also help students view different perspectives other than their own.',
    category: 'Just for fun',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '🦹🏽 Just for Fun - Super Powers (not compulsory)',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'If you could have any superpower - invisibility, time travel, super strength etc. - what would it be and why?',
            },
          ],
        },
        {
          children: [
            {
              text: 'Share your answers below and debate the relative merits of superhuman powers!\n',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '70d6524b-c0be-4762-a013-fb2d4adb4749',
    name: 'Internal weather report',
    description:
      "Use this template when you want to gain an understanding of how your students are feeling, without asking them to directly share their emotions. This question would work best in a community where students already feel comfortable together. It is also possible to ask students to 'react' with the emoji that matches their mood for a lighter touch activity.",
    category: 'Wellbeing check',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '💙 Internal Weather Report - If your mood were the weather, what what would it be, and why?”',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'I’d love to know how you are feeling about this module so far!  Please share your ‘weather report’ and let us know how we can better support you or brighten your day!',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '1cbf114d-9295-4d6a-8274-fd0ab6345be5',
    name: 'On a scale of 1-10!',
    description:
      'Use this template when you want to understand the mood of your class. This would be best shared before a live session, so you can appropriately match your tone to the mood of students. It can also be a great prompt to get to know better how to make things easier for your students.',
    category: 'Wellbeing check',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '💙 On a scale of 1-10!',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'Rank your mood today on a scale of 1 to 10, with 1 being the worst mood and 10 being the best.',
            },
          ],
        },
        {
          children: [
            {
              text: 'If you would like to explain and elaborate on your ranking, feel free to do so in the comments below! \n',
            },
          ],
        },
      ],
    },
    version: '1',
  },
  {
    id: '22775e7f-804e-451a-906b-509e048ea061',
    name: 'Rose, bud, thorn',
    description:
      'Use this template when you want to gain feedback from students, but with a structure to promote constructive criticism. These questions work well at the midpoint of a module, so that you have time to address any common themes, whilst also understanding what students are looking forward to, as to put an emphasis on these in the future.',
    category: 'Wellbeing check',
    content: {
      context: {
        entities: {},
      },
      value: [
        {
          children: [
            {
              text: '💙 Rose, Bud, Thorn',
            },
          ],
          type: 'h2',
        },
        {
          children: [
            {
              text: 'Tell me your “rose, bud, and thorn” of the course so far! ',
            },
          ],
        },
        {
          children: [
            {
              text: 'Rose - the best thing you’ve experienced so far\nBud - something you’re looking forward to \nThorn - what have you enjoyed the least or are finding the most challenging? ',
            },
          ],
        },
        {
          children: [
            {
              text: 'If you would like to explain and elaborate on these, I would love to hear your thoughts.\n',
            },
          ],
        },
      ],
    },
    version: '1',
  },
];

export default templates;
