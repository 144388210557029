import React, { PureComponent } from 'react';
import { Tooltip } from '@ublend-npm/aulaui-next';
import PropTypes from 'prop-types';

import StyledLabel from './Label.styled';

class Label extends PureComponent {
  render() {
    const { color, background, borderColor, title, tooltipText } = this.props;
    return (
      <Tooltip title={tooltipText} position="right" enabled={!!tooltipText}>
        <StyledLabel
          color={color}
          background={background}
          borderColor={borderColor}
          href="#"
          tabIndex="0"
          aria-label={tooltipText}
        >
          {title}
        </StyledLabel>
      </Tooltip>
    );
  }
}

Label.defaultProps = {
  tooltipText: '',
  color: '',
  background: '',
  borderColor: '',
};

Label.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  borderColor: PropTypes.string,
};

export default Label;
