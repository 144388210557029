import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const GroupsSummaryTitle = styled('div')`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  margin-top: ${theme.spacing.medium};
`;

export const GroupsSummaryDescription = styled('div')`
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
`;
