import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const TableFiller = styled('div')`
  flex: 1 1 0;
`;

export const TableContent = styled('div')`
  padding: 0;
  margin: 0;
`;

export const LoaderContainer = styled('div')`
  display: flex;
  padding: ${theme.spacing.xLarge};
  min-height: 40px;
`;

export const CircularProgressContainer = styled('div')`
  margin: 0 auto;
`;

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;
