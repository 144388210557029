import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

import { MessageMenuContainer } from './MessageMenu.styled';

const getMessageColor = ({ pending, failed }) => {
  if (failed) {
    return theme.color.red;
  }
  if (pending) {
    return theme.color.grayDark;
  }
  return 'inherit';
};
const getMessageOpacity = ({ pending, failed }) =>
  pending || failed ? '0.56' : '1';

export const MessageContainer = styled('div')`
  flex-grow: 1;
  opacity: ${(props) => getMessageOpacity(props)};
  color: ${(props) => getMessageColor(props)};
  margin-left: 5px;
  width: calc(100% - 105px);
  padding-right: 112px;
  box-sizing: border-box;
  .RichEditor-editor {
    margin-bottom: 0 !important;
  }
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: -25px;
  margin-right: -56px;
  background-color: ${({ highlighted }) =>
    highlighted ? theme.color.grayLight : 'none'};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${theme.color.grayLight};
    transition: background-color 0s;
    ${MessageMenuContainer} {
      opacity: 1;
    }
  }
`;
