import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import Assignment from './Assignments';
import AssignmentV4 from './AssignmentsV4';

const AssignmentAreaToggle = (props) => {
  const { assignmentPageMuiV6202501 } = useFlags();
  return assignmentPageMuiV6202501 ? (
    <Assignment {...props} />
  ) : (
    <AssignmentV4 {...props} />
  );
};

export default AssignmentAreaToggle;
