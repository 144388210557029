import styled from 'react-emotion';
import { theme, IconButton } from '@ublend-npm/aulaui-next';

const { color, spacing, borderRadius } = theme;
const { designSystem } = color;

export const Extension = styled.li`
  align-items: center;
  background: ${({ selected }) =>
    selected ? designSystem.background.grey.selected : 'none'};
  border: 2px solid transparent;
  border-radius: ${borderRadius.medium};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: ${theme.font.size.copy};
  min-height: 32px;
  padding: ${spacing.xxxSmall};
  padding-left: ${spacing.xSmall};

  :hover {
    background: ${designSystem.background.grey.hover};
  }

  :focus {
    outline: none;
    border: 2px solid ${designSystem.purple70};
    background: ${designSystem.background.grey.focus};
  }

  :not(:last-child) {
    margin-bottom: ${spacing.xSmall};
  }
`;

export const HoverIconButton = styled(IconButton)`
  color: ${theme.color.designSystem.blackOpacity60};
  opacity: 0;
  [role='button']:hover & {
    opacity: 1;
  }
  [role='button']:focus & {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
  }
  &:hover {
    background-color: ${theme.color.designSystem.background.grey.hover};
  }
`;

export const MenuContainer = styled('div')`
  display: flex;
`;

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const TitleText = styled('span')`
  max-width: ${(props) => (props.showOptions ? '80px' : 'auto')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubmissionsCount = styled('span')`
  margin-left: 5px;
`;
