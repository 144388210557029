import React from 'react';
import { AlertDialog } from '../../common/Dialog';
import {
  INBOX_CONVERSATION_ACTION_LEAVE_CONVERSATION,
  INBOX_LEAVE_CONVERSATION_TITLE,
} from '../../../../constants/texts';

export const INBOX_LEAVE_CONVERSATION_QUESTION = (conversationTitle) => (
  <div>
    You will no longer be able to send or receive messages in{' '}
    <b>{conversationTitle}</b>. To rejoin, you’ll need to be added back by
    another member of the group.
  </div>
);

interface LeaveConversationModalProps {
  title: string;
  onClose: () => {};
  onConfirm: () => {};
  open?: () => {};
  loading?: Boolean;
}

const LeaveConversationModal = ({
  title,
  open,
  onClose,
  onConfirm,
  loading,
}: LeaveConversationModalProps) => (
  <AlertDialog
    id="leave-group-conversation"
    title={INBOX_LEAVE_CONVERSATION_TITLE}
    message={INBOX_LEAVE_CONVERSATION_QUESTION(title)}
    open={open}
    onClose={onClose}
    action={{
      label: INBOX_CONVERSATION_ACTION_LEAVE_CONVERSATION,
      onClick: onConfirm,
      danger: true,
      loading,
    }}
    maxWidth="sm"
  />
);

export default LeaveConversationModal;
