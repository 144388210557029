/* eslint-disable no-undef */
import { MapDispatchToProps, connect } from 'react-redux';
import { openSchedulePost } from '@core/actions/classRoom/openCloseSchedulePost';
import { editSpacePost, deleteSpacePost } from '@core/actions/space/posts';
import { currentUserId as getCurrentUserId } from '@core/selectors/user';
import {
  accessingCurrentSpaceAsStaff,
  isUpdatingPostIdInSpace,
} from '@core/selectors/classroom';
import {
  setPostImportant,
  unsetPostImportant,
} from '@core/actions/classRoom/importantPosts';
import { goToAnnouncements, goToCommonRoom } from '@app/actions/navigation';
import { ANNOUNCEMENTS } from '@core/constants/postFeedType';
import { currentPathMatches } from '@app/selectors/routing';
import { Post } from '@app/types/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { MapStateToProps } from '@core/types/state';
import { PostUserBanner } from './PostUserBanner';
import { isInstructor } from '../../../../utils/user';

const singlePostViewRouteRegex = /\/community\/post\/[a-zA-Z0-9-]+/;

export interface OwnProps {
  post: Post;
  addTag?: () => void;
  isOpenMenuItemVisible?: boolean;
}

interface PropsFromState {
  isEducator: boolean;
  isAuthor: boolean;
  isReadOnlyUser: boolean;
  isUpdatingPost: boolean;
  navigateAfterDelete: boolean;
}

interface PropsFromDispatch {
  handleDelete: (post: Post, navigateAfterDelete: boolean) => void;
  handleImportantPost: (post: Post) => void;
  handleOpenSchedulePost: (postId: string) => void;
  handlePublishPost: (postId: string) => void;
}

const mapStateToProps: MapStateToProps<PropsFromState, OwnProps> = (
  state,
  ownProps
) => {
  const currentUserId = getCurrentUserId(state);
  const postId = (ownProps.post || {}).objectId;

  return {
    isEducator: isInstructor(currentUserId),
    isAuthor: currentUserId === ownProps.post.user.objectId,
    isReadOnlyUser: accessingCurrentSpaceAsStaff(state),
    isUpdatingPost: isUpdatingPostIdInSpace(state)(postId),
    navigateAfterDelete: currentPathMatches(state, singlePostViewRouteRegex),
  };
};

const mapDispatchToProps: MapDispatchToProps<PropsFromDispatch, OwnProps> = (
  dispatch: ThunkDispatch<unknown, unknown, Action>
) => ({
  handleDelete: (post, navigateAfterDelete = false) => {
    const { objectId: id, feedType, classRoom } = post;
    dispatch(deleteSpacePost(id));

    if (navigateAfterDelete) {
      const navigationAction =
        feedType === ANNOUNCEMENTS ? goToAnnouncements : goToCommonRoom;
      dispatch(navigationAction(classRoom));
    }
  },
  handleImportantPost: ({ objectId, important }: Post) => {
    const handleImportantAction = important
      ? unsetPostImportant
      : setPostImportant;
    dispatch(handleImportantAction(objectId));
  },
  handleOpenSchedulePost: (postId) => dispatch(openSchedulePost(postId)),
  handlePublishPost: (postId) =>
    dispatch(
      editSpacePost({
        postId,
        scheduledFor: null,
        content: undefined,
      })
    ),
});

export const PostUserBannerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostUserBanner);

export default PostUserBannerContainer;
