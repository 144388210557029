import React from 'react';
import PropTypes from 'prop-types';

import { QuickTipContainer, QuickTipCommand } from './QuickTip.styled';

const QuickTip = ({ command, text }) => (
  <QuickTipContainer>
    <div>
      {'Quick tip! '}
      {command ? <QuickTipCommand>{command}</QuickTipCommand> : null}
      {text}
    </div>
  </QuickTipContainer>
);

QuickTip.propTypes = {
  /**
   * Text that will be highlighted as first part of the tip
   */
  command: PropTypes.string,
  /**
   * Text that will be shown after the command
   */
  text: PropTypes.string.isRequired,
};

export default QuickTip;
