import React from 'react';
import PropTypes from 'prop-types';

import {
  MATERIAL_GET_STARTED_STUDENT,
  MATERIAL_GET_STARTED_INSTRUCTOR,
} from '../../../../../constants/texts';
import noContent from '../../../../../../static/no-content.png';
import EmptyContent from '../EmptyContent';

const GetStarted = ({ editable }) => (
  <EmptyContent
    image={noContent}
    copy={
      editable ? MATERIAL_GET_STARTED_INSTRUCTOR : MATERIAL_GET_STARTED_STUDENT
    }
  />
);

GetStarted.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default GetStarted;
