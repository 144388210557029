import Prefixer from 'inline-style-prefixer';
import { theme } from '@ublend-npm/aulaui-next';
import fonts from '../../../../styles/fonts';

const styles = new Prefixer().prefix({
  avatar: {
    marginRight: 20,
    flexShrink: 0,
  },
  userCell: {
    display: 'flex',
    flexAlign: 'horizontal',
    marginBottom: 20,
  },
  nameButtonsContainer: {
    width: '100%',
    marginRight: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    ...fonts.light,
    color: 'black',
    margin: 0,
    fontSize: 16,
    width: 300,
  },
  instructor: {
    color: theme.color.pink,
    paddingLeft: 15,
    fontSize: 14,
  },
  buttonsContainer: {
    width: 250,
    display: 'flex',
    float: 'left',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: 45,
    cursor: 'pointer',
    fontSize: '30px',
    display: 'flex',
  },
  toggle: {
    marginLeft: 30,
    display: 'inline-block',
    width: 45,
  },
});

export default styles;
