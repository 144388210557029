import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../../../common/Dialog';

import {
  NoTitleFieldSet,
  TextField,
  FieldRow,
} from '../../../common/FieldSets';

const RenameFolderDialogInner = ({
  initialName,
  onClose,
  onRename,
  renaming,
  isLocal,
}) => {
  const [name, setName] = useState(initialName);
  const handleNameChange = (event) => setName(event.target.value);

  const handleSaveClick = () => onRename(name);

  return (
    <React.Fragment>
      <DialogTitle id="rename-folder-dialogtitle" onClose={onClose}>
        Rename section
      </DialogTitle>
      <DialogContent>
        <NoTitleFieldSet>
          <FieldRow>
            <TextField
              id="rename-folder-name"
              label="New name"
              value={name}
              onChange={handleNameChange}
            />
          </FieldRow>
        </NoTitleFieldSet>
      </DialogContent>
      <DialogActions
        primary={{
          label: 'Save',
          disabled: name.length === 0 || isLocal,
          onClick: handleSaveClick,
          loading: renaming,
          htmlAttributes: { 'data-testid': 'rename-folder-save' },
        }}
      />
    </React.Fragment>
  );
};

RenameFolderDialogInner.propTypes = {
  initialName: PropTypes.string.isRequired,
  onRename: PropTypes.func.isRequired,
  renaming: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLocal: PropTypes.bool.isRequired,
};

const RenameFolderDialog = ({ open, onClose, ...otherProps }) => (
  <Dialog
    id="rename-folder"
    data-testid="rename-folder"
    open={open}
    onClose={onClose}
  >
    <RenameFolderDialogInner onClose={onClose} {...otherProps} />
  </Dialog>
);

RenameFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RenameFolderDialog;
