import styled from 'react-emotion';
import { theme, IconButton as BaseIconButton } from '@ublend-npm/aulaui-next';
import AddReaction from '../../common/Icons/AddReaction';

export const MessageMenuContainer = styled('div')`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 23px;
  bottom: calc(100% - 12px);
  transition: opacity 200ms;
  z-index: 150;
  box-sizing: border-box;
  opacity: ${({ isFocusing }) => (isFocusing ? 1 : 0)};
  &:hover {
    opacity: 1;
  }
`;

export const IconButton = styled(BaseIconButton)`
  color: ${theme.color.grey11};
  padding: 3px;
  color: ${theme.color.grey11};
  background-color: ${theme.color.white};
  display: inline-block;
  transition: opacity 0.2s ease 0s;
  margin-left: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px 0px;
`;

export const AddReactionIcon = styled(AddReaction)`
  color: ${theme.color.grey11};
`;
