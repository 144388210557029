/* eslint-disable react-hooks/exhaustive-deps */
import { EffectCallback, DependencyList, useEffect, useRef } from 'react';

/**
 * Hook which operates like useEffect - but does not execute on mount.
 *
 * @param {EffectCallback} fn - Imperative function that can return a cleanup function
 * @param {DependencyList} deps - If present, effect will only activate if the values in the list change
 */
const useDidUpdate = (
  fn: EffectCallback,
  deps?: DependencyList | undefined
) => {
  const isMountingRef = useRef(true);

  useEffect(() => {
    if (isMountingRef.current) {
      isMountingRef.current = false;
    } else {
      fn();
    }
  }, deps);
};

export default useDidUpdate;
