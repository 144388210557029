import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const EditorContainer = styled('div')`
  font-weight: ${theme.font.weight.regular};
  line-height: ${theme.font.lineHeight.large};
  font-size: ${theme.font.size.copy};
  width: calc(100% - 66.5px);
  margin-left: 52.5px;
`;

export default EditorContainer;
