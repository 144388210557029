import React from 'react';
import PropTypes from 'prop-types';

import { DateTimePickerDialog } from '@ublend-npm/aulaui-next';

const ScheduleFolderDialog = ({
  open,
  onClose,
  scheduledFor,
  isFolder,
  onSchedule,
  onKeepHidden,
}) => {
  const secondaryAction =
    scheduledFor && onKeepHidden
      ? {
          label: isFolder ? 'Hide section' : 'Hide page',
          onAction: onKeepHidden,
        }
      : null;

  return (
    <DateTimePickerDialog
      id="materials-schedule-date-time-picker"
      open={open}
      onClose={onClose}
      date={scheduledFor}
      primaryAction={{
        label: 'Schedule',
        onAction: onSchedule,
      }}
      secondaryAction={secondaryAction}
    />
  );
};

ScheduleFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scheduledFor: PropTypes.string,
  isFolder: PropTypes.bool.isRequired,
  onSchedule: PropTypes.func.isRequired,
  onKeepHidden: PropTypes.func,
};

ScheduleFolderDialog.defaultProps = {
  scheduledFor: null,
  onKeepHidden: null,
};

export default React.memo(ScheduleFolderDialog);
