import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, theme } from '@ublend-npm/aulaui-next';
import CircularProgressPresentational from '../../../../../CircularProgress';
import ExtensionInfoForm from '../../components/ExtensionInfoForm/ExtensionInfoForm';
import ExtensionSummary from '../../components/ExtensionSummary/ExtensionSummary';
import Navigation from '../../components/Navigation/Navigation';
import { Root, RootCentered } from './Main.styled';
import { STUDENTS_VIEW } from '../../components/Navigation/constants';
import {
  SpaceGroupType,
  ExtensionModalDataType,
} from '../../ExtensionModal.types';
import getStudentsByGroups from '../getStudentsByGroups';
import NavigationView from './NavigationView';

const Main = ({
  title,
  data: {
    extensionName,
    navigationView,
    assignedStudents,
    unassignedStudents,
    groups,
  },
  setData,
  setStep,
  spaceGroups,
  isLoadingStudents,
}) => {
  const groupsStudents = getStudentsByGroups(groups, spaceGroups);
  const selectedStudentsNumber =
    navigationView === STUDENTS_VIEW
      ? assignedStudents.length
      : groupsStudents.length;
  return (
    <>
      <DialogTitle
        closeIcon={false}
        padding={`${theme.spacing.large} ${theme.spacing.xLarge}
    ${theme.spacing.medium}`}
      >
        {title}
        <ExtensionInfoForm
          extensionName={extensionName}
          onExtensionNameChange={(newExtensionName) =>
            setData({ extensionName: newExtensionName })
          }
        />
      </DialogTitle>

      {isLoadingStudents ? (
        <RootCentered>
          <CircularProgressPresentational />
        </RootCentered>
      ) : (
        <Root>
          <ExtensionSummary
            selectedStudentsNumber={selectedStudentsNumber}
            leftStudentsNumber={unassignedStudents.length}
            navigationView={navigationView}
            setStep={setStep}
          />
          <Navigation
            selected={navigationView}
            onChange={(newNavigationView) =>
              setData({
                navigationView: newNavigationView,
              })
            }
          />

          <NavigationView
            data={{ assignedStudents, groups, unassignedStudents }}
            setData={setData}
            spaceGroups={spaceGroups}
            navigationView={navigationView}
            isLoadingStudents={isLoadingStudents}
          />
        </Root>
      )}
    </>
  );
};

Main.propTypes = {
  title: PropTypes.string.isRequired,
  spaceGroups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)).isRequired,
  data: PropTypes.shape(ExtensionModalDataType).isRequired,
  setData: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  isLoadingStudents: PropTypes.bool.isRequired,
};

export default React.memo(Main);
