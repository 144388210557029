import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { analyticsTrackEvent } from '../../../../../core/utils/analytics';
import { selectors, operations } from '../../../../store/materials';
import * as analyticsTypes from '../../../../../core/constants/analytics';
import * as userSelectors from '../../../../../core/selectors/user';
import * as classRoomSelectors from '../../../../../core/selectors/classroom';
import Material from './Material';
import {
  goToMaterials,
  goToEducatorArea,
} from '../../../../actions/navigation';

const mapStateToProps = createStructuredSelector({
  user: userSelectors.user,
  currentSectionId: selectors.currentSectionId,
  currentSectionTitle: selectors.currentSectionTitle,
  currentSpaceName: classRoomSelectors.classRoomName,
  spaceId: classRoomSelectors.currentClassRoomId,
  activeClassroom: classRoomSelectors.currentClassRoomId,
  hasFetchedSections: selectors.hasFetchedSections,
  section: selectors.currentSection,
  sectionIds: selectors.topLevelMaterialIds,
  sectionEducatorOnlyIds: selectors.topLevelMaterialEducatorOnlyIds,
  editing: selectors.editing,
  saving: selectors.isSavingSection,
  leaveDestinationPath: selectors.leaveDestinationPath,
  isDeletingSection: selectors.isDeletingSection,
  isDeletingFolder: selectors.isDeletingFolder,
  isInstructor: classRoomSelectors.isInstructor,
  hasAtLeastOneChildSection: selectors.hasAtLeastOneChildSection,
  fallbackMaterialId: selectors.getFirstMaterialId,
  fallbackEducatorMaterialId: selectors.getFirstEducatorMaterialId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchAnalytics: (sectionId, activeClassroom) => {
    // https://github.com/AulaEducation/aula/issues/13216
    // When loading the journey without a material in the URL, we may render the material section without a material id.
    if (sectionId) {
      analyticsTrackEvent(analyticsTypes.MATERIALS_VIEW, {
        itemType: analyticsTypes.MATERIAL_ITEM_TYPE,
        itemId: sectionId,
        activeClassroom,
      });
    }
  },
  onLeaveWithoutSaving: (destinationPath, keepEditing) =>
    dispatch(operations.leaveWithoutSaving(destinationPath, keepEditing)),
  goToFallbackSection: (spaceId, fallbackId) => {
    dispatch(
      ownProps.educatorOnly
        ? goToEducatorArea(spaceId, fallbackId)
        : goToMaterials(spaceId, fallbackId)
    );
  },
});

const mergeProps = (
  { fallbackMaterialId, fallbackEducatorMaterialId, spaceId, ...stateProps },
  { goToFallbackSection, ...dispatchProps },
  ownProps
) => ({
  ...ownProps,
  ...dispatchProps,
  ...stateProps,
  dispatchAnalytics: () => {
    dispatchProps.dispatchAnalytics(
      stateProps.currentSectionId,
      stateProps.activeClassroom ||
        (ownProps.params ? ownProps.params.classId : undefined)
    );
  },
  goToFallbackSection: () => {
    goToFallbackSection(
      spaceId,
      ownProps.educatorOnly ? fallbackEducatorMaterialId : fallbackMaterialId
    );
  },
  fallbackId: ownProps.educatorOnly
    ? fallbackEducatorMaterialId
    : fallbackMaterialId,
});

const MaterialContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Material);

export default MaterialContainer;
