import submitSignedRequestForm from './submitSignedRequestForm';

import { LTI_CONSUMER } from '../../../core/constants/endpoints';
import request from '../../../core/api/request';

const launchTurnitinAssignment = async ({ user, assignment, spaceId }) => {
  const { description, title, startDate, gradesReleasedAt, endDate } =
    assignment;
  const assignmentId = assignment.id || assignment.objectId;

  const { firstName, lastName, email } = user;

  const { data: signedParams } = await request({
    data: {
      assignmentId,
      spaceId,
      description,
      dueDate: endDate,
      title,
      startDate,
      gradesReleasedAt,
      firstName,
      lastName,
      email,
    },
    method: 'post',
    url: LTI_CONSUMER(),
  });

  submitSignedRequestForm(signedParams);
};

export default launchTurnitinAssignment;
