import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  talis,
  panopto,
  planetEStream,
  assignment,
  echo360,
  h5p,
  bibliu,
  templates,
  genericLti,
} from '@ublend-npm/aula-editor';

const withPlugins = (AulaEditor) => {
  const AulaEditorWithPlugins = ({
    forwardedRef,
    plugins,
    isTalisEnabled,
    isPanoptoEnabled,
    isPlanetEnabled,
    isEchoEnabled,
    isAssignmentsEnabled,
    isH5PEnabled,
    isBibliuEnabled,
    ...props
  }) => {
    const {
      webappFtEditorTemplatesEnabled202110: isTemplatesEnabled,
      ftSelfServeLtiEnabled202204: isGenericLtiEnabled,
    } = useFlags();

    const editorPlugins = useMemo(() => {
      const mergedPlugins = [...plugins];

      if (isPanoptoEnabled) mergedPlugins.push(panopto);
      if (isPlanetEnabled) mergedPlugins.push(planetEStream);
      if (isEchoEnabled) mergedPlugins.push(echo360);
      if (isTalisEnabled) mergedPlugins.push(talis);
      if (isAssignmentsEnabled) mergedPlugins.push(assignment);
      if (isH5PEnabled) mergedPlugins.push(h5p);
      if (isBibliuEnabled) mergedPlugins.push(bibliu);
      if (isGenericLtiEnabled) mergedPlugins.push(genericLti);
      if (isTemplatesEnabled) mergedPlugins.push(templates);

      return mergedPlugins;
    }, [plugins]);

    return <AulaEditor ref={forwardedRef} plugins={editorPlugins} {...props} />;
  };

  AulaEditorWithPlugins.propTypes = {
    forwardedRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    plugins: PropTypes.arrayOf(PropTypes.object),
    isTalisEnabled: PropTypes.bool,
    isPanoptoEnabled: PropTypes.bool,
    isPlanetEnabled: PropTypes.bool,
    isEchoEnabled: PropTypes.bool,
    isAssignmentsEnabled: PropTypes.bool,
    isH5PEnabled: PropTypes.bool,
    isBibliuEnabled: PropTypes.bool,
  };

  AulaEditorWithPlugins.defaultProps = {
    isTalisEnabled: false,
    isPanoptoEnabled: false,
    isPlanetEnabled: false,
    plugins: [],
    isEchoEnabled: false,
    isAssignmentsEnabled: false,
    isH5PEnabled: false,
    isBibliuEnabled: false,
    forwardedRef: null,
  };

  return React.forwardRef((props, ref) => {
    return <AulaEditorWithPlugins {...props} forwardedRef={ref} />;
  });
};

export default withPlugins;
