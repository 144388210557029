export const CREATING_EXTENSION_SERVER_ERROR =
  'Creation failed due to a server error. Please try again by clicking on the button to "Create extension".';
// TODO will be removed once the button for creating an extension is hidden for an extension
export const CREATING_EXTENSION_OF_EXTENSION_ERROR =
  'You are trying to create an extension of an extension. This is not possible.';
export const PARENT_ASSIGNMENT_SUBMISSIONS_EXIST_ERROR =
  'parent-assignment-submissions-exist';

export const STUDENT_WITH_SUBMISSION_IN_EXTENSION_ERROR =
  'extension-submissions-exist';

export const gradeTypes = {
  freeform: 'freeform',
  percentage: 'percentage',
  rubric: 'rubric',
  passFail: 'passFail',
};

export const gradeTypeDisplays = {
  freeform: 'Free form',
  percentage: 'Percentages',
  rubric: 'Rubric',
  passFail: 'Pass/Fail',
};
