/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HANDIN_PROVIDER, LTI1P3_PROVIDER } from '@core/assignments/constants';
import TopBar from './TopBar';
import List from './List';
import CreateAssignmentModal from './CreateAssignment/CreateAssignmentModal';
import getHandinUrl from './utils/getHandinUrl';
import EditAssignmentModal from './EditAssignment/EditAssignmentModal';

class Assignments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      isCoupled: false,
      editingAssignment: null,
    };

    // @FIXME: need to check babel configuration,
    // because without binding in constructor
    // when calling from componentDidMount
    // `this` becomes undefined
    this.handleFetchAssignments = this.handleFetchAssignments.bind(this);
    this.handleFetchAccessToken = this.handleFetchAccessToken.bind(this);
    this.clearEditModal = this.clearEditModal.bind(this);
    this.handleToggleCreateAssignment =
      this.handleToggleCreateAssignment.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
  }

  componentDidMount() {
    this.handleFetchAssignments();
  }

  componentDidUpdate(prevProps) {
    const { selectedSpace } = this.props;

    const thisSpaceId = selectedSpace.objectId;
    const prevSpaceId = prevProps.selectedSpace.objectId;

    if (thisSpaceId && thisSpaceId !== prevSpaceId) {
      this.handleFetchAssignments();
    }
  }

  clearEditModal() {
    const { isCreating, isCoupled } = this.state;

    if (!isCreating || isCoupled) {
      this.setState({
        editingAssignment: null,
        isCreating: false,
        isCoupled: false,
      });
    }
  }

  handleToggleCreateAssignment() {
    const { isCreating } = this.state;
    const nextState = !isCreating;

    this.setState({ isCreating: nextState }, () => {
      this.clearEditModal();
    });
  }

  handleEditing(assignment, isCoupled) {
    const { baseDomain } = this.props;

    const shouldRedirectToHandin = assignment.provider === HANDIN_PROVIDER;
    if (shouldRedirectToHandin) {
      const { space, classId, externalId } = assignment;

      // after PUT request to update Handin assignment, space is replaced by classId
      const spaceId = space || classId;

      const externalUrl = getHandinUrl({
        baseDomain,
        space: spaceId,
        assignment: externalId,
        search: 'editAssignment=true&source=ma',
      });
      window.open(externalUrl, '_blank');

      return;
    }

    this.setState({
      editingAssignment: assignment,
      isCreating: true,
      isCoupled,
    });
  }

  async handleFetchAccessToken(spaceId) {
    const { fetchAccessToken, accessTokens, needsToRefreshAccessTokenProp } =
      this.props;
    if (needsToRefreshAccessTokenProp()) {
      const accessToken = await fetchAccessToken({ spaceId });
      return accessToken;
    }

    const accessToken = accessTokens[spaceId]
      ? accessTokens[spaceId].token
      : await fetchAccessToken({ spaceId });

    return accessToken;
  }

  async handleFetchAssignments() {
    const {
      isFetching,
      selectedSpace: { objectId: spaceId },
      fetchAssignments,
      user,
    } = this.props;

    if (isFetching || !spaceId) {
      return;
    }

    // @HACK: we can't rely on `componentDidUpdate` to get accessToken
    // due to race condition
    // so we need to get accessToken here
    const accessToken = await this.handleFetchAccessToken(spaceId);

    if (accessToken) {
      fetchAssignments({
        user,
        accessToken,
        spaceId,
      });
    }
  }

  render() {
    const { selectedSpace, isEducator } = this.props;
    const { isCreating, isCoupled, editingAssignment } = this.state;

    const editingCoupledAssignment =
      !!editingAssignment &&
      isCoupled &&
      editingAssignment.provider === LTI1P3_PROVIDER;

    return [
      <TopBar
        key="top-bar"
        onShowCreateAssignment={this.handleToggleCreateAssignment}
      />,
      <List
        key="assignments-list"
        onEditAssignment={this.handleEditing}
        selectedSpace={selectedSpace}
      />,
      isEducator && (
        <>
          <CreateAssignmentModal
            key={isCreating}
            isVisible={isCreating && !isCoupled}
            onClose={this.handleToggleCreateAssignment}
            currentSpace={selectedSpace}
            editingAssignment={editingAssignment}
            fetchAccessToken={this.handleFetchAccessToken}
            fetchAssignments={this.handleFetchAssignments}
          />
          <EditAssignmentModal
            isVisible={editingCoupledAssignment}
            onClose={this.clearEditModal}
            assignment={editingAssignment}
            currentSpace={selectedSpace}
          />
        </>
      ),
    ];
  }
}

Assignments.propTypes = {
  user: PropTypes.object.isRequired,
  selectedSpace: PropTypes.object,
  isFetching: PropTypes.bool,
  isEducator: PropTypes.bool.isRequired,
  accessTokens: PropTypes.object.isRequired,
  fetchAccessToken: PropTypes.func.isRequired,
  fetchAssignments: PropTypes.func.isRequired,
  baseDomain: PropTypes.string.isRequired,
  needsToRefreshAccessTokenProp: PropTypes.func.isRequired,
};

Assignments.defaultProps = {
  selectedSpace: {},
  isFetching: false,
};

export default Assignments;
