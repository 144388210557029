import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const participantList = css`
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const participantListFull = css`
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 24px 20px;
`;

export const participantItem = css`
  margin-right: 8px;
`;

export const participantItemFull = css`
  margin-right: 0;
  margin-bottom: 20px;
`;

export const modalPaperClass = css`
  min-width: 340px;
`;

export const modalClass = css`
  position: absolute;
`;

export const modalContentContainer = css`
  overflow: auto;
  height: 340px;
`;

export const OthersContainer = styled('span')`
  font: inherit;
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.semiBold}
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  margin-top: 4px;
  color: ${theme.color.designSystem.purple70};
  cursor: pointer;
`;
