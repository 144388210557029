import * as material from '@material-ui/core';
import { theme } from '@ublend-npm/aulaui-next';
import styled, { css } from 'react-emotion';

export const SectionContainer = styled('div')`
  padding: 0;
  margin: 0;
  width: 232px;
  background-color: ${theme.color.beige};
  top: 0;
  bottom: 0;
  left: 0;
`;

export const List = styled(material.List)`
  padding: 0;
`;

export const ListItem = styled('div')`
  cursor: pointer;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0px 16px;
  width: ${({ showUnhide }) => (showUnhide ? '152' : '200')}px;
  white-space: nowrap;
  font-weight: ${({ current }) =>
    theme.font.weight[current ? 'bold' : 'regular']};

  border-top: ${({ draggingOver }) =>
    draggingOver
      ? `1px solid ${theme.color.designSystem.purple70}`
      : 'initial'};

  &:hover {
    background-color: ${theme.color.designSystem.background.grey.hover};
  }
  &:active {
    background-color: ${theme.color.designSystem.background.grey.selected};
  }
`;

const greyIfRequested = ({ grayed }) =>
  grayed ? theme.color.grey4 : 'initial';

export const InvisibleButton = styled('button')`
  font: inherit;
  background: none;
  padding: 12px 0;
  margin: 0;
  border: 0;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  color: ${greyIfRequested};
  span {
    color: ${greyIfRequested};
  }
  cursor: pointer;
`;

export const DeletionDialogMessage = styled('span')`
  font-weight: ${theme.font.weight.semiBold};
`;
