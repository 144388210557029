import { connect } from 'react-redux';
import { getLtiProviders } from '@core/selectors/lti';
import { accessingCurrentSpaceAsStaff } from '@core/selectors/classroom';
import { accessToken as getAccessToken } from '@core/selectors/user';
import showToast from '@core/toasts/operations/showToast.operation';

import List from './List';

import {
  operations as assignmentOperations,
  selectors as assignmentSelectors,
} from '../../../../../store/assignments';
import { isInstructorInSpace } from '../../../../../utils/user';
import exportSubmissionGrades from '../../../../../actions/assignments/exportGrades.action';
import { includeSearchQuery, hasProvider } from './utils';
import {
  goToSubmissions,
  goToReports,
} from '../../../../../actions/navigation';

// @HACK: some TII assignments has invalid value of null, need to default it to false
// refer to #5797
const defaultIfNull = (value, def) => (value === null ? def : value);

const filterAssignmentParams = (assignment) => ({
  provider: assignment.provider,
  status: assignment.status,
  isHidden: assignment.isHidden,
  title: assignment.title,
  description: assignment.description,
  startDate: assignment.startDate,
  endDate: assignment.endDate,
  assessment: assignment.assessment,
  objectId: assignment.objectId,
  externalId: defaultIfNull(assignment.externalId, undefined),
  groupSet: assignment.groupSet,
  isAnonymised: defaultIfNull(assignment.isAnonymised, false),
  shouldCloseOnDueDate: defaultIfNull(assignment.shouldCloseOnDueDate, false),
});

const editAssignment = (prop) => (user, spaceId, assignment) =>
  assignmentOperations.editAssignment({
    currentUser: user,
    spaceId,
    assignment: {
      ...filterAssignmentParams(assignment),
      ...prop,
    },
    accessToken: undefined, // get token from state
  });

const closeAssignment = (user, spaceId, assignment) =>
  assignmentOperations.closeAssignment({
    currentUser: user,
    spaceId,
    assignment,
  });

const reopenAssignment = (user, spaceId, assignment) =>
  assignmentOperations.reopenAssignment({
    currentUser: user,
    spaceId,
    assignment,
  });

const toggleHideAssignment = (spaceId, assignment) =>
  assignmentOperations.toggleHideAssignment({
    spaceId,
    assignment,
  });

const mapStateToProps = (state) => {
  const { user } = state.user;

  const spaceId = assignmentSelectors.getSelectedSpaceId(state);
  const isFetching = assignmentSelectors.isFetchingAssignments(state);
  const query = assignmentSelectors.getAssignmentQuery(state);

  return {
    user,
    assignments: assignmentSelectors
      .getAssignments(state)
      .filter(hasProvider)
      .filter(includeSearchQuery(query)),
    isErrored: assignmentSelectors.isErrored(state),
    isFetching: isFetching || state.accessTokens.isFetching,
    hasFetchedAssignments: assignmentSelectors.hasFetchedAssignments(state),
    isEducator: isInstructorInSpace(user.objectId, spaceId),
    accessingAsStaff: accessingCurrentSpaceAsStaff(state),
    accessToken: getAccessToken(state, spaceId),
    providers: getLtiProviders(state),
  };
};

const mapDispatchToProps = {
  toggleHideAssignment,
  closeAssignment,
  reopenAssignment,
  anonymiseStudents: editAssignment({ isAnonymised: true }),
  revealStudents: editAssignment({ isAnonymised: false }),
  deleteAssignment: editAssignment({ status: 'trash' }),
  exportGrades: exportSubmissionGrades,
  goToSubmissions,
  goToReports,
  showToast: (message) => showToast({ message }),
  showLtiLaunchErrorToast: () =>
    showToast({
      message: 'There was an error when trying to open the LTI tool.',
    }),
};

const ListContainer = connect(mapStateToProps, mapDispatchToProps)(List);

export default ListContainer;
