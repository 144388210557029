import styled from 'react-emotion';

// eslint-disable-next-line import/prefer-default-export
export const CommentsFeedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-center;
`;
