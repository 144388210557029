import { connect } from 'react-redux';
import { isSectionUserProgressComplete } from '@core/materials/selectors';

import { MATERIAL_TITLE_HINT } from '../../../../../constants/texts';
import * as classRoomSelectors from '../../../../../../core/selectors/classroom';
import {
  selectors as materialSelectors,
  operations,
} from '../../../../../store/materials';
import { selectSectionInJourney } from '../../../../../actions/materials';
import SectionListItem from './SectionListItem';

const mapStateToProps = (state, ownProps) => {
  const section = materialSelectors.getMaterial(state, ownProps.materialId);
  const currentSectionId = materialSelectors.currentSectionId(state);

  return {
    isInstructor: classRoomSelectors.isInstructor(state),
    isStudent: classRoomSelectors.getIsStudent(state),
    primaryText: section.title || MATERIAL_TITLE_HINT,
    hidden: section.hidden,
    parent: section.parent,
    selected: ownProps.materialId === currentSectionId,
    sectionCompleted: isSectionUserProgressComplete(state, ownProps.materialId),
  };
};

const mapDispatchToProps = (dispatch, { materialId, educatorOnly }) => ({
  onClick: () => dispatch(selectSectionInJourney(materialId, educatorOnly)),
  onReorder: (params) => dispatch(operations.reorderMaterial(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionListItem);
