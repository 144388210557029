import React from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import CommentsEntityTypes from '@core/utils/commentsEntityTypes';
import { accessingCurrentSpaceAsStaff } from '@core/selectors/classroom';
import { getComments } from '@core/selectors/comments';
import { operations as reactionOperations } from '@app/store/reactions';
import CommentsFeed from '@components/presentational/Comments/CommentsFeed/CommentsFeed';
import Reactions from '@components/presentational//Reactions/Reactions.container';
import { getPostById } from '@core/selectors/posts.selectors';
import { Post } from '@app/types/core';
import { COMMON_ROOM } from '@core/constants/postFeedType';
import { AulaState } from '@core/types/state';
import { CommentsContainer } from './PostMeta.styled';

type PostMetaProps = {
  postId: string;
  showComments?: boolean;
};

const PostMeta = ({ postId, showComments = true }: PostMetaProps) => {
  const dispatch = useDispatch();

  const isReadOnlyUser = useSelector((state: AulaState): boolean =>
    accessingCurrentSpaceAsStaff(state)
  );

  const comments = useSelector((state: DefaultRootState): any[] =>
    showComments ? getComments(state, postId, CommentsEntityTypes.post) : []
  );

  const post = useSelector(
    (state: DefaultRootState): Post => getPostById(state, postId)
  );

  const toggleReaction = ({ emojiName }: { emojiName: string }) =>
    dispatch(
      reactionOperations.toggleReaction({
        emojiName,
        itemId: postId,
        itemType: 'UBClassRoomPost',
      })
    );

  return (
    <React.Fragment>
      <Reactions
        sequenceId={`post-${postId}`}
        itemId={postId}
        toggleReaction={toggleReaction}
        style={{ marginBottom: 15 }}
        readOnly={isReadOnlyUser}
      />
      {showComments && (
        <CommentsContainer>
          <CommentsFeed
            comments={comments}
            entityId={postId}
            entityType={CommentsEntityTypes.post}
            itemType={post.feedType || COMMON_ROOM}
            readOnly={isReadOnlyUser}
          />
        </CommentsContainer>
      )}
    </React.Fragment>
  );
};

export default PostMeta;
