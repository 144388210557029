import React from 'react';
import PropTypes from 'prop-types';
import { Separator } from '@ublend-npm/aulaui-next';
import UserBlock from '../common/Userblock/Userblock';

import {
  Container,
  UserWrapper,
  ContentContainer,
  FooterLabel,
} from './SearchResult.styled';

const PostItem = ({
  avatar,
  highlightUserName,
  userName,
  postDate,
  content,
  footer,
  onClick,
}) => (
  <Container
    onClick={onClick}
    onKeyDown={(key) => key.which === 13 && onClick()}
    tabIndex={0}
    aria-label={userName}
  >
    {userName && (
      <span>
        <UserWrapper>
          <UserBlock
            avatar={avatar}
            name={userName}
            title={highlightUserName ? <mark>{userName}</mark> : userName}
            subtitle={postDate}
            preventFocus
          />
        </UserWrapper>
        <Separator />
      </span>
    )}
    <ContentContainer>{content}</ContentContainer>
    <Separator />
    <FooterLabel>{footer}</FooterLabel>
  </Container>
);

PostItem.propTypes = {
  /**
   * Avatar's url to show as profile picture
   */
  avatar: PropTypes.string,
  /**
   * User label to display as author of content in search result
   */
  userName: PropTypes.string.isRequired,
  /**
   * True to highlight the username
   */
  highlightUserName: PropTypes.bool,
  /**
   * Formatted date displayed under the user name
   */
  postDate: PropTypes.string.isRequired,
  /**
   * Component shown as the main section
   */
  content: PropTypes.node.isRequired,
  /**
   * Component shown as the footer
   */
  footer: PropTypes.node.isRequired,
  /**
   *
   */
  onClick: PropTypes.func,
};

PostItem.defaultProps = {
  highlightUserName: false,
};

export default PostItem;
