import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const CommentCreateWrapper = styled('div')`
  padding-left: 45px;
  padding-top: 13px;
`;

export const ReactionsWrapper = styled('div')`
  padding: 10px 0 0 45px;
`;

export const ContentContainer = styled('div')`
  padding-left: 45px;
`;

export const EditorWrapper = styled('div')`
  width: calc(100% + 66.5px);
  margin-left: -52.5px;
`;

export const CommentContainer = styled('div')`
  margin-bottom: 15px;
`;

export const CommentDelimiterWrapper = styled('div')`
  padding: 5px 0 0 15px;
`;

export const CommentDelimiter = styled('hr')`
  border: 0.5px solid ${theme.color.grey3};
`;

export const HeaderWrapperStyle = {
  height: 'auto',
  overflow: 'visible',
  marginBottom: '10px',
};
