import styled from 'react-emotion';
import Button from '@mui/material/Button';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GroupIcon from '@mui/icons-material/Group';
import { theme, Alert } from '@ublend-npm/aulaui-next';

const Main = styled<{ isDisabled: boolean }, 'li'>('li')`
  margin-bottom: 16px;

  background: ${theme.color.white};
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 3px 4px rgba(0, 0, 0, 0.14);
  border: 1px solid transparent;

  ${({ isDisabled }) =>
    isDisabled
      ? `
      cursor: not-allowed;
    `
      : `
    transition : border 200ms ease-out;
    :hover {
      border: 1px solid ${theme.color.grayDark};
    };
  `}
`;

const Content = styled<{ _dense: boolean; isDisabled: boolean }, 'button'>(
  'button'
)`
  padding: ${({ _dense }) => (_dense ? '12px 12px 8px' : '12px')};

  text-align: left;
  font-family: ${theme.font.family.workSans};

  color: ${({ isDisabled }) =>
    isDisabled
      ? theme.color.designSystem.blackOpacity60
      : theme.color.designSystem.blackOpacity87};
  font-size: 20px;
  font-weight: ${theme.font.weight.semiBold};
  letter-spacing: 0;
  line-height: 28px;
  border: none;
  background: inherit;
  pointer-events: none;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;

  color: ${theme.color.designSystem.blackOpacity60};
  padding: 12px 12px 0;
`;

const Footer = styled<{ _dense: boolean; _grade: string }, 'div'>('div')`
  padding: ${({ _dense }) => (_dense ? '0 12px 12px 12px' : '12px')};

  color: ${({ _grade }) =>
    _grade ? theme.color.green : theme.color.designSystem.blackOpacity60};
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Divider = styled.div`
  background: rgba(0, 0, 0, 0.12);
  height: 1px;
`;

const IntegrationDisabledAlert = styled(Alert)`
  margin-bottom: ${theme.spacing.large};
`;

const Header = styled.div`
  padding: 12px 12px 0 12px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

const HiddenBadge = styled(Button)`
  height: 24px;
  padding: 0 2px;
  min-width: auto;
  min-height: auto;
  margin-left: 4px;
`;

const RightItems = styled.div`
  display: inline-flex;
  justify-content: right;

  margin-left: auto;
`;

const StyledVisibilityOff = styled(VisibilityOff)`
  color: ${theme.color.designSystem.blackOpacity60};
  font-size: 20px;
`;

const GroupBadge = styled(Button)`
  color: ${theme.color.designSystem.blackOpacity60};
  height: 24px;
  padding: 0;
  min-width: auto;
  min-height: auto;
  margin-left: 4px;
`;

const AnonymousBadge = styled(Button)`
  color: ${theme.color.designSystem.blackOpacity60};
  height: ${theme.spacing.xLarge};
  padding: 0;
  min-width: auto;
  min-height: auto;
  margin-left: 4px;
`;

const StyledGroupIcon = styled(GroupIcon)`
  font-size: 20px;
  color: ${theme.color.designSystem.blackOpacity60};
`;

const SubmittedLabel = styled.div`
  margin-left: 4px;
`;

const Grade = styled.div`
  color: ${theme.color.green};
  line-height: 20px;
  font-size: 12px;
  font-weight: ${theme.font.weight.medium};
`;

const ClickableContent = styled<{ isDisabled: boolean }, 'a'>('a')`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  display: block;
`;

const AlertText = styled.span`
  color: ${theme.color.error};
`;

export {
  Main,
  Content,
  Footer,
  Divider,
  Header,
  HeaderContent,
  HiddenBadge,
  RightItems,
  StyledVisibilityOff,
  SubmittedLabel,
  Grade,
  ClickableContent,
  StyledGroupIcon,
  GroupBadge,
  AnonymousBadge,
  Subtitle,
  AlertText,
  IntegrationDisabledAlert,
};
