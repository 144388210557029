import React from 'react';
import { Button } from '@ublend-npm/aulaui-next';
import { buttonStyle } from './DiscussionTopicDialog.styled';

const RemoveDiscussionButton = ({ onClick }) => (
  <Button
    type="text"
    onClick={onClick}
    aria-label="remove discussion"
    css={buttonStyle}
  >
    Remove discussion
  </Button>
);

const HideDiscussionButton = ({ onClick }) => (
  <Button
    type="text"
    onClick={onClick}
    aria-label="hide discussion"
    css={buttonStyle}
  >
    Hide from students
  </Button>
);

const ShowDiscussionButton = ({ onClick }) => (
  <Button
    type="text"
    onClick={onClick}
    aria-label="show discussion"
    css={buttonStyle}
  >
    Show to students
  </Button>
);

export { RemoveDiscussionButton, HideDiscussionButton, ShowDiscussionButton };
