import styled from 'react-emotion';

export const FabWrapper = styled('div')`
  position: absolute;
  z-index: 151;
  right: 16px;
  bottom: 16px;
  height: 42px;
  width: 42px;
`;

export const Container = styled('div')`
  position: relative;
`;
