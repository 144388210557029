import React from 'react';
import PropTypes from 'prop-types';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Fab } from '@ublend-npm/aulaui-next';
import { FabWrapper, Container } from './JumpToBottomButton.styled';

const JumpToBottomButton = ({ onClick, visible }) => (
  <Container>
    {visible && (
      <FabWrapper>
        <Fab aria-label="Jump to bottom" onClick={onClick}>
          <ArrowDownwardIcon />
        </Fab>
      </FabWrapper>
    )}
  </Container>
);

JumpToBottomButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default JumpToBottomButton;
