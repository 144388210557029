import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import {
  MATERIAL_SECTION_DIALOG_DELETE_TITLE,
  MATERIAL_SECTION_DIALOG_DELETE_SUBTITLE,
} from '../../../../../constants/texts';
import { DeletionDialogMessage } from './common.styled';

const DeleteFolderDialog = ({
  open,
  onClose,
  folderTitle,
  onConfirm,
  deleting,
}) => (
  <ConfirmDeleteDialog
    id="delete-section-modal"
    data-testid="delete-section-modal"
    title={MATERIAL_SECTION_DIALOG_DELETE_TITLE}
    message={
      <span>
        <DeletionDialogMessage>{folderTitle}</DeletionDialogMessage>{' '}
        {MATERIAL_SECTION_DIALOG_DELETE_SUBTITLE}
      </span>
    }
    open={open}
    onClose={onClose}
    onConfirm={onConfirm}
    deleting={deleting}
  />
);

DeleteFolderDialog.propTypes = {
  deleting: PropTypes.bool.isRequired,
  folderTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteFolderDialog;
