import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Menu, MenuItem } from '@ublend-npm/aulaui-next';
import {
  MenuButton,
  menuItemTextColor,
  StyledDivider,
  menuContainer,
  MenuText,
} from './CardMenu.styled';

class CardMenu extends PureComponent {
  render() {
    const { menuItems, title } = this.props;

    return (
      <Menu
        classes={{ menu: menuContainer }}
        menuListComponent="ul"
        menuListAttributes={{
          role: 'listbox',
          'aria-label': 'Select card action',
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        renderTarget={({ handleTargetClick }) => (
          <MenuButton
            icon={MoreVertIcon}
            size="xSmall"
            label="Assignment menu"
            buttonAttributes={{
              'data-testid': `assignment-${title}-menu`,
            }}
            onClick={handleTargetClick}
          />
        )}
        onSelect={({ item }) => (typeof item === 'function' ? item() : null)}
        renderChildren={({ getItemProps }) =>
          menuItems.map((menuItem) =>
            menuItem.divider ? (
              <StyledDivider key={menuItem.id} />
            ) : (
              <MenuItem
                dense
                size="small"
                className={menuItem.textClass || menuItemTextColor}
                key={menuItem.id}
                data-testid={`menu-item-${title}-${menuItem.text}`}
                leftDecoration={<menuItem.icon />}
                primaryText={<MenuText>{menuItem.text}</MenuText>}
                {...getItemProps({
                  item: menuItem.onClick,
                  onClick: (e) => e.stopPropagation(),
                })}
              />
            )
          )
        }
      />
    );
  }
}

CardMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

CardMenu.defaultProps = {
  title: '',
};

export default CardMenu;
