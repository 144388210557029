export const last = (a) => (a.length ? a[a.length - 1] : undefined);

export const fullName = (user) => `${user.firstName} ${user.lastName}`;

export const isEmptyBlock = (block) =>
  (block.type === 'unstyled' && block.text.trim() === '') ||
  (block.type === 'atomic' && block.data.type === 'spacing');

export const isEmptyEditorContent = (content) => {
  if (!content) return true;
  if (!Object.keys(content.blockMap).length) return true;

  const emptyBlocksReducer = (acc, next) => acc && isEmptyBlock(next);
  return Object.values(content.blockMap).reduce(emptyBlocksReducer, true);
};

export const isOnline = (store) => (id) => !!store[id];

export const otherParticipants = (currentUser, conversation) => {
  const participants = { ...conversation.participants };
  delete participants[currentUser];
  return Object.keys(participants);
};

export const otherParticipantOrMe = (currentUser, conversation) =>
  otherParticipants(currentUser, conversation).pop() || currentUser;

export const isUnread = (currentUserId, conversation) => {
  const participation = conversation.participants[currentUserId];
  if (!conversation.latestMessage || !participation) return false;

  if (conversation.latestMessage.writer === currentUserId) return false;

  const readUpTo = participation.readUpTo || new Date(0).toISOString();
  return readUpTo < conversation.latestMessage.createdAt;
};

export const shallowDiff = (o1, o2) =>
  Object.entries(o1).reduce((acc, [key, value]) => {
    if (o2[key] !== value) acc.push(key);
    return acc;
  }, []);

export const isMetaKeyPressed = (e) => {
  const hasMetaKey = ['Meta', 'Control', 'Shift', 'Alt', 'Tab'].includes(e.key);
  const isSearchOrCopyShortcut =
    (e.metaKey || e.ctrlKey) && (e.key === 'c' || e.key === 'f');

  return hasMetaKey || isSearchOrCopyShortcut;
};

export const flow =
  (...functions) =>
  (component) =>
    functions.reduce((acc, next) => next(acc), component);
