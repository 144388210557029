import { connect } from 'react-redux';

import { selectors, operations } from '../../../../../store/materials';

import * as appCreators from '../../../../../actions/materials';

import ScheduleMaterialDialog from './ScheduleMaterialDialog';

const mapStateToProps = (state) => {
  const materialId = selectors.scheduleMaterialDialogOpenFor(state);
  const { isFolder, scheduledFor } = materialId
    ? selectors.getMaterial(state, materialId)
    : {};

  return {
    materialId,
    open: !!materialId,
    isFolder: !!isFolder,
    scheduledFor,
  };
};

const mapsDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(operations.closeScheduleMaterial()),
  onKeepHidden: (materialId) => () =>
    dispatch(
      operations.editFolder(materialId, {
        hidden: true,
        scheduledFor: null,
      })
    ),
  onSchedule:
    ({ id, isFolder }) =>
    (date, patch = {}) => {
      if (isFolder) {
        return dispatch(
          operations.editFolder(id, {
            hidden: true,
            scheduledFor: date,
          })
        );
      }
      return dispatch(
        operations.saveSection({
          id,
          patch: {
            ...patch,
            hidden: true,
            scheduledFor: date,
          },
          select: appCreators.selectSectionInJourney,
        })
      );
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onKeepHidden: dispatchProps.onKeepHidden(stateProps.materialId),
  onSchedule: (date) => {
    const patch = !stateProps.isFolder && ownProps.getSectionData();
    const { materialId: id, isFolder } = stateProps;
    return dispatchProps.onSchedule({ id, isFolder })(date, patch);
  },
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps,
  mergeProps
)(ScheduleMaterialDialog);
