import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const StyledOpenIcon = styled(OpenInNewIcon)`
  margin-left: ${theme.spacing.small};
  color: ${theme.color.designSystem.blackOpacity60};
  width: 18px;
  opacity: 0;
`;

export const Container = styled.a`
  color: inherit;
  text-decoration: inherit;
  display: block;
  margin-top: ${theme.spacing.xLarge};
  padding: ${theme.spacing.xSmall};

  &:hover {
    background-color: ${theme.color.designSystem.background.grey.hover};

    ${StyledOpenIcon} {
      opacity: 1;
    }
  }
`;

export const LogoContainer = styled.div`
  margin-right: ${theme.spacing.small};
  width: 24px;
  height: 24px;
`;

export const Title = styled.div`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Description = styled.div`
  margin-top: ${theme.spacing.xSmall};
  font-size: ${theme.font.size.small};
  color: ${theme.color.grey11};
  line-height: 16px;
`;
