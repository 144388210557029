import { connect } from 'react-redux';
import MessageGroup from './MessageGroup';
import { selectors as inboxSelectors } from '../../../../store/conversations';

const mapStateToProps = (state, ownProps) => {
  const firstMessage = inboxSelectors.message(state, ownProps.messages[0]);

  return {
    edited: !!firstMessage.editedAt,
  };
};

export default connect(mapStateToProps)(MessageGroup);
