import PropTypes from 'prop-types';
import React from 'react';
import EditorReadOnly from '../../common/EditorPlus/EditorReadOnly.container';

const MessageCell = ({ key, content }) => (
  <EditorReadOnly key={key} rawEditorState={JSON.parse(content)} />
);

MessageCell.propTypes = {
  key: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default MessageCell;
