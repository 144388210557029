import styled from 'react-emotion';
import { theme, Button } from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  margin-bottom: 10px;
`;

export const TitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.xSmall};
`;

export const Title = styled('span')`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  color: ${theme.color.designSystem.blackOpacity87};
`;

export const Info = styled('div')`
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.small};
  color: ${theme.color.designSystem.blackOpacity87};
`;

export const LeftStudentsButton = styled(Button)``;
