/* eslint-disable no-undef */
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  getCurrentSpaceName,
  getCurrentSpaceId,
  getScheduledPosts,
  getScheduledPostsCount,
} from '@core/selectors/space';
import {
  selectedFeedType,
  hasFinishedFetchingPostsSelector,
} from '@core/selectors/classroom';
import { FeedType, Post } from '@app/types/core';
import { goToCommonRoom } from '@app/actions/navigation';
import ScheduledPosts from './ScheduledPosts';

interface PostOwnProps {}

interface GetFeedSpecificStateToProps {
  count: number;
  scheduledPosts: Post[];
  hasFinishedFetchingPosts: boolean;
}

interface PropsFromState extends GetFeedSpecificStateToProps {
  spaceId: string;
  currentSpaceName: string;
  feedType: FeedType;
}

const getFeedSpecificStateToProps = (
  feedType: FeedType,
  state
): GetFeedSpecificStateToProps => {
  return {
    count: getScheduledPostsCount(state, feedType),
    scheduledPosts: getScheduledPosts(state, feedType),
    hasFinishedFetchingPosts: hasFinishedFetchingPostsSelector(state),
  };
};

const mapStateToProps: MapStateToProps<PropsFromState, PostOwnProps> = (
  state: any
) => {
  const feedType = selectedFeedType(state);
  return {
    spaceId: getCurrentSpaceId(state),
    currentSpaceName: getCurrentSpaceName(state) || '',
    feedType,
    isChangingClassRoom: state.classRoom.isChangingClassRoom,
    ...getFeedSpecificStateToProps(feedType, state),
  };
};

interface PropsFromDispatch {}

const mapDispatchToProps: MapDispatchToProps<
  PropsFromDispatch,
  PostOwnProps
> = (dispatch: ThunkDispatch<unknown, unknown, Action>) => ({
  goToCommonRoom: (spaceId: string) => dispatch(goToCommonRoom(spaceId)),
});

const ScheduledPostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledPosts);

export default ScheduledPostsContainer;
