import { connect } from 'react-redux';
import { selectors as inboxSelectors } from '../../../../store/conversations';
import * as usersSelectors from '../../../../../core/selectors/users';

import TypingIndicator from './TypingIndicator';

const getLabel = (typers) => {
  switch (typers.length) {
    case 0:
      return '';
    case 1:
      return `${typers[0].firstName} is typing...`;
    default:
      return 'Someone is typing...';
  }
};

const mapStateToProps = (state) => {
  const conversationId = inboxSelectors.currentConversationId(state);
  const typersIds = inboxSelectors.typersForConversation(state, conversationId);
  const typers = typersIds
    .map((id) => usersSelectors.user(state, id))
    .filter((user) => !!user);

  return {
    typing: !!typers.length,
    label: getLabel(typers),
  };
};

export default connect(mapStateToProps)(TypingIndicator);
