import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  width: 100%;
  margin: ${theme.spacing.xxSmall} 0;
  list-style-type: none;
`;

export const tooltipContainer = css`
  margin: 0;
`;
