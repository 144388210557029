import React from 'react';
import PropTypes from 'prop-types';
import ArrowForward from '@mui/icons-material/ArrowForward';

import {
  Root,
  Title,
  Info,
  TitleWrapper,
  LeftStudentsButton,
} from './ExtensionSummary.styled';
import { GROUP_VIEW, STUDENTS_VIEW } from '../Navigation/constants';
import { STUDENTS_LIST_STEP } from '../../steps/constants';

const ExtensionSummary = ({
  selectedStudentsNumber,
  leftStudentsNumber,
  navigationView,
  setStep,
  isConversion,
}) => {
  const title = isConversion
    ? `Assign students to this extension (${selectedStudentsNumber})`
    : `Assign students (${selectedStudentsNumber})`;
  const shouldShowLeftStudentButton =
    !isConversion && navigationView === STUDENTS_VIEW && !!leftStudentsNumber;
  return (
    <Root>
      <TitleWrapper>
        <Title>{title}</Title>
        {shouldShowLeftStudentButton && (
          <LeftStudentsButton
            type="text"
            size="small"
            iconRight={ArrowForward}
            onClick={() => setStep(STUDENTS_LIST_STEP)}
          >
            {leftStudentsNumber} in other cohorts
          </LeftStudentsButton>
        )}
      </TitleWrapper>
      {!isConversion && (
        <Info>
          Students added here will be removed from their original cohort
        </Info>
      )}
    </Root>
  );
};

ExtensionSummary.propTypes = {
  setStep: PropTypes.func,
  selectedStudentsNumber: PropTypes.number,
  leftStudentsNumber: PropTypes.number,
  isConversion: PropTypes.bool,
  navigationView: PropTypes.oneOf([STUDENTS_VIEW, GROUP_VIEW]).isRequired,
};

ExtensionSummary.defaultProps = {
  setStep: undefined,
  selectedStudentsNumber: 0,
  leftStudentsNumber: 0,
  isConversion: false,
};

export default React.memo(ExtensionSummary);
