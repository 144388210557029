const sortByValue = (prev, next) => {
  return prev.value.localeCompare(next.value);
};

const filterDestinationAssignmentsForConversion = (
  assignments,
  currentAssignment
) => {
  return assignments
    .filter(
      (assignment) =>
        assignment.provider === currentAssignment.provider &&
        assignment.gradeType === currentAssignment.gradeType &&
        assignment.isAnonymised === currentAssignment.isAnonymised &&
        assignment.groupSet === currentAssignment.groupSet &&
        assignment.id !== currentAssignment.id
    )
    .map(({ id, title }) => ({
      key: id,
      value: title,
    }))
    .sort(sortByValue);
};

export default filterDestinationAssignmentsForConversion;
