import PropTypes from 'prop-types';
import React from 'react';

import AulaEditorWithFileBlocksValidation from '../../../../../common/AulaEditor/AulaEditorWithFileBlocksValidation';

const AulaEditor = ({ config, editorState, onChange, spaceId }) => (
  <AulaEditorWithFileBlocksValidation
    config={{ ...config, spaceId }}
    editorState={editorState}
    onChange={onChange}
    type="assignmentExtensions"
  />
);

AulaEditor.propTypes = {
  config: PropTypes.shape({
    filesUrl: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editorState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  spaceId: PropTypes.string.isRequired,
};

export default AulaEditor;
