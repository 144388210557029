import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AnonymousIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 3.6c2.64 0 4.51 1.2 4.51 1.2l1.38 4.69H6.11L7.49 4.8S9.36 3.6 12 3.6c0 0 0 0 0 0zm0 7.85c2.77 0 6.72.58 6.72.58 1.03.28 1.87.55 2.52.81.99.33 1.56.58 1.56.58H1.2s.57-.25 1.56-.58c.65-.26 1.47-.53 2.49-.81.26-.05 4.07-.58 6.75-.58 0 0 0 0 0 0zM7.58 14.4c1.45 0 2.68.88 3.19 2.15.32-.1.69-.19 1.23-.19s.91.09 1.23.19a3.42 3.42 0 013.19-2.15c1.9 0 3.43 1.54 3.43 3.44a3.43 3.43 0 01-3.43 3.43c-1.9 0-3.44-1.54-3.44-3.43 0-.16 0-.32.03-.47-.31-.06-.65-.11-1.01-.11-.36-.01-.7.05-1.01.11.03.15.03.31.03.47 0 1.89-1.54 3.43-3.44 3.43a3.43 3.43 0 01-3.43-3.43c0-1.9 1.53-3.44 3.43-3.44 0 0 0 0 0 0zm0 .98a2.456 2.456 0 000 4.91 2.456 2.456 0 100-4.91s0 0 0 0zm6.38 2.46a2.456 2.456 0 004.91 0 2.456 2.456 0 10-4.91 0z" />
  </SvgIcon>
);

export default React.memo(AnonymousIcon);
