import PropTypes from 'prop-types';
import React from 'react';
import { isInstructor } from '../../../utils/user';
import formatDate from '../../../../core/utils/formatDate';

import { User } from './types';

import Avatar from '../../containers/User/Avatar/Avatar';
import styles from './style';

const UserBanner = ({ user, time }) => (
  <div style={styles.container}>
    <div style={styles.userImage}>
      <Avatar
        user={user}
        size={30}
        isInstructor={isInstructor(user.objectId)}
      />
    </div>
    {/* </Tooltip> */}
    <div style={styles.userNameContainer}>
      <span style={styles.userName}>
        {`${user.firstName} ${user.lastName}`}
        {isInstructor(user.objectId) ? (
          <span style={styles.instructor}>Educator</span>
        ) : null}
      </span>

      <div style={styles.time}>
        <span>{formatDate(time)}</span>
      </div>
    </div>
  </div>
);

UserBanner.propTypes = {
  user: User.isRequired,
  time: PropTypes.string.isRequired,
};

export default UserBanner;
