import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { DateTimePickerDialog, Tooltip } from '@ublend-npm/aulaui-next';
import { MuiThemeProvider } from '@material-ui/core';
import CircularProgress from '../../../CircularProgress';
import AnonymousIcon from '../../../AnonymousIcon';
import {
  ModalContent,
  Title,
  SubTitle,
  SwitchContainer,
  SwitchLabel,
  StyledTextField,
  StyledDateField,
  SwitchTitleContainer,
  HiddenIcon,
  formTheme,
  CircularProgressContainer,
} from './SharedModalStyles.styled';
import { DateFields, DateField } from './CreateModal.styled';
import UpcomingAssignmentAlert from './UpcomingAssignmentAlert.component';

const SuccessScreen = () => (
  <React.Fragment>
    <Title>Excellent!</Title>
    <SubTitle>Your assignment has been saved.</SubTitle>
  </React.Fragment>
);

class CreateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingDateType: null,
    };
  }

  renderAnonymiseSwitch() {
    const { assignment, handleSwitch, isEditing, hasAnonymizedError } =
      this.props;
    const { isAnonymised, extensions } = assignment;
    const hasAnonymizedOption = typeof isAnonymised === 'boolean';
    const shouldShowAnonymizedOption =
      !isEditing || (isEditing && hasAnonymizedOption);
    const hasSubmissions = assignment && assignment.submissions.length > 0;
    const isGradesReleasedPassed =
      new Date(assignment.gradesReleasedAt) < new Date();
    const hasExtensions = extensions && extensions.length > 0;
    const disabledSwitch =
      hasSubmissions ||
      hasAnonymizedError ||
      isGradesReleasedPassed ||
      hasExtensions;

    const tooptipText = disabledSwitch
      ? `The anonymise setting cannot be modified because the assignment ${
          hasExtensions
            ? 'contains extensions.'
            : 'already received submissions or grades released date is in the past.'
        }`
      : 'By default, student submissions will not be anonymised. Change this setting here.';

    return shouldShowAnonymizedOption ? (
      <Tooltip position="right" title={tooptipText}>
        <SwitchContainer isTop>
          <SwitchTitleContainer>
            <AnonymousIcon />
            <SwitchLabel>Anonymise student submissions</SwitchLabel>
          </SwitchTitleContainer>
          <Switch
            disabled={disabledSwitch}
            checked={isAnonymised}
            onChange={handleSwitch('isAnonymised')}
            color="primary"
            inputProps={{
              'aria-label': 'Anonymise student submissions',
            }}
          />
        </SwitchContainer>
      </Tooltip>
    ) : null;
  }

  renderForm() {
    const {
      errors,
      assignment,
      handleChange,
      handleDateTimePicker,
      handleSwitch,
    } = this.props;

    const {
      title,
      startDate,
      endDate,
      gradesReleasedAt,
      isHidden,
      description,
    } = assignment;

    const startDateFormatted = startDate ? moment(startDate).format('lll') : '';
    const endDateFormatted = endDate ? moment(endDate).format('lll') : '';
    const gradesReleaseDateFormatted = gradesReleasedAt
      ? moment(gradesReleasedAt).format('lll')
      : '';

    const { editingDateType } = this.state;
    return (
      <>
        <ModalContent data-testid="new-assignment-info">
          <MuiThemeProvider theme={formTheme}>
            <UpcomingAssignmentAlert />
            <StyledTextField
              label="Title"
              data-testid="new-assignment-title"
              value={title}
              onChange={handleChange('title')}
              error={errors.title}
            />
            <StyledTextField
              label="Description"
              data-testid="new-assignment-description"
              value={description}
              onChange={handleChange('description')}
              error={errors.description}
            />
            <DateFields>
              <DateField
                onClick={() => this.setState({ editingDateType: 'startDate' })}
              >
                <StyledDateField
                  label="Open date"
                  value={startDateFormatted}
                  error={errors.startDate}
                  data-testid="new-assignment-start-button"
                  DialogProps={{
                    'data-testid': 'new-assignment-start-date-picker',
                  }}
                />
              </DateField>

              <DateField
                onClick={() => this.setState({ editingDateType: 'endDate' })}
              >
                <StyledDateField
                  label="Due date"
                  value={endDateFormatted}
                  error={errors.endDate}
                  data-testid="new-assignment-due-button"
                  DialogProps={{
                    'data-testid': 'new-assignment-due-date-picker',
                  }}
                />
              </DateField>

              <DateField
                onClick={() =>
                  this.setState({ editingDateType: 'gradesReleasedAt' })
                }
              >
                <StyledDateField
                  label="Grades release date"
                  value={gradesReleaseDateFormatted}
                  error={errors.gradesReleasedAt}
                  data-testid="new-assignment-released-button"
                  DialogProps={{
                    'data-testid': 'new-assignment-released-date-picker',
                  }}
                />
              </DateField>
            </DateFields>
            <Tooltip
              position="right"
              title="By default, assignments are hidden from students until you are ready to make them visible."
            >
              <SwitchContainer checked={isHidden} isTop>
                <SwitchTitleContainer>
                  <HiddenIcon />
                  <SwitchLabel>Assignment is hidden</SwitchLabel>
                </SwitchTitleContainer>
                <Switch
                  checked={isHidden}
                  onChange={handleSwitch('isHidden')}
                  color="primary"
                  inputProps={{ 'aria-label': 'Assignment is hidden' }}
                />
              </SwitchContainer>
            </Tooltip>
            {this.renderAnonymiseSwitch()}
          </MuiThemeProvider>
        </ModalContent>
        <DateTimePickerDialog
          id="new-assignment-dates"
          date={(editingDateType && assignment[editingDateType]) || null}
          open={!!editingDateType}
          onClose={() => this.setState({ editingDateType: null })}
          secondaryAction={{
            label: 'Cancel',
            onAction: () => this.setState({ editingDateType: null }),
          }}
          primaryAction={{
            label: 'Save',
            onAction: (date) =>
              handleDateTimePicker(
                editingDateType,
                new Date(date).toISOString()
              ),
          }}
        />
      </>
    );
  }

  render() {
    const { isSaving, done } = this.props;

    if (!isSaving && !done) {
      return this.renderForm();
    }

    if (isSaving) {
      return (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      );
    }

    if (done) {
      return <SuccessScreen />;
    }

    return null;
  }
}

CreateModal.propTypes = {
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isSaving: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  done: PropTypes.bool.isRequired,
  hasAnonymizedError: PropTypes.bool.isRequired,
  assignment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleChange: PropTypes.func.isRequired,
  handleDateTimePicker: PropTypes.func.isRequired,
  handleSwitch: PropTypes.func.isRequired,
};

export default CreateModal;
