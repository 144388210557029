import { AulaState } from '@core/types/state';
import { LtiPlacement, LtiVersion } from '@ublend-npm/aula-schema';

const isProviderEnabled = (state: AulaState, name: string) =>
  Boolean(state.lti.providers.find((p) => p.name === name));

export const isTurnItInEnabled = (state: AulaState) =>
  isProviderEnabled(state, 'turnitin');

export const isBibliuEnabled = (state: AulaState) =>
  isProviderEnabled(state, 'bibliu');
export const isTalisEnabled = (state: AulaState) =>
  isProviderEnabled(state, 'talis');
export const isEchoEnabled = (state: AulaState) =>
  isProviderEnabled(state, 'echo');
export const isH5PEnabled = (state: AulaState) =>
  isProviderEnabled(state, 'h5p');
export const isPlanetEnabled = (state: AulaState) =>
  isProviderEnabled(state, 'planet');
export const isPanoptoEnabled = (state: AulaState) =>
  isProviderEnabled(state, 'panopto');

export const getLtiProviders = (state: AulaState) => {
  return state.lti.providers;
};

export const getMaterialPlacementLtiProviders = (state: AulaState) => {
  return state.lti.providers.filter((prov) => {
    if (prov.ltiVersion === LtiVersion.ONE_POINT_THREE) {
      return prov.placement === LtiPlacement.MATERIALS;
    }

    return true;
  });
};
