import { useEffect, useState } from 'react';
import { ObjectSchema, SchemaDescription, ValidationError } from 'yup';
import { isEqual } from 'lodash';

type Keys<T> = Extract<keyof T, string>;
type FormErrors<T> = Partial<Record<Keys<T>, string>>;

type PropOverrides = {
  helperText?: string;
  description?: string;
  readOnly?: boolean;
};
type FormFieldOverrides<T> = Partial<Record<Keys<T>, PropOverrides>>;

export const handleEmptyString = (currentValue: number, original: string) =>
  original === '' ? undefined : currentValue;

export type FormUtils<T> = {
  errors: FormErrors<T>;
  hasFormErrors: boolean;
  setField: <K extends Keys<T>>(field: K, value: string) => void;
  validateField: (field: Keys<T>) => void;
  clearForm: () => void;
  validateForm: () => T | undefined;
  getFieldProps: <K extends Keys<T>>(field: K) => FieldProps;
};

export type FieldProps = {
  id: string;
  value: string;
  error: boolean;
  helperText: string | undefined;
  onBlur: () => void;
  set: (value: string) => void;
  label: string;
  type?: string;
};

const validationOptions = { abortEarly: false, strict: false };

const extractErrors = (err: ValidationError) =>
  Object.fromEntries(err.inner.map((e) => [e.path, e.message]));

export const useYupForm = <
  Schema extends ObjectSchema<Record<string, unknown>> = ObjectSchema<
    Record<string, string>
  >,
  T = Schema['__outputType']
>({
  validationSchema,
  initialValue,
  propOverrides = {},
}: {
  validationSchema: Schema;
  initialValue?: T;
  propOverrides?: FormFieldOverrides<T>;
}): [Record<Keys<T>, string>, FormUtils<T>] => {
  type FormField = Keys<T>;
  type FormData = Record<FormField, string>;

  const getInitialValue = ({
    validationSchema,
    initialValue,
  }: {
    validationSchema: Schema;
    initialValue?: T;
  }): Record<FormField, string> => {
    if (initialValue) {
      // Form fields must be strings so stringify
      return Object.fromEntries(
        Object.entries(initialValue).map(([key, val]) => [
          key,
          val ? `${val}` : '',
        ])
      ) as FormData;
    }

    return Object.fromEntries(
      Object.keys(validationSchema.fields).map((key) => [key, ''])
    ) as FormData;
  };

  // formData initial value will be set in the useEffect below
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [errors, setErrors] = useState<FormErrors<T>>({});
  const [lastUpdated, setLastUpdated] = useState<FormField | undefined>();

  useEffect(() => {
    if (!lastUpdated) {
      const newFormData = getInitialValue({ validationSchema, initialValue });

      if (!isEqual(newFormData, formData)) {
        setFormData(newFormData);
      }
    }
  }, [initialValue, validationSchema]);

  const addError = (field: FormField, err: string) =>
    setErrors((errors) => ({ ...errors, [field]: err }));

  const removeError = <K extends FormField>(field: K) =>
    setErrors((errors) => {
      const { [field]: _, ...rest } = errors;
      return rest as FormErrors<T>;
    });

  const validateField = (field: FormField) => {
    try {
      validationSchema.validateSyncAt(field, formData, validationOptions);
      removeError(field);
    } catch (err) {
      addError(field, err.message);
    }
  };

  const validateForm = () => {
    try {
      return validationSchema.validateSync(formData, validationOptions) as T;
    } catch (err) {
      setErrors(extractErrors(err));
    }
  };

  const setField = <K extends FormField>(field: K, value: string) => {
    setFormData((data) => ({ ...data, [field]: value }));
    setLastUpdated(field);
  };

  useEffect(() => {
    if (lastUpdated) {
      validateField(lastUpdated);
    }
  }, [lastUpdated, formData]);

  const getFieldProps = <K extends FormField>(field: K): FieldProps => {
    const fieldDescription = validationSchema.describe().fields[
      field
    ] as SchemaDescription;

    const isRequired = !fieldDescription.optional;
    const label = fieldDescription.label;
    const isNumber = fieldDescription.type === 'number';

    const additionalProps = propOverrides[field];
    return {
      ...additionalProps,
      id: field,
      value: formData[field],
      error: !!errors[field],
      helperText: errors[field] || additionalProps?.helperText,
      onBlur: () => validateField(field),
      set: (value) => setField(field, value),
      label: `${label}${isRequired ? '*' : ''}`,
      type: isNumber ? 'number' : undefined,
    };
  };

  const clearForm = () => {
    setFormData(getInitialValue({ validationSchema }));
    setLastUpdated(undefined);
    Object.keys(formData).forEach((field) => removeError(field as FormField));
  };

  const hasFormErrors = Object.keys(errors).length > 0;

  return [
    formData,
    {
      errors,
      hasFormErrors,
      setField,
      validateField,
      validateForm,
      getFieldProps,
      clearForm,
    },
  ];
};
