import { theme } from '@ublend-npm/aulaui-next';

export default {
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  iconWrapper: {
    position: 'relative',
    borderRadius: 5,
    padding: 4,
    cursor: 'pointer',
    background: theme.color.beige,
    height: 20,
    display: 'inline-block',
    color: theme.color.grayDark,
    transition: 'all 0.1s',

    ':hover': {
      color: theme.color.grey6,
    },
  },
  icon: {
    fontSize: 20,
    lineHeight: '20px',
    color: theme.color.grayDark,
    fill: 'currentColor',
  },
};
