import { connect } from 'react-redux';
import {
  currentUser,
  accessToken as getAccessToken,
} from '@core/selectors/user';
import { isInstructor } from '@core/selectors/classroom';
import { getMaterialPlacementLtiProviders } from '@core/selectors/lti';

import EditorReadOnly from './EditorReadOnly';

type EditorReadOnlyProps = {
  rawEditorState: any;
  itemId?: string;
  itemType?: string;
};

const mapStateToProps = (state) => {
  const user = currentUser(state);
  const spaceId = state.classRoom.current;
  const isEducator = isInstructor(state, state.user?.userId);

  return {
    accessToken: getAccessToken(state, spaceId),
    institution: state.institution.general.institution,
    spaceId,
    user,
    isEducator,
    ltiProviders: getMaterialPlacementLtiProviders(state),
  };
};

export default connect<{}, {}, EditorReadOnlyProps>(mapStateToProps)(
  EditorReadOnly
);
