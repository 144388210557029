import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled.div`
  margin: ${theme.spacing.xSmall} ${theme.spacing.small};
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.grayLight};
  border-radius: ${theme.borderRadius.medium};
  flex-shrink: 0;
  cursor: pointer;
  transition: all ${theme.transition.speed.fast} linear;
  &:hover {
    border-color: ${theme.color.grayLight};
  }
`;

export const UserWrapper = styled.div`
  margin: ${theme.spacing.small};
`;

export const ContentContainer = styled.div`
  padding: ${theme.spacing.large} ${theme.spacing.small};
  font-size: ${theme.font.size.copy};
`;

export const FooterLabel = styled.div`
  font-size: ${theme.font.size.small};
  color: ${theme.color.grey11};
  margin: ${theme.spacing.xSmall};
  & span {
    font-weight: ${theme.font.weight.medium};
  }
`;
