import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ScheduledPosts from './Feed/ScheduledPosts';
import ScheduledPostsV4 from './FeedV4/ScheduledPosts';

const ScheduledPostsToggle = (props) => {
  const { webAppFtMuiV6Feed202412 } = useFlags();

  return webAppFtMuiV6Feed202412 ? (
    <ScheduledPosts {...props} />
  ) : (
    <ScheduledPostsV4 {...props} />
  );
};

export default ScheduledPostsToggle;
