import { connect } from 'react-redux';
import * as classRoomSelectors from '@core/selectors/classroom';
import { updateMaterialProgress } from '@core/materials/operations/updateMaterialProgress.operation';
import {
  isUpdatingMaterialProgress,
  isSectionUserProgressComplete,
} from '@core/materials/selectors';

import SectionView from './SectionView';
import * as appCreators from '../../../../actions/materials';
import { selectors, operations } from '../../../../store/materials';

const mapStateToProps = (state) => {
  const sectionId = selectors.currentSectionId(state);
  const isEducator = classRoomSelectors.isInstructor(state);
  const isStudent = classRoomSelectors.getIsStudent(state);
  return {
    sectionId,
    section: selectors.currentSection(state),
    isEducator,
    isStudent,
    editing: selectors.editing(state),
    saving: selectors.isSavingSection(state),
    selectedContent: selectors.selectedContent(state),
    isLoadingContent: selectors.isLoadingContent(state),
    reverting: selectors.isRevertingSection(state),
    isComplete: isStudent
      ? isSectionUserProgressComplete(state, sectionId)
      : false,
    isCompleteLoading: isStudent
      ? isUpdatingMaterialProgress(state, sectionId)
      : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: (sectionId) =>
    dispatch(
      operations.deleteSection({
        sectionId,
        selectSection: appCreators.selectSectionInJourney,
        educatorOnly: ownProps.educatorOnly,
      })
    ),
  onGetSection: (sectionId) =>
    dispatch(
      operations.getSection({
        sectionId,
      })
    ),
  onFindPreviousVersion: (sectionId) =>
    dispatch(operations.displayRevertModal(sectionId)),
  onSave: (patch, correlationId) =>
    dispatch(
      operations.saveSection({
        patch,
        select: appCreators.selectSectionInJourney,
        educatorOnly: ownProps.educatorOnly,
        correlationId,
      })
    ),
  onHide: (sectionId) => dispatch(operations.hideMaterial(sectionId)),
  onUnhide: (sectionId) => dispatch(operations.unHideMaterial(sectionId)),
  onEdit: () => dispatch(operations.setEditing(true)),
  onHideDiscussion: () => dispatch(operations.hideDiscussion()),
  openScheduleDialog: (sectionId) =>
    dispatch(operations.openScheduleMaterial(sectionId)),
  onKeepHidden: (sectionId) =>
    dispatch(
      operations.saveSection({
        id: sectionId,
        patch: {
          hidden: true,
          scheduledFor: null,
        },
        select: appCreators.selectSectionInJourney,
      })
    ),
  onExportMaterialPage: () => dispatch(appCreators.exportMaterialPage()),
  onImportMaterialPage: (sectionId, importingContent) =>
    dispatch(appCreators.importMaterialPage(sectionId, importingContent)), // eslint-disable-line
  submitProgress: (sectionId, userProgress) =>
    dispatch(updateMaterialProgress(sectionId, userProgress)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onDelete: () => dispatchProps.onDelete(stateProps.sectionId),
  onHide: () => dispatchProps.onHide(stateProps.sectionId),
  onUnhide: () => dispatchProps.onUnhide(stateProps.sectionId),
  onFindPreviousVersion: () =>
    dispatchProps.onFindPreviousVersion(stateProps.sectionId),
  onExportMaterialPage: () =>
    dispatchProps.onExportMaterialPage(stateProps.sectionId),
  onImportMaterialPage: (importingContent) =>
    dispatchProps.onImportMaterialPage(stateProps.sectionId, importingContent),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SectionView);
