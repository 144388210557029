import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const ProviderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ProviderTitle = styled.span`
  align-items: center;
  color: ${theme.color.designSystem.purple70};
  display: flex;
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.spacing.medium};

  svg {
    margin-left: 5px;
  }
`;
