import React, { useState } from 'react';
import { Dialog, DialogTitle, Button } from '@ublend-npm/aulaui-next';
import { useYupForm } from '@app/hooks/useYupForm/useYupForm';
import { useBoolean } from '@app/hooks/useToggle/useBoolean';
import { Assignment } from '@core/assignments/types';
import updateLti1p3Assignment from '@core/api/lti/updateLti1p3Assignment';
import { Space } from '@core/types/state';
import showToast from '@core/toasts/operations/showToast.operation';
import { useDispatch } from 'react-redux';
import { Content, Footer, StyledAlert } from './EditAssignmentModal.styled';
import { LineItemForm, LineItemFormState } from '../LineItemForm/LineItemForm';
import { getLineItemSchema } from '../LineItemForm/lineItemSchema';
import { AssignmentLaunchDetails } from '@ublend-npm/aula-schema';
import SuccessModalContent from '../../../common/SuccessModalContent/SuccessModalContent';

type EditAssignmentModalProps = {
  isVisible: boolean;
  onClose: () => void;
  assignment?: Assignment;
  currentSpace: Space;
};

const formatAssignment = ({
  title,
  description,
  startDate,
  endDate,
  gradesReleasedAt,
  maxGrade,
}: Assignment): LineItemFormState => ({
  title: title || '',
  description: description || '',
  openDate: startDate || '',
  dueDate: endDate || '',
  gradesReleaseDate: gradesReleasedAt || '',
  maxGrade: maxGrade?.toString() || '',
});

const formatPayload = (
  { providerId, resourceLinkId }: Assignment,
  {
    title,
    description,
    openDate: startDate,
    dueDate: endDate,
    gradesReleaseDate: gradesReleasedAt,
    maxGrade,
  }: LineItemFormState
): AssignmentLaunchDetails => ({
  title,
  description: description || '',
  startDate,
  endDate,
  gradesReleasedAt,
  maxGrade: parseInt(maxGrade, 10),
  providerId: providerId || '',
  resourceLinkId: resourceLinkId || '',
});

const EditAssignmentModal = ({
  isVisible,
  onClose,
  assignment,
  currentSpace,
}: EditAssignmentModalProps) => {
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saveable, setSaveableTrue, setSaveableFalse] = useBoolean(true);
  const maxGradeDisabled = assignment?.hasGradedSubmissions;

  const dispatch = useDispatch();

  const [, { getFieldProps, validateForm, clearForm }] = useYupForm({
    validationSchema: getLineItemSchema(),
    initialValue: assignment ? formatAssignment(assignment) : {},
    propOverrides: {
      maxGrade: {
        helperText: maxGradeDisabled
          ? 'The max grade is not editable once graded submissions have been received'
          : '',
        readOnly: maxGradeDisabled,
      },
    },
  });

  const isValidFormData = (formData) => {
    return formData && Object.keys(formData).length > 0;
  };

  const handleSaveError = () => {
    dispatch(
      showToast({
        emoji: '🙈',
        message: 'Oops! Something went wrong. Try again later.',
      })
    );
  };

  const handleSave = async () => {
    const formData = validateForm();
    if (!isValidFormData(formData) || !assignment) {
      setSaveableFalse();
      return;
    }

    setSaveableTrue();
    setSaving(true);

    try {
      await updateLti1p3Assignment(
        currentSpace.objectId,
        assignment.objectId,
        formatPayload(assignment, formData as LineItemFormState)
      );
      setSuccess(true);
    } catch {
      handleSaveError();
    } finally {
      setSaving(false);
    }
  };

  const handleClose = () => {
    setSaveableTrue();
    setSuccess(false);
    clearForm();
    onClose();
  };

  return (
    <Dialog open={isVisible} height="auto" width="493" onClose={handleClose}>
      {success ? (
        <SuccessModalContent
          title="Excellent!"
          subTitle="Your assignment has been saved."
          onClose={handleClose}
        />
      ) : (
        <>
          <DialogTitle onClose={handleClose}>Edit your assignment</DialogTitle>
          <Content>
            <StyledAlert type="info">
              Changes made here may not also update in the assignment tool.
              Please check to ensure alignment.
            </StyledAlert>
            {!saveable && (
              <StyledAlert type="error">
                Please fill in all required fields
              </StyledAlert>
            )}
            <LineItemForm getFieldProps={getFieldProps} />
          </Content>
          <Footer>
            <Button type="primary" onClick={handleSave} loading={saving}>
              Save
            </Button>
          </Footer>
        </>
      )}
    </Dialog>
  );
};

export default EditAssignmentModal;
