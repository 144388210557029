import { getStore } from '../../../../../store';

const getInstitution = () =>
  getStore().getState().institution.general.institution;

const launchManualAssignment = ({ assignmentId, spaceId }) => {
  window.open(
    `https://success.${getInstitution()}.aula.education/${spaceId}/assignment/${assignmentId}`,
    '_blank'
  );
};

export default launchManualAssignment;
