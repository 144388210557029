import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ublend-npm/aulaui-next';

import CircularProgress from '../../../CircularProgress';
import {
  Container,
  DropdownList,
  Item,
  TagItem,
  LabelItem,
  LoaderContainer,
} from './Dropdown.styled';

const enhanceDataSet = (dataSet) => {
  let noHeaderIndex = 0;
  return dataSet.map((item) => {
    if (item.isHeader) {
      return item;
    }
    const enhanced = {
      ...item,
      index: noHeaderIndex,
    };
    noHeaderIndex += 1;
    return enhanced;
  });
};

const Dropdown = ({
  dataSet,
  highlightedIndex,
  suggestionLabel,
  isItemSelected,
  selectedItem,
  getMenuProps,
  useAlternativeItemType,
  getItemProps,
  DropdownItemType,
  isLoading,
  DropdownStyle,
  DropdownItemStyle,
}) => {
  const getDropdownStyle = (isHeader) => ({
    ...DropdownItemStyle,
    backgroundColor: isHeader ? 'transparent' : 'inherit',
  });
  const labelItem = isItemSelected ? (
    <LabelItem key="suggestion-label" button={false}>
      {suggestionLabel}
    </LabelItem>
  ) : null;

  const ItemComponent = useAlternativeItemType ? TagItem : Item;
  return (
    <Container {...DropdownStyle}>
      {isLoading ? (
        <LoaderContainer>
          <CircularProgress size="24px" />
        </LoaderContainer>
      ) : (
        <DropdownList {...getMenuProps()}>
          {labelItem}
          {enhanceDataSet(dataSet).map((item) => (
            <ItemComponent
              key={item.key}
              selected={
                item.index === highlightedIndex || item.key === selectedItem
              }
              isHeader={item.isHeader}
              {...(!item.isHeader
                ? getItemProps({
                    item,
                    style: {
                      backgroundColor:
                        (item.index === highlightedIndex &&
                          !useAlternativeItemType) ||
                        item.key === selectedItem
                          ? theme.color.beige
                          : theme.color.white,
                      ...getDropdownStyle(item.isHeader),
                    },
                  })
                : {
                    style: getDropdownStyle(item.isHeader),
                  })}
            >
              <DropdownItemType
                {...item}
                selected={
                  item.index === highlightedIndex || item.key === selectedItem
                }
              />
            </ItemComponent>
          ))}
        </DropdownList>
      )}
    </Container>
  );
};

Dropdown.propTypes = {
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  highlightedIndex: PropTypes.number,
  suggestionLabel: PropTypes.string.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  selectedItem: PropTypes.string,
  getItemProps: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  DropdownItemType: PropTypes.elementType.isRequired,
  useAlternativeItemType: PropTypes.bool,
  DropdownStyle: PropTypes.shape({
    width: PropTypes.string,
  }),
  DropdownItemStyle: PropTypes.shape({
    height: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

Dropdown.defaultProps = {
  highlightedIndex: 0,
  isLoading: false,
  selectedItem: undefined,
  DropdownStyle: PropTypes.shape({
    width: {},
  }),
  DropdownItemStyle: {},
  useAlternativeItemType: false,
};

export default Dropdown;
