import { connect } from 'react-redux';
import {
  isFolderSectionsUserProgressComplete,
  getSectionsProgressInFolder,
} from '@core/materials/selectors';

import {
  selectors as materialSelectors,
  operations,
} from '../../../../../store/materials';
import * as classRoomSelectors from '../../../../../../core/selectors/classroom';
import FolderListItem from './FolderListItem';

const mapStateToProps = (state, ownProps) => {
  const folder = materialSelectors.getMaterial(state, ownProps.materialId);

  return {
    isFolder: true,
    primaryText: folder ? folder.title : '',
    expanded: materialSelectors.isExpanded(state, ownProps.materialId),
    hidden: folder.hidden,
    parent: folder.parent,
    materialIds: materialSelectors.sectionIdsByParent(
      state,
      ownProps.materialId,
      folder.educatorOnly
    ),
    isInstructor: classRoomSelectors.isInstructor(state),
    isStudent: classRoomSelectors.getIsStudent(state),
    allSectionsCompleted: isFolderSectionsUserProgressComplete(
      state,
      ownProps.materialId
    ),
    sectionsProgress: getSectionsProgressInFolder(state, ownProps.materialId),
  };
};

const mapsDispatchToProps = (dispatch, ownProps) => ({
  toggleFolder: () => dispatch(operations.toggleFolder(ownProps.materialId)),
  onReorder: (params) => dispatch(operations.reorderMaterial(params)),
});

export default connect(mapStateToProps, mapsDispatchToProps)(FolderListItem);
