import styled, { css } from 'react-emotion';
import { theme, Button as UnstyledButton } from '@ublend-npm/aulaui-next';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import sizes from '../../../../styles/sizes';
import {
  Camera as InboxItemCamera,
  Container as InboxItemContainer,
} from './InboxItem.styled';

const { inboxGroupsMenuWidth, inboxGroupsMenuWidthCollapsed } = sizes;

export const ButtonContainer = styled('div')`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.medium};
`;

export const Button = styled(UnstyledButton)`
  width: 100%;
  min-width: initial;
  padding: 6px;

  > span:first-child {
    justify-content: center !important;
  }
`;

export const Container = styled('div')`
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  background-color: white;
  transition: width 10ms;

  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  position: relative;

  ${InboxItemCamera} {
    transition: width 10ms;
  }

  ${InboxItemContainer} {
    transition: opacity 200ms;
  }
`;

export const ConversationList = styled('div')`
  top: 72px;
  width: 100%;
  height: calc(100% - ${sizes.inboxHeaderAndSearchWidth}px);

  & .scroll-view {
    margin-bottom: 0 !important; // this is to override a style="" property injected by ScrollBar that cuts an inboxitem
  }
`;

export const CircularProgressContainer = styled('div')`
  position: absolute;
  top: 32px;
  left: 90px;
  font-size: 24px;
`;

export const InboxButton = styled.div`
  position: fixed;
  bottom: 0;
  right: 24px;
  height: 20px;
  width: 171px;
  padding: 10px 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 4px 4px 0px 0px;
  align-items: center;
  cursor: pointer;
  background-color: ${theme.color.white};
  transition: background-color ${theme.transition.speed.xFast}
    ${theme.transition.easing.easeOut};
  justify-content: space-between;

  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};

  :hover {
    background-color: ${theme.color.beige};
  }
`;

export const IconButtonInner = styled.div`
  display: flex;
  align-items: center;
`;

export const IconButtonText = styled.div`
  line-height: 20px;
  color: ${theme.color.text};
`;

export const InboxButtonCount = styled.span`
  width: 20px;
  height: 20px;
  background: ${theme.color.error};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.color.white};
  font-size: ${theme.font.size.small};
  margin-left: ${theme.spacing.xSmall};
`;

export const InboxTitle = styled.h3`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
`;

export const InboxTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.medium};
`;

export const ArrowIcon = styled(ArrowDropUpIcon)`
  color: ${theme.color.grey16};
`;

const expanded = css`
  width: ${inboxGroupsMenuWidth}px;

  ${InboxItemCamera} {
    width: ${inboxGroupsMenuWidth}px;
    max-height: 200px;
  }

  ${Button} > span {
    display: flex;
    justify-content: space-between;
  }
`;

const shrinked = css`
  width: 0px;

  ${Button} {
    padding-left: 8px;
    padding-right: 8px;
  }

  ${Button} > span > span:first-child {
    display: none;
  }

  ${InboxItemCamera} {
    width: ${inboxGroupsMenuWidthCollapsed}px;
    max-height: 56px;
  }

  ${InboxItemContainer} {
    padding: 0;
  }
`;

const subContentHidden = css`
  ${InboxItemContainer} {
    opacity: 0;
  }

  ${Button} > span {
    opacity: 0;
  }
`;

const subContentShown = css`
  ${InboxItemContainer} {
    opacity: 1;
  }

  ${Button} > span {
    opacity: 1;
  }
`;

const inboxButtonHidden = css`
  display: none;
`;

const inboxButtonShown = css`
  display: flex;
`;

export const styles = {
  expanded,
  shrinked,
  subContentHidden,
  subContentShown,
  inboxButtonHidden,
  inboxButtonShown,
};
