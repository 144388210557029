import React from 'react';

import {
  QUIZZES_PROVIDER,
  TURNITIN_PROVIDER,
} from '@core/assignments/constants';
import { uppercaseFirst } from '../../Material/MaterialList/common';
import formatDate from '../../../../../../core/utils/formatDate';
import SubmissionsCountRow from './SubmissionsCountRow';

import {
  AssignmentDetailWrapper,
  Row,
  DetailColumnn,
  DetailTitle,
  DetailInfo,
  EditedTag,
} from './AssignmentDetails.styled';
import getAssignmentPropertiesDiff from './getAssignmentPropertiesDiff';
import { useSelector } from 'react-redux';
import { getLtiProviders } from '@core/selectors/lti';
import { Assignment } from '@core/types/state';
import { isAssignmentProviderDisabled } from '@core/assignments/utils';

const getExtensionName = ({
  title,
  parentAssignmentId,
  extensions,
}: Assignment) => {
  if (parentAssignmentId) {
    return title;
  }

  if (extensions?.length) {
    return 'Main cohort';
  }
  return;
};

type AssignmentDetailsProps = {
  assignment: Assignment;
  parentAssignment: Assignment;
  submissionCount: number;
  totalStudentsCount: number;
  accessingAsStaff?: boolean;
};

const AssignmentDetails = ({
  assignment,
  submissionCount,
  totalStudentsCount,
  accessingAsStaff = false,
  parentAssignment,
}: AssignmentDetailsProps) => {
  const ltiProviders = useSelector(getLtiProviders);

  const isProviderDisabled = isAssignmentProviderDisabled(
    assignment,
    ltiProviders
  );

  const getDateInfo = (property: keyof Assignment) => {
    const info = assignment[property];

    if (!info) {
      return null;
    }

    const modifiedProperties = getAssignmentPropertiesDiff(
      assignment,
      parentAssignment
    );

    const hasBeenModified = modifiedProperties[property];

    return (
      <DetailInfo>
        <span>{formatDate(info)}</span>
        {hasBeenModified ? <EditedTag>Edited</EditedTag> : null}
      </DetailInfo>
    );
  };

  const showProvider =
    !accessingAsStaff &&
    assignment.provider !== QUIZZES_PROVIDER &&
    !isProviderDisabled;

  return (
    <AssignmentDetailWrapper>
      <SubmissionsCountRow
        extensionName={getExtensionName(assignment)}
        submissionCount={submissionCount}
        totalStudentsCount={totalStudentsCount}
        assignment={assignment}
        showProvider={showProvider}
      />
      <Row>
        {assignment.startDate ? (
          <DetailColumnn>
            <DetailTitle>Open date</DetailTitle>
            {getDateInfo('startDate')}
          </DetailColumnn>
        ) : null}
        {assignment.endDate ? (
          <DetailColumnn>
            <DetailTitle>End date</DetailTitle>
            {getDateInfo('endDate')}
          </DetailColumnn>
        ) : null}
        {assignment.provider === TURNITIN_PROVIDER ? (
          <DetailColumnn>
            <DetailTitle>Grades release date</DetailTitle>
            {getDateInfo('gradesReleasedAt')}
          </DetailColumnn>
        ) : null}
        <DetailColumnn>
          <DetailTitle>Grade type</DetailTitle>
          <DetailInfo>
            <span>{uppercaseFirst(assignment.gradeType || '')}</span>
          </DetailInfo>
        </DetailColumnn>
      </Row>
    </AssignmentDetailWrapper>
  );
};

export default AssignmentDetails;
