import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import EducatorArea from './EducatorArea/EducatorArea.container';
import EducatorAreaV4 from './EducatorAreaV4/EducatorArea.container';

const EducatorAreaToggle = (props) => {
  const { webAppFtMuiV6EducatorPage202412 } = useFlags();

  return webAppFtMuiV6EducatorPage202412 ? (
    <EducatorArea {...props} />
  ) : (
    <EducatorAreaV4 {...props} />
  );
};

export default EducatorAreaToggle;
