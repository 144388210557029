import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  display: flex;
  align-items: center;
`;

export const OthersContainer = styled('button')`
  font: inherit;
  font-size: ${theme.font.size.copy};
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  margin-left: 8px;
  color: ${theme.color.purple};
  cursor: pointer;
`;
