import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@ublend-npm/aulaui-next';
import { STUDENTS_VIEW, GROUP_VIEW } from './constants';
import { Root, NavItemWrapper } from './Navigation.styled';

const navItems = [
  {
    key: STUDENTS_VIEW,
    label: 'Students',
  },
  /** TODO: enable the Group navigation chip
  * {
    key: GROUP_VIEW,
    label: 'Group',
  }, */
];

const Navigation = ({ onChange, selected }) => {
  return (
    <Root>
      {navItems.map(({ key, label }) => {
        const isSelected = selected === key;

        return (
          <NavItemWrapper key={key}>
            <Chip
              id={key}
              isSelected={isSelected}
              onClick={() => !isSelected && onChange(key)}
            >
              {label}
            </Chip>
          </NavItemWrapper>
        );
      })}
    </Root>
  );
};

Navigation.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.oneOf([STUDENTS_VIEW, GROUP_VIEW]).isRequired,
};

Navigation.defaultProps = {
  onChange: () => {},
};

export default React.memo(Navigation);
