import PropTypes from 'prop-types';
import React from 'react';

import * as TEXT from '../../../../../constants/texts';
import { ExtraLarge, TextField, textField } from './SectionTitle.styled';

class SectionTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      const { value } = nextProps;
      this.setState({ value });
    }
  }

  onChange(event) {
    const { value } = event.target;
    this.setState({ value });
    this.props.onChange(value);
  }

  export() {
    return this.state.value;
  }

  render() {
    const { value } = this.state;
    const useHint = value === null;
    if (this.props.editable) {
      return (
        <TextField
          data-testid="section-title"
          className="material-section-title-editable"
          inputProps={{ 'aria-label': 'Material section title' }}
          InputProps={{
            className: textField,
          }}
          autoFocus
          fullWidth
          placeholder={TEXT.MATERIAL_TITLE_HINT}
          multiline
          onChange={this.onChange}
          onKeyDown={this.props.onKeyDown}
          value={value}
        />
      );
    }

    return (
      <ExtraLarge
        className="material-section-title-disabled"
        data-testid="section-title"
      >
        {useHint ? TEXT.MATERIAL_TITLE_HINT : value}
      </ExtraLarge>
    );
  }
}

SectionTitle.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

SectionTitle.defaultProps = {
  editable: false,
  value: null,
  onChange: () => null,
  onKeyDown: () => null,
};

export default SectionTitle;
