import React, { useState } from 'react';
import { IconButton, TextField, Tooltip } from '@ublend-npm/aulaui-next';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import Cancel from '@mui/icons-material/Cancel';
import {
  StatusInputContainer,
  LimitCounter,
  TextFieldStyle,
} from './StatusInput.styled';
import EmojiPicker from '../../../emoji/EmojiPicker';
import AddReaction from '../../../common/Icons/AddReaction';
import EmojiButton from '../../../common/EmojiButton';
import EmojiUtils from '../../../../../../core/utils/emoji';
import { ENTER, SPACE } from '../../../../../utils/keyCodes';

const TextFieldWithRef = React.forwardRef((props, ref) => (
  <div ref={ref} className={TextFieldStyle}>
    <TextField {...props} />
  </div>
));

const StatusInput = ({
  statusText,
  emojiShortName,
  setStatusText,
  setEmojiShortName,
}) => {
  const [emojiPickerAnchor, setEmojiPickerAnchor] = useState(null);
  const inputRef = React.createRef();
  const limit = 100;

  const openEmojiPicker = () => setEmojiPickerAnchor(inputRef.current);
  const clear = () => {
    setStatusText('');
    setEmojiShortName('');
  };
  const hasStatus = () => statusText.length || emojiShortName.length;

  const onChangeStatus = ({ target }) => {
    setStatusText(target.value.slice(0, limit));
    if (!emojiShortName) setEmojiShortName('speech_balloon');
  };

  return (
    <StatusInputContainer>
      <TextFieldWithRef
        placeholder="Write a custom status"
        data-testid="status-text"
        value={statusText}
        onChange={onChangeStatus}
        startAdornment={
          <Tooltip title="Pick status emoji" position="top">
            <InputAdornment position="start">
              {emojiShortName.length ? (
                <EmojiButton
                  emoji={EmojiUtils.getFromShortName(emojiShortName)}
                  onClick={openEmojiPicker}
                />
              ) : (
                <IconButton
                  type="secondary"
                  size="small"
                  data-testid="pick-emoji-button"
                  label="pick status emoji"
                  icon={AddReaction}
                  onClick={openEmojiPicker}
                />
              )}
            </InputAdornment>
          </Tooltip>
        }
        endAdornment={
          hasStatus() ? (
            <Tooltip title="Clear status" position="top">
              <InputAdornment position="end">
                <IconButton
                  type="secondary"
                  size="small"
                  label="clear status"
                  icon={Cancel}
                  onClick={clear}
                  onKeyDown={(e) =>
                    (e.keyCode === ENTER || e.keyCode === SPACE) && clear()
                  }
                />
              </InputAdornment>
            </Tooltip>
          ) : null
        }
        ref={inputRef}
      />
      <LimitCounter>
        {statusText.length}/{limit}
      </LimitCounter>
      <EmojiPicker
        title="Pick status emoji"
        id="userstatus-emoji-picker"
        anchorEl={emojiPickerAnchor}
        show={!!emojiPickerAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setEmojiPickerAnchor(null)}
        onSelect={(e) => {
          setEmojiShortName(e.short_name);
        }}
      />
    </StatusInputContainer>
  );
};

StatusInput.propTypes = {
  statusText: PropTypes.string.isRequired,
  emojiShortName: PropTypes.string.isRequired,
  setStatusText: PropTypes.func.isRequired,
  setEmojiShortName: PropTypes.func.isRequired,
};

export default React.memo(StatusInput);
