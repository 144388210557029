import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const SelectAssignmentContainer = styled.div`
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
  margin-top: 20px;
`;

export default SelectAssignmentContainer;
