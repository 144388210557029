import { connect } from 'react-redux';
import * as creators from '../../../../../core/actions/users';
import { selectors as inboxSelectors } from '../../../../store/conversations';
import * as usersSelectors from '../../../../../core/selectors/users';

import ConversationModalParticipants from './ConversationModalParticipants';

const noop = () => null;

const notAvailableAndNeverFetchedYet = (userStore, fetchingStore) => (id) =>
  !userStore[id] && typeof fetchingStore[id] === 'undefined';

const notAvailableYet = (userStore, fetchingStore) => (id) =>
  !userStore[id] && fetchingStore[id] !== false;

const mapStateToProps = (state) => {
  const userStore = usersSelectors.users(state);
  const fetchingStore = usersSelectors.fetchingUsers(state);

  const conversationId = inboxSelectors.currentConversationId(state);
  const otherParticipantsIds =
    inboxSelectors.otherParticipantsIdsInConversation(state, conversationId);

  const usersToFetch = otherParticipantsIds.filter(
    notAvailableAndNeverFetchedYet(userStore, fetchingStore)
  );
  const isFetchingUsers = !!otherParticipantsIds.filter(
    notAvailableYet(userStore, fetchingStore)
  ).length;

  return {
    isFetchingUsers,
    isGroupConversation: inboxSelectors.isGroupConversation(
      state,
      conversationId
    ),
    otherParticipantsIds,
    usersToFetch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (users) => dispatch(creators.fetchUsers(users)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { usersToFetch, ...otherStateProps } = stateProps;

  return {
    ...otherStateProps,
    ...dispatchProps,
    ...ownProps,
    fetchUsers: usersToFetch.length
      ? () => dispatchProps.fetchUsers(usersToFetch)
      : noop,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConversationModalParticipants);
