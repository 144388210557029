import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Scrollbar } from '@ublend-npm/aulaui-next';

import Assignments from '../Assignments.container';
import Tool from '../Tools/Tool.component';

import {
  Container,
  LeftColumn,
  RightColumn,
  Title,
} from './AssignmentPage.styled';

const DESCRIPTIONS = {
  educator: {
    ssa: 'Understand how students are progressing, view all assignments and grades in one place and pass back grades to the student record system.',
    handin:
      'Aula’s assessment tool: here you can create and manage Handin assignments, grade submissions and provide feedback to students.',
  },
  student: {
    ssa: 'View all your assignments and grades in one place, understand how assignments contribute to your final grade and keep track of how you’re progressing in your learning journey.',
    handin:
      'Aula’s assessment tool: here you can view upcoming, current and past Handin assignments, submit your work and view grades and feedback.',
  },
};

const AssignmentPage = ({ baseDomain, isInstructor, spaceId }) => {
  return (
    <Container>
      <LeftColumn>
        <Assignments />
      </LeftColumn>
      <RightColumn data-testid="assignments-tools-links">
        <Scrollbar autoHide style={{ height: '100%' }}>
          <Title>Go to</Title>
          <Tool
            id="ssa"
            title="Student Success App"
            target="_blank"
            description={
              isInstructor
                ? DESCRIPTIONS.educator.ssa
                : DESCRIPTIONS.student.ssa
            }
            url={`https://success.${baseDomain}/${spaceId}`}
            opensInNew
          />
          <Tool
            id="handin"
            title="Handin"
            target="_blank"
            description={
              isInstructor
                ? DESCRIPTIONS.educator.handin
                : DESCRIPTIONS.student.handin
            }
            url={`https://handin.${baseDomain}/${spaceId}`}
            opensInNew
          />
        </Scrollbar>
      </RightColumn>
    </Container>
  );
};

AssignmentPage.propTypes = {
  baseDomain: PropTypes.string.isRequired,
  isInstructor: PropTypes.bool.isRequired,
  spaceId: PropTypes.string,
};

AssignmentPage.defaultProps = {
  spaceId: null,
};

export default AssignmentPage;
