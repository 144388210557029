import { LTI_CONSUMER } from '../../../core/constants/endpoints';
import request from '../../../core/api/request';
import submitSignedRequestForm from './submitSignedRequestForm';

const launchTurnitinSubmission = async ({ submissionId, spaceId }) => {
  const { data: signedParams } = await request({
    data: {
      submissionId,
      spaceId,
    },
    method: 'post',
    url: LTI_CONSUMER(),
  });

  submitSignedRequestForm(signedParams);
};

export default launchTurnitinSubmission;
