import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button } from '@ublend-npm/aulaui-next';
import {
  Wrapper,
  GroupSet,
  GroupItem,
  ListActions,
  ManageSettings,
  GroupLabel,
} from './FilterSubmissions.styled';
import Radio from '../../../common/Radio';
import { MANAGE_GROUPS, FOOTER_ACTIONS } from '../constants';

const noop = () => {};

const renderManageGroups = (selected) => (
  <ManageSettings isSelected={selected}>
    <SettingsIcon /> <span>Manage groups</span>
  </ManageSettings>
);

const renderActions = (selected) => (
  <ListActions isSelected={selected}>
    <Button type="text" size="small">
      Clear
    </Button>
  </ListActions>
);

const SubmissionGroups = memo(({ isHeader, label, students, selected }) => {
  if (label === MANAGE_GROUPS) {
    return renderManageGroups(selected);
  }

  if (label === FOOTER_ACTIONS) {
    return renderActions(selected);
  }

  return (
    <Wrapper>
      {isHeader ? (
        <GroupSet>{label}</GroupSet>
      ) : (
        <span>
          <GroupItem isSelected={selected}>
            <Radio
              value={label}
              label={<GroupLabel>{label}</GroupLabel>}
              ariaLabel={label}
              handleChange={noop}
              isSelected={selected}
              name={`${label}-radio`}
            />
            <span>{students.length}</span>
          </GroupItem>
        </span>
      )}
    </Wrapper>
  );
});

SubmissionGroups.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  isHeader: PropTypes.bool.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({})),
};

SubmissionGroups.defaultProps = {
  students: [],
};

export default SubmissionGroups;
