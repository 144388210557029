import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const AlertContainer = styled('div')`
  max-width: 98%;
  padding-bottom: ${theme.spacing.medium};
`;

export const AlertContent = styled('div')`
  padding-inline-start: ${theme.spacing.medium};
  padding-left: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  line-height: 1.5;
  padding-top: 5px;
`;
