import { useMutation, useQueryClient } from 'react-query';
import { patchSection } from '../../core/materials/api';
import factoryKeys from './queries';

const useRestoreDeletedMaterialPage = (spaceId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (selectedPageId: string) =>
      patchSection(spaceId, selectedPageId, undefined, true),
    {
      onSuccess: async (_, selectedPageId) => {
        return queryClient.setQueryData(
          factoryKeys.deletedSectionsBySpaceId(spaceId),
          (old: Array<{ id: string }>) =>
            old?.filter((material) => material.id !== selectedPageId)
        );
      },
    }
  );

  return {
    restorePage: mutateAsync,
    isRestoring: isLoading,
  };
};

export default useRestoreDeletedMaterialPage;
