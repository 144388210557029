import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useState } from 'react';
import { styleUtils } from '@ublend-npm/aulaui-next';
import { isUpdating, isPersistable } from '@ublend-npm/aula-editor';
import { ReactElementType } from '@app/components/propTypes/common';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../Dialog';
import style, { overflowHiddenClass } from './style';
import isEmptyContent from '../../ClassRoom/Feed/isEmptyContent';
import feedPostTemplates from '../../ClassRoom/Feed/CreatePost/feedPostTemplates';

const ITEM_CLASS_MAP = {
  UBInboxMessage: 'message',
  UBComment: 'comment',
  UBClassRoomPost: 'post',
};

const EditContent = forwardRef(
  (
    {
      isSavingPost,
      content,
      itemClass,
      open,
      onClose,
      updateContent,
      editorConfig,
      EditorComponent,
    },
    ref
  ) => {
    const editorRef = ref;
    const parsedContent = JSON.parse(content);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [saving, setSaving] = useState(false);
    const [editorState, setEditorState] = useState(parsedContent);
    const [enablePostButtons, setEnablePostButtons] = useState(true);

    const loading = isSavingPost || isSubmitting || saving;

    const handleRequestClose = () => {
      setEditorState(parsedContent);
      onClose();
    };

    const handleEditorChange = useCallback(
      (state) => {
        setEditorState(state);
        setIsSubmitting(isUpdating(state));
        setEnablePostButtons(
          !isEmptyContent(state) && isPersistable(state) && !isUpdating(state)
        );
      },
      [setEditorState, setIsSubmitting, setEnablePostButtons]
    );

    const handleEdit = async () => {
      setSaving(true);
      try {
        await updateContent(editorState);
        setMessage('The post has been saved');
        setTimeout(() => {
          setSaving(false);
          setMessage('');
          onClose();
        }, 1000);
      } catch (error) {
        setMessage('The post has not been saved');
        console.log(error);
      }
    };

    const templates = itemClass === 'UBClassRoomPost' ? feedPostTemplates : [];
    const contentType = itemClass === 'UBClassRoomPost' ? 'post' : null;
    const analyticsContentType =
      ITEM_CLASS_MAP[itemClass] === 'message'
        ? 'inbox'
        : ITEM_CLASS_MAP[itemClass];

    return (
      <Dialog
        id="edit-content-modal"
        open={open}
        onClose={handleRequestClose}
        maxWidth="md"
        classes={{
          root: overflowHiddenClass,
          paperScrollPaper: overflowHiddenClass,
        }}
        disableEnforceFocus
      >
        <DialogTitle
          id="edit-content-modal-dialogtitle"
          onClose={handleRequestClose}
        >
          {`Edit ${ITEM_CLASS_MAP[itemClass]}`}
        </DialogTitle>
        <DialogContent>
          <div style={style.editorWrapper}>
            {open ? (
              <EditorComponent
                ref={editorRef}
                config={editorConfig}
                editorState={editorState}
                onChange={handleEditorChange}
                isSubmitting={loading}
                setSubmittingState={setIsSubmitting}
                isAssignmentsEnabled
                templates={templates}
                contentType={contentType}
                type={analyticsContentType}
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          primary={{
            label: 'Save',
            onClick: handleEdit,
            loading: saving,
            disabled: saving || !enablePostButtons,
          }}
          secondary={{
            label: 'Cancel',
            onClick: handleRequestClose,
          }}
        />
        <div
          css={[styleUtils.visuallyHidden()]}
          aria-live="polite"
          role="status"
        >
          {message}
        </div>
      </Dialog>
    );
  }
);

EditContent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateContent: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  itemClass: PropTypes.string.isRequired,
  isSavingPost: PropTypes.bool,
  editorConfig: PropTypes.shape({}),
  EditorComponent: ReactElementType.isRequired,
};

EditContent.defaultProps = {
  isSavingPost: false,
  editorConfig: undefined,
};

export default EditContent;
