import styled from 'react-emotion';
import {
  IconButton as BaseIconButton,
  Button as UnstyledButton,
  theme,
} from '@ublend-npm/aulaui-next';
import UnstyledVisibilityOffIcon from '@mui/icons-material/VisibilityOffSharp';

export const Button = styled(UnstyledButton, {
  shouldForwardProp: (prop) => prop !== 'show',
})`
  display: ${(props) => (props.show ? 'inline-flex' : 'none')};
  margin-left: 16px;
  line-height: 12px;
`;

export const IconButton = styled(BaseIconButton)`
  margin-left: 8px;
`;

export const Container = styled('div')`
  display: flex;
  justify-content: ${(props) => (props.hidden ? 'space-between' : 'flex-end')};
  flex-direction: row;
  margin-bottom: ${theme.spacing.small};
`;

export const ActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const PageDetailsContainer = styled('div')`
  color: ${theme.color.blackAlpha(0.6)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  min-width: 30px;
`;

export const VisibilityOffIcon = styled(UnstyledVisibilityOffIcon)`
  padding-right: 6px;
  width: 24px;
  height: 24px;
`;
