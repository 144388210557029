import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import PostView from './Feed/Post/Post.container';
import PostViewV4 from './FeedV4/Post/Post.container';

const PostViewToggle = (props) => {
  const { webAppFtMuiV6Feed202412 } = useFlags();

  return webAppFtMuiV6Feed202412 ? (
    <PostView {...props} />
  ) : (
    <PostViewV4 {...props} />
  );
};

export default PostViewToggle;
