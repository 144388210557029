import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ublend-npm/aulaui-next';

const defaultStyle = {
  fill: theme.color.blackAlpha(0.6),
  height: '17.5px',
};

const PinIcon = ({ style }) => {
  return (
    <svg
      viewBox="0 0 10 19"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...defaultStyle, ...style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833333 0.666687H9.16667V2.33335L7.5 3.16669V7.33335L10 9.83335V12.3334H5.83333V15.6667L5 18.1667L4.16667 15.6667V12.3334H0V9.83335L2.5 7.33335V3.16669L0.833333 2.33335V0.666687Z"
        fill={style.fill}
      />
    </svg>
  );
};

PinIcon.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

PinIcon.defaultProps = {
  style: {},
};

export default PinIcon;
