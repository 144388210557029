import * as api from '../api/directMessages';
import { INBOX_RECONNECT } from '../actions';
import * as inboxSelectors from '../selectors';

const getId = (c) => c.id;
const withId = (id) => (c) => c.id === id;
const notEqual = (v) => (item) => item !== v;

const conversationHasNewMessages = (conversationSelector) => (c) => {
  const previousLatestMessageId = c.latestMessage && c.latestMessage.id;
  const conversation = conversationSelector(c.id);

  if (!conversation) return false;

  const newLatestMessageId =
    conversation.latestMessage && conversation.latestMessage.id;
  return previousLatestMessageId !== newLatestMessageId;
};

const withNewMessages = (conversationSelector, newConversations) =>
  newConversations.filter(conversationHasNewMessages(conversationSelector));

const reconnectStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_RECONNECT,
    status: 'started',
    payload,
  });

const reconnectError = (dispatch, payload) =>
  dispatch({
    type: INBOX_RECONNECT,
    status: 'error',
    payload,
  });

const reconnectSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_RECONNECT,
    status: 'success',
    payload,
  });

export default () => async (dispatch, getState) => {
  const state = getState();
  const currentConversationId = inboxSelectors.currentConversationId(state);

  const payload = { currentConversationId };
  reconnectStarted(dispatch, payload);

  try {
    const includeClosedConversations = true;
    const { conversations, totalCount } =
      await api.conversations.fetchConversations(includeClosedConversations);
    const conversationSelector = (id) => inboxSelectors.conversation(state, id);
    const conversationsWithNewMessages = withNewMessages(
      conversationSelector,
      conversations,
      totalCount
    );
    let currentConversationMessages;
    let tooManyNewMessagesInCurrent;
    if (
      currentConversationId &&
      conversationsWithNewMessages.find(withId(currentConversationId))
    ) {
      const currentConversation = inboxSelectors.currentConversation(state);
      const after = (currentConversation.latestMessage || currentConversation)
        .createdAt;
      const messagesResponse = await api.conversations.fetchMessages(
        currentConversationId,
        { after }
      );
      currentConversationMessages = messagesResponse.messages;
      tooManyNewMessagesInCurrent = !messagesResponse.done;
    }

    const resetConversationIds = [
      ...conversationsWithNewMessages
        .map(getId)
        .filter(notEqual(currentConversationId)),
      ...(tooManyNewMessagesInCurrent ? [currentConversationId] : []),
    ];

    reconnectSuccess(dispatch, {
      ...payload,
      conversations,
      resetConversationIds,
      currentConversationMessages,
    });
  } catch (error) {
    reconnectError(dispatch, { ...payload, error });
  }
};
