import { connect } from 'react-redux';
import ConversationParticipant from './ConversationParticipant';

const mapStateToProps = (state, ownProps) => {
  const { participantId: userId } = ownProps;
  const cachedUser = state.data.users[userId];
  return {
    name: cachedUser ? `${cachedUser.firstName} ${cachedUser.lastName}` : '',
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationParticipant);
