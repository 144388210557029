import styled from 'react-emotion';
import Prefixer from 'inline-style-prefixer';
import {
  theme,
  IconButton as BaseIconButton,
  ChipSelector,
} from '@ublend-npm/aulaui-next';
import { SPACE_SELECTOR_HEIGHT } from '../../../../../constants/sizes';

const postCellStyle = {};
const postCellWithSeparatorStyle = { marginBottom: 0 };

const tableStyle = new Prefixer().prefix({
  container: {
    backgroundColor: theme.color.designSystem.grey10,
    alignItems: 'center',
    padding: '0 54px 0 54px',
  },
  table: {
    maxWidth: 624,
    width: '100%',
    minWidth: 450,
    padding: '0 32px',
  },
  filterRow: ({ fetching }) => ({
    display: `${fetching ? 'none' : 'flex'}`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing.xxLarge,
    marginBottom: theme.spacing.small,
  }),
  recentImportantPostsText: ({
    inFilterContainer = false,
    postCount,
    tagsCount,
    recentImportantPostsCount,
    scheduledPostCount,
  }) => {
    if (!inFilterContainer) {
      return {
        marginBottom: theme.spacing.xSmall,
      };
    }

    const style = {};
    if (postCount) {
      if (tagsCount) {
        if (recentImportantPostsCount && scheduledPostCount) {
          style.top = theme.spacing.xSmall;
        } else if (recentImportantPostsCount) {
          style.top = theme.spacing.xSmall;
          style.height = theme.spacing.xLarge;
        }
      } else if (recentImportantPostsCount) {
        style.top = theme.spacing.xxSmall;
      }
    }

    return style;
  },
  scheduledPosts: ({ scheduledPostCount, recentImportantPostsCount }) =>
    scheduledPostCount && recentImportantPostsCount
      ? {
          marginBottom: theme.spacing.xSmall,
        }
      : {},
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  muteInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    color: theme.color.grey11,
    marginRight: 8,
  },
  iconContainer: {
    display: 'flex',
    marginRight: 8,
    fontSize: 20,
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  importantPosts: {
    paddingLeft: '20px',
    border: '1px solid green',
    ':hover': {
      background: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '4px',
    },
  },
  postCell: ({ hasSeparator }) =>
    hasSeparator ? postCellWithSeparatorStyle : postCellStyle,
  separator: {
    height: '44px',
  },
});

export const ImportantPosts = styled.div`
  position: relative;
  top: 0;
  display: flex;
  align-items: flex-end;
  height: 40px;
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  padding: 0 ${theme.spacing.xxSmall};
  letter-spacing: 0.15px;

  svg {
    position: relative;
    top: 1px;
    margin-right: ${theme.spacing.small};
  }

  span {
    color: ${theme.color.blackAlpha(0.87)};
  }
`;

export const IconButton = styled(BaseIconButton)`
  min-width: 40px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${SPACE_SELECTOR_HEIGHT}px);
`;

export const FeedChipSelector = styled(ChipSelector)`
  padding: ${theme.spacing.medium} 54px;
`;

export default tableStyle;
