import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import PostsTable from './Feed/PostsTable/PostsTable.container';
import PostsTableV4 from './FeedV4/PostsTable/PostsTable.container';

const PostsTableToggle = (props) => {
  const { webAppFtMuiV6Feed202412 } = useFlags();

  return webAppFtMuiV6Feed202412 ? (
    <PostsTable {...props} />
  ) : (
    <PostsTableV4 {...props} />
  );
};

export default PostsTableToggle;
