/**
 * String values of `event.key`
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 */

export const TAB = 'Tab';
export const ESCAPE = 'Escape';
export const BACKSPACE = 'Backspace';
export const DELETE = 'Delete';

export const SPACE = ' ';
export const ENTER = 'Enter';
export const UP_ARROW = 'ArrowUp';
export const DOWN_ARROW = 'ArrowDown';
export const LEFT_ARROW = 'ArrowLeft';
export const RIGHT_ARROW = 'ArrowRight';
