import { getStore } from '../../../../../store';

const getInstitution = () =>
  getStore().getState().institution.general.institution;

// https://handin.edge.aula.education/WRZlADxdAK/BKrKXe7OP5
const launchHandin = ({
  assignmentId,
  spaceId,
  submissionId,
  studentId,
  isAnonymised,
}) => {
  const baseHandinURL =
    process.env.TEST_ENV === 'cypress'
      ? `https://handin.local.aula.education/${spaceId}/${assignmentId}`
      : `https://handin.${getInstitution()}.aula.education/${spaceId}/${assignmentId}`;

  let url;
  if (isAnonymised && submissionId) {
    url = `${baseHandinURL}/submissions/${submissionId}`;
  } else if (studentId) {
    url = `${baseHandinURL}/submissions/student/${studentId}`;
  } else {
    url = baseHandinURL;
  }

  window.open(url, '_blank');
};

export default launchHandin;
