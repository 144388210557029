import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import { config as breakpoints } from '../../../../hooks/useBreakpoints';

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SectionViewContainer = styled('div')`
  min-height: unset;
  margin-left: 4px;

  @media only screen and (max-width: ${breakpoints.md - 1}px) {
    padding-right: ${theme.spacing.xxGiant};
  }
`;

export const ContentContainer = styled('div')`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const ScrollContainer = styled('div')`
  position: relative;
  height: 100%;
  flex: 1;
`;
