import styled from 'react-emotion';
import { theme, Alert } from '@ublend-npm/aulaui-next';

export const Content = styled('div')`
  overflow: auto;
`;

export const Footer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
  border-radius: 0 0 ${theme.borderRadius.large} ${theme.borderRadius.large};
  border-top: 1px solid ${theme.color.grayLight};
  padding: 0 12px;
`;

export const StyledAlert = styled(Alert)`
  margin: ${theme.spacing.large} 30px;
`;
