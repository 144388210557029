/* eslint-disable no-undef */
import { connect, MapDispatchToProps, MergeProps } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { fetchSpacePost } from '@core/actions/space/posts';
import {
  getNextScheduledPostId,
  getCurrentSpaceName,
  getCurrentSpaceId,
} from '@core/selectors/space';
import { sendViewPostAnalytic } from '@core/utils/analytics';
import { getPostById } from '@core/selectors/posts.selectors';
import goToFeed from '@app/actions/navigation/goToFeed';
import { FeedType, Post, SendViewPostAnalytic } from '@app/types/core';
import { selectedFeedType } from '@core/selectors/classroom';
import { MapStateToProps } from '@core/types/state';
import ClassRoomPost, { ClassRoomPostProps } from './Post';

interface PostOwnProps {
  routeParams: { id: string };
}

interface PropsFromState {
  spaceId: string;
  spaceName: string;
  postId: string;
  post?: Post;
  feedType: FeedType | undefined;
  nextScheduledPostId: string | null;
}

const mapStateToProps: MapStateToProps<PropsFromState, PostOwnProps> = (
  state,
  ownProps
) => {
  const postId = ownProps.routeParams.id;
  const feedType = selectedFeedType(state);
  const nextScheduledPostId = getNextScheduledPostId(state, feedType);

  return {
    spaceId: getCurrentSpaceId(state),
    spaceName: getCurrentSpaceName(state) || '',
    postId,
    feedType,
    post: getPostById(state, postId),
    nextScheduledPostId,
  };
};

interface PropsFromDispatch {
  fetchPost: (_: { spaceId: string; postId: string }) => Promise<void>;
  sendViewPostAnalytic: SendViewPostAnalytic;
}

const mapDispatchToProps: MapDispatchToProps<
  PropsFromDispatch,
  PostOwnProps
> = (dispatch: ThunkDispatch<unknown, unknown, Action>) => ({
  fetchPost: async ({ spaceId, postId }) => {
    const post = await dispatch(fetchSpacePost(postId));
    if (!post) {
      dispatch(goToFeed(spaceId));
    }
  },
  sendViewPostAnalytic,
});

const mergeProps: MergeProps<
  PropsFromState,
  PropsFromDispatch,
  PostOwnProps,
  ClassRoomPostProps
> = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

const ClassRoomPostContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ClassRoomPost);

export default ClassRoomPostContainer;
