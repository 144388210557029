import { connect } from 'react-redux';

import { isLocalId } from '@core/utils/localId';
import { selectors, operations } from '../../../../../store/materials';
import RenameFolderDialog from './RenameFolderDialog';

const mapStateToProps = (state) => {
  const folderId = selectors.renameFolderDialogOpenFor(state);
  const folder = folderId && selectors.getMaterial(state, folderId);
  return {
    folderId,
    open: !!folderId,
    initialName: folder ? folder.title : '',
    renaming: selectors.isSavingFolder(state),
    isLocal: isLocalId(folderId),
  };
};

const mapsDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(operations.closeRenameFolder()),
  onRename: (folderId) => (name) =>
    dispatch(operations.editFolder(folderId, { title: name })),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  onRename: dispatchProps.onRename(stateProps.folderId),
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps,
  mergeProps
)(RenameFolderDialog);
