import useBreakpoints, { Breakpoints } from '../../../hooks/useBreakpoints';

/**
 * This is a render-prop component that provides class-based components with
 * access to the capabilities of the useBreakpoints hook.
 *
 * @param {string} children - Render prop for this component
 *
 * @example
 *
 * <Responsive>
 *   {({ sm, md, lg, xl }) => {
 *     if (xl) {
 *       return 'I am extra-large';
 *     }
 *
 *     if (lg) {
 *       return 'I am large';
 *     }
 *
 *     if (md) {
 *       return 'I am medium';
 *     }
 *
 *     if (sm) {
 *       return 'I am small';
 *     }
 *
 *     return 'I am extra-small';
 *   }}
 * </Responsive>
 */

type ResponsiveProps = {
  children(props: Breakpoints): JSX.Element;
};

const Responsive = ({ children }: ResponsiveProps) => {
  const breakpoints = useBreakpoints();

  return children(breakpoints);
};

export default Responsive;
