import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Reply from '@mui/icons-material/Reply';
import Edit from '@mui/icons-material/Edit';

import { Tooltip } from '@ublend-npm/aulaui-next';

import {
  MessageMenuContainer,
  IconButton,
  AddReactionIcon,
} from './MessageMenu.styled';

const MessageMenu = ({
  messageId,
  editMessage,
  replyToMessage,
  openEmojiPicker,
}) => {
  const handleOpenPicker = (e) => {
    openEmojiPicker({
      messageId,
      anchor: e.currentTarget,
    });
  };

  const [isFocusing, setFocusing] = useState(false);

  return (
    <MessageMenuContainer
      isFocusing={isFocusing}
      onFocus={() => setFocusing(true)}
      onBlur={() => setFocusing(false)}
    >
      <Tooltip title="React to message" position="top">
        <div role="presentation">
          <IconButton
            onClick={handleOpenPicker}
            label="reply"
            type="secondary"
            size="small"
            icon={AddReactionIcon}
            buttonAttributes={{
              tabIndex: -1,
            }}
          />
        </div>
      </Tooltip>
      <Tooltip title="Reply to message" position="top">
        <div role="presentation">
          <IconButton
            onClick={replyToMessage}
            label="reply"
            icon={Reply}
            type="secondary"
            size="small"
            buttonAttributes={{
              tabIndex: -1,
            }}
          />
        </div>
      </Tooltip>
      {editMessage && (
        <Tooltip title="Edit message" position="top">
          <div role="presentation">
            <IconButton
              type="secondary"
              size="small"
              label="edit"
              onClick={editMessage}
              icon={Edit}
              buttonAttributes={{
                tabIndex: -1,
              }}
            />
          </div>
        </Tooltip>
      )}
    </MessageMenuContainer>
  );
};

MessageMenu.propTypes = {
  messageId: PropTypes.string.isRequired,
  editMessage: PropTypes.func,
  replyToMessage: PropTypes.func.isRequired,
  openEmojiPicker: PropTypes.func.isRequired,
};

MessageMenu.defaultProps = {
  editMessage: null,
};

export default React.memo(MessageMenu);
