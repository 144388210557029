import { cx } from 'emotion';
import React from 'react';
import PropTypes from 'prop-types';

import { Menu, ListItem, Tooltip } from '@ublend-npm/aulaui-next';

import MoreVert from '@mui/icons-material/MoreVert';

import { menu, menuItem, dangerMenuItem } from '../../../common/Menu.styled';
import { HoverIconButton } from './MaterialListItem.styled';

const MORE_OPTIONS_TOOLTIP = 'Section options';

const Actions = {
  Rename: 'rename',
  Schedule: 'schedule',
  ChangeSchedule: 'changeSchedule',
  KeepHidden: 'keepHidden',
  PublishNow: 'publishNow',
  Delete: 'delete',
};

const MenuItems = [
  {
    key: Actions.Rename,
    label: 'Rename',
    isVisible: () => true,
    danger: false,
  },
  {
    key: Actions.Schedule,
    label: 'Schedule',
    isVisible: ({ hidden, scheduledFor, educatorOnly }) =>
      !educatorOnly && !!hidden && !scheduledFor,
    danger: false,
  },
  {
    key: Actions.ChangeSchedule,
    label: 'Change scheduled time',
    isVisible: ({ hidden, scheduledFor }) => !!hidden && !!scheduledFor,
    danger: false,
  },
  {
    key: Actions.KeepHidden,
    label: 'Undo scheduling and keep hidden',
    isVisible: ({ hidden, scheduledFor }) => !!hidden && !!scheduledFor,
    danger: false,
  },
  {
    key: Actions.PublishNow,
    label: 'Publish now',
    isVisible: ({ hidden, scheduledFor }) => !!hidden && !!scheduledFor,
    danger: false,
  },
  {
    key: Actions.Delete,
    label: 'Delete',
    isVisible: () => true,
    danger: true,
  },
];

const FolderListItemMenu = (props) => {
  const {
    ariaLabelId,
    openRenameDialog,
    openDeleteDialog,
    openScheduleDialog,
    keepHidden,
    publishNow,
  } = props;

  const handleSelect = ({ item }) => {
    switch (item) {
      case Actions.Rename:
        openRenameDialog();
        break;
      case Actions.Delete:
        openDeleteDialog();
        break;
      case Actions.Schedule:
      case Actions.ChangeSchedule:
        openScheduleDialog();
        break;
      case Actions.KeepHidden:
        keepHidden();
        break;
      case Actions.PublishNow:
        publishNow();
        break;
      default:
        break;
    }
  };

  return (
    <Menu
      data-testid="folder-menu"
      classes={{ menu }}
      ariaLabelledby={ariaLabelId}
      renderTarget={({ handleTargetClick }) => (
        <Tooltip title={MORE_OPTIONS_TOOLTIP} position="top">
          <div>
            <HoverIconButton
              icon={MoreVert}
              size="xSmall"
              type="secondary"
              label={MORE_OPTIONS_TOOLTIP}
              onClick={handleTargetClick}
              position="top"
            />
          </div>
        </Tooltip>
      )}
      onSelect={handleSelect}
      renderChildren={({ getItemProps, highlightedIndex }) =>
        MenuItems.reduce(
          (acc, item, index) =>
            item.isVisible(props)
              ? [
                  ...acc,
                  <ListItem
                    attributes={{ 'data-testid': 'folder-menu-item' }}
                    key={item.key}
                    className={
                      item.danger ? cx(menuItem, dangerMenuItem) : menuItem
                    }
                    primaryText={item.label}
                    highlighted={highlightedIndex === index}
                    {...getItemProps({
                      item: item.key,
                      onClick: (e) => e.stopPropagation(),
                    })}
                  />,
                ]
              : acc,
          []
        )
      }
    />
  );
};

FolderListItemMenu.propTypes = {
  hidden: PropTypes.bool,
  educatorOnly: PropTypes.bool,
  scheduledFor: PropTypes.string,
  ariaLabelId: PropTypes.string,
  openRenameDialog: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  openScheduleDialog: PropTypes.func.isRequired,
  keepHidden: PropTypes.func.isRequired,
  publishNow: PropTypes.func.isRequired,
};

FolderListItemMenu.defaultProps = {
  ariaLabelId: null,
  hidden: false,
  educatorOnly: false,
  scheduledFor: null,
};

export default FolderListItemMenu;
