import { connect } from 'react-redux';
import {
  operations as assignmentOperations,
  selectors,
} from '../../../../../store/assignments';
import ConversionModal from './ConversionModal';
import { accessToken as getAccessToken } from '../../../../../../core/selectors/user';
import openSubmissionsAction from '../../../../../actions/navigation/openSubmissions.action';
import { extractStudentIdsFromErrorMessage } from '../utils/extractStudentsFromError';

const mapStateToProps = (state, { assignmentId }) => {
  const { user } = state.user;
  const { selectedSpace: spaceId } = state.assignments || {};
  const { groups } = state.data.groups;
  const assignment = selectors.getAssignment(state, assignmentId);
  const assignments = selectors.getAssignments(state);
  const assignmentCreationError = selectors.getAssignmentCreationError(state);
  const getSubmissions = (id) => selectors.getAssignmentSubmissions(state, id);

  const studentIdsWithExistingSubmissions = assignmentCreationError
    ? extractStudentIdsFromErrorMessage(assignmentCreationError.detail)
    : [];

  return {
    dialogTitle: 'Turn into an extension of another assignment',
    assignment,
    unassignedStudents: selectors
      .getAssignmentSubmissions(state, assignmentId)
      .map(({ user: { id, firstName, lastName, avatar, email } }) => ({
        id,
        avatar,
        email,
        fullName: `${firstName} ${lastName}`,
      })),
    spaceGroups: Object.keys(groups).map((groupId) => ({
      key: groupId,
      value: groups[groupId].name,
      students: groups[groupId].students,
    })),
    spaceId,
    accessToken: getAccessToken(state, spaceId),
    user,
    assignments,
    assignmentCreationError,
    studentIdsWithExistingSubmissions,
    getSubmissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  convertToExtension: (spaceId, extension, accessToken, user) =>
    dispatch(
      assignmentOperations.convertAssignmentToExtension({
        spaceId,
        assignment: {
          ...extension,
          objectId: extension.id,
          status: 'live',
        },
        accessToken,
        currentUser: user,
      })
    ),
  redirectToSubmissions: (spaceId, assignmentId) =>
    dispatch(openSubmissionsAction(spaceId, assignmentId)),
  resetErrorState: () => dispatch(assignmentOperations.resetErrorState()),
  fetchSubmissions: (params) =>
    dispatch(assignmentOperations.fetchSubmissions(params)),
  fetchAllSubmissionsIncludingExtensions: (params) =>
    dispatch(
      assignmentOperations.fetchAllSubmissionsIncludingExtensions(params)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversionModal);
