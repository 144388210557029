import { connect } from 'react-redux';

import MaterialListItem from './MaterialListItem';
import { selectors } from '../../../../../store/materials';

const mapStateToProps = (state, ownProps) => ({
  isFolder: selectors.isFolder(state, ownProps.materialId),
});

export default connect(mapStateToProps)(MaterialListItem);
