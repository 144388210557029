import axios from 'axios';
import { useQuery } from 'react-query';
import request from '../request';

/**
 * Thin wrapper over useQuery which adds our authentication headers.
 *
 * It also converts responses with status >= 400 to errors for react-query.
 *
 * e.g.
 * ```
 * const { data: presentations, isLoading } = useApi({ url: 'valid_url' });
 *
 * // you can already use presentations here
 * // you can check isLoading to see if the data is currently loading (e.g. and show a spinner)
 * ```
 *
 * e.g. using enabled to delay the request
 * ```
 * const { data: presentations, isLoading } = useApi({ url: 'valid_url' }, { enabled: false });
 *
 * // the request does not start because enabled is always set to false
 * // presentations is undefined, isLoading is false.
 * ```
 * ```
 * const [enabled, setEnabled] = useState(false);
 * const { data: presentations, isLoading } = useApi({ url: 'valid_url' }, { enabled });
 *
 * // the request only starts when enabled is true.
 * // we can use setEnabled to start the request (e.g. on a click event handler).
 * ```
 *
 * @param config             - axios configuration object
 * @param useQueryConfig     - useQuery configuration object
 * @returns {UseQueryResult} - response coming from useQuery
 */
const useApi = (config, useQueryConfig) => {
  const queryFn = () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    // returning a promise here instead of making queryFn async so that the request can be cancellable
    // an async function would return a brand new Promise that would not have the cancel function on it
    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await request({
          method: 'GET',
          ...config,
          cancelToken: source.token,
        });

        if (response.status >= 400) {
          reject(new Error(response.data));
        }

        resolve(response.data);
      } catch {
        reject(new Error('Request cancelled'));
      }
    });

    // cancel the request if React Query calls the `promise.cancel` method
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };

  return useQuery({
    queryKey: config.url,
    queryFn,
    ...useQueryConfig,
  });
};

export default useApi;
