import { connect } from 'react-redux';

import {
  currentUserId as getCurrentUserId,
  accessToken as getAccessToken,
} from '../../../../../core/selectors/user';
import { getMaterialPlacementLtiProviders } from '../../../../../core/selectors/lti';
import AulaEditorWithAnalyticsWithoutPlugins from '../../common/AulaEditor/AulaEditorWithAnalyticsWithoutPlugins';
import {
  isInstructor,
  currentClassRoomId,
} from '../../../../../core/selectors/classroom';

import AulaEditorWithAnalytics from '../../common/AulaEditor/AulaEditorWithAnalytics';

import CreateComment from './CreateComment';

const mapStateToProps = (state) => {
  const currentUserId = getCurrentUserId(state);
  const spaceId = currentClassRoomId(state);
  const isEducator = isInstructor(state, currentUserId);

  return {
    user: state.user.user,
    isCreatingComment: state.comment.isCreatingComment,
    spaceId,
    isEducator,
    accessToken: getAccessToken(state, spaceId),
    institution: state.institution.general.institution,
    ltiProviders: getMaterialPlacementLtiProviders(state),
    EditorComponent: isEducator
      ? AulaEditorWithAnalytics
      : AulaEditorWithAnalyticsWithoutPlugins,
  };
};

const CreateCommentContainer = connect(mapStateToProps)(CreateComment);

export default CreateCommentContainer;
