import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ublend-npm/aulaui-next';
import AulaLabel from '../Label/Label';
import { getLabelProps } from './utils';

const Label = (props) => {
  const { type } = props;
  return type ? <AulaLabel {...getLabelProps(theme.color)[type]} /> : null;
};

Label.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Label;
