import Prefixer from 'inline-style-prefixer';
import { theme } from '@ublend-npm/aulaui-next';

import fonts from '../../../../../../../styles/fonts';

const tagItemStyle = new Prefixer().prefix({
  tag: {
    ...fonts.light,
    backgroundColor: theme.color.grayLight,
    color: theme.color.grey6,
    fontSize: 12,
    lineHeight: '13px',
    padding: '4px 10px',
    borderRadius: '11px',
    float: 'left',
    margin: '0 10px 7px 0',
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    ':hover': {
      opacity: '0.7',
    },
  },
  tagNew: {
    color: theme.color.grayDark,
  },
  tagCross: {
    marginLeft: 8,
    marginRight: -3,
    paddingLeft: 3,
    paddingRight: 3,
    color: theme.color.grey6,
    outlineOffset: 1,

    ':hover': {
      color: 'black',
    },
  },
});

export default tagItemStyle;
