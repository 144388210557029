/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Tooltip } from '@ublend-npm/aulaui-next';

import { ButtonContainer } from './Search.styled';
import { IconButton } from '@ublend-npm/aulaui-next';

const SEARCH_TOOLTIP = 'Search';
export default class Search extends PureComponent {
  render() {
    return (
      <ButtonContainer>
        <Tooltip title={SEARCH_TOOLTIP}>
          <div>
            <IconButton
              label="Search"
              onClick={this.props.onClick}
              size="small"
              icon={SearchIcon}
            />
          </div>
        </Tooltip>
      </ButtonContainer>
    );
  }
}
