export const isFetchingPostsSelector = (
  { classRoom: { isFetchingAnnouncementContent, isFetchingCommonRoomContent } },
  announcementsSelected: boolean
) =>
  announcementsSelected
    ? isFetchingAnnouncementContent
    : isFetchingCommonRoomContent;

export const hasFetchedAllPostsSelector = (
  { posts: { hasFetchedAllAnnouncementPosts, hasFetchedAllCommonRoomPosts } },
  announcementsSelected: boolean
) =>
  announcementsSelected
    ? hasFetchedAllAnnouncementPosts
    : hasFetchedAllCommonRoomPosts;
