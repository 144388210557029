import PropTypes from 'prop-types';
import React from 'react';
import Scrollbar from '../../../common/ScrollBar';
import { VersionList as VersionListType } from '../types';
import VersionListItem from './VersionListItem';

const makeItem = (current, selected, onSelect) => (item) =>
  (
    <VersionListItem
      key={item.id}
      lastUpdatedAt={item.updatedAt}
      writer={item.editor}
      title={item.title}
      onSelect={() => onSelect(item.id)}
      current={current === item.id}
      selected={selected === item.id}
      aria-pressed={selected === item.id}
    />
  );

const VersionList = (props) => (
  <div className={props.className}>
    <Scrollbar autoHide viewProps={{ 'data-testid': 'versions-list' }}>
      {props.versions.map(
        makeItem(props.current, props.selected, props.onSelect)
      )}
    </Scrollbar>
  </div>
);

VersionList.propTypes = {
  className: PropTypes.string,
  current: PropTypes.string,
  selected: PropTypes.string,
  versions: VersionListType.isRequired,
  onSelect: PropTypes.func,
};

VersionList.defaultProps = {
  className: '',
  current: null,
  selected: null,
  onSelect: () => null,
};

export default VersionList;
