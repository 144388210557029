import styled from 'react-emotion';
import UnstyledVisibilityOffIcon from '@material-ui/icons/VisibilityOffSharp';
import { theme } from '@ublend-npm/aulaui-next';

export const Title = styled.span`
  font-size: 20px;
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.large};
  margin-bottom: ${theme.spacing.medium};
  color: ${(props) =>
    props.isMediumGrey ? theme.color.blackAlpha(0.6) : theme.color.black};
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.copy};
  color: ${theme.color.blackAlpha(0.87)};
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.hasPadding && theme.spacing.small} 0;
  box-sizing: border-box;
`;

export const ButtonTitle = styled.span`
  font-size: ${theme.font.size.small};
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.small};
`;

export const IconButtonContainer = styled.div`
  margin: 0px 0px 20px 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DiscussionDetailsContainer = styled.div`
  color: ${theme.color.blackAlpha(0.6)};
  display: flex;
  flex-direction: row;
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  padding: ${theme.spacing.small} 0;
`;

export const VisibilityOffIcon = styled(UnstyledVisibilityOffIcon)`
  padding-right: 6px;
  width: 24px;
  height: 24px;
`;

export const buttonSize = { height: 32, width: 164 };
