import PropTypes from 'prop-types';
import React from 'react';

import { Version } from '../types';
import SectionTitle from '../SectionView/SectionTitle';
import SectionContent from '../SectionView/SectionContent';
import VersionModalLoader from './VersionModalLoader';

const VersionView = ({ version, className, isLoadingVersion }) =>
  version === null || isLoadingVersion ? (
    <div className={className}>
      <VersionModalLoader />
    </div>
  ) : (
    <div className={className}>
      <SectionTitle value={version.title} />
      <SectionContent
        versionId={version.id}
        content={JSON.parse(version.content)}
      />
    </div>
  );

VersionView.propTypes = {
  className: PropTypes.string,
  isLoadingVersion: PropTypes.bool,
  version: Version,
};
VersionView.defaultProps = {
  className: '',
  version: null,
  isLoadingVersion: false,
};

export default VersionView;
