import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Separator, Loader, Modal } from '@ublend-npm/aulaui-next';
import TabPanel from '../common/TabPanel/TabPanel';

import SearchBox from './SearchBox';
import SearchResult from './SearchResult';
import {
  Container,
  HeaderContainer,
  SearchBoxFooterWrapper,
  ResultsCountLabel,
  ContentContainer,
  CloseIcon,
  SearchResultsContainer,
  EmptyContentContainer,
  EmptyContentTitle,
  LoaderWrapper,
  ReachedEnd,
  EmptyContentImage,
  CloseIconBox,
} from './SearchModal.styled';

import EmptyState from './empty_state.png';

const headerHeight = 70; // The header height. Needs to calculate search container max-size.

class SearchModal extends PureComponent {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.emptyContentTitle = React.createRef();
    this.resultsElement = React.createRef();

    this.state = {
      selectedTabIndex: undefined,
      searchBoxValue: undefined,
    };

    this.resultsTable = undefined;
    this.searchInputElement = undefined;

    this.renderModal = this.renderModal.bind(this);
    this.renderEmptyContent = this.renderEmptyContent.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.handleSidePanelTabClicked = this.handleSidePanelTabClicked.bind(this);
    this.handleOnSearchBoxChange = this.handleOnSearchBoxChange.bind(this);
    this.handleOnSearchBoxClear = this.handleOnSearchBoxClear.bind(this);
    this.handleContentScroll = this.handleContentScroll.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setResultsTable = this.setResultsTable.bind(this);
    this.handleContainerKeyDown = this.handleContainerKeyDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Given side panel tabs and results, select a new tab if
    // there's none already selected or selected has no results
    if (
      nextProps.sidePanelTabs.length &&
      nextProps.results &&
      Object.keys(nextProps.results).length &&
      !prevState.selectedTabIndex
    ) {
      const selectedTabIndex = nextProps.sidePanelTabs.findIndex(
        (tab) => tab.results.length
      );

      if (nextProps.onClickSidePanelTab) {
        nextProps.onClickSidePanelTab(
          nextProps.sidePanelTabs[selectedTabIndex].id
        );
      }

      return { selectedTabIndex };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const hasFocus =
      this.searchInputElement &&
      this.searchInputElement === document.activeElement;

    // Focus number of results
    if (!hasFocus && this.resultsElement && this.resultsElement.current) {
      this.resultsElement.current.focus();
    }

    // Focus empty content title
    if (!hasFocus && this.emptyContentTitle && this.emptyContentTitle.current) {
      this.emptyContentTitle.current.focus();
    }
  }

  setResultsTable(ref) {
    this.resultsTable = ref;
  }

  handleOnSearchBoxClear() {
    this.props.onSearchBoxChange('');
    this.setState({ searchBoxValue: '', selectedTabIndex: undefined });
  }

  handleOnSearchBoxChange(event) {
    if (event.target.value) {
      this.props.onSearchBoxChange(event.target.value);
      this.setState({ searchBoxValue: event.target.value });
    } else {
      this.handleOnSearchBoxClear();
    }
  }

  handleContainerKeyDown(event) {
    this.props.onKeyDown(event, this.containerRef);
  }

  handleSidePanelTabClicked(selectedTabIndex) {
    this.resultsTable.scrollToTop();

    if (this.state.selectedTabIndex !== selectedTabIndex) {
      this.props.onSidePanelTabClick(selectedTabIndex);

      this.setState({ selectedTabIndex }, () => {
        // Automatically fire onContentBottomScroll when table has no scroll
        if (
          this.resultsTable.getScrollHeight() ===
            this.resultsTable.getClientHeight() &&
          this.props.sidePanelTabs[selectedTabIndex].moreToLoad
        ) {
          this.props.onContentBottomScroll(
            selectedTabIndex,
            this.state.searchBoxValue
          );
        }
      });
    }
  }

  handleContentScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (
      bottom &&
      this.props.sidePanelTabs[this.state.selectedTabIndex].moreToLoad
    ) {
      this.props.onContentBottomScroll(
        this.state.selectedTabIndex,
        this.state.searchBoxValue
      );
    }
  }

  handleClose(e) {
    this.searchInputElement && this.searchInputElement.blur();
    this.props.onClose(e);
  }

  renderEmptyContent() {
    return (
      <EmptyContentContainer>
        <EmptyContentImage src={EmptyState} alt="No results!" />
        <EmptyContentTitle
          tabIndex="-1"
          innerRef={this.emptyContentTitle}
          onFocus={(event) => event.currentTarget.setAttribute('tabindex', '0')}
          onBlur={(event) => event.currentTarget.setAttribute('tabindex', '-1')}
          aria-live="polite"
        >
          {this.props.emptyTitle}
        </EmptyContentTitle>
      </EmptyContentContainer>
    );
  }

  renderContent() {
    if (!this.state.searchBoxValue) {
      return null;
    }
    if (!this.props.results || !Object.keys(this.props.results).length) {
      if (this.props.loading) {
        return (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        );
      }

      if (this.props.results && !Object.keys(this.props.results).length) {
        return this.renderEmptyContent();
      }
    } else if (this.props.sidePanelTabs[this.state.selectedTabIndex].results) {
      const resultsToShow = this.props.sidePanelTabs[
        this.state.selectedTabIndex
      ].results.map((resultId) => this.props.results[resultId]);

      return (
        <ContentContainer>
          <TabPanel
            title={this.props.sidePanelTitle}
            titlePosition={this.props.sidePanelTitlePosition}
            tabs={this.props.sidePanelTabs.map(({ title, disabled }) => ({
              title,
              disabled,
            }))}
            selectedTabIndex={this.state.selectedTabIndex}
            onTabClick={this.handleSidePanelTabClicked}
            canSelectDisabled
            scrollable
          />
          <SearchResultsContainer
            onScroll={this.handleContentScroll}
            scrollBarRef={this.setResultsTable}
          >
            {resultsToShow.length ? (
              <ResultsCountLabel tabIndex={-1} innerRef={this.resultsElement}>
                {`${resultsToShow.length} Result${
                  resultsToShow.length > 1 ? 's' : ''
                }`}
              </ResultsCountLabel>
            ) : null}
            {resultsToShow.map((result) => (
              <SearchResult
                key={result.id}
                onClick={() => this.props.onSearchResultClick(result.id)}
                {...(this.props.resultRenderingData
                  ? this.props.resultRenderingData(result)
                  : result)}
              />
            ))}
            {!this.props.sidePanelTabs[this.state.selectedTabIndex]
              .moreToLoad && resultsToShow.length ? (
              <ReachedEnd>Looks like you've reached the end!</ReachedEnd>
            ) : null}
            {this.props.loading && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}
            {!this.props.loading &&
              !resultsToShow.length &&
              this.renderEmptyContent()}
          </SearchResultsContainer>
        </ContentContainer>
      );
    }

    return null;
  }

  renderModal() {
    return (
      <Modal
        id="omnisearch"
        container={() => document.getElementById('user-profile-container')}
        onClose={this.handleClose}
        open={this.props.open}
        fullscreen
      >
        <Container
          hasResults={!!this.props.results}
          headerHeight={headerHeight}
          onKeyDown={this.handleContainerKeyDown}
          innerRef={this.containerRef}
        >
          <HeaderContainer id="omnisearch-dialogtitle">
            <SearchBox
              inputRef={(el) => {
                this.searchInputElement = el;
              }}
              placeholder={this.props.placeholder}
              value={this.state.searchBoxValue}
              onChange={this.handleOnSearchBoxChange}
              onClear={this.handleOnSearchBoxClear}
              onEnter={this.props.onSearchBoxEnter}
              onMount={() =>
                this.searchInputElement && this.searchInputElement.focus()
              }
            />
            <CloseIconBox
              tabIndex={0}
              role="button"
              aria-label="Close search modal"
              onClick={this.props.onClose}
              onKeyDown={(key) => key.which === 13 && this.props.onClose()}
            >
              <CloseIcon />
            </CloseIconBox>
          </HeaderContainer>
          <Separator />
          {this.renderContent()}
          {this.props.searchBoxFooter && (
            <SearchBoxFooterWrapper>
              {this.props.searchBoxFooter}
            </SearchBoxFooterWrapper>
          )}
        </Container>
      </Modal>
    );
  }

  render() {
    return <div>{this.renderModal()}</div>;
  }
}

SearchModal.propTypes = {
  /**
   * Tells the component whether to show the Omni Search modal
   */
  open: PropTypes.bool.isRequired,
  /**
   *
   */
  loading: PropTypes.bool,
  /**
   * Title for the side panel where filtering tabs are shown
   */
  sidePanelTitle: PropTypes.string,
  /**
   * Index where the side panel title will be placed
   */
  sidePanelTitlePosition: PropTypes.number,
  /**
   * Array of tabs shown in the side panel
   */
  sidePanelTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      results: PropTypes.arrayOf(PropTypes.string).isRequired,
      moreToLoad: PropTypes.bool.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  /**
   * Called when user clicks on side panel tab with tab id
   */
  onSidePanelTabClick: PropTypes.func,
  /**
   * Called with search text at every keystroke
   */
  onSearchBoxChange: PropTypes.func,
  /**
   * Called with search box "clear" button is clicked
   */
  onSearchBoxClear: PropTypes.func,
  /**
   * Called when enter key is pressed
   */
  onSearchBoxEnter: PropTypes.func,
  /**
   * On
   */
  onSearchResultClick: PropTypes.func,
  /**
   * Array of objects to be displayed as search results
   */
  results: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      postDate: PropTypes.string,
      avatar: PropTypes.string,
      content: PropTypes.node,
      footer: PropTypes.node,
    })
  ),
  /**
   * If provided, it will be called with the result's object
   * to get the necessary rendering data.
   */
  resultRenderingData: PropTypes.func,
  /**
   * Called with selected tab index and search box value
   * when scrollable content list reaches its bottom
   */
  onContentBottomScroll: PropTypes.func,
  /**
   * Called when Omni Search closes itself
   */
  onClose: PropTypes.func,
  /**
   * Called when Omni Search tab-manipulations
   */
  onKeyDown: PropTypes.func,
  /**
   * Text displayed in search box when empty
   */
  placeholder: PropTypes.string,
  /**
   * Text displayed as title when no results
   */
  emptyTitle: PropTypes.string.isRequired,
  /**
   * Node displayed under the search box
   */
  searchBoxFooter: PropTypes.node,
};

SearchModal.defaultProps = {
  loading: false,
  sidePanelTabs: [],
  sidePanelTitle: '',
  sidePanelTitlePosition: undefined,
  results: undefined,
  placeholder: '',
  searchBoxFooter: null,
  onSidePanelTabClick: () => undefined,
  onSearchBoxChange: () => undefined,
  onSearchBoxEnter: () => undefined,
  onSearchBoxClear: () => undefined,
  onClose: () => undefined,
  onKeyDown: () => undefined,
  onContentBottomScroll: () => undefined,
};

export default SearchModal;
