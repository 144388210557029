import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container } from './LastEdited.styled';
import formatDate from '../../../../../../core/utils/formatDate';

const LastEdited = ({ createdAt, editedAt }) => {
  const [formattedTime, setFormattedTime] = useState(formatDate(Date.now()));
  useEffect(() => {
    setFormattedTime(formatDate(new Date(editedAt || createdAt)));
  }, [editedAt, createdAt]);

  return (
    <Container>
      <span>{'Last edited: '}</span>
      <span>{formattedTime}</span>
    </Container>
  );
};

LastEdited.propTypes = {
  createdAt: PropTypes.string,
  editedAt: PropTypes.string,
};

LastEdited.defaultProps = {
  createdAt: null,
  editedAt: null,
};

export default LastEdited;
