import Prefixer from 'inline-style-prefixer';
import { theme } from '@ublend-npm/aulaui-next';
import React from 'react';
import { SPACE_SELECTOR_HEIGHT } from '@app/constants/sizes';

interface PostStyles {
  container: React.CSSProperties;
  parentWrapper: React.CSSProperties;
  wrapper: React.CSSProperties;
  scheduledRow: React.CSSProperties;
  nextScheduledPost: React.CSSProperties;
  navigation: React.CSSProperties;
}

const postStyle = new Prefixer().prefix<PostStyles>({
  container: {
    position: 'relative',
    overflow: 'hidden',
    height: `calc(100% - ${SPACE_SELECTOR_HEIGHT}px)`,
  },
  parentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    position: 'relative',
    padding: '0 54px',
    width: '100%',
    height: 'calc(100% - 64px)',
    maxWidth: 624,
  },
  scheduledRow: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  nextScheduledPost: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
    color: theme.color.text,
  },
  navigation: {
    padding: '20px 54px',
  },
});

export default postStyle;
