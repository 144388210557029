import PropTypes from 'prop-types';
import React from 'react';
import { cleanState } from '@ublend-npm/aula-editor';
import ResizeObserver from 'resize-observer-polyfill';
import Reply from '../Reply.container';
import { Container, EditorWrapper } from './WriteZone.styled';
import { UP_ARROW } from '../../../../../utils/keyCodes';
import AulaEditorWithFileBlocksValidation from '../../../common/AulaEditor/AulaEditorWithFileBlocksValidation';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// TODO move to utils when TH90 is deprecated
const isEmptyEditorContent = (content) =>
  !content.value ||
  (content.value.length === 1 && content.value[0].children[0].text === '');

class WriteZone extends React.PureComponent {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.editorWrapperRef = React.createRef();
    this.state = {
      refreshEditorKey: 0,
      editorState: cleanState,
      isSubmitting: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.focus = this.focus.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.clear = this.clear.bind(this);
    this.loadContentAndFocusEditor = this.loadContentAndFocusEditor.bind(this);
    this.clearAndFocusEditor = this.clearAndFocusEditor.bind(this);
    this.setSubmittingState = this.setSubmittingState.bind(this);
  }

  componentDidMount() {
    const { onHeightChange } = this.props;
    this.loadContentAndFocusEditor();
    this.ob = new ResizeObserver(onHeightChange);
    this.ob.observe(this.editorWrapperRef.current);
  }

  async componentDidUpdate(prevProps) {
    const { conversationId, reply } = this.props;
    const conversationChanged = prevProps.conversationId !== conversationId;
    const replyChanged =
      reply !== prevProps.reply || reply?.id !== prevProps.reply?.id;

    if (conversationChanged) {
      this.updateContent(prevProps.conversationId);
      this.loadContentAndFocusEditor();
    }
    if (replyChanged) {
      await this.focus();
    }
  }

  componentWillUnmount() {
    const { conversationId } = this.props;
    this.updateContent(conversationId);
    this.ob.unobserve(this.editorWrapperRef.current);
  }

  setSubmittingState(isSubmitting) {
    this.setState((prevState) => ({
      ...prevState,
      isSubmitting,
    }));
  }

  onChange(editorState, conversationId) {
    const { sendTyping, conversationId: conversationIdProp } = this.props;
    if (conversationId === conversationIdProp) {
      this.setState((prevState) => ({
        ...prevState,
        editorState,
      }));

      if (isEmptyEditorContent(editorState)) {
        sendTyping();
      }
    }
  }

  onKeyDown(event) {
    const { keyCode } = event;
    const { onEdit } = this.props;
    const { editorState } = this.state;
    if (keyCode === UP_ARROW && isEmptyEditorContent(editorState)) {
      onEdit();
    }
  }

  onReturn(editorState, conversationId) {
    const { onReturn, onMessageSubmit } = this.props;
    const { isSubmitting } = this.state;

    if (isSubmitting) return;

    onReturn(editorState, conversationId);
    onMessageSubmit();
    this.clearAndFocusEditor();
  }

  clear() {
    const { refreshEditorKey } = this.state;
    this.setState({
      refreshEditorKey: refreshEditorKey + 1,
      editorState: cleanState,
    });
  }

  updateContent(conversationId) {
    const { editorState } = this.state;
    const exportedContent = !isEmptyEditorContent(editorState)
      ? editorState
      : undefined;
    const { updateContent } = this.props;
    updateContent(conversationId, exportedContent);
  }

  async clearAndFocusEditor() {
    this.clear();
    await this.focus();
  }

  async loadContentAndFocusEditor() {
    const { content } = this.props;
    const { refreshEditorKey } = this.state;
    if (content) {
      this.setState({
        refreshEditorKey: refreshEditorKey + 1,
        editorState: content,
      });
    } else {
      this.clear();
    }
    await this.focus();
  }

  async focus() {
    await delay(50);
    const { focus } = this.editorRef.current ? this.editorRef.current : {};
    focus && focus();
  }

  render() {
    const { placeholder, reply, htmlAttributes, editorConfig, conversationId } =
      this.props;
    const { refreshEditorKey, editorState, isSubmitting } = this.state;

    return (
      <Container
        role="textbox"
        onKeyDown={this.onKeyDown}
        tabIndex={-1}
        {...htmlAttributes}
      >
        {reply && <Reply />}
        <EditorWrapper>
          <div ref={this.editorWrapperRef}>
            <AulaEditorWithFileBlocksValidation
              ref={this.editorRef}
              config={editorConfig}
              editorState={editorState}
              key={refreshEditorKey}
              placeholder={placeholder}
              type="inbox"
              onChange={this.onChange}
              onReturn={this.onReturn}
              isSubmitting={isSubmitting}
              setSubmittingState={this.setSubmittingState}
              conversationId={conversationId}
            />
          </div>
        </EditorWrapper>
      </Container>
    );
  }
}

WriteZone.propTypes = {
  conversationId: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  onMessageSubmit: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  sendTyping: PropTypes.func.isRequired,
  content: PropTypes.shape({}),
  updateContent: PropTypes.func.isRequired,
  reply: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  htmlAttributes: PropTypes.shape({}),
  editorConfig: PropTypes.shape({
    filesUrl: PropTypes.string,
    apiUrl: PropTypes.string,
  }).isRequired,
};

WriteZone.defaultProps = {
  content: cleanState,
  reply: null,
  htmlAttributes: {},
};

export default WriteZone;
