import { useState } from 'react';

type SetTrue = () => void;
type SetFalse = () => void;

export const useBoolean = (
  defaultValue?: boolean
): [boolean, SetTrue, SetFalse] => {
  const [val, setVal] = useState(defaultValue || false);

  return [val, () => setVal(true), () => setVal(false)];
};
