import React from 'react';
import { INBOX_BEGINNING_CONVERSATION } from '../../../../constants/texts';
import {
  Container,
  Indicator,
  Label,
  LabelContainer,
} from './BeginningIndicator.styled';

const BeginningIndicator = () => (
  <Container>
    <Indicator />
    <LabelContainer>
      <Label>{INBOX_BEGINNING_CONVERSATION}</Label>
    </LabelContainer>
  </Container>
);

export default BeginningIndicator;
