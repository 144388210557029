import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const RestoreMaterialOptionContainer = styled('div')`
  padding-bottom: ${theme.spacing.small};
`;

export const RadioButton = styled<{ isChecked: boolean }, 'input'>('input')`
  opacity: 0;
  position: fixed;
  width: 0;
  &:checked + label {
    background-color: ${theme.color.designSystem.background.lightPurple
      .pressed};
    border-color: ${theme.color.designSystem.purple70};
  }
  &:focus + label {
    border: 1px solid;
    border-color: ${theme.color.designSystem.blackOpacity87};
    background-color: ${(props: { isChecked: boolean }) =>
      props.isChecked
        ? theme.color.designSystem.background.lightPurple.pressed
        : theme.color.grey14};
  }
`;

export const RadioLabel = styled('label')`
  display: block;
  background-color: 'transparent';
  padding: 10px 12px;
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  border: 1px solid ${theme.color.designSystem.blackOpacity12};
  border-radius: ${theme.borderRadius.medium};
  &:hover {
    border: 1px solid ${theme.color.designSystem.blackOpacity38};
    background-color: ${theme.color.designSystem.background.grey.hover};
  }
  cursor: pointer;
`;
