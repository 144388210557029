import React, { useState, useEffect } from 'react';
import { Alert } from '@ublend-npm/aulaui-next';

const UpcomingAssignmentAlert = () => {
  const [alertViews, setAlertViews] = useState(0);

  useEffect(() => {
    const assignmentCreationAttempts =
      JSON.parse(localStorage.getItem('assignmentCreationAttempts')) || 0;

    setAlertViews(assignmentCreationAttempts);

    if (assignmentCreationAttempts < 3) {
      localStorage.setItem(
        'assignmentCreationAttempts',
        assignmentCreationAttempts + 1
      );
    }
  }, []);

  return alertViews < 3 ? (
    <Alert type="announcement">
      <span data-testid="upcoming-assignment-message">
        Before an assignment opens, students will be able to see the assignment
        title and dates only. Access to the assignment itself is disabled until
        the open date. You can still hide it completely as before.
      </span>
    </Alert>
  ) : null;
};

export default UpcomingAssignmentAlert;
