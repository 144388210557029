import styled from 'react-emotion';
import {
  Menu as AUIMenu,
  IconButton as BaseIconButton,
} from '@ublend-npm/aulaui-next';

export const IconButton = styled(BaseIconButton)``;

export const Menu = styled(AUIMenu)`
  display: inline-block;
  margin-left: 16px;
`;
