import React, { useState, useEffect } from 'react';
import moment, { MomentInput } from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Drawer, IconButton } from '@ublend-npm/aulaui-next';
import useDeletedMaterialPages from '@app/hooks/useDeletedMaterialPages';
import useRestoreDeletedMaterialPage from '@app/hooks/useRestoreDeletedMaterialPage';
import { MATERIAL_ITEM_TYPE } from '@core/constants/analytics';
import CircularProgressPresentational from '@app/components/presentational/CircularProgress';
import EditorReadOnly from '../../../common/EditorPlus/EditorReadOnly.container';
import {
  ActionButtonsContainer,
  RestoreMaterialDrawerContainer,
  RestoreMaterialDrawerTitle,
  MaterialListContainer,
  PageViewContainer,
  PageViewTitle,
  Alert,
  EditorContainer,
  InfoIconColor,
  EmptyState,
  EmptyStateContainer,
  EmptyStateMessage,
  EmptyStateTitle,
  IconButtonWrapper,
  Center,
} from './RestoreMaterialDrawer.styled';
import RestoreMaterialOption from './RestoreMaterialOption';

type RestoreMaterialDrawerProps = {
  classRoomId: string;
  educatorArea?: boolean;
  open: boolean;
  onClose: () => void;
  onRestore: (pageId: string | undefined, pageTitle: string) => void;
};

// TODO: make sure we handle all cases here: https://momentjs.com/docs/#/displaying/calendar-time/
const formatTime = (d: MomentInput): string =>
  moment(d)
    .calendar(null, {
      sameElse: () => `on DD/MM/YYYY`,
    })
    .toLowerCase();

const RestoreMaterialDrawer = ({
  classRoomId,
  educatorArea = false,
  open,
  onClose,
  onRestore,
}: RestoreMaterialDrawerProps) => {
  const [selectedPageId, setSelectedPageId] = useState<string>();

  const { deletedMaterialPages, isLoading, hasDeletedMaterialPages } =
    useDeletedMaterialPages(classRoomId, educatorArea, open);

  const { restorePage, isRestoring } =
    useRestoreDeletedMaterialPage(classRoomId);

  useEffect(() => {
    if (
      deletedMaterialPages &&
      deletedMaterialPages.length > 0 &&
      !selectedPageId
    ) {
      setSelectedPageId(deletedMaterialPages[0].id);
    }
  }, [deletedMaterialPages, selectedPageId]);

  const selectedPage = selectedPageId
    ? deletedMaterialPages?.find(({ id }) => id === selectedPageId)
    : undefined;

  const onPageRestore = async () => {
    if (!selectedPageId) {
      return;
    }

    await restorePage(selectedPageId);
    onClose();
    onRestore(selectedPageId, selectedPage?.title || '');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      aria-label="List of deleted materials"
    >
      <RestoreMaterialDrawerTitle>
        Restore deleted pages
      </RestoreMaterialDrawerTitle>
      {isLoading && (
        <Center>
          <CircularProgressPresentational />
        </Center>
      )}
      {hasDeletedMaterialPages === false && (
        <EmptyStateContainer aria-label="No deleted pages found">
          <IconButtonWrapper>
            <IconButton
              data-testid="close-material-drawer"
              onClick={onClose}
              type="secondary"
              size="small"
              icon={CloseIcon}
            />
          </IconButtonWrapper>
          <EmptyStateMessage>
            <EmptyState />
            <EmptyStateTitle>There are no deleted pages</EmptyStateTitle>
          </EmptyStateMessage>
        </EmptyStateContainer>
      )}
      {hasDeletedMaterialPages === true && (
        <>
          <RestoreMaterialDrawerContainer>
            <MaterialListContainer>
              {deletedMaterialPages?.map(({ id, title }) => (
                <RestoreMaterialOption
                  key={id}
                  value={title || ''}
                  optionKey={id}
                  onClick={() => setSelectedPageId(id)}
                  isPressed={id === selectedPageId}
                />
              ))}
            </MaterialListContainer>
            <PageViewContainer>
              {selectedPage ? (
                <React.Fragment>
                  <Alert>
                    <InfoIcon style={InfoIconColor} />
                    This page was deleted {formatTime(selectedPage.deletedAt)}.
                  </Alert>
                  <EditorContainer aria-label="Deleted material content">
                    <PageViewTitle>{selectedPage.title}</PageViewTitle>
                    <EditorReadOnly
                      itemId={selectedPageId}
                      itemType={MATERIAL_ITEM_TYPE}
                      rawEditorState={selectedPage.content}
                    />
                  </EditorContainer>
                </React.Fragment>
              ) : null}
            </PageViewContainer>
          </RestoreMaterialDrawerContainer>
          <ActionButtonsContainer>
            <Button type="secondary" aria-label="Cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={isRestoring}
              type="primary"
              disabled={!selectedPageId}
              aria-label="Restore page"
              onClick={onPageRestore}
            >
              Restore page
            </Button>
          </ActionButtonsContainer>
        </>
      )}
    </Drawer>
  );
};

export default RestoreMaterialDrawer;
