import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EditorState, cleanState, isUpdating } from '@ublend-npm/aula-editor';
import { API_URL, FILE_URL } from '@core/constants/endpoints';
import { getMaterialPlacementLtiProviders } from '@core/selectors/lti';
import * as analyticsTypes from '@core/constants/analytics';
import uuid from '@core/utils/uuid';
import { getStore } from '@app/store';

import { isLocalId } from '@core/utils/localId';
import { EditorContainer, Center } from './SectionContent.styled';
import AulaEditorWithAnalytics from '../../../common/AulaEditor/AulaEditorWithAnalytics';
import { MATERIAL_EDITOR_PLACEHOLDER } from '../../../../../constants/texts';
import CircularProgress from '../../../CircularProgress';
import DiscussionArea from '../DiscussionArea/DiscussionArea';
import useEnabledLTIIntegrations from '../../../../../hooks/useEnabledLTIIntegrations';
import useDidUpdate from '../../../../../hooks/useDidUpdate';

type UploadStateFunctionParameter = (editorState: EditorState) => boolean;

interface Props {
  content?: typeof cleanState | null;
  discussionTopic?: string;
  showDiscussion?: boolean;
  isLoadingContent?: boolean;
  reverting?: boolean;
  editable?: boolean;
  sectionId: string;
  versionId?: string | null;
  onGetSection?: (sectionId: string) => void;
  setUploadState?: (UploadStateFunctionParameter) => void;
  onDiscussionSave?: () => void;
  correlationId?: string;
}

type Ref = {
  focus: () => void;
  export: () => EditorState;
} | null;

const SectionContent = forwardRef<Ref, Props>(
  (
    {
      content = null,
      discussionTopic = undefined,
      showDiscussion = false,
      editable = false,
      isLoadingContent = false,
      onGetSection = () => null,
      reverting = false,
      sectionId,
      setUploadState = () => null,
      versionId = null,
      onDiscussionSave = () => {},
      correlationId = undefined,
    }: Props,
    ref
  ) => {
    const [editorState, setEditorState] = useState(content || cleanState);
    const [editorKey, setEditorKey] = useState(uuid());
    const { getState } = getStore();
    const state = getState();
    const spaceId = state.classRoom?.current;
    const accessToken = state.accessTokens[spaceId]?.token;
    const { institution } = state.institution?.general;
    const editorConfig = useMemo(
      () => ({
        accessToken,
        spaceId,
        institution,
        filesUrl: FILE_URL(),
        apiUrl: API_URL(),
        userId: state.user?.userId,
        materialId: sectionId,
        ltiProviders: getMaterialPlacementLtiProviders(state),
        itemType: analyticsTypes.MATERIAL_ITEM_TYPE,
        correlationId,
        itemId: sectionId,
      }),
      [correlationId]
    );

    const filteredEditorConfig = useEnabledLTIIntegrations(editorConfig);

    const editorRef = useRef<null | HTMLInputElement>(null);

    const resetState = (es = cleanState) => {
      setEditorState(es);
      setEditorKey(uuid());
    };

    useEffect(() => {
      if (isLocalId(sectionId)) {
        // If the sectionId changes to a local one, it means that the user is
        // adding a new section, then the editorState is set to a clean one.
        resetState();
      } else {
        // onGetSection() triggers the retrieval for the content of the specific
        // sectionId and updates props.content
        onGetSection(sectionId);
      }
    }, [reverting, sectionId]);

    // Updates editorState if props.content changes and is not null
    useEffect(() => {
      if (content) {
        resetState(content);
      }
    }, [content]);

    // clear editor state when changing section to avoid rendering dirty state
    useDidUpdate(() => {
      if (!versionId) {
        // we don't have the content at this point,
        // thus need to reset the state passing no arguments
        resetState();
      }
    }, [sectionId, versionId]);

    // Parent components use reference to this component in order to imperatively
    // call .focus() (to focus the editor) and .export() (to retrieve the current
    // editorState)
    useImperativeHandle(ref, () => {
      const focus = () => {
        if (editorRef.current) {
          editorRef.current.focus();
        }
      };

      const _export = () => {
        return editorState;
      };

      return {
        focus,
        export: _export,
      };
    });

    // Runs whenever the editor's state changes
    const handleChange = (newEditorState) => {
      // Updates the state
      setEditorState(newEditorState);

      // Analyses if the current state is waiting for an upload to finish,
      // and sends this information to the parent via setUploadState
      setUploadState(isUpdating(newEditorState));
    };

    const isContentEmpty = !content || editorState === cleanState;
    const contentRequiresLoading = !(isLocalId(sectionId) || versionId);

    if (contentRequiresLoading && (isLoadingContent || isContentEmpty)) {
      return (
        <Center>
          <CircularProgress />
        </Center>
      );
    }

    return (
      <EditorContainer editable={editable} aria-label="editor-container">
        <AulaEditorWithAnalytics
          // Ts-ignore is used here because we don't want to type every child component
          // @ts-ignore
          config={filteredEditorConfig}
          key={editorKey}
          editorState={editorState}
          onChange={handleChange}
          placeholder={MATERIAL_EDITOR_PLACEHOLDER}
          readOnly={!editable}
          ref={editorRef}
          type="material"
          isAssignmentsEnabled
          cursorPosition="start"
          correlationId={correlationId}
        />
        <DiscussionArea
          entityId={sectionId}
          editable={editable}
          discussionTopic={discussionTopic}
          showDiscussion={showDiscussion}
          onDiscussionSave={onDiscussionSave}
        />
      </EditorContainer>
    );
  }
);

export default SectionContent;
