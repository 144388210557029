/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { cx } from 'emotion';
import { Menu, ListItem, Tooltip, IconButton } from '@ublend-npm/aulaui-next';
import MoreVert from '@material-ui/icons/MoreVert';
import ReplyIcon from '@material-ui/icons/Reply';
import { COMMENT_ITEM_TYPE } from '@core/constants/analytics';

import EditContent from '../../common/EditModal';
import UserBanner from '../UserBanner';
import { menu, menuItem, dangerMenuItem } from '../../common/Menu.styled';

import styles from './style';

const Actions = {
  Edit: 'edit',
  Delete: 'delete',
};

const MenuItems = [
  {
    key: Actions.Edit,
    getLabel: () => 'Edit',
    isVisible: ({ isAuthor }) => isAuthor,
    danger: false,
  },
  {
    key: Actions.Delete,
    getLabel: () => 'Delete',
    isVisible: ({ isAuthor, isEducator }) => isAuthor || isEducator,
    danger: true,
  },
];

class CommentUserBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editOpen: false,
    };

    this.handleSelectMenuItem = this.handleSelectMenuItem.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
  }

  handleSelectMenuItem({ item }) {
    const { handleDelete } = this.props;
    switch (item) {
      case Actions.Edit:
        this.handleOpenEdit();
        break;
      case Actions.Delete:
        handleDelete();
        break;
      default:
        break;
    }
  }

  handleOpenEdit() {
    this.setState({ editOpen: true });
  }

  handleCloseEdit() {
    this.setState({ editOpen: false });
  }

  render() {
    const {
      comment,
      style,
      user,
      isEdited,
      handleReply,
      isReadOnlyUser,
      entityId,
    } = this.props;
    const { editOpen } = this.state;
    const visibleMenuItems = MenuItems.filter((item) =>
      item.isVisible(this.props)
    );
    const commentMenu =
      visibleMenuItems.length > 0 ? (
        <Menu
          menuListComponent="ul"
          classes={{ menu }}
          ariaLabelledby={entityId}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          renderTarget={({ handleTargetClick }) => (
            <Tooltip title="Comment options">
              <div>
                <IconButton
                  icon={MoreVert}
                  size="xSmall"
                  type="secondary"
                  label="Comment options"
                  onClick={handleTargetClick}
                />
              </div>
            </Tooltip>
          )}
          onSelect={this.handleSelectMenuItem}
          renderChildren={({ getItemProps, highlightedIndex }) =>
            MenuItems.reduce(
              (acc, item, index) =>
                item.isVisible(this.props)
                  ? [
                      ...acc,
                      <ListItem
                        key={item.key}
                        className={
                          item.danger ? cx(menuItem, dangerMenuItem) : menuItem
                        }
                        primaryText={item.getLabel(this.props)}
                        highlighted={highlightedIndex === index}
                        {...getItemProps({
                          item: item.key,
                          onClick: (e) => e.stopPropagation(),
                        })}
                      />,
                    ]
                  : acc,
              []
            )
          }
        />
      ) : null;

    return (
      <div style={style}>
        <div style={styles.container}>
          <UserBanner
            user={user}
            time={comment.createdAt}
            isEdited={isEdited}
          />
          <div style={styles.wrapper}>
            {!isReadOnlyUser && (
              <Tooltip title="Reply to comment">
                <div>
                  <IconButton
                    icon={ReplyIcon}
                    type="secondary"
                    size="xSmall"
                    label="Reply"
                    onClick={handleReply}
                  />
                </div>
              </Tooltip>
            )}
            <div>
              {commentMenu}
              <EditContent
                open={editOpen}
                content={comment.content}
                itemClass="UBComment"
                itemType={COMMENT_ITEM_TYPE}
                itemId={comment.id}
                onClose={this.handleCloseEdit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CommentUserBanner.propTypes = {
  style: PropTypes.object,
  user: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  isEducator: PropTypes.bool.isRequired,
  isAuthor: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleReply: PropTypes.func.isRequired,
  isEdited: PropTypes.bool,
  entityId: PropTypes.string.isRequired,
  sequenceId: PropTypes.string.isRequired,
  isReadOnlyUser: PropTypes.bool.isRequired,
};

CommentUserBanner.defaultProps = {
  isEdited: false,
  style: {},
};

export default CommentUserBanner;
