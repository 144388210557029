import styled from 'react-emotion';

import { theme } from '@ublend-npm/aulaui-next';

export const Subtitle = styled.div`
  font-size: ${theme.font.size.small};
  font-weight: ${theme.font.weight.regular};
  line-height: ${theme.font.lineHeight.small};
  margin-bottom: ${theme.spacing.medium};
`;

export const Footer = styled.div`
  padding-left: ${theme.spacing.xLarge};
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.isSpread ? 'space-between' : 'flex-end'};
  align-items: center;
`;

export const buttonStyle = {
  margin: `0 ${theme.spacing.xLarge} ${theme.spacing.xLarge} 0`,
};
