/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, Alert, Button } from '@ublend-npm/aulaui-next';
import {
  Lti1P3Provider,
  LtiPlacement,
  LtiVersion,
} from '@ublend-npm/aula-schema';

import { isInstructorInSpace as isInstructorInSpaceSelector } from '@core/selectors/classroom';
import { currentUser } from '@core/selectors/user';
import { baseDomain, institutionShortName } from '@core/selectors/institution';
import { getLtiProviders, isTurnItInEnabled } from '@core/selectors/lti';
import { AulaState, ProviderType } from '@core/types/state';
import uuid from '@core/utils/uuid';
import showToast from '@core/toasts/operations/showToast.operation';
import { getIsCoupledProvider } from '@core/assignments/utils';

import TurnitinLogo from '@static/turnitin.png';
import HandinLogo from '@static/handin-logo.svg';

import getHandinUrl from '../utils/getHandinUrl';
import TurnitinModal from '../TurnitinModal';
import {
  validateHandinAssignment,
  validateTurnItInAssignment,
  hasError,
  filterAssignmentParams,
  Tool,
  formatAssignmentFormData,
} from './utils';
import successBackground from '@static/create-assignment-3.svg';
import { operations as assignmentOperations } from '../../../../../store/assignments';

import {
  AlertContainer,
  BackButtonPlaceholder,
  Footer,
  SuccessFooter,
  Content,
  Title,
  BackgroundContainer,
  TitleWrapper,
} from './CreateAssignmentModal.styled';

import ToolSelector from './ToolSelector';
import CreateModal from './CreateModal.component';
import {
  TURNITIN_PROVIDER,
  HANDIN_PROVIDER,
  LTI1P3_PROVIDER,
} from '@core/assignments/constants';
import { Lti1p3Launch } from '../Lti1p3Launch/Lti1p3Launch';
import GotItModal from './GotItModal';
import { LineItemForm } from '../LineItemForm/LineItemForm';
import { useYupForm } from '@app/hooks/useYupForm/useYupForm';
import { getLineItemSchema } from '../LineItemForm/lineItemSchema';
import saveLti1p3Assignment from '@core/api/lti/saveLti1p3Assignment';

export type EmptyAssignmentProps = {
  title: string;
  startDate?: string;
  endDate?: string;
  gradesReleasedAt?: string;
  provider?: ProviderType;
  isHidden?: boolean;
  shouldCloseOnDueDate?: boolean;
  description?: string;
  isAnonymised: boolean;
  submissions: string[];
};

const defaultAssignment: EmptyAssignmentProps = {
  title: '',
  startDate: undefined,
  endDate: undefined,
  gradesReleasedAt: undefined,
  provider: undefined,
  isHidden: true,
  shouldCloseOnDueDate: false,
  description: '',
  isAnonymised: false,
  submissions: [],
};

const getModalContainer = () =>
  document.getElementById('user-profile-container');

type CreateAssignmentModalProps = {
  onClose: () => void;
  isVisible: boolean;
  editingAssignment: any;
  currentSpace: any;
  fetchAccessToken: (spaceId: string) => Promise<string>;
  fetchAssignments: () => Promise<void>;
};

const CreateAssignmentModal = ({
  onClose,
  isVisible,
  editingAssignment,
  currentSpace,
  fetchAccessToken,
  fetchAssignments,
}: CreateAssignmentModalProps) => {
  const user = useSelector(currentUser);
  const baseUrlDomain = useSelector(baseDomain);
  const institution = useSelector(institutionShortName) || '';
  const isEducator = useSelector((state: AulaState) =>
    isInstructorInSpaceSelector(state)(
      user?.objectId || null,
      currentSpace.objectId
    )
  );
  const isTurnitinEnabled = useSelector(isTurnItInEnabled);
  const ltiTools = useSelector(getLtiProviders);

  const propOverrides = {
    maxGrade: {
      helperText:
        'This should be a numerical value which matches the maximum grade in the assignment tool, e.g. 100.',
    },
  };

  const dispatch = useDispatch();

  const [assignment, setAssignment] = useState(
    () => editingAssignment || defaultAssignment
  );
  const isEditing1p3Assignment =
    editingAssignment && editingAssignment.provider === LTI1P3_PROVIDER;

  let step;
  if (isEditing1p3Assignment) {
    step = 3;
  } else {
    step = editingAssignment ? 2 : 1;
  }

  const [currentStep, setCurrentStep] = useState(step);

  const [newAssignment, setNewAssignment] = useState();

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasAnonymizedError, setHasAnonymizedError] = useState(false);
  const [turnitinLaunch, setTurnitinLaunch] = useState<any>(null);
  const [lti1p3LaunchProviderId, setLti1p3LaunchProviderId] = useState<
    string | undefined
  >();
  const [lti1p3LaunchErrored, setLti1p3LaunchErrored] = useState(false);
  const lti1p3LaunchLoading = lti1p3LaunchProviderId && !lti1p3LaunchErrored;
  const [resourceLinkId, setResourceLinkId] = useState(uuid());
  const [newAssignmentId, setNewAssignmentId] = useState<string>();

  const titleRef = useRef<HTMLHeadingElement>(null);

  const shouldShowBackButton = currentStep !== 1 && !editingAssignment;

  const handleDateTimePickerForTII = (name: string, date: string) => {
    setErrors({
      ...errors,
      [name]: false,
    });
    setAssignment({
      ...assignment,
      [name]: date,
    });
  };

  const handleTextFieldChange = (name) => {
    return (event) => {
      setErrors({
        ...errors,
        [name]: false,
      });
      setAssignment({
        ...assignment,
        [name]: event.target.value,
      });
    };
  };

  const handleSwitch = (name: string) => {
    return () => {
      const status = assignment[name];
      setAssignment({
        ...assignment,
        [name]: !status,
      });
    };
  };

  const staticTools: Tool[] = [
    {
      logo: HandinLogo,
      name: 'Handin',
      key: HANDIN_PROVIDER,
      validator: validateHandinAssignment,
      externalUrl: getHandinUrl,
    },
  ];

  if (isTurnitinEnabled) {
    staticTools.push({
      logo: TurnitinLogo,
      name: 'Turnitin',
      key: TURNITIN_PROVIDER,
      validator: validateTurnItInAssignment,
      render: () => {
        return (
          <CreateModal
            space={currentSpace.objectId}
            handleChange={handleTextFieldChange}
            handleDateTimePicker={handleDateTimePickerForTII}
            handleSwitch={handleSwitch}
            assignment={assignment}
            hasAnonymizedError={hasAnonymizedError}
            isSaving={saving}
            done={saved}
            errors={errors}
          />
        );
      },
    });
  }

  const lti1p3Tools = ltiTools.filter(
    (tool) =>
      tool.ltiVersion === LtiVersion.ONE_POINT_THREE &&
      tool.placement === LtiPlacement.ASSIGNMENTS &&
      !tool.disabled
  ) as Lti1P3Provider[];

  const assignmentTools: Tool[] = [
    ...staticTools,
    ...lti1p3Tools.map((tool) => ({
      logo: tool.iconUrl,
      key: tool.clientId,
      name: tool.description,
      isDeepLinking: !!tool.isDeepLinking,
      isCoupled: getIsCoupledProvider(tool),
      ltiVersion: tool.ltiVersion,
      id: tool.id,
      assignmentCreationParameters: tool.assignmentCreationParameters,
      launch: () => {
        setLti1p3LaunchProviderId(tool.id);
        setLti1p3LaunchErrored(false);
      },
    })),
  ];

  let tool;

  if (isEditing1p3Assignment) {
    tool = assignmentTools
      .map((t) => t.id)
      .indexOf(editingAssignment.providerId);
  } else {
    tool = editingAssignment
      ? assignmentTools.map((t) => t.key).indexOf(editingAssignment.provider)
      : 0;
  }

  const [currentTool, setCurrentTool] = useState(tool);

  const { assignmentCreationParameters } = assignmentTools[currentTool];

  const validationSchema = getLineItemSchema(
    assignmentCreationParameters || []
  );

  const [
    newAssignmentFormData,
    { validateForm, getFieldProps, clearForm, hasFormErrors },
  ] = useYupForm({ validationSchema, propOverrides });

  const handleToolChange = (toolObj: Tool) => {
    const indexOfTool = assignmentTools.map((t) => t.key).indexOf(toolObj.key);

    if (currentTool !== 0 && currentTool !== indexOfTool) {
      clearForm();
    }
    setCurrentTool(assignmentTools.map((t) => t.key).indexOf(toolObj.key));

    setErrors({});
  };

  const handleResourceLinkTitleChange = (title) => {
    // assignment.title is used to store the resource link title for the launch request
    setAssignment({
      ...assignment,
      title,
    });
  };

  const onLti1p3LaunchSuccess = () => {
    setLti1p3LaunchProviderId(undefined);
    setResourceLinkId(uuid());
    setCurrentStep(3);
  };

  const onLti1p3LaunchError = () => {
    setLti1p3LaunchErrored(true);
    setResourceLinkId(uuid());
    dispatch(
      showToast({
        message: 'There was an error when trying to open the LTI tool.',
      })
    );
  };

  const nextButtonLabel = currentStep === 2 ? 'Save' : 'Next';

  const renderStepOne = () => {
    return (
      <>
        <Title>Select how you would like to create the assignment</Title>
        <ToolSelector
          onToolChange={handleToolChange}
          onResourceLinkTitleChange={handleResourceLinkTitleChange}
          currentTool={assignmentTools[currentTool]}
          tools={assignmentTools}
          resourceLinkTitle={assignment.title}
        />
        {lti1p3LaunchLoading && (
          <Lti1p3Launch
            providerId={lti1p3LaunchProviderId}
            resourceLinkId={resourceLinkId}
            resourceLinkTitle={assignment.title}
            onSuccessLaunch={onLti1p3LaunchSuccess}
            onErrorLaunch={onLti1p3LaunchError}
          />
        )}
      </>
    );
  };

  const renderStepThree = () => {
    const { name, logo } = assignmentTools[currentTool];
    const textParagraph1 =
      'Please proceed to your selected tool, which has been opened in a new tab, to complete the creation.';
    const textParagraph2 =
      'After the assignment has been successfully created, return to this page to find it in the assignments list.';

    return (
      <GotItModal
        isVisible={isVisible}
        logo={logo}
        name={name}
        textParagraph1={textParagraph1}
        textParagraph2={textParagraph2}
        onClose={onClose}
      />
    );
  };

  const renderStepTwo = () => {
    if (assignmentTools[currentTool].isCoupled) {
      return (
        <>
          <LineItemForm
            getFieldProps={getFieldProps}
            extraParameters={assignmentCreationParameters}
            showFormInfoMessage
          />
          {lti1p3LaunchLoading && (
            <Lti1p3Launch
              providerId={lti1p3LaunchProviderId}
              resourceLinkId={resourceLinkId}
              assignmentFormData={newAssignmentFormData}
              assignmentId={newAssignmentId}
              onSuccessLaunch={onLti1p3LaunchSuccess}
              onErrorLaunch={onLti1p3LaunchError}
            />
          )}
        </>
      );
    }

    return assignmentTools[currentTool].render?.();
  };

  const save = async (assignmentData): Promise<any> => {
    const { key: provider, validator } = assignmentTools[currentTool];
    const validateErrors = validator?.(assignmentData);

    if (validateErrors && hasError(validateErrors)) {
      setErrors(validateErrors);
      return null;
    }

    const accessToken = await fetchAccessToken(currentSpace.objectId);

    if (editingAssignment) {
      return dispatch(
        assignmentOperations.editAssignment({
          spaceId: currentSpace.objectId,
          assignment: {
            ...filterAssignmentParams(assignmentData),
            provider,
          },
          accessToken,
        })
      );
    }

    return dispatch(
      assignmentOperations.createAssignment({
        spaceId: currentSpace.objectId,
        assignment: {
          ...assignmentData,
          provider,
          status: 'live',
        },
        accessToken,
      })
    );
  };

  const launchTurnitin = (tiiAssignment) => {
    setTurnitinLaunch(tiiAssignment);
  };

  const closeTurnitin = () => {
    setTurnitinLaunch(null);
    // refresh the assignment list after closing the Turnitin modal
    // ensures any back-end updates from Turnitin received via the lti-hooks service are reflected in the UI
    fetchAssignments();
  };

  const launchAssignment = (launchingAssignment) => {
    if (launchingAssignment.provider === TURNITIN_PROVIDER) {
      launchTurnitin(launchingAssignment);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = async () => {
    const externalUrl = assignmentTools[currentTool].externalUrl?.({
      baseDomain: baseUrlDomain,
      space: currentSpace.objectId,
      search: 'createAssignment=true&source=ma',
    });

    if (externalUrl && !assignmentTools[currentTool].isCoupled) {
      setCurrentStep(3);
      window.open(externalUrl, '_blank');
    }

    if (currentStep === 1) {
      if (assignmentTools[currentTool].isCoupled) {
        setCurrentStep(2);
        if (titleRef.current) {
          titleRef.current.focus();
        }
      } else {
        if (assignmentTools[currentTool]?.render) {
          setCurrentStep(2);
        } else {
          assignmentTools[currentTool]?.launch?.();
        }
      }
      return;
    }

    if (assignmentTools[currentTool].isCoupled) {
      setSaving(true);
      try {
        const formData = validateForm();
        if (!formData) {
          setSaving(false);
          return;
        }
        const formattedAssignmentData = formatAssignmentFormData(
          formData,
          assignmentTools[currentTool].id,
          resourceLinkId
        );
        const {
          data: { id: newAssignmentId },
        } = await saveLti1p3Assignment(
          currentSpace.objectId,
          formattedAssignmentData
        );
        setNewAssignmentId(newAssignmentId);
        assignmentTools[currentTool].launch?.();
      } catch {
        dispatch(
          showToast({
            message: 'There was an error when trying to create the assignment.',
          })
        );
      }
      setSaving(false);
      return;
    }

    try {
      setSaving(true);
      const savedAssignment = await save(assignment);
      setSaving(false);
      if (savedAssignment) {
        setCurrentTool(currentTool);
        setCurrentStep(2);
        setSaved(true);
        setNewAssignment(savedAssignment);
        launchAssignment(savedAssignment);
      }
    } catch (error) {
      setCurrentTool(currentTool);
      setCurrentStep(2);
      setSaving(false);
      setHasAnonymizedError(
        error &&
          error.message &&
          error.message === 'cannot-update-anonymous-status'
      );
    }
  };

  let title;
  if (newAssignment) {
    title = '';
  } else if (currentStep > 1) {
    title = 'Set up your assignment';
  } else {
    title = 'Create assignment';
  }

  if (currentStep === 3) {
    return renderStepThree();
  }

  const uwsQuizAlert = (
    <>
      Quiz is no longer available. If you would like to set up a quiz, you can
      do so in myUWS. Fill in the{' '}
      <a
        target="_blank"
        rel="noopener"
        href="https://forms.office.com/pages/responsepage.aspx?id=t0SZ-E5Kp06RVjKZ80EWR9U_KCgDKbNLptf1zBe_LGVURURMM1FMTElaVUtJUFhCVDFFSzRYVkxORC4u"
      >
        request form
      </a>
      , and the Learning Transformation team will assist you.
    </>
  );

  const genericQuizAlert =
    'Quiz is no longer available. Please contact your administrator for more information';

  const quizAlert = ['uws', 'uws-test'].includes(institution)
    ? uwsQuizAlert
    : genericQuizAlert;

  return (
    <Dialog
      container={getModalContainer}
      open={isVisible}
      height={saved ? '583' : '658'}
      width="493"
      onClose={onClose}
      id={'Create assignment modal'}
    >
      {saved && <BackgroundContainer background={successBackground} />}
      {!saved && (
        <DialogTitle>
          <TitleWrapper innerRef={titleRef} tabIndex={0}>
            {title}
          </TitleWrapper>
        </DialogTitle>
      )}
      <Content createStep={currentStep === 2} saved={saved}>
        {currentStep === 1 && renderStepOne()}
        {currentStep === 2 && renderStepTwo()}
        {currentStep === 1 && (
          <AlertContainer>
            <Alert type="info">{quizAlert}</Alert>
          </AlertContainer>
        )}
      </Content>
      {!saved && (
        <Footer id="footer" role="group" title="Create assignment modal footer">
          {shouldShowBackButton && (
            <Button onClick={prevStep} aria-label="Back">
              Back
            </Button>
          )}
          {!shouldShowBackButton && <BackButtonPlaceholder />}
          <Button
            type="primary"
            onClick={nextStep}
            aria-label={
              hasFormErrors
                ? `${nextButtonLabel}, there are errors on the form. Please fix them before continuing.`
                : nextButtonLabel
            }
            loading={saving || lti1p3LaunchLoading}
            tabIndex={0}
            data-testid="next-assignment-create-step"
          >
            {nextButtonLabel}
          </Button>
        </Footer>
      )}
      {saved && (
        <SuccessFooter>
          <Button
            type="primary"
            onClick={() => launchAssignment(newAssignment)}
            aria-label="View Assignment"
          >
            View Assignment
          </Button>
          <Button
            type="text"
            onClick={onClose}
            aria-label="Close"
            data-testid="close-assignment-create-button"
          >
            Close
          </Button>
        </SuccessFooter>
      )}
      {turnitinLaunch && (
        <TurnitinModal
          key={turnitinLaunch}
          user={user}
          assignment={turnitinLaunch}
          space={currentSpace}
          isEducator={isEducator}
          onClose={closeTurnitin}
        />
      )}
    </Dialog>
  );
};

export default CreateAssignmentModal;
