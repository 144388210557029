import PropTypes from 'prop-types';
import React from 'react';
import {
  MATERIAL_DIALOG_DELETE_TITLE,
  MATERIAL_DIALOG_DELETE_SUBTITLE,
} from '../../../../../constants/texts';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import { DeletionDialogMessage } from './common.styled';

const DeleteSectionModal = ({
  open,
  onClose,
  onConfirm,
  deleting,
  sectionTitle,
}) => (
  <ConfirmDeleteDialog
    id="delete-section-modal"
    title={MATERIAL_DIALOG_DELETE_TITLE}
    message={
      <span>
        <DeletionDialogMessage>{sectionTitle}</DeletionDialogMessage>{' '}
        {MATERIAL_DIALOG_DELETE_SUBTITLE}
      </span>
    }
    open={open}
    onClose={onClose}
    onConfirm={onConfirm}
    deleting={deleting}
  />
);

DeleteSectionModal.propTypes = {
  deleting: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  sectionTitle: PropTypes.string,
};

DeleteSectionModal.defaultProps = {
  sectionTitle: '',
};

export default DeleteSectionModal;
