import PropTypes from 'prop-types';
import React from 'react';
import EditableSectionView from './EditableSectionView';
import ReadOnlySectionView from './ReadOnlySectionView';
import { Section } from '../types';

const SectionView = (props) => {
  const { section, isEducator } = props;
  if (!section) {
    return null;
  }

  if (isEducator) {
    return <EditableSectionView {...props} />;
  }

  return <ReadOnlySectionView {...props} />;
};

SectionView.propTypes = {
  section: Section,
  isEducator: PropTypes.bool,
  educatorOnly: PropTypes.bool,
};

SectionView.defaultProps = {
  isEducator: false,
  educatorOnly: false,
  section: null,
};

export default SectionView;
