import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const CurrentSubtitle = styled('span')`
  color: ${theme.color.designSystem.purple70};
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.small};
`;

export const DefaultSubtitle = styled('span')`
  color: ${theme.color.grayDark};
  font-size: ${theme.font.size.small};
`;

export const Active = styled('button')`
  cursor: pointer;
  width: 100%;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  padding: 14px 16px;
  background-color: ${(props) =>
    props.selected ? theme.color.beige : 'transparent'};
  border: none;
  text-align: inherit;

  &:hover {
    background-color: ${theme.color.grayLight};
  }

  &:active {
    background-color: ${theme.color.gray};
  }

  &:focus {
    outline: none;
  }
`;
