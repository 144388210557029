import React from 'react';
import styled from 'react-emotion';

import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { theme } from '@ublend-npm/aulaui-next';

export const SuggestionsContainer = styled(Paper)`
  padding-top: 8px;
  padding-bottom: 8px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
`;

export const SuggestionItemContainer = styled(({ size, ...props }) => (
  <MenuItem component="div" {...props} />
))`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ size }) => {
    if (size === 'medium') {
      return `
        height: 45px;
        padding: 0;
      `;
    }
    return `
        height: 32px;
        padding: 0px 8px;
      `;
  }}};
`;

export const CircularProgressContainer = styled('div')`
  display: block;
  margin: 0 auto;
  margin-top: ${theme.spacing.xxSmall};
  margin-bottom: ${theme.spacing.xxSmall};
`;
