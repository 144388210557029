import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import getSortedCommentsWithReplies from '../utils/getSortedCommentsWithReplies';
import fetch from '../../api/comments/fetch';
import queryKeys from './queryKeys';
import fetchReactionsSuccess from '../../reactions/operations/fetchReactionsSuccess.operation';
import { GENERIC_COMMENTS_FETCH } from '../../constants/actions';
import { ActionStatus } from '../../constants/actionsStatus';
import { isLocalId } from '../../utils/localId';
import { CommentEntityType } from '../../utils/commentsEntityTypes';

const getCommentsAction = (status: ActionStatus, payload: unknown) => ({
  type: GENERIC_COMMENTS_FETCH,
  status,
  payload,
});

const useGetComments = (
  entityType: CommentEntityType,
  entityId: string,
  until: string | null = null,
  disableFetching = false
) => {
  const dispatch = useDispatch();
  const [isDispatched, setDispatched] = useState(false);

  return useQuery(
    queryKeys.comments(entityType, entityId),
    () => fetch({ entityType, entityId, until }),
    {
      enabled:
        !!entityType && !!entityId && !disableFetching && !isLocalId(entityId),
      refetchOnWindowFocus: false,
      onSuccess: ({ rawComments, reactions }) => {
        setDispatched(false);
        dispatch(
          getCommentsAction('success', {
            entityId,
            entityType,
            comments: rawComments,
          })
        );
        dispatch(fetchReactionsSuccess(reactions));
        setDispatched(true);
      },
      select: ({ comments, reactions }) => ({
        commentsIds: comments.filter(({ root }) => !root).map(({ id }) => id),
        comments: getSortedCommentsWithReplies(comments),
        rawComments: comments,
        reactions,
        isDispatched,
      }),
      onError: (error) => {
        dispatch({
          ...getCommentsAction('error', error),
          error,
        });
      },
    }
  );
};

export default useGetComments;
