import differenceInSeconds from 'date-fns/differenceInSeconds';
import parseISO from 'date-fns/parseISO';

export const STATUS = {
  upcoming: 'upcoming',
  open: 'open',
  pastDueDate: 'pastDueDate',
  nearDueDate: 'nearDueDate',
  closed: 'closed',
};

export const getStatus = ({
  startDate,
  endDate,
  status,
  shouldCloseOnDueDate,
}) => {
  const now = new Date();
  const parsedStart = parseISO(startDate);
  const parsedEnd = parseISO(endDate);
  const diffStartInSeconds = differenceInSeconds(parsedStart, now);
  const diffDueInSeconds = differenceInSeconds(parsedEnd, now);

  if (status === STATUS.closed) {
    return status;
  }

  if (!startDate || !endDate) {
    return null;
  }

  if (diffStartInSeconds > 0) {
    return STATUS.upcoming;
  }

  if (diffDueInSeconds > 604800) {
    // equal to 7 days
    return STATUS.open;
  }

  if (diffDueInSeconds > 0) {
    return STATUS.nearDueDate;
  }

  if (shouldCloseOnDueDate) {
    return STATUS.closed;
  }

  return STATUS.pastDueDate;
};

export const getGradeAndSubmittedProps = (isEducator, { submission }) => {
  if (isEducator) {
    return {};
  }
  if (submission && submission.length) {
    return {
      grade: submission[0].displayGrade,
      isSubmitted: true,
    };
  }
  return {};
};

export const includeSearchQuery =
  (searchQuery) =>
  ({ title }) => {
    if (!searchQuery || typeof searchQuery !== 'string') {
      return true;
    }

    return title.toLowerCase().includes(searchQuery.toLowerCase());
  };

export const hasProvider = (assignment) => !!assignment.provider;
