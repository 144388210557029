import PropTypes from 'prop-types';
import React from 'react';
import { isLocalId as isPending } from '@core/utils/localId';
import MessageMenu from './MessageMenu';
import MessageView from './MessageCell.container';
import Reactions from '../../Reactions/Reactions.container';

import { Container, MessageContainer } from './Message.styled';
import Quote from './Quote.container';

const Message = ({
  currentUserId,
  messageId,
  openEditMessage,
  replyToMessage,
  writerId,
  failed,
  repliedMessage,
  firstMessage,
  toggleReaction,
  openEmojiPicker,
  closeEmojiPicker,
  onRepliedMessageClicked,
  highlighted,
}) => (
  <Container highlighted={highlighted}>
    <MessageContainer pending={isPending(messageId)} failed={failed}>
      {repliedMessage && (
        <Quote
          firstMessage={firstMessage}
          onClick={() => onRepliedMessageClicked(repliedMessage.id)}
          {...repliedMessage}
        />
      )}
      <MessageView messageId={messageId} />
      <Reactions
        itemId={messageId}
        toggleReaction={toggleReaction}
        hideWhenNoReactions
        style={{
          paddingTop: 8,
          paddingLeft: 53,
        }}
        openEmojiPicker={() => openEmojiPicker({ messageId })}
        closeEmojiPicker={closeEmojiPicker}
      />
    </MessageContainer>
    <MessageMenu
      messageId={messageId}
      editMessage={currentUserId === writerId ? openEditMessage : undefined}
      replyToMessage={replyToMessage}
      openEmojiPicker={openEmojiPicker}
    />
  </Container>
);

Message.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  createdAt: PropTypes.string.isRequired, // used for change detection only
  currentUserId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  openEditMessage: PropTypes.func.isRequired,
  replyToMessage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  updatedAt: PropTypes.string, // used for change detection only
  writerId: PropTypes.string.isRequired,
  failed: PropTypes.bool,
  firstMessage: PropTypes.bool,
  repliedMessage: PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    writer: PropTypes.string.isRequired,
  }),
  toggleReaction: PropTypes.func.isRequired,
  openEmojiPicker: PropTypes.func.isRequired,
  closeEmojiPicker: PropTypes.func.isRequired,
  onRepliedMessageClicked: PropTypes.func.isRequired,
  highlighted: PropTypes.bool.isRequired,
};

Message.defaultProps = {
  updatedAt: undefined,
  failed: false,
  repliedMessage: null,
  firstMessage: false,
};

export default React.memo(Message);
