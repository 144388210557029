import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import { SubmissionsContent } from '../Submissions.styled';

export const AssignmentDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  ${SubmissionsContent} & {
    margin-bottom: 35px;
    margin-right: ${theme.spacing.large};
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10%;
`;

export const FirstRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 35px;
`;

export const DetailColumnn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailTitle = styled.span`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.spacing.small};
  color: ${theme.color.blackAlpha(0.6)};
  line-height: 16px;
`;

export const DetailInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${theme.font.size.copy};
  color: ${theme.color.blackAlpha(0.87)};
  line-height: 16px;
  margin-left: -5px;
  > span {
    margin-left: 5px;
  }
`;

export const EditedTag = styled.span`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.small};
  display: inline-block;
`;

export const SubmissionDetails = styled.span`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.spacing.medium};
  color: ${theme.color.black};
  line-height: ${theme.font.lineHeight.copy};
  transform: translate(0, 4px);

  span {
    font-weight: ${theme.font.weight.regular};
  }
`;
