import styled from 'react-emotion';
import { styleUtils, theme } from '@ublend-npm/aulaui-next';

export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  min-width: 300px;
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.color.white};
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
`;

export const EditorWrapper = styled('div')`
  flex: 1;
  width: 100%;
  min-width: 0;
  padding: 5px 20px 5px 12px;
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.color.grey3};
  :focus-within {
    ${styleUtils.focusOutline}
  }
`;

export const AvatarWrapper = styled('div')`
  margin-right: ${theme.spacing.medium};
`;
