import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  objectId: PropTypes.string,
  title: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  space: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  gradeType: PropTypes.string,

  isAnonymised: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,

  shouldCloseOnDueDate: PropTypes.bool,

  description: PropTypes.string,
  externalId: PropTypes.string,
  resourceLinkId: PropTypes.string,
  gradesReleasedAt: PropTypes.string,
  maxGrade: PropTypes.number,

  parentAssignmentId: PropTypes.string,
  students: PropTypes.arrayOf(PropTypes.string),
  classRoom: PropTypes.shape({
    objectId: PropTypes.string,
  }),
});
