import React from 'react';
import PropTypes from 'prop-types';
import {
  GroupsSummaryDescription,
  GroupsSummaryTitle,
} from './GroupsSelectionSummary.styled';
import { SpaceUserType } from '../../ExtensionModal.types';

const GroupsSelectionSummary = ({ students }) => {
  if (!students.length) {
    return null;
  }

  return (
    <>
      <GroupsSummaryTitle>
        These students will be added to this extension:
      </GroupsSummaryTitle>
      <GroupsSummaryDescription>{students.join(', ')}</GroupsSummaryDescription>
    </>
  );
};

GroupsSelectionSummary.propTypes = {
  students: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)).isRequired,
};

export default React.memo(GroupsSelectionSummary);
