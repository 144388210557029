import React from 'react';
import PropTypes from 'prop-types';

import { Container, Body, pointer } from './EmptyMaterialList.styled';

const getMessage = (isInstructor) =>
  isInstructor
    ? 'Sections and pages will appear here. Start by adding a new page or by importing pre-existing materials.'
    : 'Sections and pages will appear here';

const EmptyMaterialList = ({ isInstructor }) => (
  <Container id="no-materials">
    {isInstructor && (
      <span className={pointer} role="img" aria-label="Point up">
        👆
      </span>
    )}
    <Body>{getMessage(isInstructor)}</Body>
  </Container>
);

EmptyMaterialList.propTypes = {
  isInstructor: PropTypes.bool.isRequired,
};

export default EmptyMaterialList;
