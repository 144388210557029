import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { editSpacePost } from '../../../../../core/actions/space/posts';
import editComment from '../../../../../core/actions/comment/update';
import EditContent from './Edit';
import { isInstructor } from '../../../../../core/selectors/classroom';
import AulaEditorWithAnalytics from '../AulaEditor/AulaEditorWithAnalytics';
import AulaEditorWithAnalyticsWithoutPlugins from '../AulaEditor/AulaEditorWithAnalyticsWithoutPlugins';
import { currentUserId as getCurrentUserId } from '../../../../../core/selectors/user';
import generateEditorConfig from '../../ClassRoom/generateEditorConfig';

const getClassRooms = (state) => state.data.classRooms;
const classRoomsSelector = createSelector(getClassRooms, (classRooms) =>
  Object.keys(classRooms).map((x) => ({ ...classRooms[x] }))
);

const mapStateToProps = (state, ownProps) => {
  const currentUserId = getCurrentUserId(state);
  const isEducator = isInstructor(state, currentUserId);

  return {
    classRooms: classRoomsSelector(state),
    isSaving: false,
    editorConfig: generateEditorConfig({
      state,
      itemId: ownProps.itemId,
      itemType: ownProps.itemType,
    }),
    EditorComponent: isEducator
      ? AulaEditorWithAnalytics
      : AulaEditorWithAnalyticsWithoutPlugins,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateContent: (content) => {
    let result;
    if (ownProps.itemClass === 'UBClassRoomPost') {
      result = dispatch(
        editSpacePost({
          postId: ownProps.itemId,
          content: JSON.stringify(content),
        })
      );
    } else if (ownProps.itemClass === 'UBComment') {
      result = dispatch(editComment(JSON.stringify(content), ownProps.itemId));
    }

    if (!result) {
      return Promise.error();
    }

    return result;
  },
});

const EditContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContent);

export default EditContentContainer;
