import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const BackgroundContainer = styled<{ background?: string }, 'div'>(
  'div'
)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.color.white};
  background: ${({ background }) =>
    background
      ? `${theme.color.white} url(${background}) top right no-repeat`
      : theme.color.white};
  background-size: contain;
  width: 493px;
  min-height: 340px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
  border-radius: 8px;
  @@ -75,30 +70,8 @@ export const Content = styled<
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Footer = styled('div')`
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding-bottom: 36px;
  padding-top: 50px;
`;

export const Title = styled('div')`
  color: #31405c;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
`;

export const SubTitle = styled('div')`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 12px;
`;
