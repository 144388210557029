import styled from 'react-emotion';
import Clear from '@material-ui/icons/Clear';
import { theme, Scrollbar } from '@ublend-npm/aulaui-next';

const searchContentContainer = 470;

export const Container = styled.div`
  position: absolute;
  top: ${(props) => (props.hasResults ? '10%' : '100px')};
  bottom: ${(props) => (props.hasResults ? '10%' : 'inherit')};
  margin-left: -360px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 720px;
  max-height: ${(props) => searchContentContainer + props.headerHeight}px;
  background-color: ${theme.color.white};
  border-radius: ${theme.borderRadius.medium};
  overflow: hidden;
`;

export const SearchBoxFooterWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing.small} ${theme.spacing.large};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing.medium};
  width: 100%;
  box-sizing: border-box;
`;

export const ContentContainer = styled.div`
  background-color: ${theme.color.beige};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${searchContentContainer}px;
  transition: height 0.75s linear;
`;

export const SearchResultsContainer = styled(Scrollbar)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: ${theme.spacing.xxSmall} 0px;
  background-color: ${theme.color.beige};
`;

export const ResultsCountLabel = styled.div`
  margin: ${theme.spacing.small} ${theme.spacing.xSmall} 0px
    ${theme.spacing.small};
  min-height: ${theme.spacing.large};
  font-size: ${theme.font.size.small};
  color: ${theme.color.grey11};
`;

export const CloseIcon = styled(Clear)`
  color: ${theme.color.grey11};
  cursor: pointer;
  transition: all ${theme.transition.speed.fast} linear;
  &:hover {
    opacity: 0.4;
  }
`;

export const ReachedEnd = styled.span`
  margin: 28px auto;
  color: ${theme.color.grey11};
  font-size: ${theme.font.size.copy};
  display: table;
`;

export const EmptyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${theme.spacing.xxxLarge} auto ${theme.spacing.xxxxLarge} auto;
  width: 60%;
`;

export const EmptyContentImage = styled.img`
  width: 220px;
  height: 220px;
`;

export const EmptyContentTitle = styled.h3`
  color: ${theme.color.mediumBlue};
  font-size: ${theme.font.size.large};
  font-weight: ${theme.font.weight.medium};
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.xSmall};
  display: inline-block;
`;

export const LoaderWrapper = styled.div`
  display: table;
  color: ${theme.color.green};
  padding: ${theme.spacing.medium};
  margin: 0px auto;
`;

export const CloseIconBox = styled('div')`
  margin-left: ${theme.spacing.small};
  display: flex;
  justify-content: center;
  align-items: center;
`;
