import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@ublend-npm/aulaui-next';

import Button from './EditedFieldsWarning.styled';

const propertiesToCheck = {
  description: 'Assignment description',
  startDate: 'Open date',
  endDate: 'Due date',
  gradesReleasedAt: 'Grades release date',
  isHidden: 'Visibility settings',
};

export const getEditedFields = (oldData, newData) => {
  return Object.keys(propertiesToCheck).reduce((reduced, fieldName) => {
    if (oldData[fieldName] !== newData[fieldName]) {
      return { ...reduced, [fieldName]: propertiesToCheck[fieldName] };
    }
    return reduced;
  }, {});
};
export const getEditedHelperText = (field, editedFields) => {
  return editedFields[field] ? 'Edited' : '';
};

const EditedFieldsWarning = ({ editedFields, onRestoreData }) => {
  const editedFieldsNames = Object.keys(editedFields);

  if (editedFieldsNames.length === 0) {
    return (
      <Alert type="info">
        You haven&#39;t edited any assignment properties yet.
      </Alert>
    );
  }

  return (
    <Alert type="info">
      <div>
        <span>Properties edited: </span>
        <span>
          {editedFieldsNames
            .map((fieldName) => propertiesToCheck[fieldName])
            .join(', ')}
        </span>
      </div>
      <Button type="text" size="small" onClick={onRestoreData}>
        Restore original properties
      </Button>
    </Alert>
  );
};

EditedFieldsWarning.propTypes = {
  editedFields: PropTypes.shape({}).isRequired,
  onRestoreData: PropTypes.func.isRequired,
};

export default React.memo(EditedFieldsWarning);
