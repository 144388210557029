import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import InboxCont from './Inbox.container';
import InboxContV4 from './InboxV4.container';

const InboxContainer = (props) => {
  const { webAppFtMuiV6MessagePageSidebar202412 } = useFlags();
  // Conditionally render the appropriate component based on the feature flag
  // Pass down all received props so the children components can access them
  return webAppFtMuiV6MessagePageSidebar202412 ? (
    // eslint-disable-next-line react/jsx-filename-extension
    <InboxCont {...props} />
  ) : (
    <InboxContV4 {...props} />
  );
};

export default InboxContainer;
