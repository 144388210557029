import styled from 'react-emotion';

import { theme } from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  padding: ${theme.spacing.medium} ${theme.spacing.xLarge};
  flex-grow: 2;
  overflow: auto;
`;

export const RootCentered = styled('div')`
  padding: ${theme.spacing.medium} ${theme.spacing.xLarge};
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
