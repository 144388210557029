import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import SearchRounded from '@mui/icons-material/SearchRounded';
import User from '../User/User';

export const SearchIcon = styled(SearchRounded)`
  font-size: 18px;
  color: ${theme.color.designSystem.blackOpacity60};
`;

export const SearchBarItem = styled(User)`
  padding: 12px;
  background: transparent;
`;
