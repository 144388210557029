import { connect } from 'react-redux';
import SearchBar from './SearchBar';
import { operations, selectors } from '../../../../../../store/assignments';

const mapStateToProps = (state) => ({
  query: selectors.getAssignmentQuery(state),
});
const mapDispatchToProps = (dispatch) => ({
  search: operations.search(dispatch),
});

const SearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);

export default SearchBarContainer;
