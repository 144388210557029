import styled from 'react-emotion';
import { Reply, Clear } from '@mui/icons-material';
import { theme } from '@ublend-npm/aulaui-next';

export const ReplyContainer = styled('div')`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(239, 239, 239);
  padding-bottom: 9px;
`;

export const RepliedMessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export const ReplyMessage = styled('div')`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.color.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
`;

export const Author = styled('div')`
  color: ${theme.color.mediumBlue};
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
`;

export const Container = styled('div')`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 30px;
`;

export const ReplyIcon = styled(Reply)`
  color: ${theme.color.mediumBlue};
  padding-right: 15px;
  height: 24px;
  width: 24px;
`;

export const ClearIcon = styled(Clear)`
  color: ${theme.color.purple3};
  cursor: pointer;
  padding-right: 15px;
  height: 24px;
  width: 24px;
`;
