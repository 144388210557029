import { API_URL, FILE_URL } from '../../../../core/constants/endpoints';
import { isInstructor } from '../../../../core/selectors/classroom';
import { getMaterialPlacementLtiProviders } from '../../../../core/selectors/lti';

const generateEditorConfig = ({ state, itemType, itemId }) => {
  const spaceId = state.classRoom?.current;
  const accessToken = state.accessTokens[spaceId]?.token;
  const { institution } = state.institution?.general;
  const isEducator = isInstructor(state, state.user?.userId);

  return {
    itemId,
    accessToken,
    spaceId,
    institution,
    filesUrl: FILE_URL(),
    apiUrl: API_URL(),
    userId: state.user?.userId,
    isEducator,
    ltiProviders: getMaterialPlacementLtiProviders(state),
    itemType,
  };
};

export default generateEditorConfig;
