import { convertLegacyContent } from '@ublend-npm/aula-editor';
import * as selectors from '../../../core/materials/selectors';
import editMaterial from '../../../core/materials/operations/editMaterial.operation';
import setEditing from '../../../core/materials/operations/setEditing.operation';

const isLegacyContent = (content) => !!content.blocks;

const ensureConverted = (content) =>
  isLegacyContent(content) ? convertLegacyContent(content) : content;

const appendContent = (oldContent, newContent) => {
  return {
    ...oldContent,
    context: {
      ...oldContent.context,
      entities: {
        ...oldContent.context.entities,
        ...newContent.context.entities,
      },
    },
    value: [...oldContent.value, ...newContent.value],
  };
};

const importMaterialPage =
  (sectionId, importingContent) => async (dispatch, getState) => {
    const state = getState();
    const currentMaterial = {
      ...selectors.getMaterial(state, sectionId),
      content: selectors.selectedContent(state),
    };
    const currentContent = ensureConverted(currentMaterial.content);
    const contentToAppend = ensureConverted(
      importingContent.material[0].content
    );

    const updatedMaterial = {
      ...currentMaterial,
      content: appendContent(currentContent, contentToAppend),
    };
    dispatch(setEditing(true));
    dispatch(editMaterial(updatedMaterial));
  };

export default importMaterialPage;
