import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const alignCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled('div')`
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
`;

export const BarContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconBox = styled('div')`
  width: 32px;
  height: 32px;
  ${alignCenter};
  color: ${theme.color.grayDark};
  cursor: pointer;
`;

export const LeftSide = styled('div')`
  ${alignCenter};
  flex-grow: 1;
  justify-content: flex-start;
`;

export const SearchInput = styled('input')`
  border: none;
  font-size: 14px;
  margin-left: 8px;
  width: 100%;
  background: transparent;
  padding: 4px;
  border-bottom: 1px solid ${theme.color.grayDark};
  font-family: ${theme.font.family.workSans};

  &::placeholder {
    color: ${theme.color.grayDark};
  }
  &:focus {
    outline: none;
  }
`;
