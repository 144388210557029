const getStudentsByGroups = (selectedGroups = [], spaceGroups = []) => {
  return selectedGroups.reduce((reduced, groupValue) => {
    const group = spaceGroups.find(({ value }) => value === groupValue);

    if (group) {
      return [
        ...reduced,
        ...group.students.map(
          ({ firstName, lastName }) => `${firstName} ${lastName}`
        ),
      ];
    }
    return [];
  }, []);
};

export default getStudentsByGroups;
