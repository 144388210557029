import React from 'react';
import styled from 'react-emotion';

import BaseTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorIcon from '@material-ui/icons/Error';

import { theme } from '@ublend-npm/aulaui-next';

const { font, color } = theme;

export const errorIconStyle = {
  color: color.designSystem.error,
  height: '22px',
  width: '22px',
};

const TextField = styled(
  ({ shrinkLabel, useErrorIcon, startAdornment, endAdornment, ...props }) => (
    <BaseTextField
      margin="dense"
      variant="outlined"
      {...props}
      InputProps={{
        classes: {
          input: 'fieldset-textfield-input',
          focused: 'fieldset-textfield-focusedInput',
          error: 'fieldset-textfield-error',
        },
        endAdornment:
          props.error && useErrorIcon ? (
            <InputAdornment position="end">
              <ErrorIcon style={errorIconStyle} />
            </InputAdornment>
          ) : (
            endAdornment
          ),
        startAdornment,
      }}
      InputLabelProps={{
        shrink: shrinkLabel,
        classes: {
          root: 'fieldset-textfield-label',
          shrink: 'fieldset-textfield-shrunkLabel',
          focused: 'fieldset-textfield-focusedLabel',
          error: 'fieldset-textfield-errorLabel',
        },
      }}
    />
  )
)`
  display: flex;
  flex: 1;
  & .fieldset-textfield-input {
    font-family: ${font.family.workSans};
    font-size: 14px;
    border-color: ${color.grey9};
  }
  & .fieldset-textfield-label {
    font-family: ${font.family.workSans};
    font-size: 14px;
    transform: translate(14px, 11px) scale(1);
  }
  & .fieldset-textfield-focusedInput {
    & fieldset {
      border-color: ${color.purple} !important;
    }
  }
  & .fieldset-textfield-error {
    & fieldset {
      border: 2px solid ${color.designSystem.error} !important;
    }
  }
  & .fieldset-textfield-focusedLabel {
    font-family: ${font.family.workSans};
    color: ${color.purple};
  }
  & .fieldset-textfield-shrunkLabel {
    transform: translate(14px, -6px) scale(0.75);
  }
  & .fieldset-textfield-errorLabel {
    color: ${color.designSystem.error} !important;
  }
`;

export default React.memo(TextField);
