import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { hideVisually } from 'polished';
import { Container, Input, SearchIcon, ClearButton } from './SearchBox.styled';

class SearchBox extends PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = undefined;

    this.state = {
      isClearSearch: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputClear = this.handleInputClear.bind(this);
    this.handleInputRef = this.handleInputRef.bind(this);
  }

  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  handleInputChange(event) {
    const { onEnter, onChange } = this.props;
    if (event.key === 'Enter') {
      onEnter();
      return;
    }

    onChange(event);
  }

  handleInputClear() {
    const { onClear } = this.props;
    onClear();
    if (this.inputRef) {
      this.inputRef.focus();
    }

    this.setState({ isClearSearch: true });
  }

  handleInputRef(ref) {
    const { inputRef } = this.props;
    this.inputRef = ref;
    if (this.props) {
      inputRef(ref);
    }
  }

  render() {
    const { placeholder, value } = this.props;
    const { isClearSearch } = this.state;
    return (
      <Container>
        <SearchIcon />
        <Input
          aria-label={placeholder}
          innerRef={this.handleInputRef}
          placeholder={placeholder}
          value={value}
          onChange={this.handleInputChange}
        />
        {value && (
          <ClearButton
            role="button"
            aria-label="Clear search text"
            tabIndex={0}
            onClick={this.handleInputClear}
          >
            Clear
          </ClearButton>
        )}
        <p style={{ ...hideVisually() }} aria-live="polite">
          {!value && isClearSearch ? 'Search text was cleared' : ''}
        </p>
      </Container>
    );
  }
}

SearchBox.propTypes = {
  /**
   * Text to show when the field is empty
   */
  placeholder: PropTypes.string,
  /**
   * Text to show in the input field
   */
  value: PropTypes.string,
  /**
   * Called at every input onChange
   */
  onChange: PropTypes.func,
  /**
   * Called when the user types the return key
   */
  onEnter: PropTypes.func,
  /**
   * Called when user clicks on the clear button
   */
  onClear: PropTypes.func,
  /**
   * Called when component mounts
   */
  onMount: PropTypes.func,
  /**
   * Passes the input ref
   */
  inputRef: PropTypes.func,
};

SearchBox.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => undefined,
  onEnter: () => undefined,
  onClear: () => undefined,
  onMount: () => undefined,
  inputRef: () => undefined,
};

export default SearchBox;
