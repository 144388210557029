import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { currentUserId } from '../../../../../core/selectors/user';
import {
  operations as creators,
  selectors as inboxSelectors,
} from '../../../../store/conversations';
import * as appCreators from '../../../../actions/inboxv2';

import InboxMenu from './InboxMenu';

const countUnreadConversations = createSelector(
  (state) => state,
  inboxSelectors.conversations,
  (state) => currentUserId(state),
  inboxSelectors.countUnreadConversations
);

const mapStateToProps = createStructuredSelector({
  conversationIds: inboxSelectors.visibleConversationsIds,
  totalCount: inboxSelectors.totalCount,
  fetchingConversations: (state) => state.inboxv2.fetchingConversations,
  totalUnreadConversations: countUnreadConversations,
});

const mapDispatchToProps = (dispatch) => ({
  fetchConversations: (includeClosed, page) =>
    dispatch(creators.fetchConversations(includeClosed, page)),
  filterConversations: (filterValue) =>
    dispatch(creators.filterConversations(filterValue)),
  openConversation: (conversationId) =>
    dispatch(appCreators.selectConversation(conversationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InboxMenu);
