import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Add from '@mui/icons-material/Add';
import { Tooltip } from '@ublend-npm/aulaui-next';
import MaterialListItem from '../MaterialList/MaterialListItem.container';
import MaterialMenu from '../MaterialList/MaterialMenu.container';
import RenameFolderDialog from '../MaterialList/RenameFolderDialog.container';
import DeleteFolderDialog from '../MaterialList/DeleteFolderDialog.container';
import EmptyMaterialList from '../MaterialList/EmptyMaterialList';
import {
  AddButton,
  MaterialListTitle,
  MaterialListWrapper,
  MaterialListScrollWrapper,
  MaterialListItemsWrapper,
} from './MaterialListEducatorOnly.styled';
import ScrollBar from '../../../common/ScrollBar';
import RestoreMaterialDrawer from '../MaterialList/RestoreMaterialDrawer.container';

const MaterialListEducatorOnly = ({
  classRoomId,
  onAddClick,
  materialEducatorOnlyIds,
  isFetchingSections,
  isReadOnlyUser,
}) => {
  const [restoring, setRestoring] = useState(false);

  return (
    <MaterialListWrapper>
      <ScrollBar autoHide>
        <MaterialListScrollWrapper>
          <MaterialListTitle
            component="div"
            title="Materials"
            testID="menu-materials"
          >
            {!isReadOnlyUser && (
              <React.Fragment>
                <MaterialMenu
                  educatorOnly
                  onRestorePage={() => setRestoring(true)}
                />
                <RestoreMaterialDrawer
                  classRoomId={classRoomId}
                  educatorArea
                  open={restoring}
                  onClose={() => setRestoring(false)}
                />
                <Tooltip title="New page" space={4} position="right">
                  <AddButton
                    type="secondary"
                    size="xSmall"
                    icon={Add}
                    label="Create page"
                    onClick={onAddClick}
                  />
                </Tooltip>
              </React.Fragment>
            )}
          </MaterialListTitle>
          <MaterialListItemsWrapper>
            {materialEducatorOnlyIds.length || isFetchingSections ? (
              materialEducatorOnlyIds.map((id, index) => (
                <MaterialListItem
                  key={id}
                  materialId={id}
                  educatorOnly
                  data-testid={`educator-material-${index + 1}`}
                />
              ))
            ) : (
              <EmptyMaterialList isInstructor />
            )}
          </MaterialListItemsWrapper>

          <RenameFolderDialog />
          <DeleteFolderDialog educatorOnly />
        </MaterialListScrollWrapper>
      </ScrollBar>
    </MaterialListWrapper>
  );
};

MaterialListEducatorOnly.propTypes = {
  classRoomId: PropTypes.string.isRequired,
  onAddClick: PropTypes.func.isRequired,
  materialEducatorOnlyIds: PropTypes.arrayOf(PropTypes.string),
  isFetchingSections: PropTypes.bool.isRequired,
  isReadOnlyUser: PropTypes.bool.isRequired,
};

MaterialListEducatorOnly.defaultProps = {
  materialEducatorOnlyIds: [],
};

export default React.memo(MaterialListEducatorOnly);
