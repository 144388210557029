import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from '@material-ui/core/Radio';

import { Wrapper } from './Radio.styled';

const Radio = ({
  isSelected,
  handleChange,
  label,
  value,
  color,
  name,
  ariaLabel,
  style,
  'data-testid': dataTestId,
}) => {
  return (
    <Wrapper
      value={value}
      label={label}
      data-testid={dataTestId}
      control={
        <RadioButton
          checked={isSelected}
          onChange={handleChange}
          color={color}
          name={name}
          inputProps={{ 'aria-label': ariaLabel }}
          size="medium"
          style={{ padding: '0', ...style }}
          classes={{ checked: 'selected' }}
        />
      }
    />
  );
};

Radio.propTypes = {
  color: PropTypes.string,
  style: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  ariaLabel: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
};

Radio.defaultProps = {
  color: 'default',
  style: {},
  'data-testid': undefined,
};

export default Radio;
