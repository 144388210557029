import { theme } from '@ublend-npm/aulaui-next';
import styled from 'react-emotion';

type CommentListProps = {
  collapsed?: boolean;
  isReply?: boolean;
};

export const ToggleContainer = styled<CommentListProps, 'div'>('div')`
  height: 45px;
  line-height: 45px;
  color: ${theme.color.designSystem.purple70};
  cursor: pointer;
  transition: opacity 0.2s;
  width: ${({ collapsed }) => (collapsed ? '100%' : 'calc(100% + 72px)')};
  margin-left: ${({ collapsed }) =>
    collapsed ? '0' : `-${theme.spacing.xxxLarge}`};
  background-color: ${({ collapsed }) =>
    collapsed ? 'transparent' : theme.color.grayLight};
  :hover {
    opacity: 0.75;
  }
`;

export const LoadMoreTextWrapper = styled('span')`
  color: ${theme.color.designSystem.purple70};
  font-size: 14px;
  font-weight: ${theme.font.weight.semiBold};
  cursor: pointer;
`;

export const HideCommentsWrapper = styled('span')`
  font-size: 14px;
  color: ${theme.color.grey6};
  padding-left: ${theme.spacing.xxxLarge};
`;

export const ShowCommentsWrapper = styled('span')`
  font-size: 14px;
  font-weight: ${theme.font.weight.semiBold};
`;

export const ShowCommentsCarretWrapper = styled('span')`
  display: inline-block;
  height: 10px;
  width: 10px;
`;

export const ShowCommentsContainer = styled('span')`
  display: flex;
  justify-content: space-between;
`;

export const ShowCommentsCarret = styled<CommentListProps, 'div'>('div')`
  width: 5px;
  height: 5px;
  margin-left: 8px;
  border-left: 2px solid ${theme.color.designSystem.purple70};
  border-bottom: 2px solid ${theme.color.designSystem.purple70};
  transition: all 0.3s;
  border-color: ${({ collapsed }) =>
    collapsed ? theme.color.designSystem.purple70 : theme.color.grey6};
  transform: ${({ collapsed }) =>
    collapsed ? 'rotate(-45deg)' : 'rotate(-225deg)'};
  margin-top: ${({ collapsed }) => (collapsed ? '0' : '5px')};
`;

export const LoadMoreContainer = styled<CommentListProps, 'div'>('div')`
  margin: 20px 0;
  width: calc(100% - ${({ isReply }) => (isReply ? '20px' : '27px')});
  padding: 0 ${({ isReply }) => (isReply ? '20px' : '27px')};
`;

export const CommentListContainer = styled<CommentListProps, 'div'>('div')`
  width: 100%;
  transition: height 0.5s;
  padding-left: ${({ isReply }) => (isReply ? '45px' : '0')};
`;

type CommentListWrapperProps = {
  commentsCount: number;
};

export const CommentListWrapper = styled<CommentListWrapperProps, 'div'>('div')`
  width: 100%;
  float: left;
  display: ${({ commentsCount }) => (commentsCount ? 'block' : 'none')};
`;

export const CommentWrapper = styled<CommentListProps, 'div'>('div')`
  width: calc(100% - ${({ isReply }) => (isReply ? '45px' : '0')});
`;

export const Spacer = styled('div')`
  height: 22px;
`;
