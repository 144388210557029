import PropTypes from 'prop-types';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { styleUtils, Tooltip } from '@ublend-npm/aulaui-next';
import ScheduledItemHeader from '../../../common/ScheduledItemHeader';

import {
  BUTTON_SAVE,
  MATERIAL_EDIT,
  MATERIAL_UNHIDE_TOOLTIP,
  MATERIAL_SCHEDULE_TOOLTIP,
  MATERIAL_PUBLISH,
} from '../../../../../constants/texts';
import {
  Container,
  Button,
  ActionsContainer,
  PageDetailsContainer,
  IconButton,
  VisibilityOffIcon,
} from './ActionBar.styled';
import SectionViewActionMenu from './SectionViewActionMenu';

const renderHiddenPageInfo = () => (
  <PageDetailsContainer>
    <VisibilityOffIcon />
    This page is hidden
  </PageDetailsContainer>
);

const renderScheduledPageInfo = (scheduledFor, items, saving) => (
  <ScheduledItemHeader
    scheduledFor={scheduledFor}
    items={items}
    disabled={saving}
  />
);

const renderPageInfo = ({ hidden, scheduledFor, items, saving }) => {
  if (hidden && scheduledFor) {
    return renderScheduledPageInfo(new Date(scheduledFor), items, saving);
  }
  if (hidden) {
    return renderHiddenPageInfo();
  }
  return null;
};

const ActionBar = ({
  onUnhide,
  onSave,
  onEdit,
  onDeleteSection,
  onHideSection,
  onFindPreviousVersion,
  onImportMaterialPage,
  onExportMaterialPage,
  showUnhide,
  showSave,
  showEdit,
  canSave,
  canFindPreviousVersion,
  canExportMaterialPage,
  a11yMessage,
  saving,
  openScheduleDialog,
  onKeepHidden,
  scheduledFor,
  educatorOnly,
}) => {
  const items = [
    {
      label: 'Change scheduled time',
      action: openScheduleDialog,
    },
    {
      label: 'Undo scheduling and keep hidden',
      action: onKeepHidden,
    },
    {
      label: 'Publish now',
      action: onUnhide,
    },
  ];
  return (
    <Container scheduledFor={scheduledFor} hidden={showUnhide}>
      {renderPageInfo({ hidden: showUnhide, scheduledFor, items })}
      <ActionsContainer>
        {showUnhide && !scheduledFor && !educatorOnly && (
          <Tooltip title={MATERIAL_SCHEDULE_TOOLTIP} position="top">
            <div>
              <IconButton
                onClick={openScheduleDialog}
                label="schedule"
                size="small"
                icon={ScheduleIcon}
              />
            </div>
          </Tooltip>
        )}
        {showEdit && (
          <Tooltip title={MATERIAL_EDIT} position="top">
            <div>
              <IconButton
                type="secondary"
                onClick={onEdit}
                label={MATERIAL_EDIT}
                size="small"
                icon={EditIcon}
              />
            </div>
          </Tooltip>
        )}
        {showUnhide && !scheduledFor && (
          <Tooltip title={MATERIAL_UNHIDE_TOOLTIP} position="bottom">
            <div>
              <Button
                type="secondary"
                size="small"
                id="material-publish-button"
                onClick={onUnhide}
                show={showUnhide}
              >
                {MATERIAL_PUBLISH}
              </Button>
            </div>
          </Tooltip>
        )}

        <Button
          type="primary"
          size="small"
          data-testid="material-save-button"
          aria-label="Save material"
          id="material-save-button"
          onClick={onSave}
          show={showSave}
          loading={saving}
          disabled={saving || !canSave}
        >
          {BUTTON_SAVE}
        </Button>
        <p css={[styleUtils.visuallyHidden()]} aria-live="polite">
          {a11yMessage}
        </p>
        <SectionViewActionMenu
          educatorOnly={educatorOnly}
          canFindPreviousVersion={canFindPreviousVersion}
          canExportMaterialPage={canExportMaterialPage}
          onDeleteSection={onDeleteSection}
          onHideSection={!showUnhide ? onHideSection : null}
          onFindPreviousVersion={onFindPreviousVersion}
          onExportMaterialPage={onExportMaterialPage}
          onImportMaterialPage={onImportMaterialPage}
          editing={showEdit}
        />
      </ActionsContainer>
    </Container>
  );
};

ActionBar.propTypes = {
  ...SectionViewActionMenu.propTypes,
  scheduledFor: PropTypes.string,
  canSave: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  showUnhide: PropTypes.bool.isRequired,
  showSave: PropTypes.bool.isRequired,
  showEdit: PropTypes.bool.isRequired,
  a11yMessage: PropTypes.string,
  onUnhide: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onExportMaterialPage: PropTypes.func,
  onImportMaterialPage: PropTypes.func,
  educatorOnly: PropTypes.bool,
  openScheduleDialog: PropTypes.func,
  onKeepHidden: PropTypes.func,
};

ActionBar.defaultProps = {
  ...SectionViewActionMenu.defaultProps,
  onUnhide: () => null,
  onSave: () => null,
  onEdit: () => null,
  onKeepHidden: () => null,
  openScheduleDialog: () => null,
  scheduledFor: null,
};

export default ActionBar;
