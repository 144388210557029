import PropTypes from 'prop-types';
import React from 'react';

import formatDate from '../../../../../../core/utils/formatDate';
import { Container, Info, Text } from './SectionWarning.styled';

const SectionWarning = ({ scheduledFor, hidden }) => {
  if (!hidden) {
    return null;
  }
  const text = scheduledFor
    ? `This page is in a section scheduled for ${formatDate(scheduledFor)}`
    : 'This page is in a hidden section';
  return (
    <Container>
      <Info htmlColor="#0000ff" />
      <Text>{text}</Text>
    </Container>
  );
};

SectionWarning.propTypes = {
  scheduledFor: PropTypes.string,
  hidden: PropTypes.bool,
};

SectionWarning.defaultProps = {
  scheduledFor: undefined,
  hidden: false,
};

export default SectionWarning;
