import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { BarContainer, SearchInput, LeftSide, IconBox } from './Search.styled';

class SearchBar extends PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onChange(e) {
    const { search } = this.props;
    search(e.target.value);
  }

  onClose() {
    const { search, onClose } = this.props;

    search('');
    onClose();
  }

  render() {
    return (
      <BarContainer>
        <LeftSide>
          <IconBox>
            <SearchIcon />
          </IconBox>
          <SearchInput
            onChange={this.onChange}
            autoFocus
            type="text"
            placeholder="Search..."
          />
        </LeftSide>
        <IconBox>
          <CloseIcon onClick={this.onClose} />
        </IconBox>
      </BarContainer>
    );
  }
}

SearchBar.propTypes = {
  search: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SearchBar;
