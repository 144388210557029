import styled from 'react-emotion';

export const Container = styled('div')`
  flex: 1;
`;

export const Separator = styled('div')`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${(props) => props.height};
`;

export const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
