import React from 'react';
import PropTypes from 'prop-types';
import noContent from '../../../../../static/no-page.png';

import EmptyContent from './EmptyContent';

const EmptyPage = ({ isInstructor }) => {
  if (!isInstructor) return null;
  return (
    <EmptyContent
      image={noContent}
      copy="Now that you have started creating sections for your materials, you can use the + button on the left to create your first page."
    />
  );
};

EmptyPage.propTypes = {
  isInstructor: PropTypes.bool.isRequired,
};

export default EmptyPage;
