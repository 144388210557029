import { connect } from 'react-redux';
import { operations as creators } from '../../../../../store/conversations';
import EditMessageModal from './EditMessageModalAulaEditor';

const mapStateToProps = (state) => ({
  message: state.data.inboxv2.messages[state.inboxv2.currentlyEditedMessageId],
  saving: state.inboxv2.editingMessage,
  user: state.user.user,
});

const mapDispatchToProps = {
  save: creators.editMessage,
  close: creators.closeEditMessage,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  close: (...params) => {
    dispatchProps.close(...params);
    ownProps.onClose();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditMessageModal);
