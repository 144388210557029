import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Container, Text, Image } from './NoContent.styled';

import emptyImage from '../../../../../static/Search_Empty.svg';

const NoResults = ({ primaryText, secondaryText }) => {
  const ref = useRef(null);

  return (
    <Container>
      <Image width="162px" src={emptyImage} alt="no_search_results" />
      <Text innerRef={ref} tabIndex={-1}>
        {primaryText}
        <br />
        {secondaryText}
      </Text>
    </Container>
  );
};

NoResults.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
};

NoResults.defaultProps = {
  secondaryText: '',
};

export default NoResults;
