import PropTypes from 'prop-types';
import React from 'react';
import { Menu, MenuItem } from '@ublend-npm/aulaui-next';
import ArrowDropdown from '@material-ui/icons/ArrowDropDown';

import formatDate from '../../../../../core/utils/formatDate';

import {
  ChangeScheduledItemButton,
  ScheduledIcon,
  Container,
} from './ScheduledItemHeader.styled';

const renderMenu = (items, disabled) => (
  <Menu
    ariaLabelledby="menu_feed"
    renderTarget={({ handleTargetClick }) => (
      <ChangeScheduledItemButton
        iconRight={ArrowDropdown}
        size="medium"
        type="primary"
        label=""
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          return handleTargetClick(e);
        }}
        tabIndex={0}
      >
        Change
      </ChangeScheduledItemButton>
    )}
    onSelect={({ item }) => item()}
    renderChildren={({ getItemProps }) =>
      items.map(({ label, action }) => (
        <MenuItem
          key={label}
          size="medium"
          primaryText={label}
          {...getItemProps({
            item: action,
          })}
        />
      ))
    }
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
  />
);

const ScheduledItemHeader = ({ scheduledFor, items, disabled }) => (
  <Container>
    <ScheduledIcon />
    Scheduled for {formatDate(scheduledFor)}
    {renderMenu(items, disabled)}
  </Container>
);

ScheduledItemHeader.propTypes = {
  scheduledFor: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      label: PropTypes.string,
    })
  ).isRequired,
  disabled: PropTypes.bool,
};

ScheduledItemHeader.defaultProps = {
  disabled: false,
};

export default ScheduledItemHeader;
