import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const Container = styled('div')`
  background: ${(props) =>
    props.selected ? theme.color.grey3 : theme.color.beige};
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  max-width: 200px;
  font-weight: 450;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${theme.color.grey11};
`;

export default Container;
