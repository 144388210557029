import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Lti1P3Provider, LtiVersion } from '@ublend-npm/aula-schema';
import { LTI_1P3_START } from '@core/constants/endpoints';
import { currentUser } from '@core/selectors/user';
import { getCurrentSpaceId } from '@core/selectors/space';
import { getLtiProviders } from '@core/selectors/lti';

import { Lti1p3Form } from './Lti1p3Form';
import { lti1p3Start } from './lti1p3Start';
import { LtiRequestType } from './types';

export type Lti1P3LaunchProps = {
  providerId: string;
  resourceLinkId: string;
  resourceLinkTitle?: string;
  assignmentId?: string;
  assignmentFormData?: Record<string, unknown>;
  onSuccessLaunch: () => void;
  onErrorLaunch: (err: Error) => void;
};

export const Lti1p3Launch = ({
  providerId,
  resourceLinkId,
  resourceLinkTitle,
  assignmentId,
  assignmentFormData,
  onSuccessLaunch,
  onErrorLaunch,
}: Lti1P3LaunchProps) => {
  const user = useSelector(currentUser);
  const spaceId = useSelector(getCurrentSpaceId);
  const ltiProviders = useSelector(getLtiProviders);

  const [ltiSessionToken, setLtiSessionToken] = useState<string | undefined>();
  const [shouldLaunch, setShouldLaunch] = useState(true);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { objectId: userId } = user || {};
  const provider = ltiProviders.find(
    (p) =>
      p.id === providerId &&
      p.ltiVersion === LtiVersion.ONE_POINT_THREE &&
      !p.disabled
  ) as Lti1P3Provider | undefined;
  const { loginUrl, targetUrl, iss, clientId, isDeepLinking } = provider || {};
  const type = isDeepLinking
    ? LtiRequestType.DEEP_LINKING
    : LtiRequestType.RESOURCE_LINK;

  useEffect(() => {
    if (shouldLaunch && !ltiSessionToken && userId && clientId) {
      lti1p3Start({
        url: LTI_1P3_START(),
        spaceId,
        clientId,
        type,
        userId,
        resourceLinkId,
        ...(resourceLinkTitle ? { resourceLinkTitle } : {}),
        ...(assignmentId ? { assignmentId } : {}),
        ...(assignmentFormData ? { assignmentFormData } : {}),
      })
        .then((ltiToken) => {
          setLtiSessionToken(ltiToken);
        })
        .catch((err) => {
          onErrorLaunch(err);
        });
    }
  }, [
    shouldLaunch,
    ltiSessionToken,
    userId,
    spaceId,
    clientId,
    type,
    resourceLinkId,
  ]);

  useEffect(() => {
    if (formRef.current && shouldLaunch && ltiSessionToken && clientId) {
      // eslint-disable-next-line no-unused-expressions
      formRef.current.requestSubmit();
      setLtiSessionToken(undefined);
      setShouldLaunch(false);
      onSuccessLaunch();
    }
  }, [shouldLaunch, ltiSessionToken, clientId]);

  if (
    shouldLaunch &&
    ltiSessionToken &&
    clientId &&
    loginUrl &&
    targetUrl &&
    iss
  ) {
    return (
      <Lti1p3Form
        ref={formRef}
        loginUrl={loginUrl}
        targetUrl={targetUrl}
        clientId={clientId}
        iss={iss}
        type={
          isDeepLinking
            ? LtiRequestType.DEEP_LINKING
            : LtiRequestType.RESOURCE_LINK
        }
        loginHint={ltiSessionToken}
        target="_blank"
      />
    );
  }

  return null;
};
