const groupSubmissionsByDate = (submissionsData, dueDate) => {
  const earlySubmissions = [];
  const lateSubmissions = [];
  const noSubmissions = [];

  submissionsData.map((submission) => {
    if (!submission.id) {
      return noSubmissions.push(submission);
    }
    if (new Date(submission.receivedAt) <= new Date(dueDate)) {
      return earlySubmissions.push(submission);
    }

    return lateSubmissions.push(submission);
  });

  return { earlySubmissions, lateSubmissions, noSubmissions };
};

export default groupSubmissionsByDate;
