import styled, { css } from 'react-emotion';

import { theme, Button as BaseButton } from '@ublend-npm/aulaui-next';

const { font, color, spacing } = theme;

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'compact',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  padding-top: ${(props) => (props.compact ? 24 : 36)}px;
  padding-bottom: ${(props) => (props.compact ? 24 : 36)}px;
`;

export const FadedContainer = styled(Container)`
  border-radius: ${spacing.xxSmall};
  background-image: linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0));
`;

export const Icon = styled('div')`
  padding-bottom: ${spacing.xxSmall};
  font-size: ${font.size.display};
`;

export const Text = styled('div')`
  font-size: ${font.size.copy};
  font-family: ${font.family.workSans};
  line-height: ${font.lineHeight.copy};
  padding-bottom: ${spacing.xLarge};
  color: ${color.designSystem.blackOpacity87};
  max-width: 400px;
  margin: auto;
  white-space: pre-line;
  &:focus {
    outline: none;
  }
`;

export const ButtonGroup = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    font-family: ${font.family.workSans};
    font-size: ${font.size.copy};
    font-weight: ${font.weight.semiBold};
    line-height: ${spacing.medium};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  background-color: white;
  border: 1px solid ${color.designSystem.blackOpacity38};
  color: ${color.designSystem.purple70};
  margin-top: ${spacing.xSmall};
  min-width: 232px;

  :hover {
    background-color: ${color.designSystem.background.lightPurple.hover};
  }
  :focus {
    background-color: ${color.designSystem.background.lightPurple.focus};
  }
`;

export const addGroupToSetButtonClass = css`
  background-color: ${color.designSystem.purple70};
  min-width: 232px;

  :hover {
    background-color: ${color.purpleDark};
  }
`;

export const Image = styled('img')`
  margin-bottom: ${spacing.large};
`;

export const ButtonStyles = {
  color: color.designSystem.purple70,
  border: `1px solid ${color.designSystem.blackOpacity38}`,
  background: 'transparent',
  marginLeft: 'auto',
  ':disabled': {
    border: `1px solid ${color.designSystem.blackOpacity38}`,
    color: color.designSystem.blackOpacity38,
    background: 'transparent',
  },
};
