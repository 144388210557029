import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import { cleanState } from '@ublend-npm/aula-editor';

import {
  Alert,
  DateTimePickerDialog,
  OutlinedContainer,
} from '@ublend-npm/aulaui-next';
import PropTypes from 'prop-types';

import {
  HANDIN_PROVIDER,
  TURNITIN_PROVIDER,
} from '@core/assignments/constants';
import {
  Root,
  Form,
  DateField,
  DateFields,
  HiddenIcon,
  SwitchContainer,
  SwitchLabel,
  SwitchTitleContainer,
  TextField,
  TextAreaField,
  Title,
  HandinEditorWrapper,
  AlertContainer,
  HandinHelperText,
} from './Properties.styled';
import EditedFieldsWarning, {
  getEditedFields,
  getEditedHelperText,
} from '../../components/EditedFieldsWarning/EditedFieldsWarning';
import {
  ExtensionModalDataType,
  AssignmentType,
  AssignmentCreationErrorType,
} from '../../ExtensionModal.types';
import {
  CREATING_EXTENSION_OF_EXTENSION_ERROR,
  CREATING_EXTENSION_SERVER_ERROR,
} from '../../../constants';
import AulaEditor from '../../components/AulaEditorInHandin';

const getCustomDatePickerProps = (type, { startDate, endDate }) => {
  switch (type) {
    case 'startDate':
      return {
        maxDate: endDate,
      };
    case 'endDate':
      return {
        minDate: startDate,
      };
    case 'gradesReleasedAt':
      return {
        minDate: endDate,
      };
    default:
      return {};
  }
};

const getDescription = (description) => {
  if (typeof description === 'string') {
    try {
      return JSON.parse(description);
    } catch (error) {
      return description;
    }
  }
  return description;
};

const Properties = ({
  data,
  data: {
    extensionName,
    startDate,
    endDate,
    gradesReleasedAt,
    isHidden,
    description,
    spaceId,
  },
  setData,
  assignment,
  assignment: { provider },
  assignmentCreationError,
}) => {
  const [editingDateType, setEditingDateType] = useState(null);
  const [editorData, setEditorData] = useState(null);

  const editedFields = getEditedFields(assignment, data);
  const handleEditorChange = (editorState) => {
    setData({
      description: JSON.stringify(editorState),
    });
  };

  useEffect(() => {
    setEditorData(data.editorState);
  }, []);
  let errorText;
  let editor;

  if (assignmentCreationError) {
    const isExtensionOfAnExtension =
      assignmentCreationError.errorMessage === 'extension-of-an-extension';
    if (isExtensionOfAnExtension) {
      errorText = CREATING_EXTENSION_OF_EXTENSION_ERROR;
    } else {
      errorText = CREATING_EXTENSION_SERVER_ERROR;
    }
  }

  const restoreOriginalValues = () => {
    setData({
      startDate: assignment.startDate,
      endDate: assignment.endDate,
      gradesReleasedAt: assignment.gradesReleasedAt,
      description: assignment.description,
      isHidden: assignment.isHidden,
    });
    editor.editor.setEditorState(editorData);
  };

  const isTIIAssignment = assignment.provider === TURNITIN_PROVIDER;

  const parsedDescription = getDescription(description);

  return (
    <>
      <Root>
        <Title closeIcon={false}>
          Edit properties for &#39;{extensionName}&#39;
        </Title>
        <Form>
          {provider === HANDIN_PROVIDER && (
            <HandinEditorWrapper>
              <OutlinedContainer
                label="Assignment description"
                id="extension-editor-container"
              >
                <AulaEditor
                  spaceId={spaceId}
                  editorState={parsedDescription || cleanState}
                  onChange={handleEditorChange}
                />
              </OutlinedContainer>
              <HandinHelperText>
                {getEditedHelperText('description', editedFields)}
              </HandinHelperText>
            </HandinEditorWrapper>
          )}

          {provider === TURNITIN_PROVIDER && (
            <TextAreaField
              label="Assignment description"
              value={description}
              multiline
              rows={5}
              maxRows={5}
              onChange={(e) =>
                setData({
                  description: e.target.value,
                })
              }
              helperText={getEditedHelperText('description', editedFields)}
            />
          )}

          <DateFields>
            <DateField onClick={() => setEditingDateType('startDate')}>
              <TextField
                id="Open date"
                label="Open date"
                value={moment(startDate).format('lll')}
                helperText={getEditedHelperText('startDate', editedFields)}
              />
            </DateField>

            <DateField onClick={() => setEditingDateType('endDate')}>
              <TextField
                id="Due date"
                label="Due date"
                value={moment(endDate).format('lll')}
                helperText={getEditedHelperText('endDate', editedFields)}
              />
            </DateField>

            {isTIIAssignment && (
              <DateField onClick={() => setEditingDateType('gradesReleasedAt')}>
                <TextField
                  id="Grades release date"
                  label="Grades release date"
                  value={moment(gradesReleasedAt).format('lll')}
                  helperText={getEditedHelperText(
                    'gradesReleasedAt',
                    editedFields
                  )}
                />
              </DateField>
            )}
          </DateFields>

          <SwitchContainer checked={isHidden}>
            <SwitchTitleContainer>
              <HiddenIcon />
              <SwitchLabel>Hide this extension</SwitchLabel>
            </SwitchTitleContainer>

            <Switch
              checked={isHidden}
              onChange={() =>
                setData({
                  isHidden: !isHidden,
                })
              }
              color="primary"
              inputProps={{ 'aria-label': 'Hide this extension' }}
            />
          </SwitchContainer>

          <EditedFieldsWarning
            editedFields={editedFields}
            onRestoreData={restoreOriginalValues}
          />

          {assignmentCreationError && assignmentCreationError.errorCode && (
            <AlertContainer>
              <Alert type="error">{errorText}</Alert>
            </AlertContainer>
          )}
        </Form>
      </Root>

      <DateTimePickerDialog
        id="extension-dates"
        date={data[editingDateType]}
        open={!!editingDateType}
        onClose={() => setEditingDateType(null)}
        secondaryAction={{
          label: 'Cancel',
          onAction: () => setEditingDateType(null),
        }}
        primaryAction={{
          label: 'Save',
          onAction: (date) =>
            setData({
              [editingDateType]: new Date(date).toISOString(),
            }),
        }}
        DateTimePickerProps={getCustomDatePickerProps(editingDateType, data)}
      />
    </>
  );
};

Properties.propTypes = {
  data: PropTypes.shape(ExtensionModalDataType).isRequired,
  setData: PropTypes.func.isRequired,
  assignment: PropTypes.shape(AssignmentType).isRequired,
  assignmentCreationError: PropTypes.shape(AssignmentCreationErrorType),
};

Properties.defaultProps = {
  assignmentCreationError: null,
};

export default React.memo(Properties);
