import { INBOX_CONVERSATIONS } from '../../../constants/endpoints';
import request from './request';

export default async (includeClosed = false, page = 1) => {
  const config = {
    method: 'get',
    url: INBOX_CONVERSATIONS(includeClosed, page),
  };
  const response = await request(config);
  return response.data;
};
