import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../../../../common/Dialog';
import DiscussionTopicDialogInner from './DiscussionTopicDialogInner';

const DiscussionTopicDialog = ({
  open,
  onClose,
  onSetDiscussionTopic,
  initialTopic,
  canRemoveDiscussion,
  onRemoveDiscussion,
  showDiscussion,
  onToggleShowDiscussion,
}) => (
  <Dialog id="set-discussion-topic" open={open} onClose={onClose} maxWidth="md">
    <DiscussionTopicDialogInner
      onClose={onClose}
      onSetDiscussionTopic={onSetDiscussionTopic}
      initialTopic={initialTopic}
      canRemoveDiscussion={canRemoveDiscussion}
      onRemoveDiscussion={onRemoveDiscussion}
      showDiscussion={showDiscussion}
      onToggleShowDiscussion={onToggleShowDiscussion}
    />
  </Dialog>
);

DiscussionTopicDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetDiscussionTopic: PropTypes.func.isRequired,
  initialTopic: PropTypes.string,
  onRemoveDiscussion: PropTypes.func,
  canRemoveDiscussion: PropTypes.bool,
  showDiscussion: PropTypes.bool,
  onToggleShowDiscussion: PropTypes.func,
};

DiscussionTopicDialog.defaultProps = {
  initialTopic: '',
  canRemoveDiscussion: false,
  onRemoveDiscussion: () => {},
  showDiscussion: true,
  onToggleShowDiscussion: () => {},
};

export default DiscussionTopicDialog;
