import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent } from '@ublend-npm/aulaui-next';
import { HANDIN_PROVIDER } from '@core/assignments/constants';
import User from '../User/User';
import StudentContainer from './ExistingSubmissionErrorDialog.styled';

const getOperationCouldNotBeCompletedText = (isConversion) => {
  const baseText = `The operation couldn’t be completed because the
  following students have submissions`;

  if (isConversion) {
    const destinationAssignmentText = 'in the destination assignment';
    return `${baseText} ${destinationAssignmentText}.`;
  }

  return `${baseText}.`;
};

const getHandinErrorText = (isConversion) => {
  return `${getOperationCouldNotBeCompletedText(isConversion)}
  Please delete one of the submissions for these students and try again.
`;
};

const getTurnitinErrorText = (isConversion) => {
  return `${getOperationCouldNotBeCompletedText(isConversion)}
  Please open this assignment in Turnitin and delete one of the submissions for these students before trying again.
`;
};

const extensionWithSubmissionErrorText =
  'The operation couldn’t be completed because the following students still have a submission in the extension. Please delete their submission before removing them from this extension.';

const ExistingSubmissionErrorDialog = (props) => {
  const { provider, students, onClose, isConversion, errorCode } = props;

  const providerErrorText =
    provider === HANDIN_PROVIDER
      ? getHandinErrorText(isConversion)
      : getTurnitinErrorText(isConversion);

  const errorText =
    errorCode === 'extension-submissions-exist'
      ? extensionWithSubmissionErrorText
      : providerErrorText;

  const getStudentsContent = () => {
    return students.map((student) => {
      const { id, email, fullName, avatar } = student;
      return (
        <User
          isReadOnly
          key={id}
          onActionClick={() => {}}
          email={email}
          fullName={fullName}
          avatar={avatar}
        />
      );
    });
  };

  return (
    <Dialog
      id="duplicate-submission"
      width="400"
      height="272"
      onClose={onClose}
      open
    >
      <DialogTitle>Existing submissions found</DialogTitle>
      <DialogContent>
        <div>{errorText}</div>
        <StudentContainer>{getStudentsContent()}</StudentContainer>
      </DialogContent>
    </Dialog>
  );
};

ExistingSubmissionErrorDialog.propTypes = {
  provider: PropTypes.string.isRequired,
  students: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
  isConversion: PropTypes.bool,
  errorCode: PropTypes.string,
};

ExistingSubmissionErrorDialog.defaultProps = {
  students: [],
  onClose: () => {},
  isConversion: false,
  errorCode: '',
};

export default React.memo(ExistingSubmissionErrorDialog);
