const userIdNotEqual = (userId) => (user) => user.id !== userId;
const getName = (user) => `${user.firstName} ${user.lastName}`;

const formatNames = ({ users, userId, self }) => {
  const names = self
    ? ['You', ...users.filter(userIdNotEqual(userId)).map(getName)]
    : users.map(getName);

  switch (names.length) {
    case 0:
      return '';
    case 1:
      return names[0];
    case 2:
      return names.join(' and ');
    // eslint-disable-next-line no-case-declarations
    default: {
      const lastIndex = names.length - 1;
      return `${names.slice(0, -1).join(', ')}, and ${names[lastIndex]}`;
    }
  }
};

export default formatNames;
