import React from 'react';
import { icons, theme } from '@ublend-npm/aulaui-next';
import { LogoImage, DefaultLogoContainer } from '../List/List.styled';
import HandinLogo from '../../../../../../static/handin-logo.svg';
import TurnitinLogo from '../../../../../../static/turnitin.png';
import QuizzesLogo from '../../../../../../static/quizzes.png';

type AssignmentProviderLogoProps = {
  imageSrc?: string;
  providerType: string;
  providerName?: string;
};

const StaticLogos = {
  'hand-in': HandinLogo,
  handin: HandinLogo,
  turnitin: TurnitinLogo,
  quizzes: QuizzesLogo,
};

export const AssignmentProviderLogo = ({
  imageSrc,
  providerType,
  providerName = providerType,
}: AssignmentProviderLogoProps) => {
  const src = StaticLogos[providerType] || imageSrc;

  if (!src) {
    const { ExtensionIcon } = icons;
    return (
      <DefaultLogoContainer>
        <ExtensionIcon style={{ color: theme.color.designSystem.grey50 }} />
      </DefaultLogoContainer>
    );
  }

  return (
    <LogoImage
      width="24px"
      height="24px"
      src={src}
      alt="assignment"
      aria-label={`${providerName} icon`}
    />
  );
};
