import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  text-align: center;
`;

export const Title = styled('p')`
  font-family: ${theme.font.family.workSans};
  font-size:  ${theme.font.size.copy},
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.black};
  line-height: ${theme.font.lineHeight.copy};
  margin-top: 0;
  margin-bottom: ${theme.spacing.large};
`;

export const Image = styled('img')`
  margin-top: ${theme.spacing.medium};
  height: 421px;
  @media (max-height: 600px) {
    height: 350px;
  }
  @media (max-height: 500px) {
    height: 200px;
  }
`;
