import React from 'react';
import { bool, func, string } from 'prop-types';

import AulaEditor from './Edit.container';

const EditItem = (props) => {
  const { itemClass, onClose, content, open, itemId, itemType } = props;

  return (
    <AulaEditor
      open={open}
      content={content}
      itemClass={itemClass}
      itemType={itemType}
      itemId={itemId}
      onClose={onClose}
    />
  );
};

EditItem.propTypes = {
  itemClass: string.isRequired,
  itemType: string.isRequired,
  onClose: func.isRequired,
  content: string.isRequired,
  open: bool.isRequired,
  itemId: string.isRequired,
};

export default EditItem;
