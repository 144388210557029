import { TAB } from './keyCodes';

const focusableElements = [
  '[tabindex]:not([tabindex^="-"]):not([inert])',
  '[contenteditable]:not([tabindex^="-"]):not([inert])',
  'a[href]:not([tabindex^="-"]):not([inert])',
  'input:not([disabled]):not([inert])',
  'button:not([disabled]):not([inert])',
  'textarea:not([disabled]):not([inert])',
  'select:not([disabled]):not([inert])',
  'area[href]:not([tabindex^="-"]):not([inert])',
  'iframe:not([tabindex^="-"]):not([inert])',
  'audio:not([tabindex^="-"]):not([inert])',
  'video:not([tabindex^="-"]):not([inert])',
  'div[role="button"]',
];

// Apply on keyDown
// e - event
// containerElement - container, where should be focus
const checkFocusTrack = (e, containerElement) => {
  const isTabPressed = e.key === 'Tab' || e.keyCode === TAB;

  if (!isTabPressed) {
    return;
  }

  if (containerElement) {
    const canFocusElements =
      containerElement.querySelectorAll(focusableElements);

    if (canFocusElements && canFocusElements.length > 0) {
      const lastFocusableEl = canFocusElements[canFocusElements.length - 1];
      const firstFocusableEl = canFocusElements[0];

      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } /* tab */ else if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }
  }
};

export default checkFocusTrack;
