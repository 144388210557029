import { connect } from 'react-redux';
import Assignments from './Assignments';
import { baseDomain } from '../../../../../core/selectors/institution';
import { isInstructorInSpace } from '../../../../../core/selectors/classroom';
import fetchAccessTokenAction from '../../../../../core/actions/accessToken/accessToken.action';
import {
  operations as assignmentOperations,
  selectors as assignmentSelectors,
} from '../../../../store/assignments';
import {
  currentUserId,
  needsToRefreshAccessToken,
} from '../../../../../core/selectors/user';

const mapStateToProps = (state) => {
  const userId = currentUserId(state);
  const spaceId = assignmentSelectors.getSelectedSpaceId(state);

  const needsToRefreshAccessTokenProp = () => {
    return needsToRefreshAccessToken(state, spaceId);
  };
  return {
    user: state.user.user,
    selectedSpace: state.data.classRooms[spaceId],
    isFetching:
      assignmentSelectors.isFetchingAssignments(state) ||
      state.accessTokens.isFetching,
    accessTokens: state.accessTokens,
    baseDomain: baseDomain(state),
    isEducator: isInstructorInSpace(state)(userId, spaceId),
    needsToRefreshAccessTokenProp,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAssignments: dispatch(assignmentOperations.fetchAssignments),
  fetchAccessToken: fetchAccessTokenAction(dispatch),
});

const AssignmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Assignments);

export default AssignmentsContainer;
