import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content, Copy } from './EmptyContent.styled';

const EmptyContent = ({ image, copy }) => (
  <Container>
    <Content>
      <img src={image} alt="no material" width={240} />
      <Copy>{copy}</Copy>
    </Content>
  </Container>
);

EmptyContent.propTypes = {
  image: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
};

export default EmptyContent;
