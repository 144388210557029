import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DateTimePickerDialog } from '@ublend-npm/aulaui-next';

const SchedulePostDialog = ({
  open,
  onClose,
  scheduledFor,
  onSchedule,
  onReschedule,
}) => {
  const [defaultDate, setDefaultDate] = useState(new Date());
  useEffect(() => {
    const newDate = scheduledFor ? new Date(scheduledFor) : new Date();
    setDefaultDate(newDate);
  }, [open, scheduledFor]);
  return (
    <DateTimePickerDialog
      id="posts-schedule-date-time-picker"
      open={open}
      onClose={onClose}
      date={defaultDate.toISOString()}
      primaryAction={{
        label: scheduledFor ? 'Reschedule' : 'Schedule',
        onAction: scheduledFor ? onReschedule : onSchedule,
      }}
    />
  );
};

SchedulePostDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scheduledFor: PropTypes.string,
  onSchedule: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
};

SchedulePostDialog.defaultProps = {
  scheduledFor: null,
};

export default React.memo(SchedulePostDialog);
