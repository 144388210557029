import { connect } from 'react-redux';
import TagList from './TagList';
import { createTag } from '../../../../../../../core/actions/tags/tag';
import filterByTag from '../../../../../../../core/actions/classRoom/filter';
import {
  createTagPost,
  deleteTagPost,
} from '../../../../../../../core/actions/tags/tagPost';
import { tagsByNameQuery } from '../../../../../../../core/constants/search';
import { currentUserId as getCurrentUserId } from '../../../../../../../core/selectors/user';
import {
  isInstructor,
  accessingCurrentSpaceAsStaff,
} from '../../../../../../../core/selectors/classroom';

const compareTags = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (b.name > a.name) {
    return -1;
  }
  return 0;
};

const mapStateToProps = (state, ownProps) => {
  const post = state.data.posts[ownProps.post.objectId];
  const tagPosts = post ? post.tagPosts : [];
  const isReadOnlyUser = accessingCurrentSpaceAsStaff(state);
  const isEducator = isInstructor(state);
  const isAuthor = getCurrentUserId(state) === post.user.objectId;
  return {
    tags: tagPosts
      .filter((tagPost) => state.data.tags[tagPost.tagId])
      .map((tagPost) => {
        return Object.assign(state.data.tags[tagPost.tagId], {
          tagPostId: tagPost.tagPostId,
        });
      })
      .sort(compareTags),
    shouldUpdate: true,
    spaceTags: state.data.tags,
    canAdd: !isReadOnlyUser && (isEducator || isAuthor),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteTag: (tagId) => {
    const postId = ownProps.post.objectId;
    dispatch(deleteTagPost({ tagId, postId }));
  },
  search: (client, value) =>
    client
      .search(tagsByNameQuery(value, ownProps.post.classRoom))
      .then((result) => result.hits.hits),

  clickTag: (tagId) => dispatch(filterByTag(tagId)),

  addTag: (value, tagId) =>
    new Promise((resolve, reject) => {
      if (!tagId) {
        const createTagResult = dispatch(
          createTag(value, ownProps.post.classRoom)
        );

        if (!createTagResult) {
          reject();
          return;
        }

        createTagResult.then(
          (tag) => {
            const createTagPostResult = dispatch(
              createTagPost(tag.objectId, ownProps.post.objectId)
            );

            if (!createTagPostResult) {
              reject();
              return;
            }

            createTagPostResult.then(
              (tagPost) => {
                resolve(tagPost);
              },
              (error) => {
                reject(error);
              }
            );
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        const createTagPostResult = dispatch(
          createTagPost(tagId, ownProps.post.objectId)
        );

        if (!createTagPostResult) {
          reject();
          return;
        }

        createTagPostResult.then(
          (tagPost) => {
            resolve(tagPost);
          },
          (error) => {
            reject(error);
          }
        );
      }
    }),
});

const TagListContainer = connect(mapStateToProps, mapDispatchToProps)(TagList);

export default TagListContainer;
