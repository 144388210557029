import Prefixer from 'inline-style-prefixer';
import { theme as AulaUITheme } from '@ublend-npm/aulaui-next';
import fonts from '../../../../../../styles/fonts';

const tagListStyle = new Prefixer().prefix({
  container: {
    width: 'calc(100% - 20px)',
    padding: '15px 10px 7px',
    cursor: 'pointer',
    transition: 'all 0.2s',
    float: 'left',
    ':hover': {
      backgroundColor: AulaUITheme.color.grey0,
    },
  },
  tagIcon: {
    fontSize: '24px',
    lineHeight: '24px',
    height: 24,
    marginRight: 10,
    float: 'left',
  },
  tagContainer: {
    float: 'left',
    width: 'calc(100% - 34px)',
  },
  tag: {
    ...fonts.regular,
    backgroundColor: AulaUITheme.color.grey4,
    color: 'white',
    fontSize: 12,
    lineHeight: '12px',
    padding: '5px 10px',
    borderRadius: '11px',
    float: 'left',
    margin: '0 10px 5px 0',
    cursor: 'pointer',
    transition: 'opacity 0.3s',
  },
  tagNew: {
    color: AulaUITheme.color.grayDark,
  },
  focusTag: {
    backgroundColor: 'red',
  },
  tagCross: {
    marginLeft: 5,
    paddingLeft: 5,
    borderLeft: `1px solid ${AulaUITheme.color.grayLight}`,
    color: AulaUITheme.color.grey3,
  },
  loadingTags: {
    fontSize: 12,
    color: AulaUITheme.color.grey4,
    height: 29,
    lineHeight: '20px',
  },
});

export const theme = new Prefixer().prefix({
  container: {
    float: 'left',
  },
  input: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    outline: 'none',
  },
  suggestionsContainerOpen: {
    zIndex: 200,
    position: 'absolute',
    listStyleType: 'none',
    padding: '0 5px 5px',
    margin: 0,
    width: '250px',
    marginBottom: 5,
    backgroundColor: 'white',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.30)',
  },
  suggestionsList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  suggestion: {
    marginTop: 5,
    width: 'calc(100% - 30px)',
    padding: '0 15px',
    cursor: 'pointer',
    fontSize: 14,
    color: AulaUITheme.color.designSystem.purple70,
    height: 45,
    lineHeight: '45px',
  },
  suggestionHighlighted: {
    backgroundColor: AulaUITheme.color.grey14,
  },
});

export default tagListStyle;
