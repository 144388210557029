import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const ParticipantSuggestionItemText = styled('div')`
  padding-left: 8px;
  font-weight: 500;
`;

export const Educator = styled('span')`
  color: ${theme.color.secondary};
`;

export default ParticipantSuggestionItemText;
