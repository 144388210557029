const { summarizeBlockContent } = require('@ublend-npm/aula-editor-utils');

const getAulaEditorContentSummary = ({ content, allBlocks = false }) => {
  const { value: blocks, context } = content;

  if (allBlocks) {
    return blocks.map((block) =>
      summarizeBlockContent(context)(block).flat().join('')
    );
  }

  return [summarizeBlockContent(context)(blocks[0]).flat().join('')];
};

export default getAulaEditorContentSummary;
