export default ({ method, url, body }) => {
  const tiiForm = document.createElement('form');
  tiiForm.method = method;
  tiiForm.action = url;

  Object.keys(body).forEach((key) => {
    const tiiInput = document.createElement('input');
    tiiInput.setAttribute('name', key);
    tiiInput.setAttribute('value', body[key]);
    tiiForm.appendChild(tiiInput);
  });

  tiiForm.target = '_blank';
  document.body.appendChild(tiiForm);
  tiiForm.submit();
  document.body.removeChild(tiiForm);
};
