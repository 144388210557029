import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  margin-bottom: ${theme.spacing.xxSmall};
`;

export const NavItemWrapper = styled('span')`
  display: inline-block;
  margin-right: ${theme.spacing.small};
`;
