import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const DateFields = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DateField = styled('div')`
  width: calc(50% - ${theme.spacing.small});
  margin-top: 20px;
  height: 48px;
`;
