export const getLabelProps = (colors) => ({
  upcoming: {
    title: 'Upcoming',
    color: colors.designSystem.blackOpacity60,
    background: colors.designSystem.grey10,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText:
      'This assignment block is visible to students but they cannot access it until the open date.',
  },
  nearDueDate: {
    title: 'Open',
    color: colors.green,
    background: colors.backgrounds.primary,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText: 'The assignment is open for submissions.',
  },
  open: {
    title: 'Open',
    color: colors.black,
    background: colors.backgrounds.primary,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText: 'The assignment is open for submissions.',
  },
  pastDueDate: {
    title: 'Past due date',
    color: colors.black,
    background: colors.backgrounds.warning,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText:
      'The due date is past but the assignment is still open for submissions.',
  },
  closed: {
    title: 'Closed',
    color: colors.designSystem.blackOpacity60,
    background: colors.designSystem.grey10,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText:
      'The assignment is closed and does not accept submissions at this point.',
  },
  submitted: {
    title: 'Submitted',
    color: colors.green,
    background: colors.backgrounds.primary,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText: 'Submitted',
  },
  submittedLate: {
    title: 'Submitted Late',
    color: colors.orange,
    background: colors.backgrounds.warning,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText: 'Submitted Late',
  },
  notSubmitted: {
    title: 'Not Submitted',
    color: colors.mediumBlue,
    background: colors.backgrounds.default,
    borderColor: colors.designSystem.blackOpacity12,
    tooltipText: 'Not Submitted',
  },
  manualAssignment: {
    title: 'Manual assignment',
    color: colors.mediumBlue,
    background: colors.backgrounds.default,
    borderColor: colors.designSystem.blackOpacity12,
  },
});
