import { Post } from '@app/types/core';
import React from 'react';

import TagList from '../../TagList/TagList.container';
import { PostTagsWrapper, PostTagsSeparator } from './PostTags.styled';

type PostTagsProps = {
  post: Pick<Post, 'objectId' | 'tagPosts' | 'user'>;
};

const PostTags = ({ post }: PostTagsProps) => (
  <PostTagsWrapper>
    <PostTagsSeparator topSeparator />
    <TagList post={post} />
    <PostTagsSeparator bottomSeparator />
  </PostTagsWrapper>
);

export default PostTags;
