import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { INBOX_MESSAGE_ITEM_TYPE } from '@core/constants/analytics';
import { user } from '@core/selectors/users';
import {
  selectors,
  operations as creators,
} from '../../../store/conversations';
import {
  selectors as notificationSelectors,
  operations as notificationOperations,
} from '../../../store/notifications';
import { operations as reactionOperations } from '../../../store/reactions';
import ConversationModal from './ConversationModal';

const groupIdFromLocation = (location) => location.query.inbox;

const getStatus = (state, participants, currentUser) => {
  const participantIds = Object.keys(participants);
  const others = participantIds.filter((id) => id !== currentUser.objectId);

  if (others.length === 1) {
    const participant = user(state, others[0]);

    return participant?.status;
  }

  return null;
};

const makeMapStateToProps = () => {
  return (state, { location, flags }) => {
    const isFetchingGroups = false;
    const groupId = groupIdFromLocation(location);
    const { conversations } = state.data.inboxv2;
    const conversation = conversations[groupId];

    const currentUser = state.user.user;
    const status = flags.ftUserStatusEnabled202107
      ? getStatus(state, conversation.participants, currentUser)
      : null;

    return {
      groupId,
      currentUser,
      conversation,
      leaveGroupOpened: state.inboxv2.leaveGroupOpened,
      leavingConversation: state.inboxv2.leavingConversation,
      open: !!groupId && !isFetchingGroups,
      renameGroupOpened: state.inboxv2.renameGroupOpened,
      title: selectors.conversationTitle(state, groupId),
      status,
      conversationMuted: notificationSelectors.isConversationMuted(
        state,
        groupId
      ),
    };
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openAddParticipant: () => dispatch(creators.openViewParticipants(true)),
  closeLeaveGroup: () => dispatch(creators.closeLeaveGroup()),
  openLeaveGroup: () => dispatch(creators.openLeaveGroup()),
  setConversationClosed: () => {
    const conversationId = groupIdFromLocation(ownProps.location);
    dispatch(creators.setConversationClosed(conversationId));
  },
  setConversationReopened: () => {
    const conversationId = groupIdFromLocation(ownProps.location);
    dispatch(creators.setConversationReopened(conversationId));
  },
  openRenameGroup: () => dispatch(creators.openRenameGroup()),
  openViewParticipants: () => dispatch(creators.openViewParticipants()),
  leaveConversation: () => dispatch(creators.leaveConversation()),
  toggleReaction: ({ itemId, emojiName }) =>
    dispatch(
      reactionOperations.toggleReaction({
        itemId,
        itemType: INBOX_MESSAGE_ITEM_TYPE,
        emojiName,
      })
    ),
  fetchMessages: () => dispatch(creators.fetchMessages({ replace: true })),
  toggleMuteConversation: () => {
    const conversationId = groupIdFromLocation(ownProps.location);
    return dispatch(
      notificationOperations.toggleMuteConversation(conversationId)
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  leaveConversation: async () => {
    await dispatchProps.leaveConversation();
    dispatchProps.closeLeaveGroup();
    ownProps.closeModal();
  },
});

export default withLDConsumer()(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ConversationModal)
);
