import React from 'react';
import { SURE_DELETE_POST } from '@app/constants/texts';
import { AlertDialog } from '../../common/Dialog';

interface ConfirmDeleteDialogProps {
  closeDeleteDialog: () => void;
  deletePost: () => void;
}

export const ConfirmDeleteDialog = ({
  deletePost,
  closeDeleteDialog,
}: ConfirmDeleteDialogProps) => {
  return (
    <AlertDialog
      id="confirm-delete-post"
      title="Delete post"
      message={SURE_DELETE_POST}
      open
      onClose={closeDeleteDialog}
      action={{
        label: 'Delete',
        onClick: deletePost,
        danger: true,
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: closeDeleteDialog,
      }}
      maxWidth={undefined}
    />
  );
};
