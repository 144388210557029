import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const StatusText = styled('div')`
  display: flex;
  gap: 6px;
  color: ${theme.color.designSystem.blackOpacity60};
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.regular};
`;

export const ConversationTitle = styled('div')`
  font-size: ${theme.font.size.headline};
  color: ${theme.color.designSystem.blackOpacity87};
  font-weight: ${theme.font.weight.semiBold};
`;
