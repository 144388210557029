import { useQuery } from 'react-query';
import { DeletedSection, Folder } from '@core/materials/types';
import { fetchSections } from '@core/materials/api';
import factoryKeys from './queries';

const isPageForRole =
  (educatorArea: boolean) =>
  (material: DeletedSection | Folder): material is DeletedSection => {
    return !material.isFolder && material.educatorOnly === educatorArea;
  };

const useDeletedMaterialPages = (
  spaceId: string,
  educatorArea: boolean,
  enabled: boolean = false
) => {
  const { data, isLoading } = useQuery(
    factoryKeys.deletedSectionsBySpaceId(spaceId),
    () => fetchSections(spaceId, false, true),
    {
      select: (sections) =>
        sections.filter(isPageForRole(educatorArea)).sort((a, b) => {
          return (
            new Date(b.deletedAt).getTime() - new Date(a.deletedAt).getTime()
          );
        }),
      enabled: !!enabled && !!spaceId,
    }
  );

  return {
    deletedMaterialPages: data,
    hasDeletedMaterialPages: data !== undefined ? data.length > 0 : undefined,
    isLoading,
  };
};

export default useDeletedMaterialPages;
