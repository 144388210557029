import styled from 'react-emotion';
import { TextField as TextFieldBase, theme } from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  margin-top: ${theme.spacing.large};
`;

export const Title = styled('div')`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  color: ${theme.color.designSystem.blackOpacity87};
  margin-bottom: ${theme.spacing.small};
`;

export const TextField = styled(TextFieldBase)`
  margin: 0;
  font-weight: ${(props) =>
    props.isConversion ? 400 : theme.font.weight.medium};

  & .fieldset-textfield-label {
    transform: translate(14px, 11px) scale(1);
  }

  & .fieldset-textfield-label.fieldset-textfield-shrunkLabel {
    transform: translate(14px, -6px) scale(0.75);
  }
`;
