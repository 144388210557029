import { theme } from '@ublend-npm/aulaui-next';

const filledColor = '#458647';
const unFilledColor = '#E0DDE5';
const height = 6;
const borderRadius = 16;

export const getProgressBarStyles = {
  progressBarContainer: {
    display: 'flex',
    marginTop: theme.spacing.small,
  },
  continuousProgressbarContainer: {
    display: 'flex',
    backgroundColor: unFilledColor,
    height,
    borderRadius,
    marginTop: theme.spacing.small,
  },
  continuousProgressbarBar: ({
    percentageWidth,
  }: {
    percentageWidth: number;
  }) => ({
    backgroundColor: filledColor,
    width: `${percentageWidth}%`,
    height,
    borderRadius,
  }),
  sectionsProgressBarContainer: ({
    isFilled,
    pixelGapBetweenSections,
    isLastSection,
  }: {
    isFilled?: boolean;
    pixelGapBetweenSections: number;
    isLastSection: boolean;
  }) => ({
    backgroundColor: isFilled ? filledColor : unFilledColor,
    marginRight: isLastSection ? 0 : pixelGapBetweenSections,
    flexGrow: 1,
    height,
    borderRadius,
    overflow: 'hidden',
  }),
};
