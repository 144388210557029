import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const InformationSection = styled.div`
  display: flex;
  align-items: top;
  margin-top: ${theme.spacing.small};
`;

export const InformationText = styled.div`
  font-size: ${theme.font.size.small};
  color: ${theme.color.designSystem.blackOpacity60};
`;

export const InformationIcon = styled(ErrorOutlineIcon)`
  color: ${theme.color.designSystem.blackOpacity60};
  height: 16px;
  margin-right: ${theme.spacing.xxSmall};
`;
