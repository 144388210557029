import { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const selectedListItem = css`
  background-color: ${theme.color.blackAlpha(theme.opacity.alpha8)};
  &:active {
    background-color: ${theme.color.blackAlpha(theme.opacity.alpha8)};
  }
  &:focus {
    background-color: ${theme.color.blackAlpha(theme.opacity.alpha8)};
  }
  &:hover {
    background-color: ${theme.color.blackAlpha(theme.opacity.alpha8)};
  }
`;

export const completedListItem = css`
  background-color: ${theme.color.blackAlpha(theme.opacity.alpha2)};
`;

export const sectionItem = css`
  .label {
    font-weight: normal;
    line-height: 22px;
  }
`;

export const buttonItem = css`
  .label {
    font-weight: ${theme.font.weight.semiBold};
    line-height: 22px;
  }
`;
