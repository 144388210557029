import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@ublend-npm/aulaui-next';
import ExtensionInfoForm from '../../components/ExtensionInfoForm/ExtensionInfoForm';
import ExtensionSummary from '../../components/ExtensionSummary/ExtensionSummary';
import Navigation from '../../components/Navigation/Navigation';
import { Root } from './Main.styled';

import { STUDENTS_VIEW } from '../../components/Navigation/constants';
import { SpaceGroupType, AssignmentType } from '../../ExtensionModal.types';
import AssignmentSelection from '../../components/AssignmentSelection/AssignmentSelection';
import ConversionDetails from '../../components/ConversionDetails/ConversionDetails';
import getStudentsByGroups from '../getStudentsByGroups';
import getSuggestedTitleForAnExtension from '../getSuggestedTitle';
import NavigationView from './NavigationView';
import filterDestinationAssignmentsForConversion from '../../../../Submissions/filterDestinationAssignmentsForConversion';

const Conversion = ({
  dialogTitle,
  data: { parentAssignmentTitle, destinationAssignmentId },
  assignmentData: { title, assignedStudents, unassignedStudents, groups },
  assignments,
  assignment,
  setData,
  setAssignmentData,
  setStep,
  spaceGroups,
  onClose,
  hasError,
  greyedOutUsers,
}) => {
  const navigationView = STUDENTS_VIEW;
  const groupsStudents = getStudentsByGroups(groups, spaceGroups);
  const selectedStudentsNumber =
    navigationView === STUDENTS_VIEW
      ? assignedStudents.length
      : groupsStudents.length;
  const [edited, setEdited] = useState(false);

  const updateDestinationAssignmentData = (id) => {
    const parentTitle = assignments.find((item) => item.id === id).title;
    setData({
      parentAssignmentTitle: parentTitle,
      destinationAssignmentId: id,
    });
    setAssignmentData({
      title: !edited ? getSuggestedTitleForAnExtension(assignments, id) : title,
    });
  };

  if (!parentAssignmentTitle) {
    updateDestinationAssignmentData(
      filterDestinationAssignmentsForConversion(assignments, assignment)[0].key
    );
  }

  if (!title && !edited) {
    const suggestedName = getSuggestedTitleForAnExtension(assignments);
    setAssignmentData({ title: suggestedName });
  }

  return (
    <>
      <DialogTitle onClose={onClose}>
        {dialogTitle}

        <ExtensionInfoForm
          isConversion
          hasTitle={false}
          extensionName={title}
          onExtensionNameChange={(newTitle) => {
            setAssignmentData({ title: newTitle });
            setEdited(true);
          }}
        />

        <AssignmentSelection
          assignments={filterDestinationAssignmentsForConversion(
            assignments,
            assignment
          )}
          value={
            destinationAssignmentId ||
            filterDestinationAssignmentsForConversion(
              assignments,
              assignment
            )[0].key
          }
          onChange={(id) => {
            updateDestinationAssignmentData(id);
          }}
        />

        <ConversionDetails
          currentTitle={assignment.title}
          convertedExtensionTitle={title}
          parentAssignmentTitle={parentAssignmentTitle}
        />
      </DialogTitle>
      <Root>
        <ExtensionSummary
          selectedStudentsNumber={selectedStudentsNumber}
          leftStudentsNumber={
            unassignedStudents.length - assignedStudents.length
          }
          navigationView={navigationView}
          setStep={setStep}
          isConversion
        />
        <Navigation
          selected={navigationView}
          onChange={(view) =>
            setData({
              navigationView: view,
            })
          }
        />
        <NavigationView
          data={{ assignedStudents, unassignedStudents, groups }}
          setData={setAssignmentData}
          spaceGroups={spaceGroups}
          navigationView={navigationView}
          hasError={hasError}
          greyedOutUsers={greyedOutUsers}
        />
      </Root>
    </>
  );
};

Conversion.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  spaceGroups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)).isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  assignmentData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignment: PropTypes.shape(AssignmentType).isRequired,
  setData: PropTypes.func.isRequired,
  setStep: PropTypes.func,
  setAssignmentData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  greyedOutUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Conversion.defaultProps = {
  hasError: false,
  setStep: undefined,
};

export default React.memo(Conversion);
