import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@ublend-npm/aulaui-next';

import HandinLogo from '../../../../../../static/handin-logo.svg';
import SSALogo from '../../../../../../static/ssa-logo.png';

import {
  Container,
  LogoContainer,
  Title,
  Header,
  Description,
  StyledOpenIcon,
} from './Tool.styled';

const logos = {
  handin: HandinLogo,
  ssa: SSALogo,
};

const Tool = ({ id, title, description, url, target, opensInNew }) => {
  const logoSrc = logos[id];

  return (
    <Container href={url} target={target} rel="noopener noreferrer">
      <Header>
        {logoSrc && (
          <LogoContainer>
            <Avatar src={logoSrc} name={title} size="small" />
          </LogoContainer>
        )}
        <Title>{title}</Title>

        {opensInNew && <StyledOpenIcon />}
      </Header>
      <Description>{description}</Description>
    </Container>
  );
};

Tool.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  opensInNew: PropTypes.bool,
};

Tool.defaultProps = {
  opensInNew: false,
};

export default Tool;
