import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@ublend-npm/aulaui-next';

import ParticipantSuggestionItemText, {
  Educator,
} from './ParticipantSuggestionItemText.styled';

const ParticipantSuggestionItem = ({
  suggestion: { label, avatar, isEducator },
}) => {
  const educator = isEducator ? (
    <React.Fragment>
      <span> - </span>
      <Educator>Educator</Educator>
    </React.Fragment>
  ) : null;

  return (
    <React.Fragment>
      <Avatar size="small" name={label} src={avatar} />
      <ParticipantSuggestionItemText>
        {label}
        {educator}
      </ParticipantSuggestionItemText>
    </React.Fragment>
  );
};

ParticipantSuggestionItem.propTypes = {
  suggestion: PropTypes.shape({
    label: PropTypes.string,
    avatar: PropTypes.string,
    isEducator: PropTypes.bool,
  }).isRequired,
};

export default ParticipantSuggestionItem;
