import moment from 'moment';

const hasDateBeenEdited = (currentDate, previousDate) => {
  const current = moment(currentDate);
  const previous = moment(previousDate);

  return !current.isSame(previous);
};

const getAssignmentPropertiesDiff = (assignment, parentAssignment) => {
  const diff = {};
  // No diff needed if this is the parent assignment
  if (!parentAssignment || Object.keys(parentAssignment).length === 0) {
    return diff;
  }

  // The assignment description can also be edited
  // but it has been left out of scope for now.
  const propertiesThatCanBeEdited = {
    startDate: {
      type: 'date',
    },
    endDate: {
      type: 'date',
    },
    gradesReleasedAt: {
      type: 'date',
    },
  };

  Object.keys(propertiesThatCanBeEdited).forEach((property) => {
    const { type } = propertiesThatCanBeEdited[property];
    if (type !== 'date') {
      return;
    }

    const currentPropertyValue = assignment[property];
    const previousPropertyValue = parentAssignment[property];

    if (!currentPropertyValue || !previousPropertyValue) {
      return;
    }

    if (hasDateBeenEdited(currentPropertyValue, previousPropertyValue)) {
      diff[property] = true;
    }
  });

  return diff;
};

export default getAssignmentPropertiesDiff;
