import React from 'react';
import { theme } from '@ublend-npm/aulaui-next';
import createSvgIcon from './hoc/createSvgIcon';

export default createSvgIcon({
  svg: () => (
    <>
      <path
        fill={theme.color.grey4}
        d="M31.107 0.585l-0.787 0.003c-4.030 0.001-8.060 0.003-12.090-0.003-0.261-0-0.448 0.070-0.634 0.258-3.513 3.548-7.047 7.074-10.546 10.637-1.857 1.891-3.739 3.756-5.597 5.644-0.753 0.765-0.758 1.59 0.007 2.356 3.793 3.797 7.589 7.588 11.38 11.386 0.245 0.246 0.543 0.395 0.844 0.549 0.197 0 0.395 0 0.592 0 0.413-0.121 0.714-0.397 1.007-0.698 1.444-1.481 2.898-2.953 4.343-4.432 1.614-1.652 3.222-3.308 4.832-4.963 1.633-1.677 3.276-3.347 4.896-5.037 0.523-0.546 1.053-1.083 1.579-1.624 0.112-0.116 0.18-0.232 0.18-0.401-0.004-4.204-0.004-9.472-0.006-13.676z"
      />
      <path
        fill={theme.color.grey6}
        d="M22.612 3.432c-3.137 0-5.681 2.544-5.681 5.681s2.544 5.681 5.681 5.681c3.138 0 5.681-2.543 5.681-5.681s-2.544-5.681-5.681-5.681zM22.612 11.874c-1.525 0-2.761-1.236-2.761-2.761s1.236-2.761 2.761-2.761c1.525 0 2.761 1.236 2.761 2.761s-1.236 2.761-2.761 2.761z"
      />
      <path
        fill={theme.color.white}
        d="M25.373 9.113c0 1.525-1.236 2.761-2.761 2.761s-2.761-1.236-2.761-2.761c0-1.525 1.236-2.761 2.761-2.761s2.761 1.236 2.761 2.761z"
      />
    </>
  ),
  displayName: 'Tag',
  viewBox: '0 0 32 32',
});
