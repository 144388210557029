import ArrowDropdown from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  theme,
  Avatar,
  List as BaseList,
  Button,
} from '@ublend-npm/aulaui-next';
import styled from 'react-emotion';
import BaseDivider from '@mui/material/Divider';
import sizes from '../../../../../styles/sizes';

export const SpaceSelectorContainer = styled.div`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  &:hover {
    cursor: pointer;
    background-color: ${theme.color.blackAlpha(0.08)};
  }
  font-size: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${theme.spacing.xxSmall};
  padding-top: ${theme.spacing.xxSmall};
  padding-bottom: ${theme.spacing.xxSmall};
`;

export const SpaceSelectorTarget = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SpaceTitle = styled.div`
  max-width: calc(85vw - ${sizes.inboxGroupsMenuWidth}px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SpaceAvatar = styled(Avatar)`
  min-width: 32px;
  margin-right: 10px;
`;

export const ArrowDropdownIcon = styled(ArrowDropdown)`
  margin-left: ${theme.spacing.xxSmall};
  color: ${theme.color.blackAlpha(0.6)};
`;

export const ArrowForwardIcon = styled(ArrowForwardIosIcon)`
  margin-left: ${theme.spacing.xxSmall};
  font-size: ${theme.font.lineHeight.copy};
`;

export const MenuItemsContainer = styled.div`
  min-width: 300px;
  max-width: 320px;
`;

export const Text = styled.div`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.large};
  font-weight: ${theme.font.weight.semiBold};
  line-height: ${theme.font.lineHeight.large};
  color: ${theme.color.black};
  padding-top: ${theme.spacing.medium};
  padding-bottom: ${theme.spacing.xSmall};
  padding-left: ${theme.spacing.medium};
  padding-right: ${theme.spacing.medium};
`;

export const List = styled(BaseList)`
  ${({ inline }) => inline && `margin-right: -19px;`};
`;

export const SpaceListContainer = styled.div`
  overflow: scroll;
  flex: 1;
`;

export const Divider = styled(BaseDivider)`
  margin-right: 12px;
  margin-left: 12px;
`;

export const BackToMainCategoryButton = styled(Button)`
  margin: 8px 16px 8px 16px;
  justify-content: left;
`;

export const BackToMainCategoryText = styled.span`
  padding-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 410px;
  margin-left: -4px;
`;

export const NoMatchContainer = styled.div`
  padding-top: ${theme.spacing.xxLarge};
  display: block;
`;

export const GrayText = styled.div`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.copy};
  color: ${theme.color.designSystem.blackOpacity60};
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
`;
