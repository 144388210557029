import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { theme } from '@ublend-npm/aulaui-next';
import { ChildRouteType } from '@app/components/propTypes/common';

import { isLocalId } from '@core/utils/localId';
import push from '../../../../utils/routing';

import * as types from './types';
import DeleteSectionModal from './MaterialList/DeleteSectionModal.container';
import LeaveWithoutSavingModal from './SectionView/LeaveWithoutSavingModal';
import GetStarted from './GetStarted';
import SectionView from './SectionView.container';
import EmptyPage from './EmptyPage';
import VersionModal from './Versions';
import {
  Container,
  SectionViewContainer,
  ContentContainer,
} from './Material.styled';

// TODO move this to a generic location
const { color, font } = theme;
const aulaTheme = createTheme({
  typography: {
    fontFamily: font.family.workSans,
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: color.designSystem.purple70,
    },
    text: {
      hint: color.grey11,
    },
  },
});

class MaterialV2 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleOnCloseLeaveWithoutSaving =
      this.handleOnCloseLeaveWithoutSaving.bind(this);
    this.handleOnConfirmLeaveWithoutSaving =
      this.handleOnConfirmLeaveWithoutSaving.bind(this);
  }

  componentWillMount() {
    const { route, router, educatorOnly } = this.props;
    const tab = educatorOnly ? 'educatorArea' : 'materials';
    // We need this fallback for backwards compatibility with old URLs
    const materialRoute = route.childRoutes
      ? route.childRoutes.find((childRoute) => childRoute.path.includes(tab))
      : route;
    this.unregisterLeaveHook = router.setRouteLeaveHook(
      Object.assign(materialRoute, {
        // Must be explicit since path might be '/materials' or '/educatorArea' at mount time
        path: `${tab}(/:sectionId)`,
      }),
      this.routerWillLeave.bind(this)
    );
  }

  componentDidMount() {
    const { currentSectionId, dispatchAnalytics } = this.props;
    this.fallbackWhenNoSectionExists();

    if (!isLocalId(currentSectionId)) {
      dispatchAnalytics();
    }
    this.updateDocumentTitle();
  }

  componentDidUpdate(prevProps) {
    const {
      currentSectionId,
      dispatchAnalytics,
      leaveDestinationPath,
      onLeaveWithoutSaving,
      editing,
      saving,
    } = this.props;
    const shouldDispatchChangeSectionAnalytics =
      prevProps.currentSectionId !== currentSectionId &&
      typeof currentSectionId !== 'undefined' &&
      !isLocalId(currentSectionId);

    if (shouldDispatchChangeSectionAnalytics) {
      dispatchAnalytics();
    }

    if (leaveDestinationPath && !editing && !saving) {
      push(leaveDestinationPath);
      onLeaveWithoutSaving(null);
    }
    this.updateDocumentTitle();
  }

  componentWillUnmount() {
    if (this.unregisterLeaveHook) {
      this.unregisterLeaveHook();
    }
  }

  routerWillLeave(e) {
    const { currentSectionId, editing, onLeaveWithoutSaving } = this.props;
    if (typeof currentSectionId !== 'undefined' && editing) {
      onLeaveWithoutSaving(e.pathname, null);
      return false;
    }

    return true;
  }

  updateDocumentTitle() {
    const { currentSectionTitle, currentSpaceName } = this.props;
    const section = currentSectionTitle
      ? `${currentSectionTitle} - Materials - `
      : '';
    const space = currentSpaceName ? `${currentSpaceName} - ` : '';
    document.title = `${section}${space}Aula`;
  }

  handleOnCloseLeaveWithoutSaving() {
    const { onLeaveWithoutSaving } = this.props;
    onLeaveWithoutSaving(null, true);
  }

  handleOnConfirmLeaveWithoutSaving() {
    const { onLeaveWithoutSaving, leaveDestinationPath } = this.props;
    onLeaveWithoutSaving(leaveDestinationPath, false);
  }

  fallbackWhenNoSectionExists() {
    const {
      currentSectionId,
      section,
      hasAtLeastOneChildSection,
      goToFallbackSection,
      fallbackId,
    } = this.props;

    if (
      (!section || !currentSectionId) &&
      hasAtLeastOneChildSection &&
      fallbackId
    ) {
      goToFallbackSection();
    }
  }

  render() {
    const {
      hasFetchedSections,
      sectionIds,
      section,
      leaveDestinationPath,
      editing,
      saving,
      isDeletingSection,
      isDeletingFolder,
      isInstructor,
      hasAtLeastOneChildSection,
      currentSectionId,
      educatorOnly,
    } = this.props;

    const displayGetStarted =
      hasFetchedSections &&
      sectionIds.length === 0 &&
      !hasAtLeastOneChildSection;

    const getSectionViewComponent = () => {
      if (displayGetStarted) {
        return (
          <ContentContainer>
            <SectionViewContainer>
              <GetStarted />
            </SectionViewContainer>
          </ContentContainer>
        );
      }

      if (!section && !hasAtLeastOneChildSection) {
        return (
          <ContentContainer>
            <SectionViewContainer>
              <EmptyPage isInstructor={isInstructor} />
            </SectionViewContainer>
          </ContentContainer>
        );
      }

      if (isDeletingSection || isDeletingFolder) {
        return <div />;
      }

      if (currentSectionId && section) {
        return <SectionView educatorOnly={educatorOnly} />;
      }

      return null;
    };

    const getVersionModalComponent = () => {
      return <VersionModal />;
    };

    return (
      <MuiThemeProvider theme={aulaTheme}>
        <Container>
          {getSectionViewComponent()}
          <LeaveWithoutSavingModal
            open={leaveDestinationPath !== null && editing && !saving}
            onClose={this.handleOnCloseLeaveWithoutSaving}
            onConfirm={this.handleOnConfirmLeaveWithoutSaving}
          />
          <DeleteSectionModal
            educatorOnly={educatorOnly}
            sectionTitle={section?.title}
          />
          {getVersionModalComponent()}
        </Container>
      </MuiThemeProvider>
    );
  }
}

MaterialV2.propTypes = {
  dispatchAnalytics: PropTypes.func.isRequired,
  currentSectionId: PropTypes.string, // eslint-disable-line react/require-default-props
  currentSectionTitle: PropTypes.string,
  currentSpaceName: PropTypes.string,
  section: types.Section,
  sectionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  editing: PropTypes.bool,
  saving: PropTypes.bool,
  hasAtLeastOneChildSection: PropTypes.bool.isRequired,
  hasFetchedSections: PropTypes.bool,
  onLeaveWithoutSaving: PropTypes.func.isRequired,
  leaveDestinationPath: PropTypes.string,
  route: PropTypes.shape({
    childRoutes: PropTypes.arrayOf(ChildRouteType),
    path: PropTypes.string,
  }).isRequired,
  router: PropTypes.shape({
    setRouteLeaveHook: PropTypes.func.isRequired,
  }).isRequired,
  isDeletingSection: PropTypes.bool.isRequired,
  isDeletingFolder: PropTypes.bool.isRequired,
  isInstructor: PropTypes.bool.isRequired,
  educatorOnly: PropTypes.bool,
  goToFallbackSection: PropTypes.func.isRequired,
  fallbackId: PropTypes.string,
};

MaterialV2.defaultProps = {
  currentSectionTitle: '',
  currentSpaceName: '',
  section: null,
  editing: false,
  saving: false,
  hasFetchedSections: false,
  leaveDestinationPath: null,
  educatorOnly: false,
  fallbackId: null,
};

export default withRouter(MaterialV2);
