import styled from 'react-emotion';

const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export default Container;
