import styled, { css } from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import { config as breakpoints } from '../../../../../hooks/useBreakpoints';

const { blackOpacity12, blackOpacity87 } = theme.color.designSystem;

const mediaSettings = css`
  @media (min-width: 768px) {
    width: 700px;
  }

  // large devices
  @media (min-width: 992px) {
    width: 929px;
  }

  // x-large devices
  @media (min-width: 1200px) {
    width: 1129px;
  }
`;

export const RestoreMaterialDrawerTitle = styled('h2')`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 20px;
  line-height: ${theme.font.lineHeight.large};
  margin: 0;
  position: absolute;
  top: ${theme.spacing.large};
  left: ${theme.spacing.large};
`;

export const PageViewTitle = styled('h1')`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 24px;
  line-height: ${theme.font.lineHeight.headline};
  margin-left: 52.5px;
  margin-right: 0;
  margin-bottom: ${theme.spacing.xxLarge};
  margin-top: ${theme.spacing.xLarge};
`;

export const RestoreMaterialDrawerContainer = styled('div')`
  display: flex;
  overflow: hidden;
  height: 100%;
  ${mediaSettings};
`;

export const MaterialListContainer = styled('section')`
  margin-top: ${theme.spacing.xxGiant};
  flex: 0 0 409px;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: ${theme.spacing.large};
`;

export const ActionButtonsContainer = styled('div')`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid;
  border-color: ${blackOpacity12};
  padding: ${theme.spacing.small} ${theme.spacing.medium};
  flex-wrap: wrap;
  gap: ${theme.spacing.medium};
  background-color: white;
`;

export const PageViewContainer = styled('section')`
  background-color: ${theme.color.designSystem.grey10};
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

export const EditorContainer = styled('div')`
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 0 ${theme.spacing.xxxxGiant} 0
      calc(${theme.spacing.large} - 52.5px);
    flex-basis: 75%;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: 0 110px 0 calc(101px - 52.5px);
  }
`;

export const Alert = styled('div')`
  width: 100%;
  min-height: 20px;
  background-color: ${theme.color.designSystem.background.light.information};
  padding: 10px ${theme.spacing.large};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${theme.spacing.xSmall};
  font-size: ${theme.font.size.copy};
  color: ${blackOpacity87};
  box-shadow: inset 0px -1px 0px ${blackOpacity12};
`;

export const InfoIconColor = { color: theme.color.designSystem.information };

export const IconButtonWrapper = styled('div')`
  top: ${theme.spacing.medium};
  right: ${theme.spacing.xLarge};
  margin: auto;
  position: absolute;
`;

export const EmptyStateContainer = styled('div')`
  ${mediaSettings};
`;

export const EmptyStateMessage = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 111px;
`;

export const EmptyStateTitle = styled('span')`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.large};
`;

export const EmptyState = styled('div')`
  background-image: url('../../../../../static/empty-state-sep-21.png');
  background-size: contain;
  width: 444px;
  height: 374px;
  background-repeat: no-repeat;
`;

export const Center = styled('div')`
  ${mediaSettings};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
