import styled from 'react-emotion';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { theme } from '@ublend-npm/aulaui-next';

export const Wrapper = styled(FormControlLabel)`
  span {
    color: ${theme.color.blackAlpha(0.87)};
    font-family: ${theme.font.family.workSans};
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.copy};
    line-height: ${theme.font.lineHeight.copy};
    white-space: pre-wrap;
  }

  span > svg {
    color: ${theme.color.blackAlpha(0.6)};
  }

  span.selected svg {
    color: ${theme.color.designSystem.purple70};
  }
`;

export default Wrapper;
