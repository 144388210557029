import React from 'react';
import { connect } from 'react-redux';
import GroupParticipants from '../../Legacy/GroupParticipants';
import {
  selectors,
  operations as creators,
} from '../../../../../store/conversations';
import { ConversationIcon } from './RenameGroupModal.styled';

const stopPropagation = (e) => e.stopPropagation();

const addParticipants = (participantIds) =>
  creators.addParticipants(participantIds);
const changeGroupName = (title) => creators.renameConversation(title);
const removeParticipant = (participantId) =>
  creators.removeParticipant(participantId);

const compareParticipants = (p1, p2) => {
  const s1 = `${p1.firstName} ${p1.lastName}`;
  const s2 = `${p2.firstName} ${p2.lastName}`;
  return s1 < s2 ? -1 : 1;
};

const mapStateToProps = (state, ownProps) => {
  const conversationTitle = selectors.conversationTitle(
    state,
    ownProps.conversation.id
  );

  return {
    addParticipantsFirst: state.inboxv2.addParticipantOpened,
    classRoomId: state.classRoom.current,
    groupId: ownProps.conversation.id,
    groupName: conversationTitle,
    icon: () => React.createElement(ConversationIcon, ownProps),
    open: state.inboxv2.viewParticipantsOpened,
    onClick: stopPropagation,
    participants: Object.keys(ownProps.conversation.participants)
      .map((id) => state.data.users[id])
      .filter((u) => !!u)
      .sort(compareParticipants),
    title: conversationTitle,
    userId: state.user.userId,
    boundToSpaceGroup: !!ownProps.conversation.boundToSpaceGroup,
  };
};

const mapDispatchToProps = {
  addParticipants,
  changeGroupName,
  handleClose: creators.closeViewParticipants,
  removeParticipant,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  handleClose: (...params) => {
    dispatchProps.handleClose(...params);
    ownProps.onClose();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GroupParticipants);
