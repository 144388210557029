import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FlipMove from 'react-flip-move';
import { analyticsTrackEvent } from '@core/utils/analytics';
import {
  COMMENT_ITEM_TYPE,
  LOAD_MORE_COMMENTS,
} from '@core/constants/analytics';
import { CommentEntityType } from '@core/utils/commentsEntityTypes';
import { ENTER } from '../../../../utils/keyCodes';
import {
  ToggleContainer,
  LoadMoreTextWrapper,
  HideCommentsWrapper,
  ShowCommentsWrapper,
  ShowCommentsCarretWrapper,
  ShowCommentsContainer,
  ShowCommentsCarret,
  LoadMoreContainer,
  CommentListContainer,
  CommentWrapper,
  CommentListWrapper,
  Spacer,
} from './styles';
import CommentCell, {
  CommentAnalyticsItemType,
} from '../CommentCell/CommentCell';
import { operations as reactionOperations } from '../../../../store/reactions';

const COMMENTS_PER_LOAD = 5;
const INITIAL_COMMENTS_LOAD = 5;

type CommentListCollapsableProps = {
  showInitialComments?: boolean;
  showComments?: boolean;
  isReply?: boolean;
  entityId: string;
  entityType: CommentEntityType;
  itemType?: CommentAnalyticsItemType;
  rootCommentId?: string;
  comments: string[];
  commentsCount: number;
  spaceId?: string;
};

type PrevProps =
  | {
      comments: string[];
      showComments?: boolean;
    }
  | undefined;

const usePrevious = (value) => {
  const ref = useRef<PrevProps>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const CommentListCollapsable = ({
  showInitialComments = false,
  showComments = false,
  isReply = false,
  entityId,
  entityType,
  itemType,
  rootCommentId = undefined,
  comments,
  commentsCount,
  spaceId = undefined,
}: CommentListCollapsableProps) => {
  const dispatch = useDispatch();

  const commentsIdBlock = `comments-${Math.round(Math.random() * 1000)}`;

  const [numVisibleComments, setNumVisibleComments] = useState(
    showInitialComments ? INITIAL_COMMENTS_LOAD : 0
  );
  const [collapsed, setCollapsed] = useState(!showInitialComments);
  const [shouldRenderList, setShouldRenderList] = useState(false);
  const [initialFetch, setInitialFetch] = useState(false);
  const prevProps: PrevProps = usePrevious({
    comments,
    showComments,
  });

  const toggleReaction = ({ emojiName, commentId }) =>
    dispatch(
      reactionOperations.toggleReaction({
        emojiName,
        itemId: commentId,
        itemType: COMMENT_ITEM_TYPE,
      })
    );

  const getVisibleComments = useCallback(() => {
    return numVisibleComments > 0 && numVisibleComments < comments.length
      ? comments.slice(Math.max(0, comments.length - numVisibleComments))
      : comments;
  }, [numVisibleComments, comments]);

  const setVisibleComments = useCallback(() => {
    setShouldRenderList(true);
    setNumVisibleComments(comments.length);
    analyticsTrackEvent(LOAD_MORE_COMMENTS, {
      activeClassroom: spaceId,
      entityType,
      entityId,
    });
  }, [comments]);

  const visibleComments = getVisibleComments();

  const shouldRenderBorder = useCallback(
    (index) => !isReply && index !== visibleComments.length - 1,
    [isReply, visibleComments]
  );

  const handleCollapse = useCallback(() => {
    if (collapsed && !initialFetch) {
      setInitialFetch(true);
      setNumVisibleComments(COMMENTS_PER_LOAD);
    } else {
      setVisibleComments();
    }

    setCollapsed(!collapsed);
  }, [collapsed, initialFetch]);

  const handleCollapseFromKeyboard = useCallback((e) => {
    if (e.keyCode === ENTER) handleCollapse();
  }, []);

  const renderLoadMore = useCallback(() => {
    if (!collapsed && numVisibleComments < comments.length) {
      const nbCommentsToLoad = comments.length - numVisibleComments;
      const loadMoreText = isReply
        ? `See ${nbCommentsToLoad} previous repl${
            nbCommentsToLoad > 1 ? 'ies' : 'y'
          }`
        : 'See previous comments';

      return (
        <LoadMoreContainer
          isReply={isReply}
          onClick={() => setVisibleComments()}
        >
          <LoadMoreTextWrapper>{loadMoreText}</LoadMoreTextWrapper>
        </LoadMoreContainer>
      );
    }

    return <Spacer />;
  }, [numVisibleComments, comments, isReply]);

  const renderToggleContainer = useCallback(() => {
    const Wrapper = collapsed ? ShowCommentsWrapper : HideCommentsWrapper;
    const toggleText = collapsed
      ? `Show ${commentsCount} comment${commentsCount > 1 ? 's' : ''}`
      : 'Hide comments';

    return isReply ? null : (
      <ToggleContainer
        role="button"
        tabIndex={0}
        aria-expanded={!collapsed}
        aria-controls={commentsIdBlock}
        onClick={handleCollapse}
        onKeyDown={handleCollapseFromKeyboard}
        collapsed={collapsed}
      >
        <ShowCommentsContainer>
          <Wrapper>
            {toggleText}
            <ShowCommentsCarretWrapper>
              <ShowCommentsCarret collapsed={collapsed} />
            </ShowCommentsCarretWrapper>
          </Wrapper>
        </ShowCommentsContainer>
      </ToggleContainer>
    );
  }, [isReply, collapsed, commentsCount]);

  useEffect(() => {
    if (showComments) {
      handleCollapse();
    }
  }, []);

  useEffect(() => {
    if (prevProps) {
      const prevCommentsCount = prevProps?.comments?.length;
      const commentDiff = comments.length - prevCommentsCount;

      const newCommentCreated = prevProps?.showComments !== showComments;

      if (newCommentCreated && numVisibleComments === 0) {
        setNumVisibleComments(numVisibleComments + 1);
        setShouldRenderList(true);
        setCollapsed(false);
      }

      if (numVisibleComments > 0 && commentDiff > 0) {
        setNumVisibleComments(numVisibleComments + commentDiff);
        setShouldRenderList(true);
        setCollapsed(false);
      } else if (commentDiff === 0 && shouldRenderList) {
        setShouldRenderList(false);
      }
    }
  }, [prevProps, comments, showComments]);

  return (
    <CommentListContainer isReply={isReply}>
      <CommentListWrapper commentsCount={commentsCount}>
        {renderToggleContainer()}
        {renderLoadMore()}
        {!collapsed && (
          <CommentWrapper id={commentsIdBlock} isReply={isReply}>
            <FlipMove
              enterAnimation="fade"
              key={entityId}
              disableAllAnimations={!shouldRenderList || isReply}
            >
              {visibleComments.map((commentId, idx) => (
                <div key={commentId}>
                  <CommentCell
                    sequenceId={`entity-${entityId}-comment-${commentId}`}
                    key={commentId}
                    id={commentId}
                    itemType={itemType}
                    rootCommentId={rootCommentId || commentId}
                    toggleReaction={toggleReaction}
                    renderBorder={shouldRenderBorder(idx)}
                    ReplyContainer={CommentListCollapsable}
                  />
                </div>
              ))}
            </FlipMove>
          </CommentWrapper>
        )}
      </CommentListWrapper>
    </CommentListContainer>
  );
};

export default CommentListCollapsable;
