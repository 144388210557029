import { connect } from 'react-redux';
import Quote from './Quote';

import { user, fetchingUser } from '../../../../../core/selectors/users';

import { fetchUser } from '../../../../../core/actions/users';

const mapStateToProps = (state, ownProps) => {
  const author = user(state, ownProps.writer);
  return {
    author,
    shouldFetchUser: !fetchingUser(state, ownProps.writer) && !author,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUser: () => dispatch(fetchUser(ownProps.writer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
