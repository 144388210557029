import styled, { css } from 'react-emotion';
import * as material from '@material-ui/core';
import { theme, styleUtils } from '@ublend-npm/aulaui-next';

export const TextField = styled(material.TextField)`
  textarea {
    ${styleUtils.typography.headline()};
    color: ${theme.color.designSystem.blackOpacity87};
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
  }

  .material-section-title-editable {
    color: ${theme.color.designSystem.blackOpacity87};
  }
`;

export const textField = css`
  &:after {
    border-bottom: 2px solid ${theme.color.purple};
  }
`;

export const ExtraLarge = styled('h1')`
  display: inline-block;
  ${styleUtils.typography.headline()};
  font-size: ${theme.font.size.headline};
  font-family: Work Sans;
  font-style: normal;
  font-weight: ${theme.font.weight.semiBold};
  font-size: 24px;
  line-height: 36px;
  margin: 0;
`;
