import { connect } from 'react-redux';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';

import { isLocalId } from '@core/utils/localId';
import {
  operations as creators,
  selectors as inboxSelectors,
} from '../../../store/conversations';
import * as userSelectors from '../../../../core/selectors/user';
import groupMessages from './groupMessages';

import MessageTable from './MessageTable';

const putPendingLast = (messages) => {
  const pendingReducer = (curr, next) => {
    const target = isLocalId(next.id) ? 'pending' : 'saved';
    curr[target].push(next);
    return curr;
  };
  const { saved, pending } = messages.reduce(pendingReducer, {
    saved: [],
    pending: [],
  });
  return [...saved, ...pending];
};

const flow =
  (...fns) =>
  (param) =>
    fns.reduce((acc, next) => next(acc), param);

const getId = (o) => (o || {}).id;
const messagesListsEqual = (a1, a2) => {
  if (a1 === a2) {
    return true;
  }

  if (a1.length !== a2.length) {
    return false;
  }
  for (let i = 0; i < a1.length; i += 1) {
    if (getId(a1[i]) !== getId(a2[i])) {
      return false;
    }
  }
  return true;
};

const createMessagesListsEqualSelector = createSelectorCreator(
  defaultMemoize,
  messagesListsEqual
);

const messagesForCurrentConversationSelector = createSelector(
  inboxSelectors.inboxData,
  inboxSelectors.currentConversationId,
  (inboxv2, currentConversationId) => {
    const state = { data: { inboxv2 } };
    const getMessage = (id) => inboxSelectors.message(state, id);
    const messageIds =
      inboxSelectors.conversationMessages(state, currentConversationId) || [];
    const messages = messageIds.map(getMessage);
    return messages;
  }
);

const groupsSelector = createMessagesListsEqualSelector(
  messagesForCurrentConversationSelector,
  flow(putPendingLast, groupMessages, (groups) => groups)
);

const mapStateToProps = (state) => {
  const conversationId = inboxSelectors.currentConversationId(state);
  const currentMessageId = inboxSelectors.currentConversationMessageId(state);

  const conversation = inboxSelectors.conversation(state, conversationId);

  const hasOlderMessages = inboxSelectors.hasOlderMessages(
    state,
    conversationId
  );
  const hasNewerMessages = inboxSelectors.hasNewerMessages(
    state,
    conversationId
  );
  const fetchingMessages = inboxSelectors.fetchingMessagesForConversation(
    state,
    conversationId
  );
  const initialFetchDone = state.inboxv2.initialFetchDone[conversationId];
  const latestMessageDate = inboxSelectors.conversationLatestMessageDate(
    state,
    conversationId
  );
  const shouldFetchOnMount =
    !fetchingMessages && (hasNewerMessages || !initialFetchDone);
  const currentUserId = userSelectors.currentUserId(state);

  return {
    currentMessageId,
    hasOlderMessages,
    hasNewerMessages,
    conversationCreatedAt: conversation.createdAt,
    conversationLoaded: !!inboxSelectors.currentConversation(state),
    conversationId,
    emptyConversation: !inboxSelectors.conversationLatestMessage(
      state,
      conversationId
    ),
    featSetConversationUnreadEnabled: false,
    fetchingMessages,
    isUnread: inboxSelectors.isConversationUnreadBy(
      state,
      conversationId,
      currentUserId
    ),
    oldestMessageDate: inboxSelectors.oldestLoadedMessage(
      state,
      conversationId
    ),
    newestLoadedMessageDate: inboxSelectors.newestLoadedMessage(
      state,
      conversationId
    ),
    latestMessageDate,
    latestMessageIsOwn: inboxSelectors.conversationLatestMessageIsOwn(
      state,
      conversationId
    ),
    shouldFetchOnMount,
    groups: groupsSelector(state),
  };
};

const fetchInitial = () => creators.fetchMessages({ replace: true });
const fetchPrevious = (before) => creators.fetchMessages({ before });
const fetchNext = (after) => creators.fetchMessages({ after });
const setConversationRead = (readUpTo) =>
  creators.setConversationRead(readUpTo);
const fetchMessagesAroundMessageId = (messageId) =>
  creators.fetchMessages({
    aroundMessageId: messageId,
    replace: true,
  });
const highlightMessage = (messageId) => creators.highlightMessage(messageId);

const mapDispatchToProps = {
  fetchInitial,
  fetchPrevious,
  fetchNext,
  setConversationRead,
  fetchMessagesAroundMessageId,
  highlightMessage,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const newState = {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchPrevious: () =>
      dispatchProps.fetchPrevious(stateProps.oldestMessageDate),
    fetchNext: () =>
      dispatchProps.fetchNext(stateProps.newestLoadedMessageDate),
  };

  delete newState.oldestMessageDate;
  return newState;
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, {
  forwardRef: true,
})(MessageTable);
