import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  padding-bottom: ${theme.spacing.small};
  padding-left: ${theme.spacing.medium};
  padding-right: ${theme.spacing.medium};
  box-sizing: border-box;
  max-width: calc(584px + ${theme.spacing.medium} + ${theme.spacing.medium});
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
`;
