import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import EducatorArea from './EducatorArea';
import * as appCreators from '../../../../../actions/inboxv2';
import { operations as creators } from '../../../../../store/conversations';
import { isCreatingConversation } from '../../../../../../core/conversations/selectors';
import { fetchingUsers, user } from '../../../../../../core/selectors/users';
import * as classRoomSelectors from '../../../../../../core/selectors/classroom';
import { fetchUsers } from '../../../../../../core/actions/users';

const isFetchingUsers = createSelector(
  fetchingUsers,
  classRoomSelectors.instructorUsers,
  (users, educatorsIds = []) =>
    educatorsIds.reduce((acc, id) => acc || users[id], false)
);

const mapStateToProps = (state) => ({
  educatorsIds: classRoomSelectors.instructorUsers(state),
  educators: classRoomSelectors.instructorUsers(state).reduce((acc, id) => {
    const usr = user(state, id);
    return [...acc, ...(usr ? [usr] : [])];
  }, []),
  fetching: isFetchingUsers(state),
  spaceId: classRoomSelectors.currentClassRoomId(state),
  creating: isCreatingConversation(state),
  isReadOnlyUser: classRoomSelectors.accessingCurrentSpaceAsStaff(state),
});

const mapDispatchToProps = (dispatch) => ({
  createConversation: (spaceId) =>
    dispatch(
      creators.createConversation({
        spaceId,
        educatorOnly: true,
      })
    ),
  selectConversation: (conversationId) =>
    dispatch(appCreators.selectConversation(conversationId)),
  fetchUsers: (users) => dispatch(fetchUsers(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EducatorArea);
