import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const QuoteViewContainer = styled('div')`
  display: flex;
  padding-left: 53px;
  flex-direction: row;
  margin-top: ${({ firstMessage }) => (firstMessage ? '7px' : '14px')};
  margin-bottom: 14px;
  cursor: pointer;
`;

export const QuoteDelimiter = styled('div')`
  border-left: 2px solid ${theme.color.mediumBlue};
  margin-bottom: -5px;
  margin-top: -5px;
  padding-right: 12px;
`;

export const QuoteMessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const QuoteContainer = styled('div')`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.color.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
`;

export const Author = styled('div')`
  color: ${theme.color.mediumBlue};
  font-size: smaller;
  font-weight: bold;
`;
