import styled from 'react-emotion';

export const ConversionDetailsContainer = styled.div`
  margin-top: 11px;
  > div:first-child {
    margin-top: 20px;
  }
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const SubTitle = styled.div`
  font-size: 12px;
`;

export const Content = styled.div`
  margin-top: 10px;
`;

export const EmptyTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
`;
