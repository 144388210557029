import { connect } from 'react-redux';
import RestoreMaterialDrawer from './RestoreMaterialDrawer';
import { selectSectionInJourney as redirectToPage } from '../../../../../actions/materials';
import { operations as toastOperations } from '../../../../../store/toasts';
import RestoreMaterialsToastMessage from './RestoreMaterialsToastMessage';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRestore: (pageId, pageTitle) => {
    dispatch(
      toastOperations.showToast({
        message: RestoreMaterialsToastMessage({ pageTitle }),
      })
    );
    dispatch(redirectToPage(pageId, ownProps.educatorArea));
  },
});

export default connect(null, mapDispatchToProps)(RestoreMaterialDrawer);
