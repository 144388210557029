import styled from 'react-emotion';
import { theme, Avatar as UnstyledAvatar } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  display: flex;
  align-items: ${({ row }) => (row ? 'center' : 'initial')};
`;

export const Description = styled('div')`
  display: flex;
  align-items: ${({ row }) => (row ? 'center' : 'flex-start')};
  height: ${({ row }) => (row ? `${theme.spacing.xLarge}` : 'initial')};
  font-family: 'Work Sans', sans-serif;
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.size.copy};
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  justify-content: ${({ row }) => (row ? 'flex-start' : 'space-between')};
`;

export const Avatar = styled(UnstyledAvatar)`
  margin-right: ${theme.spacing.xSmall};
  flex-shrink: 0;
`;

export const Title = styled('span')`
  font-weight: ${({ bold }) =>
    bold ? theme.font.weight.bold : theme.font.weight.regular};
  color: ${theme.color.mediumBlue};
`;

export const Subtitle = styled('span')`
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.grey11};
  margin-left: ${({ row }) => (row ? theme.spacing.xSmall : 0)};
`;
