import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import useMutateApi from '../../api/hooks/useMutateApi';
import { COMMENT } from '../../constants/endpoints';
import { COMMENTS_DELETE } from '../../constants/actions';
import { newError } from '../../actions/errors';
import removeCommentFromQueryData from '../utils/removeCommentFromQueryData';
import queryKeys from './queryKeys';

const getCommentAction = (status, payload) => ({
  type: COMMENTS_DELETE,
  status,
  ...payload,
});

const useDeleteComment = (comment) => {
  const { id, entityType, entityId } = comment;
  const queryClient = useQueryClient();
  const queryKey = queryKeys.comments(entityType, entityId);
  const dispatch = useDispatch();

  return useMutateApi(
    {
      url: COMMENT(id),
      method: 'DELETE',
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(queryKey);
        dispatch(getCommentAction('started', {}));
      },
      onSuccess: () => {
        removeCommentFromQueryData(queryClient, comment);
        dispatch(getCommentAction('success', { comment }));
      },
      onError: (error) => {
        dispatch(newError(error, 'deleteComment'));
        dispatch(getCommentAction('error', { error }));
      },
    }
  );
};

export default useDeleteComment;
