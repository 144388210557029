import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Content = styled('div')`
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${theme.color.text};
    width: 65%;
  }
`;

export const Container = styled('div')`
  position: relative;
  padding: 56px 80px;
  z-index: 1;
`;

export const Copy = styled('p')`
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.large};
`;
