import React from 'react';

import { MATERIAL_VERSIONS_NO_VERSION } from '../../../../../constants/texts';
import noContent from '../../../../../../static/no-content.png';
import { Center } from './common.styled';

export default () => (
  <Center>
    <img src={noContent} alt="no student" width={240} height={240} />
    <p>{MATERIAL_VERSIONS_NO_VERSION}</p>
  </Center>
);
