import styled from 'react-emotion';

export const Container = styled('div')`
  outline: none;
`;

export const EditorWrapper = styled('div')`
  padding: 24px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden; // prevents empty editor placeholder from overflowing
  ${({ readOnly }) => (readOnly ? 'opacity: 0.5;' : '')}
`;
