import MainStep from './views/Main';
import StudentsListStep from './views/StudentsList';
import PropertiesStep from './views/Properties';

import {
  CREATE_EXTENSION_STEP,
  MAIN_STEP,
  PROPERTIES_STEP,
  STUDENTS_LIST_STEP,
} from './constants';

const steps = {
  [MAIN_STEP]: {
    component: MainStep,
    actions: {
      primary: {
        label: () => 'Next',
        stepId: PROPERTIES_STEP,
        isDisabled: (props, state) => {
          return (
            !state.extensionName ||
            (!state.assignedStudents.length && !state.groups.length)
          );
        },
      },
      secondary: {
        label: () => 'Cancel',
      },
    },
    getRequiredProps: (props) => {
      return {
        title: props.title,
        unassignedStudents: props.unassignedStudents,
        spaceGroups: props.spaceGroups,
        onClose: props.onClose,
        isLoadingStudents: props.isLoadingStudents,
      };
    },
  },
  [STUDENTS_LIST_STEP]: {
    component: StudentsListStep,
    actions: {
      primary: {
        label: () => 'Next',
        stepId: PROPERTIES_STEP,
        isDisabled: (props, state) => {
          return (
            !state.extensionName ||
            (!state.assignedStudents.length && !state.groups.length)
          );
        },
      },
      secondary: {
        label: () => 'Cancel',
      },
    },
    getRequiredProps: (props) => {
      return {
        unassignedStudents: props.unassignedStudents,
      };
    },
  },
  [PROPERTIES_STEP]: {
    component: PropertiesStep,
    actions: {
      primary: {
        label: (isEditing) =>
          isEditing ? 'Apply changes' : 'Create extension',
        stepId: CREATE_EXTENSION_STEP,
      },
      secondary: {
        label: () => 'Back',
        stepId: MAIN_STEP,
      },
    },
    getRequiredProps: (props) => {
      return {
        assignment: props.assignment,
        onClose: props.onClose,
        assignmentCreationError: props.assignmentCreationError,
      };
    },
  },
};

export default steps;
