import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { Dialog, DialogActions, theme } from '@ublend-npm/aulaui-next';
import {
  SpaceUserType,
  SpaceGroupType,
  AssignmentType,
  AssignmentCreationErrorType,
} from './ExtensionModal.types';
import Conversion from './steps/views/Conversion';
import { DialogContentStyled } from './ExtensionModal.styled';
import ExistingSubmissionErrorDialog from './components/ExistingSubmissionErrorDialog/ExistingSubmissionErrorDialog';
import {
  PARENT_ASSIGNMENT_SUBMISSIONS_EXIST_ERROR,
  STUDENT_WITH_SUBMISSION_IN_EXTENSION_ERROR,
} from '../constants';
import getExistingSubmissions from '../../Submissions/getExistingSubmissions';

const aulaTheme = createTheme({
  typography: {
    fontFamily: theme.font.family.workSans,
  },
  palette: {
    primary: {
      main: theme.color.purple,
    },
    text: {
      hint: theme.color.grey11,
    },
  },
});

const hasSubmissionInParent = (assignmentCreationError) => {
  return (
    assignmentCreationError &&
    assignmentCreationError.errorCode &&
    (assignmentCreationError.errorCode ===
      PARENT_ASSIGNMENT_SUBMISSIONS_EXIST_ERROR ||
      assignmentCreationError.errorCode ===
        STUDENT_WITH_SUBMISSION_IN_EXTENSION_ERROR)
  );
};

const ConversionModal = (props) => {
  const {
    unassignedStudents,
    spaceGroups,
    onClose,
    assignments,
    dialogTitle,
    assignment,
    assignmentCreationError,
    studentIdsWithExistingSubmissions,
    redirectToSubmissions,
    resetErrorState,
    open,
    getSubmissions,
    fetchAllSubmissionsIncludingExtensions,
  } = props;

  const { provider, id } = assignment;

  const [destinationAssignmentData, setDestinationAssignmentData] = useState(
    {}
  );

  const usersWithSubmissionsInAssignment = getExistingSubmissions(
    getSubmissions(id)
  ).map((item) => item.user.id);

  const getUsersWithSubmissionsInDestination = () => {
    const destinationAssignment = assignments.find(
      (item) => item.id === destinationAssignmentData.destinationAssignmentId
    );
    const extensionsIdsFromDestination =
      destinationAssignment && destinationAssignment.extensions
        ? destinationAssignment.extensions.map((extension) => extension.id)
        : [];
    const ids = [
      ...extensionsIdsFromDestination,
      destinationAssignmentData.destinationAssignmentId,
    ];

    const userSubmissionsInAssignments = ids.map((assignmentId) =>
      getExistingSubmissions(getSubmissions(assignmentId)).map(
        (item) => item.user.id
      )
    );

    return userSubmissionsInAssignments.reduce(
      (acc, users) => [...acc, ...users],
      []
    );
  };

  const usersWithSubmissonsInDestination =
    getUsersWithSubmissionsInDestination();

  const studentsWithValidSubmissions = usersWithSubmissionsInAssignment.filter(
    (userId) =>
      !usersWithSubmissonsInDestination.find(
        (userWithSubmission) => userWithSubmission === userId
      )
  );

  const studentsWithSubmissionInAssignment =
    studentsWithValidSubmissions.reduce((acc, userId) => {
      const user = unassignedStudents.find(
        ({ id: studentId }) => studentId === userId
      );

      if (!user) {
        return acc;
      }

      return [
        ...acc,
        {
          ...user,
          userActionDisabled: true,
        },
      ];
    }, []);

  const [loading, setLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState({
    assignedStudents: studentsWithSubmissionInAssignment,
    groups: [],
    unassignedStudents,
  });

  useEffect(() => {
    resetErrorState();
  }, []);

  const studentsWithExistingSubmissions = useMemo(() => {
    if (!unassignedStudents?.length) {
      return [];
    }
    return studentIdsWithExistingSubmissions.map((studentId) =>
      unassignedStudents.find((student) => student.id === studentId)
    );
  }, [studentIdsWithExistingSubmissions, unassignedStudents]);

  const getGreyedOutUsers = (usersInDestination, usersInAssignment) => {
    return usersInDestination.filter(
      (userId) => !usersInAssignment.includes(userId)
    );
  };

  const save = async () => {
    const { user, spaceId, accessToken, convertToExtension } = props;

    const studentIds = assignmentData.unassignedStudents.map(
      (student) => student.id
    );
    setLoading(true);

    const assignmentDetails = {
      ...assignmentData,
      parentAssignmentId: destinationAssignmentData.destinationAssignmentId,
      classId: spaceId,
    };

    const extension = {
      ...props.assignment,
      ...assignmentDetails,
      students: studentIds,
      submissions: [],
    };

    const convertedExtension = await convertToExtension(
      spaceId,
      extension,
      accessToken,
      user
    );

    // Redirect to parent assignment if conversion is successful
    if (convertedExtension) {
      onClose(() =>
        redirectToSubmissions(
          spaceId,
          destinationAssignmentData.destinationAssignmentId
        )
      );
    }
  };

  const actions = {
    primary: {
      label: 'Convert',
      isDisabled: (destinationAssignmentDetails, assignmentDetails) => {
        return (
          !assignmentDetails.title ||
          !assignmentDetails.unassignedStudents.length ||
          !destinationAssignmentDetails.parentAssignmentTitle
        );
      },
    },
    secondary: {
      label: 'Cancel',
    },
  };

  const getActions = () => {
    if (!actions) {
      return null;
    }

    const { primary, secondary } = actions;

    const actionsConfig = {
      primary: primary && {
        label: primary.label,
        onClick: () => save(),
        disabled:
          primary.isDisabled &&
          primary.isDisabled(destinationAssignmentData, assignmentData),
        loading,
      },
      secondary: secondary && {
        label: secondary.label,
        onClick: () => {
          onClose();
        },
      },
    };

    return (
      <DialogActions
        primary={actionsConfig.primary}
        secondary={actionsConfig.secondary}
      />
    );
  };

  const updateDestinationAssignmentData = (newProps) => {
    // fetch submissions for destination assignment and for its extensions
    if (newProps.destinationAssignmentId) {
      const destinationAssignment = assignments.find(
        (item) => item.id === newProps.destinationAssignmentId
      );
      const extensionsIdsFromDestination = destinationAssignment.extensions
        ? destinationAssignment.extensions.map((extension) => extension.id)
        : [];
      const ids = [
        ...extensionsIdsFromDestination,
        newProps.destinationAssignmentId,
      ];

      fetchAllSubmissionsIncludingExtensions({
        accessToken: props.accessToken,
        assignmentsIds: ids,
      });
    }

    setDestinationAssignmentData((oldProps) => ({
      ...oldProps,
      ...newProps,
    }));
  };

  const updateAssignmentData = (newProps) => {
    setAssignmentData((oldProps) => ({
      ...oldProps,
      ...newProps,
    }));
  };

  const hasError =
    assignmentCreationError &&
    !hasSubmissionInParent(assignmentCreationError) &&
    !!assignmentCreationError.errorCode;

  if (hasError && loading) {
    setLoading(false);
  }

  return hasSubmissionInParent(assignmentCreationError) ? (
    <ExistingSubmissionErrorDialog
      students={studentsWithExistingSubmissions}
      provider={provider}
      onClose={onClose}
      isConversion
    />
  ) : (
    <MuiThemeProvider theme={aulaTheme}>
      <Dialog id="extension-modal" open={open} onClose={onClose}>
        <DialogContentStyled>
          <Conversion
            data={destinationAssignmentData}
            assignmentData={assignmentData}
            setData={updateDestinationAssignmentData}
            setAssignmentData={updateAssignmentData}
            spaceGroups={spaceGroups}
            onClose={onClose}
            assignments={assignments}
            assignment={assignment}
            dialogTitle={dialogTitle}
            hasError={hasError}
            greyedOutUsers={getGreyedOutUsers(
              usersWithSubmissonsInDestination,
              usersWithSubmissionsInAssignment
            )}
          />
          {getActions()}
        </DialogContentStyled>
      </Dialog>
    </MuiThemeProvider>
  );
};

ConversionModal.propTypes = {
  // Modal control props
  dialogTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  convertToExtension: PropTypes.func.isRequired,
  redirectToSubmissions: PropTypes.func.isRequired,
  resetErrorState: PropTypes.func.isRequired,

  // Modal data props
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName: PropTypes.string,
  }).isRequired,
  accessToken: PropTypes.string,
  spaceId: PropTypes.string.isRequired,
  assignment: PropTypes.shape(AssignmentType).isRequired,
  unassignedStudents: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
  spaceGroups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)),
  getSubmissions: PropTypes.func.isRequired,
  fetchAllSubmissionsIncludingExtensions: PropTypes.func.isRequired,
  assignments: PropTypes.arrayOf(PropTypes.object),
  assignmentCreationError: PropTypes.shape(AssignmentCreationErrorType),
  studentIdsWithExistingSubmissions: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
};

ConversionModal.defaultProps = {
  onClose: () => {},
  unassignedStudents: [],
  spaceGroups: [],
  accessToken: '',
  assignments: [],
  assignmentCreationError: {},
};

export default React.memo(ConversionModal);
