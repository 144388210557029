import { newError } from '../errors';
import { COMMON_ROOM } from '../../constants/postFeedType';
import * as actionTypes from '../../constants/actions';
import createPostApi from '../../api/posts/create';

const createPostStart = () => ({
  type: actionTypes.CLASSROOM_CREATE_POST,
  status: 'started',
});

const createPostSuccess = (post, dispatch) => {
  dispatch({
    type: actionTypes.CLASSROOM_CREATE_POST,
    status: 'success',
    post,
  });
};

const createPostError = (postData, error, dispatch) => {
  dispatch(newError(error, 'createPostError'));
  dispatch({
    type: actionTypes.CLASSROOM_CREATE_POST,
    status: 'error',
    postData,
    error,
  });
};

export const createPost = (postData) => async (dispatch) => {
  const { content, correlationId, feedType } = postData;

  if (!content) {
    createPostError(postData, 'Missing properties in postData', dispatch);
    return;
  }

  dispatch(createPostStart());

  try {
    const post = await createPostApi({
      spaceId: postData.spaceId,
      content: JSON.stringify(content),
      scheduledFor: postData.scheduledFor,
      correlationId,
      feedType: feedType || COMMON_ROOM,
    });
    createPostSuccess(post, dispatch);
  } catch (error) {
    dispatch(createPostError(postData, error, dispatch));
  }
};
