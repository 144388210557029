import { connect } from 'react-redux';
import CommentUserBanner from './CommentUserBanner';
import { currentUserId as getCurrentUserId } from '../../../../../core/selectors/user';
import { accessingCurrentSpaceAsStaff } from '../../../../../core/selectors/classroom';
import { isInstructor } from '../../../../utils/user';

const mapStateToProps = (state, ownProps) => {
  const currentUserId = getCurrentUserId(state);
  return {
    isEducator: isInstructor(currentUserId),
    isAuthor: currentUserId === ownProps.comment.author,
    isReadOnlyUser: accessingCurrentSpaceAsStaff(state),
  };
};

const CommentUserBannerContainer = connect(mapStateToProps)(CommentUserBanner);

export default CommentUserBannerContainer;
