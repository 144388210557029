import styled from 'react-emotion';

import Schedule from '@material-ui/icons/Schedule';
import { theme, Button } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  color: ${theme.color.blackAlpha(0.6)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  min-width: 30px;
`;

export const ScheduledIcon = styled(Schedule)`
  padding-right: 6px;
  width: 20px;
  height: 20px;
`;

export const ChangeScheduledItemButton = styled(Button)`
  margin-left: 10px;
`;
