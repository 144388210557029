import styled, { css } from 'react-emotion';
import { DateTimePicker } from '@material-ui/pickers';
import UnStyledGroupIcon from '@material-ui/icons/Group';
import UnStyledHiddenIcon from '@material-ui/icons/VisibilityOff';
import { theme, TextField } from '@ublend-npm/aulaui-next';
import { createTheme, TextField as MuiTextField } from '@material-ui/core';
import AnonymousIcon from '../../../AnonymousIcon';

// this is equivalent to rgba(0,0,0, 0.87) over white
const darkGray = 'rgb(33,33,33)';

// this is equivalent to rgba(0,0,0, 0.6) over white
const gray = 'rgb(102,102,102)';

export const formTheme = createTheme({
  palette: {
    primary: {
      main: theme.color.purple,
    },
  },
  typography: {
    fontFamily: theme.font.family.workSans,
    fontSize: 14,
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: '#666666',
      },
    },
  },
});

export const CommonStyle = `
  input {
    font-size: 14px;
  }

  label {
    font-size: 14px;
  }
`;

export const Title = styled('div')`
  color: #31405c;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
`;

export const SubTitle = styled('div')`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 12px;
`;

export const StyledPickerLeft = styled(DateTimePicker)`
  width: calc(50% - 6px);
  margin-right: 6px;
  margin-top: 20px;

  ${CommonStyle}
`;

export const datePickerClass = styled('div')`
  z-index: 9999999999;
`;

export const StyledPickerRight = styled(DateTimePicker)`
  width: calc(50% - 7px);
  margin-left: 7px;
  margin-top: 20px;

  ${CommonStyle}
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px; /* 40px - 2px(borders) */
  margin-top: ${({ isTop }) => (isTop ? '15px' : '0')};
`;

export const SwitchLabel = styled('label')`
  display: block;
  color: ${darkGray};
  font-size: 14px;
  margin-left: ${({ noIndent }) => (noIndent ? '0' : '12px')};
`;

export const StyledTextField = styled(TextField)`
  margin-top: 24px;
  ${CommonStyle}
`;

export const StyledDateField = styled(TextField)`
  ${CommonStyle}
`;

export const GradeTypeSelect = styled(MuiTextField)`
  margin-top: 24px;
  width: 100%;

  ${CommonStyle}
`;

export const ModalContent = styled('div')`
  padding: 0 30px;
  overflow: hidden auto;
  align-self: stretch;
  padding-top: 14px;
  padding-bottom: 30px;

  ${StyledTextField}:nth-child(1) {
    margin-top: 10px;
    color: red;
  }
`;

export const SwitchTitleContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const GroupIcon = styled(UnStyledGroupIcon)`
  font-size: 16px;
`;

export const HiddenIcon = styled(UnStyledHiddenIcon)`
  font-size: 24px;
  color: ${gray};
`;

export const GrayAnonymousIcon = styled(AnonymousIcon)`
  fill: ${gray};
`;

export const GroupSelector = styled('div')`
  margin-top: 8px;
  margin-left: 24px;
`;

export const providerCapitalize = css`
  text-transform: capitalize;
`;

export const switchOffColor = css`
  color: #666666;
`;

export const CircularProgressContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;
