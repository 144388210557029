import * as api from '../api/directMessages';
import { INBOX_FETCH_CONVERSATIONS } from '../actions';

const fetchConversationsStarted = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_CONVERSATIONS,
    status: 'started',
    payload,
  });

const fetchConversationsSuccess = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_CONVERSATIONS,
    status: 'success',
    payload,
  });

const fetchConversationsError = (dispatch, payload) =>
  dispatch({
    type: INBOX_FETCH_CONVERSATIONS,
    status: 'error',
    payload,
  });

const fetchConversations =
  (includeClosed = false, page = 1) =>
  async (dispatch) => {
    fetchConversationsStarted(dispatch, {});

    try {
      const { conversations, totalCount } =
        await api.conversations.fetchConversations(includeClosed, page);
      fetchConversationsSuccess(dispatch, { conversations, totalCount });
    } catch (error) {
      fetchConversationsError(dispatch, { error });
    }
  };

export default fetchConversations;
