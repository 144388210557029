import { EDUCATOR_ROLE } from '../../../constants/space';
import {
  STARTED_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants/actionsStatus';

export const fetchClassRoomParticipantsReducer = (state, action) => {
  switch (action.status) {
    case 'started': {
      if (action.reset) {
        const classRooms = { ...state.classRooms };

        const { spaceId } = action;
        classRooms[spaceId] = Object.assign({}, classRooms[spaceId] || {}, {
          participants: [],
        });
        return {
          ...state,
          classRooms,
        };
      }

      return state;
    }
    case 'success': {
      const classRooms = Object.assign({}, state.classRooms);

      if (action.participants.length > 0) {
        const { spaceId } = action;
        classRooms[spaceId] = Object.assign({}, classRooms[spaceId] || {}, {
          participants: [
            ...(classRooms[spaceId] ? classRooms[spaceId].participants : []),
            ...action.participants,
          ],
        });
      }

      const instructors = action.participants.reduce((acc, user) => {
        if (user.role !== EDUCATOR_ROLE) {
          return acc;
        }
        return [...acc, user.id];
      }, []);

      if (instructors.length) {
        const { spaceId } = action;
        const updatedInstructors = [
          ...new Set([
            ...(classRooms[spaceId] ? classRooms[spaceId].instructors : []),
            ...instructors,
          ]),
        ];
        classRooms[spaceId] = Object.assign({}, classRooms[spaceId] || {}, {
          instructors: updatedInstructors,
        });
      }

      return {
        ...state,
        classRooms,
      };
    }
    default:
      return state;
  }
};

export const fetchClassRoomParticipantsHistoryReducer = (state, action) => {
  switch (action.status) {
    case 'started': {
      if (action.reset) {
        const classRooms = { ...state.classRooms };

        const { spaceId } = action;
        classRooms[spaceId] = Object.assign({}, classRooms[spaceId] || {}, {
          participantsHistory: {},
          participantsHistoryCount: 0,
        });
        return {
          ...state,
          classRooms,
        };
      }

      return state;
    }
    case 'success': {
      const classRooms = Object.assign({}, state.classRooms);

      if (action.participantsHistoryCount > 0) {
        const { spaceId } = action;
        classRooms[spaceId] = Object.assign({}, classRooms[spaceId] || {}, {
          participantsHistory: {
            ...(classRooms[spaceId]
              ? classRooms[spaceId].participantsHistory
              : {}),
            ...action.participantsHistory,
          },
          participantsHistoryCount: action.participantsHistoryCount,
        });
      }

      return {
        ...state,
        classRooms,
      };
    }
    default:
      return state;
  }
};

export const removeClassRoomParticipantReducer = (state, action) => {
  switch (action.status) {
    case 'started': {
      return state;
    }
    case 'success': {
      const classRooms = Object.assign({}, state.classRooms);
      const classId = action.classRoomId;
      const filteredParticipants = classRooms[classId].participants.filter(
        (participant) => participant.id !== action.removedClassRoomUserId
      );

      const filteredInstructors = classRooms[classId].instructors.filter(
        (instructorId) => instructorId !== action.removedClassRoomUserId
      );

      classRooms[classId] = Object.assign({}, classRooms[classId], {
        participants: filteredParticipants,
        instructors: filteredInstructors,
      });

      return {
        ...state,
        classRooms,
      };
    }
    default:
      return state;
  }
};

export const addParticipantsToSpaceReducer = (state, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return { ...state, isAddingParticipantToSpace: true };
    case SUCCESS_STATUS: {
      const classRooms = Object.assign({}, state.classRooms);
      const classId = action.classRoomId;
      classRooms[classId].instructors = [
        ...classRooms[classId].instructors,
        ...action.userIds,
      ];
      return {
        ...state,
        classRooms,
        hasAddParticipantError: false,
        isAddingParticipantToSpace: false,
      };
    }
    case ERROR_STATUS:
      return {
        ...state,
        hasAddParticipantError: true,
        isAddingParticipantToSpace: false,
      };

    default:
      return state;
  }
};
