import styled, { css } from 'react-emotion';
import {
  theme,
  Button as MUIButton,
  List as AUIList,
} from '@ublend-npm/aulaui-next';

export const Button = styled(MUIButton)`
  width: 215px;
`;

export const ButtonContainer = styled('span')`
  width: 202px;
  margin-bottom: 35px;
  margin-right: 54px;
`;

export const educatorAreaContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const participantsContainer = css`
  display: flex;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
`;

export const headerContainer = css`
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 72px;
  padding-left: 16px;
`;

export const areaTitle = css`
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 20px;
  line-height: 24px;
  margin: 0px 15px 6px 0;
`;

export const hintContainer = css`
  display: flex;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
`;

export const hint = css`
  font-size: 14px;
  color: ${theme.color.grey6};
`;

export const List = styled(AUIList)`
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 25%;
  height: 100%;
  padding-top: 0;
  padding-left: 0;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const MaterialContent = styled('div')`
  flex-basis: 75%;
  height: 100%;
`;
