import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  height: 100%;
  flex: 1;
`;

export const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.medium};

  span.material-section-title-disabled {
    color: ${theme.color.designSystem.blackOpacity87};
  }
`;
