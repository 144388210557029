import { connect } from 'react-redux';
import * as classRoomSelectors from '@core/selectors/classroom';
import GetStarted from './GetStarted';

const mapStateToProps = (state) => ({
  editable: classRoomSelectors.isInstructor(state),
});

const GetStartedContainer = connect(mapStateToProps)(GetStarted);

export default GetStartedContainer;
