import React, { RefObject } from 'react';

import { LtiRequestType } from './types';

export type Lti1p3FormProps = {
  loginUrl: string;
  targetUrl: string;
  clientId: string;
  iss: string;
  type: LtiRequestType;
  target: string;
  loginHint: string;
};

export const Lti1p3Form = React.forwardRef(
  (
    {
      loginUrl,
      targetUrl,
      clientId,
      iss,
      type,
      target,
      loginHint,
    }: Lti1p3FormProps,
    ref: RefObject<HTMLFormElement>
  ) => (
    <form
      ref={ref}
      method="post"
      action={loginUrl}
      target={target}
      data-testid="lti1p3-form"
    >
      <input type="hidden" name="iss" value={iss} />
      <input type="hidden" name="client_id" value={clientId} />
      <input type="hidden" name="target_link_uri" value={targetUrl} />
      <input type="hidden" name="login_hint" value={loginHint} />
      {type === LtiRequestType.DEEP_LINKING && (
        <input
          type="hidden"
          name="lti_message_hint"
          value="LtiDeepLinkingRequest"
        />
      )}
    </form>
  )
);
