import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '../../../../../common/FieldSets/Autocomplete';
import { SearchIcon, SearchBarItem } from './SearchBar.styled';
import { SpaceUserType } from '../../ExtensionModal.types';

const normalizeUsersList = (users = []) => {
  return users.map(({ id, fullName, avatar, email }) => ({
    id,
    avatar,
    email,
    label: fullName,
  }));
};

const getUsersSuggestionsList = (users, selectedUsers) => {
  return users.filter(
    ({ id }) => !selectedUsers.find((selectedUser) => selectedUser.id === id)
  );
};

const onInputChange = (availableUsers, setDataSet) => (query) => {
  if (!query) {
    return setDataSet(availableUsers);
  }

  return setDataSet(
    availableUsers.filter(
      ({ label }) => label.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  );
};

const SearchBar = ({
  users,
  selectedUsers,
  isFetching,
  onChange,
  greyedOutUsers,
}) => {
  const normalizedUsers = normalizeUsersList(users);
  const availableUsers = getUsersSuggestionsList(
    normalizedUsers,
    selectedUsers
  );

  const [dataSet, setDataSet] = React.useState(availableUsers);

  const onSelect = (student) => {
    onChange([
      ...selectedUsers,
      {
        id: student.id,
        avatar: student.avatar,
        email: student.email,
        fullName: student.label,
      },
    ]);
  };

  const isDisabled = (id) => {
    return greyedOutUsers.includes(id);
  };

  const getUserInformation = () => {
    return (
      <>
        <div>This student can&#39;t be added because they already</div>
        <div>have a submission in the destination assignment</div>
      </>
    );
  };

  return (
    <Autocomplete
      id="group-participant-autocomplete"
      placeholder="Search for students"
      hideLabel
      dataset={dataSet}
      suggestionItemSize="medium"
      SuggestionItem={({ suggestion: { id, label, email, avatar } }) => (
        <SearchBarItem
          fullName={label}
          email={email}
          avatar={avatar}
          isDisabled={isDisabled(id)}
          actionIcon={!isDisabled(id) ? AddIcon : null}
          tooltip={!isDisabled(id) ? 'Add to extension' : undefined}
          disabledText={isDisabled(id) ? getUserInformation() : undefined}
        />
      )}
      greyedOutUsers={greyedOutUsers}
      onItemSelect={onSelect}
      onInputChange={onInputChange(availableUsers, setDataSet)}
      isFetching={isFetching}
      isDisabled={isFetching}
      InputStartAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

SearchBar.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)).isRequired,
  onChange: PropTypes.func.isRequired,
  greyedOutUsers: PropTypes.arrayOf(PropTypes.string),
  isFetching: PropTypes.bool,
};

SearchBar.defaultProps = {
  greyedOutUsers: [],
  isFetching: false,
};

export default React.memo(SearchBar);
