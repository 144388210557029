/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import {
  DropTargetMonitor,
  useDrag as dndUseDrag,
  useDrop as dndUseDrop,
} from 'react-dnd';
import throttle from 'lodash.throttle';

enum DropType {
  BEFORE = 'before',
  AFTER = 'after',
}

interface ReorderMaterialProps {
  sourceId: string;
  destId: string;
  parentId: string;
  dropType?: DropType;
}

interface DroppableItem {
  materialId: string;
  isFolder: boolean;
  expanded: boolean;
  parent: string;
  onReorder: (_: ReorderMaterialProps) => void;
}

const getDropType = (
  monitor: DropTargetMonitor<DroppableItem>,
  ref: React.RefObject<Element>,
  destProps: DroppableItem
) => {
  const position = monitor.getClientOffset();
  if (!ref.current || !position) {
    return undefined;
  }

  if (destProps.isFolder && destProps.expanded) {
    return DropType.BEFORE;
  }

  const bounds = ref.current.getBoundingClientRect();
  const middleY = (bounds.bottom - bounds.top) / 2;
  const relativeY = position.y - bounds.top;
  return relativeY < middleY ? DropType.BEFORE : DropType.AFTER;
};

export const DND_TYPE = 'DND_TYPE_MATERIAL';

export const useDrag = (props: DroppableItem) =>
  dndUseDrag({
    type: DND_TYPE,
    item: {
      ...props,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

export const useDrop = (
  destProps: DroppableItem,
  ref: React.RefObject<Element>
) => {
  const [dropType, setDropType] = useState<DropType>();

  const updateDropType = useCallback(
    (monitor: DropTargetMonitor<DroppableItem>) => {
      const isDraggingOver = monitor.isOver() && monitor.canDrop();
      const newDropType = getDropType(monitor, ref, destProps);
      setDropType(isDraggingOver ? newDropType : undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [destProps] // Ref not needed in dependency array
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDropTypeThrottled = useCallback(throttle(updateDropType, 200), [
    updateDropType,
  ]);

  const [, drop] = dndUseDrop({
    accept: DND_TYPE,
    canDrop: (item: DroppableItem) => {
      const differentMaterial = item.materialId !== destProps.materialId;
      const folderIntoFolder = item.isFolder && !!destProps.parent;
      return differentMaterial && !folderIntoFolder;
    },
    drop: (item: DroppableItem, monitor) => {
      if (monitor.didDrop() || !ref.current) {
        return;
      }

      item.onReorder({
        sourceId: item.materialId,
        destId: destProps.materialId,
        parentId: destProps.parent,
        dropType: getDropType(monitor, ref, destProps),
      });
    },
    hover: (_item, monitor) => {
      updateDropTypeThrottled(monitor);
    },
    collect: (monitor) => {
      updateDropTypeThrottled(monitor);
      return {};
    },
  });

  return [{ dropType }, drop];
};
