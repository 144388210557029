import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import push from '../../../../../../../utils/routing';
import { ENTER, DELETE } from '../../../../../../../utils/keyCodes';
import style from './style';

@Radium
class TagItem extends React.Component {
  render() {
    const { tag, _key, onRemove, handleRemove, showDelete, handleClick } =
      this.props;
    const renderCross = () => {
      if (showDelete && !tag.tmp) {
        return (
          <span
            key={_key}
            role="button"
            tabIndex={0}
            aria-label={`remove ${tag.name} tag`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove(_key, tag.objectId, onRemove);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === ENTER || e.keyCode === DELETE) {
                e.preventDefault();
                e.stopPropagation();
                handleRemove(_key, tag.objectId, onRemove);
              }
            }}
            style={style.tagCross}
          >
            X
          </span>
        );
      }
      if (showDelete && tag.tmp) {
        return (
          <span style={{ ...style.tagCross, color: 'transparent' }} key={_key}>
            X
          </span>
        );
      }

      return null;
    };

    return (
      <div
        role="button"
        tabIndex="0"
        style={{
          ...style.tag,
          opacity: tag.tmp ? '0.5' : '1',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(tag.objectId);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === ENTER) {
            e.preventDefault();
            e.stopPropagation();
            handleClick(tag.objectId);
          }
        }}
      >
        <span>{tag.name}</span>
        {renderCross()}
      </div>
    );
  }
}

TagItem.propTypes = {
  showDelete: PropTypes.bool,
  tag: PropTypes.object,
  _key: PropTypes.number,
  onRemove: PropTypes.func,
  handleRemove: PropTypes.func,
  handleClick: PropTypes.func,
  classId: PropTypes.string,
};

export default TagItem;
