import React from 'react';
import { Button } from '@ublend-npm/aulaui-next';
import successBackground from '@static/create-assignment-3.svg';
import {
  BackgroundContainer,
  Footer,
  SubTitle,
  Title,
} from './SuccessModalContent.styled';

type SuccessModalContentProps = {
  title: string;
  subTitle: string;
  onClose: () => void;
};

const SuccessModalContent = ({
  title,
  subTitle,
  onClose,
}: SuccessModalContentProps) => {
  return (
    <>
      <BackgroundContainer background={successBackground} />
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <Footer>
        <Button type="text" onClick={onClose} aria-label="Close">
          Close
        </Button>
      </Footer>
    </>
  );
};

export default SuccessModalContent;
