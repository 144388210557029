import React from 'react';
import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

const AvatarContainer = styled('div')`
  margin-right: ${theme.spacing.small};
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
`;

export default React.memo(AvatarContainer);
