import React from 'react';

import createSvgIcon from './hoc/createSvgIcon';

export default createSvgIcon({
  svg: () => (
    <>
      <path d="M15.163 31.722l-0.037-0.009c-0.086-0.021-0.17-0.039-0.255-0.045-1.997-0.131-3.857-0.616-5.528-1.442-4.832-2.39-7.798-6.268-8.817-11.531-0.080-0.411-0.129-0.842-0.178-1.259-0.023-0.194-0.045-0.388-0.071-0.581v-1.696l0.011-0.050c0.021-0.088 0.039-0.177 0.045-0.266 0.134-1.977 0.614-3.817 1.427-5.469 2.383-4.847 6.266-7.825 11.54-8.848 0.411-0.079 0.844-0.13 1.262-0.179 0.195-0.023 0.389-0.045 0.584-0.071h1.691l0.038 0.009c0.084 0.021 0.17 0.040 0.255 0.044 1.997 0.13 3.855 0.617 5.528 1.443 4.832 2.39 7.798 6.268 8.817 11.53 0.078 0.412 0.13 0.845 0.178 1.263 0.023 0.193 0.046 0.386 0.070 0.578v1.695l-0.011 0.051c-0.020 0.088-0.038 0.177-0.045 0.267-0.134 1.974-0.614 3.815-1.426 5.468-2.384 4.847-6.265 7.823-11.54 8.847-0.412 0.079-0.843 0.13-1.262 0.18-0.195 0.022-0.389 0.045-0.582 0.070h-1.693zM15.995 1.504c-7.968 0-14.468 6.489-14.492 14.464-0.012 3.862 1.49 7.502 4.226 10.251 2.741 2.752 6.383 4.271 10.254 4.277 7.988 0.001 14.489-6.488 14.514-14.464 0.009-3.862-1.491-7.503-4.227-10.251-2.741-2.753-6.383-4.272-10.255-4.278h-0.020z" />
      <path d="M9.2 16.462c-0.525 0-0.671-0.175-0.74-0.327-0.073-0.159-0.053-0.32 0.056-0.454 0.118-0.145 0.319-0.23 0.553-0.232 0.398-0.003 0.797-0.004 1.194-0.004l12.72 0.003c0.412 0.001 0.678 0.178 0.694 0.465 0.018 0.291-0.2 0.51-0.542 0.543-0.051 0.006-0.102 0.006-0.152 0.006h-13.783z" />
    </>
  ),
  displayName: 'Remove',
  viewBox: '0 0 32 32',
});
