import { connect } from 'react-redux';

import { selectors as materialSelectors } from '../../../../../store/materials';
import SectionWarning from './SectionWarning';

const mapStateToProps = (state, ownProps) => {
  const section = materialSelectors.getMaterial(state, ownProps.materialId);
  if (section.parent) {
    const { hidden, scheduledFor } = materialSelectors.getMaterial(
      state,
      section.parent
    );
    return {
      hidden,
      scheduledFor,
    };
  }
  return {
    hidden: false,
  };
};

export default connect(mapStateToProps)(SectionWarning);
