import { connect } from 'react-redux';
import { Avatar } from '@ublend-npm/aulaui-next';
import { online } from '../../../../../core/selectors/live';

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps;
  const cachedUser = state.data.users[userId];
  const user = cachedUser || { objectId: userId };
  return {
    name: user.firstName,
    src: user.avatar,
    size: 'small',
    online: online(state, userId),
    colourSeed: userId,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
