import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import {
  theme,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from '@ublend-npm/aulaui-next';
import MoreVert from '@mui/icons-material/MoreVert';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CreateIcon from '@mui/icons-material/Create';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import Logout from '../../common/Icons/Logout';

import { API_URL, FILE_URL } from '../../../../../core/constants/endpoints';

import ChangeGroupNameModal from './modals/RenameGroupModal.container';
import EditMessageModal from './modals/EditMessageModalAulaEditor.container';
import ParticipantsModal from './modals/ViewParticipantsModal.container';
import LeaveConversationModal from './LeaveConversationModal';
import {
  MessageTable,
  WriteZoneContainer,
  HeaderAddonContainer,
  AddonContainer,
  ModalContent,
  ModalStyled,
  ActionContainer,
  ModalWrapper,
} from './ConversationModal.styled';

import ConversationModalParticipants from './ConversationModalParticipants.container';
import ConversationIcon from '../common/ConversationIcon.container';

import * as types from '../types';
import onlyWhenDefined from './onlyWhenDefined';
import WriteZone from './WriteZoneAulaEditor/WriteZone.container';
import {
  INBOX_CONVERSATION_ACTION_ADD_PARTICIPANTS,
  INBOX_CONVERSATION_ACTION_LEAVE_CONVERSATION,
  INBOX_CONVERSATION_ACTION_RENAME_CONVERSATION,
  INBOX_CONVERSATION_ACTION_VIEW_PARTICIPANTS,
  INBOX_CONVERSATION_ACTION_MUTE,
  INBOX_CONVERSATION_ACTION_UNMUTE,
  INBOX_CONVERSATION_CLOSE_CONVERSATION,
} from '../../../../constants/texts';
import EmojiPicker from '../../emoji/EmojiPicker';
import checkFocusTrack from '../../../../utils/checkFocusTrack';
import { ESCAPE } from '../../../../utils/keyCodes';
import ConversationModalTitle from './ConversationModalTitle';

const flow =
  (...functions) =>
  (component) =>
    functions.reduce((acc, next) => next(acc), component);
const BackDropStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const ConversationActions = [
  {
    getLabel: () => INBOX_CONVERSATION_ACTION_VIEW_PARTICIPANTS,
    getHandler: ({ openViewParticipants }) => openViewParticipants,
    key: 'openViewParticipants',
    getIcon: () => GroupIcon,
    visible: ({ conversation }) => !!conversation.isGroup,
    disabled: () => false,
  },
  {
    getLabel: () => INBOX_CONVERSATION_ACTION_ADD_PARTICIPANTS,
    getHandler: ({ openAddParticipant }) => openAddParticipant,
    key: 'openAddParticipants',
    getIcon: () => GroupAddIcon,
    visible: ({ conversation }) =>
      !!conversation.isGroup && !conversation.boundToSpaceGroup,
    disabled: ({ conversation }) => !!conversation.educatorOnly,
    disabledToolTip: 'Educator only conversation',
  },
  {
    getLabel: () => INBOX_CONVERSATION_ACTION_RENAME_CONVERSATION,
    getHandler: ({ openRenameGroup }) => openRenameGroup,
    key: 'openRenameGroup',
    getIcon: () => CreateIcon,
    visible: ({ conversation }) =>
      !!conversation.isGroup &&
      !conversation.boundToSpaceGroup &&
      !conversation.educatorOnly,
    disabled: () => false,
  },
  {
    getLabel: ({ conversationMuted }) =>
      conversationMuted
        ? INBOX_CONVERSATION_ACTION_UNMUTE
        : INBOX_CONVERSATION_ACTION_MUTE,
    getHandler: ({ toggleMuteConversation }) => toggleMuteConversation,
    key: 'muteUnmute',
    getIcon: ({ conversationMuted }) =>
      conversationMuted ? VolumeUpIcon : VolumeOffIcon,
    visible: () => true,
    disabled: () => false,
  },
  {
    getLabel: () => INBOX_CONVERSATION_CLOSE_CONVERSATION,
    getHandler: ({ setConversationClosed }) => setConversationClosed,
    key: 'closeConversation',
    getIcon: () => Logout,
    visible: ({ conversation }) => !conversation.boundToSpaceGroup,
    disabled: () => false,
  },
  {
    getLabel: () => INBOX_CONVERSATION_ACTION_LEAVE_CONVERSATION,
    getHandler: ({ openLeaveGroup }) => openLeaveGroup,
    key: 'openLeaveGroup',
    getIcon: () => ExitToAppIcon,
    visible: ({ conversation }) =>
      !!conversation.isGroup &&
      !conversation.boundToSpaceGroup &&
      !conversation.educatorOnly,
    disabled: () => false,
  },
];

class ConversationModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      emojiPickerAnchor: null,
      emojiPickerOpenForMessageId: null,
    };

    this.messageTable = null;
    this.writeZone = null;
    this.conversationModalId = 'conversation-panel';

    this.closeLeaveGroup = this.closeLeaveGroup.bind(this);
    this.focusWriteZone = this.focusWriteZone.bind(this);
    this.onWriteZoneResize = this.onWriteZoneResize.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.setMessageTable = this.setMessageTable.bind(this);
    this.setWriteZone = this.setWriteZone.bind(this);
    this.openEmojiPicker = this.openEmojiPicker.bind(this);
    this.closeEmojiPicker = this.closeEmojiPicker.bind(this);
    this.selectEmoji = this.selectEmoji.bind(this);
    this.onJumpToBottom = this.onJumpToBottom.bind(this);
    this.onFocusWindow = this.onFocusWindow.bind(this);

    this.editorConfig = {
      filesUrl: FILE_URL(),
      apiUrl: API_URL(),
    };
  }

  async componentDidMount() {
    window.addEventListener('focus', this.onFocusWindow);
    const { conversation, setConversationReopened } = this.props;
    if (conversation.closed) {
      setConversationReopened();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocusWindow);
  }

  onFocusWindow() {
    this.focusWriteZone();
  }

  onMessageSubmit() {
    this.messageTable.scrollToBottom();
  }

  onWriteZoneResize() {
    if (!this.messageTable) return;
    this.messageTable.onWriteZoneResize();
  }

  async onJumpToBottom() {
    const { fetchMessages } = this.props;
    await fetchMessages();
    this.messageTable.scrollToBottom();
  }

  setMessageTable(reference) {
    this.messageTable = reference;
  }

  setWriteZone(reference) {
    this.writeZone = reference;
  }

  closeLeaveGroup() {
    const { closeLeaveGroup } = this.props;
    closeLeaveGroup();
    this.focusWriteZone();
  }

  focusWriteZone() {
    if (!this.writeZone) return;
    this.writeZone.focus();
  }

  openEmojiPicker({ messageId, anchor }) {
    this.setState({
      emojiPickerOpenForMessageId: messageId,
      emojiPickerAnchor: anchor,
    });
  }

  closeEmojiPicker() {
    this.setState({
      emojiPickerOpenForMessageId: null,
      emojiPickerAnchor: null,
    });
  }

  selectEmoji(emoji) {
    const { toggleReaction } = this.props;
    const { emojiPickerOpenForMessageId } = this.state;
    toggleReaction({
      itemId: emojiPickerOpenForMessageId,
      emojiName: emoji.short_name,
    });
  }

  render() {
    const {
      closeModal,
      open,
      title,
      status,
      conversation,
      currentUser,
      openViewParticipants,
      leavingConversation,
      leaveGroupOpened,
      leaveConversation,
    } = this.props;

    const { emojiPickerAnchor } = this.state;

    const renderMenu = () => {
      const headerActions = ConversationActions.filter((action) =>
        action.visible(this.props)
      );
      if (headerActions.length === 0) {
        return null;
      }

      return (
        <Menu
          ariaLabelledby={`menu_${conversation.id}`}
          renderTarget={({ handleTargetClick }) => (
            <div>
              <IconButton
                icon={MoreVert}
                size="small"
                type="secondary"
                id="conversation-menu-button"
                label="Conversation menu"
                onClick={handleTargetClick}
              />
            </div>
          )}
          onSelect={({ item }) => item()}
          renderChildren={({ getItemProps }) =>
            headerActions.map(
              ({
                getLabel,
                getHandler,
                key,
                getIcon,
                disabled,
                disabledToolTip,
              }) => {
                const Icon = getIcon(this.props);
                const isDisabled = disabled(this.props);
                const menuItem = (
                  <MenuItem
                    key={`action:${key}`}
                    size="medium"
                    disabled={isDisabled}
                    leftDecoration={
                      <Icon style={{ color: theme.color.grey11 }} />
                    }
                    primaryText={getLabel(this.props)}
                    {...getItemProps({
                      item: getHandler(this.props),
                      onClick: (e) => e.stopPropagation(),
                    })}
                  />
                );
                return isDisabled && disabledToolTip ? (
                  <Tooltip title={disabledToolTip}>{menuItem}</Tooltip>
                ) : (
                  menuItem
                );
              }
            )
          }
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        />
      );
    };

    const renderModalBody = () => (
      <ModalContent tabIndex={-1}>
        <MessageTable
          innerRef={this.setMessageTable}
          openEmojiPicker={this.openEmojiPicker}
          closeEmojiPicker={this.closeEmojiPicker}
        />
        <WriteZoneContainer>
          <WriteZone
            editorConfig={this.editorConfig}
            ref={this.setWriteZone}
            onHeightChange={this.onWriteZoneResize}
            onMessageSubmit={this.onMessageSubmit}
            htmlAttributes={{
              'data-testid': 'dm-message-textbox',
            }}
          />
        </WriteZoneContainer>
      </ModalContent>
    );

    const headerChildren = (
      <Fragment>
        <AddonContainer>
          <HeaderAddonContainer>
            <ConversationModalParticipants
              conversation={conversation}
              currentUser={currentUser}
              openViewParticipants={openViewParticipants}
            />
          </HeaderAddonContainer>
        </AddonContainer>
        <ActionContainer id={`menu_${conversation.id}`}>
          {renderMenu()}
        </ActionContainer>
      </Fragment>
    );

    return (
      <div>
        <ModalStyled
          keepMounted
          size="fullscreen"
          id={this.conversationModalId}
          container={() => document.getElementById('content-container')}
          open={open}
          BackdropProps={{ style: BackDropStyle }}
          onBackdropClick={closeModal}
          disableEnforceFocus
          onClose={closeModal}
          onKeyDown={(e) => {
            if (e.keyCode === ESCAPE) closeModal();

            checkFocusTrack(
              e,
              document.getElementById(this.conversationModalId)
            );
          }}
          headerData={{
            children: headerChildren,
            icon: <ConversationIcon conversationId={conversation.id} />,
            title: <ConversationModalTitle title={title} status={status} />,
            iconSize: 'small',
            hint: conversation.educatorOnly
              ? 'Only educators are in this conversation'
              : null,
          }}
          data-testid="dm-modal"
        >
          <ModalWrapper>{renderModalBody()}</ModalWrapper>
        </ModalStyled>
        <ParticipantsModal
          conversation={conversation}
          currentUser={currentUser}
          onClose={this.focusWriteZone}
        />
        <ChangeGroupNameModal
          conversation={conversation}
          currentUser={currentUser}
          onClose={this.focusWriteZone}
          title={title}
        />
        <LeaveConversationModal
          loading={leavingConversation}
          open={leaveGroupOpened}
          onClose={this.closeLeaveGroup}
          onConfirm={leaveConversation}
          title={title}
        />
        <EditMessageModal
          editorConfig={this.editorConfig}
          conversation={conversation}
          onClose={this.focusWriteZone}
        />
        <EmojiPicker
          id="conversations-emoji-picker"
          anchorEl={emojiPickerAnchor}
          show={!!emojiPickerAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={this.closeEmojiPicker}
          onSelect={this.selectEmoji}
        />
      </div>
    );
  }
}

ConversationModal.propTypes = {
  groupId: PropTypes.string.isRequired,
  setConversationReopened: PropTypes.func.isRequired,
  closeLeaveGroup: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  conversation: types.Conversation.isRequired,
  currentUser: types.User.isRequired,
  leaveConversation: PropTypes.func.isRequired,
  leavingConversation: PropTypes.bool,
  leaveGroupOpened: PropTypes.bool,
  open: PropTypes.bool,
  openAddParticipant: PropTypes.func.isRequired,
  openLeaveGroup: PropTypes.func.isRequired,
  openRenameGroup: PropTypes.func.isRequired,
  openViewParticipants: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  status: types.Status,
  toggleReaction: PropTypes.func.isRequired,
  fetchMessages: PropTypes.func.isRequired,
  conversationMuted: PropTypes.bool.isRequired,
  toggleMuteConversation: PropTypes.func.isRequired,
};

ConversationModal.defaultProps = {
  open: false,
  status: null,
  leaveGroupOpened: false,
  leavingConversation: false,
};

const wrap = flow(onlyWhenDefined(['conversation', 'currentUser']));

export default wrap(ConversationModal);
