import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { icons } from '@ublend-npm/aulaui-next';

import { ENTER } from '../../../../utils/keyCodes';
import { SpaceLoader } from './Loaders';
import {
  SpaceSelectorContainer,
  Container,
  TabsWrapper,
  Alert,
} from './SpaceBar.styled';
import SpaceSelector from './SpaceSelector/SpaceSelector.container';
import SpaceSelectorV4 from './SpaceSelectorV4/SpaceSelector.container';
import { Tabs, Tab } from '../../common/Tabs';
import { useFlags } from 'launchdarkly-react-client-sdk';

const SpaceBar = ({
  currentTabId,
  shouldDisplaySpaceLoaders,
  shouldDisplayTabsPanel,
  tabs,
  onTabChange,
  accessingAsStaff,
}) => {
  const [showWarning, setShowWarning] = useState(true);

  const { spaceSelectorComponentUpgradeToV620252 } = useFlags();

  const spaceSelector = shouldDisplaySpaceLoaders ? (
    <SpaceLoader />
  ) : spaceSelectorComponentUpgradeToV620252 ? (
    <SpaceSelector />
  ) : (
    <SpaceSelectorV4 />
  );
  const tabIndex = tabs.findIndex((tab) => tab.id === currentTabId);

  return (
    <Container
      shouldShowBottomShadow={shouldDisplayTabsPanel}
      aria-label="Main"
    >
      {accessingAsStaff && showWarning && (
        <Alert>
          Some actions are not available because you are viewing this space via
          staff access.
          <icons.Close
            tabIndex="0"
            role="button"
            onClick={() => setShowWarning(false)}
            onKeyDown={(e) => e.keyCode === ENTER && setShowWarning(false)}
            style={{ fontSize: '20px', color: 'black', cursor: 'pointer' }}
          />
        </Alert>
      )}
      <SpaceSelectorContainer showWarning={accessingAsStaff && showWarning}>
        {spaceSelector}
      </SpaceSelectorContainer>
      {shouldDisplayTabsPanel && (
        <TabsWrapper>
          <Tabs
            id="space-settings-tabs"
            value={tabIndex}
            onChange={onTabChange}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.label}
                icon={tab.icon}
                aria-label={tab.documentTitlePrefix}
                data-testid={tab.id}
              />
            ))}
          </Tabs>
        </TabsWrapper>
      )}
    </Container>
  );
};

SpaceBar.propTypes = {
  shouldDisplaySpaceLoaders: PropTypes.bool,
  shouldDisplayTabsPanel: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      documentTitlePrefix: PropTypes.string.isRequired,
      label: PropTypes.node,
    })
  ).isRequired,
  currentTabId: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  accessingAsStaff: PropTypes.bool.isRequired,
};

SpaceBar.defaultProps = {
  shouldDisplaySpaceLoaders: false,
  shouldDisplayTabsPanel: true,
};

export default React.memo(SpaceBar);
