/* eslint-env browser */
import PropTypes from 'prop-types';
import React from 'react';

import MoreVert from '@mui/icons-material/MoreVert';
import { Menu, ListItem, Separator, Tooltip } from '@ublend-npm/aulaui-next';

import readUploadedFileContent from '../../../../../utils/readUploadedFileContent';
import {
  MATERIAL_CREATE_FOLDER,
  IMPORT_MATERIAL,
  EXPORT_MATERIAL,
  MATERIAL_IMPORT_EXPORT_TOOLTIP,
  RESTORE_PAGE,
} from '../../../../../constants/texts';

import { uppercaseFirst } from './common';
import { UploadButton, moreMenu, menuItem } from './MaterialMenu.styled';
import { IconButton } from '@ublend-npm/aulaui-next';

class MaterialMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setMenu = this.setMenu.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  setMenu(reference) {
    this.menu = reference;
  }

  async handleFileUpload(event) {
    // for some reason FileList does not have a map method
    const files = [...event.target.files];
    const fileContentPromises = files.map(readUploadedFileContent);
    const materials = await Promise.all(fileContentPromises);
    this.menu.handleClose(event);

    const { onImportMaterial, educatorOnly } = this.props;
    onImportMaterial({ materials, educatorOnly });
  }

  handleSelect({ item, preventClose }) {
    const { onCreateFolder, onExportMaterial, educatorOnly, onRestorePage } =
      this.props;
    switch (item) {
      case 'create-folder':
        onCreateFolder(educatorOnly);
        break;
      case 'import':
        preventClose();
        document.getElementById('upload-button').click();
        break;
      case 'export':
        onExportMaterial(educatorOnly);
        break;
      case 'restore':
        onRestorePage();
      default:
    }
  }

  render() {
    const {
      onExportMaterial,
      onImportMaterial,
      onCreateFolder,
      onRestorePage,
      ...menuProps
    } = this.props;

    return (
      <React.Fragment>
        <Menu
          ref={this.setMenu}
          ariaLabelledby="menu-materials"
          classes={{ menu: moreMenu }}
          {...menuProps}
          renderTarget={({ handleTargetClick, isOpen }) => (
            <Tooltip title={MATERIAL_IMPORT_EXPORT_TOOLTIP} position="top">
              <div>
                <IconButton
                  type="secondary"
                  size="xSmall"
                  icon={MoreVert}
                  label="more options"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTargetClick(e);
                  }}
                  buttonAttributes={{
                    'aria-expanded': isOpen,
                    'data-testid': 'materials-more-options',
                  }}
                  position="top"
                />
              </div>
            </Tooltip>
          )}
          onSelect={this.handleSelect}
          renderChildren={({ getItemProps, highlightedIndex }) => [
            <ListItem
              className={menuItem}
              key={MATERIAL_CREATE_FOLDER}
              primaryText={uppercaseFirst(MATERIAL_CREATE_FOLDER)}
              highlighted={highlightedIndex === 0}
              attributes={{ 'data-testid': 'materials-create-folder' }}
              {...getItemProps({
                item: 'create-folder',
                onClick: (e) => e.stopPropagation(),
              })}
            />,
            <Separator key="separator" attributes={{ 'aria-hidden': true }} />,
            <ListItem
              className={menuItem}
              key={IMPORT_MATERIAL}
              primaryText={uppercaseFirst(IMPORT_MATERIAL)}
              highlighted={highlightedIndex === 1}
              {...getItemProps({
                item: 'import',
                onClick: (e) => e.stopPropagation(),
              })}
            />,
            <ListItem
              className={menuItem}
              key={EXPORT_MATERIAL}
              primaryText={uppercaseFirst(EXPORT_MATERIAL)}
              highlighted={highlightedIndex === 2}
              {...getItemProps({
                item: 'export',
                onClick: (e) => e.stopPropagation(),
              })}
            />,
            onRestorePage ? (
              <ListItem
                className={menuItem}
                key={RESTORE_PAGE}
                primaryText={uppercaseFirst(RESTORE_PAGE)}
                highlighted={highlightedIndex === 3}
                {...getItemProps({
                  item: 'restore',
                  onClick: (e) => e.stopPropagation(),
                })}
              />
            ) : null,
          ]}
        />
        <UploadButton
          aria-hidden="true"
          type="file"
          multiple
          id="upload-button"
          onChange={this.handleFileUpload}
        />
      </React.Fragment>
    );
  }
}

MaterialMenu.propTypes = {
  onCreateFolder: PropTypes.func.isRequired,
  onImportMaterial: PropTypes.func.isRequired,
  onExportMaterial: PropTypes.func.isRequired,
  educatorOnly: PropTypes.bool,
  onRestorePage: PropTypes.func.isRequired,
};

MaterialMenu.defaultProps = {
  educatorOnly: false,
};

export default MaterialMenu;
