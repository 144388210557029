import PropTypes from 'prop-types';
import React from 'react';
import Divider from '@material-ui/core/Divider';
import Responsive from '@app/components/containers/common/Responsive';
import uuid from '@core/utils/uuid';
import { analyticsTrackEvent } from '@core/utils/analytics';
import * as analyticsTypes from '@core/constants/analytics';

import { isLocalId } from '@core/utils/localId';
import { Section } from '../types';
import ActionBar from './ActionBar';
import { Container, TitleContainer } from './EditableSectionView.styled';
import SectionContent from './SectionContent';
import SectionWarning from './SectionWarning.container';
import SectionTitle from './SectionTitle';
import LastEdited from './LastEdited';
import ScheduleMaterialDialog from '../MaterialList/ScheduleMaterialDialog.container';
import { TAB } from '../../../../../utils/keyCodes';
import ScrollBar from '../../../common/ScrollBar';
import {
  ScrollContainer,
  SectionViewContainer,
  ContentContainer,
} from '../Material.styled';

class EditableSectionView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      unfinishedUpload: false,
      a11yMessage: null,
      dividerVisible: false,
      discussionTopic: props.section.discussionTopic,
      showDiscussion: props.section.showDiscussion,
      correlationId: uuid(),
    };

    this.setTitleComponent = this.setTitleComponent.bind(this);
    this.setContentComponent = this.setContentComponent.bind(this);
    this.setUploadState = this.setUploadState.bind(this);
    this.setA11yMessage = this.setA11yMessage.bind(this);

    this.onSave = this.onSave.bind(this);
    this.openScheduleDialog = this.openScheduleDialog.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTitleKeyDown = this.onTitleKeyDown.bind(this);
    this.onUnhide = this.onUnhide.bind(this);
    this.onHide = this.onHide.bind(this);
    this.getSectionData = this.getSectionData.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onDiscussionSave = this.onDiscussionSave.bind(this);

    this.setTitleComponent(null);
    this.setContentComponent(null);
  }

  static getDerivedStateFromProps(props, state) {
    let s = state;

    const propSectionId = props.section && props.section.id;
    const propTitle = props.section && props.section.title;

    if (propTitle !== state.propTitle) {
      s = {
        ...s,
        propTitle,
        hasTitle: !!propTitle,
      };
    }

    if (propSectionId !== state.sectionId) {
      s = {
        ...s,
        sectionId: propSectionId,
        discussionTopic: props.section.discussionTopic,
        showDiscussion: props.section.showDiscussion,
      };
    }

    return s;
  }

  componentDidUpdate(prevProps) {
    const { editing: prevEditing } = prevProps;
    const { editing, section } = this.props;
    const isSectionLocal = isLocalId(section.id);
    if (editing && !prevEditing) {
      const correlationId = uuid();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ correlationId });
      analyticsTrackEvent(analyticsTypes.MATERIAL_EDITOR_START, {
        activeClassroom: section.space,
        itemType: analyticsTypes.MATERIAL_ITEM_TYPE,
        correlationId,
        ...(!isSectionLocal && { itemId: section.id }),
      });
    }
  }

  onDiscussionSave({ discussionTopic, showDiscussion }) {
    const { onHideDiscussion } = this.props;
    this.setState({ discussionTopic, showDiscussion });
    if (showDiscussion === false && !!discussionTopic) {
      onHideDiscussion();
    }
  }

  onSave() {
    const { onSave } = this.props;
    const { discussionTopic, showDiscussion, correlationId } = this.state;
    const patch = {
      title: this.titleComponent.export(),
      content: this.contentComponent.export(),
      discussionTopic,
      showDiscussion,
    };
    this.setA11yMessage('The page saved successfully.');
    onSave(patch, correlationId);
  }

  onEdit() {
    const { onEdit } = this.props;
    this.setState({ correlationId: uuid() });
    this.setA11yMessage('');
    onEdit();
  }

  onTitleKeyDown(e) {
    if (e.shiftKey && e.keyCode === TAB) {
      return;
    }

    if (e.keyCode === TAB) {
      e.preventDefault();

      if (this.contentComponent) {
        this.contentComponent.focus();
      }
    }
  }

  onUnhide() {
    const { onUnhide, section } = this.props;
    this.setA11yMessage('The page is currently unhiden.');
    onUnhide(section);
  }

  onHide() {
    const { onHide, section } = this.props;
    this.setA11yMessage('The page is currently hiden.');
    onHide(section);
  }

  onTitleChange(value) {
    this.setState({ hasTitle: !!value });
  }

  onScroll({ scrollTop }) {
    this.setState({ dividerVisible: scrollTop > 0 });
  }

  getSectionData() {
    return {
      title: this.titleComponent.export(),
      content: this.contentComponent.export(),
    };
  }

  setTitleComponent(ref) {
    this.titleComponent = ref;
  }

  setContentComponent(ref) {
    this.contentComponent = ref;
  }

  setUploadState(unfinishedUpload) {
    this.setState({ unfinishedUpload });
  }

  setA11yMessage(a11yMessage) {
    this.setState({ a11yMessage });
  }

  openScheduleDialog() {
    const {
      openScheduleDialog,
      section: { id: sectionId },
    } = this.props;
    openScheduleDialog(sectionId);
  }

  render() {
    const {
      discussionTopic,
      showDiscussion,
      correlationId,
      hasTitle,
      unfinishedUpload,
      a11yMessage,
      dividerVisible,
    } = this.state;
    const {
      section,
      editing,
      selectedContent,
      onGetSection,
      isLoadingContent,
      reverting,
      educatorOnly,
      saving,
      onDelete,
      onFindPreviousVersion,
      onExportMaterialPage,
      onImportMaterialPage,
      onKeepHidden,
    } = this.props;
    const isSectionLocal = isLocalId(section.id);

    const sectionView = (
      <SectionViewContainer>
        <TitleContainer>
          <SectionTitle
            onChange={this.onTitleChange}
            ref={this.setTitleComponent}
            editable={editing}
            value={section.title}
            onKeyDown={this.onTitleKeyDown}
          />
        </TitleContainer>
        {!editing ? (
          <LastEdited
            editedAt={section.updatedAt}
            createdAt={section.createdAt}
          />
        ) : null}
        <SectionContent
          ref={this.setContentComponent}
          editable={editing}
          content={selectedContent}
          onGetSection={onGetSection}
          isLoadingContent={isLoadingContent}
          reverting={reverting}
          sectionId={section.id}
          setUploadState={this.setUploadState}
          discussionTopic={discussionTopic}
          showDiscussion={showDiscussion}
          onDiscussionSave={this.onDiscussionSave}
          correlationId={correlationId}
        />
      </SectionViewContainer>
    );

    return (
      <Container>
        <ContentContainer>
          <SectionViewContainer>
            <SectionWarning materialId={section.id} />
            <ActionBar
              educatorOnly={educatorOnly}
              canSave={hasTitle}
              showUnhide={section.hidden}
              showSave={editing || saving}
              showEdit={!editing}
              openScheduleDialog={this.openScheduleDialog}
              onUnhide={this.onUnhide}
              onSave={this.onSave}
              onEdit={this.onEdit}
              saving={saving || unfinishedUpload}
              canFindPreviousVersion={!isSectionLocal}
              canExportMaterialPage={!isSectionLocal}
              onDeleteSection={onDelete}
              onHideSection={this.onHide}
              scheduledFor={section && section.scheduledFor}
              onFindPreviousVersion={onFindPreviousVersion}
              onExportMaterialPage={onExportMaterialPage}
              onImportMaterialPage={onImportMaterialPage}
              onKeepHidden={() => onKeepHidden(section.id)}
              a11yMessage={a11yMessage}
            />
            {dividerVisible && <Divider />}
          </SectionViewContainer>
          <Responsive>
            {({ md }) =>
              md ? (
                <ScrollContainer>
                  <ScrollBar
                    autoHide
                    onScrollFrame={this.onScroll}
                    viewProps={{ style: { height: '100%' } }}
                  >
                    {sectionView}
                  </ScrollBar>
                </ScrollContainer>
              ) : (
                sectionView
              )
            }
          </Responsive>
        </ContentContainer>
        <ScheduleMaterialDialog getSectionData={this.getSectionData} />
      </Container>
    );
  }
}

EditableSectionView.propTypes = {
  section: Section.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedContent: PropTypes.object,
  isLoadingContent: PropTypes.bool.isRequired,
  reverting: PropTypes.bool,
  editing: PropTypes.bool,
  saving: PropTypes.bool,
  openScheduleDialog: PropTypes.func.isRequired,
  onKeepHidden: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onGetSection: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onFindPreviousVersion: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onUnhide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onExportMaterialPage: PropTypes.func.isRequired,
  onImportMaterialPage: PropTypes.func.isRequired,
  educatorOnly: PropTypes.bool,
  onHideDiscussion: PropTypes.func.isRequired,
};

EditableSectionView.defaultProps = {
  editing: false,
  saving: false,
  reverting: false,
  educatorOnly: false,
  selectedContent: null,
};

export default EditableSectionView;
