import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DeleteSectionModal from './DeleteSectionModal';
import { selectors, operations } from '../../../../../store/materials';
import { selectSectionInJourney as selectSection } from '../../../../../actions/materials';

const mapStateToProps = createStructuredSelector({
  deleting: selectors.isDeletingSection,
  open: selectors.currentDeleteCandidateCheckNull,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const onDelete = (status) =>
    dispatch(
      operations.deleteSection({
        status,
        selectSection,
        educatorOnly: ownProps.educatorOnly,
      })
    );

  return {
    onClose: () => onDelete('aborted'),
    onConfirm: () => onDelete('request'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSectionModal);
