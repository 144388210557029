import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const AlertContainer = styled('div')`
  margin-top: 24px;
  margin-left: -16px;
  margin-right: 24px;
`;

export const Container = styled<{ background?: string }, 'div'>('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.color.white};
  background-size: contain;
  width: 493px;
  max-height: 700px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
  border-radius: 8px;
  /* to make colored children obey border-radius */
  overflow: auto;
  max-height: 100vh;
`;

export const BackgroundContainer = styled<{ background?: string }, 'div'>(
  'div'
)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.color.white};
  background: ${({ background }) =>
    background
      ? `${theme.color.white} url(${background}) top right no-repeat`
      : theme.color.white};
  background-size: contain;
  width: 493px;
  min-height: 340px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
  border-radius: 8px;
  @@ -75,30 +70,8 @@ export const Content = styled<
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Content = styled<
  {
    createStep: boolean;
    saved: boolean;
  },
  'div'
>('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: ${({ createStep }) => (createStep ? '0px' : '40px')};
  height: ${({ saved }) =>
    saved ? 'calc(100% - 550px)' : 'calc(100% - 113px)'};
  overflow: auto;
`;

export const Footer = styled('div')`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  border-bottom-left-radius: ${theme.borderRadius.large};
  border-bottom-right-radius: ${theme.borderRadius.large};
  border-top: 1px solid ${theme.color.grayLight};
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
`;

export const SuccessFooter = styled('div')`
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding-bottom: 36px;
  padding-top: 50px;
`;

export const Title = styled('p')`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.regular};
  line-height: ${theme.font.lineHeight.copy};
  margin: 0 0 14px -10px;
  color: ${theme.color.designSystem.blackOpacity87};
  padding-top: 24px;
`;

export const Text = styled('div')`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.large};
  line-height: 24px;
  margin-bottom: 24px;
  color: ${theme.color.mediumBlue};
`;

export const BackButtonPlaceholder = styled('div')`
  width: 65px;
`;

export const ContentContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 32px;
`;

export const DividerContainer = styled('div')`
  font-size: 16px;
  line-height: 25.6px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TitleWrapper = styled('h4')`
  margin: 0;
`;
