import { connect } from 'react-redux';
import UserBlock from '../../common/Userblock';
import { currentUserId } from '../../../../../core/selectors/user';
import { user } from '../../../../../core/selectors/users';
import { online } from '../../../../../core/selectors/live';

const mapStateToProps = (state, ownProps) => {
  const { avatar, firstName, lastName } = user(state, ownProps.authorId) || {
    firstName: '',
    lastName: '',
  };
  const fullname = `${firstName} ${lastName}`;
  return {
    correlationId: ownProps.authorId,
    online:
      currentUserId(state) !== ownProps.authorId &&
      online(state, ownProps.authorId),
    name: fullname,
    title: fullname,
    avatar,
    size: ownProps.size,
  };
};

export default connect(mapStateToProps)(UserBlock);
