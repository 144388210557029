import { ReactNode } from 'react';
import {
  LtiAssignmentCreationParameters,
  LtiVersion,
} from '@ublend-npm/aula-schema';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import gradeTypes from './gradeTypes';

const isEmpty = (str: string | undefined) => !str || str === '';

type ValidatorProps = {
  title: string;
  startDate: string;
  endDate: string;
  gradesReleasedAt?: string;
  gradeType?: string;
  maxGrade?: number;
  isGroupAssignment?: boolean;
  groupSet?: string;
  description?: string;
};

type ValidatorResponse = {
  title: boolean;
  startDate: boolean;
  endDate: boolean;
  gradesReleasedAt?: boolean;
  gradeType?: boolean;
  maxGrade?: boolean;
  isGroupAssignment?: boolean;
  groupSet?: boolean;
  description?: boolean;
};

type EditingAssignment = any;

export type Tool = {
  id?: string;
  key: string;
  name: string;
  logo?: string;
  ltiVersion?: LtiVersion;
  isDeepLinking?: boolean;
  isCoupled?: boolean;
  assignmentCreationParameters?: LtiAssignmentCreationParameters;
  externalUrl?: (props: any) => string;
  validator?: (props: ValidatorProps) => ValidatorResponse;
  render?: () => ReactNode;
  launch?: () => void;
};

export const validateHandinAssignment = ({
  title,
  startDate,
  endDate,
  isGroupAssignment,
  groupSet,
}: ValidatorProps): ValidatorResponse => ({
  title: isEmpty(title),
  startDate: isEmpty(startDate),
  endDate: isEmpty(endDate) || isBefore(parseISO(endDate), parseISO(startDate)),
  isGroupAssignment: isGroupAssignment && isEmpty(groupSet),
});

export const validateTurnItInAssignment = ({
  title,
  startDate,
  endDate,
  gradesReleasedAt,
  gradeType,
  maxGrade,
}: ValidatorProps): ValidatorResponse => ({
  title: isEmpty(title) || title.length < 2,
  startDate: isEmpty(startDate),
  endDate: isEmpty(endDate) || isBefore(parseISO(endDate), parseISO(startDate)),
  gradesReleasedAt:
    isEmpty(gradesReleasedAt) ||
    (gradesReleasedAt !== undefined &&
      isBefore(parseISO(gradesReleasedAt), parseISO(startDate))),
  maxGrade:
    gradeType === gradeTypes.points.value &&
    (!maxGrade || maxGrade < 1 || maxGrade > 1000),
});

export const hasError = (errors) =>
  Object.values(errors).some((error) => !!error);

export const defaultIfNull = (value, def) => (value === null ? def : value);

export const filterAssignmentParams = (input: EditingAssignment) => ({
  classId: input.classId,
  classRoom: input.classRoom,
  description: input.description,
  endDate: input.endDate,
  externalId: defaultIfNull(input.externalId, undefined),
  gradeType: input.gradeType,
  gradesReleasedAt: defaultIfNull(input.gradesReleasedAt, undefined),
  groupSet: defaultIfNull(input.groupSet, undefined),
  groups: defaultIfNull(input.groups, undefined),
  instructor: input.instructor,
  isAnonymised: defaultIfNull(input.isAnonymised, false),
  isHidden: defaultIfNull(input.isHidden, false),
  objectId: input.objectId,
  provider: input.provider,
  shouldCloseOnDueDate: defaultIfNull(input.shouldCloseOnDueDate, false),
  space: input.space,
  startDate: input.startDate,
  status: input.status,
  students: defaultIfNull(input.students, undefined),
  submissions: input.submissions,
  title: input.title,
  user: input.user,
});

export const formatAssignmentFormData = (
  formData,
  providerId,
  resourceLinkId
) => {
  const { title, description, openDate, dueDate, gradesReleaseDate, maxGrade } =
    formData;

  return {
    title,
    description,
    startDate: openDate,
    endDate: dueDate,
    gradesReleasedAt: gradesReleaseDate,
    providerId,
    resourceLinkId,
    maxGrade,
  };
};
