import { connect } from 'react-redux';
import { selectors } from '../../../../store/conversations';
import MessageCell from './MessageCell';

const mapStateToProps = (state, ownProps) => {
  const message = selectors.message(state, ownProps.messageId);

  return {
    key: message.editedAt || message.createdAt,
    content: message.content,
  };
};

export default connect(mapStateToProps)(MessageCell);
