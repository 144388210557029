const getSuggestedTitleForAnExtension = (
  assignments,
  destinationAssignmentId
) => {
  if (!destinationAssignmentId) {
    return 'Extension 1';
  }
  const { extensions } = assignments.find(
    ({ id }) => id === destinationAssignmentId
  );
  if (!extensions || extensions.length === 0) {
    return 'Extension 1';
  }
  const hasExtension1 = extensions.find(
    (extension) => extension.title === 'Extension 1'
  );
  const hasExtension2 = extensions.find(
    (extension) => extension.title === 'Extension 2'
  );
  if (!hasExtension1) {
    return 'Extension 1';
  }
  if (!hasExtension2) {
    return 'Extension 2';
  }
  return `${extensions[0].title} 1`;
};

export default getSuggestedTitleForAnExtension;
