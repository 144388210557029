import React from 'react';
import { func } from 'prop-types';
import { Button } from '@ublend-npm/aulaui-next';
import AddIcon from '@material-ui/icons/Add';
import {
  SubTitle,
  ButtonTitle,
  buttonSize,
} from './DiscussionDetailsCard.styled';

export const AddDiscussionCard = ({ openDiscussionTopicModal }) => (
  <>
    <SubTitle>Add discussion or activity</SubTitle>
    <Button
      type="secondary"
      onClick={openDiscussionTopicModal}
      id="add-discussion"
      iconLeft={AddIcon}
      data-testid="add-discussion-button"
      aria-label="Add discussion"
      css={buttonSize}
    >
      <ButtonTitle>Add discussion</ButtonTitle>
    </Button>
  </>
);

AddDiscussionCard.propTypes = {
  openDiscussionTopicModal: func.isRequired,
};

export default AddDiscussionCard;
