import PropTypes from 'prop-types';
import React from 'react';

import MoreVert from '@mui/icons-material/MoreVert';
import { ListItem, Tooltip } from '@ublend-npm/aulaui-next';

import { UploadButton } from '../MaterialList/MaterialMenu.styled';
import { ENTER } from '../../../../../utils/keyCodes';

import {
  MATERIAL_IMPORTPAGE,
  MATERIAL_EXPORTPAGE,
  MATERIAL_DELETE,
  MATERIAL_FIND_PREVIOUS,
  MATERIAL_HIDE,
  MATERIAL_SECTION_MENU,
} from '../../../../../constants/texts';

import { Menu, IconButton } from './SectionViewActionMenu.styled';
import readUploadedFileContent from '../../../../../utils/readUploadedFileContent';

const MENU_ITEM_KEYS = {
  MATERIAL_EXPORTPAGE: 'MATERIAL_EXPORTPAGE',
  MATERIAL_IMPORTPAGE: 'MATERIAL_IMPORTPAGE',
  MATERIAL_FIND_PREVIOUS: 'MATERIAL_FIND_PREVIOUS',
  MATERIAL_DELETE: 'MATERIAL_DELETE',
  MATERIAL_HIDE: 'MATERIAL_HIDE',
};

const noop = () => null;
const initiateFileUpload = () => {
  document.getElementById('single-upload-button').click();
};

const getMenuItems = ({ excludeItems = [] }) =>
  [
    {
      key: MENU_ITEM_KEYS.MATERIAL_EXPORTPAGE,
      text: MATERIAL_EXPORTPAGE,
      id: 'material-export-button',
      fn: (props) => props.onExportMaterialPage,
      hideOnEdit: true,
    },
    {
      key: MENU_ITEM_KEYS.MATERIAL_IMPORTPAGE,
      text: MATERIAL_IMPORTPAGE,
      id: 'material-import-button',
      fn: () => initiateFileUpload,
      hideOnEdit: true,
    },
    {
      key: MENU_ITEM_KEYS.MATERIAL_FIND_PREVIOUS,
      text: MATERIAL_FIND_PREVIOUS,
      fn: (props) => props.onFindPreviousVersion,
    },
    {
      key: MENU_ITEM_KEYS.MATERIAL_DELETE,
      text: MATERIAL_DELETE,
      fn: (props) => props.onDeleteSection,
    },
  ].filter(({ key }) => !excludeItems.includes(key));

const handleFileUpload = async (event, onImportMaterialPage) => {
  const files = [...event.target.files];
  const fileContentPromises = files.map(readUploadedFileContent);
  const fileContents = await Promise.all(fileContentPromises);
  const materialContent = JSON.parse(fileContents[0]);
  onImportMaterialPage(materialContent);
  document.getElementById('single-upload-button').value = '';
};

const SectionViewActionMenu = (props) => {
  const {
    onHideSection,
    educatorOnly,
    canFindPreviousVersion,
    canExportMaterialPage,
  } = props;
  const excludeItems = [];
  if (!canFindPreviousVersion) {
    excludeItems.push(MENU_ITEM_KEYS.MATERIAL_FIND_PREVIOUS);
  }
  if (!canExportMaterialPage) {
    excludeItems.push(MENU_ITEM_KEYS.MATERIAL_EXPORTPAGE);
  }

  const validMenuItems = [
    ...(onHideSection && !educatorOnly
      ? [
          {
            key: MENU_ITEM_KEYS.MATERIAL_HIDE,
            text: MATERIAL_HIDE,
            fn: (p) => p.onHideSection,
          },
        ]
      : []),
    ...getMenuItems({ excludeItems }),
  ];
  return (
    <div>
      <Menu
        menuListComponent="ul"
        menuListAttributes={{
          role: 'listbox',
          'aria-label': 'Section more actions',
        }}
        renderTarget={({ handleTargetClick }) => (
          <Tooltip
            title={MATERIAL_SECTION_MENU}
            position="bottom"
            onKeyDown={(e) => e.keyCode === ENTER && handleTargetClick(e)}
          >
            <div>
              <IconButton
                type="secondary"
                size="small"
                label={MATERIAL_SECTION_MENU}
                buttonAttributes={{ id: 'material-more-button' }}
                onClick={handleTargetClick}
                icon={MoreVert}
              />
            </div>
          </Tooltip>
        )}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onSelect={({ item }) => item()}
        renderChildren={({ getItemProps }) =>
          validMenuItems
            .filter((mItem) =>
              !props.editing ? mItem.hideOnEdit !== true : true
            )
            .map(({ key, text, id, fn }, index) => (
              <ListItem
                size="medium"
                key={key}
                primaryText={text}
                highlighted={index === 0}
                attributes={{
                  id,
                  'aria-label': text,
                  'data-testid': key.toLowerCase(),
                }}
                {...getItemProps({
                  item: fn(props),
                })}
              />
            ))
        }
      />
      <UploadButton
        aria-hidden="true"
        type="file"
        id="single-upload-button"
        onChange={(event) =>
          handleFileUpload(event, props.onImportMaterialPage)
        }
      />
    </div>
  );
};

SectionViewActionMenu.propTypes = {
  canFindPreviousVersion: PropTypes.bool,
  canExportMaterialPage: PropTypes.bool,
  onExportMaterialPage: PropTypes.func,
  onImportMaterialPage: PropTypes.func,
  onHideSection: PropTypes.func,
  onFindPreviousVersion: PropTypes.func,
  onDeleteSection: PropTypes.func,
  editing: PropTypes.bool,
  educatorOnly: PropTypes.bool,
};

SectionViewActionMenu.defaultProps = {
  canFindPreviousVersion: false,
  canExportMaterialPage: true,
  onExportMaterialPage: noop,
  onImportMaterialPage: noop,
  onHideSection: null,
  onFindPreviousVersion: noop,
  onDeleteSection: noop,
  editing: false,
  educatorOnly: false,
};

export default SectionViewActionMenu;
