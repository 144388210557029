/* eslint-disable class-methods-use-this */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import { hideVisually } from 'polished';
import PropTypes from 'prop-types';

import { List as MaterialList } from '@material-ui/core';
import {
  getAssignmentProvider,
  getIsCoupledProvider,
  isAssignmentProviderDisabled,
} from '@core/assignments/utils';
import { analyticsTrackEvent } from '@core/utils/analytics';
import * as analyticEvents from '@core/constants/analytics';
import deleteLti1p3Assignment from '@core/api/lti/deleteLti1p3Assignment';
import {
  TURNITIN_PROVIDER,
  HANDIN_PROVIDER,
  QUIZZES_PROVIDER,
  MANUAL,
  LTI1P3_PROVIDER,
} from '@core/assignments/constants';
import AssignmentCard from '../AssignmentCard/AssignmentCard';
import {
  Container,
  OuterContainer,
  LoaderContainer,
  DeleteIcon,
  ExportIcon,
  deleteTextClass,
  Visibility,
  VisibilityOff,
  Edit,
  Open,
  Lock,
  LockOpen,
  People,
  EmptyBox,
  EmptyBg,
  Image,
  EmptyTitle,
  EmptySubTitle,
} from './List.styled';
import launchHandin from '../utils/launchHandin';
import launchManualAssignment from '../utils/launchManualAssignment';
import TurnitinModal from '../TurnitinModal';

import { gradeTypeDisplays } from '../constants';
import emptyImage from '../../../../../../static/Search_Empty.svg';
import { STATUS, getStatus, getGradeAndSubmittedProps } from './utils';
import Scrollbar from '../../../common/ScrollBar';
import { AlertDialog } from '../../../common/Dialog';
import * as TEXT from '../../../../../constants/texts';
import CircularProgress from '../../../CircularProgress';
import { Lti1p3Launch } from '../Lti1p3Launch/Lti1p3Launch';
import GotItModal from '../CreateAssignment/GotItModal';
import { AssignmentProviderLogo } from '../AssignmentProviderLogo/AssignmentProviderLogo';
import { fetchAssignment } from '@core/assignments/api/fetch';

const ProviderConfig = {
  'hand-in': {
    title: 'Handin',
  },
  handin: {
    title: 'Handin',
  },
  turnitin: {
    title: 'Turnitin',
  },
  quizzes: {
    title: 'Quizzes',
  },
  'lti-1p3': {
    title: 'Lti-1p3',
  },
};

const isGroupAssignment = ({ groupSet }) => !!groupSet;

class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      turnitinLaunch: null,
      lti1p3Assignment: null,
      deleteDialogOpen: false,
      assignment: null,
      isDeleting: false,
      showSuccessDialog: false,
      editingAssigmentProviderIconUrl: null,
      editingAssignmentProviderDescription: null,
    };

    this.launchAssignment = this.launchAssignment.bind(this);
    this.exportGrades = this.exportGrades.bind(this);
    this.launchTurnitin = this.launchTurnitin.bind(this);
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderAssignmentCard = this.renderAssignmentCard.bind(this);
    this.renderList = this.renderList.bind(this);
    this.renderErrorState = this.renderErrorState.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderManualAssignment = this.renderManualAssignment.bind(this);
    this.closeTurnItIn = this.closeTurnItIn.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.confirmDeleteDialog = this.confirmDeleteDialog.bind(this);
    this.handleDeleteAssignment = this.handleDeleteAssignment.bind(this);
    this.ltiLaunchSuccess = this.ltiLaunchSuccess.bind(this);
    this.ltiLaunchError = this.ltiLaunchError.bind(this);
    this.hideShowSuccessDialog = this.hideShowSuccessDialog.bind(this);
  }

  componentDidMount() {
    // re-render every 30 seconds in case any assignments have passed the due date
    this.interval = setInterval(() => this.forceUpdate(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getVisibilityMenuItem(assignment) {
    const {
      selectedSpace: { objectId: spaceId },
      toggleHideAssignment,
      isEducator,
    } = this.props;

    if (!isEducator) {
      return null;
    }

    const action = assignment.isHidden
      ? { id: 'show-assignment', text: 'Show assignment', icon: Visibility }
      : { id: 'hide-assignment', text: 'Hide assignment', icon: VisibilityOff };

    return {
      ...action,
      onClick: () => toggleHideAssignment(spaceId, assignment),
    };
  }

  getToggleCloseMenuItem({ status, assignment }) {
    const {
      user,
      selectedSpace: { objectId: spaceId },
      closeAssignment,
      reopenAssignment,
      isEducator,
    } = this.props;

    if (!isEducator || assignment.provider !== HANDIN_PROVIDER) {
      return null;
    }

    return status === STATUS.closed
      ? {
          id: 're-open',
          text: 'Re-open',
          onClick: () => reopenAssignment(user, spaceId, assignment),
          icon: LockOpen,
        }
      : {
          id: 'close',
          text: 'Close',
          onClick: () => closeAssignment(user, spaceId, assignment),
          icon: Lock,
        };
  }

  getToggleAnonymizeMenuItem(assignment) {
    const {
      user,
      selectedSpace: { objectId: spaceId },
      anonymiseStudents,
      revealStudents,
      isEducator,
    } = this.props;

    if (!isEducator || assignment.provider !== HANDIN_PROVIDER) {
      return null;
    }

    if (assignment.extensions && assignment.extensions.length > 0) {
      return null;
    }

    return assignment.isAnonymised
      ? {
          id: 'reveal-students',
          text: 'Reveal students',
          onClick: () => revealStudents(user, spaceId, assignment),
          icon: People,
        }
      : {
          id: 'anonymise-students',
          text: 'Anonymise students',
          onClick: () => anonymiseStudents(user, spaceId, assignment),
          icon: People,
        };
  }

  async getExternalId(assignmentId) {
    const accessToken = this.props.accessToken;
    if (assignmentId && accessToken) {
      const result = await fetchAssignment({
        accessToken,
        assignmentId,
      });
      return result?.data?.assignment?.externalId;
    }
    return undefined;
  }

  async launchAssignment(assignment) {
    const {
      selectedSpace: { objectId: selectedSpaceId },
      goToSubmissions,
      isEducator,
      accessingAsStaff,
      goToReports,
    } = this.props;

    const openSubmissionsInAula = () =>
      goToSubmissions({
        spaceId: selectedSpaceId,
        assignmentId: assignment.id ? assignment.id : assignment.objectId,
      });
    if (isEducator || accessingAsStaff) {
      openSubmissionsInAula();
      return;
    }

    switch (assignment.provider) {
      case HANDIN_PROVIDER:
        launchHandin({
          assignmentId:
            assignment.externalId ||
            (await this.getExternalId(assignment.objectId)),
          spaceId: assignment.classRoom
            ? assignment.classRoom.objectId
            : selectedSpaceId,
        });
        break;
      case TURNITIN_PROVIDER:
        this.launchTurnitin(assignment);
        break;
      case QUIZZES_PROVIDER: {
        if (assignment.submission) {
          if (new Date() > new Date(assignment.endDate)) {
            goToReports({
              assignmentId: assignment.id,
              spaceId: selectedSpaceId,
              submissionId: assignment.submission[0].objectId,
              reportType: 'feedback',
            });
          }
        }
        break;
      }
      case LTI1P3_PROVIDER:
        this.launchLti1p3Assignment(assignment);
        break;
      default:
        break;
    }
  }

  exportGrades(assignment) {
    return () => {
      const { selectedSpace, exportGrades } = this.props;
      const { classRoom } = assignment;
      const spaceId = classRoom ? classRoom.objectId : selectedSpace.objectId;

      exportGrades({
        assignment,
        spaceId,
      });
    };
  }

  launchTurnitin(assignment) {
    this.setState({
      turnitinLaunch: assignment,
    });
  }

  launchLti1p3Assignment(assignment) {
    this.setState({
      lti1p3Assignment: assignment,
    });
  }

  closeTurnItIn() {
    this.setState({ turnitinLaunch: null });
  }

  openDeleteDialog(assignment) {
    this.setState({ deleteDialogOpen: true, assignment });
  }

  closeDeleteDialog() {
    this.setState({ deleteDialogOpen: false, assignment: null });
  }

  async handleDeleteAssignment(assignment) {
    const {
      user,
      selectedSpace: { objectId: spaceId },
      deleteAssignment,
      showToast,
    } = this.props;

    this.setState({ isDeleting: true });
    if (assignment.provider === LTI1P3_PROVIDER) {
      try {
        await deleteLti1p3Assignment(spaceId, assignment.id);
        analyticsTrackEvent(analyticEvents.DELETE_ASSIGNMENT, {
          assignment: {
            objectId: assignment.id,
          },
          activeClassroom: spaceId,
        });
      } catch {
        showToast('There was an error while deleting the assignment.');
      }
    } else {
      await deleteAssignment(user, spaceId, assignment);
    }
    this.setState({ isDeleting: false });
    this.closeDeleteDialog();
  }

  confirmDeleteDialog() {
    const { deleteDialogOpen, assignment, isDeleting } = this.state;

    if (deleteDialogOpen) {
      return (
        <AlertDialog
          id="confirm-delete-assignment"
          title="Delete assignment"
          message={TEXT.SURE_DELETE_ASSIGNMENT}
          open={deleteDialogOpen}
          onClose={this.closeDeleteDialog}
          action={{
            label: 'Delete',
            onClick: () => this.handleDeleteAssignment(assignment),
            danger: true,
            loading: isDeleting,
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: this.closeDeleteDialog,
          }}
        />
      );
    }

    return null;
  }

  editAssignment({ assignment, editingCoupled, iconUrl, description }) {
    const { onEditAssignment } = this.props;

    if (!editingCoupled && assignment.provider === LTI1P3_PROVIDER) {
      this.setState({
        lti1p3Assignment: assignment,
        editingAssigmentProviderIconUrl: iconUrl,
        editingAssignmentProviderDescription: description,
      });
    } else {
      onEditAssignment(assignment, editingCoupled);
    }
  }

  ltiLaunchSuccess() {
    const { isEducator } = this.props;
    this.setState({ lti1p3Assignment: null, showSuccessDialog: isEducator });
  }

  ltiLaunchError() {
    const { showLtiLaunchErrorToast } = this.props;
    this.setState({ lti1p3Assignment: null, showSuccessDialog: false });
    showLtiLaunchErrorToast();
  }

  hideShowSuccessDialog() {
    this.setState({ showSuccessDialog: false });
  }

  renderLoader() {
    return (
      <Container>
        <LoaderContainer data-testid="assignments-loader">
          <CircularProgress size="24px" />
        </LoaderContainer>
      </Container>
    );
  }

  renderEmpty() {
    const { assignments, searchQuery } = this.props;

    if (!assignments.length) {
      const title = searchQuery ? 'Oops! No matches' : 'No assignments';

      const subTitle = searchQuery
        ? 'No assignment in your search match any assignments.'
        : 'This space contains no assignments currently.';

      return (
        <EmptyBox>
          <EmptyBg>
            <Image
              width="220px"
              height="220px"
              src={emptyImage}
              alt="no_assignments"
            />
            <EmptyTitle tabIndex="-1" aria-live="polite">
              {title}
            </EmptyTitle>
            <EmptySubTitle>{subTitle}</EmptySubTitle>
          </EmptyBg>
        </EmptyBox>
      );
    }

    return null;
  }

  renderErrorState() {
    const { isErrored } = this.props;

    if (isErrored) {
      const title = 'Unable to fetch assignments';
      const subTitle = 'Refresh the page to try again!';

      return (
        <EmptyBox>
          <EmptyBg>
            <EmptyTitle>{title}</EmptyTitle>
            <EmptySubTitle>{subTitle}</EmptySubTitle>
          </EmptyBg>
        </EmptyBox>
      );
    }

    return null;
  }

  renderManualAssignment(assignment) {
    const {
      isEducator,
      selectedSpace: { objectId: selectedSpaceId },
    } = this.props;
    const { gradeType, submission } = assignment;

    const customFooter = `${gradeTypeDisplays[gradeType]} grading`;

    // block is only clickable for educator
    const handleContentClick = isEducator
      ? () =>
          launchManualAssignment({
            assignmentId: assignment.id,
            spaceId: selectedSpaceId,
          })
      : undefined;

    // not reuse `getGradeAndSubmittedProps` to avoid displaying `Submitted` status
    const grade =
      submission && submission.length ? submission[0].displayGrade : undefined;

    return (
      <AssignmentCard
        {...assignment}
        dueDate={assignment.endDate}
        key={assignment.id}
        status="manualAssignment"
        isEducator={isEducator}
        dense
        onContentClick={handleContentClick}
        customFooter={customFooter}
        grade={grade}
      />
    );
  }

  renderAssignmentCard(assignment) {
    const { isEducator, accessingAsStaff, providers } = this.props;

    if (assignment.provider === MANUAL) {
      return this.renderManualAssignment(assignment);
    }

    const providerConfig = ProviderConfig[assignment.provider];

    if (!providerConfig) {
      // Provider not supported
      return null;
    }

    const isLti1P3Provider = assignment.provider === LTI1P3_PROVIDER;
    const provider = getAssignmentProvider(assignment, providers);
    const isCoupled = getIsCoupledProvider(provider);

    const providerName = isLti1P3Provider
      ? provider?.description
      : providerConfig.title;
    const providerIconUrl = provider?.iconUrl;
    const isProviderDisabled = isAssignmentProviderDisabled(
      assignment,
      providers
    );

    const assignmentStatus = getStatus(assignment);

    const showOpenMenuItem =
      [LTI1P3_PROVIDER, HANDIN_PROVIDER].includes(assignment.provider) &&
      isEducator &&
      !isProviderDisabled;

    const showEditMenuItem =
      [TURNITIN_PROVIDER, QUIZZES_PROVIDER].includes(assignment.provider) &&
      isEducator &&
      !isProviderDisabled &&
      !isCoupled;

    const showEditAssignmentMenuItem =
      isEducator && !isProviderDisabled && isCoupled;

    const isEditEnabledForQuizzes = !(providerName === 'Quizzes');
    const menuItems = [
      this.getVisibilityMenuItem(assignment),
      showOpenMenuItem && {
        id: 'open',
        text: `Open in ${providerName}`,
        onClick: () =>
          this.editAssignment({
            assignment,
            editingCoupled: false,
            iconUrl: providerIconUrl,
            description: providerName,
          }),
        icon: Open,
      },
      showEditMenuItem &&
        isEditEnabledForQuizzes && {
          id: 'edit',
          text: `Edit in ${providerName}`,
          onClick: () =>
            this.editAssignment({
              assignment,
              editingCoupled: false,
              iconUrl: providerIconUrl,
              description: providerName,
            }),
          icon: Edit,
        },
      showEditAssignmentMenuItem && {
        id: 'edit-assigment',
        text: 'Edit assignment',
        onClick: () =>
          this.editAssignment({
            assignment,
            editingCoupled: true,
            iconUrl: providerIconUrl,
            description: providerName,
          }),
        icon: Edit,
      },
      (isEducator || accessingAsStaff) && {
        id: 'export-grades',
        text: 'Export grades',
        onClick: this.exportGrades(assignment),
        icon: ExportIcon,
      },
      this.getToggleCloseMenuItem({
        assignment,
        status: assignmentStatus,
      }),
      this.getToggleAnonymizeMenuItem(assignment),
      isEducator &&
        (!isLti1P3Provider || isCoupled) && {
          id: 'delete',
          text: 'Delete',
          onClick: () => this.openDeleteDialog(assignment),
          icon: DeleteIcon,
          textClass: deleteTextClass,
        },
    ].filter((item) => !!item);

    return (
      <AssignmentCard
        {...assignment}
        dueDate={assignment.endDate}
        key={assignment.objectId}
        provider={assignment.provider}
        status={assignmentStatus}
        isEducator={isEducator}
        dense
        menuItems={menuItems}
        Logo={() => (
          <AssignmentProviderLogo
            imageSrc={providerIconUrl}
            providerType={assignment.provider}
            providerName={providerName}
          />
        )}
        {...getGradeAndSubmittedProps(isEducator, assignment)}
        onContentClick={() => this.launchAssignment(assignment)}
        isGroupAssignment={isGroupAssignment(assignment)}
        isProviderDisabled={isProviderDisabled}
      />
    );
  }

  renderList() {
    const { user, selectedSpace, assignments, isEducator } = this.props;

    const {
      turnitinLaunch,
      lti1p3Assignment,
      showSuccessDialog,
      editingAssignmentProviderDescription,
      editingAssigmentProviderIconUrl,
    } = this.state;

    if (!assignments.length) {
      return null;
    }

    return (
      <MaterialList data-testid="assignment-list">
        <span
          aria-live="polite"
          style={{ ...hideVisually() }}
        >{`${assignments.length} assignments found`}</span>
        {assignments.map(this.renderAssignmentCard)}
        {turnitinLaunch && (
          <TurnitinModal
            key={turnitinLaunch}
            user={user}
            assignment={turnitinLaunch}
            space={selectedSpace}
            isEducator={isEducator}
            onClose={this.closeTurnItIn}
          />
        )}
        {lti1p3Assignment && (
          <Lti1p3Launch
            providerId={lti1p3Assignment.providerId}
            resourceLinkId={lti1p3Assignment.resourceLinkId}
            resourceLinkTitle={lti1p3Assignment.title}
            assignmentId={lti1p3Assignment.id || lti1p3Assignment.objectId}
            onSuccessLaunch={this.ltiLaunchSuccess}
            onErrorLaunch={this.ltiLaunchError}
          />
        )}
        {showSuccessDialog && (
          <GotItModal
            isVisible={showSuccessDialog}
            logo={editingAssigmentProviderIconUrl}
            name={editingAssignmentProviderDescription}
            textParagraph1="Please proceed to your selected tool, which has been opened in a new tab, to edit the assignment."
            textParagraph2="After the assignment has been edited, any updates will be reflected in Aula."
            onClose={this.hideShowSuccessDialog}
          />
        )}
      </MaterialList>
    );
  }

  renderContent() {
    const { isFetching, isErrored, assignments, hasFetchedAssignments } =
      this.props;
    if (isFetching && !hasFetchedAssignments) {
      return this.renderLoader();
    }

    if (isErrored) {
      return this.renderErrorState();
    }

    if (!assignments.length) {
      return this.renderEmpty();
    }

    return this.renderList();
  }

  render() {
    const { accessToken } = this.props;
    if (!accessToken) {
      return this.renderLoader();
    }

    return (
      <OuterContainer>
        <Scrollbar autoHide style={{ height: '100%' }}>
          <Container>{this.renderContent()}</Container>
          {this.confirmDeleteDialog()}
        </Scrollbar>
      </OuterContainer>
    );
  }
}

List.propTypes = {
  user: PropTypes.object.isRequired,
  assignments: PropTypes.array,
  isFetching: PropTypes.bool,
  hasFetchedAssignments: PropTypes.bool,
  isErrored: PropTypes.bool,
  searchQuery: PropTypes.string,
  selectedSpace: PropTypes.object,
  isEducator: PropTypes.bool.isRequired,
  accessingAsStaff: PropTypes.bool.isRequired,
  toggleHideAssignment: PropTypes.func.isRequired,
  closeAssignment: PropTypes.func.isRequired,
  reopenAssignment: PropTypes.func.isRequired,
  anonymiseStudents: PropTypes.func.isRequired,
  showLtiLaunchErrorToast: PropTypes.func.isRequired,
  revealStudents: PropTypes.func.isRequired,
  deleteAssignment: PropTypes.func.isRequired,
  exportGrades: PropTypes.func.isRequired,
  goToSubmissions: PropTypes.func.isRequired,
  onEditAssignment: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  goToReports: PropTypes.func.isRequired,
  providers: PropTypes.array,
};

List.defaultProps = {
  selectedSpace: {},
  isFetching: false,
  hasFetchedAssignments: false,
  isErrored: false,
  assignments: [],
  searchQuery: null,
  accessToken: undefined,
  providers: [],
};

export default List;
