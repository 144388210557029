import { getCurrentSpaceId } from '@core/selectors/space';
import Paths from '@app/constants/paths';
import push from '../../utils/routing';

export const openScheduledAnnouncements = () => (_dispatch, getState) =>
  push(
    `/dashboard/${getCurrentSpaceId(getState())}/community/${
      Paths.ANNOUNCEMENTS
    }/scheduled`
  );

export const openScheduledCommonRoomPosts = () => (_dispatch, getState) =>
  push(
    `/dashboard/${getCurrentSpaceId(getState())}/community/${
      Paths.COMMON_ROOM
    }/scheduled`
  );
