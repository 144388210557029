import { POSTS } from '../../constants/endpoints';
import { COMMON_ROOM } from '../../constants/postFeedType';
import request from '../request';
import formatPost from './formatPost';

const createPost = async ({
  spaceId,
  content,
  scheduledFor,
  correlationId,
  feedType = COMMON_ROOM,
}) => {
  const {
    data: { post },
  } = await request({
    method: 'post',
    url: POSTS(spaceId),
    headers: {
      'x-correlation-id': correlationId || undefined,
    },
    data: {
      post: {
        content,
        scheduledFor,
        feedType,
      },
    },
  });

  return formatPost(post);
};

export default createPost;
