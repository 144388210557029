import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

type PostTagsProps = {
  topSeparator?: boolean;
  bottomSeparator?: boolean;
};

export const PostTagsSeparator = styled.div<PostTagsProps>`
  background-color: ${theme.color.grey3};
  height: 1px;
  width: 100%;
  margin-top: ${({ bottomSeparator }) => (bottomSeparator ? `0` : '15px')};
  float: ${({ bottomSeparator }) => (bottomSeparator ? `left` : 'none')};
  margin-bottom: ${({ topSeparator }) => (topSeparator ? `0` : '25px')};
`;

export const PostTagsWrapper = styled.div`
  width: 100%;
  flaot: left;
`;
