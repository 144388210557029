import React from 'react';
import { Alert, AlertTitle } from '@ublend-npm/aulaui-next';
import { AlertContainer, AlertContent } from './QuizDecommissionBanner.styled';

type QuizDecommissionBannerProps = {
  institution: string;
};
const BANNER = {
  generic: {
    title: 'Important Update: Learnosity Quizzes on Aula',
    subtitle:
      'As of 1st September 2024, Learnosity quizzes are no longer supported on Aula. While you can still access and view your scores for any quizzes you completed before this date, detailed quiz feedback and interactive features will no longer be available directly on Aula.\n\nFor further information, please reach out to your institution’s administrator or support team.',
  },
  coventry: {
    title: 'Important Update: Learnosity Quizzes on Aula',
    subtitle:
      'Learnosity quizzes are no longer supported on Aula. While you can still access and view your scores for any quizzes you completed before 1st September 2024, detailed quiz feedback and interactive features will no longer be available directly on Aula. For further information, please reach out to your module leader.\n\nFor further guidance for educators, please email: <a href="mailto:learningenhance@coventry.ac.uk">learningenhance@coventry.ac.uk</a> .',
  },
  uws: {
    title: 'Important Update: Learnosity Quizzes on Aula',
    subtitle:
      'As of 1st September 2024, Learnosity quizzes are no longer supported on Aula. While you can still access and view your scores for any quizzes you completed before this date, detailed quiz feedback and interactive features will no longer be available directly on Aula.\n\n For further support, fill in the <a href="https://forms.office.com/pages/responsepage.aspx?id=t0SZ-E5Kp06RVjKZ80EWR9U_KCgDKbNLptf1zBe_LGVURURMM1FMTElaVUtJUFhCVDFFSzRYVkxORC4u" >request form</a>, and the Learning Transformation team will assist you.',
  },
};

const QuizDecommissionBanner = ({
  institution,
}: QuizDecommissionBannerProps) => {
  const title = institution
    ? BANNER[institution].title
    : BANNER['generic'].title;
  const subtitle = institution
    ? BANNER[institution].subtitle
    : BANNER['generic'].subtitle;

  return (
    <AlertContainer>
      <Alert type="info">
        <AlertTitle>{title}</AlertTitle>
        <AlertContent>
          <div
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></div>
        </AlertContent>
      </Alert>
    </AlertContainer>
  );
};

export default QuizDecommissionBanner;
