import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { hasError, isPersistable, isUpdating } from '@ublend-npm/aula-editor';
import { trimEnd } from '@ublend-npm/aula-editor-utils';

import { AulaEditorWrapper } from './AulaEditor.styles';

const withFileBlockValidation = (AulaEditor) => {
  const FileBlockValidation = ({
    onChange,
    onReturn,
    forwardedRef,
    setSubmittingState,
    isSubmitting,
    conversationId,
    ...props
  }) => {
    const handleOnReturn = useCallback((editorState) => {
      if (isPersistable(editorState)) {
        if (!hasError(editorState) && !isUpdating(editorState)) {
          onReturn(trimEnd(editorState), conversationId);
        } else {
          setSubmittingState(true);
        }
      }
    }, []);

    const handleOnChange = useCallback(
      (nextEditorState) => {
        onChange(nextEditorState, conversationId);

        // Deliver the message when the state is updated by the aula editor
        // and the submit is in progress until the state becomes persistable
        if (isSubmitting) {
          if (!isPersistable(nextEditorState) || hasError(nextEditorState)) {
            setSubmittingState(false);
          } else if (!isUpdating(nextEditorState)) {
            onReturn(nextEditorState, conversationId);
            setSubmittingState(false);
          }
        }
      },
      [isSubmitting]
    );

    return (
      <AulaEditorWrapper readOnly={isSubmitting}>
        <AulaEditor
          onChange={handleOnChange}
          onReturn={handleOnReturn}
          readOnly={isSubmitting}
          ref={forwardedRef}
          {...props}
        />
      </AulaEditorWrapper>
    );
  };

  FileBlockValidation.propTypes = {
    onChange: PropTypes.func.isRequired,
    onReturn: PropTypes.func.isRequired,
    forwardedRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
  };

  return React.forwardRef((props, ref) => {
    return <FileBlockValidation {...props} forwardedRef={ref} />;
  });
};

export default withFileBlockValidation;
