import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyElement } from '@ublend-npm/aula-editor-utils';
import { analyticsTrackEvent } from '../../../../../core/utils/analytics';
import {
  POST_EDITOR_START,
  COMMENT_EDITOR_START,
  INBOX_EDITOR_START,
  MATERIAL_EDITOR_FIRST_EDIT,
} from '../../../../../core/constants/analytics';

const editorTypeMap = {
  comment: COMMENT_EDITOR_START,
  createpost: POST_EDITOR_START,
  post: POST_EDITOR_START,
  inbox: INBOX_EDITOR_START,
  material: MATERIAL_EDITOR_FIRST_EDIT,
};

const editorTypeToAnalyticsEventType = (type) => editorTypeMap[type] || type;

const withAnalytics = (AulaEditor) => {
  const AulaEditorWithAnalytics = ({ onChange, ...props }) => {
    const { forwardedRef, correlationId, ...rest } = props;
    const trackedStart = React.useRef(false);
    const trackEvent = () => {
      const eventName = editorTypeToAnalyticsEventType(props.type);
      const { spaceId, itemType, itemId } = props.config;
      const isV3Event = [
        POST_EDITOR_START,
        MATERIAL_EDITOR_FIRST_EDIT,
        COMMENT_EDITOR_START,
      ].includes(eventName);

      if (eventName) {
        analyticsTrackEvent(eventName, {
          // Note: messages are space agnostic, so no need to send the spaceId as a field
          ...(eventName !== INBOX_EDITOR_START && { activeClassroom: spaceId }),
          itemType,
          ...(isV3Event && {
            correlationId,
            itemId,
          }),
        });
      }
    };

    const handleOnChange = (nextEditorState, conversationId) => {
      const isEditorEmpty = nextEditorState.value.every((node) =>
        isEmptyElement(node)
      );
      if (trackedStart.current && isEditorEmpty) {
        trackedStart.current = false;
      }

      if (!trackedStart.current && !isEditorEmpty) {
        trackedStart.current = true;

        trackEvent();
      }

      onChange(nextEditorState, conversationId);
    };

    return (
      <AulaEditor onChange={handleOnChange} ref={forwardedRef} {...rest} />
    );
  };

  AulaEditorWithAnalytics.propTypes = {
    type: PropTypes.oneOf([
      'comment',
      'createpost',
      'post',
      'inbox',
      'material',
      'assignmentExtensions',
    ]).isRequired,
    config: PropTypes.shape({
      apiUrl: PropTypes.string,
      institution: PropTypes.string,
      filesUrl: PropTypes.string,
      uploadRetries: PropTypes.number,
      accessToken: PropTypes.string,
      userId: PropTypes.string,
      spaceId: PropTypes.string,
      assignmentId: PropTypes.string,
      conversationId: PropTypes.string,
      postId: PropTypes.string,
      materialId: PropTypes.string,
      isEducator: PropTypes.bool,
      itemType: PropTypes.string,
      itemId: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    correlationId: PropTypes.string,
    forwardedRef: PropTypes.shape({
      current: PropTypes.shape({ focus: PropTypes.func.isRequired }),
    }),
  };

  AulaEditorWithAnalytics.defaultProps = {
    config: {},
    correlationId: undefined,
    forwardedRef: null,
  };

  return React.forwardRef((props, ref) => {
    return <AulaEditorWithAnalytics {...props} forwardedRef={ref} />;
  });
};

export default withAnalytics;
