import styled, { css } from 'react-emotion';
import Divider from '@material-ui/core/Divider';

import { IconButton as Button } from '@ublend-npm/aulaui-next';

const menuContainer = css`
  width: 240px;
  padding: 8px 0;
`;

const StyledDivider = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const MenuText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MenuButton = styled(Button)`
  margin-left: 4px;
`;

const menuClass = css`
  height: 20px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  :hover {
    opacity: 0.56;
  }
`;

const menuItemTextColor = css`
  color: black;
`;

export {
  StyledDivider,
  MenuText,
  menuContainer,
  MenuButton,
  menuClass,
  menuItemTextColor,
};
