import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Loader = styled('div')`
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.color.grayDark};
  width: 24px;
  height: 24px;
  margin-left: 8px;
  opacity: ${({ opacity }) => opacity};
`;
