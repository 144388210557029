import React from 'react';
import PropTypes from 'prop-types';
import { GlobalHotKeys } from 'react-hotkeys';
import debounce from 'lodash.debounce';
import Highlighter from 'react-highlight-words';
import { isAulaEditorState } from '@ublend-npm/aula-editor-utils';

import checkFocusTrack from '../../../utils/checkFocusTrack';
import push from '../../../utils/routing';
import { isMac } from '../../../utils/device';
import formatDate from '../../../../core/utils/formatDate';

import QuickTip from '../common/QuickTip/QuickTip';
import EditorReadOnly from '../common/EditorPlus/EditorReadOnly.container';
import { EditorWrapper, ResultText } from './OmniSearch.styled';
import SearchModal from './SearchModal';

const keyMap = {
  OPEN_SEARCH: `${isMac() ? 'command' : 'ctrl'}+shift+f`,
};

const highlightMatch = (text) => {
  const pattern = /<em>.*?<\/em>/g;
  const matches = text.match(pattern);

  return (
    <Highlighter
      searchWords={matches || []}
      autoEscape
      textToHighlight={text}
      highlightTag={({ children }) => (
        <mark key={children}>
          {children.replace(/<em>/g, '').replace(/<\/em>/g, '')}
        </mark>
      )}
    />
  );
};

const renderTh90ContentPreview = (content) => {
  const block = content.blocks[0];
  if (block.text) {
    return `${block.text.substring(0, 150)} ...`;
  }

  return (
    <EditorReadOnly
      key={block.key}
      rawEditorState={{
        ...content,
        blocks: [block],
      }}
    />
  );
};

const renderAulaEditorContentPreview = (content) => {
  const firstBlock = content.value[0];
  const text = firstBlock?.children?.[0]?.text;

  if (text) {
    return `${text.substring(0, 150)} ...`;
  }

  return (
    <EditorReadOnly
      rawEditorState={{
        ...content,
        value: [firstBlock],
      }}
    />
  );
};

const quickTip = (
  <QuickTip
    command={`${isMac() ? '⌘' : 'Ctrl'} + ⇧ + F`}
    text=" to open search."
  />
);

class OmniSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: undefined,
    };

    this.lastRequestSource = undefined;

    this.handleOpenSearch = this.handleOpenSearch.bind(this);
    this.handleSearchResultClicked = this.handleSearchResultClicked.bind(this);
    this.handleSearchBoxChange = this.handleSearchBoxChange.bind(this);
    this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
    this.searchResultRenderingData = this.searchResultRenderingData.bind(this);
    this.renderAulaEditorSearchData =
      this.renderAulaEditorSearchData.bind(this);
    this.renderTh90EditorSearchData =
      this.renderTh90EditorSearchData.bind(this);
  }

  componentWillMount() {
    this.fetchResultsDebounced = debounce(
      this.handleFetchResults.bind(this),
      200
    );
  }

  handleOpenSearch(e) {
    e.preventDefault();
    this.props.openModal();
  }

  handleFetchResults(query, type, skip, selectedTabIndex) {
    this.props.fetchResults({
      query,
      type,
      skip,
      selectedTabIndex,
    });
  }

  handleSearchBoxChange(value) {
    if (this.props.results && Object.keys(this.props.results).length) {
      this.props.clearResults({ loading: !!value });
    }

    this.setState({
      searchValue: value,
    });

    if (value) {
      const { type } = this.props.tabs[0];
      this.fetchResultsDebounced(value, type, 0);
    }
  }

  handleLoadNextPage(selectedTabIndex, searchBoxValue) {
    if (!this.props.loading) {
      const { type, results } = this.props.tabs[selectedTabIndex];
      this.fetchResultsDebounced(
        searchBoxValue,
        type,
        results.length,
        selectedTabIndex > 0 ? selectedTabIndex : undefined
      );
    }
  }

  handleSearchResultClicked(resultId) {
    const { redirectUrl, redirectQuery } = this.props.results[resultId];

    if (redirectUrl) {
      push(redirectUrl);
    } else {
      push({ pathname: this.props.location.pathname, query: redirectQuery });
    }

    this.props.closeModal();
  }

  searchResultRenderingData({ content, ...props }) {
    const parsedContent = JSON.parse(content);

    if (isAulaEditorState(parsedContent)) {
      return this.renderAulaEditorSearchData({
        ...props,
        content: parsedContent,
      });
    }

    return this.renderTh90EditorSearchData({
      ...props,
      content: parsedContent,
    });
  }

  renderAulaEditorSearchData({
    createdAt,
    content,
    title,
    highlightTitle,
    avatar,
    footerPrefix,
    footerKey,
    keysHighlightMap,
  }) {
    const highlightedTexts = Object.entries(keysHighlightMap);
    return {
      userName: title,
      highlightUserName: !!highlightTitle,
      avatar,
      postDate: formatDate(createdAt),
      content: (
        <EditorWrapper>
          {highlightedTexts.length
            ? highlightedTexts.map(([key, texts], i) => {
                const block = content.value[key];
                // render text as text, highlighting matches
                if (!block.type || block.type.includes('list')) {
                  return (
                    <ResultText key={key}>
                      {highlightMatch(
                        `${i === 0 ? '...' : ''} ${texts.join('')} ...`
                      )}
                    </ResultText>
                  );
                }
                // render other things using the editor.
                return (
                  <EditorReadOnly
                    rawEditorState={{
                      ...content,
                      value: [block],
                    }}
                  />
                );
              })
            : renderAulaEditorContentPreview(content)}
        </EditorWrapper>
      ),
      footer: (
        <span>
          {footerPrefix} <b>{this.props.footerMap[footerKey]}</b>
        </span>
      ),
    };
  }

  renderTh90EditorSearchData({
    createdAt,
    content,
    title,
    highlightTitle,
    avatar,
    footerPrefix,
    footerKey,
    keysHighlightMap,
  }) {
    return {
      userName: title,
      highlightUserName: !!highlightTitle,
      avatar,
      postDate: formatDate(createdAt),
      content: (
        <EditorWrapper>
          {Object.keys(keysHighlightMap).length
            ? content.blocks
                .filter((block) => block.key in keysHighlightMap)
                .map((block, i) => {
                  if (block.text && block.text.trim().length) {
                    return (
                      <ResultText key={block.key}>
                        {highlightMatch(
                          `${i === 0 ? '...' : ''} ${
                            keysHighlightMap[block.key]
                          } ...`
                        )}
                      </ResultText>
                    );
                  }

                  return (
                    <EditorReadOnly
                      key={block.key}
                      rawEditorState={{
                        ...content,
                        blocks: [block],
                      }}
                    />
                  );
                })
            : renderTh90ContentPreview(content)}
        </EditorWrapper>
      ),
      footer: (
        <span>
          {footerPrefix} <b>{this.props.footerMap[footerKey]}</b>
        </span>
      ),
    };
  }

  render() {
    const handlers = {
      OPEN_SEARCH: this.handleOpenSearch,
    };

    return (
      <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
        <SearchModal
          open={this.props.open}
          loading={this.props.loading}
          placeholder="Search for posts, files, conversations, materials…"
          emptyTitle="Oops! No results."
          searchBoxFooter={this.state.searchValue ? null : quickTip}
          results={this.props.results}
          resultRenderingData={this.searchResultRenderingData}
          sidePanelTitle="Filter by"
          sidePanelTitlePosition={1}
          sidePanelTabs={this.props.tabs}
          onClose={this.props.closeModal}
          onSearchBoxChange={this.handleSearchBoxChange}
          onSearchResultClick={this.handleSearchResultClicked}
          onContentBottomScroll={this.handleLoadNextPage}
          onKeyDown={(e, ref) => checkFocusTrack(e, ref ? ref.current : null)}
        />
      </GlobalHotKeys>
    );
  }
}

OmniSearch.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  results: PropTypes.objectOf(PropTypes.object),
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchResults: PropTypes.func.isRequired,
  clearResults: PropTypes.func.isRequired,
  footerMap: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

OmniSearch.defaultProps = {
  results: undefined,
};

export default OmniSearch;
