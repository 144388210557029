import React, { useEffect, useState } from 'react';
import { Avatar, icons, theme } from '@ublend-npm/aulaui-next';
import { LtiVersion } from '@ublend-npm/aula-schema';

import {
  HANDIN_PROVIDER,
  TURNITIN_PROVIDER,
} from '@core/assignments/constants';
import { validateImageUrl } from '@core/utils/validateImageUrl';

import {
  RadioContainer,
  AssignmentLabelContainer,
  DescriptionContainer,
  AssignmentProviderText,
  DescriptionText,
  TitleTextField,
} from './ToolSelector.styled';
import Radio from '../../../common/Radio';
import { Tool } from './utils';

const DESCRIPTION = {
  [HANDIN_PROVIDER]: 'Recommended for text or multimedia submissions',
  [TURNITIN_PROVIDER]: 'Recommended when plagiarism checking is required',
};

type ToolSelectorProps = {
  currentTool: Tool;
  tools: Tool[];
  onToolChange: (tool: Tool) => void;
  onResourceLinkTitleChange: (value: string) => void;
  resourceLinkTitle: string;
};
export type FailedImagesType = {
  [key: string]: boolean;
};
const ToolSelector = ({
  currentTool,
  tools,
  onToolChange,
  onResourceLinkTitleChange,
  resourceLinkTitle,
}: ToolSelectorProps) => {
  const [failedImages, setFailedImages] = useState<FailedImagesType>({});

  useEffect(() => {
    (async () => {
      const newFailedImages = {};

      const responses = await Promise.all(
        tools.map((tool) => {
          if (tool.logo) {
            return validateImageUrl(tool.logo);
          }
          return new Promise((resolve) => resolve(true));
        })
      );

      responses.forEach((resp, index) => {
        newFailedImages[tools[index].key] = !resp;
      });

      setFailedImages(newFailedImages);
    })();
  }, [tools]);

  const renderAssignmentLabel = (tool: Tool) => {
    const imageIcon =
      tool.logo && !failedImages[tool.key] ? (
        <Avatar src={tool.logo} name={tool.logo} size="small" />
      ) : (
        <icons.ExtensionIcon
          style={{
            color: theme.color.designSystem.grey50,
          }}
          data-testid="extension-icon"
        />
      );

    return (
      <AssignmentLabelContainer aria-hidden="true">
        {imageIcon}
        <DescriptionContainer>
          <AssignmentProviderText>{tool.name}</AssignmentProviderText>
          {DESCRIPTION[tool.key] ? (
            <DescriptionText>{DESCRIPTION[tool.key]}</DescriptionText>
          ) : null}
        </DescriptionContainer>
      </AssignmentLabelContainer>
    );
  };

  return (
    <RadioContainer>
      {tools.map((tool) => {
        const selected = tool.key === currentTool.key;

        const showTitleField =
          selected &&
          tool.ltiVersion === LtiVersion.ONE_POINT_THREE &&
          tool.isDeepLinking === false &&
          !tool.isCoupled;

        return (
          <>
            <Radio
              data-testid={`tools-selector-${tool.key}`}
              key={tool.name}
              label={renderAssignmentLabel(tool)}
              ariaLabel={`${tool.name} ${
                DESCRIPTION[tool.key] ? `, ${DESCRIPTION[tool.key]}` : ''
              }`}
              handleChange={() => onToolChange(tool)}
              isSelected={selected}
              name="select-tool-radio"
              value={tool.name}
            />
            {showTitleField ? (
              <TitleTextField
                label="Assignment title"
                placeholder="Assignment title"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onResourceLinkTitleChange(e.target.value)
                }
                value={resourceLinkTitle}
              />
            ) : null}
          </>
        );
      })}
    </RadioContainer>
  );
};

export default ToolSelector;
