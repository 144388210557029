import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MATERIAL_ITEM_TYPE } from '@core/constants/analytics';
import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import { accessingCurrentSpaceAsStaff } from '@core/selectors/classroom';
import { getComment } from '@core/selectors/comments';
import useDeleteComment from '@core/comments/hooks/useDeleteComment';

import { AulaState } from '@core/types/state';
import EditorReadOnly from '../../common/EditorPlus/EditorReadOnly.container';
import CommentUserBanner from '../../UserBanner/CommentUserBanner/CommentUserBanner.container';
import Reactions from '../../Reactions/Reactions.container';
import CreateComment from '../CreateComment/CreateComment.container';
import { Comment } from '../types';
import {
  CommentCreateWrapper,
  ReactionsWrapper,
  ContentContainer,
  EditorWrapper,
  CommentContainer,
  CommentDelimiter,
  CommentDelimiterWrapper,
  HeaderWrapperStyle,
} from './styles';

export type CommentAnalyticsItemType =
  | typeof MATERIAL_ITEM_TYPE
  | typeof ANNOUNCEMENTS
  | typeof COMMON_ROOM
  | 'Unknown';

type CommentCellProps = {
  id: string;
  sequenceId?: string;
  showComments?: boolean;
  toggleReaction?: Function;
  rootCommentId?: string;
  renderBorder?: boolean;
  ReplyContainer?: Function;
  itemType?: CommentAnalyticsItemType;
};

const CommentCell = ({
  id,
  sequenceId = undefined,
  showComments = false,
  toggleReaction = () => {},
  rootCommentId = undefined,
  renderBorder = false,
  ReplyContainer = () => {},
  itemType = 'Unknown',
}: CommentCellProps) => {
  const [replyToCommentId, setReplyToCommentId] = useState('');
  const comment: Comment = useSelector((state) => getComment(state, id));
  const isReadOnlyUser = useSelector((state: AulaState) =>
    accessingCurrentSpaceAsStaff(state)
  );

  const { entityId, entityType, content, user, root, replies, editedAt } =
    comment;

  const { mutate: handleDelete } = useDeleteComment(comment);

  const handleReply = useCallback(
    (commentId) => {
      setReplyToCommentId(replyToCommentId === commentId ? null : commentId);
      return null;
    },
    [replyToCommentId]
  );

  const renderCreateComment = () => {
    return replyToCommentId ? (
      <CommentCreateWrapper>
        <CreateComment
          entityId={entityId}
          entityType={entityType}
          parent={replyToCommentId}
          root={rootCommentId}
          key={replyToCommentId}
          autoFocus
          didComment={() => handleReply(replyToCommentId)}
        />
      </CommentCreateWrapper>
    ) : null;
  };

  return (
    <CommentContainer key={id}>
      <CommentUserBanner
        sequenceId={sequenceId}
        entityId={entityId}
        style={HeaderWrapperStyle}
        comment={comment}
        user={user}
        isEdited={!!editedAt}
        handleReply={() => handleReply(id)}
        handleDelete={handleDelete}
      />

      <ContentContainer>
        <EditorWrapper>
          <EditorReadOnly
            key={editedAt || id}
            itemId={id}
            rawEditorState={JSON.parse(content)}
            itemType={itemType}
          />
        </EditorWrapper>
      </ContentContainer>
      {!isReadOnlyUser && (
        <ReactionsWrapper>
          <Reactions
            sequenceId={sequenceId}
            itemId={id}
            toggleReaction={(e) => toggleReaction({ ...e, commentId: id })}
          />
        </ReactionsWrapper>
      )}
      {!root && (
        <ReplyContainer
          entityId={entityId}
          entityType={entityType}
          comments={replies}
          commentsCount={replies.length}
          totalCommentsCount={replies.length}
          showComments={showComments}
          toggleReaction={toggleReaction}
          rootCommentId={rootCommentId}
          onReply={handleReply}
          showInitialComments
          isReply
        />
      )}
      {!isReadOnlyUser && renderCreateComment()}
      {renderBorder && (
        <CommentDelimiterWrapper>
          <CommentDelimiter />
        </CommentDelimiterWrapper>
      )}
    </CommentContainer>
  );
};

export default CommentCell;
