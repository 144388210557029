import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Section, Version } from '../types';
import VersionModal from './VersionModal';
import { selectors, operations } from '../../../../../store/materials';

class VersionModalContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.onRevert = this.onRevert.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingVersions && !this.props.isLoadingVersions) {
      this.onSelect(this.props.section.version);
    }
  }

  onRevert() {
    this.props.revert(this.props.selectedVersion);
  }

  onSelect(versionId) {
    if (
      !this.props.selectedVersion ||
      this.props.selectedVersion.id !== versionId
    ) {
      this.props.loadVersion(this.props.section.id, versionId);
    }
  }

  render() {
    const section = this.props.section || {};

    return (
      <VersionModal
        isLoadingVersions={this.props.isLoadingVersions}
        isLoadingVersion={this.props.isLoadingVersion}
        open={this.props.section !== null}
        revert={this.onRevert}
        current={section.version}
        reverting={this.props.reverting}
        selected={this.props.selectedVersion}
        versions={section.versions || []}
        onClose={this.props.close}
        onSelect={this.onSelect}
      />
    );
  }
}

VersionModalContainer.propTypes = {
  isLoadingVersions: PropTypes.bool,
  isLoadingVersion: PropTypes.bool,
  section: Section,
  selectedVersion: Version,
  close: PropTypes.func.isRequired,
  revert: PropTypes.func.isRequired,
  loadVersion: PropTypes.func.isRequired,
  reverting: PropTypes.bool,
};

VersionModalContainer.defaultProps = {
  isLoadingVersions: false,
  isLoadingVersion: false,
  reverting: false,
  section: null,
  selectedVersion: null,
};

const mapStateToProps = createStructuredSelector({
  isLoadingVersions: selectors.isFetchingVersions,
  isLoadingVersion: selectors.isLoadingVersion,
  selectedVersion: selectors.selectedVersion,
  section: selectors.currentRevertCandidate,
  reverting: selectors.isRevertingSection,
});

const mapDispatchToProps = (dispatch) => ({
  loadVersion: (sectionId, versionId) =>
    dispatch(operations.getVersion({ sectionId, versionId })),
  revert: (version) => dispatch(operations.revertSectionToVersion(version)),
  close: () => dispatch(operations.displayRevertModal(undefined)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionModalContainer);
