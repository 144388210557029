import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentSpaceId } from '@core/selectors/space';

import { CommentEntityType } from '@core/utils/commentsEntityTypes';
import { AulaState } from '@core/types/state';
import CommentListCollapsable from '../CommentListCollapsable/CommentListCollapsable';
import CreateComment from '../CreateComment/CreateComment.container';
import { CommentsFeedWrapper } from './styles';
import { CommentAnalyticsItemType } from '../CommentCell/CommentCell';

type CommentsFeedProps = {
  entityId: string;
  entityType: CommentEntityType;
  itemType?: CommentAnalyticsItemType;
  readOnly: boolean;
  comments: string[];
  showInitialComments?: boolean;
};

const CommentsFeed = ({
  entityId,
  entityType,
  showInitialComments,
  readOnly,
  itemType,
  comments,
}: CommentsFeedProps) => {
  const [showComments, setShowComments] = useState(false);

  const spaceId = useSelector((state: AulaState): string =>
    getCurrentSpaceId(state)
  );

  return (
    <CommentsFeedWrapper>
      <CommentListCollapsable
        entityId={entityId}
        entityType={entityType}
        itemType={itemType}
        showComments={showComments}
        showInitialComments={showInitialComments}
        comments={comments}
        commentsCount={comments.length}
        spaceId={spaceId}
      />
      {!readOnly && (
        <CreateComment
          entityId={entityId}
          entityType={entityType}
          didComment={() => setShowComments(true)}
        />
      )}
    </CommentsFeedWrapper>
  );
};

CommentsFeed.defaultProps = {
  showInitialComments: false,
};

export default CommentsFeed;
