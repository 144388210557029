import React from 'react';
import { TextField, DateTimePickerDialog } from '@ublend-npm/aulaui-next';
import { useBoolean } from '@app/hooks/useToggle/useBoolean';
import { formatDate } from '@app/utils/formatDate';

type DateFieldProps = Readonly<{
  id: string;
  className?: string;
  label: string;
  value: string | undefined;
  minDate?: string;
  maxDate?: string;
  error?: boolean;
  helperText?: string;
  onBlur: () => void;
  onChange: (date: string | undefined) => void;
}>;

export const DateField = ({
  id,
  className,
  label,
  value,
  minDate,
  maxDate,
  error,
  helperText,
  onBlur,
  onChange,
}: DateFieldProps) => {
  const [isOpen, openModal, closeModal] = useBoolean();

  const whenEnterPressed =
    (func: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        func();
      }
    };

  const handleClose = () => {
    closeModal();
    onBlur();
  };

  return (
    <>
      <div
        role="input"
        className={className}
        onClick={openModal}
        onKeyDown={whenEnterPressed(openModal)}
        onBlur={() => {
          // modal will gain focus when opened so ignore blur when this happens
          if (!isOpen) onBlur();
        }}
      >
        <TextField
          id={id}
          label={label}
          value={formatDate(value)}
          error={error}
          helperText={helperText}
        />
      </div>
      <DateTimePickerDialog
        id={label}
        date={value}
        open={isOpen}
        DateTimePickerProps={{
          dateOnly: false,
          minDate: minDate || undefined,
          maxDate: maxDate || undefined,
        }}
        onClose={handleClose}
        primaryAction={{
          label: 'Confirm',
          onAction: onChange,
        }}
        secondaryAction={{
          label: 'Cancel',
          onAction: handleClose,
        }}
      />
    </>
  );
};
