import styled, { css } from 'react-emotion';
import { theme, IconButton, ListTitle } from '@ublend-npm/aulaui-next';
import List from '@material-ui/core/List';

export const titleContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const iconColor = css`
  color: ${theme.color.grayDark};
`;

export const titleMenu = css`
  flex-basis: 90%;
`;

export const AddButton = styled(IconButton)`
  margin-left: 6px;
`;

export const MaterialListTitle = styled(ListTitle)`
  font-family: ${theme.font.family.workSans};
  font-size: 20px;
  padding: 0;
  margin-bottom: 17px;
`;

export const MaterialListWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const MaterialListScrollWrapper = styled.div`
  border-right: 1px solid ${theme.color.blackAlpha(0.12)};
  padding: ${theme.spacing.medium};
`;

export const MaterialListItemsWrapper = styled(List)`
  padding: 0;
`;
