import { connect } from 'react-redux';
import Reply from './Reply';
import { user } from '../../../../../core/selectors/users';

import {
  operations as creators,
  selectors as inbox,
} from '../../../../store/conversations';

const mapStateToProps = (state) => {
  const repliedMessage = inbox.replyingToMessage(state);
  const author = user(state, repliedMessage.writer);
  return {
    ...repliedMessage,
    author,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dismissReply: () => dispatch(creators.dismissReplyToMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reply);
