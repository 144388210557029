import React from 'react';

interface Styles {
  container: React.CSSProperties;
  rightContainer: React.CSSProperties;
  addTag: React.CSSProperties;
  optionsContainer: React.CSSProperties;
}

const styles: Styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexShrink: 0,
  },
  addTag: {
    marginTop: 4,
  },
  optionsContainer: {
    marginLeft: 8,
  },
};

export default styles;
