import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from '@ublend-npm/aulaui-next';
import { INBOX_OTHERS_TOOLTIP } from '../../../../constants/texts';
import ConversationParticipant from './ConversationParticipant.container';
import {
  Container,
  OthersContainer,
} from './ConversationModalParticipants.styled';
import ConversationModalParticipantsLoader from './ConversationModalParticipantsLoader';

const PARTICIPANTS_THRESHOLD = 9;
const TO_DISPLAY = 6;

const pluralizeOthers = (n) => (n > 1 ? 'others' : 'other');

const partitionParticipants = (threshold, displayCount) => (participants) => {
  if (participants.length > threshold) {
    return [
      participants.slice(0, displayCount),
      participants.slice(displayCount),
    ];
  }

  return [participants, []];
};

const renderOthers = (count, openViewParticipants) =>
  count > 0 ? (
    <Tooltip title={INBOX_OTHERS_TOOLTIP} position="bottom">
      <OthersContainer onClick={openViewParticipants}>
        +{count} {pluralizeOthers(count)}
      </OthersContainer>
    </Tooltip>
  ) : null;

const renderParticipant = (participant) => (
  <ConversationParticipant key={participant} participantId={participant} />
);

class ConversationModalParticipants extends React.PureComponent {
  componentDidMount() {
    this.props.fetchUsers();
  }

  componentDidUpdate() {
    this.props.fetchUsers();
  }

  render() {
    if (!this.props.isGroupConversation) {
      return null;
    }

    if (this.props.isFetchingUsers) {
      return (
        <Container>
          <ConversationModalParticipantsLoader />
        </Container>
      );
    }

    const partition = partitionParticipants(PARTICIPANTS_THRESHOLD, TO_DISPLAY);
    const [othersToDisplay, othersToHide] = partition(
      this.props.otherParticipantsIds
    );
    const moreCount = othersToHide.length;

    return (
      <Container data-testid="conversation-participants">
        {othersToDisplay.map(renderParticipant)}
        {renderOthers(moreCount, this.props.openViewParticipants)}
      </Container>
    );
  }
}

ConversationModalParticipants.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  isGroupConversation: PropTypes.bool.isRequired,
  openViewParticipants: PropTypes.func.isRequired,
  otherParticipantsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ConversationModalParticipants;
