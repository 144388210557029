import PropTypes from 'prop-types';
import React from 'react';
import TimeAgo from '../common/TimeAgo';
import Message from './Message.container';
import {
  Container,
  EditedLabel,
  MessagesContainer,
  Row,
} from './MessageGroup.styled';
import UserBlock from './UserBlock.container';

const MessageGroup = ({
  id,
  author,
  createdAt,
  edited,
  messages,
  openEmojiPicker,
  closeEmojiPicker,
  onRepliedMessageClicked,
}) => (
  <Container id={id} data-testid="message">
    <Row>
      <UserBlock
        authorId={author}
        collapsed
        subtitle={<TimeAgo date={createdAt} />}
        size="small"
      />
      {edited && <EditedLabel>(Edited)</EditedLabel>}
    </Row>
    <MessagesContainer>
      {messages.map((messageId, index) => (
        <Message
          key={`message:${messageId}`}
          messageId={messageId}
          firstMessage={index === 0}
          openEmojiPicker={openEmojiPicker}
          closeEmojiPicker={closeEmojiPicker}
          onRepliedMessageClicked={onRepliedMessageClicked}
        />
      ))}
    </MessagesContainer>
  </Container>
);

MessageGroup.propTypes = {
  id: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  edited: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
  openEmojiPicker: PropTypes.func.isRequired,
  closeEmojiPicker: PropTypes.func.isRequired,
  onRepliedMessageClicked: PropTypes.func.isRequired,
};

export default React.memo(MessageGroup);
