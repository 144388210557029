/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';

import Reaction from './Reaction/Reaction.container';
import ReactionAdd from './ReactionAdd/ReactionAdd';
import { PositionPropType } from '../emoji/EmojiPicker/EmojiPicker';

class Reactions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.toggleReaction = this.toggleReaction.bind(this);
    this.addReaction = this.addReaction.bind(this);
  }

  toggleReaction({ emojiName }) {
    const { toggleReaction } = this.props;
    toggleReaction({ emojiName });
  }

  addReaction(emoji) {
    this.toggleReaction({ emojiName: emoji.short_name });
  }

  render() {
    const {
      sequenceId,
      itemId,
      reactions,
      style,
      hideWhenNoReactions,
      anchorOrigin,
      transformOrigin,
      openEmojiPicker,
      closeEmojiPicker,
      readOnly,
    } = this.props;

    if (reactions.length === 0 && hideWhenNoReactions) {
      return null;
    }

    return (
      <div style={style}>
        {reactions.map((r) => (
          <Reaction
            id={r.id}
            itemId={itemId}
            reaction={r}
            key={r.id}
            onClick={this.toggleReaction}
            disabled={readOnly}
          />
        ))}
        <span>
          {!readOnly && (
            <ReactionAdd
              id={`addReaction-${sequenceId}`}
              onSelect={this.addReaction}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              openEmojiPicker={openEmojiPicker}
              closeEmojiPicker={closeEmojiPicker}
            />
          )}
        </span>
      </div>
    );
  }
}

Reactions.propTypes = {
  style: PropTypes.object,
  itemId: PropTypes.string.isRequired,
  reactions: PropTypes.array.isRequired,
  toggleReaction: PropTypes.func.isRequired,
  sequenceId: PropTypes.string,
  hideWhenNoReactions: PropTypes.bool,
  anchorOrigin: PositionPropType,
  transformOrigin: PositionPropType,
  openEmojiPicker: PropTypes.func,
  closeEmojiPicker: PropTypes.func,
  readOnly: PropTypes.bool,
};

Reactions.defaultProps = {
  sequenceId: 'post-0',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  hideWhenNoReactions: false,
  openEmojiPicker: () => {},
  closeEmojiPicker: () => {},
  readOnly: false,
};

export default Reactions;
