import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import { Link } from 'react-router';

export const PostNavigationLink = styled(Link)`
  font-family: ${theme.font.family.workSans};
  font-style: normal;
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.small};
  text-decoration: none;
  margin-left: -${theme.spacing.xSmall};

  text-align: right;
  cursor: pointer;

  color: ${theme.color.purple};
  display: inline-flex;
  align-items: center;
  max-width: 120px;
`;

export const PurpleColor = theme.color.purple;

export default PostNavigationLink;
