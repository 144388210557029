import { theme } from '@ublend-npm/aulaui-next';

export default {
  container: {
    display: 'inline-block',
    padding: 3,
    background: theme.color.beige,
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 5,
    cursor: 'pointer',
    transition: 'color 0.1s',
    color: theme.color.grey6,
    float: 'left',

    ':hover': {
      padding: 2,
      border: `1px solid ${theme.color.grey6}`,
      color: 'black',
    },
  },
  self: {
    padding: 2,
    border: `1px solid ${theme.color.green1}`,
    background: `${theme.color.green3}`,
    ':hover': {
      border: `1px solid ${theme.color.green0}`,
      color: 'black',
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
  },
  emojiWrapper: {
    display: 'inline-block',
    float: 'left',
  },
  count: {
    fontSize: 16,
    lineHeight: '22px',
    marginLeft: 5,
  },
  tooltipPadding: {
    padding: 3,
  },
  tooltipReaction: {
    color: theme.color.grayDark,
  },
  marginTop2: {
    marginTop: 2,
  },
};
