import styled, { css } from 'react-emotion';

import { theme } from '@ublend-npm/aulaui-next';

export const SeparatorWithTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SeparatorText = styled.span`
  font-size: ${theme.font.size.copy};
  color: ${theme.color.blackAlpha(0.6)};
  margin: 0 ${theme.spacing.medium};
`;

export const separatorClassName = css`
  width: unset;
  flex: 1;
`;
