import { DateField as DateFieldBase } from '@components/presentational/common/DateField/DateField';
import {
  SelectInput as SelectFieldBase,
  TextField as TextFieldBase,
} from '@ublend-npm/aulaui-next';
import styled from 'react-emotion';

export const TextField = styled(TextFieldBase)`
  min-height: 64px;
`;

export const DateField = styled(DateFieldBase)`
  min-height: 64px;
`;

export const SelectField = styled(SelectFieldBase)`
  min-height: 64px;
`;
