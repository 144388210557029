import styled from 'react-emotion';
import { Button as ButtonBase } from '@ublend-npm/aulaui-next';

const Button = styled(ButtonBase)`
  padding: 0;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

export default Button;
