/* eslint-disable no-undef */
import React from 'react';
import { ChevronLeft } from '@material-ui/icons';
import { PostNavigationLink, PurpleColor } from './PostNavigation.styled';

export type PreviousPage = 'all assignments' | 'feed';

export type PostNavigationProps = Readonly<{
  href: string;
  onClick?: React.MouseEventHandler;
  style?: React.CSSProperties;
  prevPage: PreviousPage;
}>;

const PostNavigation = ({
  style = {},
  href,
  onClick,
  prevPage,
}: PostNavigationProps) => {
  return (
    <PostNavigationLink to={href} style={style} onClick={onClick}>
      <ChevronLeft htmlColor={PurpleColor} />
      <span>Back to {prevPage}</span>
    </PostNavigationLink>
  );
};

export default PostNavigation;
