import { connect } from 'react-redux';

import { selectors, operations } from '../../../../../store/materials';
import FolderListItemMenu from './FolderListItemMenu';

const mapStateToProps = (state, ownProps) => {
  const folder = selectors.getMaterial(state, ownProps.materialId);
  return {
    hidden: folder.hidden,
    scheduledFor: folder.scheduledFor,
  };
};

const mapsDispatchToProps = (dispatch, ownProps) => ({
  openRenameDialog: () =>
    dispatch(operations.openRenameFolder(ownProps.materialId)),
  openDeleteDialog: () =>
    dispatch(operations.openDeleteFolder(ownProps.materialId)),
  openScheduleDialog: () =>
    dispatch(operations.openScheduleMaterial(ownProps.materialId)),
  keepHidden: () =>
    dispatch(
      operations.editFolder(ownProps.materialId, {
        hidden: true,
        scheduledFor: null,
      })
    ),
  publishNow: () =>
    dispatch(
      operations.editFolder(ownProps.materialId, {
        hidden: false,
        scheduledFor: null,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(FolderListItemMenu);
