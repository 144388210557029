import PropTypes from 'prop-types';
import { GROUP_VIEW, STUDENTS_VIEW } from './components/Navigation/constants';

export const SpaceUserType = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  fullName: PropTypes.string,
};

export const AssignmentType = {
  provider: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  gradesReleasedAt: PropTypes.string,
  description: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  parentAssignmentId: PropTypes.string,
  isAnonymised: PropTypes.bool.isRequired,
};

export const User = {
  userId: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};

export const SpaceGroupType = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
};

export const ExtensionModalDataType = {
  navigationView: PropTypes.oneOf([STUDENTS_VIEW, GROUP_VIEW]),
  assignedStudents: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
  unassignedStudents: PropTypes.arrayOf(PropTypes.shape(SpaceUserType)),
  groups: PropTypes.arrayOf(PropTypes.shape(SpaceGroupType)),
  startDate: AssignmentType.startDate,
  endDate: AssignmentType.endDate,
  gradesReleasedAt: AssignmentType.gradesReleasedAt,
  description: AssignmentType.description,
  isHidden: AssignmentType.isHidden,
  spaceId: PropTypes.string.isRequired,
};

export const AssignmentCreationErrorType = {
  errorCode: PropTypes.string,
  detail: PropTypes.string,
  errorMessage: PropTypes.string,
};

export const AssignmentUpdateErrorType = {
  errorCode: PropTypes.string,
  detail: PropTypes.string,
};
