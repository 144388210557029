import React from 'react';
import PropTypes from 'prop-types';

import Container from './DropdownItem.styled';

const DropdownItem = ({ label, leftDecoration, ...props }) => (
  <Container {...props}>
    {leftDecoration}
    {label}
  </Container>
);

DropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  leftDecoration: PropTypes.node,
};

DropdownItem.defaultProps = {
  leftDecoration: null,
};

export default DropdownItem;
