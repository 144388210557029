import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Reaction from './Reaction';
import * as userSelectors from '../../../../../core/selectors/user';
import { selectors as reactionSelectors } from '../../../../store/reactions';
import * as userActions from '../../../../../core/actions/users';
import {
  users as getUsersSelector,
  fetchingUser,
} from '../../../../../core/selectors/users';

const getUsersFromId = (users, userIds) =>
  userIds.map((userId) => users[userId]).filter((user) => !!user);

const usersSelector = () => createSelector(getUsersFromId, (users) => users);

const getReactionUsers = (reactions) => Object.keys(reactions);

const reactionUsersSelector = () =>
  createSelector(getReactionUsers, (reactions) => reactions);

const makeMapStateToProps = () => {
  const getUsers = usersSelector();
  const reactionUsers = reactionUsersSelector();

  const mapStateToProps = (state, { itemId, reaction }) => {
    const userIds = reactionUsers(reaction.users);
    const isFetchingUsers = userIds.every((userId) =>
      fetchingUser(state, userId)
    );
    const users = getUsers(getUsersSelector(state), userIds);
    const isMissingUsers = users.length !== userIds.length;
    const shouldFetchUsers = isMissingUsers && !isFetchingUsers;

    return {
      userId: userSelectors.currentUserId(state),
      disabled: reactionSelectors.isTogglingReaction(
        state,
        itemId,
        reaction.emojiName
      ),
      shouldFetchUsers,
      users,
      isMissingUsers,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, { reaction }) => ({
  getUsers: () => dispatch(userActions.fetchUsers(Object.keys(reaction.users))),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  disabled: stateProps.disabled || ownProps.disabled,
});

const ReactionContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Reaction);

export default ReactionContainer;
