import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';

import TextField from '../TextField.styled';
import Suggestions from './Suggestions';
import { Container, Content } from './Autocomplete.styled';

const itemToString = (item) => (item ? item.label : '');

const Autocomplete = ({
  id,
  placeholder,
  hideLabel,
  dataset,
  SuggestionItem,
  onItemSelect,
  onInputChange,
  isFetching,
  isDisabled,
  suggestionItemSize,
  InputStartAdornment,
  greyedOutUsers,
}) => {
  const downshift = useRef(null);
  const handleSelect = (item) => {
    if (!item) {
      return;
    }
    downshift.current.clearSelection();
    onItemSelect(item);
  };

  const inputId = `${id}-input`;
  const autocompleteIdBlock = `autocomplete-${Math.round(
    Math.random() * 1000
  )}`;

  return (
    <Container id={autocompleteIdBlock}>
      <Downshift
        ref={downshift}
        id={id}
        inputId={inputId}
        labelId={autocompleteIdBlock}
        itemToString={itemToString}
        onSelect={handleSelect}
        onInputValueChange={onInputChange}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          inputValue,
        }) => {
          const {
            onBlur,
            onFocus,
            InputProps,
            ref: inputRef,
            ...otherInputProps
          } = getInputProps({
            placeholder,
          });

          const input = (
            <TextField
              InputProps={{
                onBlur,
                onFocus,
                inputRef,
                ...InputProps,
              }}
              startAdornment={InputStartAdornment}
              disabled={isDisabled}
              InputLabelProps={getLabelProps({ shrink: true })}
              label={!hideLabel && placeholder}
              {...otherInputProps}
              id={inputId}
            />
          );

          return (
            <Content
              {...getRootProps({
                refKey: 'innerRef',
              })}
            >
              {input}
              <div aria-live="polite" {...getMenuProps()}>
                <Suggestions
                  dataset={dataset}
                  inputValue={inputValue}
                  getItemProps={getItemProps}
                  isOpen={isOpen}
                  highlightedIndex={highlightedIndex}
                  SuggestionItem={SuggestionItem}
                  isFetching={isFetching}
                  size={suggestionItemSize}
                  greyedOutUsers={greyedOutUsers}
                />
              </div>
            </Content>
          );
        }}
      </Downshift>
    </Container>
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }).isRequired
  ).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  SuggestionItem: PropTypes.elementType,
  isFetching: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  suggestionItemSize: PropTypes.string,
  greyedOutUsers: PropTypes.arrayOf(PropTypes.string),
  hideLabel: PropTypes.bool,
  InputStartAdornment: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Autocomplete.defaultProps = {
  SuggestionItem: null,
  suggestionItemSize: 'small',
  greyedOutUsers: [],
  hideLabel: true,
  isDisabled: false,
  InputStartAdornment: null,
};

export default Autocomplete;
