import React from 'react';
import { connect } from 'react-redux';
import {
  selectors,
  operations as creators,
} from '../../../../../store/conversations';
import RenameConversationModal from '../../Legacy/ChangeConversationName';
import { ConversationIcon } from './RenameGroupModal.styled';

const changeGroupName = (title) => creators.renameConversation(title);

const mapStateToProps = (state, ownProps) => ({
  open: state.inboxv2.renameGroupOpened,
  isChangingGroupName: state.inboxv2.renamingConversation,
  group: ownProps.conversation,
  participants: Object.keys(ownProps.conversation.participants)
    .map((id) => state.data.users[id])
    .filter((u) => !!u),
  title: selectors.conversationTitle(state, ownProps.conversation.id),
  icon: () => React.createElement(ConversationIcon, ownProps),
});

const mapDispatchToProps = {
  changeGroupName,
  handleClose: creators.closeRenameGroup,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  handleClose: (...params) => {
    dispatchProps.handleClose(...params);
    ownProps.onClose();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(RenameConversationModal);
