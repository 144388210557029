import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@ublend-npm/aulaui-next';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../../../../../common/Dialog';
import { NoTitleFieldSet, FieldRow } from '../../../../../common/FieldSets';
import { Footer, Subtitle } from './DiscussionTopicDialog.styled';
import {
  RemoveDiscussionButton,
  HideDiscussionButton,
  ShowDiscussionButton,
} from './DiscussionActionButtons';

const DiscussionTopicDialogInner = ({
  initialTopic,
  onClose,
  onSetDiscussionTopic,
  canRemoveDiscussion,
  onRemoveDiscussion,
  showDiscussion,
  onToggleShowDiscussion,
}) => {
  const [topic, setTopic] = useState(initialTopic);
  const [errorHelper, setErrorHelper] = useState(null);
  const handleTopicChange = (event) => {
    setTopic(event.target.value);
    setErrorHelper(null);
  };
  const handleSaveClick = () => {
    if (topic && topic.length) {
      onSetDiscussionTopic(topic);
    } else {
      setErrorHelper('Please add a prompt');
    }
  };

  const renderDiscussionActionBtn = () => {
    if (canRemoveDiscussion) {
      return <RemoveDiscussionButton onClick={onRemoveDiscussion} />;
    }
    return showDiscussion ? (
      <HideDiscussionButton
        onClick={() => onToggleShowDiscussion(false, topic)}
      />
    ) : (
      <ShowDiscussionButton
        onClick={() => onToggleShowDiscussion(true, topic)}
      />
    );
  };

  return (
    <>
      <DialogTitle
        id="discussion-topic-dialog-title"
        role="heading"
        onClose={onClose}
      >
        {initialTopic
          ? `Edit discussion/activity`
          : `Add a discussion or activity`}
      </DialogTitle>
      <DialogContent>
        <NoTitleFieldSet>
          <Subtitle>
            Write a prompt and let students respond with comments and replies.
          </Subtitle>
          <FieldRow>
            <TextField
              id="discussion-topic"
              role="textbox"
              label="Prompt"
              helperText={errorHelper}
              error={!!errorHelper}
              useErrorIcon={false}
              value={topic}
              onChange={handleTopicChange}
            />
          </FieldRow>
        </NoTitleFieldSet>
      </DialogContent>
      <Footer isSpread={!!initialTopic}>
        {!!initialTopic && renderDiscussionActionBtn()}
        <DialogActions
          primary={{
            label: 'Confirm',
            onClick: handleSaveClick,
            htmlAttributes: { 'data-testid': 'discussion-topic-save' },
          }}
          secondary={{
            label: 'Cancel',
            onClick: onClose,
            htmlAttributes: { 'data-testid': 'discussion-topic-cancel' },
          }}
        />
      </Footer>
    </>
  );
};

DiscussionTopicDialogInner.propTypes = {
  initialTopic: PropTypes.string,
  onSetDiscussionTopic: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveDiscussion: PropTypes.func,
  canRemoveDiscussion: PropTypes.bool,
  showDiscussion: PropTypes.bool,
  onToggleShowDiscussion: PropTypes.func.isRequired,
};

DiscussionTopicDialogInner.defaultProps = {
  initialTopic: '',
  onRemoveDiscussion: () => {},
  canRemoveDiscussion: false,
  showDiscussion: false,
};

export default DiscussionTopicDialogInner;
