import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  HANDIN_PROVIDER,
  TURNITIN_PROVIDER,
} from '@core/assignments/constants';

const useAnonymousExtensionsFlag = ({
  provider,
  isAnonymised,
}: {
  provider: String;
  isAnonymised: boolean;
}) => {
  const {
    webappFtTurnitinAnonymousExtensions202110,
    webappFtHandinAnonymousExtensions202110,
  } = useFlags();

  if (isAnonymised) {
    if (provider === HANDIN_PROVIDER) {
      return webappFtHandinAnonymousExtensions202110;
    }
    if (provider === TURNITIN_PROVIDER) {
      return webappFtTurnitinAnonymousExtensions202110;
    }

    return false;
  }

  return true;
};

export default useAnonymousExtensionsFlag;
