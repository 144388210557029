import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ImportantPosts from './ImportantPosts/ImportantPosts.container';
import ImportantPostsV4 from './ImportantPostsV4/ImportantPosts.container';

const ImportantPostsToggle = (props) => {
  const { webAppFtMuiV6Feed202412 } = useFlags();

  return webAppFtMuiV6Feed202412 ? (
    <ImportantPosts {...props} />
  ) : (
    <ImportantPostsV4 {...props} />
  );
};

export default ImportantPostsToggle;
