import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from '@ublend-npm/aulaui-next';
import SelectAssignmentContainer from './AssignmentSelection.styled';

const AssignmentSelection = ({ assignments, value, onChange }) => {
  return (
    <SelectAssignmentContainer data-testid="select-assignment">
      <SelectInput
        value={value || ''}
        name="SelectInput"
        label="Destination assignment"
        data={assignments}
        handleChange={(e) => {
          return onChange(e.target.value);
        }}
      />
    </SelectAssignmentContainer>
  );
};

AssignmentSelection.propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default React.memo(AssignmentSelection);
