import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import LaunchIcon from '@mui/icons-material/Launch';
import { TURNITIN_PROVIDER } from '@core/assignments/constants';
import { ENTER } from '../../../../../utils/keyCodes';
import { useAssignmentTypeProvider } from '../AssignmentTypeProvider';

import {
  GroupName,
  GridColumn,
  VisuallyHidden,
  SubmissionListItem,
  SubmissionHyperLink,
} from '../Submissions.styled';

const SubmissionsGroupCard = memo(
  ({ name, grade, userId, submissionId, submissionDate, accessingAsStaff }) => {
    const [isVisible, setIsVisible] = useState(false);
    const assignmentTypeConfig = useAssignmentTypeProvider();

    const { title, type, launchSubmission } = assignmentTypeConfig;
    const isTurnitinSubmission = type === TURNITIN_PROVIDER;
    const hasSubmission = !!submissionId;
    const canOpenSubmission =
      hasSubmission && (!accessingAsStaff || !isTurnitinSubmission);

    const onFocus = () => setIsVisible(true);
    const onBlur = () => setIsVisible(false);

    const handleLaunchSubmission = (e) => {
      e.stopPropagation();
      return launchSubmission({ userId });
    };

    return (
      <SubmissionListItem
        data-testid="submission-item"
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onFocus}
        onMouseLeave={onBlur}
        isHeader
      >
        <GridColumn cols={6}>
          <GroupName data-testid="submission-item-full-name">{name}</GroupName>
        </GridColumn>
        <GridColumn cols={4}>
          <p data-testid="submission-item-date">{submissionDate}</p>
        </GridColumn>
        <GridColumn cols={2}>
          <span data-testid="submission-item-grade">{grade}</span>
          {canOpenSubmission && (
            <SubmissionHyperLink
              tabIndex="0"
              onClick={handleLaunchSubmission}
              onKeyDown={(e) => e.keyCode === ENTER && handleLaunchSubmission()}
              data-testid="submission-item-link"
              css={!isVisible ? VisuallyHidden : ''}
            >
              <span>Open in {title}</span>
              <LaunchIcon fontSize="small" />
            </SubmissionHyperLink>
          )}
        </GridColumn>
      </SubmissionListItem>
    );
  }
);

SubmissionsGroupCard.propTypes = {
  name: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
  grade: PropTypes.string.isRequired,
  submissionDate: PropTypes.string.isRequired,
  accessingAsStaff: PropTypes.bool,
};

SubmissionsGroupCard.defaultProps = {
  accessingAsStaff: false,
};

export default SubmissionsGroupCard;
