import { currentClassRoomId } from '../../../core/selectors/classroom';
import { exportSections } from '../../../core/materials/api';
import getNewFileUrl from '../../utils/getNewFileUrl';
import toastOperation from '../../../core/toasts/operations';

const exportMaterial = (educatorOnly) => (dispatch, getState) => {
  const state = getState();
  const classRoomId = currentClassRoomId(state);
  exportSections(classRoomId, educatorOnly)
    .then((url) => {
      // convert url so that access grants can get validated
      const newUrl = getNewFileUrl(url);
      window.open(newUrl, '_blank');
    })
    .catch(() => {
      dispatch(
        toastOperation.showToast({
          emoji: '🤔',
          message: 'There was an error when exporting the materials.',
        })
      );
    });
};

export default exportMaterial;
