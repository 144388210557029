import React from 'react';
import PropTypes from 'prop-types';
import { ParticipantContent, Tooltip } from '@ublend-npm/aulaui-next';
import { Root, tooltipContainer } from './User.styled';

const User = ({
  fullName,
  email,
  onActionClick,
  actionIcon,
  tooltip,
  userAdded,
  avatar,
  isDisabled,
  disabledText,
}) => {
  const renderParticipantContent = () => {
    return (
      <ParticipantContent
        fullName={fullName}
        email={email}
        avatar={avatar}
        icon={actionIcon}
        tooltip={tooltip}
        userInfoGreyedOut={userAdded}
        onClick={onActionClick}
        isDisabled={isDisabled}
      />
    );
  };
  return (
    <Root>
      {disabledText ? (
        <Tooltip
          title={disabledText}
          position="top"
          classes={{
            tooltip: tooltipContainer,
          }}
        >
          <div>{renderParticipantContent()}</div>
        </Tooltip>
      ) : (
        renderParticipantContent()
      )}
    </Root>
  );
};

User.propTypes = {
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string,
  onActionClick: PropTypes.func,
  actionIcon: PropTypes.elementType,
  tooltip: PropTypes.string,
  avatar: PropTypes.string,
  userAdded: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

User.defaultProps = {
  email: '',
  tooltip: '',
  userAdded: false,
  isDisabled: false,
  onActionClick: () => {},
  disabledText: '',
  actionIcon: null,
  avatar: null,
};

export default React.memo(User);
