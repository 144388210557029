import { connect } from 'react-redux';
import AssignmentPage from './AssignmentPage.component';
import { baseDomain } from '../../../../../../core/selectors/institution';
import {
  isInstructor,
  currentClassRoomId,
} from '../../../../../../core/selectors/classroom';

const mapStateToProps = (state) => ({
  baseDomain: baseDomain(state),
  isInstructor: isInstructor(state),
  spaceId: currentClassRoomId(state),
});

export default connect(mapStateToProps, null)(AssignmentPage);
