/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import debounce from 'lodash.debounce';
import { Tooltip } from '@ublend-npm/aulaui-next';
import EmojiUtils from '../../../../../core/utils/emoji';
import Emoji from '../../emoji/Emoji';
import style from './style';
import formatNames from './formatNames';

// to avoid frequent service call when user moving mouse in screen
// added wait time, after wait time it will make service call
const WAIT_MS = 150;

const noop = () => {};

@Radium
class Reaction extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getUsers = debounce(this.getUsers.bind(this), WAIT_MS);
    this.onShowTooltipEvent = this.onShowTooltipEvent.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this.updateEmoji();
  }

  componentDidUpdate(prevProps) {
    const {
      reaction: { emojiName },
    } = this.props;
    const emojiChanged = prevProps.reaction.emojiName !== emojiName;
    if (emojiChanged) {
      this.updateEmoji();
    }
  }

  onShowTooltipEvent() {
    const { shouldFetchUsers } = this.props;
    if (shouldFetchUsers) {
      this.getUsers();
    }
  }

  onToggle() {
    const {
      onClick,
      reaction: { emojiName },
    } = this.props;
    onClick({ emojiName });
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      this.onToggle();
    }
  }

  async getUsers() {
    const { getUsers } = this.props;
    await getUsers();
  }

  hasUserReacted() {
    const {
      reaction: { users },
      userId,
    } = this.props;
    return !!users[userId];
  }

  updateEmoji() {
    const {
      reaction: { emojiName },
    } = this.props;
    this.setState({
      emoji: EmojiUtils.getFromShortName(emojiName),
    });
  }

  renderTooltipTitle() {
    const { reaction, userId, users, isMissingUsers } = this.props;

    return (
      <div style={style.tooltipPadding}>
        {!isMissingUsers ? (
          <div>
            <span style={style.tooltipReaction}>
              Reacted with
              {` :${reaction.emojiName}:`}
            </span>
            <div style={style.marginTop2}>
              {formatNames({
                userId,
                users,
                self: this.hasUserReacted(),
              })}
            </div>
          </div>
        ) : (
          'Loading...'
        )}
      </div>
    );
  }

  render() {
    const { reaction, id, disabled } = this.props;
    const { emoji } = this.state;

    const reactionElement = (
      <span
        id={id}
        role="button"
        aria-label={`${reaction.emojiName} ${reaction.count}`}
        aria-disabled={disabled}
        onMouseEnter={this.onShowTooltipEvent}
        onFocus={this.onShowTooltipEvent}
        style={{
          ...style.container,
          ...(this.hasUserReacted() ? style.self : {}),
          ...(disabled ? style.disabled : {}),
        }}
        onClick={disabled ? noop : this.onToggle}
        onKeyDown={disabled ? noop : this.onKeyDown}
        tabIndex="0"
      >
        <span style={style.emojiWrapper}>
          <Emoji emoji={emoji} size={22} />
        </span>
        <span style={style.count}>{reaction.count}</span>
      </span>
    );

    return disabled ? (
      reactionElement
    ) : (
      <Tooltip title={this.renderTooltipTitle()}>{reactionElement}</Tooltip>
    );
  }
}

Reaction.propTypes = {
  reaction: PropTypes.shape({
    users: PropTypes.shape({}),
    count: PropTypes.number,
    emoji: PropTypes.shape({}),
    emojiName: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  getUsers: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  shouldFetchUsers: PropTypes.bool.isRequired,
  isMissingUsers: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ).isRequired,
};

Reaction.defaultProps = {
  disabled: false,
};

export default Reaction;
