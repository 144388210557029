import { css } from 'react-emotion';

export default {
  editorWrapper: {
    color: 'black',
    marginLeft: 50,
    marginRight: 50,
  },
};

export const overflowHiddenClass = css`
  overflow-y: hidden;
`;
