import { getPostById } from './posts.selectors';
import { getMaterial } from '../materials/selectors';
import CommentsEntityTypes from '../utils/commentsEntityTypes';

export const getComment = (state, commentId) => state.data.comments[commentId];

export const getComments = (state, entityId, entityType) => {
  switch (entityType) {
    case CommentsEntityTypes.material: {
      const comments = (getMaterial(state, entityId) || {}).comments || [];
      return comments;
    }
    case CommentsEntityTypes.post:
      return (getPostById(state, entityId) || {}).comments || [];
    default:
      return [];
  }
};

export const commentCount = (state, entityId, entityType) =>
  getComments(state, entityId, entityType).length;
