/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import InfiniteTable from '../../common/InfiniteTable';
import MessageGroup from './MessageGroup.container';
import BeginningIndicator from './BeginningIndicator';
import MessageTableNoContent from './MessageTableNoContent';
import TypingIndicator from './TypingIndicator.container';
import JumpToBottomButton from './JumpToBottomButton';

const groupKeyExtractor = (group) => `group:${group.messages[0]}`;

class MessageTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fromBottom: 0,
      moreThanOneScreenUp: false,
    };

    this.infinite = null;

    /* this will be used to know whether to force scrolling to the bottom  */
    this.onScroll = this.onScroll.bind(this);
    this.onWriteZoneResize = this.onWriteZoneResize.bind(this);
    this.setInfinite = this.setInfinite.bind(this);
    this.scrollFromBottom = this.scrollFromBottom.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.onRepliedMessageClicked = this.onRepliedMessageClicked.bind(this);
    this.onJumpToBottom = this.onJumpToBottom.bind(this);
  }

  componentDidMount() {
    const { currentMessageId } = this.props;
    if (currentMessageId) {
      this.loadAndScrollToMessage(currentMessageId);
    } else {
      this.scrollToBottom();
      this.optionallySetConversationRead();

      const { shouldFetchOnMount } = this.props;
      if (shouldFetchOnMount) {
        this.fetchInitial();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      conversationLoaded,
      conversationId,
      latestMessageDate,
      shouldFetchOnMount,
    } = this.props;
    if (!conversationLoaded) return;

    const { fromBottom } = this.state;
    const atBottom = fromBottom === 0;
    const conversationChanged = prevProps.conversationId !== conversationId;

    const becauseNewMessageAndAtBottom =
      atBottom && latestMessageDate !== prevProps.latestMessageDate;

    if (conversationChanged || becauseNewMessageAndAtBottom) {
      this.scrollToBottom();
    }

    if (shouldFetchOnMount && conversationChanged) {
      this.fetchInitial();
    }

    this.optionallySetConversationRead();
  }

  onWriteZoneResize() {
    this.scrollFromBottom();
  }

  onScroll({ scrollHeight, clientHeight, scrollTop }) {
    const fromBottom = scrollHeight - clientHeight - scrollTop;
    const moreThanOneScreenUp = fromBottom > clientHeight;
    this.setState({
      fromBottom,
      moreThanOneScreenUp,
    });
  }

  async onRepliedMessageClicked(messageId) {
    this.loadAndScrollToMessage(messageId);
  }

  async onJumpToBottom() {
    const { hasNewerMessages, fetchInitial } = this.props;
    if (hasNewerMessages) {
      await fetchInitial();
    }

    this.scrollToBottom();
  }

  setInfinite(value) {
    this.infinite = value;
  }

  async loadAndScrollToMessage(messageId) {
    const { fetchMessagesAroundMessageId, highlightMessage } = this.props;
    await fetchMessagesAroundMessageId(messageId);
    const { groups } = this.props;
    const group = groups.find(({ messages }) => messages.includes(messageId));
    const groupElement =
      group && document.getElementById(groupKeyExtractor(group));
    if (groupElement) {
      highlightMessage(messageId);
      this.scrollIntoView(groupElement);
    }
  }

  scrollIntoView(element) {
    const { scrollTop, clientHeight } = this.infinite.getScrollValues();

    const containerBottom = scrollTop + clientHeight;
    const elementTop = element.offsetTop;
    const elementBottom = elementTop + element.clientHeight;
    if (elementBottom <= containerBottom && elementTop >= scrollTop) {
      return;
    }
    this.infinite.scrollTop(elementTop);
  }

  optionallySetConversationRead() {
    const {
      conversationCreatedAt,
      featSetConversationUnreadEnabled,
      isUnread,
      latestMessageIsOwn,
      latestMessageDate,
      setConversationRead,
      hasNewerMessages,
    } = this.props;
    if (featSetConversationUnreadEnabled) {
      if (isUnread) {
        setConversationRead(latestMessageDate || conversationCreatedAt);
      }
    } else if (isUnread && !latestMessageIsOwn && !hasNewerMessages) {
      setConversationRead(latestMessageDate);
    }
  }

  scrollFromBottom() {
    if (!this.infinite) return;

    const { fromBottom } = this.state;
    const { scrollHeight, clientHeight } = this.infinite.getScrollValues();
    this.infinite.scrollTop(scrollHeight - clientHeight - fromBottom);
  }

  scrollToBottom() {
    if (!this.infinite) return;

    this.infinite.scrollToBottom();
    this.setState({ fromBottom: 0 });
  }

  async fetchInitial() {
    const { fetchInitial } = this.props;
    await fetchInitial();
    this.scrollToBottom();
  }

  render() {
    const {
      hasOlderMessages,
      hasNewerMessages,
      emptyConversation,
      fetchingMessages,
      fetchPrevious,
      fetchNext,
      openEmojiPicker,
      closeEmojiPicker,
      groups,
    } = this.props;

    const { moreThanOneScreenUp } = this.state;

    if (emptyConversation) {
      return <MessageTableNoContent />;
    }

    return (
      <React.Fragment>
        <InfiniteTable
          ref={this.setInfinite}
          reversed
          items={groups}
          keyExtractor={groupKeyExtractor}
          renderItem={(group) => (
            <MessageGroup
              author={group.author}
              createdAt={group.createdAt}
              messages={group.messages}
              openEmojiPicker={openEmojiPicker}
              closeEmojiPicker={closeEmojiPicker}
              onRepliedMessageClicked={this.onRepliedMessageClicked}
            />
          )}
          renderListStart={() => <BeginningIndicator />}
          renderEmptyList={() => <MessageTableNoContent />}
          renderFooter={() => <TypingIndicator />}
          hasPrevious={hasOlderMessages}
          loadPrevious={fetchPrevious}
          hasNext={hasNewerMessages}
          loadNext={fetchNext}
          loading={fetchingMessages}
          onScroll={this.onScroll}
          scrollDebounceMs={0}
          viewStyle={{
            paddingLeft: 0,
            paddingTop: 24,
            paddingBottom: 0,
            paddingRight: 0,
          }}
          resetScrollOnMount={false}
        />
        <JumpToBottomButton
          visible={
            !fetchingMessages && (hasNewerMessages || moreThanOneScreenUp)
          }
          onClick={this.onJumpToBottom}
        />
      </React.Fragment>
    );
  }
}

MessageTable.propTypes = {
  groups: PropTypes.array.isRequired,
  hasOlderMessages: PropTypes.bool,
  hasNewerMessages: PropTypes.bool,
  conversationCreatedAt: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  conversationLoaded: PropTypes.bool.isRequired,
  emptyConversation: PropTypes.bool.isRequired,
  featSetConversationUnreadEnabled: PropTypes.bool,
  fetchingMessages: PropTypes.bool,
  fetchInitial: PropTypes.func.isRequired,
  fetchPrevious: PropTypes.func.isRequired,
  fetchNext: PropTypes.func.isRequired,
  isUnread: PropTypes.bool.isRequired,
  latestMessageDate: PropTypes.string,
  latestMessageIsOwn: PropTypes.bool,
  setConversationRead: PropTypes.func.isRequired,
  shouldFetchOnMount: PropTypes.bool,
  openEmojiPicker: PropTypes.func.isRequired,
  closeEmojiPicker: PropTypes.func.isRequired,
  fetchMessagesAroundMessageId: PropTypes.func.isRequired,
  highlightMessage: PropTypes.func.isRequired,
  currentMessageId: PropTypes.string,
};

MessageTable.defaultProps = {
  hasOlderMessages: true,
  hasNewerMessages: true,
  featSetConversationUnreadEnabled: false,
  fetchingMessages: false,
  latestMessageDate: undefined,
  latestMessageIsOwn: false,
  shouldFetchOnMount: false,
  currentMessageId: null,
};

export default MessageTable;
