import Prefixer from 'inline-style-prefixer';
import { theme } from '@ublend-npm/aulaui-next';
import fonts from '../../../fonts';

const inboxParticipantsStyle = new Prefixer().prefix({
  wrapper: {
    padding: '24px',
    color: 'black',
  },
  table: {
    marginTop: 20,
    maxHeight: 250,
    marginBottom: 50,
  },
  infoWrapper: {
    display: 'flex',
  },
  labelsWrapper: {
    marginLeft: 15,
  },
  title: {
    fontSize: 18,
    color: 'black',
  },
  addParticipantWrapper: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
  },
  addParticipantButton: {
    fontFamily: theme.font.family.workSans,
    fontSize: 14,
    fontWeight: theme.font.weight.regular,
    margin: '22px 0',
    boxShadow: 'none',
    textTransform: 'none',
    letterSpacing: 0.43,
    backgroundColor: '#fff',
    color: theme.color.purple3,
    border: '1px solid rgba(0, 0, 0, 0.38)',
  },

  participantAutocomplete: {
    marginTop: 40,
  },
  subtitle: {
    ...fonts.light,
    fontSize: 14,
  },
  participants: {
    fontSize: 16,
    color: 'black',
    marginTop: 50,
    marginBottom: 10,
  },
  groupNameField: {
    width: 'calc(100% - 30px)',
    fontSize: 16,
  },
});

export default inboxParticipantsStyle;
