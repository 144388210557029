import React from 'react';
import styled from 'react-emotion';
import { Button as LoaderButton } from '@ublend-npm/aulaui-next';
import UnstyledVersionList from './VersionList';
import UnstyledVersionView from './VersionView';

export const ModalContent = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: stretch;
`;

export const VersionList = styled(UnstyledVersionList)`
  flex: none;
  width: 424px;
  border-right: 1px solid #e1e1e1;
  padding-top: 8px;
`;

export const VersionView = styled(UnstyledVersionView)`
  box-sizing: border-box;
  width: 100%;
  padding: 56px;
`;

const PrimaryButton = (props) => <LoaderButton type="primary" {...props} />;

export const ActionButton = styled(PrimaryButton)`
  margin-right: 16px;

  .title {
    position: relative;
  }
`;
