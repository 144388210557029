import {
  FETCH_ACCESS_TOKEN_STARTED,
  FETCH_ACCESS_TOKEN_SUCCESS,
  FETCH_ACCESS_TOKEN_ERROR,
} from '../../constants/actions';

const accessTokensReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCESS_TOKEN_STARTED:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_ACCESS_TOKEN_SUCCESS:
      // Tokens expire every 24h, we will refresh after 23h just to be safe.
      // 108 is time for 1.8 minuetes, we need to set it 1500 for 25 mins
      return {
        ...state,
        isFetching: false,
        [action.payload.spaceId]: {
          token: action.payload.accessToken,
          expiry: Date.now() + 1000 * 1500,
        },
      };

    case FETCH_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        isFetching: false,
        [action.payload.spaceId]: null,
      };

    default:
      return state;
  }
};

export default accessTokensReducer;
