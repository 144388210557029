import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Tooltip } from '@ublend-npm/aulaui-next';
import ModeComment from '@material-ui/icons/ModeComment';
import { ChildRouteType } from '@app/components/propTypes/common';
import Material from '../../MaterialV4/Material.container';
import {
  Button,
  ButtonContainer,
  educatorAreaContainer,
  headerContainer,
  hintContainer,
  hint,
  participantsContainer,
  areaTitle,
  List,
  Container,
  MaterialContent,
} from './EducatorArea.styled';
import EducatorParticipants from './EducatorParticipants';
import EducatorAreaIcon from './EducatorAreaIcon';
import MaterialListEducatorOnly from '../MaterialListEducatorOnlyV4';

const TITLE = 'Educator area';
const HINT = 'The educator area is not visible to students';

const EducatorArea = (props) => {
  const {
    spaceId,
    educators,
    educatorsIds,
    fetchUsers,
    fetching,
    creating,
    createConversation,
    selectConversation,
    route,
    isReadOnlyUser,
  } = props;

  useEffect(() => {
    if (!fetching && educators.length !== educatorsIds.length) {
      fetchUsers(educatorsIds);
    }
  }, [educators, educatorsIds, fetching]);

  const handleClick = async () => {
    const conversation = await createConversation(spaceId);
    selectConversation(conversation.objectId);
  };
  const notEnoughEducators = !educators.length || educators.length < 2;
  const button = (
    <ButtonContainer>
      <Button
        type="primary"
        size="medium"
        loading={creating}
        iconLeft={ModeComment}
        onClick={handleClick}
        disabled={notEnoughEducators}
        data-testid="educator-area-conversation-button"
      >
        Educator conversation
      </Button>
    </ButtonContainer>
  );
  const educatorButton = notEnoughEducators ? (
    <Tooltip title="At least 2 educators are required for a conversation">
      {button}
    </Tooltip>
  ) : (
    button
  );

  return (
    <div className={educatorAreaContainer}>
      <div className={headerContainer}>
        <div>
          <div className={participantsContainer}>
            <span className={areaTitle}>{TITLE}</span>
            <EducatorParticipants educators={educators} />
          </div>
          <div className={hintContainer}>
            <EducatorAreaIcon />
            <span className={hint}>{HINT}</span>
          </div>
        </div>
        {!isReadOnlyUser && educatorButton}
      </div>
      <Container>
        <List>
          <MaterialListEducatorOnly />
        </List>
        <MaterialContent>
          <Material route={route} educatorOnly />
        </MaterialContent>
      </Container>
    </div>
  );
};

EducatorArea.propTypes = {
  spaceId: PropTypes.string.isRequired,
  educatorsIds: PropTypes.arrayOf(PropTypes.string),
  educators: PropTypes.arrayOf(PropTypes.object),
  fetchUsers: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  creating: PropTypes.bool,
  createConversation: PropTypes.func,
  selectConversation: PropTypes.func,
  route: PropTypes.shape({
    childRoutes: PropTypes.arrayOf(ChildRouteType),
    path: PropTypes.string,
  }).isRequired,
  isReadOnlyUser: PropTypes.bool.isRequired,
};

EducatorArea.defaultProps = {
  educatorsIds: [],
  educators: [],
  fetching: false,
  creating: false,
  createConversation: () => {},
  selectConversation: () => {},
};

export default React.memo(EducatorArea);
