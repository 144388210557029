import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import { theme } from '@ublend-npm/aulaui-next';

import {
  Container,
  HeaderCell,
  CheckBoxCell,
  RowCell,
  Caption,
} from './Table.styled';
import notificationsTypes from './notificationsTypes';

export default class NotificationsTable extends PureComponent {
  renderRow(notificationType) {
    const {
      hasFetchedSettings,
      isFetchingSettings,
      isSavingSettings,
      settings,
      toggleSetting,
    } = this.props;
    const disabled =
      !hasFetchedSettings || isFetchingSettings || isSavingSettings;
    return (
      <TableRow key={notificationType.label} hover>
        <RowCell component="th" scope="row">
          {notificationType.label}
        </RowCell>
        <CheckBoxCell padding="checkbox">
          <Checkbox
            style={{ color: theme.color.designSystem.purple70 }}
            inputProps={{ 'aria-label': `${notificationType.label} push` }}
            checked={!!settings.push[notificationType.value]}
            disabled={disabled}
            onChange={(_e, isChecked) => {
              toggleSetting('push', notificationType.value, isChecked);
            }}
          />
        </CheckBoxCell>
        <CheckBoxCell padding="checkbox">
          <Checkbox
            style={{ color: theme.color.designSystem.purple70 }}
            inputProps={{ 'aria-label': `${notificationType.label} email` }}
            checked={!!settings.email[notificationType.value]}
            disabled={disabled}
            onChange={(_e, isChecked) => {
              toggleSetting('email', notificationType.value, isChecked);
            }}
          />
        </CheckBoxCell>
      </TableRow>
    );
  }

  render() {
    return (
      <Container>
        <Table>
          <Caption>Notification settings</Caption>
          <TableHead>
            <TableRow>
              <HeaderCell>Notification type</HeaderCell>
              <CheckBoxCell padding="checkbox">Push</CheckBoxCell>
              <CheckBoxCell padding="checkbox">Email</CheckBoxCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationsTypes.map((notificationType) =>
              this.renderRow(notificationType)
            )}
          </TableBody>
        </Table>
      </Container>
    );
  }
}

const SettingsType = PropTypes.shape({
  push: PropTypes.shape({}),
  email: PropTypes.shape({}),
});

NotificationsTable.propTypes = {
  hasFetchedSettings: PropTypes.bool.isRequired,
  isFetchingSettings: PropTypes.bool.isRequired,
  isSavingSettings: PropTypes.bool.isRequired,
  settings: SettingsType.isRequired,
  toggleSetting: PropTypes.func.isRequired,
};
