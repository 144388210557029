import styled from 'react-emotion';

export const LoaderContaier = styled('div')`
  height: calc(100vh - 135px);
  width: calc(100% - 64px);
  position: fixed;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Iframe = styled('iframe')`
  width: 100%;
  height: 100%;
`;
