import { push } from 'react-router-redux';
import { currentClassRoomId } from '../../../core/selectors/classroom';

const selectSection =
  (sectionId, educatorOnly = false) =>
  (dispatch, getState) => {
    const state = getState();
    const classRoomId = currentClassRoomId(state);
    // Duplicated selectSection function for the new route path (within the Journey tab).
    // This needs to be duplicated while the new Information Architecture changes are only
    // done for Desktop.

    const tab = educatorOnly ? 'educatorArea' : 'materials';
    const url =
      sectionId !== null
        ? `/dashboard/${classRoomId}/journey/${tab}/${sectionId}`
        : `/dashboard/${classRoomId}/journey/${tab}`;
    dispatch(push(url));
  };

export default selectSection;
