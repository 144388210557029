import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ublend-npm/aulaui-next';
import CircularProgress from '../../../CircularProgress';
import {
  SuggestionsContainer,
  SuggestionItemContainer,
  CircularProgressContainer,
} from './Suggestions.styled';

const Suggestions = ({
  size,
  isOpen,
  dataset,
  inputValue,
  highlightedIndex,
  getItemProps,
  SuggestionItem,
  isFetching,
  greyedOutUsers,
}) => {
  const filter = inputValue.trim();

  if (!isOpen || filter.length === 0) {
    return null;
  }

  const isDisabled = (id) => {
    return greyedOutUsers.includes(id);
  };

  return (
    <SuggestionsContainer>
      {isFetching ? (
        <CircularProgressContainer>
          <CircularProgress size={20} />
        </CircularProgressContainer>
      ) : (
        dataset.map((suggestion, index) => (
          <SuggestionItemContainer
            key={suggestion.objectId || suggestion.id}
            selected={index === highlightedIndex}
            suggestion={suggestion}
            size={size}
            {...(!isDisabled(suggestion.id)
              ? getItemProps({
                  item: suggestion,
                  style: {
                    backgroundColor:
                      index === highlightedIndex
                        ? theme.color.beige
                        : theme.color.white,
                  },
                })
              : {})}
          >
            {SuggestionItem ? (
              <SuggestionItem suggestion={suggestion} />
            ) : (
              suggestion.label
            )}
          </SuggestionItemContainer>
        ))
      )}
    </SuggestionsContainer>
  );
};

Suggestions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }).isRequired
  ).isRequired,
  inputValue: PropTypes.string.isRequired,
  highlightedIndex: PropTypes.number,
  getItemProps: PropTypes.func.isRequired,
  SuggestionItem: PropTypes.elementType,
  isFetching: PropTypes.bool.isRequired,
  size: PropTypes.string,
  greyedOutUsers: PropTypes.arrayOf(PropTypes.string),
};

Suggestions.defaultProps = {
  SuggestionItem: null,
  highlightedIndex: -1,
  size: 'small',
  greyedOutUsers: [],
};

export default Suggestions;
