import styled from 'react-emotion';
import {
  styleUtils,
  theme,
  Modal,
  IconButton as AUIIconButton,
} from '@ublend-npm/aulaui-next';
import UnstyledMessageTable from '../MessageTable.container';

export const MessageTable = styled(UnstyledMessageTable)`
  position: unset;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

export const WriteZoneContainer = styled('div')`
  flex-shrink: 0;
  min-height: 64px;
  border-top: 1px solid #efefef;
  box-sizing: border-box;
  padding-left: ${theme.spacing.xLarge};
  /* padding-bottom: 25px; */
  width: 100%;
  :focus-within {
    ${styleUtils.focusOutline}
  }
`;

export const HeaderAddonContainer = styled('div')`
  display: flex;
`;

export const AddonContainer = styled('div')`
  flex-shrink: none;
`;

export const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: inherit;
`;

export const ModalStyled = styled(Modal)`
  position: absolute !important;
`;

export const ModalContent = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const ActionContainer = styled('div')`
  flex-shrink: none;
`;

export const IconButton = styled(AUIIconButton)`
  min-width: 40px;
  margin-left: ${theme.spacing.small};
  background-color: ${theme.color.grayLight};
  color: ${theme.color.designSystem.purple70};

  &:hover {
    color: ${theme.color.designSystem.purple70};
  }

  &:active {
    color: ${theme.color.designSystem.purple70};
  }
`;
