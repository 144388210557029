import PropTypes from 'prop-types';
import React from 'react';
import {
  MATERIAL_DIALOG_LEAVE_TITLE,
  MATERIAL_DIALOG_LEAVE_SUBTITLE,
  MATERIAL_DIALOG_LEAVE_CANCEL_LABEL,
  MATERIAL_DIALOG_LEAVE_CONFIRM_LABEL,
} from '../../../../../constants/texts';

import { AlertDialog } from '../../../common/Dialog';

const LeaveWithoutSavingModal = ({ open, onClose, onConfirm }) => (
  <AlertDialog
    id="leave-without-saving"
    title={MATERIAL_DIALOG_LEAVE_TITLE}
    message={MATERIAL_DIALOG_LEAVE_SUBTITLE}
    open={open}
    onClose={onClose}
    action={{
      label: MATERIAL_DIALOG_LEAVE_CONFIRM_LABEL,
      onClick: onConfirm,
      danger: true,
    }}
    secondaryAction={{
      label: MATERIAL_DIALOG_LEAVE_CANCEL_LABEL,
      onClick: onClose,
    }}
  />
);

LeaveWithoutSavingModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

LeaveWithoutSavingModal.defaultProps = {
  open: false,
  onClose: undefined,
  onConfirm: () => null,
};

export default LeaveWithoutSavingModal;
