import styled from 'react-emotion';

import BasePopper from '@material-ui/core/Popper';

export const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Content = styled('div')`
  flex-grow: 1;
  position: relative;
`;

export const Popper = styled(BasePopper)`
  z-index: 1300;
`;
