import React, { useCallback, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { FeedType, Post, SendViewPostAnalytic } from '@app/types/core';
import { ANNOUNCEMENTS } from '@core/constants/postFeedType';
import PostCell from '../PostsTable/PostCell/PostCell';
import PostNavigation from '../PostNavigation/PostNavigation.container';
import SchedulePostDialog from '../SchedulePostDialog';
import style from './style';
import ViewScheduledPostButton from '../ViewScheduledPostButton';

export interface ClassRoomPostProps {
  fetchPost: (_: { postId: string; spaceId: string }) => Promise<void>;
  nextScheduledPostId: string | null;
  post?: Post;
  postId: string;
  feedType: FeedType | undefined;
  sendViewPostAnalytic: SendViewPostAnalytic;
  spaceId?: string;
  spaceName?: string;
}

const updateDocumentTitle = (spaceName?: string) => {
  const space = spaceName ? `${spaceName} - ` : '';
  document.title = `Post - ${space}Aula`;
};

const ClassRoomPost = ({
  fetchPost,
  nextScheduledPostId,
  post,
  postId,
  feedType,
  sendViewPostAnalytic,
  spaceId,
  spaceName,
}: ClassRoomPostProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [postViewEventSent, setPostViewEventSent] = useState(false);
  const isAnnouncementsFeed = feedType === ANNOUNCEMENTS;

  const loadPost = useCallback(async () => {
    setIsLoading(true);

    if (spaceId) {
      await fetchPost({ spaceId, postId });
      setIsLoading(false);
    }
  }, [postId, spaceId, fetchPost]);

  useEffect(() => {
    loadPost();
    updateDocumentTitle(spaceName);
  }, [loadPost, spaceName]);

  useEffect(() => {
    if (!postViewEventSent && postId && post && spaceId) {
      sendViewPostAnalytic({ postId, post, activeClassroom: spaceId });
      setPostViewEventSent(true);
    }
  }, [postViewEventSent, postId, post, spaceId, sendViewPostAnalytic]);

  useEffect(() => {
    // Make sure we send a post view event again if the postId changes
    setPostViewEventSent(false);
  }, [postId]);

  if (isLoading || !post) {
    return <div />;
  }

  const scheduledRowLabel = `Next scheduled ${
    isAnnouncementsFeed ? 'announcement' : 'post'
  }`;

  const scheduledRow =
    nextScheduledPostId === post.objectId ? (
      <div style={style.scheduledRow}>
        <div style={style.nextScheduledPost}>{scheduledRowLabel}</div>
        <ViewScheduledPostButton />
      </div>
    ) : null;

  return (
    <div style={style.container}>
      <Scrollbars autoHide>
        <PostNavigation style={style.navigation} postFeedType={post.feedType} />
        <div style={style.parentWrapper}>
          <div style={style.wrapper}>
            {scheduledRow}
            <PostCell
              key={post.objectId}
              post={post}
              isOpenMenuItemVisible={false}
            />
          </div>
        </div>
      </Scrollbars>
      <SchedulePostDialog />
    </div>
  );
};

export default ClassRoomPost;
