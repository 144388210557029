import React from 'react';
import { func, bool, string } from 'prop-types';
import { RowContainer } from './DiscussionDetailsCard.styled';
import EditDiscussionCard from './EditDiscussionCard';
import AddDiscussionCard from './AddDiscussionCard';

const DiscussionDetailsCard = ({
  openDiscussionTopicModal,
  discussionTopic,
  showDiscussion,
}) => {
  return (
    <RowContainer hasPadding={!discussionTopic}>
      {discussionTopic ? (
        <EditDiscussionCard
          showDiscussion={showDiscussion}
          discussionTopic={discussionTopic}
          openDiscussionTopicModal={openDiscussionTopicModal}
        />
      ) : (
        <AddDiscussionCard
          openDiscussionTopicModal={openDiscussionTopicModal}
        />
      )}
    </RowContainer>
  );
};

DiscussionDetailsCard.propTypes = {
  openDiscussionTopicModal: func.isRequired,
  discussionTopic: string,
  showDiscussion: bool,
};

DiscussionDetailsCard.defaultProps = {
  discussionTopic: '',
  showDiscussion: false,
};

export default DiscussionDetailsCard;
