import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Downshift from 'downshift';

import DropdownItem from './DropdownItem';
import Header from './Header';
import Dropdown from './Dropdown';

import { Container, Content, Popper } from './Select.styled';

const itemToString = (item) => (item ? item.label : '');
const noop = () => {};

const Select = ({
  dataSet,
  selectedKey,
  showKeyInHeader,
  label,
  DropdownItemType,
  HeaderType,
  onChange,
  onDismiss,
  headerFontColor,
  headerIconColor,
  headerFontSize,
  headerBackgroundColor,
  isLoading,
  DropdownStyle,
  DropdownItemStyle,
  headerTextTruncate,
  ariaLabelledby,
}) => {
  const downshift = useRef(null);
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const handleSelect = (item) => {
    if (item && !item.isHeader) {
      onChange(item.key);
    }
  };

  const headerOnClick = (e) => {
    if (!onDismiss) {
      return noop;
    }
    e.stopPropagation();
    onDismiss();
  };

  return (
    <Container>
      <Downshift
        ref={downshift}
        id="select-downshift"
        onSelect={handleSelect}
        itemToString={itemToString}
        labelId={ariaLabelledby}
      >
        {({
          getRootProps,
          getItemProps,
          getMenuProps,
          getToggleButtonProps,
          highlightedIndex,
          isOpen,
        }) => {
          const header =
            showKeyInHeader && selectedKey ? (
              <HeaderType
                expanded={isOpen}
                onClick={noop}
                fontColor={headerFontColor}
                iconColor={headerIconColor}
                backgroundColor={headerBackgroundColor}
                textTruncate={headerTextTruncate}
                {...dataSet.find((item) => item.key === selectedKey)}
              />
            ) : (
              <Header
                expanded={isOpen}
                label={
                  (dataSet.find((item) => item.key === selectedKey) || {})
                    .label || label
                }
                onClick={headerOnClick}
                fontColor={headerFontColor}
                iconColor={headerIconColor}
                fontSize={headerFontSize}
                textTruncate={headerTextTruncate}
                dismiss={!showKeyInHeader && !!selectedKey}
                backgroundColor={headerBackgroundColor}
              />
            );
          const renderDropdown = () => (
            <Popper open={isOpen} placement="bottom" anchorEl={dropdownAnchor}>
              <Dropdown
                dataSet={dataSet}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                highlightedIndex={highlightedIndex}
                suggestionLabel={label}
                isItemSelected={showKeyInHeader && !!selectedKey}
                useAlternativeItemType={!showKeyInHeader}
                selectedItem={selectedKey}
                DropdownItemType={DropdownItemType}
                DropdownStyle={DropdownStyle}
                DropdownItemStyle={DropdownItemStyle}
                isLoading={isLoading}
              />
            </Popper>
          );
          return (
            <Content
              {...getRootProps({
                refKey: 'innerRef',
                role: undefined,
              })}
            >
              <div
                {...getToggleButtonProps()}
                ref={(node) => setDropdownAnchor(node)}
              >
                {header}
              </div>
              {renderDropdown()}
            </Content>
          );
        }}
      </Downshift>
    </Container>
  );
};

Select.propTypes = {
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  selectedKey: PropTypes.string,
  showKeyInHeader: PropTypes.bool,
  label: PropTypes.string.isRequired,
  DropdownItemType: PropTypes.elementType,
  DropdownStyle: PropTypes.shape({
    width: PropTypes.string,
  }),
  DropdownItemStyle: PropTypes.shape({
    height: PropTypes.string,
  }),
  HeaderType: PropTypes.elementType,
  headerFontColor: PropTypes.string,
  headerIconColor: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  headerFontSize: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  headerTextTruncate: PropTypes.bool,
  ariaLabelledby: PropTypes.string,
};

Select.defaultProps = {
  selectedKey: null,
  headerTextTruncate: false,
  DropdownItemType: DropdownItem,
  HeaderType: Header,
  DropdownStyle: {},
  DropdownItemStyle: {},
  onDismiss: undefined,
  showKeyInHeader: true,
  headerFontColor: undefined,
  headerIconColor: undefined,
  headerFontSize: undefined,
  headerBackgroundColor: undefined,
  ariaLabelledby: '',
};

export default Select;
