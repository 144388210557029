import PropTypes from 'prop-types';
import React from 'react';

import InfiniteTable from '../../common/InfiniteTable';
import style, { Title, PinIconStyle } from './style';
import PostCell from '../Feed/PostsTable/PostCell/PostCell';
import PostNavigation from '../Feed/PostNavigation/PostNavigation.container';
import PinIcon from '../Feed/PinIcon/PinIcon';

const PAGE_TITLE = 'Pinned posts';

class ImportantPostsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.updateDocumentTitle = this.updateDocumentTitle.bind(this);
  }

  componentDidMount() {
    const { removeTagFilter, classId } = this.props;
    this.updateDocumentTitle();

    // Remove the tag filter when arriving on this page.
    // This makes sure we load all the pinned posts on this page.
    // TO DO: Solve this problem better by changing the posts state shape to be per search query.
    if (classId) {
      removeTagFilter();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { importantPosts, fetch } = this.props;
    if (
      importantPosts.length === 0 &&
      nextProps.importantPosts.length === 0 &&
      nextProps.classId &&
      !nextProps.isFetching &&
      nextProps.count > 0
    ) {
      fetch(nextProps.classId);
    }
  }

  componentDidUpdate() {
    this.updateDocumentTitle();
  }

  updateDocumentTitle() {
    const { currentSpaceName } = this.props;
    const space = currentSpaceName ? `${currentSpaceName} - ` : '';
    document.title = `Pinned posts - ${space} - Aula`;
  }

  render() {
    const { importantPosts, count, classId, fetch, isFetching, hasFetchedAll } =
      this.props;

    return (
      <div style={style.parentContainer}>
        <InfiniteTable
          items={importantPosts}
          renderItem={(post) => (
            <div style={style.postWrapper}>
              <PostCell
                post={post}
                classId={classId}
                style={{ maxWidth: 624 }}
              />
            </div>
          )}
          keyExtractor={(post) => `important-post-${post.objectId}`}
          hasNext={!hasFetchedAll}
          loadNext={() => fetch(classId)}
          loading={isFetching}
          renderHeader={() => (
            <div style={style.header}>
              <PostNavigation style={style.navigation} />
              <Title>
                <PinIcon style={PinIconStyle} />
                <span>{`${PAGE_TITLE} (${count})`}</span>
              </Title>
            </div>
          )}
          viewStyle={style.postsContainer}
          contentStyle={style.table}
        />
      </div>
    );
  }
}

ImportantPostsList.propTypes = {
  count: PropTypes.number,
  fetch: PropTypes.func.isRequired,
  classId: PropTypes.string.isRequired,
  currentSpaceName: PropTypes.string,
  importantPosts: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool.isRequired,
  hasFetchedAll: PropTypes.bool.isRequired,
  removeTagFilter: PropTypes.func.isRequired,
};

ImportantPostsList.defaultProps = {
  importantPosts: [],
  currentSpaceName: '',
  count: 0,
};

export default ImportantPostsList;
