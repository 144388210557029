import FileDownload from 'js-file-download';
import { fetchGrades } from '../../../core/assignments/api';
import { accessToken as getAccessToken } from '../../../core/selectors/user';
import jsToCsv from '../../../core/utils/jsToCsv';

import { analyticsTrackEvent } from '../../../core/utils/analytics';
import * as analyticEvents from '../../../core/constants/analytics';

const gradesFields = ['firstName', 'lastName', 'email', 'grade', 'externalId'];

const exportSubmissionGrades =
  ({
    assignment: { objectId: assignmentId, title, extensions = [] },
    spaceId,
  }) =>
  async (_, getState) => {
    const state = getState();
    const assignmentIds = [
      assignmentId,
      ...extensions.map(({ id }) => id),
    ].join(',');

    const { data } = await fetchGrades({
      assignmentId: assignmentIds,
      spaceId,
      accessToken: getAccessToken(state, spaceId),
    });

    const studentGrades = data.grades[assignmentId]
      .filter(({ grade }) => !!grade)
      .map((grade) => ({
        ...grade,
        externalId: grade.studentExternalId,
        assignmentExternalId: grade.externalId,
      }));

    analyticsTrackEvent(analyticEvents.EXPORT_ASSIGNMENT_GRADES, {
      assignment: { objectId: assignmentId, title },
      activeClassroom: spaceId,
    });

    FileDownload(
      jsToCsv(gradesFields, studentGrades),
      `${title}.csv`,
      'text/csv;charset=utf-8;'
    );
  };

export default exportSubmissionGrades;
