import React, { useMemo, useState } from 'react';
import styled from 'react-emotion';
import InfoIcon from '@mui/icons-material/Info';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tooltip, Button, icons } from '@ublend-npm/aulaui-next';
import { useDispatch } from 'react-redux';

import { HANDIN_PROVIDER } from '@core/assignments/constants';
import { analyticsTrackEvent } from '../../../../../core/utils/analytics';
import * as ANALYTICS from '../../../../../core/constants/analytics';
import showToast from '../../../../../core/toasts/operations/showToast.operation';

import handinSubmissionsExport from './handinSubmissionsExport';
import { useAssignmentTypeProvider } from './AssignmentTypeProvider';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NarrowContainer = styled('div')`
  width: 15em;
`;

export interface SubmissionsDownloadAllProps {
  currentUserIsEducator: boolean;
  assignmentExternalId: string;
  accessToken: string;
  isGroupAssignment: boolean;
  isAnonymisedAssignment: boolean;
  submissionCount: number;
}

const SubmissionsDownloadAll = (props: SubmissionsDownloadAllProps) => {
  const { currentUserIsEducator, submissionCount } = props;
  const { webAppFtSubmissionsDownloadEnabled202211: isEnabled } = useFlags();
  const { type } = useAssignmentTypeProvider();
  const [isRequested, setIsRequested] = useState(false);
  const dispatch = useDispatch();
  const context = useMemo(() => {
    const errorMessage = 'There are no submissions to download yet';
    const disabled = submissionCount === 0;
    const ariaLabel = disabled
      ? `It is not possible to download all submissions for this assignment: ${errorMessage.toLowerCase()}.`
      : 'Download all submissions for this assignment.';
    const tooltip = disabled ? (
      errorMessage
    ) : (
      <NarrowContainer>
        Only files attached to submissions are downloaded
      </NarrowContainer>
    );

    return { disabled, ariaLabel, tooltip };
  }, [submissionCount]);

  if (!isEnabled) {
    return null;
  }

  if (type !== HANDIN_PROVIDER) {
    return null;
  }

  if (!currentUserIsEducator) {
    return null;
  }

  const requestDownloadAll = async () => {
    try {
      const { accessToken, assignmentExternalId } = props;
      analyticsTrackEvent(ANALYTICS.BULK_DOWNLOAD, {
        assignmentId: assignmentExternalId,
        itemId: assignmentExternalId,
        itemType: ANALYTICS.ASSIGNMENT_ITEM_TYPE,
      });
      console.info('Requesting download of all submissions', {
        assignmentExternalId,
      });
      setIsRequested(true);
      dispatch(
        showToast({
          message:
            "Preparing to download files attached to submissions. Once completed, you'll receive an email with a link to download the ZIP file.",
        })
      );
      await handinSubmissionsExport(accessToken, assignmentExternalId);
    } catch {
      dispatch(
        showToast({
          emoji: '🤔',
          message: 'There was an error while preparing submissions.',
        })
      );
      setIsRequested(false);
    }
  };

  return (
    <Container>
      <Button
        onClick={requestDownloadAll}
        aria-label={context.ariaLabel}
        type="secondary"
        iconLeft={icons.CloudDownloadIcon}
        disabled={isRequested || context.disabled}
      >
        Download Submissions
      </Button>

      <Tooltip title={context.tooltip}>
        <InfoIcon color="action" data-testid="info-icon" />
      </Tooltip>
    </Container>
  );
};

export default SubmissionsDownloadAll;
