import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { cleanState } from '@ublend-npm/aula-editor';
import MaterialListEducatorOnly from './MaterialListEducatorOnly';
import * as appCreators from '../../../../../actions/materials';
import { selectors, operations } from '../../../../../store/materials';
import {
  currentClassRoomId,
  accessingCurrentSpaceAsStaff,
} from '../../../../../../core/selectors/classroom';

const selectPieces = createStructuredSelector({
  classRoomId: currentClassRoomId,
  materialEducatorOnlyIds: selectors.topLevelMaterialEducatorOnlyIds,
  isFetchingSections: selectors.isFetchingSections,
  staffAccess: accessingCurrentSpaceAsStaff,
});

const mapStateToProps = createSelector(
  selectPieces,
  ({
    classRoomId,
    materialEducatorOnlyIds,
    isFetchingSections,
    staffAccess,
  }) => ({
    classRoomId,
    isFetchingSections,
    materialEducatorOnlyIds,
    isReadOnlyUser: staffAccess,
  })
);

const mapDispatchToProps = (dispatch) => ({
  onAddClick: () =>
    dispatch(
      operations.createSection({
        content: cleanState,
        educatorOnly: true,
        hidden: false,
        onSelect: appCreators.selectSectionInJourney,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialListEducatorOnly);
