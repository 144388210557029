import { COMMENT } from '../../constants/endpoints';
import request from '../request';
import formatUser from '../posts/formatUser';

const edit = async ({ commentId, content }) => {
  const {
    data: { comment },
  } = await request({
    method: 'put',
    url: COMMENT(commentId),
    data: { content },
  });

  return {
    ...comment,
    user: formatUser(comment.user),
  };
};

export default edit;
