import { connect } from 'react-redux';
import { fetchImportantPosts } from '../../../../../core/actions/classRoom/importantPosts';
import filterByTag from '../../../../../core/actions/classRoom/filter';
import ImportantPostsList from './ImportantPostsList';
import {
  classRoomName,
  currentClassRoom,
  importantPostsSelector,
} from '../../../../../core/selectors/classroom';

const mapStateToProps = (state) => ({
  importantPosts: importantPostsSelector(state),
  open: state.ui.importantPostsOpen,
  classId: state.classRoom.current,
  currentSpaceName: classRoomName(state),
  count: currentClassRoom(state) && currentClassRoom(state).importantPostCount,
  isFetching: state.classRoom.isFetchingImportantPosts,
  hasFetchedAll: state.classRoom.hasFetchedAllImportantPosts,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (classId) => dispatch(fetchImportantPosts({ spaceId: classId })),
  removeTagFilter: () => {
    dispatch(filterByTag(undefined));
  },
});

const ImportantPostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportantPostsList);

export default ImportantPostsContainer;
