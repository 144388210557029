import { FETCH_COMMENTS } from '../../constants/endpoints';
import request from '../request';

const fetch = async ({ entityType, entityId, until }) => {
  const {
    data: { comments, reactions },
  } = await request({
    method: 'get',
    url: FETCH_COMMENTS({ entityType, entityId }),
    params: { until },
  });

  return { comments, reactions };
};

export default fetch;
