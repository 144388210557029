import { handleEmptyString } from '@app/hooks/useYupForm/useYupForm';
import { LtiAssignmentCreationParameters } from '@ublend-npm/aula-schema';
import { object, string, number } from 'yup';

// aula-schema does not export an enum when there is only one possible value.
// if more types are available in the future, consider using the enum that is
// going to be created by aula-schema rather than this const.
export const dropdownType = 'dropdown';

export const lineItemSchemaBase = {
  title: string().label('Title').required(),
  description: string().label('Description'),
  openDate: string().label('Open date').datetime().required(),
  dueDate: string().label('Due date').datetime().required(),
  gradesReleaseDate: string().label('Grades release date').datetime(),
  maxGrade: number()
    .transform(handleEmptyString)
    .label('Maximum grade')
    .required(),
};

export const getLineItemSchema = (
  params: LtiAssignmentCreationParameters = []
) => {
  const dropdownSchemas = params
    .filter((p) => p.type === dropdownType)
    .reduce((acc, cur) => {
      return {
        ...acc,
        [cur.key]: string()
          .label(cur.label)
          .oneOf(cur.options.map((o) => o.value))
          .required(),
      };
    }, {});

  return object({
    ...lineItemSchemaBase,
    ...dropdownSchemas,
  });
};
