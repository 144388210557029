import FileDownload from 'js-file-download';
import { classRoomName } from '../../../core/selectors/classroom';
import { exporterPage } from '../../../core/materials/importExport';

const exportMaterialPage = () => (_, getState) => {
  const state = getState();
  const materialJSON = JSON.stringify(exporterPage(state));
  const fileName = `PAGE_${classRoomName(
    state
  )}_class_material_${Date.now()}.json`;
  const mimeType = 'text/plain';
  FileDownload(materialJSON, fileName, mimeType);
};

export default exportMaterialPage;
