import styled from 'react-emotion';

import UnstyledAddIcon from '@material-ui/icons/Add';

export const AddIcon = styled(UnstyledAddIcon)`
  font-size: 30px;
`;

export const Container = styled('div')`
  margin-left: 8px;
`;
