export default {
  percentage: {
    value: 'percentage',
    displayValue: 'Percentages',
  },
  points: {
    value: 'points',
    displayValue: 'Points',
  },
};
