import { FeedType } from '@app/types/core';
import { ANNOUNCEMENTS } from '@core/constants/postFeedType';
import React, { memo } from 'react';
import TextButton from '../../../common/Buttons/TextButton';

interface ViewScheduledPostButtonProps {
  count: number;
  openScheduledPosts: (feedType: FeedType) => void;
  feedType: FeedType | undefined;
}

const ViewScheduledPostButton = ({
  count,
  feedType,
  openScheduledPosts,
}: ViewScheduledPostButtonProps) => {
  const isAnnouncementsFeed = feedType === ANNOUNCEMENTS;
  const labelText = `View ${count} scheduled ${
    isAnnouncementsFeed ? 'announcement' : 'post'
  }${count > 1 ? 's' : ''}`;
  if (!feedType) {
    return null;
  }

  return (
    <TextButton
      label={labelText}
      onClick={() => openScheduledPosts(feedType)}
    />
  );
};

export default memo(ViewScheduledPostButton);
