/* eslint-disable react/prop-types */
import React from 'react';
import TimeAgo from 'react-timeago';

const timeFormatter = (val, unit, suffix) =>
  unit === 'second'
    ? 'Just now'
    : `${val} ${unit}${val > 1 ? 's' : ''} ${suffix}`;

export default React.memo(({ date }) => (
  <TimeAgo
    date={date}
    minPeriod={60}
    formatter={timeFormatter}
    title={new Date(date).toLocaleString('en-GB')}
  />
));
