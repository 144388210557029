import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button } from '@ublend-npm/aulaui-next';
import showToast from '@core/toasts/operations/showToast.operation';
import { Assignment } from '@core/types/state';
import { LTI1P3_PROVIDER } from '@core/assignments/constants';
import { Lti1p3Launch } from '../Assignments/Lti1p3Launch/Lti1p3Launch';
import { useAssignmentTypeProvider } from './AssignmentTypeProvider';
import { ProviderContainer, ProviderTitle } from './Provider.styled';

type ProviderProps = {
  assignment: Assignment;
};

const Provider = ({ assignment }: ProviderProps) => {
  const assignmentProvider = useAssignmentTypeProvider();

  const { launchAssignment, title, renderImage } = assignmentProvider;
  const [lti1p3Launch, setLti1p3Launch] = useState(false);
  const dispatch = useDispatch();

  const ltiLaunchSuccess = () => {
    setLti1p3Launch(false);
  };

  const ltiLaunchError = () => {
    setLti1p3Launch(false);
    dispatch(
      showToast({
        message: 'There was an error when trying to open the LTI tool.',
      })
    );
  };

  const launchAssignmentHandler = () => {
    if (assignment.provider === LTI1P3_PROVIDER) {
      setLti1p3Launch(true);
    } else {
      launchAssignment();
    }
  };

  const { providerId, resourceLinkId, id } = assignment;

  return (
    <ProviderContainer>
      {renderImage({})}
      <Button type="text" onClick={launchAssignmentHandler}>
        <ProviderTitle>
          Open in {title}
          <LaunchIcon fontSize="small" />
        </ProviderTitle>
        {lti1p3Launch && providerId && resourceLinkId ? (
          <Lti1p3Launch
            assignmentId={id}
            providerId={providerId}
            resourceLinkId={resourceLinkId}
            resourceLinkTitle={assignment.title}
            onSuccessLaunch={ltiLaunchSuccess}
            onErrorLaunch={ltiLaunchError}
          />
        ) : null}
      </Button>
    </ProviderContainer>
  );
};

export default Provider;
