import { theme } from '@ublend-npm/aulaui-next';
import fonts from '../../../styles/fonts';

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    flexShrink: 2,
  },
  userImage: {
    flexShrink: 0,
  },
  userNameContainer: {
    marginTop: -4,
    marginLeft: 15,
    height: '100%',
    flexWrap: 'nowrap',
    flexShrink: 2,
  },
  instructor: {
    color: theme.color.pink,
    paddingLeft: 15,
    fontSize: 14,
  },
  userName: {
    ...fonts.regular,
    fontSize: 14,
  },
  time: {
    ...fonts.light,
    fontSize: 14,
    color: theme.color.grey11,
    letterSpacing: '1px',
    paddingTop: '1px',
  },
  edited: {
    marginLeft: 12,
  },
};
