import React, { useState } from 'react';
import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import { Section } from '../types';

import SectionContent from './SectionContent';
import SectionTitle from './SectionTitle';
import LastEdited from './LastEdited';
import {
  Container,
  Separator,
  TitleContainer,
} from './ReadOnlySectionView.styled';
import ScrollBar from '../../../common/ScrollBar';
import {
  ScrollContainer,
  SectionViewContainer,
  ContentContainer,
} from '../Material.styled';
import ReadonlyActionBar from './ReadonlyActionBar';

const ReadOnlySectionView = ({
  section,
  selectedContent,
  onGetSection,
  isLoadingContent,
  isComplete,
  isCompleteLoading,
  submitProgress,
  isStudent,
}) => {
  const [isDividerVisible, setIsDividerVisible] = useState(false);
  const { md } = useBreakpoints();

  const isStudentDividerVisible = isStudent && isDividerVisible;
  const StudentReadonlyActionBar = isStudent ? (
    <ReadonlyActionBar
      sectionId={section.id}
      isComplete={isComplete}
      isCompleteLoading={isCompleteLoading}
      submitProgress={submitProgress}
    />
  ) : null;

  const content = (
    <ContentContainer>
      <SectionViewContainer>
        <TitleContainer>
          <SectionTitle value={section.title} />
          <Separator height="16px" />
          <LastEdited
            editedAt={section.updatedAt}
            createdAt={section.createdAt}
          />
        </TitleContainer>
        <SectionContent
          sectionId={section.id}
          content={selectedContent}
          discussionTopic={section.discussionTopic}
          showDiscussion={section.showDiscussion}
          onGetSection={onGetSection}
          isLoadingContent={isLoadingContent}
        />
      </SectionViewContainer>
    </ContentContainer>
  );

  const onScroll = ({ scrollTop }) => {
    setIsDividerVisible(scrollTop > 0);
  };

  if (md) {
    return (
      <Container>
        {StudentReadonlyActionBar}
        {isStudentDividerVisible && <Divider />}
        <ScrollContainer>
          <ScrollBar autoHide onScrollFrame={onScroll}>
            {content}
          </ScrollBar>
        </ScrollContainer>
      </Container>
    );
  }

  return (
    <Container>
      <>
        {StudentReadonlyActionBar}
        {content}
      </>
    </Container>
  );
};

ReadOnlySectionView.propTypes = {
  section: Section.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedContent: PropTypes.object,
  isLoadingContent: PropTypes.bool.isRequired,
  onGetSection: PropTypes.func.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isCompleteLoading: PropTypes.bool.isRequired,
  isStudent: PropTypes.bool.isRequired,
  submitProgress: PropTypes.func.isRequired,
};

ReadOnlySectionView.defaultProps = {
  selectedContent: null,
};

export default ReadOnlySectionView;
