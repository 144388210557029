import styled from 'react-emotion';
import { theme, ListItem } from '@ublend-npm/aulaui-next';
import Add from '@mui/icons-material/Add';

export const AddSectionListItem = styled(ListItem)`
  color: ${theme.color.designSystem.purple70};
  font-size: ${theme.font.size.copy};
  .addNewPageLabel {
    font-weight: ${theme.font.weight.regular};
  }
`;

export const AddIcon = styled(Add)`
  font-size: ${theme.font.size.xLarge};
  font-weight: ${theme.font.weight.semiBold};
`;
