import { connect } from 'react-redux';
import DeleteFolderDialog from './DeleteFolderDialog';
import { selectors, operations } from '../../../../../store/materials';
import { selectSectionInJourney } from '../../../../../actions/materials';

const mapStateToProps = (state) => {
  const folderId = selectors.deleteFolderDialogOpenFor(state);
  const folder = folderId && selectors.getMaterial(state, folderId);
  return {
    folderId,
    folderTitle: folder ? folder.title : 'Section',
    open: !!folderId,
    deleting: selectors.isDeletingFolder(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(operations.closeDeleteFolder()),
  onConfirm: (folderId, educatorOnly) => () =>
    dispatch(
      operations.deleteFolder(folderId, selectSectionInJourney, !!educatorOnly)
    ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  onConfirm: dispatchProps.onConfirm(
    stateProps.folderId,
    ownProps.educatorOnly
  ),
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DeleteFolderDialog);
