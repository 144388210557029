import React, { MouseEvent, createRef } from 'react';

import {
  RadioLabel,
  RadioButton,
  RestoreMaterialOptionContainer,
} from './RestoreMaterialOption.styled';

type RestoreMaterialOptionProps = {
  onClick: any;
  optionKey: string;
  value: string;
  isPressed: boolean;
};

const RestoreMaterialOption = (props: RestoreMaterialOptionProps) => {
  const { onClick, optionKey, value, isPressed } = props;

  const handleClick = () => {
    onClick(optionKey);
  };

  const inputRef = createRef<HTMLInputElement>();

  const handleMouseDown = (e: MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current?.focus();
  };

  if (!value) return null;

  return (
    <RestoreMaterialOptionContainer data-testid="restore-material-item">
      <RadioButton
        type="radio"
        id={optionKey}
        name="restore-materials-list"
        data-testid={`restore-material-${optionKey}`}
        value={value}
        checked={isPressed}
        isChecked={isPressed}
        onChange={handleClick}
        innerRef={inputRef}
      />
      <RadioLabel
        id={value}
        htmlFor={optionKey}
        onMouseDown={handleMouseDown}
        onMouseUp={handleClick}
      >
        {value}
      </RadioLabel>
    </RestoreMaterialOptionContainer>
  );
};

export default RestoreMaterialOption;
