const getGroupSubmissionsObj = (submissions) =>
  submissions.reduce((acc, submission) => {
    const { group, receivedAt = '', displayGrade: userGrade = '' } = submission;

    if (!acc[group.id]) {
      acc[group.id] = {
        name: group.name,
        receivedAt,
        displayGrade: userGrade,
        submissions: [submission],
        ...(submission.id && { id: submission.id }),
      };
    } else {
      const { receivedAt: dateReceived, displayGrade: groupGrade } =
        acc[group.id];
      acc[group.id] = {
        ...acc[group.id],
        receivedAt: dateReceived || receivedAt,
        displayGrade: groupGrade !== userGrade ? '(Mixed)' : userGrade,
        submissions: [...acc[group.id].submissions, submission],
      };
    }

    return acc;
  }, {});

const formatGroupSubmissions = (submissions) => {
  const submissionsObj = getGroupSubmissionsObj(submissions);
  return Object.keys(submissionsObj).map((group) => ({
    ...submissionsObj[group],
  }));
};

export default formatGroupSubmissions;
