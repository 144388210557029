import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ublend-npm/aulaui-next';
import { Container } from './CreateButton.styled';

const CreateButton = ({ onShowCreateAssignment }) => (
  <Container>
    <Button
      onClick={onShowCreateAssignment}
      data-testid="new-assignment-button"
      size="small"
      type="primary"
    >
      New assignment
    </Button>
  </Container>
);

CreateButton.propTypes = {
  onShowCreateAssignment: PropTypes.func.isRequired,
};

export default CreateButton;
