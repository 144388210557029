import { styleUtils } from '@ublend-npm/aulaui-next';
import styled from 'react-emotion';

export const EditorContainer = styled('div')`
  box-sizing: border-box;
  position: relative;
  padding-top: 20px;
  height: 100%;
  :focus-within {
    ${styleUtils.focusOutline}
  }
`;

export const Center = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
