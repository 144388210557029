import moment from 'moment';

const TimeFormat = 'HH:mm';
const DateFormat = 'MMM DD';
const YearFormat = 'YYYY';

const formatDate = (date, options = {}) => {
  const includeTime =
    typeof options.includeTime === 'undefined' ? true : options.includeTime;
  const now = moment(new Date());
  const today = now.clone().startOf('day');
  const yesterday = now.clone().subtract(1, 'days').startOf('day');
  const thisYear = now.clone().startOf('year');
  const momentDate = moment(date);

  const isToday = momentDate.isSame(today, 'd');
  const isYesterday = momentDate.isSame(yesterday, 'd');
  const isThisYear = momentDate.isSame(thisYear, 'y');

  if (isToday) {
    return includeTime ? momentDate.format(`[Today], ${TimeFormat}`) : 'Today';
  }
  if (isYesterday) {
    return includeTime
      ? momentDate.format(`[Yesterday], ${TimeFormat}`)
      : 'Yesterday';
  }
  if (isThisYear) {
    const format = includeTime ? `${DateFormat}, ${TimeFormat}` : DateFormat;
    return momentDate.format(format);
  }

  const format = includeTime
    ? `${DateFormat} ${YearFormat}, ${TimeFormat}`
    : `${DateFormat} ${YearFormat}`;
  return momentDate.format(format);
};

export default formatDate;
