import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  font-family: ${theme.font.family.workSans};
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.regular};
  line-height: ${theme.font.size.copy};
  color: ${theme.color.grey11};
  padding-bottom: 15px;
`;
