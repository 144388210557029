/* eslint-disable indent */
import { combineReducers } from 'redux';

import * as types from '../../constants/actions';
import * as classRoomReducers from './classRoom/classRoom';
import * as classRoomPostReducers from './classRoom/classRoomPosts';
import * as classRoomParticipantsReducers from './classRoom/classRoomParticipants';
import * as classRoomStarsReducer from './classRoom/classRoomStars';

import * as postReducers from './posts';
import * as importantPostsReducer from './importantPosts';
import {
  fetchScheduledAnnouncementsReducer,
  fetchScheduledCommonRoomReducer,
} from './space/scheduledPosts';
import * as classRoomUserReducers from './classRoomUser';
import * as userReducers from './user';
import * as commentReducers from './commentGeneric';
import * as postCommentReducers from './comment';
import * as notificationReducers from './notifications';
import * as tagReducers from './tags';
import * as devicesReducer from './devices';
import * as usersReducer from './users';

import { Entities } from '../../utils/commentsEntityTypes';
import {
  ERROR_STATUS,
  STARTED_STATUS,
  SUCCESS_STATUS,
} from '../../constants/actionsStatus';

const initialState = {
  hasInitialData: false,
  isFetchingInitialData: false,
  isFetchingData: false,
  isFetchingNotifications: false,
  isUpdatingPost: false,
  postIdsUpdating: [],
  assignments: {
    assignments: [],
    submissions: {},
  },
  classRooms: {},
  classRoomUsers: {},
  [Entities.post]: {},
  files: {},
  comments: {},
  notifications: [],
  notificationsError: false,
  liveNotifications: {
    settings: {
      push: {
        messages: false,
        posts: false,
        comments: false,
      },
      email: {
        messages: false,
        posts: false,
        comments: false,
      },
    },
  },
  reactions: {},
  inboxv2: {
    conversations: {},
    messages: {},
    participants: {},
    drafts: {},
  },
  groups: {
    sets: {},
    groups: {},
    searchedSets: [],
  },
  tags: {},
  selectedTag: {},
  [Entities.material]: {},
  devices: {},
  users: {},
  hasAddParticipantError: false,
  isAddingParticipantToSpace: false,
};

const startupReducer = (state = initialState, action) => {
  switch (action.status) {
    case STARTED_STATUS:
      return {
        ...state,
        hasInitialData: false,
        isFetchingInitialData: true,
      };
    case SUCCESS_STATUS:
      return {
        ...state,
        hasInitialData: true,
        isFetchingInitialData: false,
      };
    case ERROR_STATUS:
      return {
        ...state,
        hasInitialData: false,
        isFetchingInitialData: false,
      };

    default:
      return state;
  }
};

export const DataReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.STARTUP_FETCH_DATA:
      return startupReducer(state, action);
    case types.SPACE_FETCH_COMMON_ROOM:
      return postReducers.fetchCommonRoomReducer(state, action);
    case types.SPACE_FETCH_ANNOUNCEMENTS:
      return postReducers.fetchAnnouncementsReducer(state, action);
    case types.FETCH_IMPORTANT_POSTS:
      return importantPostsReducer.fetchClassRoomImportantPostsReducer(
        state,
        action
      );
    case types.SET_POST_IMPORTANT:
      return importantPostsReducer.setPostImportantReducer(state, action);
    case types.UNSET_POST_IMPORTANT:
      return importantPostsReducer.unsetPostImportantReducer(state, action);
    case types.FETCH_SCHEDULED_COMMON_ROOM:
      return fetchScheduledCommonRoomReducer(state, action);
    case types.FETCH_SCHEDULED_ANNOUNCEMENT:
      return fetchScheduledAnnouncementsReducer(state, action);
    case types.CLASSROOM_FETCH_POST:
      return {
        ...state,
        posts: {
          ...postReducers.fetchSpacePostReducer(state, action).posts,
        },
        classRooms: {
          ...classRoomPostReducers.fetchClassRoomPostReducer(state, action)
            .classRooms,
        },
      };
    case types.CLASSROOM_CREATE_POST:
      return postReducers.createPostReducer(state, action);
    case types.CLASSROOM_EDIT_POST:
      return postReducers.editPostReducer(state, action);
    case types.CLASSROOM_DELETE_POST:
      return postReducers.deletePostReducer(state, action);
    case types.CLASSROOM_FETCH_JOINED:
      return {
        ...state,
        classRooms: {
          ...classRoomReducers.fetchClassRoomsReducer(state, action).classRooms,
        },
        classRoomUsers: {
          ...classRoomUserReducers.fetchClassRoomsReducer(state, action)
            .classRoomUsers,
        },
      };
    case types.CLASSROOM_FETCH_PARTICIPANTS:
      return {
        ...state,
        classRooms: {
          ...classRoomParticipantsReducers.fetchClassRoomParticipantsReducer(
            state,
            action
          ).classRooms,
        },
      };
    case types.CLASSROOM_FETCH_PARTICIPANTS_HISTORY:
      return {
        ...state,
        classRooms: {
          ...classRoomParticipantsReducers.fetchClassRoomParticipantsHistoryReducer(
            state,
            action
          ).classRooms,
        },
      };
    case types.CLASSROOM_REMOVE_PARTICIPANT:
      return classRoomParticipantsReducers.removeClassRoomParticipantReducer(
        state,
        action
      );
    case types.CLASSROOM_ADD_PARTICIPANTS:
      return classRoomParticipantsReducers.addParticipantsToSpaceReducer(
        state,
        action
      );

    case types.CLASSROOM_PROMOTE_PARTICIPANT:
      return {
        ...state,
        classRoomUsers: {
          ...classRoomUserReducers.promoteClassRoomParticipantReducer(
            state,
            action
          ).classRoomUsers,
        },
      };
    case types.CLASSROOM_UNPROMOTE_PARTICIPANT:
      return {
        ...state,
        classRoomUsers:
          classRoomUserReducers.unpromoteClassRoomParticipantReducer(
            state,
            action
          ).classRoomUsers,
      };
    case types.CLASSROOM_CHANGE_AVATAR:
      return classRoomReducers.changeClassroomAvatarReducer(state, action);
    case types.GENERIC_COMMENTS_FETCH:
      return commentReducers.fetchCommentsReducer(state, action);
    case types.COMMENTS_CREATE:
      return commentReducers.createCommentReducer(state, action);
    case types.COMMENTS_FETCH:
      return postCommentReducers.fetchCommentsReducer(state, action);
    case types.COMMENTS_EDIT:
      return commentReducers.editCommentReducer(state, action);
    case types.COMMENTS_DELETE:
      return commentReducers.deleteCommentReducer(state, action);
    case types.FETCH_NOTIFICATIONS:
      return {
        ...state,
        ...notificationReducers.fetchNotificationsReducer(state, action),
        isFetchingNotifications: action.status === STARTED_STATUS,
      };
    case types.CLEAR_NOTIFICATIONS_ERROR:
      return { ...state, notificationsError: false };
    case types.NOTIFICATIONS_SET_READ:
      return {
        ...state,
        notifications: notificationReducers.setReadNotificationReducer(
          state,
          action
        ).notifications,
        notificationsError: notificationReducers.setReadNotificationReducer(
          state,
          action
        ).notificationsError,
      };
    case types.NOTIFICATION_SET_READ_TYPE:
      return {
        ...state,
        notifications: notificationReducers.setReadNotificationTypeReducer(
          state,
          action
        ).notifications,
      };
    case types.TAG_FILTER_CLEARED: {
      return {
        ...state,
        selectedTag: {},
      };
    }
    case types.FETCH_TAGS: {
      return tagReducers.fetchTagsReducer(state, action);
    }
    case types.FETCH_TAGS_POST: {
      return tagReducers.fetchTagPostsReducer(state, action);
    }
    case types.CREATE_TAG: {
      return {
        ...state,
        tags: tagReducers.createTagReducer(state, action).tags,
      };
    }
    case types.CREATE_TAG_POST: {
      return tagReducers.createTagPostReducer(state, action);
    }
    case types.DELETE_TAG_POST: {
      return tagReducers.deleteTagPostReducer(state, action);
    }
    case types.USER_FETCH_DEVICES: {
      return devicesReducer.fetch(state, action);
    }
    case types.USER_LOGOUT: {
      return {
        ...initialState,
      };
    }
    case types.USER_LOGIN:
      return userReducers.userLoginReducer(state, action);
    case types.FETCH_USERS:
      return {
        ...state,
        users: usersReducer.fetchUsersReducer(state.users, action),
      };
    case types.SPACE_SELECTOR_TOGGLE_STAR:
      return classRoomStarsReducer.toggleStarredSpace(state, action);

    default:
      return state;
  }
};

const constantOrInitial = (initial) => (v) =>
  typeof v === 'undefined' ? initial : v;

const set = (o, key, value) => {
  // eslint-disable-next-line no-param-reassign
  o[key] = value;
  return o;
};
const fromEntries = (o, [key, value]) => set(o, key, value);

/* This is necessary while we convert all existing modules to ducks and get
 * rid of `DataReducer` because `combineReducer` will ignore the state pieces
 * it's not aware of
 * Eg. if there's only an `inboxv2` duck then all keys except inboxv2 would get
 * ignored by combineReducers */
const combineDucksDataReducers = (ducksDataReducers, initial) =>
  combineReducers(
    Object.entries(initial)
      .map(([key, initialStatePiece]) => [
        key,
        typeof ducksDataReducers[key] !== 'undefined'
          ? ducksDataReducers[key]
          : constantOrInitial(initialStatePiece),
      ])
      .reduce(fromEntries, {})
  );

export default (ducksDataReducers) => {
  const duckDataReducer = combineDucksDataReducers(
    ducksDataReducers,
    initialState
  );

  return (state = initialState, action) =>
    duckDataReducer(DataReducer(state, action), action);
};
