import React, { useCallback, useState } from 'react';

import { isScheduledPost } from '@core/utils/posts';
import PostUserBanner from '@components/presentational/UserBanner/PostUserBanner/PostUserBanner.container';
import EditorReadOnly from '@components/presentational/common/EditorPlus/EditorReadOnly.container';
import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import { Post } from '@app/types/core';

import PostTags from './PostTags/PostTags';
import PostMeta from './PostMeta/PostMeta';

import {
  PostCellContainer,
  PostCellWrapper,
  PostCellSeparator,
  PostCellEditedWrapper,
  ContentWrapper,
  PostCellPreviewWrapper,
} from './PostCell.styled';

type PostCellProps = {
  post: Post;
  isRecentImportant?: boolean;
  isOpenMenuItemVisible?: boolean;
  style?: any;
};

const PostCell = ({
  post,
  isOpenMenuItemVisible,
  isRecentImportant,
  style,
}: PostCellProps) => {
  const [showTags, setShowTags] = useState(!!post.tagPosts.length);

  const isCommentable = post.feedType !== ANNOUNCEMENTS;

  const isScheduled = isScheduledPost(post);
  const content = post.content ? JSON.parse(post.content) : undefined;

  const testId = post.important
    ? `post-element-${isRecentImportant ? 'important-recent' : 'important'}`
    : 'post-element';

  const addTag = useCallback(() => setShowTags(true), [setShowTags]);

  return content ? (
    <PostCellContainer style={style}>
      <PostCellWrapper
        isRecentImportant={isRecentImportant}
        data-testid={testId}
      >
        <PostUserBanner
          post={post}
          addTag={addTag}
          isOpenMenuItemVisible={isOpenMenuItemVisible}
        />
        {showTags || !!post.tagPosts.length ? (
          <PostTags post={post} />
        ) : (
          <PostCellSeparator />
        )}
        <PostCellPreviewWrapper>
          <ContentWrapper>
            <div>
              <EditorReadOnly
                key={post.editedAt || post.createdAt}
                itemId={post.objectId}
                rawEditorState={content}
                itemType={post.feedType || COMMON_ROOM}
              />
            </div>
          </ContentWrapper>
          {post.editedAt ? (
            <PostCellEditedWrapper>(Edited)</PostCellEditedWrapper>
          ) : null}
        </PostCellPreviewWrapper>
        {!isScheduled && (
          <PostMeta postId={post.objectId} showComments={isCommentable} />
        )}
      </PostCellWrapper>
    </PostCellContainer>
  ) : null;
};

PostCell.defaultProps = {
  isRecentImportant: false,
  style: {},
};

export default PostCell;
