import React from 'react';
import { useSelector } from 'react-redux';

import { accessingCurrentSpaceAsStaff } from '@core/selectors/classroom';
import useGetComments from '@core/comments/hooks/useGetComments';
import CommentsEntityTypes from '@core/utils/commentsEntityTypes';
import { MATERIAL_ITEM_TYPE } from '@core/constants/analytics';
import { AulaState } from '@core/types/state';
import CommentsFeed from '../../../Comments/CommentsFeed/CommentsFeed';
import {
  Cell,
  CommentsContainer,
  Container,
  Title,
} from './DiscussionArea.styled';
import EditDiscussion from './EditDiscussion/EditDiscussion';

type DiscussionAreaProps = {
  entityId: string;
  discussionTopic?: string;
  editable?: boolean;
  showDiscussion?: boolean;
  onDiscussionSave?: Function;
};

const DiscussionArea = ({
  entityId,
  discussionTopic,
  editable,
  showDiscussion,
  onDiscussionSave,
}: DiscussionAreaProps) => {
  const isReadOnlyUser = useSelector((state: AulaState): boolean =>
    accessingCurrentSpaceAsStaff(state)
  );

  const { isSuccess, data } = useGetComments(
    CommentsEntityTypes.material,
    entityId,
    null,
    editable
  );

  const comments = data?.commentsIds || [];
  const commentsCount = comments.length;

  const showDiscussionArea = !!showDiscussion || !!editable;
  const readOnly = isReadOnlyUser || (!showDiscussion && !!editable);

  return showDiscussionArea ? (
    <Container>
      <Cell hasPadding={!!(discussionTopic || commentsCount)}>
        {editable ? (
          <EditDiscussion
            title={discussionTopic}
            showDiscussion={showDiscussion}
            hasComments={!!commentsCount}
            onDiscussionSave={onDiscussionSave}
          />
        ) : (
          <Title>{discussionTopic || 'Discussion'}</Title>
        )}
        {!editable && (
          <CommentsContainer data-testid="discussion-area-comments">
            {isSuccess && data?.isDispatched && (
              <CommentsFeed
                entityId={entityId}
                entityType={CommentsEntityTypes.material}
                itemType={MATERIAL_ITEM_TYPE}
                readOnly={readOnly}
                comments={comments}
                showInitialComments
              />
            )}
          </CommentsContainer>
        )}
      </Cell>
    </Container>
  ) : null;
};

DiscussionArea.defaultProps = {
  discussionTopic: undefined,
  editable: false,
  showDiscussion: false,
  onDiscussionSave: () => {},
};

export default DiscussionArea;
