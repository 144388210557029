import React from 'react';
import { func, string, bool } from 'prop-types';
import { Tooltip, IconButton } from '@ublend-npm/aulaui-next';
import EditIcon from '@mui/icons-material/Edit';
import { DISCUSSION_TOPIC_EDIT } from '../../../../../../../constants/texts';
import {
  Title,
  TitleContainer,
  IconButtonContainer,
  DiscussionDetailsContainer,
  VisibilityOffIcon,
} from './DiscussionDetailsCard.styled';

const EditDiscussionCard = ({
  showDiscussion,
  discussionTopic,
  openDiscussionTopicModal,
}) => (
  <>
    <TitleContainer>
      {!showDiscussion && (
        <DiscussionDetailsContainer>
          <VisibilityOffIcon />
          Hidden from students
        </DiscussionDetailsContainer>
      )}
      <Title isMediumGrey={!showDiscussion}>{discussionTopic}</Title>
    </TitleContainer>
    <Tooltip title={DISCUSSION_TOPIC_EDIT} position="top">
      <IconButtonContainer>
        <IconButton
          type="secondary"
          onClick={openDiscussionTopicModal}
          label="edit"
          size="small"
          data-testid="edit-discussion-button"
          icon={EditIcon}
        />
      </IconButtonContainer>
    </Tooltip>
  </>
);

EditDiscussionCard.propTypes = {
  openDiscussionTopicModal: func.isRequired,
  discussionTopic: string,
  showDiscussion: bool,
};

EditDiscussionCard.defaultProps = {
  discussionTopic: '',
  showDiscussion: false,
};

export default EditDiscussionCard;
