import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Wrapper = styled.div`
  width: 100%;

  &:hover {
    background: transparent;
  }
`;

export const GroupSet = styled.span`
  color: ${theme.color.blackAlpha(0.6)};
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.small};
  line-height: ${theme.font.lineHeight.copy};
  padding: ${theme.spacing.small} ${theme.spacing.medium};
  margin-bottom: ${theme.spacing.xxSmall};

  &:hover {
    background: transparent;
  }
`;

export const GroupLabel = styled.span`
  padding-left: 12px;
`;

export const GroupItem = styled.span`
  color: ${theme.color.blackAlpha(0.87)};
  display: flex;
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
  justify-content: space-between;
  line-height: ${theme.font.lineHeight.copy};
  padding: ${theme.spacing.small} ${theme.spacing.medium};
  ${(props) =>
    props.isSelected && `background: ${theme.color.blackAlpha(0.04)};`}

  label {
    color: ${theme.color.blackAlpha(0.6)};
    margin-left: 0;
  }

  span {
    color: ${theme.color.blackAlpha(0.6)};
  }

  &:hover {
    background: ${theme.color.blackAlpha(0.04)};
  }
`;

export const ManageSettings = styled.div`
  border-top: 1px solid ${theme.color.grey3};
  color: ${theme.color.blackAlpha(0.87)};
  display: flex;
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.font.size.copy};
  line-height: ${theme.font.lineHeight.large};
  padding: ${theme.spacing.small} ${theme.spacing.medium};
  ${(props) =>
    props.isSelected && `background: ${theme.color.blackAlpha(0.04)};`}
  width: 100%;

  span {
    padding-left: 14px;
  }

  &:hover {
    background: ${theme.color.blackAlpha(0.04)};
  }
`;

export const ListActions = styled.div`
  border-top: 1px solid ${theme.color.grey3};
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing.xSmall} ${theme.spacing.medium};
  ${(props) =>
    props.isSelected && `background: ${theme.color.blackAlpha(0.04)};`}
  width: 100%;
`;
