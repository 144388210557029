import styled from 'react-emotion';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { theme } from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  padding: ${theme.spacing.medium};
  flex-grow: 2;
  overflow: auto;
`;

export const StudentsWrapper = styled('div')`
  padding: ${theme.spacing.medium};
  padding-top: 0;
`;

export const TabsWrapper = styled('div')`
  #space-settings-tabs > div {
    border-bottom: 1px solid ${theme.color.designSystem.blackOpacity12};
  }

  div > button > span.wrapper > span {
    padding: 0;
  }
`;

export const ArrowBack = styled(ArrowBackIcon)`
  font-size: 14px;
`;
