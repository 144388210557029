import React from 'react';
import styled, { css } from 'react-emotion';

import BaseListItemAvatar from '@mui/material/ListItemAvatar';

import { theme, Button as BaseButton } from '@ublend-npm/aulaui-next';

const { color, spacing, font } = theme;
const { designSystem } = color;

export const scrollbarStyle = {
  height: '100%',
};

export const Container = styled.div`
  height: 80%;
  max-width: 1000px;
  padding-right: ${spacing.xxGiant};
`;

export const TopBar = styled.div`
  margin-right: ${theme.spacing.large};
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${spacing.medium} 0px;

  &.loader {
    justify-content: center;
    margin-top: ${spacing.xLarge};
  }
`;

export const NavigationInlineStyle = { maxWidth: '200px' };

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const ExtensionsPane = styled.ul`
  border-right: 1px solid ${designSystem.blackOpacity12};
  left: -8px;
  list-style-type: none;
  margin: 0 ${spacing.medium} 0 0;
  min-height: 380px;
  padding: 0 ${spacing.small} 0 0;
  position: relative;
  overflow-y: auto;
  width: 212px;
`;

export const SubmissionsContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  /* This should provide the base font styles for everything inside Submissions */
  color: ${theme.color.black};
  font-size: ${font.size.copy};
  font-weight: normal;
  line-height: ${font.lineHeight.copy};
`;

export const SubmissionsCommandBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 28px;
`;

export const ExtensionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.xLarge};
`;

export const AssignmentTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 10px;
  margin-right: ${theme.spacing.large};
`;

export const AssignmentTitle = styled.div`
  color: ${theme.color.blackAlpha(0.87)};
  font-family: ${font.family.workSans};
  font-weight: ${font.weight.semiBold};
  font-size: ${spacing.large};
  letter-spacing: 0.15px;
  line-height: ${font.lineHeight.large};
`;

export const DropdownWrapper = styled.div`
  width: 200px;
`;

export const ExtensionTitle = styled.span`
  color: ${theme.color.black};
  font-family: ${font.family.workSans};
  font-weight: ${font.weight.semiBold};
  font-size: ${font.size.large};
  line-height: ${font.lineHeight.copy};

  span {
    font-weight: ${font.weight.regular};
  }
`;

export const SubmissionGroupTitle = styled.p`
  color: ${theme.color.blackAlpha(0.6)};
  font-weight: ${font.weight.semiBold};
  font-size: ${font.size.small};
  letter-spacing: 0.4px;
  line-height: 16px;
`;

export const SubmissionListItemsWrapper = styled.div`
  padding-bottom: 35px;
  margin-right: ${theme.spacing.large};
`;

export const SubmissionHeaderContainer = styled.div`
  margin-right: ${theme.spacing.large};
`;

export const SubmissionListItem = styled(({ isHeader, ...props }) => (
  <div {...props} />
))`
  align-items: center;
  display: flex;
  height: 64px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  padding-right: 0 !important;
  margin-right: ${theme.spacing.large};

  &:hover {
    background-color: ${(props) =>
      props.isHeader ? '' : designSystem.background.grey.hover};
    ${(props) => !props.isHeader && 'cursor: pointer;'};
  }
`;

export const NoSubmissionsListItem = styled.div`
  align-items: center;
  display: flex;
  height: 64px;
  margin-top: 0;
  padding: ${spacing.small} 0;
  color: ${theme.color.blackAlpha(0.38)};
`;

const makeColumns = ({ cols = 2, gap }) => {
  const percent = (100 / 12) * cols;
  const gapText = gap ? `gap: ${gap}px;` : '';
  return `flex-basis: ${percent}%; max-width: ${percent}%; ${gapText}`;
};

export const GridColumn = styled(({ cols, withSpaceBetween, ...props }) => (
  <div {...props} />
))`
  ${(props) => makeColumns(props)}
  display: flex;
  align-items: center;
  ${({ withSpaceBetween }) =>
    withSpaceBetween ? 'justify-content: space-between' : ''};
`;

export const MenuContainer = styled.span`
  text-align: center;
`;

export const SubmissionsListHeader = styled.span`
  ${(props) => makeColumns(props)}
  font-weight: ${font.weight.semiBold};
`;

export const GroupName = styled.p`
  margin-left: 12px;
`;

export const SubmissionHyperLink = styled.div`
  color: ${theme.color.blackAlpha(0.6)};
  display: flex;
  justify-content: center;
  margin-left: 17px;
  align-items: center;

  svg {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const VisuallyHidden = css`
  :not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const SubmissionListItemAvatar = styled(BaseListItemAvatar)`
  margin: 0;
  width: 40px;
  height: 40px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing.large};
`;

export const DropdownStyle = { width: '200px' };
export const DropdownItemStyle = {
  height: 'auto',
  padding: '0',
};

export const AssignmentConversion = styled.div`
  width: max-content;
  display: inline;
  margin-right: 8px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${spacing.large};
`;

export const AlertContainer = styled.div`
  width: 445px;
`;

export const Button = styled(BaseButton)`
  background-color: ${theme.color.offWhite};
`;
