import { Assignment } from '@core/types/state';
import { Lti1P3Provider, LtiProvider } from '@ublend-npm/aula-schema';
import { LTI1P3_PROVIDER } from './constants';

const is1p3Provider = (assignment: Assignment) =>
  assignment.provider === LTI1P3_PROVIDER;

export const getAssignmentProvider = (
  assignment: Assignment,
  ltiProviders: LtiProvider[] | undefined = []
) => {
  if (is1p3Provider(assignment)) {
    return ltiProviders.find(
      (provider) => provider.id === assignment.providerId
    );
  }

  return ltiProviders.find((provider) => provider.name === assignment.provider);
};

export const isAssignmentProviderDisabled = (
  assignment: Assignment,
  ltiProviders: LtiProvider[] | undefined = []
): boolean => {
  const provider = getAssignmentProvider(assignment, ltiProviders);

  /* 1p3 providers should exist in known providers.
   If not present, we should infer it as disabled.

   A bespoke provider may not exist in known providers, since they are hardcoded.
   If not present, we should leave this as enabled. */
  return provider?.disabled ?? is1p3Provider(assignment);
};

export const getIsCoupledProvider = (provider: Lti1P3Provider) =>
  // An LTI 1.3 provider is coupled if it has any assignment parameters
  !!(
    Object.keys(provider?.assignmentParameters || {}).length > 0 ||
    (provider?.assignmentCreationParameters &&
      provider.assignmentCreationParameters.length > 0)
  );
