import React, { useEffect } from 'react';
import { FeedType, Post } from '@app/types/core';
import { ANNOUNCEMENTS } from '@core/constants/postFeedType';
import InfiniteTable from '../../../common/InfiniteTable';
import style from './style';
import PostCell from '../PostsTable/PostCell/PostCell';
import SchedulePostDialog from '../SchedulePostDialog';
import PostNavigation from '../PostNavigation/PostNavigation.container';

const getPageTitle = (feedType: FeedType, count) =>
  `Scheduled ${feedType === ANNOUNCEMENTS ? 'announcement' : 'post'}${
    count !== 0 ? 's' : ''
  }`;

interface SchedulePostListProps {
  count: number;
  spaceId: string | null;
  currentSpaceName: string;
  scheduledPosts: Post[];
  isFetching: boolean;
  feedType: FeedType;
  isChangingClassRoom: boolean;
}

const SchedulePostList = ({
  currentSpaceName = '',
  scheduledPosts,
  spaceId = null,
  count = 0,
  feedType,
  isChangingClassRoom,
}: SchedulePostListProps) => {
  useEffect(() => {
    const space = currentSpaceName ? `${currentSpaceName} - ` : '';
    document.title = `${getPageTitle(feedType, count)} - ${space}Aula`;
  }, [currentSpaceName, feedType, count]);

  const title = `${getPageTitle(feedType, count)} (${count})`;
  const isLoading = isChangingClassRoom || !spaceId;

  const renderHeader = () =>
    !isChangingClassRoom && (
      <div style={style.header}>
        <PostNavigation style={style.navigation} postFeedType={feedType} />
        <span style={style.title}>{title}</span>
        <SchedulePostDialog />
      </div>
    );

  return (
    <div style={style.parentContainer}>
      <InfiniteTable
        items={scheduledPosts}
        renderItem={(post: Post) => (
          <div style={style.postWrapper}>
            <PostCell post={post} />
          </div>
        )}
        keyExtractor={(post: Post) => post.objectId}
        loading={isLoading}
        renderHeader={renderHeader}
        viewStyle={style.postsContainer}
        contentStyle={style.table}
      />
    </div>
  );
};

export default SchedulePostList;
