import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  position: relative;
  flex-shrink: 0;
  overflow: visible;
  margin-bottom: ${theme.spacing.xLarge};
  padding-right: ${theme.spacing.xxxGiant};
  padding-left: ${theme.spacing.xLarge};
`;

export const EditedLabel = styled('span')`
  font-size: ${theme.font.size.copy};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.grey11};
  margin-left: ${theme.spacing.xSmall};
`;

export const MessagesContainer = styled('div')`
  flex-shrink: 0;
  padding-top: ${theme.spacing.xSmall};
`;

export const Row = styled('div')`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
