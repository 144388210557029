import { connect } from 'react-redux';
import ReactionsPresentational from './Reactions';
import { selectors } from '../../../store/reactions';

const makeMapStateToProps = () => {
  const getReactions = selectors.getReactionsSelector();
  return (state, props) => ({
    reactions: getReactions(state, { itemId: props.itemId }),
  });
};

const Reactions = connect(makeMapStateToProps, null)(ReactionsPresentational);

export default Reactions;
