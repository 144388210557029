import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TimeAgo from '../common/TimeAgo';
import getContentSummary from '../../../../../core/utils/getContentSummary';

import {
  QuoteViewContainer,
  QuoteDelimiter,
  QuoteMessageContainer,
  QuoteContainer,
  Author,
} from './Quote.styled';

const QuoteView = ({
  author,
  content,
  firstMessage,
  createdAt,
  getUser,
  shouldFetchUser,
  onClick,
}) => {
  useEffect(() => {
    if (shouldFetchUser) {
      getUser();
    }
  }, [shouldFetchUser]);
  const user = author || { firstName: '', lastName: '' };

  const [contentSummary, setContentSummary] = useState(null);
  useEffect(() => {
    setContentSummary(getContentSummary({ content })[0]);
  }, [content]);

  return (
    <QuoteViewContainer
      role="button"
      onClick={onClick}
      firstMessage={firstMessage}
    >
      <QuoteDelimiter />
      <QuoteMessageContainer>
        <Author>
          {user.firstName} {user.lastName}
        </Author>
        <QuoteContainer>
          {contentSummary || (
            <span role="img" aria-label="msg">
              💬 Message - <TimeAgo date={createdAt} />
            </span>
          )}
        </QuoteContainer>
      </QuoteMessageContainer>
    </QuoteViewContainer>
  );
};

QuoteView.propTypes = {
  firstMessage: PropTypes.bool,
  shouldFetchUser: PropTypes.bool.isRequired,
  author: PropTypes.shape({}).isRequired,
  content: PropTypes.string.isRequired,
  getUser: PropTypes.func.isRequired,
  createdAt: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

QuoteView.defaultProps = {
  firstMessage: false,
  createdAt: null,
};

export default QuoteView;
