import PropTypes from 'prop-types';
import React from 'react';
import { ReactElementType } from '@app/components/propTypes/common';
import { AlertDialog } from '../../../common/Dialog';

const ConfirmDeleteDialog = ({
  id,
  title,
  message,
  open,
  onClose,
  onConfirm,
  deleting,
}) => (
  <AlertDialog
    id={id}
    title={title}
    message={message}
    open={open}
    onClose={onClose}
    maxWidth="md"
    action={{
      label: 'Delete',
      onClick: onConfirm,
      danger: true,
      loading: deleting,
      htmlAttributes: { 'data-testid': 'delete-confirm' },
    }}
    secondaryAction={{
      label: 'Cancel',
      onClick: onClose,
    }}
  />
);

ConfirmDeleteDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, ReactElementType]).isRequired,
  deleting: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmDeleteDialog.defaultProps = {
  deleting: false,
  open: false,
};

export default ConfirmDeleteDialog;
