import { newError } from '../errors';
import * as actionTypes from '../../constants/actions';
import edit from '../../api/comments/edit';

// edit comment
const editCommentStart = (dispatch) => {
  dispatch({
    type: actionTypes.COMMENTS_EDIT,
    status: 'started',
  });
};

const editCommentSuccess = (comment, dispatch) => {
  dispatch({
    type: actionTypes.COMMENTS_EDIT,
    status: 'success',
    comment,
  });
};

const editCommentError = (error, dispatch) => {
  dispatch(newError(error, 'editComment'));
  dispatch({
    type: actionTypes.COMMENTS_EDIT,
    status: 'error',
    error,
  });
};

const editComment = (content, commentId) => async (dispatch) => {
  if (!commentId || !content) {
    const err = {
      message: "You need to provide the comment's object id and content",
    };
    return editCommentError(err, dispatch);
  }

  editCommentStart(dispatch);

  try {
    const editedComment = await edit({
      commentId,
      content,
    });
    return editCommentSuccess(editedComment, dispatch);
  } catch (err) {
    return editCommentError(err, dispatch);
  }
};

export default editComment;
