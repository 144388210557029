import React from 'react';
import PropTypes from 'prop-types';
import { NoSubmissionsListItem, GridColumn } from './Submissions.styled';

const NoSubmissionsCard = ({ count, isGroup }) => {
  return (
    <NoSubmissionsListItem>
      <GridColumn cols={6}>
        <p>{`${count} other ${isGroup ? `groups` : `students`}`}</p>
      </GridColumn>
      <GridColumn cols={4}>
        <p>No submission</p>
      </GridColumn>
      <GridColumn cols={2}>
        <span>-</span>
      </GridColumn>
    </NoSubmissionsListItem>
  );
};

NoSubmissionsCard.propTypes = {
  count: PropTypes.number.isRequired,
  isGroup: PropTypes.bool,
};

NoSubmissionsCard.defaultProps = {
  isGroup: false,
};

export default NoSubmissionsCard;
