import { theme } from '@ublend-npm/aulaui-next';

export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: 0,
    alignItems: 'flex-start',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
  },
  reactionsWrapper: {
    marginLeft: 20,
    float: 'right',
  },
  iconButton: {
    height: 'auto',
    paddingBottom: 0,
    paddingTop: 0,
  },
  iconBackground: {
    background: theme.color.beige,
    borderRadius: '3px',
  },
};
