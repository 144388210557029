import styled from 'react-emotion';
import Search from '@material-ui/icons/Search';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  position: relative;
  height: 35px;
  overflow: hidden;
  margin-left: ${theme.spacing.xSmall};
  margin-right: ${theme.spacing.xSmall};
  border: none;
  outline-width: 0;
  outline: none;
  font-size: ${theme.font.size.large};
`;

export const SearchIcon = styled(Search)`
  color: ${theme.color.grey11};
`;

export const ClearButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: ${theme.font.size.small};
  color: ${theme.color.grey11};
  transition: all ${theme.transition.speed.fast} linear;
  &:hover {
    opacity: 0.4;
  }
`;
