import styled, { css } from 'react-emotion';
import UnstyledVisibility from '@material-ui/icons/Visibility';
import UnstyledVisibilityOff from '@material-ui/icons/VisibilityOff';
import UnstyledEdit from '@material-ui/icons/Edit';
import UnstyledDeleteIcon from '@material-ui/icons/Delete';
import UnstyledLock from '@material-ui/icons/Lock';
import UnStyledLockOpen from '@material-ui/icons/LockOpen';
import UnStyledPeople from '@material-ui/icons/People';
import UnstyledExport from '@material-ui/icons/ImportExport';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { theme } from '@ublend-npm/aulaui-next';

export const Container = styled('div')`
  width: 100%;
  background: transparent;
  padding: 0 16px;
  box-sizing: border-box;
  min-height: 100%;
`;

export const OuterContainer = styled('div')`
  height: 100%;
  max-width: calc(584px + ${theme.spacing.medium} + ${theme.spacing.medium});
`;

export const LogoImage = styled('img')`
  margin-right: 8px;
`;

export const DefaultLogoContainer = styled('div')`
  margin-right: 8px;
`;

export const LoaderContainer = styled('div')`
  /* height: 100%; */
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const deleteTextClass = css`
  color: #cf0019;
`;

const buttonCss = css`
  width: 20px;
  height: 20px;
`;

export const Visibility = styled(UnstyledVisibility)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const VisibilityOff = styled(UnstyledVisibilityOff)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const Edit = styled(UnstyledEdit)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const Open = styled(OpenInNewIcon)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const Lock = styled(UnstyledLock)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const LockOpen = styled(UnStyledLockOpen)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const People = styled(UnStyledPeople)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const ExportIcon = styled(UnstyledExport)`
  ${buttonCss};
  color: ${theme.color.grayDark};
`;

export const DeleteIcon = styled(UnstyledDeleteIcon)`
  ${buttonCss};
  color: #cf0019;
`;

export const EmptyBox = styled('div')`
  height: 489px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const EmptyBg = styled('div')`
  height: 372px;
  width: 484px;
  background: white;
  border: 1px solid ${theme.color.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Image = styled('img')`
  border-radius: 50%;
  background: white;
  margin-bottom: 20px;
`;

export const EmptyTitle = styled('div')`
  color: ${theme.color.mediumBlue};
  font-size: 20px;
  font-weight: ${theme.font.weight.bold};
  text-align: center;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const EmptySubTitle = styled('div')`
  color: ${theme.color.grayDark};
  font-size: 16px;
  text-align: center;
  line-height: 24px;
`;
