import React, { useContext, createContext } from 'react';
import { useSelector } from 'react-redux';
import { getLtiProviders } from '@core/selectors/lti';
import { Assignment, User } from '@core/types/state';
import { icons, theme } from '@ublend-npm/aulaui-next';

import {
  HANDIN_PROVIDER,
  TURNITIN_PROVIDER,
  QUIZZES_PROVIDER,
  LTI1P3_PROVIDER,
} from '@core/assignments/constants';
import launchHandin from '../Assignments/utils/launchHandin';
import launchTurnitinAssignment from '../../../../actions/assignments/launchTurnitinAssignment.action';
import launchTurnitinSubmission from '../../../../actions/assignments/launchTurnitinSubmission.action';
import goToReports from '../../../../actions/navigation/goToReports.action';

import { AssignmentProviderLogo } from '../Assignments/AssignmentProviderLogo/AssignmentProviderLogo';

type AssignmentTypeProviderProps = {
  assignment?: Assignment;
  user?: User;
  spaceId?: string;
  children?: React.ReactNode;
};
type AssignmentLaunchFunction = (_?: {
  submissionId?: string | null;
  studentId?: string | null;
}) => void;

type ProviderContext = {
  type: string;
  title: string;
  renderImage: React.FunctionComponent;
  launchSubmission: AssignmentLaunchFunction;
  launchAssignment: AssignmentLaunchFunction;
  launchAssignments: AssignmentLaunchFunction;
};

const AssignmentTypeContext = createContext<ProviderContext>({
  type: '',
  title: '',
  renderImage: () => null,
  launchSubmission: () => {},
  launchAssignment: () => {},
  launchAssignments: () => {},
});

const getQuizSpaceId = (
  assignment: Assignment | undefined,
  spaceId: string | undefined
) => (assignment?.classRoom ? assignment.classRoom.objectId : spaceId);

const AssignmentTypeProvider = ({
  assignment,
  spaceId,
  user,
  children,
}: AssignmentTypeProviderProps) => {
  const launchTIISubmission = ({ submissionExternalId }) => {
    launchTurnitinSubmission({
      submissionId: submissionExternalId,
      spaceId,
    });
  };

  const launchQuizReport = ({ submissionId, reportType }) => {
    goToReports({
      assignmentId: assignment?.id,
      spaceId: getQuizSpaceId(assignment, spaceId),
      submissionId,
      reportType,
    })();
  };

  const lti1p3Provider = useSelector(getLtiProviders).find(
    (provider) => provider.id === assignment?.providerId
  );

  const goToHandin = ({ submissionId = null, studentId = null } = {}) =>
    launchHandin({
      assignmentId: assignment?.externalId,
      spaceId: assignment?.classRoom ? assignment?.classRoom.objectId : spaceId,
      submissionId,
      studentId,
      isAnonymised: assignment?.isAnonymised,
    });

  const goToTurnitin = () =>
    launchTurnitinAssignment({
      user,
      assignment,
      spaceId,
    });

  const { ExtensionIcon } = icons;

  const providerConfig = {
    [HANDIN_PROVIDER]: {
      type: HANDIN_PROVIDER,
      title: 'Handin',
      renderImage: () => (
        <AssignmentProviderLogo providerType={HANDIN_PROVIDER} />
      ),
      launchAssignment: goToHandin,
      launchFeedback: goToHandin,
      launchSubmission: goToHandin,
    },
    [TURNITIN_PROVIDER]: {
      type: TURNITIN_PROVIDER,
      title: 'Turnitin',
      renderImage: () => (
        <AssignmentProviderLogo providerType={TURNITIN_PROVIDER} />
      ),
      launchAssignment: goToTurnitin,
      launchFeedback: goToTurnitin,
      launchSubmission: launchTIISubmission,
    },
    [QUIZZES_PROVIDER]: {
      type: QUIZZES_PROVIDER,
      title: 'quizzes',
      renderImage: () => (
        <AssignmentProviderLogo providerType={QUIZZES_PROVIDER} />
      ),
      launchAssignment: () => {},
      launchFeedback: ({ submissionId }) =>
        launchQuizReport({ submissionId, reportType: 'feedback' }),
      launchSubmission: ({ submissionId }) =>
        launchQuizReport({ submissionId, reportType: 'review' }),
    },
    [LTI1P3_PROVIDER]: {
      type: LTI1P3_PROVIDER,
      title: lti1p3Provider?.description,
      renderImage: () =>
        lti1p3Provider?.iconUrl ? (
          <AssignmentProviderLogo
            providerType={LTI1P3_PROVIDER}
            imageSrc={lti1p3Provider?.iconUrl}
          />
        ) : (
          <ExtensionIcon style={{ color: theme.color.designSystem.grey50 }} />
        ),
      launchAssignment: () => {},
      launchFeedback: () => {},
      launchSubmission: () => {},
    },
  };

  const contextValue = providerConfig[assignment?.provider || ''];

  return (
    <AssignmentTypeContext.Provider value={contextValue}>
      {children}
    </AssignmentTypeContext.Provider>
  );
};

export const useAssignmentTypeProvider = () => {
  const context = useContext(AssignmentTypeContext);
  if (context === undefined) {
    throw new Error(
      'useAssignmentTypeProvider must be used within a AssignmentTypeProvider'
    );
  }
  return context;
};

export default AssignmentTypeProvider;
