import styled from 'react-emotion';
import {
  theme,
  Button as BaseButton,
  DialogActions as BaseDialogActions,
} from '@ublend-npm/aulaui-next';

export const Root = styled('div')`
  flex-grow: 1;
  overflow: auto;
  padding: ${theme.spacing.medium} ${theme.spacing.xLarge};
`;

export const DialogContentStyled = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalFooter = styled('div')`
  display: flex;
  justify-content: ${(props) =>
    props.isEditing ? 'space-between' : 'flex-end'};
  align-items: center;
  max-width: inherit;
  border-top: 1px solid ${theme.color.designSystem.blackOpacity12};
`;

export const DeleteButton = styled(BaseButton)`
  margin-left: ${theme.spacing.large};
`;

export const DialogActions = styled(BaseDialogActions)`
  border: none;
`;
