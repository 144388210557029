import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const HourGlassHalfFullIcon = React.memo((props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6 2L6.00863 7.17499C6.00951 7.7034 6.21946 8.20999 6.59264 8.5841L10 12L6.59088 15.4262C6.21878 15.8002 6.00951 16.306 6.00863 16.8335L6 22H18V16.8284C18 16.298 17.7893 15.7893 17.4142 15.4142L14 12L17.4124 8.59609C17.7886 8.22087 18 7.71141 18 7.18011V2H6ZM15.4142 15.9142C15.7893 16.2893 16 16.798 16 17.3284V20H8V17.3284C8 16.798 8.21071 16.2893 8.58579 15.9142L12 12.5L15.4142 15.9142Z"
        fill="#31405C;"
      />
    </SvgIcon>
  );
});
