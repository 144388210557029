import { HANDIN_SUBMISSIONS_EXPORT } from '@core/constants/endpoints';
import { getHeaders } from '@core/assignments/api/utils';

const handinSubmissionsExport = async (
  accessToken: string,
  assignmentExternalId: string
) => {
  await fetch(HANDIN_SUBMISSIONS_EXPORT(assignmentExternalId), {
    method: 'POST',
    headers: getHeaders(accessToken),
  });
};

export default handinSubmissionsExport;
