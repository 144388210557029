import PropTypes from 'prop-types';
import React from 'react';
import UserBlock from '../../../common/Userblock';

import {
  MATERIAL_CURRENT_VERSION_BY,
  MATERIAL_EDITED_BY_AT,
} from '../../../../../constants/texts';
import formatDate from '../../../../../../core/utils/formatDate';
import { Writer } from '../types';
import {
  Active,
  CurrentSubtitle,
  DefaultSubtitle,
} from './VersionListItem.styled';

const name = (writer) => `${writer.firstName} ${writer.lastName}`;

const Subtitle = (props) => {
  const { current, lastUpdatedAt, writer } = props;

  if (current) {
    return (
      <CurrentSubtitle>
        {MATERIAL_CURRENT_VERSION_BY(name(writer))}
      </CurrentSubtitle>
    );
  }

  return (
    <DefaultSubtitle>
      {MATERIAL_EDITED_BY_AT(name(writer), formatDate(lastUpdatedAt))}
    </DefaultSubtitle>
  );
};
Subtitle.propTypes = {
  current: PropTypes.bool.isRequired,
  lastUpdatedAt: PropTypes.string.isRequired,
  writer: Writer.isRequired,
};

const VersionListItem = (props) => (
  <Active selected={props.selected} onClick={props.onSelect}>
    <UserBlock
      boldTitle={props.selected}
      title={props.title}
      subtitle={<Subtitle {...props} />}
      avatar={props.writer.avatar}
      name={props.writer.firstName}
    />
  </Active>
);

VersionListItem.propTypes = {
  current: PropTypes.bool,
  lastUpdatedAt: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  writer: Writer.isRequired,
  onSelect: PropTypes.func,
};

VersionListItem.defaultProps = {
  current: false,
  selected: false,
  onSelect: () => null,
};

export default VersionListItem;
