import React from 'react';
import { theme, Button } from '@ublend-npm/aulaui-next';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { MaterialProgressStatus } from '@ublend-npm/aula-schema';

import { Container } from './ActionBar.styled';
import { StepCompleteButton } from './ReadonlyActionBar.syled';

type ReadonlyActionBarProps = Readonly<{
  sectionId: string;
  isComplete: boolean;
  isCompleteLoading: boolean;
  submitProgress: (
    sectionId: string,
    userProgress: MaterialProgressStatus
  ) => void;
}>;

const ReadonlyActionBar = ({
  sectionId,
  isComplete,
  isCompleteLoading,
  submitProgress,
}: ReadonlyActionBarProps) => {
  const StepCompletedIcon = () => (
    <CheckCircleOutline
      htmlColor="white"
      data-testid="completed-icon"
      role="img"
      aria-label="Step completed"
    />
  );
  const MarkAsCompleteIcon = () => (
    <CheckCircleOutline
      htmlColor={theme.color.grey7}
      data-testid="mark-as-complete-icon"
      role="img"
      aria-label="Mark as complete"
    />
  );

  return (
    <Container>
      {isComplete ? (
        <StepCompleteButton
          iconLeft={StepCompletedIcon}
          loading={isCompleteLoading}
          disabled={isCompleteLoading}
          size="medium"
          onClick={() =>
            submitProgress(sectionId, MaterialProgressStatus.INCOMPLETE)
          }
        >
          Step complete
        </StepCompleteButton>
      ) : (
        <Button
          iconLeft={MarkAsCompleteIcon}
          loading={isCompleteLoading}
          disabled={isCompleteLoading}
          type="secondary"
          onClick={() =>
            submitProgress(sectionId, MaterialProgressStatus.COMPLETE)
          }
        >
          Mark as complete
        </Button>
      )}
    </Container>
  );
};

export default ReadonlyActionBar;
