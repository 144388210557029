import React from 'react';
import PropTypes from 'prop-types';

import HelpOutline from '@material-ui/icons/HelpOutline';
import { Tooltip } from '@ublend-npm/aulaui-next';

import { Container, TitleRow, Title, Legend } from './FieldSet.styled';

const FieldSet = ({ title, legend, children, style }) => {
  // Users of this component can choose whether to display a help icon with
  // a tooltip message (string) or an entirely custom component to give extra
  // information on the FieldSet.
  const legendComponent =
    typeof legend === 'string' ? (
      <Tooltip position="top" title={legend}>
        <Legend>
          <HelpOutline />
        </Legend>
      </Tooltip>
    ) : (
      <Legend>{legend}</Legend>
    );

  return (
    <Container style={style}>
      <TitleRow>
        <Title>{title}</Title>
        {legendComponent}
      </TitleRow>
      {children}
    </Container>
  );
};

FieldSet.propTypes = {
  title: PropTypes.string,
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.object, // CSS styles to apply
};

FieldSet.defaultProps = {
  title: null,
  legend: null,
};

export default FieldSet;
