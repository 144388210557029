import React from 'react';
import PropTypes from 'prop-types';
import { Root, Title, TextField } from './ExtensionInfoForm.styled';

const ExtensionInfoForm = ({
  extensionName,
  onExtensionNameChange,
  hasTitle,
  isConversion,
}) => {
  const onExtensionNameFieldChange = (e) => {
    const {
      target: { value },
    } = e;

    onExtensionNameChange(value);
  };

  return (
    <Root>
      {hasTitle && <Title>Extension info</Title>}
      <TextField
        isConversion={isConversion}
        label="Extension name"
        value={extensionName}
        onChange={onExtensionNameFieldChange}
      />
    </Root>
  );
};

ExtensionInfoForm.propTypes = {
  extensionName: PropTypes.string,
  onExtensionNameChange: PropTypes.func,
  hasTitle: PropTypes.bool,
  isConversion: PropTypes.bool,
};

ExtensionInfoForm.defaultProps = {
  extensionName: '',
  onExtensionNameChange: () => {},
  hasTitle: true,
  isConversion: false,
};

export default React.memo(ExtensionInfoForm);
