import { connect } from 'react-redux';
import { createSelector, defaultMemoize } from 'reselect';
import {
  operations as creators,
  selectors as inboxSelectors,
} from '../../../../../store/conversations';
import * as socketCreators from '../../../../../../core/actions/socket';
import * as userSelectors from '../../../../../../core/selectors/user';
import * as usersSelectors from '../../../../../../core/selectors/users';
import WriteZone from './WriteZone';

const noop = () => null;

const currentConversationId = defaultMemoize(
  inboxSelectors.currentConversationId
);
const messages = defaultMemoize(inboxSelectors.messages);
const currentConversationMessageIds = defaultMemoize((state) => {
  const conversationId = currentConversationId(state);
  return inboxSelectors.conversationMessages(state, conversationId);
});
const conversationContent = defaultMemoize((state) => {
  const conversationId = currentConversationId(state);
  return inboxSelectors.conversationDraft(state, conversationId);
});

const replyingMessage = defaultMemoize((state) =>
  inboxSelectors.replyingToMessage(state)
);

const latestMessageFrom = createSelector(
  messages,
  currentConversationMessageIds,
  userSelectors.currentUserId,
  (messageStore = {}, conversationMessages = [], currentUserId) => {
    let message;
    for (let i = conversationMessages.length - 1; i >= 0; i -= 1) {
      message = messageStore[conversationMessages[i]];
      if (message && message.writer === currentUserId) {
        return message.id;
      }
    }

    return null;
  }
);

const recipientSelector = defaultMemoize((state) => {
  const conversationId = currentConversationId(state);
  const recipientId = inboxSelectors.recipientId(state, conversationId);
  return usersSelectors.user(state, recipientId);
});

const mapStateToProps = createSelector(
  currentConversationId,
  latestMessageFrom,
  recipientSelector,
  conversationContent,
  replyingMessage,
  (conversationId, latestOwnMessageId, recipient, content, reply) => ({
    conversationId,
    latestOwnMessageId,
    placeholder: recipient
      ? `Message ${recipient.firstName}...`
      : 'Write a message...',
    reply,
    content,
  })
);

const mapDispatchToProps = (dispatch) => ({
  onEdit: (id) => dispatch(creators.openEditMessage(id)),
  onReturn: (content, conversationId) =>
    dispatch(creators.sendMessage(content, conversationId)),
  sendTyping: (id) => dispatch(socketCreators.userIsTyping(id)),
  updateContent: (conversationId, messageData) =>
    dispatch(creators.updateMessageDraft(conversationId, messageData)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { latestOwnMessageId, ...otherStateProps } = stateProps;
  const { onEdit, sendTyping, ...otherDispatchProps } = dispatchProps;

  return {
    ...otherStateProps,
    ...otherDispatchProps,
    ...ownProps,
    onEdit: latestOwnMessageId ? () => onEdit(latestOwnMessageId) : noop,
    sendTyping: () => sendTyping(otherStateProps.conversationId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, {
  forwardRef: true,
})(WriteZone);
