import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { theme } from '@ublend-npm/aulaui-next';
import Container from './EducatorAreaIcon.styled';

const EducatorAreaIcon = () => (
  <Container>
    <SvgIcon viewBox="-6 -4 30 30" htmlColor={theme.color.avatarBlue}>
      <path d="M7 16L3 12L4.41 10.59L7 13.17L13.59 6.58L15 8L7 16ZM9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0Z" />
    </SvgIcon>
  </Container>
);

export default EducatorAreaIcon;
