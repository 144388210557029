// indices
export const INDICES = {
  POSTS: 'posts',
  TAGS: 'tags',
  USERS: 'users',
};

// Queries

export const groupPartipantQuery = (value) => ({
  index: INDICES.USERS,
  type: 'User',
  size: 50,
  body: {
    query: {
      bool: {
        must: [
          {
            multi_match: {
              fields: ['*Name', 'email'],
              query: value,
              fuzziness: 5,
              prefix_length: 0,
            },
          },
        ],
      },
    },
  },
});

const createPostsAndTagsQuery = (value, index) => {
  if (index === INDICES.TAGS) {
    return {
      match: {
        name: {
          query: value,
          fuzziness: 1,
          prefix_length: 4,
        },
      },
    };
  }
  if (index === INDICES.POSTS) {
    return {
      multi_match: {
        fields: ['processedContent', 'user.*Name'],
        query: value,
        fuzziness: 4,
        prefix_length: 4,
      },
    };
  }
  return { match: { _all: value } };
};

export const postsAndTagsQueries = (text, classRoomId) => {
  const esQueries = [];
  [INDICES.POSTS, INDICES.TAGS].forEach((index) => {
    const idx = index.toLocaleLowerCase();
    esQueries.push({ index: idx });
    esQueries.push({
      size: 50,
      query: {
        bool: {
          must: [
            createPostsAndTagsQuery(text, idx),
            {
              match: {
                ...(index === INDICES.POSTS
                  ? { classRoomId }
                  : { classRoom: classRoomId }),
              },
            },
          ],
        },
      },
    });
  });

  return esQueries;
};

export const tagsQuery = (tagId, classRoomId) => ({
  index: INDICES.POSTS,
  size: 50,
  body: {
    query: {
      bool: {
        must: [
          { match: { 'tags.objectId': tagId } },
          { match: { classRoomId } },
        ],
      },
    },
  },
});

export const tagsByNameQuery = (tagName, classRoom) => ({
  index: INDICES.TAGS,
  size: 999,
  body: {
    query: {
      bool: {
        must: [{ match: { name: tagName } }, { match: { classRoom } }],
      },
    },
  },
});

export const allTagsQuery = (classRoom) => ({
  index: INDICES.TAGS,
  size: 999,
  body: {
    query: {
      bool: {
        must: [{ match_all: {} }, { match: { classRoom } }],
      },
    },
  },
});
