import React from 'react';
import {
  InformationIcon,
  InformationSection,
  InformationText,
} from './FormInfoMessage.styled';

type FormInfoMessageProps = {
  message: string;
};

const FormInfoMessage = ({ message }: FormInfoMessageProps) => {
  return (
    <InformationSection>
      <InformationIcon data-testid="information-icon" />
      <InformationText>{message}</InformationText>
    </InformationSection>
  );
};

export default FormInfoMessage;
