/* eslint-env browser */

// Copied from https://blog.shovonhasan.com/using-promises-with-filereader/
// only codestyle got changed

const errorHandler = (reader, reject) => () => {
  reader.abort();
  reject(new DOMException('Problem parsing input file.'));
};

const loadHandler = (reader, resolve) => () => resolve(reader.result);

export default (inputFile) =>
  new Promise((resolve, reject) => {
    const temporaryFileReader = new FileReader();
    temporaryFileReader.onerror = errorHandler(temporaryFileReader, reject);
    temporaryFileReader.onload = loadHandler(temporaryFileReader, resolve);
    temporaryFileReader.readAsText(inputFile);
  });
