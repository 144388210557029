import { connect } from 'react-redux';
import MaterialMenu from './MaterialMenu';
import { operations } from '../../../../../store/materials';
import * as appCreators from '../../../../../actions/materials';

const mapDispatchToProps = (dispatch) => ({
  onCreateFolder: (educatorOnly) =>
    dispatch(operations.createFolder(educatorOnly)),
  onImportMaterial: (params) => dispatch(operations.importMaterial(params)),
  onExportMaterial: (educatorOnly) =>
    dispatch(appCreators.exportMaterial(educatorOnly)),
});

export default connect(null, mapDispatchToProps)(MaterialMenu);
