import { connect } from 'react-redux';
import EducatorParticipant from './EducatorParticipant';
import { online } from '../../../../../../core/selectors/live';
import * as usersSelectors from '../../../../../../core/selectors/users';

const mapStateToProps = (state, ownProps) => {
  const { participantId } = ownProps;
  const userId = participantId.id;
  const cachedUser = usersSelectors.user(state, userId);
  const user = cachedUser || { objectId: userId };

  return {
    name: user.firstName,
    src: user.avatar,
    online: online(state, userId),
    colourSeed: userId,
    fullName: `${user.firstName} ${user.lastName}`,
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EducatorParticipant);
