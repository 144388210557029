import { CLASSROOM_OPEN_CLOSE_SCHEDULE_POST } from '../../constants/actions';

export const openSchedulePost = (postId) => (dispatch) =>
  dispatch({
    type: CLASSROOM_OPEN_CLOSE_SCHEDULE_POST,
    payload: { schedulePostDialogOpenFor: postId },
  });

export const closeSchedulePost = () => (dispatch) =>
  dispatch({
    type: CLASSROOM_OPEN_CLOSE_SCHEDULE_POST,
    payload: { schedulePostDialogOpenFor: null },
  });
