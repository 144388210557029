import React from 'react';
import PropTypes from 'prop-types';

import Container from './TagFilterItem.styled';

const TagFilterItem = ({ label, leftDecoration, ...props }) => (
  <Container {...props}>{label}</Container>
);

TagFilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  leftDecoration: PropTypes.node,
  selected: PropTypes.bool,
};

TagFilterItem.defaultProps = {
  leftDecoration: null,
  selected: false,
};

export default TagFilterItem;
