import PropTypes from 'prop-types';
import React from 'react';
import { Container } from './TypingIndicator.styled';

const TypingIndicator = ({ typing, label }) => (
  <Container typing={typing}>{label}</Container>
);

TypingIndicator.propTypes = {
  typing: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default React.memo(TypingIndicator);
