import styled from 'react-emotion';
import { theme, TextField } from '@ublend-npm/aulaui-next';

export const RadioContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AssignmentLabelContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
`;

export const AssignmentProviderText = styled('p')`
  font-size: ${theme.font.size.copy};
  color: black;
  line-height: 12px;
  margin: 0;
`;

export const DescriptionText = styled('p')`
  font-size: ${theme.font.size.small};
  color: ${theme.color.designSystem.blackOpacity60};
  margin: 4px 0 0 0;
  line-height: normal;
`;

export const DescriptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  padding-left: 8px;
  height: 32px;
`;

export const TitleTextField = styled(TextField)`
  margin: 0 12px 0 28px;
`;
