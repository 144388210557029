import { connect } from 'react-redux';
import TopBar from './TopBar';
import { isInstructor } from '../../../../../../core/selectors/classroom';

const mapStateToProps = (state) => ({
  isInstructor: isInstructor(state),
});

const TopBarContainer = connect(mapStateToProps)(TopBar);

export default TopBarContainer;
