import styled from 'react-emotion';
import { theme } from '@ublend-npm/aulaui-next';
import BaseCreateIcon from '@mui/icons-material/Create';

export const EditZoneContainer = styled('div')`
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${theme.spacing.xLarge};
`;

export const TitleContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const CreateIcon = styled(BaseCreateIcon)`
  margin-right: 16px;
`;
